import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getEmpyInfo } from "../../api/reportApi";
import { Status } from "../model/common/Status";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col, Select, DatePicker } from "antd";
import "../../styles/report.scss";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../api/companyApi";
import {
  TreeSelectionInput,
  getAllDsOption,
} from "../common/TreeSelectionInput";

const { Option } = Select;

export default function JobRecruitmentDetailsForm() {
  const { t } = useTranslation();
  const REPORT_NAME = "Job Recruitment Details Report";

  const [year, setYear] = useState();
  const [month, setMonth] = useState([]);
  const [statusLocal, setStatus] = useState([]);
  const [hiringStatusDS, setHiringStatusDS] = useState([
    { text: "ALL SELECTED", value: 0 },
    { text: "Hiring Successful", value: 1 },
    { text: "Hiring Unsuccessful", value: 2 },
  ]);
  const [empyInfo, setEmpyInfo] = useState({ EmpyKey: null });
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [dsStatusOptions, setStatusOptions] = useState([]);
  const [dsMonthOptions, setDsMonthOptions] = useState([]);
  const [dsComp, setCompOptions] = useState([]);
  const [isReportGenerating, setReportGenerating] = useState(false);
  const [isValidated, setValidated] = useState([0, 1, 2]);
  const [validatedDS, setValidatedDS] = useState([
    { text: "ALL SELECTED", value: 0 },
    { text: "Validated", value: 1 },
    { text: "Non-Validated", value: 2 },
  ]);
  const [isHired, setHired] = useState([0, 1, 2]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedComp, setSelectedComp] = useState();

  const onSingleCompanyChange = (value) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value > -1) {
      setSelectedComp(value);
    } else {
      setSelectedComp([]);
    }
  };

  function getMonthLabel(value) {
    const mthLabel = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return mthLabel[value];
  }

  const onYrChange = (value) => {
    setIsOpen(false);
    setYear(value);
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }
  };

  const onMonthChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setMonth(getAllDsOption(dsMonthOptions, true));
        } else {
          setMonth([]);
        }
      } else {
        if (dsMonthOptions.length > 0) {
          setMonth(
            value.filter((x) => x !== -1).length === dsMonthOptions.length
              ? getAllDsOption(dsMonthOptions, true)
              : value.filter((x) => x !== -1)
          );
        } else {
          setMonth(value.filter((x) => x !== -1));
        }
      }
    } else {
      setMonth([]);
    }
  };

  const onStatusChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setStatus(getAllDsOption(dsStatusOptions, true));
        } else {
          setStatus([]);
        }
      } else {
        if (dsStatusOptions.length > 0) {
          setStatus(
            value.filter((x) => x !== -1).length === dsStatusOptions.length
              ? getAllDsOption(dsStatusOptions, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setStatus([]);
    }
  };

  const onValidated = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === 0) {
        if (value.includes(0)) {
          setValidated(getAllDsOption(validatedDS, false));
        } else {
          setValidated([]);
        }
      } else {
        if (validatedDS.length > 0) {
          setValidated(
            value.includes(1) && value.includes(2)
              ? getAllDsOption(validatedDS, false)
              : value.filter((x) => x !== 0)
          );
        }
      }
    } else {
      setValidated([]);
    }
  };

  const onHired = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === 0) {
        if (value.includes(0)) {
          setHired(getAllDsOption(hiringStatusDS, false));
        } else {
          setHired([]);
        }
      } else {
        if (hiringStatusDS.length > 0) {
          setHired(
            value.includes(1) && value.includes(2)
              ? getAllDsOption(hiringStatusDS, false)
              : value.filter((x) => x !== 0)
          );
        }
      }
    } else {
      setHired([]);
    }
  };

  const generateReport = (e) => {
    setReportGenerating(true);
    e.preventDefault();
    setReportGenerate(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );

    setReportParam({
      CompKey: selectedComp,
      Year: year.$y,
      Month: month.includes(-1) ? [null] : month,
      Status: statusLocal.includes(-1) ? [null] : statusLocal,
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
      IsValidated: isValidated[0],
      IsHired: isHired[0],
    });
  };

  const validate = (
    year,
    status,
    month,
    isHired,
    isValidated,
    selectedComp
  ) => {
    var valid = false;
    if (
      year > 0 &&
      status.length > 0 &&
      month.length > 0 &&
      isHired.length > 0 &&
      isValidated.length > 0 &&
      selectedComp !== undefined
    ) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(year, statusLocal, month, isHired, isValidated, selectedComp);
  }, [year, statusLocal, month, isHired, isValidated, selectedComp]);

  useEffect(() => {
    let promises = [getEmpyInfo(), getCompany()];
    const statusArr = [];
    for (var st in Status) {
      // eslint-disable-next-line no-prototype-builtins
      if (Status.hasOwnProperty(st)) {
        if (st >= 0 && st <= 7) {
          statusArr.push({
            text: Status[st],
            value: st,
          });
        }
      }
    }
    const monthArr = [];
    for (var i = 1; i < 13; i++) {
      monthArr.push({
        text: getMonthLabel(i - 1),
        value: i,
      });
    }
    setValidatedDS([
      { text: "ALL SELECTED", value: 0 },
      { text: "Validated", value: 1 },
      { text: "Non-Validated", value: 2 },
    ]);
    setHiringStatusDS([
      { text: "ALL SELECTED", value: 0 },
      { text: "Hiring Successful", value: 1 },
      { text: "Hiring Unsuccessful", value: 2 },
    ]);
    setDsMonthOptions(monthArr);
    setStatusOptions(statusArr);
    setStatus(["0", "1", "4", "5", "7"]);

    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setEmpyInfo(data[0][0]);
        }

        if (data[1] && data[1].length > 0) {
          const CompArr = [];
          data[1].forEach((element) => {
            CompArr.push({
              text: element.CodeDesc,
              value: element.Key,
            });
          });
          setCompOptions(CompArr);
        }
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.company")}</label>
          <br />
          <Select
            mode="single"
            style={{
              width: 240,
              maxHeight: 100,
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            placeholder={t("general.company_placeholder")}
            onChange={onSingleCompanyChange}
            optionLabelProp="label"
            value={selectedComp}
            filterOption={(input, option) =>
              option.props.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {dsComp.map((element) => (
              <Option
                key={element.value}
                value={element.value}
                label={element.text}
              >
                <div>{element.text}</div>
              </Option>
            ))}
          </Select>
          <br />
          <label>{t("general.year")}</label>
          <br />
          <DatePicker
            allowClear={false}
            value={year}
            mode="year"
            open={isOpen}
            placeholder={t("general.year_placeholder_2")}
            format="YYYY"
            onOpenChange={(status) => {
              if (status) {
                setIsOpen(true);
              } else {
                setIsOpen(false);
              }
            }}
            onPanelChange={onYrChange}
            style={{ width: 240 }}
          />
          <br />
          <label>{t("general.month")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.month_placeholder")}
            fncOnChange={onMonthChange}
            value={month}
            dsOption={dsMonthOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.status")}</label>
          <br />{" "}
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.status_placeholder")}
            fncOnChange={onStatusChange}
            value={statusLocal}
            dsOption={dsStatusOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("report.hiring_status")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("report.hiring_status_placeholder")}
            fncOnChange={onHired}
            value={isHired}
            dsOption={hiringStatusDS}
            isIncludeAllOption={false}
          ></TreeSelectionInput>
          <br />
          <label>{t("report.is_validated")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("report.is_validated_placeholder")}
            fncOnChange={onValidated}
            value={isValidated}
            dsOption={validatedDS}
            isIncludeAllOption={false}
          ></TreeSelectionInput>
          <br />
          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
