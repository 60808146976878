import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageRoleTypesForm from "../../forms/Master/ManageRoleTypesForm";
import PropTypes from "prop-types";
import RoleTypeModel from "../../model/Master/RoleTypeModel";
import {
  getRoleType,
  addRoleType,
  updateRoleType,
} from "../../../api/roleTypeApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newRoleType = RoleTypeModel({});
const types = [
  { Key: 1, Desc: "Admin" },
  { Key: 2, Desc: "Approver" },
  { Key: 3, Desc: "User" },
];

export default function ManageRoleTypesPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [roleType, setRoletype] = useState(newRoleType);
  const [isLoading, setIsLoading] = useState(true);
  const { RoleTypeKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = RoleTypeKey;
    if (editKey > 0) {
      promises.push(getRoleType(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
          if (values[0].CreatedDate) {
            values[0].CreatedOn = values[0].CreatedDate;
          }
        }
        setRoletype(values[0] ? values[0] : newRoleType);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [RoleTypeKey]);

  function saveRoleType(values) {
    let roleTypeSubmit = {
      ...roleType,
      ...values,
      RoleTypeCode: values.RoleTypeCode.trim(),
      RoleTypeDesc: values.RoleTypeDesc.trim(),
      Type: values.Type ? values.Type.key : 0,
    };

    let upsertRoleType =
      roleTypeSubmit.RoleTypeKey !== -1 ? updateRoleType : addRoleType;
    setIsLoading(true);
    upsertRoleType(roleTypeSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/roletypes", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          roleTypeSubmit.RoleTypeKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputRoleType() {
    history("/home/roletypes", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageRoleTypesForm
      isLoading={isLoading}
      roleType={roleType}
      types={types}
      onSubmitted={saveRoleType}
      onCancelled={cancelInputRoleType}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageRoleTypesPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
