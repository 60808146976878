import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LeaveValidationForm from "../../forms/Leave/LeaveValidationForm";
import {
  getPendingAndValidatedLeaves,
  validateLeaves,
  invalidateLeaves,
  checkPayrollMonthEndClose,
} from "../../../api/leaveApprovalApi";
import { getLeaveConfg } from "../../../api/leaveConfgApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";

export default function LeaveValidationPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [leaveTrans, setLeaveTrans] = useState([]);
  const [selectedLeaveTrans, setSelectedLeaveTrans] = useState([]);
  const [leaveConfg, setLeaveConfg] = useState({});
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getLeaveTrans, [currentYear]);

  function getLeaveTrans() {
    setIsLoading(true);
    let promises = [getPendingAndValidatedLeaves(currentYear), getLeaveConfg()];

    Promise.all(promises)
      .then((values) => {
        setLeaveTrans(values[0] ? values[0] : []);
        setLeaveConfg(values[1] ? values[1] : []);
        setSelectedLeaveTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewLeave() {
    history("/home/leaveapplication/leavetrans");
  }

  function checkMthEndClose(selectedItems) {
    setIsLoading(true);

    checkPayrollMonthEndClose(selectedItems)
      .then((value) => {
        setIsLoading(false);
        if (value.length > 0) {
          Modal.error({
            title: t("leaveApplicationForm.invalid_leave_validation"),
            content: t(
              "leaveApplicationForm.not_allowed_validate_closed_payroll_month_message"
            ),
          });
        } else {
          validateLeaveTrans(selectedItems);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("leaveApplicationForm.fail_to_check_payroll_month_end_status"),
          error
        );
      });
  }

  function validateLeaveTrans(selectedLeaves) {
    setIsLoading(true);

    validateLeaves(selectedLeaves)
      .then(() => {
        getLeaveTrans();
        toast.success(t("general.record_validated_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_validate_record"), error);
      });
  }

  function rejectLeaveTrans(selectedLeaves, reason) {
    setIsLoading(true);

    invalidateLeaves(selectedLeaves, reason)
      .then(() => {
        getLeaveTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewLeaveTrans(leaveTransKey) {
    history("/home/leaveapplication/leavetrans/" + leaveTransKey, {
      state: { fromValidation: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <LeaveValidationForm
      isLoading={isLoading}
      data={leaveTrans}
      selectedItems={selectedLeaveTrans}
      onAdd={onAddNewLeave}
      onView={viewLeaveTrans}
      onValidated={
        leaveConfg && leaveConfg.IsPostULToQuarto
          ? checkMthEndClose
          : validateLeaveTrans
      }
      onRejected={rejectLeaveTrans}
      onItemSelected={setSelectedLeaveTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

LeaveValidationPage.propTypes = {
  history: PropTypes.func.isRequired,
};
