import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "medicaldeduction/";

export function getApprovedVoidClinics(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedVoidClinics",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMedicalClaimLimit(
  claimTypeKey,
  userKey,
  empyKey,
  clinicApplicationDate
) {
  return axios({
    method: "GET",
    url: base + "GetMedicalClaimLimit",
    headers: authHeader(),
    params: {
      claimTypeKey: claimTypeKey,
      transUserKey: userKey,
      transEmpyKey: empyKey,
      clinicApplicationDate: clinicApplicationDate,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addMedicalDeduction(clinicTrans) {
  return axios({
    method: "POST",
    url: base + "AddMedicalDeduction",
    headers: authHeader(),
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function updateMedicalDeduction(clinicTrans) {
  return axios({
    method: "PUT",
    url: base + "UpdateMedicalDeduction",
    headers: authHeader(),
    params: { key: clinicTrans.MedicalDeductionTransKey },
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}
