import { Modal } from "antd";

const { confirm } = Modal;

export function showDeleteConfirm(message, key, DeleteFunction, t) {
  confirm({
    title: t("general.delete_confirmation"),
    content:
      t("general.delete_message_1") + message + t("general.delete_message_2"),
    okText: t("general.yes"),
    okType: "danger",
    cancelText: t("general.no"),
    onOk() {
      DeleteFunction(key);
    },
    onCancel() {},
  });
}
