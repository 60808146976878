import React, { useState, useEffect } from "react";
import { BellOutlined } from "@ant-design/icons";
import { Badge, Tabs, Tooltip } from "antd";
import PropTypes from "prop-types";
import "./NoticeIcon.css";
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown";
import NoticeList from "./NoticeList";
import { withRouter } from "../../customHooks/withRouter";
import { connect } from "react-redux";
import * as notificationActions from "../../redux/actions/notificationAction";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";

function NoticeIcon({ history, actions, notifications: notificationData }) {
  const [isVisible, setIsVisible] = useState(false);
  const [isClear, setIsClear] = useState(false);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();
  let totalAllRecords = 0;

  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line
  }, [actions]);

  useEffect(() => {
    setCount(totalAllRecords);
    // eslint-disable-next-line
  }, [getNotificationBox]);

  const loadMoreData = () => {
    let runningNum = -1;
    if (notificationData.length > 0) {
      if (notificationData[0].child.length > 0) {
        runningNum = Math.min(
          ...notificationData[0].child.map((o) => o.NotificationKey)
        );
      }
    }
    actions.loadNotificationsOnDemand(runningNum);
  };

  const trigger = (
    <span
      style={{
        display: "inline-block",
        height: "100%",
        padding: "0 12px",
        cursor: "pointer",
        transition: "all .3s",
      }}
    >
      <Tooltip placement="bottomRight" title={t("noticeIcon.notifications")}>
        <Badge count={count} style={{ boxShadow: "none" }} className="badge">
          <BellOutlined
            className="icon"
            style={{ padding: 4, verticalAlign: "middle", fontSize: 16 }}
          />
        </Badge>
      </Tooltip>
    </span>
  );

  function getItems() {
    let result = [];
    if (notificationData.length > 0) {
      notificationData.forEach((i) => {
        let totalReadRecords = 0;
        let totalRecords = i.TotalRecords;
        let showClear = false;
        let showViewMore = false;
        let addRecords = i.AddedRecords === undefined ? 0 : i.AddedRecords;
        let deleteRecords =
          i.DeletedRecords === undefined ? 0 : i.DeletedRecords;
        if (i.child.length > 0) {
          for (let num = 0; num < i.child.length; num++) {
            if (
              i.child[num].IsRead === true ||
              i.child[num].IsDeleted === true
            ) {
              totalReadRecords += 1;
            }
          }
          showClear = true;
          showViewMore = true;
        }

        totalRecords =
          i.TotalRecords - totalReadRecords + addRecords - deleteRecords;
        if (isClear) totalRecords = 0;
        totalAllRecords = totalAllRecords + totalRecords;

        result.push({
          label: `${t("noticeIcon." + i.Type)} (${totalRecords})`,
          key: i.Type,
          children: (
            <NoticeList
              data={i}
              onClick={(item) => onItemClick(item)}
              onClear={() => onClearTab(`${i.Type}`)}
              onViewMore={(item) => onViewMoreItem(item)}
              title={`${t(i.Type)}`}
              showClear={showClear}
              showViewMore={showViewMore}
              numOfChild={totalRecords}
              loadMoreData={loadMoreData}
            />
          ),
        });
      });
    }
    return result;
  }

  function getNotificationBox() {
    return (
      <Tabs
        items={getItems()}
        style={{
          boxShadow: "0 2px 8px rgba(0,0,0,.15)",
          borderRadius: "inherit",
        }}
      ></Tabs>
    );
  }

  function handleVisibleChange(isVisible) {
    setIsVisible(isVisible);
  }

  function onItemClick(data) {
    if (data.IsRead === false) {
      setIsVisible(false);
      actions
        .updateNotifications(data.NotificationKey)
        .then(() => {
          if (data.Route !== "") {
            if (notificationData[0].child.length > 0) {
              if (
                notificationData[0].child.filter((x) => x.IsRead === false)
                  .length < 7 &&
                notificationData[0].child.length <
                  notificationData[0].TotalRecords
              ) {
                loadMoreData();
              }
            }

            history(data.Route);
          }
        })
        .catch(() => {});
    }
  }

  function onClearTab(data) {
    let typeKey = getTypeKey(data);

    actions
      .updateAllNotifications(typeKey)
      .then(() => {
        setIsClear(true);
      })
      .catch(() => {});
  }

  function getTypeKey(param) {
    switch (param) {
      case "Notification":
        return 1;
      case "Message":
        return 2;
      case "Event":
        return 3;
      default:
        return 0;
    }
  }

  function onViewMoreItem() {
    setIsVisible(false);
    history("/home/notification");
  }

  const notificationBox = getNotificationBox();

  return (
    <HeaderDropdown
      placement="bottomRight"
      dropdownRender={() => notificationBox}
      overlayClassName="popover"
      trigger={["click"]}
      open={isVisible}
      onOpenChange={handleVisibleChange}
    >
      {trigger}
    </HeaderDropdown>
  );
}

NoticeIcon.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadNotificationsOnDemand: bindActionCreators(
        notificationActions.loadNotificationsOnDemand,
        dispatch
      ),
      updateNotifications: bindActionCreators(
        notificationActions.updateNotifications,
        dispatch
      ),
      updateAllNotifications: bindActionCreators(
        notificationActions.updateAllNotifications,
        dispatch
      ),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NoticeIcon));
