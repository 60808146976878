import React from "react";
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox } from "antd";
import PropTypes from "prop-types";

export const ActiveCheckbox = ({ isChecked, ...props }) => (
  <Checkbox checked={isChecked} disabled={true} {...props} />
);

ActiveCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
};

export const AddButton = ({ fncAdd, ...props }) => (
  <Button
    type="link"
    icon={<PlusOutlined />}
    size="small"
    onClick={() => fncAdd()}
    {...props}
  />
);

AddButton.propTypes = {
  fncAdd: PropTypes.func.isRequired,
};

export const EditButton = ({ fncEdit, ...props }) => (
  <Button
    type="link"
    icon={<EditOutlined />}
    size="small"
    onClick={() => fncEdit()}
    {...props}
  />
);

EditButton.propTypes = {
  fncEdit: PropTypes.func.isRequired,
};

export const DeleteButton = ({ fncDelete, ...props }) => (
  <Button
    type="link"
    icon={<DeleteOutlined />}
    size="small"
    onClick={() => fncDelete()}
    {...props}
  />
);

DeleteButton.propTypes = {
  fncDelete: PropTypes.func.isRequired,
};
