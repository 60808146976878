import dayjs from "dayjs";
import React,{ useEffect, useState } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import Axios from "axios";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { getVehicleRequisitions } from "../../../api/vehicleRequisitionApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getESSEmployee } from "../../../api/employeeApi";
import VehicleReqAdminForm from "../../forms/AdminPortal/VehicleReqAdminForm";
import { useLocation } from "react-router-dom";

export default function VehicleReqAdminPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [allVehicleReqDS, setAllVehicleReqDS] = useState([]);
  const [filteredVehicleReqDS, setFilteredVehicleReqDS] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();   //remain filter values when close input form
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.filterYear : dayjs().year()
  );
  const [currentMonth, setCurrentMonth] = useState(
    location.state ? location.state.filterMonth : dayjs().month() + 1
  );
  const [allOUs, setAllOUs] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOUKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  const [userKey, setUserKey] = useState(
    location.state ? location.state.filterUserKey : 0
  );
  const [pagination, setPagination] = useState(
    {
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });

  useEffect(getVehicleReqs, []);

  function getVehicleReqs() {
    let promises = [
      getVehicleReqDataSource(currentYear, currentMonth),
      getOU(),
      getDepartment(),
      getESSEmployee(),
    ];

    Promise.all(promises).then((values) => {
      resetPagination();

      setAllOUs(values[1] ? values[1].filter((x) => x.Active) : []);
      setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
      setAllUsers(values[3] ? values[3] : []);
      setInitialUsers(values[3] ? values[3] : []);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      setAllVehicleReqDS([]);
      setFilteredVehicleReqDS([]);
      setAllOUs([]);
      setAllDepartments([]);
      setAllUsers([]);
      setInitialUsers([]);
      showErrorMessage(
        t('general.fail_to_load_record') + 
          error.response.data.error_description
      );
    });
  }

  function getVehicleReqDataSource(currentYear, currentMonth) {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    getVehicleRequisitions(source, currentYear, currentMonth, true)
      .then((result) => {
        setAllVehicleReqDS(result);
        setFilteredVehicleReqDS(
          result.filter(
            (x) => 
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey) &&
              (userKey === 0 || x.UserKey === userKey)
            )
        );

        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        setAllVehicleReqDS([]);
        setFilteredVehicleReqDS([]);
        showErrorMessage(
          t('general.fail_to_load_record') + 
            error.response.data.error_description
        );
      });
  }

  function onViewVehicleReq(VehicleRequisitionKey) {
    history("/home/vehiclerequisition/vehicletrans/" + VehicleRequisitionKey,
    { state: { 
      fromAdmin: true,
      filterOUKey: ouKey,
      filterDeptKey: deptKey,
      filterUserKey: userKey,
      filterYear: currentYear,
      filterMonth: currentMonth,
    }});
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function filterRecord(ouKey, deptKey, userKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setUserKey(userKey);
    setFilteredVehicleReqDS(
      allVehicleReqDS.filter(
        (x) => 
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (userKey === 0 || x.UserKey === userKey)
        )
    );
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  return (
    <VehicleReqAdminForm 
      isLoading={isLoading}
      vehicleReqDS={filteredVehicleReqDS}
      getVehicleReqDataSource={getVehicleReqDataSource}
      form={form}
      onViewVehicleReq={onViewVehicleReq}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      setCurrentMonth={setCurrentMonth}
      allOUs={allOUs}
      allDepartments={allDepartments}
      allUsers={allUsers}
      initialUsers={initialUsers}
      setAllUsers={setAllUsers}
      ouKey={ouKey}
      deptKey={deptKey}
      userKey={userKey}
      currentYear={currentYear}
      currentMonth={currentMonth}
      filterRecord={filterRecord}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

VehicleReqAdminPage.propTypes = {
  history: PropTypes.func.isRequired,
};  