import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "trainingapplicationapproval/";

export function getPendingApprovalTrainingApplications() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalTrainingApplications",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedTrainingApplications(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedTrainingApplications",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidTrainingApplications(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidTrainingApplications",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledTrainingApplications(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledTrainingApplications",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveTrainingApplications(trainingApplication) {
  return axios({
    method: "POST",
    url: base + "ApproveTrainingApplications",
    headers: authHeader(),
    data: trainingApplication,
  })
    .then()
    .catch(handleError);
}

export function rejectTrainingApplications(trainingApplication, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectTrainingApplications",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: trainingApplication,
  })
    .then()
    .catch(handleError);
}

export function voidTrainingApplications(trainingApplication, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidTrainingApplications",
    headers: authHeader(),
    params: { reason: voidReason },
    data: trainingApplication,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledTrainingApplications(trainingApplication) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledTrainingApplications",
    headers: authHeader(),
    data: trainingApplication,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledTrainingApplications(
  trainingApplication,
  rejectReason
) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledTrainingApplications",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: trainingApplication,
  })
    .then()
    .catch(handleError);
}
