import React from "react";
import { List, Card, Checkbox, Avatar, Row, Col } from "antd";
import PropTypes from "prop-types";
import { EmpySingleList } from "./EmpySingleList";

export const EmpyApprovalList = ({
  isLoading,
  data,
  initialData,
  isSelectable = false,
  selectedItems = [],
  onItemSelected,
  selectedDetails = [],
  onDetailsSelected,
  onListActionsRendered,
  footerElement,
}) => {
  function selectItem(isSelected, key) {
    if (onItemSelected) {
      if (isSelected) {
        if (selectedItems.findIndex((x) => x.EmpyKey === key) === -1) {
          let selectedItem = data.find((x) => x.EmpyKey === key);
          let selectedDetail = initialData.filter((x) => x.EmpyKey === key);
          let selectedDets = Object.assign(
            [],
            selectedDetails.filter((x) => x.EmpyKey !== key)
          );
          selectedDetail.forEach((res) => {
            selectedDets.push(res);
          });
          onItemSelected([...selectedItems, selectedItem]);
          onDetailsSelected(selectedDets);
        }
      } else {
        onItemSelected(selectedItems.filter((x) => x.EmpyKey !== key));
        onDetailsSelected(selectedDetails.filter((x) => x.EmpyKey !== key));
      }
    }
  }

  return (
    <div>
      <Row>
        <Col
          style={{
            height: "calc(100vh - 146px)",
            overflow: "auto",
            padding: "0px",
            backgroundColor: "#e4e6eb",
          }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <List
            loading={isLoading}
            split={false}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <Card style={{ width: "100%", padding: "0" }}>
                <List.Item style={{ padding: 0 }}>
                  <Row style={{ display: "block", width: "100%" }}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      {isSelectable && (
                        <Checkbox
                          style={{
                            marginRight: 5,
                            marginTop: 10,
                            width: "20px",
                            float: "left",
                          }}
                          indeterminate={
                            selectedDetails.filter(
                              (x) => x.EmpyKey === item.EmpyKey
                            ).length &&
                            selectedDetails.filter(
                              (x) => x.EmpyKey === item.EmpyKey
                            ).length <
                              initialData.filter(
                                (res) => res.EmpyKey === item.EmpyKey
                              ).length
                          }
                          checked={
                            selectedItems.findIndex(
                              (x) => x.EmpyKey === item.EmpyKey
                            ) >= 0 ||
                            selectedDetails.filter(
                              (res) => res.EmpyKey === item.EmpyKey
                            ).length ===
                              initialData.filter(
                                (res) => res.EmpyKey === item.EmpyKey
                              ).length
                          }
                          onChange={(event) =>
                            selectItem(event.target.checked, item.EmpyKey)
                          }
                        />
                      )}
                      <List.Item.Meta
                        avatar={<Avatar size="large" src={item.EmpyPhotoUrl} />}
                        title={
                          <div style={{ color: "#0070cc" }}>
                            {item.EmpyName}
                          </div>
                        }
                      />
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <EmpySingleList
                        data={initialData.filter(
                          (res) => res.EmpyKey === item.EmpyKey
                        )}
                        selectedDetails={selectedDetails}
                        onDetailsSelected={onDetailsSelected}
                        isSelectable={true}
                        isLoading={isLoading}
                        selectedItems={selectedItems}
                        onItemSelected={onItemSelected}
                        onListActionsRendered={onListActionsRendered}
                      />
                    </Col>
                  </Row>
                </List.Item>
              </Card>
            )}
            bordered
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          {initialData.length > 0 ? (
            <div
              style={{
                fontSize: "14px",
                padding: "10px 15px",
                border: "1px solid #c3d0d9",
                backgroundColor: "#f0f3f5",
              }}
            >
              {footerElement()}
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
};

EmpyApprovalList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  initialData: PropTypes.array.isRequired,
  isSelectable: PropTypes.bool,
  selectedItems: PropTypes.array,
  selectedDetails: PropTypes.array,
  onDetailsSelected: PropTypes.func,
  onItemSelected: PropTypes.func,
  onListActionsRendered: PropTypes.func,
  footerElement: PropTypes.func,
};
