import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "claimconfg/";

export function getClaimConfg() {
  return axios({
    method: "GET",
    url: base + "GetClaimConfg",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addClaimConfg(claimConfg) {
  return axios({
    method: "POST",
    url: base + "AddClaimConfg",
    headers: authHeader(),
    data: claimConfg
  })
    .then()
    .catch(handleError);
}

export function updateClaimConfg(claimConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateClaimConfg",
    headers: authHeader(),
    params: { key: claimConfg.ClaimConfgKey },
    data: claimConfg
  })
    .then()
    .catch(handleError);
}
