import React from "react";
import { Result, Button } from "antd";
import { useTranslation } from "react-i18next";

const PageNotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle={useTranslation().t("pageNotFound.page_not_exist")}
    extra={
      <Button type="primary" href="/login">
        {useTranslation().t("general.back_to_login")}
      </Button>
    }
  />
);

export default PageNotFound;
