import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import EmployeeProfileApprovalForm from "../../forms/Profile/EmployeeProfileApprovalForm";
import { dataSourceService } from "./../../../api/employeeApi";
import { useTranslation } from "react-i18next";

export default function EmployeeProfileApprovalPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [empyAprDS, setEmpyAprDS] = useState([]);
  const [initialAprDS, setInitialAprDS] = useState([]);
  const [selectedEmpyApr, setSelectedEmpyApr] = useState([]);
  const [selectedDetails, setSelectedDetails] = useState([]);

  useEffect(getPendingEmpy, []);

  function getPendingEmpy() {
    const combinedEmpy = [];
    let prevEmpyKey = -1;
    dataSourceService
      .getAllPendingData()
      .then((value) => {
        let field = {};
        value.forEach((element, index) => {
          //check does this row of data belongs to same employee as previous one
          //assigned necessary values
          if (prevEmpyKey <= 0) {
            prevEmpyKey = element.EmpyKey;
          } else if (prevEmpyKey !== element.EmpyKey) {
            prevEmpyKey = element.EmpyKey;
            combinedEmpy.push(field);
            //clear field so that it wont changed the value pushed into combinedEmpy
            field = {};
          }
          //assign value to field constant to combined all data belongs to 1 employee together
          if (element.Category === "General Details") {
            field[element.Field] = element[element.Field];
            field[element.Field + "SubmitDate"] = element.SubmitDate;
            field["EmpyKey"] = element.EmpyKey;
            field["EmpyName"] = element.EmpyName;
            field["EmpyPhotoUrl"] = element.EmpyPhotoUrl;
            field["AprStatus"] = element.EmpyPhotoUrl;
          } else if (element.Category === "Children") {
            field["EmpyKey"] = element.EmpyKey;
            field["EmpyName"] = element.EmpyName;
            field["EmpyPhotoUrl"] = element.EmpyPhotoUrl;
            if (typeof field["childDetails"] == "undefined") {
              field["childDetails"] = [
                {
                  EmpyChildName: element.EmpyChildName,
                  EmpyChildDOB: element.EmpyChildDOB,
                  EduLevel: element.EduLevel,
                  SchName: element.SchName,
                  AprStatus: element.AprStatus,
                  EmpyAprKey: element.EmpyAprKey,
                  EmpyChildDetKey: element.EmpyChildDetKey * index,
                  SubmitDate: element.SubmitDate,
                },
              ];
            } else {
              field.childDetails.push({
                EmpyChildName: element.EmpyChildName,
                EmpyChildDOB: element.EmpyChildDOB,
                EduLevel: element.EduLevel,
                SchName: element.SchName,
                AprStatus: element.AprStatus,
                EmpyAprKey: element.EmpyAprKey,
                EmpyChildDetKey: element.EmpyChildDetKey * index,
                SubmitDate: element.SubmitDate,
              });
            }
          } else {
            field["EmpyKey"] = element.EmpyKey;
            field["EmpyName"] = element.EmpyName;
            field["EmpyPhotoUrl"] = element.EmpyPhotoUrl;
            if (typeof field["eduDetails"] == "undefined") {
              field["eduDetails"] = [
                {
                  Education: element.Education,
                  Remarks: element.Remarks,
                  AprStatus: element.AprStatus,
                  EmpyAprKey: element.EmpyAprKey,
                  EmpyEduDetKey: element.EmpyEduDetKey * index,
                  SubmitDate: element.SubmitDate,
                },
              ];
            } else {
              field.eduDetails.push({
                Education: element.Education,
                Remarks: element.Remarks,
                AprStatus: element.AprStatus,
                EmpyAprKey: element.EmpyAprKey,
                EmpyEduDetKey: element.EmpyEduDetKey * index,
                SubmitDate: element.SubmitDate,
              });
            }
          }

          //check last index
          if (index === value.length - 1) {
            combinedEmpy.push(field);
            field = {};
          }
        });
        setEmpyAprDS(combinedEmpy);
        setInitialAprDS(value);
        setIsLoading(false);
        setSelectedEmpyApr([]);
        setSelectedDetails([]);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function approveEmpyDetails(selectedEmpyDet) {
    // setIsLoading(true);

    dataSourceService
      .approveEmpy(selectedEmpyDet)
      .then(() => {
        getPendingEmpy();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function rejectEmpyDetails(selectedEmpyDet, reason) {
    // setIsLoading(true);

    dataSourceService
      .rejectEmpy(selectedEmpyDet, reason)
      .then(() => {
        getPendingEmpy();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <EmployeeProfileApprovalForm
      isLoading={isLoading}
      data={empyAprDS}
      initialData={initialAprDS}
      selectedItems={selectedEmpyApr}
      selectedDetails={selectedDetails}
      onApproved={approveEmpyDetails}
      onRejected={rejectEmpyDetails}
      onDetailsSelected={setSelectedDetails}
      onItemSelected={setSelectedEmpyApr}
    />
  );
}
