import React from "react";
import PropTypes from "prop-types";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Row, Col, Button, Divider, Alert, Select, Form } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const LoginForm = ({
  submit,
  data,
  isLoading,
  errorMessage,
  showErrMsg,
  showClient,
  isValidated,
  clientData,
  handleSubmitFailed,
  handleClose,
  enterLoading,
  essLogo,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = await form.validateFields();
    let loginData = { ...data, ...values, isValidated };
    submit(loginData);
  };

  return (
    <Row
      style={{
        backgroundImage: "linear-gradient(#edf5fa, #c3cfe2)",
        minHeight: "100vh",
      }}
    >
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 12, offset: 6 }}
        xl={{ span: 8, offset: 8 }}
        style={{}}
      >
        <div
          className="loginContainer"
          style={{
            width: "400px",
            backgroundColor: "#fff",
            borderRadius: "10px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <img
              src={essLogo}
              width="250px"
              alt="logo"
              style={{ marginTop: "50px" }}
            />
          </div>

          <div
            style={{
              marginTop: 5,
              marginBottom: 40,
              fontSize: 16,
              color: "rgba(0, 0, 0, 0.45)",
              textAlign: "center",
            }}
          ></div>

          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={handleSubmitFailed}
            className="login-form"
            style={{ maxWidth: 340, margin: "0 auto" }}
          >
            {showErrMsg ? (
              <Alert
                message={errorMessage}
                type="error"
                afterClose={handleClose}
                showIcon
                style={{ marginBottom: 24 }}
              />
            ) : null}

            <Form.Item
              name={"email"}
              rules={[
                {
                  required: true,
                  message: t("general.email_required_validation"),
                },
              ]}
              initialValue={data.email}
            >
              <Input
                size="large"
                prefix={<UserOutlined style={{ color: "#2196f3" }} />}
                placeholder={t("general.email_placeholder")}
                onChange={handleClose}
                disabled={isValidated}
              />
            </Form.Item>
            <Form.Item
              name={"password"}
              rules={[
                {
                  required: true,
                  message: t("general.password_required_validation"),
                },
              ]}
              initialValue={data.password}
            >
              <Input.Password
                type="password"
                size="large"
                prefix={<LockOutlined style={{ color: "#2196f3" }} />}
                placeholder={t("general.password_required_validation")}
                onChange={handleClose}
                disabled={isValidated}
              />
            </Form.Item>

            {showClient ? (
              <Form.Item
                label={t("general.client")}
                name={"client"}
                rules={[
                  {
                    required: true,
                    message: t("general.client_required_validation"),
                  },
                ]}
              >
                <Select
                  size="large"
                  showSearch
                  placeholder={t("general.client_placeholder")}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {clientData.map((data) => (
                    <Option key={data.Key} value={data.Key}>
                      {data.CodeDesc}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null}

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                onClick={enterLoading}
                style={{
                  width: "100%",
                  height: 40,
                  fontSize: 16,
                  marginTop: 16,
                }}
              >
                {t("general.login")}
              </Button>
            </Form.Item>

            <div style={{ display: "flow-root" }}>
              {isValidated ? (
                <a
                  className="login-form-change-user"
                  href="/login"
                  style={{ float: "right" }}
                >
                  {t("general.change_user")}
                </a>
              ) : (
                <a
                  className="login-form-forgot"
                  href="/forgotpassword"
                  style={{ float: "right" }}
                >
                  {t("general.forgot_password")}
                </a>
              )}
            </div>
            <Divider />
          </Form>
          <div style={{ textAlign: "center", paddingBottom: "25px" }}>
            {t("general.copyright")}
          </div>
        </div>
      </Col>
    </Row>
  );
};

LoginForm.propTypes = {
  submit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showErrMsg: PropTypes.bool.isRequired,
  showClient: PropTypes.bool.isRequired,
  isValidated: PropTypes.bool.isRequired,
  clientData: PropTypes.array.isRequired,
  handleSubmitFailed: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  enterLoading: PropTypes.func.isRequired,
  essLogo: PropTypes.string.isRequired,
};

export default LoginForm;
