import React, { useState } from "react";
import PropTypes from "prop-types";
import { EditButton } from "../../common/TableCols";
import { Table, Card, Spin, Row, Col, Form } from "antd";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import { SelectionInput } from "../../common/SelectionInput";
import { useTranslation } from "react-i18next";

const RoleAssignmentForm = ({
  isLoading,
  roleAssignments,
  onEdit,
  allOus,
  allDepartments,
  filterRecord,
  form,
  ouKey,
  deptKey,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isSuperAdmin = !auth
    ? false
    : auth.UserRoleKey === '1';

  const RoleAssignmentEditButton = (value, roleTypeKey) => (
    <EditButton fncEdit={() => onEdit(value)} disabled={!isSuperAdmin && roleTypeKey === 1} />
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
      width: 150,
      ...FilterGridDropdown(
        "EmpyID",
        searchText,
        handleSearch,
        handleReset,
        "Employee ID",
        t
      ),
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmpyName",
      key: "EmpyName",
      sorter: (a, b) => a.EmpyName.localeCompare(b.EmpyName),
      sortDirections: ["ascend", "descend"],
      width: 200,
      ...FilterGridDropdown(
        "EmpyName",
        searchText,
        handleSearch,
        handleReset,
        "Employee Name",
        t
      ),
    },
    {
      title: t("general.email"),
      dataIndex: "Email",
      key: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
      sortDirections: ["ascend", "descend"],
      width: 200,
      ...FilterGridDropdown(
        "Email",
        searchText,
        handleSearch,
        handleReset,
        "Email",
        t
      ),
    },
    {
      title: t("general.role"),
      dataIndex: "RoleTypeCode",
      key: "RoleTypeCode",
      sorter: (a, b) => a.RoleTypeCode.localeCompare(b.RoleTypeCode),
      sortDirections: ["ascend", "descend"],
      width: 100,
      ...FilterGridDropdown(
        "RoleTypeCode",
        searchText,
        handleSearch,
        handleReset,
        "Role",
        t
      ),
    },
    {
      title: t("general.action"),
      dataIndex: "UserKey",
      key: "EditRoleAssignment",
      align: "center",
      width: 60,
      render: (value, record) => RoleAssignmentEditButton(value, record.RoleTypeKey),
    },
  ];

  function onChangeOU(value) {
    let dept = form.getFieldValue("Dept");
    filterRecord(value.value, dept.value);
  }

  function onChangeDept(value) {
    let ou = form.getFieldValue("OU");
    filterRecord(ou.value, value.value);
  }

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Form layout="vertical" form={form}>
      <Card
        title={
          <span className="form-title">
            {t("roleAssignment.role_assignment")}
          </span>
        }
      >
        <Spin spinning={isLoading}>
          {!isLoading ? (
            <div>
              <Row gutter={24}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={t("general.operating_unit")}
                    name="OU"
                    initialValue={{ value: ouKey }}
                  >
                    <SelectionInput
                      ref={React.createRef()}
                      labelInValue={true}
                      data={allOus}
                      valueProp={"OUKey"}
                      textProp={"OUCodeOUDesc"}
                      disabledProp={"Active"}
                      placeholder={t("general.operating_unit_placeholder")}
                      onChange={onChangeOU}
                      isIncludeAllOption={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item
                    label={t("general.department")}
                    name="Dept"
                    initialValue={{ value: deptKey }}
                  >
                    <SelectionInput
                      ref={React.createRef()}
                      labelInValue={true}
                      data={allDepartments}
                      valueProp={"Key"}
                      textProp={"CodeDesc"}
                      disabledProp={"Active"}
                      placeholder={t("general.department_placeholder")}
                      onChange={onChangeDept}
                      isIncludeAllOption={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Table
                dataSource={roleAssignments}
                columns={columns}
                size="middle"
                rowKey={(record) => record.UserKey}
                pagination={{
                  defaultCurrent: currentPage,
                  onChange: tablePaginationChanged,
                }}
                scroll={{ y: "calc(100vh - 270px)" }}
              />
            </div>
          ) : null}
        </Spin>
      </Card>
    </Form>
  );
};

RoleAssignmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roleAssignments: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  allOus: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  filterRecord: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  ouKey: PropTypes.number.isRequired,
  deptKey: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default RoleAssignmentForm;
