import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavigationBlocker } from "./navigationBlocker";

export function useNavigatingAway(canShowDialogPrompt, setIsDirty) {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [showDialogPrompt, setShowDialogPrompt] = useState(false);
  const [wantToNavigateTo, setWantToNavigateTo] = useState(null);
  const [isNavigationConfirmed, setIsNavigationConfirmed] = useState(false);

  const handleNavigationBlocking = useCallback(
    (locationToNavigateTo) => {
      if (
        !isNavigationConfirmed &&
        locationToNavigateTo.location.pathname !== currentLocation.pathname
      ) {
        if (locationToNavigateTo.location.state !== null) {
          if (locationToNavigateTo.location.state.force) {
            setIsNavigationConfirmed(true);
            setShowDialogPrompt(false);
            setWantToNavigateTo(locationToNavigateTo);
            return true;
          } else {
            setShowDialogPrompt(true);
            setWantToNavigateTo(locationToNavigateTo);
            return false;
          }
        } else {
          setShowDialogPrompt(true);
          setWantToNavigateTo(locationToNavigateTo);
          return false;
        }
      }
      return true;
    },
    // eslint-disable-next-line
    [isNavigationConfirmed, currentLocation.pathname, canShowDialogPrompt]
  );

  const cancelNavigation = useCallback(() => {
    setShowDialogPrompt(false);
  }, []);

  const confirmNavigation = useCallback(() => {
    setIsNavigationConfirmed(true);
    setShowDialogPrompt(false);
  }, []);

  useEffect(() => {
    if (isNavigationConfirmed && wantToNavigateTo) {
      navigate(wantToNavigateTo.location.pathname);
      setIsNavigationConfirmed(false);
      setWantToNavigateTo(null);
      setIsDirty(false);
    }
  }, [isNavigationConfirmed, navigate, wantToNavigateTo, setIsDirty]);

  NavigationBlocker(handleNavigationBlocking, canShowDialogPrompt);

  return [showDialogPrompt, confirmNavigation, cancelNavigation];
}
