import React, { useState, useEffect } from "react";
import { Input, Row, Col, Button, Card, Spin, Form } from "antd";
import {
  StatusesBySubject
} from "../../model/common/Status";
import { NotifSubjectTable } from "../AdminPortal/NotificationSubjectTable";
import { NotifSubjectForm } from "../AdminPortal/NotificationSubjectForm";
import PropTypes from "prop-types";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

let tempNotifConfg = {
  NotifConfgKey: "0",
  UserKey: "0",
  NotifUserConfg: [],
  UpdatedBy: -1,
  UpdatedByCode: "",
  UpdatedOn: new Date(),
};

const ManageNotifConfgForm = ({
  isLoading,
  notifConfg,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formSubject] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [selectedNotifConfg, setSelectedNotifConfg] = useState(tempNotifConfg);
  const [showSubjectStatusForm, setShowSubjectStatusForm] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const formats = { cellSpacing: 20 };

  useEffect(() => {
    formSubject.resetFields();
  }, [isFormInitialized, formSubject]);

  function editNotifSubject(refKey) {
    const refNotifConfg = notifConfg.NotifUserConfg.find(item => item.Subject === refKey);

    setSelectedNotifConfg(refNotifConfg ? refNotifConfg : tempNotifConfg);
    setShowSubjectStatusForm(true);
    setIsFormInitialized(!isFormInitialized);
  }

  function saveNotifConfg(values) {
    const index = notifConfg.NotifUserConfg.findIndex(x => x.SubjectKey === values.SubjectKey);
  
    if (index !== -1) {
      const tempData = { ...notifConfg.NotifUserConfg[index] };
      const statusesIncluded = StatusesBySubject[values.SubjectKey];

      if (statusesIncluded.length > 0) {
        for (var i = 0; i < statusesIncluded.length; i++) {
          tempData[statusesIncluded[i]] = values.checkedStatusList.includes(statusesIncluded[i]) ? true : false;
        }
      }
      tempData.EmailNotification = values["EmailNotification"];

      const updatedNotifConfg = {
        ...notifConfg,
        NotifUserConfg: notifConfg.NotifUserConfg.map((item, i) => i === index ? tempData : item),
      };
  
      onDataChanged(updatedNotifConfg);
      setShowSubjectStatusForm(false);
      setIsFormInitialized(!isFormInitialized);
    }
  }

  function onCancelledEdit(){
    setShowSubjectStatusForm(false);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    setRowSelected(value);
  }

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("notifConfigForm.notification_settings")}
                </span>
              }
            >
              <Row gutter={formats.cellSpacing}>
                <Col span={12}>
                  <Form.Item
                    label={t("general.employee_name")}
                    name={"EmpyName"}
                    initialValue={
                      notifConfg.EmpyID === ""
                        ? undefined
                        : notifConfg.EmpyID + " - " + notifConfg.EmpyName
                    }
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <NotifSubjectTable
                    data={notifConfg.NotifUserConfg}
                    onEdited={editNotifSubject}
                    onRowSelected={onRowSelected}
                  />
                </Col>

                <NotifSubjectForm 
                  isVisible={showSubjectStatusForm}
                  selectedNotifConfg={selectedNotifConfg}
                  onSaved={saveNotifConfg}
                  onCancelled={onCancelledEdit}
                  setIsDirty={setIsDirty}
                  form={formSubject}
                />
              </Row>
            </Card>

            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: 10 }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={rowSelected}
              showFooter={
                rowSelected.UpdatedBy && rowSelected.UpdatedBy !== -1
                  ? true
                  : false
              }
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageNotifConfgForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  notifConfg: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageNotifConfgForm;
