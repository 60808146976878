import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "leaveconfg/";

export function getLeaveConfg() {
  return axios({
    method: "GET",
    url: base + "GetLeaveConfg",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addLeaveConfg(leaveConfg) {
  return axios({
    method: "POST",
    url: base + "AddLeaveConfg",
    headers: authHeader(),
    data: leaveConfg,
  })
    .then()
    .catch(handleError);
}

export function updateLeaveConfg(leaveConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateLeaveConfg",
    headers: authHeader(),
    params: { key: leaveConfg.LeaveConfgKey },
    data: leaveConfg,
  })
    .then()
    .catch(handleError);
}

export function getOffRestDay(userKey = -1, empyKey = -1) {
  return axios({
    method: "GET",
    url: base + "GetOffRestDay",
    headers: authHeader(),
    params: { userKey: userKey, empyKey: empyKey },
  })
    .then(handleResponse)
    .catch(handleError);
}
