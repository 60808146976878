export default function AnnouncementModel({
  AnnouncementKey = -1,
  AnnouncementTitle = "",
  AnnouncementDesc = "",
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
  attaches = [],

  publishedBy = -1,
  publishedByCode = "",
  publishedDate = new Date(),
  status = 0,
}) {
  return Object.freeze({
    AnnouncementKey: AnnouncementKey,
    AnnouncementTitle: AnnouncementTitle,
    AnnouncementDesc: AnnouncementDesc,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
    Attaches: attaches,
    PublishedBy: publishedBy,
    PublishedByCode: publishedByCode,
    PublishedDate: publishedDate,
    Status: status,
  });
}
