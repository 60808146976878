import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageClinicForm from "../../forms/Master/ManageClinicForm";
import PropTypes from "prop-types";
import ClinicModel from "../../model/Clinic/ClinicModel";
import { getClinic, addClinic, updateClinic } from "../../../api/clinicApi";
import * as compapi from "../../../api/companyApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newClinic = ClinicModel({});

export default function ManageClinicPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [clinic, setClinic] = useState(newClinic);
  const [isLoading, setIsLoading] = useState(true);
  const { ClinicKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    promises.push(compapi.getCompany());
    let editKey = ClinicKey;
    if (editKey > 0) {
      promises.push(getClinic(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        values[1]
          ? (values[1]["Company"] = values[0])
          : (newClinic.Company.length = 0);
        setClinic(newClinic);
        values[0].map((x) => newClinic["Company"].push(x));

        if (values[1]) {
          if (values[1].UpdatedDate) {
            values[1].UpdatedOn = values[1].UpdatedDate;
          }
          if (values[1].CreatedDate) {
            values[1].CreatedOn = values[1].CreatedDate;
          }
        }

        setClinic(values[1] ? values[1] : newClinic);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [ClinicKey]);

  function saveClinic(values) {
    let clinicSubmit = {
      ...clinic,
      ...values,
      ClinicCode: values.ClinicCode.trim(),
      ClinicDesc: values.ClinicDesc.trim(),
      ClinicName: values.ClinicName.trim(),
      Prefix: values.Prefix.trim(),
    };

    let upsertClinic = clinicSubmit.ClinicKey !== -1 ? updateClinic : addClinic;
    setIsLoading(true);
    upsertClinic(clinicSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/clinics", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          clinicSubmit.ClinicKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputClinic() {
    history("/home/clinics", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageClinicForm
      isLoading={isLoading}
      clinic={clinic}
      onSubmitted={saveClinic}
      onCancelled={cancelInputClinic}
      onDataChanged={setClinic}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageClinicPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
