import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import PropMaintApprovalForm from "../../forms/PropMaint/PropMaintApprovalForm";
import {
  getPendingAndApprovedPropMaints,
  approvePropMaints,
  rejectPropMaints,
} from "../../../api/propMaintApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function PropMaintApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [propMaints, setPropMaints] = useState([]);
  const [selectedPropMaints, setSelectedPropMaints] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  useEffect(getPropMaints, [currentYear]);

  function getPropMaints() {
    setIsLoading(true);
    getPendingAndApprovedPropMaints(currentYear)
      .then((value) => {
        setPropMaints(value ? value : []);
        setSelectedPropMaints([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewPropMaint() {
    history("/home/propmaint/proptrans");
  }

  function onApprovePropMaints(selectedPropMaints) {
    setIsLoading(true);

    approvePropMaints(selectedPropMaints)
      .then(() => {
        getPropMaints();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectPropMaints(selectedPropMaints, reason, isAllowAmend) {
    setIsLoading(true);

    rejectPropMaints(selectedPropMaints, reason, isAllowAmend)
      .then(() => {
        getPropMaints();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewPropMaint(propMaintKey) {
    history("/home/propmaint/proptrans/" + propMaintKey, {
      state: { fromApproval: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <PropMaintApprovalForm
      isLoading={isLoading}
      data={propMaints}
      selectedItems={selectedPropMaints}
      onAdd={onAddNewPropMaint}
      onView={viewPropMaint}
      onApproved={onApprovePropMaints}
      onRejected={onRejectPropMaints}
      onItemSelected={setSelectedPropMaints}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
}

PropMaintApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
