import React from "react";
import { Table } from "antd";
import { EditButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const RoleTypesPermissionByFormTable = ({ data, onEdited }) => {
  const { t } = useTranslation();

  const RoleTypesPermissionActionButtons = (value, record) =>
    record.menuUrl !== "" && record.name !== "Report" ? (
      <EditButton fncEdit={() => onEdited(value)} />
    ) : null;

  const columns = [
    {
      title: t("general.name"),
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: t("general.action"),
      dataIndex: "key",
      key: "editRoleTypesPermissionByForm",
      align: "center",
      width: 100,
      render: RoleTypesPermissionActionButtons,
    },
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        size="middle"
        pagination={false}
        rowKey={(record) => record.key}
      />
    </div>
  );
};

RoleTypesPermissionByFormTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdited: PropTypes.func.isRequired,
};
