import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { SettingsForm } from "../../forms/Master/SettingsForm";
import {
  getLeaveConfg,
  addLeaveConfg,
  updateLeaveConfg,
} from "../../../api/leaveConfgApi";
import {
  getClaimConfg,
  addClaimConfg,
  updateClaimConfg,
} from "../../../api/claimConfgApi";
import {
  getClinicConfg,
  addClinicConfg,
  updateClinicConfg,
} from "../../../api/clinicConfgApi";
import {
  getAnnouncementConfg,
  addAnnouncementConfg,
  updateAnnouncementConfg,
} from "../../../api/announcementConfgApi";
import {
  getTravelConfg,
  addTravelConfg,
  updateTravelConfg,
} from "../../../api/travelConfgApi";
import ClaimConfgModel from "../../model/Claim/ClaimConfgModel";
import ClinicConfgModel from "../../model/Clinic/ClinicConfgModel";
import TravelConfgModel from "../../model/Claim/Travel/TravelConfgModel";
import LeaveConfgModel from "../../model/Leave/LeaveConfgModel";
import AnnouncementConfgModel from "../../model/Announcement/AnnouncementConfgModel";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newClaimConfg = ClaimConfgModel({});
let newLeaveConfg = LeaveConfgModel({});
let newClinicConfg = ClinicConfgModel({});
let newTravelConfg = TravelConfgModel({});
let newAnnouncementConfg = AnnouncementConfgModel({});

export default function SettingsPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leaveConfg, setLeaveConfg] = useState(newLeaveConfg);
  const [claimConfg, setClaimConfg] = useState(newClaimConfg);
  const [clinicConfg, setClinicConfg] = useState(newClinicConfg);
  const [travelConfg, setTravelConfg] = useState(newTravelConfg);
  const [announcementConfg, setAnnouncementConfg] =
    useState(newAnnouncementConfg);

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(getConfigs, []);

  function getConfigs() {
    let promises = [
      getLeaveConfg(),
      getClaimConfg(),
      getClinicConfg(),
      getTravelConfg(),
      getAnnouncementConfg(),
    ];

    Promise.all(promises)
      .then((values) => {
        setLeaveConfg(values[0] ? values[0] : newLeaveConfg);
        setClaimConfg(values[1] ? values[1] : newClaimConfg);
        setClinicConfg(values[2] ? values[2] : newClinicConfg);
        setTravelConfg(values[3] ? values[3] : newTravelConfg);
        setAnnouncementConfg(values[4] ? values[4] : newAnnouncementConfg);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function saveSettings(values) {
    setIsLoading(true);

    let leaveConfgSubmit = {
      ...leaveConfg,
      CutOffDay: values.LeaveCutOffDay ? values.LeaveCutOffDay : 28,
      AllowHalfDay: values.LeaveAllowHalfDay ? values.LeaveAllowHalfDay : false,
      IsValidateTrans: values.LeaveIsValidateTrans
        ? values.LeaveIsValidateTrans
        : false,
      IsPostULToQuarto: values.LeaveIsPostULToQuarto
        ? values.LeaveIsPostULToQuarto
        : false,
    };

    let claimConfgSubmit = {
      ...claimConfg,
      CutOffDay: values.ClaimCutOffDay ? values.ClaimCutOffDay : 28,
      LimitResetMth: values.ClaimLimitResetMth ? values.ClaimLimitResetMth : 1,
      IsGroupByProj: values.ClaimIsGroupByProj
        ? values.ClaimIsGroupByProj
        : false,
      IsMultiCurrency: values.ClaimIsMultiCurrency
        ? values.ClaimIsMultiCurrency
        : false,
      IsValidateTrans: values.ClaimIsValidateTrans
        ? values.ClaimIsValidateTrans
        : false,
      IsPostToQuarto: values.ClaimIsPostToQuarto
        ? values.ClaimIsPostToQuarto
        : false,
      IsProrated: values.ClaimIsProrated, //default set to true in input already
      ProrateDateBased: values.ClaimProrateDateBased
        ? values.ClaimProrateDateBased
        : "H",
    };

    let clinicConfgSubmit = {
      ...clinicConfg,
      IsValidateTrans: values.ClinicIsValidateTrans
        ? values.ClinicIsValidateTrans
        : false,
    };

    let travelConfgSubmit = {
      ...travelConfg,
      IsAllowOverClaim: values.IsAllowOverClaim
        ? values.IsAllowOverClaim
        : false,
      IsValidateTrans: values.TravelIsValidateTrans
        ? values.TravelIsValidateTrans
        : false,
    };

    let announcementConfgSubmit = {
      ...announcementConfg,
      EmailNotification: values.AnnouncementEmailNotification
        ? values.AnnouncementEmailNotification
        : false,
    };

    let upsertLeaveConfg =
      leaveConfgSubmit.LeaveConfgKey !== -1 ? updateLeaveConfg : addLeaveConfg;

    let upsertClaimConfg =
      claimConfgSubmit.ClaimConfgKey !== -1 ? updateClaimConfg : addClaimConfg;

    let upsertClinicConfg =
      clinicConfgSubmit.ClinicConfgKey !== -1
        ? updateClinicConfg
        : addClinicConfg;

    let upsertTravelConfg =
      travelConfgSubmit.TravelConfgKey !== -1
        ? updateTravelConfg
        : addTravelConfg;

    let upsertAnnouncementConfg =
      announcementConfgSubmit.AnnouncementConfgKey !== -1
        ? updateAnnouncementConfg
        : addAnnouncementConfg;

    let promises = [
      upsertLeaveConfg(leaveConfgSubmit),
      upsertClaimConfg(claimConfgSubmit),
      upsertClinicConfg(clinicConfgSubmit),
      upsertTravelConfg(travelConfgSubmit),
      upsertAnnouncementConfg(announcementConfgSubmit),
    ];

    Promise.all(promises)
      .then(() => {
        getConfigs();
        toast.success(t("general.record_saved_successfully"));
        setIsDirty(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_save_record"), error);
      });
  }

  function cancelInputSettings() {
    history("/home");
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <SettingsForm
      isLoading={isLoading}
      onSubmitted={saveSettings}
      onCancelled={cancelInputSettings}
      claimConfg={claimConfg}
      leaveConfg={leaveConfg}
      clinicConfg={clinicConfg}
      travelConfg={travelConfg}
      announcementConfg={announcementConfg}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

SettingsPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
