import React, { useState } from "react";
import {
  CheckCircleOutlined,
  ShareAltOutlined,
  SlidersOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import {
  Modal,
  InputNumber,
  Row,
  Col,
  Input,
  Select,
  Table,
  Button,
  Alert,
  Switch,
  Form,
} from "antd";
import PropTypes from "prop-types";
import AppraisalTemplateSubDetailModel from "../../model/AppraisalTemplate/AppraisalTemplateSubDetailModel";
import { RowState } from "../../model/common/RowState";
import { DeleteButton } from "../../common/TableCols";
import { RecordType } from "../../model/common/RecordType";
import { useTranslation } from "react-i18next";

const ManageAppraisalTemplateQuestionForm = ({
  isVisible,
  question,
  onQuestionChanged,
  onCancel,
  inUsed,
  onSave,
  form,
}) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const newAnswer = AppraisalTemplateSubDetailModel({});
  const [showErorrMsg, setshowErorrMsg] = useState(false);

  const iconEnum = {
    1: <CheckCircleOutlined />,
    2: <ShareAltOutlined />,
    3: <SlidersOutlined />,
    4: <CheckSquareOutlined />,
  };

  const AnswerIcon = (value, record) => (
    <Row>
      <Col span={24}>{iconEnum[record.ATSubDetType]}</Col>
    </Row>
  );

  const AnswerAction = (value, record) => (
    <Row>
      <Col
        span={24}
        style={{
          display: record.RcdType === RecordType.USER && !inUsed ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteAnswer(value)} />
      </Col>
    </Row>
  );

  const sectionAnswerColumns = [
    {
      title: t("general.type"),
      dataIndex: "ATSubDetKey",
      key: "QuestionIcon",
      align: "center",
      width: 30,
      hidden: false,
      render: AnswerIcon,
    },
    {
      title: "",
      dataIndex: "ATSubDetSeqNo",
      key: "ATSubDetSeqNo",
      align: "center",
      width: 10,
      hidden: true,
    },
    {
      title: t("appraisalTemplateForm.answer"),
      dataIndex: "ATSubDetDesc",
      key: "ATSubDetDesc",
      editable: true,
      hidden: false,
    },
    {
      title: t("general.action"),
      dataIndex: "ATSubDetKey",
      key: "AnswerActions",
      align: "center",
      width: 50,
      hidden: false,
      render: AnswerAction,
    },
  ];

  const EditableContext = React.createContext();

  const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
      <tr {...props} />
    </EditableContext.Provider>
  );

  const EditableFormRow = EditableRow;

  class EditableCell extends React.Component {
    state = {
      editing: false,
    };

    toggleEdit = () => {
      const editing = !this.state.editing;
      this.setState({ editing }, () => {
        if (editing) {
          this.input.focus();
        }
      });
    };

    save = () => {
      const { record, handleSaveAnswer } = this.props;
      form
        .validateFields()
        .then((values) => {
          this.toggleEdit();
          handleSaveAnswer({ ...record, ...values });
        })
        .catch(() => {});
    };

    renderCell = (form) => {
      this.form = form;
      const { children, dataIndex, record, title } = this.props;
      const { editing } = this.state;
      return editing ? (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `${title} ` + t("appraisalTemplateForm.is_required"),
            },
            {
              max: 500,
              message: t("appraisalTemplateForm.answer_max_length_validation"),
            },
          ]}
          initialValue={record[dataIndex]}
        >
          <Input
            ref={(node) => (this.input = node)}
            onPressEnter={this.save}
            onBlur={this.save}
            maxLength={500}
          />
        </Form.Item>
      ) : (
        <div
          className="editable-cell-value-wrap"
          style={{ paddingRight: 24 }}
          onClick={this.toggleEdit}
        >
          {children}
        </div>
      );
    };

    render() {
      const {
        editable,
        dataIndex,
        title,
        record,
        index,
        handleSaveAnswer,
        children,
        ...restProps
      } = this.props;
      return (
        <td {...restProps}>
          {editable ? (
            <EditableContext.Consumer>
              {this.renderCell}
            </EditableContext.Consumer>
          ) : (
            children
          )}
        </td>
      );
    }
  }

  const ansTableComponents = {
    body: {
      row: EditableFormRow,
      cell: EditableCell,
    },
  };

  function saveForm(e) {
    form
      .validateFields()
      .then((values) => {
        let gotErrors = false;
        if (
          form.getFieldValue("ATDetType") === 1 ||
          form.getFieldValue("ATDetType") === 4
        ) {
          if (getFilterAppraisalTemplateAnswerData().length === 0) {
            gotErrors = true;
            setshowErorrMsg(true);
          }
        }

        if (!gotErrors) {
          let questionSubmit = {
            ...question,
            ...{
              ATDetTitle: values.ATDetTitle ? values.ATDetTitle : "",
              ATDetSubTitle: values.ATDetSubTitle ? values.ATDetSubTitle : "",
              ATDetWeight: values.ATDetWeight ? values.ATDetWeight : 0,
              ATDetType: values.ATDetType ? values.ATDetType : 1,
              ATDetComment: values.ATDetComment ? values.ATDetComment : false,
              ATDetInd: "D",
            },
          };
          onSave(questionSubmit);
        }
      })
      .catch(() => {});
  }

  function tips() {
    let tip = "";

    if (question.ATDetType === 1) {
      tip = t("appraisalTemplateForm.multiple_choices_radio");
    } else if (question.ATDetType === 2) {
      tip = t("appraisalTemplateForm.either_yes_or_no");
    } else if (question.ATDetType === 3) {
      tip = t("appraisalTemplateForm.scale_1_to_10");
    } else if (question.ATDetType === 4) {
      tip = t("appraisalTemplateForm.multiple_choices_checkbox");
    }

    return tip;
  }

  function getFilterAppraisalTemplateAnswerData() {
    let count = 0;
    let filterData = question.AppraisalSubDetail.filter(
      (x) => x.RowState !== RowState.DELETED
    ).map((x) => {
      count++;
      return { ...x, ATSubDetSeqNo: count };
    });

    return filterData.reverse();
  }

  function deleteAnswer(aTSubDetKey) {
    //change state to DELETED for removal in db
    let newData = question.AppraisalSubDetail.filter(
      //exclude those new record which is added and deleted before save the form
      (y) =>
        !(
          (y.ATSubDetKey === aTSubDetKey || y.ATSubDetKey === aTSubDetKey) &&
          y.RowState === RowState.ADDED
        )
    ).map((x) => {
      return (x.ATSubDetKey === aTSubDetKey || x.ATSubDetKey === aTSubDetKey) &&
        x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    });
    onQuestionChanged({ ...question, AppraisalSubDetail: newData });
  }

  function addNewAnswer() {
    setshowErorrMsg(false);
    onQuestionChanged({
      ...question,
      AppraisalSubDetail: [
        ...question.AppraisalSubDetail,
        { ...newAnswer, ATSubDetType: form.getFieldValue("ATDetType") },
      ],
    });
  }

  function handleSaveAnswer(row) {
    const newData = question.AppraisalSubDetail.filter(
      (x) => x.RowState !== RowState.DELETED
    );
    const index = newData.findIndex(
      (item) => row.ATSubDetKey === item.ATSubDetKey
    );
    const item = newData[index];

    row.RowState = RowState.MODIFIED;

    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    onQuestionChanged({
      ...question,
      AppraisalSubDetail: newData,
    });
  }

  function handleChangeQuestionType(value) {
    onQuestionChanged({
      ...question,
      AppraisalSubDetail: [],
      ATDetType: value,
    });
  }

  const columns = sectionAnswerColumns.map((col) => {
    if (!col.editable) {
      if (question.ATDetType === 1) {
        return { ...col, hidden: false };
      }
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSaveAnswer: handleSaveAnswer,
      }),
    };
  });

  function handleClose() {
    setshowErorrMsg(false);
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">
          {t("appraisalTemplateForm.criteria")}
        </span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      afterClose={handleClose}
      width="800px"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("appraisalTemplateForm.criteria")}
          name="ATDetTitle"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("appraisalTemplateForm.criteria_required_validation"),
            },
            {
              max: 500,
              message: t(
                "appraisalTemplateForm.criteria_max_length_validation"
              ),
            },
          ]}
          initialValue={question.ATDetTitle}
        >
          <Input
            placeholder={t("appraisalTemplateForm.criteria_placeholder")}
          />
        </Form.Item>
        <Form.Item
          label={t("general.description")}
          name="ATDetSubTitle"
          rules={[
            {
              max: 500,
              message: t("general.description_max_length_500_validation"),
            },
          ]}
          initialValue={question.ATDetSubTitle}
        >
          <Input placeholder={t("general.description_placeholder")} />
        </Form.Item>
        <Row>
          <Col span={5}>
            <Form.Item
              label={t("appraisalTemplateForm.relative_weight")}
              name="ATDetWeight"
              rules={[
                {
                  required: true,
                  message: t(
                    "appraisalTemplateForm.relative_weight_required_validation"
                  ),
                },
              ]}
              initialValue={question.ATDetWeight}
            >
              <InputNumber
                step="1"
                precision={0}
                min={1}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label={t("general.type")}
              help={tips()}
              name="ATDetType"
              initialValue={question.ATDetType}
            >
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
                onChange={handleChangeQuestionType}
                disabled={inUsed}
                dropdownMatchSelectWidth={false}
              >
                <Option value={1}>
                  {t("appraisalTemplateForm.multiple_options")}
                </Option>
                <Option value={2}>{t("appraisalTemplateForm.yes_no")}</Option>
                <Option value={3}>
                  {t("appraisalTemplateForm.subjective")}
                </Option>
                <Option value={4}>
                  {t("appraisalTemplateForm.multiple_selection")}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              style={{ textAlign: "right", margin: 0 }}
              name="ATDetComment"
              valuePropName="checked"
              initialValue={question.ATDetComment}
            >
              <Switch
                checkedChildren={t(
                  "appraisalTemplateForm.show_comment_section"
                )}
                unCheckedChildren={t(
                  "appraisalTemplateForm.hide_comment_section"
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display:
              form.getFieldValue("ATDetType") === 1 ||
              form.getFieldValue("ATDetType") === 4
                ? "block"
                : "none",
          }}
        >
          <Button
            type="primary"
            style={{ marginBottom: 16, display: !inUsed ? "" : "none" }}
            onClick={() => addNewAnswer()}
          >
            {t("appraisalTemplateForm.add_an_answer")}
          </Button>
          <div>
            <Table
              dataSource={getFilterAppraisalTemplateAnswerData()}
              columns={columns.filter((x) => x.hidden === false)}
              size="middle"
              pagination={false}
              rowKey={(record) => record.ATSubDetKey}
              bordered
              rowClassName={() => "editable-row"}
              components={ansTableComponents}
            />
          </div>
        </Row>
        {showErorrMsg ? (
          <Alert
            message={t("appraisalTemplateForm.multiple_choices_validation")}
            type="error"
            afterClose={handleClose}
            closable
            showIcon
            closeText={t("general.close_now")}
          />
        ) : null}
      </Form>
    </Modal>
  );
};

ManageAppraisalTemplateQuestionForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  onQuestionChanged: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  inUsed: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageAppraisalTemplateQuestionForm;
