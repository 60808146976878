import React, { useState, useEffect } from "react";
import * as restDayProfileApi from "../../../api/restDayProfileApi";
import { toast } from "react-toastify";
import { RestDayProfileForm } from "../../forms/Master/RestDayProfileForm";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function RestDayProfilePage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [restDayProfiles, setRestDayProfiles] = useState([]);
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getRestDayProfiles, []);

  function getRestDayProfiles() {
    restDayProfileApi
      .getAllRestDayProfiles()
      .then((result) => {
        setIsLoading(false);
        setRestDayProfiles(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setRestDayProfiles([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function editItem(profile) {
    history("/home/restdayprofiles/restdayprofile/" + profile.UserKey, {
      state: { currentPage: currentPage },
    });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  return (
    <RestDayProfileForm
      isLoading={isLoading}
      restDayProfiles={restDayProfiles}
      onEdit={editItem}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

RestDayProfilePage.propTypes = {
  history: PropTypes.func.isRequired,
};
