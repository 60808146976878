import React, { useState, useEffect } from "react";
import { Modal, Input, InputNumber, DatePicker, Row, Col, Form } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const MedicalAmountDeductionInput = ({
  isVisible,
  onSaved,
  onCancelled,
  selectedData,
  userCompCurr,
  allClaimTypes,
  getMedicalClaimTypeLimit,
}) => {
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);
  const [claimType, setClaimType] = useState();
  const [medicalClaimLimit, setMedicalClaimLimit] = useState(-1);
  const [medicalClaimUsage, setMedicalClaimUsage] = useState(0);

  useEffect(() => {
    form.resetFields();
    if (!isVisible) return;
    setIsSaving(false);
    setClaimType();
    setMedicalClaimLimit(-1);
    setMedicalClaimUsage(0);
  }, [isVisible, form]);

  function onChangeClaimType(newClaimType) {
    setClaimType(newClaimType);
    if (newClaimType) {
      getMedicalClaimTypeLimit(
        newClaimType.key,
        selectedData.UserKey,
        selectedData.EmpyKey,
        selectedData.ClinicApplicationDate
      ).then((value) => {
        setMedicalClaimLimit(value[0] ? value[0] : -1);
        setMedicalClaimUsage(value[1] ? value[1] : 0);
      });
    }
  }

  function saveForm() {
    setIsSaving(true);
    form
      .validateFields()
      .then((values) => {
        if (values.ClaimType) {
          values.ClaimTypeKey = values.ClaimType.key;
        }

        if (!isSaving) onSaved(values);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("clinicVisitForm.medical_amount_deduction")}
      okText={t("general.submit")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("general.document_no")}
          name="DocNum"
          initialValue={selectedData.DocNum}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label={t("general.clinic")}
          name="Clinic"
          initialValue={
            selectedData.ClinicCode + " - " + selectedData.ClinicName
          }
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label={t("general.employee_name")}
          name="UserCode"
          initialValue={selectedData.UserCode}
        >
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label={t("general.claim_type")}
          name="ClaimType"
          rules={[
            {
              required: true,
              message: t("general.claim_type_required_validation"),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !allClaimTypes.some((x) => x.ClaimTypeKey === value.value)
                ) {
                  return Promise.reject(
                    new Error(t("general.invalid_claim_type"))
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={
            selectedData.ClaimTypeKey > 0
              ? {
                  key: selectedData.ClaimTypeKey,
                  value: selectedData.ClaimTypeKey,
                  label:
                    selectedData.ClaimTypeCode +
                    " - " +
                    selectedData.ClaimTypeDesc,
                }
              : allClaimTypes.length === 1
              ? {
                  key: allClaimTypes[0].ClaimTypeKey,
                  value: allClaimTypes[0].ClaimTypeKey,
                  label:
                    allClaimTypes[0].ClaimTypeCode +
                    " - " +
                    allClaimTypes[0].ClaimTypeDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            ref={React.createRef()}
            labelInValue={true}
            data={allClaimTypes}
            valueProp={"ClaimTypeKey"}
            textProp={"ClaimTypeCodeDesc"}
            disabledProp={"Active"}
            placeholder={t("general.claim_type_placeholder")}
            disabled={allClaimTypes.length === 1}
            onChange={(value) => onChangeClaimType(value)}
          />
        </Form.Item>

        <p
          style={{
            display: claimType && claimType.key > 0 ? "" : "none",
          }}
        >
          {<i>{t("general.usage")}</i>}:{" "}
          {medicalClaimUsage
            ? medicalClaimUsage.toLocaleString(regionLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            : 0}
          /
          {!medicalClaimLimit || medicalClaimLimit === -1
            ? " - "
            : medicalClaimLimit === 0
            ? t("general.unlimited")
            : medicalClaimLimit.toLocaleString(regionLocale(), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
        </p>

        <Form.Item
          label={t("general.receipt_no")}
          name="ReceiptNo"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("general.receipt_no_required_validation"),
            },
            {
              max: 250,
              message: t("general.receipt_no_max_length_validation"),
            },
          ]}
          initialValue={selectedData.ReceiptNo}
        >
          <Input placeholder={t("general.receipt_no_placeholder")} />
        </Form.Item>
        <Row gutter={20}>
          <Col xs={24} lg={12}>
            <Form.Item
              label={t("general.visit_date")}
              name="VisitDate"
              rules={[
                {
                  required: true,
                  message: t("general.clinic_visit_date_required_validation"),
                },
              ]}
              initialValue={
                dayjs(
                  dayjs(new Date(selectedData.VisitDate)).format("DD/MM/YYYY"),
                  "DD/MM/YYYY",
                  true
                ).isValid()
                  ? dayjs(new Date(selectedData.VisitDate))
                  : dayjs(new Date())
              }
            >
              <DatePicker
                format="DD/MM/YYYY"
                placeholder={t("general.clinic_visit_placeholder")}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              label={
                t("general.amount") +
                (userCompCurr && userCompCurr.CurrCode
                  ? " (" + userCompCurr.CurrCode + ")"
                  : "")
              }
              name="Amount"
              rules={[
                {
                  required: true,
                  message: t("general.amount_required_validation"),
                },
              ]}
              initialValue={selectedData.Amount}
            >
              <InputNumber
                prefix={userCompCurr.CurrSymb ? userCompCurr.CurrSymb : ""}
                formatter={inputLocaleFormatter(
                  precisionDecimal(userCompCurr.CurrCode)
                )}
                parser={inputLocaleParser(
                  precisionDecimal(userCompCurr.CurrCode)
                )}
                placeholder="0.00"
                step={stepPrecision(userCompCurr.CurrCode)}
                precision={precisionDecimal(userCompCurr.CurrCode)}
                min={0}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

MedicalAmountDeductionInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  selectedData: PropTypes.object.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  allClaimTypes: PropTypes.array.isRequired,
  getMedicalClaimTypeLimit: PropTypes.func.isRequired,
};

export const MedicalAmountDeductionModal = MedicalAmountDeductionInput;
