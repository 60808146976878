import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidVehicleRequisitions,
  voidVehicleRequisitions,
} from "../../../api/vehicleRequisitionApprovalApi";
import ApprovedVehicleRequisitionVoidForm from "../../forms/VehicleReq/ApprovedVehicleRequisitionVoidForm";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function ApprovedVehicleRequisitionVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleRequisitions, setVehicleRequisitions] = useState([]);
  const [selectedVehicleRequisitions, setSelectedVehicleRequisitions] =
    useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getVehicleRequisitions, [currentYear]);

  function getVehicleRequisitions() {
    setIsLoading(true);
    getApprovedAndVoidVehicleRequisitions(currentYear)
      .then((value) => {
        setVehicleRequisitions(value ? value : []);
        setSelectedVehicleRequisitions([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewVehicleRequisition() {
    history("/home/vehiclerequisition/vehicletrans");
  }

  function onVoidVehicleRequisitions(selectedVehicleRequisitions, reason) {
    setIsLoading(true);

    voidVehicleRequisitions(selectedVehicleRequisitions, reason)
      .then(() => {
        getVehicleRequisitions();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewVehicleRequisitions(vehicleRequisitionKey) {
    history("/home/vehiclerequisition/vehicletrans/" + vehicleRequisitionKey, {
      state: { fromVoid: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ApprovedVehicleRequisitionVoidForm
      isLoading={isLoading}
      data={vehicleRequisitions}
      selectedItems={selectedVehicleRequisitions}
      onAdd={onAddNewVehicleRequisition}
      onView={viewVehicleRequisitions}
      onVoided={onVoidVehicleRequisitions}
      onItemSelected={setSelectedVehicleRequisitions}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

ApprovedVehicleRequisitionVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
