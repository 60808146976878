import { handleResponse, handleError, authHeader } from "./apiUtils";
import Axios from "axios";
import { API_ROOT } from "./api-config";

const defaultURL = API_ROOT + "holidayprofile/";
const basicURL = API_ROOT;

export const dataSourceService = {
  getHdrDataSource,
  deleteHolidayProfile,
  getHolidayList,
  getOU,
  saveHolidayDet,
  getHolidayHdr,
};

function getHdrDataSource(source) {
  return Axios.get(defaultURL + "GetAllHolidayProfile", {
    headers: authHeader(),
    cancelToken: source.token,
    // cancelToken: new CancelToken(function executor(c) {
    //   // setCancelToken(c);
    //   setCancelToken(function() {
    //     setLoad(true);
    //     return c;
    //   });
    //   // getCancel(c);
    // })
  }).then((res) => {
    return typeof res.data.value == "undefined" ? res.data : res.data.value;
  });
}

function deleteHolidayProfile(ProfileKey) {
  return Axios.delete(defaultURL + "DeleteRecord", {
    headers: authHeader(),
    params: { ProfileKey: ProfileKey },
  })
    .then()
    .catch(handleError);
}

function getHolidayHdr(ProfileKey) {
  return Axios.get(defaultURL + "GetHolidayProfile", {
    headers: authHeader(),
    params: { ProfileKey: ProfileKey },
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getHolidayList(ProfileKey, source) {
  return Axios.get(defaultURL + "GetHolidayProfileDet", {
    headers: authHeader(),
    params: { ProfileKey: ProfileKey },
    cancelToken: source.token,
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getOU(source) {
  return Axios.get(basicURL + "ou/GetAllOU", {
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function saveHolidayDet(data, source) {
  return Axios.post(defaultURL + "SaveHoliday", data, {
    headers: authHeader(),
    cancelToken: source.token,
  }).catch(handleError);
}

export function getOffHolidayProfile(empyKey = -1) {
  return Axios({
    method: "GET",
    url: defaultURL + "GetOffHolidayProfile",
    headers: authHeader(),
    params: { empyKey: empyKey },
  })
    .then(handleResponse)
    .catch(handleError);
}
