export default function VehicleModel({
  VehicleKey = -1,
  VehicleCode = "",
  VehicleDesc = "",
  OURefKey = -1,
  VehRefKey = -1,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null
}) {
  return Object.freeze({
    VehicleKey: VehicleKey,
    VehicleCode: VehicleCode,
    VehicleDesc: VehicleDesc,
    OURefKey: OURefKey,
    VehRefKey: VehRefKey,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion
  });
}
