export default function AppraisalTemplateHeaderModel({
  ATKey = -1,
  ATCode = "",
  ATDesc = "",
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
  AppraisalTemplateDet = [],
}) {
  return Object.freeze({
    ATKey: ATKey,
    ATCode: ATCode,
    ATDesc: ATDesc,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
    AppraisalTemplateDet: AppraisalTemplateDet,
  });
}
