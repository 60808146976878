import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageTrainingForm } from "../../forms/Master/ManageTrainingForm";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import TrainingModel from "../../model/Training/TrainingModel";
import TrainingApplicationModel from "../../model/Training/TrainingApplicationModel";
import {
  getTraining,
  addTraining,
  updateTraining,
} from "../../../api/trainingApi";
import { applyTraining } from "../../../api/trainingApplicationApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { getCompany } from "../../../api/companyApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newTraining = TrainingModel({});

export default function ManageTrainingPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [training, setTraining] = useState(newTraining);
  const [fileList, setFileList] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noOfApplicants, setNoOfApplicants] = useState(1);
  const location = useLocation();
  //fromList -> click from training application list, fromTrans -> click from inside transaction
  const isFromApplication = location.state && location.state.fromApplication 
    ? location.state.fromApplication 
    : false;
  const isShowOUPerms = location.state && location.state.fromSetup 
    ? location.state.fromSetup 
    : false;
  const isViewOnly =
    location.state &&
    (location.state.fromApplication ||
      location.state.fromList ||
      location.state.fromTrans)
      ? true
      : false;
  const isShowTotalParticipants =
    location.state &&
    (location.state.fromApproval ||
      location.state.fromVoid ||
      location.state.fromCancel ||
      location.state.fromAdmin)
      ? true
      : false;

  const isShowAvailable = location.state
    ? location.state.isShowAvailable
    : true;
  const trainingYear =
    location.state && location.state.trainingYear
      ? dayjs(location.state.trainingYear)
      : dayjs();
  const { TrainingKey } = useParams();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let editKey = TrainingKey;
    let promises = [getCompany()];

    if (editKey > 0) {
      promises.push(getTraining(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setCompanies(values[0] ? values[0] : []);
        let trainingToEdit = values[1];

        if (trainingToEdit) {
          if (trainingToEdit.UpdatedDate) {
            trainingToEdit.UpdatedOn = values[1].UpdatedDate;
          }
          if (trainingToEdit.CreatedDate) {
            trainingToEdit.CreatedOn = values[1].CreatedDate;
          }

          trainingToEdit.TrainingDate = [
            dayjs(trainingToEdit.TrainingFromDate),
            dayjs(trainingToEdit.TrainingToDate),
          ];
          trainingToEdit.ApplicationDate = [
            dayjs(trainingToEdit.ApplicationStartDate),
            dayjs(trainingToEdit.ApplicationEndDate),
          ];

          setNoOfApplicants(
            trainingToEdit.NoOfApplicants > 0
              ? trainingToEdit.NoOfApplicants
              : 1
          );
          setTraining(trainingToEdit);

          let editedFiles = trainingToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });
          setFileList(editedFiles);
        } else {
          setTraining(newTraining);
          setFileList([]);
        }

        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [TrainingKey, t]);

  function saveTraining(values, isPublish) {
    let trainingSubmit = {
      ...training,
      ...values,
      Title: values.Title.trim(),
      Description: values.Description.trim(),
      Location: values.Location.trim(),
      IsPublish: isPublish,
      NoOfApplicants: values.ApplicantsLimit === 1 ? -1 : noOfApplicants,
      checkedOUList: values.checkedOUList,
    };

    let trainingFromDate = values.TrainingDate[0];
    trainingFromDate.set({ second: 0, millisecond: 0 });
    trainingSubmit.TrainingFromDate = trainingFromDate.startOf("minute").$d;

    let trainingToDate = values.TrainingDate[1];
    trainingToDate.set({ second: 0, millisecond: 0 });
    trainingSubmit.TrainingToDate = trainingToDate.startOf("minute").$d;

    let applicationFromDate = values.ApplicationDate[0];
    applicationFromDate.set({ second: 0, millisecond: 0 });
    trainingSubmit.ApplicationStartDate =
      applicationFromDate.startOf("minute").$d;

    let applicationToDate = values.ApplicationDate[1];
    applicationToDate.set({ second: 0, millisecond: 0 });
    trainingSubmit.ApplicationEndDate = applicationToDate.startOf("minute").$d;

    let upsertTraining =
      trainingSubmit.TrainingKey !== -1 ? updateTraining : addTraining;

    setIsLoading(true);
    upsertTraining(trainingSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/trainings", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        let message =
          trainingSubmit.TrainingKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          trainingSubmit.TrainingKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputTraining() {
    if (location.state) {
      if (location.state.fromApplication) {
        history("/home/trainingcalendar/", {
          state: {
            isShowAvailable: isShowAvailable,
            trainingYear: trainingYear.toString(),
          },
        });
      }
      if (location.state.fromTrans) {
        if (location.state.fromAdmin) {
          history(
            "/home/trainingapplication/trainingtrans/" +
              location.state.trainingApplicationKey,
            {
              state: location.state,
            }
          );
        } else {
          history(
            "/home/trainingapplication/trainingtrans/" +
              location.state.trainingApplicationKey,
            {
              state: location.state,
            }
          );
        }
      } else {
        if (location.state.fromDisplay) {
          history("/home/trainingapplicationdisplay");
        }
        if (location.state.fromApproval) {
          history("/home/trainingapplicationapproval");
        }
        if (location.state.fromVoid) {
          history("/home/trainingapplicationvoid");
        }
        if (location.state.fromCancel) {
          history("/home/trainingapplicationcancellationapproval");
        }
        if (location.state.fromAdmin) {
          history("/home/adminportal/training", {
            state: location.state,
          }); //pass filter values
        }
      }
      if (location.state.currentPage) {
        history("/home/trainings", {
          state: {
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
      }
    } else {
      history("/home/trainings", {
        state: {
          currentPage: location.state
            ? location.state.currentPage
              ? location.state.currentPage
              : 1
            : 1,
        },
      });
    }
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Training")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onApplyTraining(applyTrainingKey) {
    setIsLoading(true);
    let trainingApplication = TrainingApplicationModel({
      trainingKey: applyTrainingKey,
    });

    applyTraining(trainingApplication)
      .then(() => {
        toast.success(t("trainingForm.training_applied_successfully"));
        history("/home/trainingcalendar");
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("trainingForm.training_applied_failed"), error);
      });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ManageTrainingForm
      isLoading={isLoading}
      training={training}
      noOfApplicants={noOfApplicants}
      setNoOfApplicants={setNoOfApplicants}
      fileList={fileList}
      setFileList={setFileList}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      onSubmitted={saveTraining}
      onCancelled={cancelInputTraining}
      onApplied={onApplyTraining}
      onDataChanged={setTraining}
      companies={companies}
      setIsDirty={setIsDirty}
      isViewOnly={isViewOnly}
      isFromApplication={isFromApplication}
      isShowOUPerms={isShowOUPerms}
      isShowTotalParticipants={isShowTotalParticipants}
      form={form}
    />
  );
}

ManageTrainingPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
