import React, { useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Radio,
  InputNumber,
  DatePicker,
  Divider,
  Upload,
  message,
  Form,
  Tabs,
  Tree
} from "antd";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { RowState } from "../../model/common/RowState";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const TrainingInputs = ({
  isLoading,
  training,
  noOfApplicants,
  setNoOfApplicants,
  fileList,
  setFileList,
  onUploadAttachment,
  onRemoveAttachment,
  onSubmitted,
  onCancelled,
  onApplied,
  onDataChanged,
  companies,
  isViewOnly,
  isFromApplication,
  isShowOUPerms,
  isShowTotalParticipants,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const disableField = training.RcdType === RecordType.USER ? false : true;
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const company = companies ? companies : [];
  let checkedOUList =
    training.checkedOUList.length > 0 ? training.checkedOUList : [];
  const [activeTab, setActiveTab] = useState("0");

  Form.useWatch("ApplicantsLimit", form);

  const renderTreeNodes = (data) =>
    data.map((item) => {
      if (item.SelectionDetData) {
        return {
          title: item.CodeDesc,
          key: item.Code,
          children: renderTreeNodes(item.SelectionDetData),
        };
      }
      return {
        title: item.CodeDesc,
        key: item.Key,
      };
    });

  const onCheck = (checkedKeys) => {
    checkedOUList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedOUList.push(checkedKeys[index]);
      }
    }
    setIsDirty(true);
    onDataChanged({ ...training, checkedOUList: checkedOUList });
  };

  const handleSubmit = async (isPublish) => {
    form
      .validateFields()
      .then((values) => {
        values["checkedOUList"] = checkedOUList;
        let trainingToDate = values.TrainingDate[1];
        let applicationToDate = values.ApplicationDate[1];

        if (applicationToDate >= trainingToDate) {
          toast.error(t("trainingForm.training_applied_validation"));
          return;
        }

        uploadAttachments()
          .then((result) => {
            values.Attaches = [...training.Attaches.slice(), ...result];
            onSubmitted(values, isPublish);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  };

  function handleSubmitFailed() {
    setActiveTab("0");
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        training.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: !isViewOnly,
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  function onChangeNoOfApplicants(value) {
    setNoOfApplicants(value ? value : 1);
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {isViewOnly
                    ? t("trainingForm.training_details")
                    : t("trainingForm.training_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={training.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                    style={{ display: isViewOnly ? "none" : "" }}
                  />
                </Form.Item>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={[
                  {
                    key: "0",
                    label: t("general.general"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.title")}
                              name="Title"
                              rules={[
                                {
                                  required: !isViewOnly,
                                  whitespace: true,
                                  message: t("general.title_required_validation"),
                                },
                                {
                                  max: 250,
                                  message: t("general.title_max_length_validation"),
                                },
                              ]}
                              initialValue={training.Title}
                            >
                              <Input
                                placeholder={t("general.title_placeholder")}
                                disabled={disableField || isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.description")}
                              name="Description"
                              rules={[
                                {
                                  required: !isViewOnly,
                                  whitespace: true,
                                  message: t("general.description_required_validation"),
                                },
                              ]}
                              initialValue={training.Description}
                            >
                              <TextArea
                                placeholder={t("general.description_placeholder")}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                disabled={isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("trainingForm.location")}
                              name="Location"
                              rules={[
                                {
                                  required: !isViewOnly,
                                  whitespace: true,
                                  message: t("trainingForm.location_required_validation"),
                                },
                                {
                                  max: 500,
                                  message: t(
                                    "trainingForm.location_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={training.Location}
                            >
                              <Input
                                placeholder={t("trainingForm.location_placeholder")}
                                disabled={isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={<span>{t("trainingForm.training_date")}</span>}
                              name="TrainingDate"
                              rules={[
                                {
                                  required: !isViewOnly,
                                  message: t(
                                    "trainingForm.training_date_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={training.TrainingDate}
                            >
                              <RangePicker
                                style={{ width: "100%" }}
                                allowClear={true}
                                showToday={false}
                                showTime={{
                                  defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                                  format: "HH:mm",
                                }}
                                format="DD/MM/YYYY HH:mm"
                                placeholder={["Start Date", "End Date"]}
                                disabled={isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={<span>{t("trainingForm.application_date")}</span>}
                              name="ApplicationDate"
                              rules={[
                                {
                                  required: !isViewOnly,
                                  message: t(
                                    "trainingForm.application_date_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={training.ApplicationDate}
                            >
                              <RangePicker
                                style={{ width: "100%" }}
                                allowClear={true}
                                showToday={false}
                                showTime={{
                                  defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                                  format: "HH:mm",
                                }}
                                format="DD/MM/YYYY HH:mm"
                                placeholder={["Start Date", "End Date"]}
                                disabled={isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            {isShowTotalParticipants ? 
                              (
                                <Form.Item
                                  label={<span>{t("general.total_no_of_participants")}</span>}
                                  name="TotalApplicants"
                                >
                                  <p>
                                    {training.TotalConfirmedApplications} / {training.NoOfApplicants !== -1 
                                      ? training.NoOfApplicants
                                      : "-"
                                    }
                                  </p>
                                </Form.Item>
                              ) 
                            : (
                                <Form.Item
                                  label={<span>{t("general.no_of_participants")}</span>}
                                  name="ApplicantsLimit"
                                  initialValue={training.NoOfApplicants > 0 ? 2 : 1}
                                >
                                  <Radio.Group disabled={isViewOnly}>
                                    <Radio value={1}>{t("trainingForm.no_limit")}</Radio>
                                    <Radio value={2}>
                                      {t("trainingForm.limit_to")}
                                      <InputNumber
                                        size="small"
                                        style={{
                                          marginBottom: -8,
                                        }}
                                        step="1"
                                        precision={0}
                                        min={1}
                                        formatter={inputLocaleFormatter(0)}
                                        parser={inputLocaleParser(0)}
                                        value={
                                          form.getFieldValue("ApplicantsLimit") === 2
                                            ? noOfApplicants
                                            : null
                                        }
                                        disabled={
                                          form.getFieldValue("ApplicantsLimit") === 2 &&
                                          !isViewOnly
                                            ? false
                                            : true
                                        }
                                        onChange={onChangeNoOfApplicants}
                                      />
                                      {t("trainingForm.persons")}
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              )
                            }
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={
                                <span>
                                  {t("trainingForm.require_participants_feedback")}
                                </span>
                              }
                              name="RequireFeedback"
                              valuePropName="checked"
                              initialValue={
                                training.RequireFeedback
                                  ? training.RequireFeedback
                                  : undefined
                              }
                            >
                              <Switch
                                checkedChildren={t("general.yes")}
                                unCheckedChildren={t("general.no")}
                                disabled={isViewOnly}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider
                          style={{
                            display: isViewOnly && fileList.length === 0 ? "none" : "",
                          }}
                        />
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            display: isViewOnly && fileList.length === 0 ? "none" : "",
                          }}
                        >
                          <Col span={24}>
                            <Form.Item
                              label={<span>{t("general.attachment")}</span>}
                              name="Attachment"
                              rules={[{ required: false }]}
                            >
                              <Upload {...uploadProp()} listType="picture">
                                <Button style={{ display: isViewOnly ? "none" : "" }}>
                                  <UploadOutlined /> {t("general.browse")}
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )
                  },
                  {
                    ...isShowOUPerms && {
                      key: "1",
                      label: t("general.permission"),
                      children: (
                        <Tree
                          checkable
                          defaultExpandAll={true}
                          defaultCheckedKeys={
                            training.checkedOUList.length > 0
                              ? training.checkedOUList
                              : []
                          }
                          onCheck={onCheck}
                          treeData={renderTreeNodes(company)}
                        />
                      ),
                    }
                  }
                ]}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="link"
                  onClick={onCancelled}
                  style={{
                    marginRight: "5px",
                    display: isViewOnly ? "" : "none",
                  }}
                >
                  {t("general.back")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => onApplied(training.TrainingKey)}
                  style={{
                    marginRight: "5px",
                    display:
                      !training.IsApplyByUser &&
                      training.IsOpenForApplication &&
                      isFromApplication &&
                      (training.NoOfApplicants === -1 ||
                        training.TotalConfirmedApplications <
                          training.NoOfApplicants)
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.apply")}
                </Button>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{
                    marginRight: "5px",
                    display: isViewOnly ? "none" : "",
                  }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => handleSubmit(true)}
                  style={{ display: isViewOnly ? "none" : "" }}
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={training}
              showFooter={
                training.TrainingKey !== -1 && !isViewOnly ? true : false
              }
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

TrainingInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  training: PropTypes.object.isRequired,
  noOfApplicants: PropTypes.number.isRequired,
  setNoOfApplicants: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onApplied: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
  isViewOnly: PropTypes.bool.isRequired,
  isFromApplication: PropTypes.bool.isRequired,
  isShowOUPerms: PropTypes.bool.isRequired,
  isShowTotalParticipants: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const ManageTrainingForm = TrainingInputs;
