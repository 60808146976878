import React, { useEffect } from "react";
import { Modal, Form } from "antd";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import { useTranslation } from "react-i18next";

const RoomAssignmentInput = ({
  isVisible,
  allRooms,
  selectedRoom,
  onSaved,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [isVisible, form]);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        onSaved(values.Room.key);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("roomRequisitionForm.meeting_room_assignment")}
      okText={t("general.confirm")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("roomRequisitionForm.meeting_room")}
          name="Room"
          rules={[
            {
              required: true,
              message: t(
                "roomRequisitionForm.meeting_room_required_validation"
              ),
            },
            {
              validator: (_, value) => {
                if (value && !allRooms.some((x) => x.Key === value.value)) {
                  return Promise.reject(
                    new Error(t("roomRequisitionForm.invalid_meeting_room"))
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          initialValue={
            selectedRoom && selectedRoom.RoomKey > 0
              ? {
                  key: selectedRoom.RoomKey,
                  value: selectedRoom.RoomKey,
                  label: selectedRoom.RoomCode + " - " + selectedRoom.RoomDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            ref={React.createRef()}
            labelInValue={true}
            data={allRooms}
            valueProp={"Key"}
            textProp={"CodeDesc"}
            disabledProp={"Active"}
            placeholder={t("roomRequisitionForm.meeting_room_placeholder")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

RoomAssignmentInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  allRooms: PropTypes.array.isRequired,
  selectedRoom: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export const RoomAssignmentModal = RoomAssignmentInput;
