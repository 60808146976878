import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidClinics,
  voidClinics,
} from "../../../api/clinicApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import ClinicVisitVoidForm from "../../forms/Clinic/ClinicVisitVoidForm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function ClinicVisitVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [clinicTrans, setClinicTrans] = useState([]);
  const [selectedClinicTrans, setSelectedClinicTrans] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getClinicTrans, [currentYear]);

  function getClinicTrans() {
    setIsLoading(true);
    getApprovedAndVoidClinics(currentYear)
      .then((value) => {
        setClinicTrans(value ? value : []);
        setSelectedClinicTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewClinic() {
    history("/home/clinicvisit/clinictrans");
  }

  function voidClinicTrans(selectedClinics, reason) {
    setIsLoading(true);

    voidClinics(selectedClinics, reason)
      .then(() => {
        getClinicTrans();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewClinicTrans(clinicTransKey) {
    history("/home/clinicvisit/clinictrans/" + clinicTransKey, {
      state: { fromVoid: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ClinicVisitVoidForm
      isLoading={isLoading}
      data={clinicTrans}
      selectedItems={selectedClinicTrans}
      onAdd={onAddNewClinic}
      onView={viewClinicTrans}
      onVoided={voidClinicTrans}
      onItemSelected={setSelectedClinicTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

ClinicVisitVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
