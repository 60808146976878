import React from "react";
import {
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Spin, Card, Typography, Button } from "antd";
import PropTypes from "prop-types";
// import '../../../../node_modules/react-keyed-file-browser/dist/react-keyed-file-browser.css';
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
import FileBrowser, { Icons } from "react-keyed-file-browser";
import "font-awesome/css/font-awesome.min.css";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

export const DocumentManagerForm = ({
  isLoading,
  files,
  handleCreateFolder,
  handleCreateFiles,
  handleRenameFolder,
  handleRenameFile,
  handleDeleteFolder,
  handleDeleteFile,
  // handleDownloadFolder,
  handleDownloadFile,
  handleSelectedFile,
  isEdit,
  onEdit,
  isAdmin,
}) => {
  const { t } = useTranslation();

  return (
    <Spin spinning={isLoading}>
      <Card
        title={
          <span className="form-title">
            {t("documentManager.document_manager")}
          </span>
        }
      >
        <ExclamationCircleOutlined hidden={!isAdmin} />

        <Text hidden={!isAdmin} type="secondary">
          {t("documentManager.drag_and_drop_to_import_file")}
        </Text>
        <br />
        <br />
        <Button hidden={!isAdmin} onClick={onEdit}>
          {!isEdit ? <EditOutlined /> : <CloseOutlined />}
          {!isEdit ? t("general.edit") : t("documentManager.cancel_edit")}
        </Button>

        <FileBrowser
          files={files}
          icons={Icons.FontAwesome(4)}
          onCreateFolder={isAdmin ? handleCreateFolder : false}
          onCreateFiles={isAdmin ? handleCreateFiles : false}
          onMoveFolder={isAdmin && isEdit ? handleRenameFolder : false}
          onMoveFile={isAdmin && isEdit ? handleRenameFile : false}
          onRenameFolder={isAdmin && isEdit ? handleRenameFolder : false}
          onRenameFile={isAdmin && isEdit ? handleRenameFile : false}
          onDeleteFolder={isAdmin && isEdit ? handleDeleteFolder : false}
          onDeleteFile={isAdmin && isEdit ? handleDeleteFile : false}
          // onDownloadFolder={handleDownloadFolder}
          // onDownloadFile={handleDownloadFile}
          onSelectFile={handleSelectedFile}
          detailRenderer={() => null}
          canFilter={false}
        />
      </Card>
    </Spin>
  );
};

DocumentManagerForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  files: PropTypes.array.isRequired,
  handleCreateFolder: PropTypes.func,
  handleCreateFiles: PropTypes.func,
  handleRenameFolder: PropTypes.func,
  handleRenameFile: PropTypes.func,
  handleDeleteFolder: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  isEdit: PropTypes.bool.isRequired,
  onEdit: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};
