import { RowState } from "../common/RowState";

export default function LeaveTypeModel({
  LTKey = -1,
  LTCode = "",
  LTDesc = "",
  LTColor = "",
  LTIcon = "",
  LTType = 1, // 1-Paid, 2-Unpaid
  LTEntitlementRule = 1, // 1-Accrual, 2-Full-Pro, 3-Fixed
  LTGender = 1, // 1-All, 2-Male, 3-Female
  LTMaritalStatus = 1, // 1-All, 2-Single, 3-Married
  LTRoundingMethod = 1, // 1-Round-Down, 2-Round-Up
  IsLTELeave = false,
  IsIncludeHoliday = false,
  NoOfCarryForwardYear = 1,
  LTResetRule = 1,
  LTResetLimit = 0,
  LTEDay = 0,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  CFExpiryMth = 12,
  RowVersion = null,
  checkedOUList = [],
  Company = [],
  LeaveTypeRule = [
    {
      LTRuleKey: Math.floor(Math.random() * 999999),
      EmpyGradeKey: -1,
      EmpyGradeCodeGradeDesc: "All",
      EffectiveAfter: 0,
      EntitledDay: 8,
      CarryForwardDay: 0,
      RcdType: 1,
      RowState: RowState.ADDED,
    },
  ],
}) {
  return Object.freeze({
    LTKey: LTKey,
    LTCode: LTCode,
    LTDesc: LTDesc,
    LTColor: LTColor,
    LTIcon: LTIcon,
    LTType: LTType, // 1-Paid, 2-Unpaid
    LTEntitlementRule: LTEntitlementRule, // 1-Accrual, 2-Full-Pro, 3-Fixed
    LTGender: LTGender, // 1-All, 2-Male, 3-Female
    LTMaritalStatus: LTMaritalStatus, // 1-All, 2-Single, 3-Married
    LTRoundingMethod: LTRoundingMethod, // 1-Round-Down, 2-Round-Up
    IsLTELeave: IsLTELeave,
    IsIncludeHoliday: IsIncludeHoliday,
    NoOfCarryForwardYear: NoOfCarryForwardYear,
    LTResetRule: LTResetRule,
    LTResetLimit: LTResetLimit,
    LTEDay: LTEDay,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    CFExpiryMth: CFExpiryMth,
    RowVersion: RowVersion,
    LeaveTypeRule: LeaveTypeRule,
    checkedOUList: checkedOUList,
    Company: Company,
  });
}
