import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import VehicleRequisitionAssignmentForm from "../../forms/VehicleReq/VehicleRequisitionAssignmentForm";
import { getPendingAssignedVehicleRequisitions } from "../../../api/vehicleRequisitionApprovalApi";
import { assignVehicleAndMiles } from "../../../api/vehicleRequisitionApi";
import { getAvailableVehiclesForAssignment } from "../../../api/vehicleApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function VehicleRequisitionAssignmentPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleRequisitions, setVehicleRequisitions] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getVehicleRequisitions, [currentYear]);

  function getVehicleRequisitions() {
    setIsLoading(true);
    getPendingAssignedVehicleRequisitions(currentYear)
      .then((value) => {
        setVehicleRequisitions(value ? value : []);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function getAvailableVehicles(vehicleRequisitionKey, onDataLoaded) {
    getAvailableVehiclesForAssignment(vehicleRequisitionKey)
      .then((value) => {
        setAllVehicles(value ? value : []);

        if (onDataLoaded != null) {
          onDataLoaded();
        }
      })
      .catch((error) => {
        showErrorMessage(
          t("vehicleRequisitionForm.fail_to_get_vehicle_record"),
          error
        );
      });
  }

  function onAddNewVehicleRequisition() {
    history("/home/vehiclerequisition/vehicletrans");
  }

  function onAssignVehicle(selectedVehicleRequisition) {
    setIsLoading(true);

    assignVehicleAndMiles(selectedVehicleRequisition)
      .then(() => {
        getVehicleRequisitions();
        toast.success(
          t("vehicleRequisitionForm.vehicle_is_assigned_successfully")
        );
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("vehicleRequisitionForm.fail_to_assign_vehicle_to_record"),
          error
        );
      });
  }

  function viewVehicleRequisitions(vehicleRequisitionKey) {
    history("/home/vehiclerequisition/vehicletrans/" + vehicleRequisitionKey, {
      state: { fromAssignment: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <VehicleRequisitionAssignmentForm
      isLoading={isLoading}
      data={vehicleRequisitions}
      allVehicles={allVehicles}
      onAdd={onAddNewVehicleRequisition}
      onView={viewVehicleRequisitions}
      onAssigned={onAssignVehicle}
      getAvailableVehicles={getAvailableVehicles}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

VehicleRequisitionAssignmentPage.propTypes = {
  history: PropTypes.func.isRequired,
};
