import {
  NotificationOutlined,
  MailOutlined,
  MessageOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { List, Avatar, Skeleton } from "antd";
import React from "react";
import classNames from "classnames";
import "./NoticeList.css";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

function NoticeList({
  data: i,
  onClick,
  onClear,
  onViewMore,
  title,
  showClear,
  showViewMore,
  numOfChild,
  loadMoreData,
}) {
  const { t } = useTranslation();
  let notificationData = i.child.filter((x) => x.IsRead === false);

  if (numOfChild === 0) {
    return (
      <>
        <div className="notFound">
          <Avatar
            size={64}
            style={{
              display: "inline-block",
              marginBottom: 16,
            }}
            icon={<NotificationOutlined />}
          />

          <div>{t("noticeList.all_caught_up")}</div>
        </div>
        <div className="bottomBar">
          <div
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {t("noticeList.view_all")}
          </div>
        </div>
      </>
    );
  }

  const iconEnum = {
    mail: <MailOutlined />,
    message: <MessageOutlined />,
    schedule: <ScheduleOutlined />,
  };

  return (
    <>
      <div
        id="scrollableDiv"
        style={{
          maxBlockSize: "450px",
          overflow: "auto",
          marginTop: -16,
        }}
      >
        <InfiniteScroll
          dataLength={i.child.length}
          next={loadMoreData}
          hasMore={i.child.length < numOfChild && i.TotalRecords > 20}
          loader={
            <Skeleton
              avatar
              paragraph={{
                rows: 1,
              }}
              active
            />
          }
          scrollableTarget="scrollableDiv"
        >
          <List
            dataSource={notificationData}
            renderItem={(item, i) => {
              const leftIcon = item.Icon ? (
                <Avatar
                  style={{
                    backgroundColor: item.IconBgColor,
                    marginTop: 4,
                  }}
                  icon={iconEnum[item.Icon]}
                />
              ) : null;

              const itemCls = classNames("item", {
                read: item.IsRead,
              });

              return (
                <List.Item
                  key={item.NotificationKey || i}
                  onClick={() => onClick && onClick(item)}
                  style={{
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 24,
                    paddingLeft: 24,
                    overflow: "hidden",
                    cursor: "pointer",
                    transition: "all .3s",
                  }}
                  className={itemCls}
                >
                  <List.Item.Meta
                    style={{ width: "100%", textAlign: "left" }}
                    avatar={leftIcon}
                    title={
                      <div
                        style={{
                          marginBottom: 8,
                          fontWeight: "normal",
                        }}
                      >
                        {item.Title}
                        <div
                          style={{
                            float: "right",
                            marginTop: -1.5,
                            marginRight: 0,
                            fontWeight: "normal",
                          }}
                        >
                          {item.Extra}
                        </div>
                      </div>
                    }
                    description={
                      <div>
                        <div style={{ fontSize: 12, lineHeight: 1.5 }}>
                          {item.Message}
                        </div>
                        <div
                          style={{
                            margintop: 4,
                            fontSize: 12,
                            lineHeight: 1.5,
                          }}
                        >
                          {item.SendDate}
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              );
            }}
          />
        </InfiniteScroll>
      </div>

      <div className="bottomBar">
        {showClear ? (
          <div onClick={onClear}>{t("noticeList.clear_notification")}</div>
        ) : null}
        {showViewMore ? (
          <div
            onClick={(e) => {
              if (onViewMore) {
                onViewMore(e);
              }
            }}
          >
            {t("noticeList.view_all")}
          </div>
        ) : null}
      </div>
    </>
  );
}

NoticeList.propTypes = {
  data: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  onViewMore: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  showClear: PropTypes.bool.isRequired,
  showViewMore: PropTypes.bool.isRequired,
  numOfChild: PropTypes.number.isRequired,
  loadMoreData: PropTypes.func.isRequired,
};

export default NoticeList;
