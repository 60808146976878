import React from "react";
import {
  Spin,
  Card,
  Row,
  Col,
  DatePicker,
  Form,
} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { SelectionInput } from "../../common/SelectionInput";
import { useTranslation } from "react-i18next";
import TravelAuthorizationDisplayForm from "../../forms/Claim/Travel/TravelAuthorizationDisplayForm";

const { MonthPicker } = DatePicker;

const TravelAdminForm = ({
  isLoading,
  travelDS,
  getTravelDataSource,
  form,
  onViewTravel,
  onAttachmentClicked,
  setCurrentYear,
  setCurrentMonth,
  allOUs,
  allDepartments,
  allUsers,
  initialUsers,
  setAllUsers,
  ouKey,
  deptKey,
  userKey,
  currentYear,
  currentMonth,
  filterRecord,
  pagination,
  setPagination,
  resetPagination,
}) => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let currentMonthField = values.MonthYear.month() + 1;
        let currentYearField = values.MonthYear.year();
        getTravelDataSource(currentYearField, currentMonthField);
      })
      .catch(() => {});
  };

  function onYearMonthChanged(value) {
    if (value) {
      setCurrentMonth(value.month() + 1);
      setCurrentYear(value.year());

      form
        .validateFields()
        .then(() => {
          let currentMonthField = value.month() + 1;
          let currentYearField = value.year();
          getTravelDataSource(currentYearField,currentMonthField);
        });
    }
  }

  function onChangeOU(value) {
    let dept = form.getFieldValue("Dept");
    let filteredUsers = initialUsers.filter(
      (x) =>
        (value.value === 0 || x.OUKey === value.value) &&
        (dept.value === 0 || x.DeptKey === dept.value)
    );
    setAllUsers(filteredUsers);

    form.setFieldsValue({
      User: { key: 0, value: 0, label: "All" },
      Dept: { key: 0, value: 0, label: "All" },
    });
    
    filterRecord(value.value, 0, 0);
  }

  function onChangeDept(value) {
    let ou = form.getFieldValue("OU");
    let filteredUsers = initialUsers.filter(
      (x) =>
        (ou.value === 0 || x.OUKey === ou.value) &&
        (value.value === 0 || x.DeptKey === value.value)
    );
    setAllUsers(filteredUsers);

    form.setFieldsValue({
      User: { key: 0, value: 0, label: "All" },
    });
    
    filterRecord(ou.value, value.value, 0);
  }

  function onChangeUser(value){
    let ou = form.getFieldValue("OU");
    let dept = form.getFieldValue("Dept");

    filterRecord(ou.value, dept.value, value.value);
  }

  return (
    <Card
      title= {<span className="form-title">
        {t("general.travel")}
      </span>}
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={t("general.operating_unit")}
                name="OU"
                initialValue={{ value: ouKey }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allOUs}
                  valueProp={"OUKey"}
                  textProp={"OUCodeOUDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.operating_unit_placeholder")}
                  onChange={onChangeOU}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={t("general.department")}
                name="Dept"
                initialValue={{ value: deptKey }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allDepartments}
                  valueProp={"Key"}
                  textProp={"CodeDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.department_placeholder")}
                  onChange={onChangeDept}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={t("general.employee")}
                name="User"
                initialValue={{ value: userKey }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allUsers}
                  valueProp={"UserKey"}
                  textProp={"EmpyIDEmpyName"}
                  placeholder={t("general.employee_placeholder")}
                  onChange={onChangeUser}
                  isIncludeAllOption={allUsers.length > 0}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={24}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Form.Item
                label={t("general.month_year")}
                name="MonthYear"
                rules={[
                  {
                    required: true,
                    message: t("general.month_year_required_validation"),
                  },
                ]}
                initialValue={dayjs().year(currentYear).month(currentMonth - 1)}
              >
                <MonthPicker
                  allowClear={false}
                  placeholder={t("general.month_year_placeholder")}
                  format="MM/YYYY"
                  onChange={onYearMonthChanged}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{marginTop: 30}}>
            <Col span={24}>
              <TravelAuthorizationDisplayForm
                isLoading={isLoading}
                isFromAdmin={true}
                data={travelDS}
                onView={onViewTravel}
                onAttachmentClicked={onAttachmentClicked}
                setCurrentYear={setCurrentYear}
                pagination={pagination}
                setPagination={setPagination}
                resetPagination={resetPagination}
              />      
            </Col>
          </Row>            
        </Form>
      </Spin>
    </Card>
  );
}

TravelAdminForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  travelDS: PropTypes.array.isRequired,
  getTravelDataSource: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onViewTravel: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentMonth: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  allOUs: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  initialUsers: PropTypes.array.isRequired,
  setAllUsers: PropTypes.func.isRequired,
  ouKey: PropTypes.number.isRequired,
  deptKey: PropTypes.number.isRequired,
  userKey: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  currentMonth: PropTypes.number.isRequired,
  filterRecord: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
  resetPagination: PropTypes.func.isRequired,
}

export default TravelAdminForm;