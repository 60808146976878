export default function ClinicConfgModel({
  clinicConfgKey = -1,
  isValidateTrans = false,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null
}) {
  return Object.freeze({
    ClinicConfgKey: clinicConfgKey,
    IsValidateTrans: isValidateTrans,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion
  });
}
