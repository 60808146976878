import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "vehicle/";

export function getAllVehicles() {
  return axios({
    method: "GET",
    url: base + "GetAllVehicles",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getVehicle(vehicleKey) {
  return axios({
    method: "GET",
    url: base + "GetVehicle",
    headers: authHeader(),
    params: { key: vehicleKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getVehicleCode() {
  return axios({
    method: "GET",
    url: base + "getVehicleCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAvailableVehiclesForAssignment(key) {
  return axios({
    method: "GET",
    url: base + "GetAvailableVehiclesForAssignment",
    headers: authHeader(),
    params: { vehicleRequisitionKey: key }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getQuartoVehicles(key) {
  return axios({
    method: "GET",
    url: base + "GetQuartoVehicles",
    headers: authHeader(),
    params: { editVehicleKey: key }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addVehicle(vehicle) {
  return axios({
    method: "POST",
    url: base + "AddVehicle",
    headers: authHeader(),
    data: vehicle
  })
    .then()
    .catch(handleError);
}

export function updateVehicle(vehicle) {
  return axios({
    method: "PUT",
    url: base + "UpdateVehicle",
    headers: authHeader(),
    params: { key: vehicle.VehicleKey },
    data: vehicle
  })
    .then()
    .catch(handleError);
}

export function deleteVehicle(vehicleKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteVehicle",
    headers: authHeader(),
    params: { key: vehicleKey }
  })
    .then()
    .catch(handleError);
}

