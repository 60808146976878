import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ManageAPRLvlConfgForm } from "../../forms/Master/ManageAPRLvlConfgForm";
import { toast } from "react-toastify";
import {
  GetSelectedAPRLvlConfg,
  UpdateAPRLvlConfg,
} from "../../../api/aprLvlConfgApi";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newAPRLvlConfg = {
  SubjectKey: "0",
  Subject: "",
  SubjectDesc: "",
  AppLvl: "0",
  IsOverride: false,
  UpdatedBy: -1,
  UpdatedByCode: "",
  UpdatedOn: new Date(),
};

export default function ManageAPRLvlConfgPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [aprLvlConfgs, setAprLvlConfgs] = useState(newAPRLvlConfg);
  const [isLoading, setIsLoading] = useState(true);
  const { SubjectKey } = useParams();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = SubjectKey;
    if (editKey > 0) {
      promises.push(GetSelectedAPRLvlConfg(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
        }

        setAprLvlConfgs(values[0] ? values[0] : newAPRLvlConfg);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [SubjectKey]);

  function cancelInputAPRLvlConfg() {
    history("/home/aprlvlconfgs");
  }

  function saveAprLvlConfg(values) {
    let aprlvlconfgSubmit = { ...aprLvlConfgs, ...values };
    if (
      aprlvlconfgSubmit.AppLvl === t("aprLvlConfigForm.not_required") ||
      aprlvlconfgSubmit.AppLvl === "0"
    ) {
      aprlvlconfgSubmit.AppLvl = 0;
      aprlvlconfgSubmit.IsOverride = false;
    }

    setIsLoading(true);
    UpdateAPRLvlConfg(aprlvlconfgSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/aprlvlconfgs", { state: { force: true } });

        let message = t("general.record_saved_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          aprlvlconfgSubmit.SubjectKey !== -1
            ? t("general.fail_to_update_record")
            : t("aprLvlConfigForm.subject_does_not_exists");
        toast.error(message + error.message);
      });
  }

  return (
    <ManageAPRLvlConfgForm
      isLoading={isLoading}
      aprLvlConfgs={aprLvlConfgs}
      onSubmitted={saveAprLvlConfg}
      onCancelled={cancelInputAPRLvlConfg}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAPRLvlConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
