import React, { useState, useEffect } from "react";
import {
  CheckCircleTwoTone,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Button,
  Card,
  Spin,
  Pagination,
  Table,
  Modal,
  Tag,
  Divider,
  Radio,
  Input,
  InputNumber,
  DatePicker,
  Form,
  Space,
} from "antd";
import PropTypes from "prop-types";
import EntryFormFooter from "../../common/EntryFormFooter";
import dayjs from "dayjs";
import { RowState } from "../../model/common/RowState";
import {
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import ManageAppraisalTemplatePreviewForm from "../Master/ManageAppraisalTemplatePreviewForm";
import { SelectionInput } from "../../common/SelectionInput";
import { ReportViewer } from "../../reports/ReportViewer";
import { useParams } from "react-router-dom";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const ManageAppraisalSubmissionForm = ({
  isLoading,
  appraisalSubmission,
  appraisalTemplate,
  empyInfo,
  grades,
  positions,
  appraisalRating,
  loadPageData,
  onSubmitted,
  onCancelled,
  onApproved,
  onRevert,
  onDataChanged,
  isOwner,
  isLv1Appraiser = false,
  isLv2Appraiser = false,
  isViewOnly = false,
  form,
  setIsDirty,
}) => {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { MonthPicker } = DatePicker;
  const REPORT_NAME = "Appraisal Report";

  const isSystemRcd = appraisalSubmission.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(appraisalSubmission.Status);
  const isSubmitted = isStatusSubmitted(appraisalSubmission.Status);
  const isVoid = isStatusVoid(appraisalSubmission.Status);
  const isApproved =
    isStatusPartialApproved(appraisalSubmission.Status) ||
    isStatusApproved(appraisalSubmission.Status);
  const isPartialApproved = isStatusPartialApproved(appraisalSubmission.Status);
  const defaultEffectiveYearMonth = dayjs().startOf("month");
  const isEditableByApprover =
    isApproved && !(isPartialApproved && isLv2Appraiser);

  const isSelfAppraiseExp =
    !dayjs().isBetween(
      appraisalSubmission.FromSelfAppraisalDate,
      appraisalSubmission.ToSelfAppraisalDate,
      "day",
      "[]"
    ) || !isStatusOpen(appraisalSubmission.Status);

  const isReviewExp = !dayjs().isBetween(
    appraisalSubmission.FromReviewDate,
    appraisalSubmission.ToReviewDate,
    "day",
    "[]"
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });
  const [currentAction, setCurrentAction] = useState("NoChange");

  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [reportParam, setReportParam] = useState({});
  const [showAppraisal, setShowAppraisal] = useState(false);
  const { AppraisalTransHdrKey } = useParams();

  useEffect(() => {
    loadPageData(AppraisalTransHdrKey, form.resetFields);
    // eslint-disable-next-line
  }, [AppraisalTransHdrKey]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 18,
        offset: 6,
      },
    },
  };

  const radioStyle = {
    //height: "30px",
    //lineHeight: "30px",
  };

  const handleSubmit = () => {
    handleSubmitFunc(0, appraisalSubmission.Reason); //to maintain rejected reason for amendment when save but not submit
  };

  function handleSubmitFunc(statusAction, cancelReason = "") {
    form
      .validateFields()
      .then((values) => {
        let tempValue = Object.assign({}, appraisalSubmission);
        if (!(isLv1Appraiser || isLv2Appraiser)) {
          tempValue.Status = statusAction;
          tempValue.Reason = cancelReason;
        } else {
          tempValue.EffectiveDate = dayjs(values.EffectiveDate).format(
            "YYYY/MM/DD"
          );
          tempValue.Remarks = values.Remarks;
          tempValue.ActionReason = values.ActionReason;
          tempValue.ExtendMth = values.ExtendMth;
          if (values.FromGrade !== undefined)
            tempValue.FromGradeKey = values.FromGrade.key;
          if (values.ToGrade !== undefined)
            tempValue.ToGradeKey = values.ToGrade.key;
          else tempValue.ToGradeKey = values.FromGrade.key;
          if (values.FromPost !== undefined)
            tempValue.FromPostKey = values.FromPost.key;
          if (values.ToPost !== undefined)
            tempValue.ToPostKey = values.ToPost.key;
          else tempValue.ToPostKey = values.FromPost.key;
          tempValue.Type = values.Type;
        }
        onSubmitted(tempValue);
      })
      .catch(() => {});
  }

  function handleApprove() {
    form
      .validateFields()
      .then((values) => {
        let tempValue = Object.assign({}, appraisalSubmission);
        tempValue.EffectiveDate = dayjs(values.EffectiveDate).format(
          "YYYY/MM/DD"
        );
        tempValue.ActionReason = values.ActionReason;
        tempValue.Remarks = values.Remarks;
        tempValue.ExtendMth = values.ExtendMth;
        if (values.FromGrade !== undefined)
          tempValue.FromGradeKey = values.FromGrade.key;
        if (values.ToGrade !== undefined)
          tempValue.ToGradeKey = values.ToGrade.key;
        else tempValue.ToGradeKey = values.FromGrade.key;
        if (values.FromPost !== undefined)
          tempValue.FromPostKey = values.FromPost.key;
        if (values.ToPost !== undefined)
          tempValue.ToPostKey = values.ToPost.key;
        else tempValue.ToPostKey = values.FromPost.key;
        tempValue.Type = values.Type;

        onApproved(tempValue);
      })
      .catch(() => {});
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("appraisalSubmissionForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmitFunc(1);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("appraisalSubmissionForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk() {
        handleSubmitFunc(0);
      },
      onCancel() {},
    });
  }

  function showApproveConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.approval_confirmation"),
          content: t("appraisalSubmissionForm.approve_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleApprove();
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function showRevertConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.revert_confirmation"),
          content: t("appraisalSubmissionForm.revert_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            onRevert(appraisalSubmission);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function next() {
    if (currentStep === 0) {
      setPagination({
        current: 1,
        minIndex: 0,
        maxIndex: 1,
      });
    }
    const current = currentStep + 1;
    setCurrentStep(current);
    if (current === 2) {
      setCurrentAction(appraisalSubmission.Type);
    }
  }

  function prev() {
    const current = currentStep - 1;
    setCurrentStep(current);
  }

  function onChangePagination(page) {
    setPagination({
      current: page,
      minIndex: (page - 1) * 1,
      maxIndex: page * 1,
    });
  }

  const columns = [
    {
      title: t("appraisalTemplateForm.section"),
      dataIndex: "AppraisalTitle",
      key: "AppraisalTitle",
    },
    {
      title: t("appraisalSubmissionForm.section_weight_percentage"),
      width: "65px",
      align: "center",
      dataIndex: "AppraisalWeight",
      key: "AppraisalWeight",
    },
    {
      title: t("appraisalSubmissionForm.self_assessment"),
      children: [
        {
          title: t("appraisalSubmissionForm.self_assessment_percentage"),
          width: "90px",
          align: "center",
          dataIndex: "SelfWeight",
          key: "SelfWeight",
        },
        {
          title: t("appraisalSubmissionForm.self_weighted_score_percentage"),
          width: "80px",
          align: "center",
          dataIndex: "SelfScore",
          key: "SelfScore",
        },
      ],
    },
    {
      title: t("appraisalSubmissionForm.level_1"),
      children: [
        {
          title: t("appraisalSubmissionForm.level_1_assessment_percentage"),
          width: "90px",
          align: "center",
          dataIndex: "AprLv1Weight",
          key: "AprLv1Weight",
        },
        {
          title: t("appraisalSubmissionForm.level_1_weighted_score_percentage"),
          width: "80px",
          align: "center",
          dataIndex: "AprLv1Score",
          key: "AprLv1Score",
        },
      ],
    },
    {
      title: t("appraisalSubmissionForm.level_2"),
      children: [
        {
          title: t("appraisalSubmissionForm.level_2_assessment_percentage"),
          width: "90px",
          align: "center",
          dataIndex: "AprLv2Weight",
          key: "AprLv2Weight",
        },
        {
          title: t("appraisalSubmissionForm.level_2_weighted_score_percentage"),
          width: "80px",
          align: "center",
          dataIndex: "AprLv2Score",
          key: "AprLv2Score",
        },
      ],
    },
  ];

  function getFilterAppraisalData() {
    let result = [];
    let header = appraisalSubmission.Dets.filter(
      (x) =>
        x.RowState !== RowState.DELETED &&
        x.ATDetInd === "H" &&
        x.UserRole === "user"
    );

    let detail = appraisalSubmission.Dets.filter(
      (x) =>
        x.RowState !== RowState.DELETED &&
        x.ATDetInd === "D" &&
        x.UserRole === "user"
    );

    let detailAprLv1 = appraisalSubmission.Dets.filter(
      (x) =>
        x.RowState !== RowState.DELETED &&
        x.ATDetInd === "D" &&
        x.UserRole === "aprlv1"
    );

    let detailAprLv2 = appraisalSubmission.Dets.filter(
      (x) =>
        x.RowState !== RowState.DELETED &&
        x.ATDetInd === "D" &&
        x.UserRole === "aprlv2"
    );

    let tempHdrTotal = 0;
    let tempHdrAprlv1Total = 0;
    let tempHdrAprlv2Total = 0;

    header.forEach((hdr) => {
      let tempDetTotal = 0;
      let tempDetAprLv1Total = 0;
      let tempDetAprLv2Total = 0;
      detail
        .filter((fil) => fil.RefATDetKey === hdr.ATDetKey)
        .forEach((det) => {
          tempDetTotal += calculateTotalSummary(det);
          tempDetAprLv1Total += calculateTotalSummary(
            detailAprLv1.find((z) => z.ATDetKey === det.ATDetKey)
          );
          tempDetAprLv2Total += calculateTotalSummary(
            detailAprLv2.find((z) => z.ATDetKey === det.ATDetKey)
          );
        });
      result.push({
        ...hdr,
        SelfWeight: tempDetTotal.toFixed(2),
        SelfScore: ((tempDetTotal / 100) * hdr.AppraisalWeight).toFixed(2),
        AprLv1Weight: tempDetAprLv1Total.toFixed(2),
        AprLv1Score: ((tempDetAprLv1Total / 100) * hdr.AppraisalWeight).toFixed(
          2
        ),
        AprLv2Weight: tempDetAprLv2Total.toFixed(2),
        AprLv2Score: ((tempDetAprLv2Total / 100) * hdr.AppraisalWeight).toFixed(
          2
        ),
      });
      tempHdrTotal += (tempDetTotal / 100) * hdr.AppraisalWeight;
      tempHdrAprlv1Total += (tempDetAprLv1Total / 100) * hdr.AppraisalWeight;
      tempHdrAprlv2Total += (tempDetAprLv2Total / 100) * hdr.AppraisalWeight;
    });
    result.push({
      AppraisalTitle: t("appraisalSubmissionForm.final_score"),
      AppraisalWeight: 100 + calculateRating(100),
      SelfScore:
        tempHdrTotal.toFixed(2) + calculateRating(tempHdrTotal.toFixed(2)),
      AprLv1Score:
        tempHdrAprlv1Total.toFixed(2) +
        calculateRating(tempHdrAprlv1Total.toFixed(2)),
      AprLv2Score:
        tempHdrAprlv2Total.toFixed(2) +
        calculateRating(tempHdrAprlv2Total.toFixed(2)),
      ATDetKey: -1,
    });
    return result;
  }

  function calculateTotalSummary(item) {
    let result = 0;

    if (item === undefined) {
      return result;
    } else if (item.ATDetType === 1) {
      result =
        (item.AppraisalSelection / item.AppraisalTotal) * item.AppraisalWeight;
    } else if (item.ATDetType === 2) {
      if (item.AppraisalSelection) result = item.AppraisalWeight;
    } else if (item.ATDetType === 3) {
      result = (item.AppraisalSelection / 10) * item.AppraisalWeight;
    } else if (item.ATDetType === 4) {
      result =
        (item.AppraisalSelection.length / item.AppraisalTotal) *
        item.AppraisalWeight;
    }
    return result;
  }

  function onChangeRadio(e) {
    form.resetFields([
      "EffectiveDate",
      "ActionReason",
      "ExtendMth",
      "ToGrade",
      "ToPost",
    ]);

    setCurrentAction(e.target.value);
    onDataChanged({
      ...appraisalSubmission,
      Type: e.target.value,
    });
  }

  function getAppraisalRatingData() {
    let tempData = appraisalRating.AppraisalRatingDet.slice().reverse();
    return tempData;
  }

  function calculateRating(value) {
    let lastItem = appraisalRating.AppraisalRatingDet.at(-1);
    let decimalPoints = appraisalRating.ARDecimalPoints;

    if (lastItem !== undefined) {
      if (lastItem.ARDetKey !== -1) {
        let tempRating = (value / 100) * lastItem.ARDetMaxRange;
        tempRating = tempRating.toFixed(decimalPoints);
        return " (" + tempRating + ")";
      }
    }

    return "";
  }

  function formatLegend(min, max) {
    let decimalPoints = appraisalRating.ARDecimalPoints;
    return min.toFixed(decimalPoints) + " - " + max.toFixed(decimalPoints);
  }

  function printAppraisal() {
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );
    setReportParam({
      UserKey: empyInfo.UserKey,
      AppraisalTransHdrKey: AppraisalTransHdrKey,
      APKey: appraisalSubmission.APKey,
      EmpyKey: empyInfo.EmpyKey,
      EmpyIDName: empyInfo.EmpyID + " - " + empyInfo.EmpyName,
      Position: empyInfo.PostDesc,
      Grade: empyInfo.GradeDesc,
      Department: empyInfo.DeptDesc,
      Appraiser: empyInfo.Appraiser,
      AppraisalCodeDesc: appraisalSubmission.APCodeAPDesc,
    });
    setReportGenerate(true);
    setShowAppraisal(true);
  }

  function onCloseReportModal() {
    setShowAppraisal(false);
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <>
                <span className="form-title">
                  {t("appraisalSubmissionForm.appraisal_form")}
                </span>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{
                    paddingLeft: 10,
                    display: appraisalSubmission.IsVerified ? "" : "none",
                  }}
                />
                <Tag
                  color="green"
                  style={{
                    marginLeft: 10,
                    display:
                      appraisalSubmission.IsRequireValidation &&
                      appraisalSubmission.IsValidated
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.validated")}
                </Tag>
              </>
            }
          >
            <Spin spinning={isLoading}>
              <Form {...formItemLayout} onFinish={handleSubmit}>
                <div
                  style={{
                    display: currentStep === 0 ? "block" : "none",
                  }}
                >
                  <Form.Item {...tailFormItemLayout}>
                    <span className="ant-form-text">
                      {appraisalSubmission.APCodeAPDesc}
                    </span>
                  </Form.Item>
                  <Form.Item label={t("general.employee_name")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.EmpyName}</span>
                  </Form.Item>
                  <Form.Item label={t("general.employee_id")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.EmpyID}</span>
                  </Form.Item>
                  <Form.Item label={t("general.position")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.PostDesc}</span>
                  </Form.Item>
                  <Form.Item label={t("general.grade")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.GradeDesc}</span>
                  </Form.Item>
                  <Form.Item label={t("general.department")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.DeptDesc}</span>
                  </Form.Item>
                  <Form.Item label={t("appraisalSubmissionForm.appraiser")}>
                    <span className="ant-form-text"> : </span>
                    <span className="ant-form-text">{empyInfo.Appraiser}</span>
                  </Form.Item>
                </div>
              </Form>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onValuesChange={() => setIsDirty(true)}
              >
                <div
                  style={{
                    display: currentStep === 1 ? "block" : "none",
                  }}
                >
                  {appraisalTemplate.AppraisalTemplateDet.filter(
                    (f) => f.ATDetInd === "H" && f.RowState !== RowState.DELETED
                  ).map(
                    (x, index) =>
                      index >= pagination.minIndex &&
                      index < pagination.maxIndex && (
                        <ManageAppraisalTemplatePreviewForm
                          key={x.ATDetKey}
                          sectionHeader={x}
                          sectionDetail={appraisalTemplate.AppraisalTemplateDet.filter(
                            (fd) =>
                              fd.RefATDetKey === x.ATDetKey &&
                              fd.RowState !== RowState.DELETED
                          )}
                          sectionAnswer={appraisalSubmission.Dets.filter((ad) =>
                            isLv1Appraiser
                              ? ad.UserRole === "aprlv1"
                              : isLv2Appraiser
                              ? ad.UserRole === "aprlv2"
                              : ad.UserRole === "user"
                          )}
                          sectionTag={appraisalSubmission.Dets.filter((ad) =>
                            isLv1Appraiser
                              ? ad.UserRole !== "aprlv1"
                              : isLv2Appraiser
                              ? ad.UserRole !== "aprlv2"
                              : ad.UserRole !== "user"
                          )}
                          onDataChanged={onDataChanged}
                          appraisalSubmission={appraisalSubmission}
                          isSelfAppraiseExp={isSelfAppraiseExp}
                          isReviewExp={isReviewExp}
                          isLv1Appraiser={isLv1Appraiser}
                          isLv2Appraiser={isLv2Appraiser}
                          isApproved={isEditableByApprover}
                        />
                      )
                  )}
                  <Pagination
                    defaultCurrent={1}
                    pageSize={1}
                    current={pagination.current}
                    total={
                      appraisalTemplate.AppraisalTemplateDet.filter(
                        (f) =>
                          f.ATDetInd === "H" && f.RowState !== RowState.DELETED
                      ).length
                    }
                    onChange={onChangePagination}
                    style={{ textAlign: "center" }}
                  />
                </div>

                <div
                  style={{
                    display: currentStep === 2 ? "block" : "none",
                  }}
                >
                  <h3 className="form-subtitle">
                    {t("appraisalSubmissionForm.summary")}
                  </h3>
                  <Table
                    dataSource={getFilterAppraisalData()}
                    rowKey={(record) => record.ATDetKey}
                    columns={columns}
                    size="middle"
                    bordered
                    pagination={false}
                  />

                  {/* Legend */}
                  <div
                    style={{
                      display: appraisalRating.ARKey !== -1 ? "block" : "none",
                    }}
                  >
                    <Divider orientation="left" style={{ color: "#0070cc" }}>
                      {t("general.legend")}
                    </Divider>

                    {getAppraisalRatingData().map((y) => (
                      <div
                        key={y.ARDetKey}
                        style={{ width: "100%", paddingBottom: "8px" }}
                      >
                        <Row>
                          <Col xs={24} lg={6}>
                            {y.ARDetDesc}
                          </Col>
                          <Col xs={24} lg={18}>
                            {formatLegend(y.ARDetMinRange, y.ARDetMaxRange)}
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>

                  {/*Summary */}
                  <div
                    style={{
                      display:
                        isLv1Appraiser || isLv2Appraiser ? "block" : "none",
                    }}
                  >
                    <Divider></Divider>
                    <h3 className="form-subtitle">
                      {t("appraisalSubmissionForm.actions")}
                    </h3>

                    <Row>
                      <Col xs={24} lg={24}>
                        <Form.Item
                          label={t("appraisalSubmissionForm.appraiser_comment")}
                          name="Remarks"
                          rules={[
                            {
                              max: 500,
                              message: t(
                                "appraisalTemplateForm.comments_max_length_validation"
                              ),
                            },
                          ]}
                          initialValue={appraisalSubmission.Remarks}
                        >
                          <TextArea
                            placeholder={t(
                              "appraisalTemplateForm.comments_placeholder"
                            )}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col xs={24} lg={24}>
                        <Form.Item
                          label={t(
                            "appraisalSubmissionForm.appraiser_recommendation"
                          )}
                          name="Type"
                          initialValue={
                            appraisalSubmission.Type !== undefined
                              ? appraisalSubmission.Type
                              : "NoChange"
                          }
                        >
                          <Radio.Group
                            onChange={onChangeRadio}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          >
                            <Space
                              direction="vertical"
                              style={{ marginBottom: 12 }}
                            >
                              <Radio style={radioStyle} value={"NoChange"}>
                                {t("appraisalSubmissionForm.no_changes")}
                              </Radio>
                              <Radio style={radioStyle} value={"Increment"}>
                                {t("appraisalSubmissionForm.increment")}
                              </Radio>
                              <Radio style={radioStyle} value={"Decrement"}>
                                {t("appraisalSubmissionForm.decrement")}
                              </Radio>
                              <Radio style={radioStyle} value={"Promotion"}>
                                {t("appraisalSubmissionForm.promotion")}
                              </Radio>
                              <Radio style={radioStyle} value={"Probation"}>
                                {t("appraisalSubmissionForm.confirm_probation")}
                              </Radio>
                              <Radio style={radioStyle} value={"ExtProbation"}>
                                {t(
                                  "appraisalSubmissionForm.extend_probation_period"
                                )}
                              </Radio>
                              <Radio style={radioStyle} value={"Termination"}>
                                {t("appraisalSubmissionForm.termination")}
                              </Radio>
                            </Space>
                          </Radio.Group>
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        lg={24}
                        style={{
                          display:
                            currentAction === "Increment" ||
                            currentAction === "Decrement" ||
                            currentAction === "Promotion" ||
                            currentAction === "Probation"
                              ? ""
                              : "none",
                        }}
                      >
                        <Form.Item
                          label={t(
                            "appraisalSubmissionForm.effective_month_year"
                          )}
                          name="EffectiveDate"
                          initialValue={
                            appraisalSubmission.EffectiveDate
                              ? dayjs(
                                  new Date(appraisalSubmission.EffectiveDate)
                                )
                              : defaultEffectiveYearMonth
                          }
                        >
                          <MonthPicker
                            allowClear={false}
                            placeholder={t(
                              "appraisalSubmissionForm.effective_month_year"
                            )}
                            format="MM/YYYY"
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        lg={11}
                        style={{
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <Form.Item
                          label={t("appraisalSubmissionForm.from_grade")}
                          name="FromGrade"
                          rules={[
                            {
                              required:
                                currentAction === "Promotion" ? true : false,
                              message: t(
                                "appraisalSubmissionForm.from_grade_required_validation"
                              ),
                            },
                          ]}
                          initialValue={
                            appraisalSubmission.FromGradeKey > 0
                              ? {
                                  key: appraisalSubmission.FromGradeKey,
                                }
                              : undefined
                          }
                        >
                          <SelectionInput
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            ref={React.createRef()}
                            labelInValue={true}
                            data={grades}
                            placeholder={t(
                              "appraisalSubmissionForm.from_grade_placeholder"
                            )}
                            valueProp={"Key"}
                            textProp={"CodeDesc"}
                            disabledProp={"Active"}
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        lg={2}
                        style={{
                          textAlign: "center",
                          marginTop: "28px",
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <RightOutlined />
                      </Col>
                      <Col
                        xs={24}
                        lg={11}
                        style={{
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <Form.Item
                          label={t("appraisalSubmissionForm.to_grade")}
                          name="ToGrade"
                          initialValue={
                            appraisalSubmission.ToGradeKey > 0
                              ? {
                                  key: appraisalSubmission.ToGradeKey,
                                }
                              : undefined
                          }
                        >
                          <SelectionInput
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            ref={React.createRef()}
                            data={grades}
                            placeholder={t(
                              "appraisalSubmissionForm.to_grade_placeholder"
                            )}
                            labelInValue={true}
                            valueProp={"Key"}
                            textProp={"CodeDesc"}
                            disabledProp={"Active"}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        lg={11}
                        style={{
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <Form.Item
                          label={t("appraisalSubmissionForm.from_position")}
                          name="FromPost"
                          rules={[
                            {
                              required:
                                currentAction === "Promotion" ? true : false,
                              message: t(
                                "appraisalSubmissionForm.from_position_required_validation"
                              ),
                            },
                          ]}
                          initialValue={
                            appraisalSubmission.FromPostKey > 0
                              ? {
                                  key: appraisalSubmission.FromPostKey,
                                }
                              : undefined
                          }
                        >
                          <SelectionInput
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            ref={React.createRef()}
                            data={positions}
                            placeholder={t(
                              "appraisalSubmissionForm.from_position_placeholder"
                            )}
                            labelInValue={true}
                            valueProp={"Key"}
                            textProp={"CodeDesc"}
                            disabledProp={"Active"}
                            disabled={true}
                          />
                        </Form.Item>
                      </Col>
                      <Col
                        xs={24}
                        lg={2}
                        style={{
                          textAlign: "center",
                          marginTop: "28px",
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <RightOutlined />
                      </Col>
                      <Col
                        xs={24}
                        lg={11}
                        style={{
                          display: currentAction === "Promotion" ? "" : "none",
                        }}
                      >
                        <Form.Item
                          label={t("appraisalSubmissionForm.to_position")}
                          name="ToPost"
                          initialValue={
                            appraisalSubmission.ToPostKey > 0
                              ? {
                                  key: appraisalSubmission.ToPostKey,
                                }
                              : undefined
                          }
                        >
                          <SelectionInput
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            ref={React.createRef()}
                            data={positions}
                            placeholder={t(
                              "appraisalSubmissionForm.to_position_placeholder"
                            )}
                            labelInValue={true}
                            valueProp={"Key"}
                            textProp={"CodeDesc"}
                            disabledProp={"Active"}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        lg={12}
                        style={{
                          display:
                            currentAction === "ExtProbation" ? "" : "none",
                        }}
                      >
                        <Form.Item
                          label={t("appraisalSubmissionForm.extended_period")}
                          name="ExtendMth"
                          rules={[
                            {
                              required:
                                currentAction === "ExtProbation" ? true : false,
                              message: t(
                                "appraisalSubmissionForm.extended_period_required_validation"
                              ),
                            },
                            {
                              validator: (_, value) => {
                                if (value < 1)
                                  return Promise.reject(
                                    new Error(
                                      t(
                                        "appraisalSubmissionForm.extended_period_must_be_>_0"
                                      )
                                    )
                                  );

                                return Promise.resolve();
                              },
                            },
                          ]}
                          initialValue={appraisalSubmission.ExtendMth}
                        >
                          <InputNumber
                            style={{ width: "100%" }}
                            step="1"
                            min={1}
                            precision={0}
                            formatter={inputLocaleFormatter(0)}
                            parser={inputLocaleParser(0)}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        xs={24}
                        lg={24}
                        style={{
                          display:
                            currentAction === "Promotion" ||
                            currentAction === "Termination"
                              ? ""
                              : "none",
                        }}
                      >
                        <Form.Item
                          label={t("general.reason")}
                          name="ActionReason"
                          rules={[
                            {
                              max: 500,
                              message: t(
                                "general.reason_max_length_500_validation"
                              ),
                            },
                            {
                              required:
                                currentAction === "Termination" ? true : false,
                              message: t("general.reason_required_validation"),
                            },
                          ]}
                          initialValue={
                            appraisalSubmission.ActionReason
                              ? appraisalSubmission.ActionReason
                              : ""
                          }
                        >
                          <TextArea
                            placeholder={t("general.reason_placeholder")}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            disabled={
                              isReviewExp ||
                              !(isLv1Appraiser || isLv2Appraiser) ||
                              isEditableByApprover
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>

                <Row className="save-form-section" style={{ marginTop: 10 }}>
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                        {/* Cancel */}
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.cancel")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={printAppraisal}
                          style={{
                            marginRight: 10,
                            display:
                              isStatusApproved(appraisalSubmission.Status) &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.print")}
                        </Button>
                        {/* Previous */}
                        <Button
                          size="large"
                          type="default"
                          onClick={prev}
                          style={{
                            marginRight: 10,
                            display: currentStep !== 0 ? "" : "none",
                          }}
                        >
                          <LeftOutlined />
                          {t("general.previous")}
                        </Button>
                        {/* Next */}
                        <Button
                          size="large"
                          type="default"
                          onClick={next}
                          style={{
                            marginRight: 10,
                            display: currentStep !== 2 ? "" : "none",
                          }}
                        >
                          {t("general.next")} <RightOutlined />
                        </Button>
                        {/* Save */}
                        <Button
                          size="large"
                          htmlType="submit"
                          style={{
                            marginRight: 10,
                            display:
                              currentStep === 2 &&
                              !isSystemRcd &&
                              !isViewOnly &&
                              ((dayjs().isBetween(
                                appraisalSubmission.FromSelfAppraisalDate,
                                appraisalSubmission.ToSelfAppraisalDate,
                                "day",
                                "[]"
                              ) &&
                                isOpen &&
                                isOwner) ||
                                (dayjs().isBetween(
                                  appraisalSubmission.FromReviewDate,
                                  appraisalSubmission.ToReviewDate,
                                  "day",
                                  "[]"
                                ) &&
                                  ((isLv1Appraiser && isSubmitted) ||
                                    (isLv2Appraiser && isPartialApproved))))
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        {/* Withdraw */}
                        <Button
                          size="large"
                          type="primary"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isSystemRcd &&
                              !isViewOnly &&
                              !(isLv1Appraiser || isLv2Appraiser) &&
                              dayjs().isBetween(
                                appraisalSubmission.FromSelfAppraisalDate,
                                appraisalSubmission.ToSelfAppraisalDate,
                                "day",
                                "[]"
                              )
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        {/* Submit */}
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            display:
                              currentStep === 2 &&
                              isOpen &&
                              isOwner &&
                              !isSystemRcd &&
                              !isViewOnly &&
                              dayjs().isBetween(
                                appraisalSubmission.FromSelfAppraisalDate,
                                appraisalSubmission.ToSelfAppraisalDate,
                                "day",
                                "[]"
                              )
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        {/* Approve */}
                        <Button
                          size="large"
                          type="primary"
                          onClick={showApproveConfirm}
                          style={{
                            display:
                              currentStep === 2 &&
                              (isLv1Appraiser || isLv2Appraiser) &&
                              (!isApproved ||
                                (isPartialApproved && isLv2Appraiser)) &&
                              !isSystemRcd &&
                              !isViewOnly &&
                              dayjs().isBetween(
                                appraisalSubmission.FromReviewDate,
                                appraisalSubmission.ToReviewDate,
                                "day",
                                "[]"
                              )
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.approve")}
                        </Button>

                        <Button
                          size="large"
                          type="primary"
                          onClick={showRevertConfirm}
                          style={{
                            display:
                              currentStep === 2 &&
                              (isLv1Appraiser || isLv2Appraiser) &&
                              isApproved &&
                              !(isPartialApproved && isLv2Appraiser) &&
                              !isSystemRcd &&
                              !isViewOnly &&
                              dayjs().isBetween(
                                appraisalSubmission.FromReviewDate,
                                appraisalSubmission.ToReviewDate,
                                "day",
                                "[]"
                              )
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.revert")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Modal
                  open={showAppraisal}
                  title={
                    <span className="form-subtitle">
                      {t("general.appraisal")}
                    </span>
                  }
                  okText="Save"
                  onOk={onCloseReportModal}
                  onCancel={onCloseReportModal}
                  destroyOnClose={true}
                  maskClosable={false}
                  footer={[
                    <Button key="back" onClick={onCloseReportModal}>
                      {t("general.close")}
                    </Button>,
                  ]}
                  width={"70%"}
                  style={{ top: 20, width: "100%" }}
                >
                  <div className="report-container">
                    <ReportViewer
                      Collapsed={false}
                      CustomStyle={true}
                      ReportParam={reportParam}
                      ReportName={reportName}
                      ReportGenerate={isReportGenerate}
                    />
                  </div>
                </Modal>
              </Form>
            </Spin>
          </Card>

          <EntryFormFooter
            footerData={appraisalSubmission}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              appraisalSubmission.Status === 4 ||
              appraisalSubmission.Status === 1
                ? true
                : false
            }
            showFooter={
              appraisalSubmission.AppraisalTransHdrKey !== -1 ? true : false
            }
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>
    </div>
  );
};

ManageAppraisalSubmissionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisalSubmission: PropTypes.object.isRequired,
  appraisalTemplate: PropTypes.object.isRequired,
  empyInfo: PropTypes.object.isRequired,
  grades: PropTypes.array.isRequired,
  positions: PropTypes.array.isRequired,
  appraisalRating: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
  onRevert: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isLv1Appraiser: PropTypes.bool,
  isLv2Appraiser: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageAppraisalSubmissionForm;
