import React, { useState, useEffect } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Table,
  Alert,
  Pagination,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { AddButton, EditButton, DeleteButton } from "../../common/TableCols";
import ManageAppraisalTemplateSectionForm from "../Master/ManageAppraisalTemplateSectionForm";
import ManageAppraisalTemplateQuestionForm from "../Master/ManageAppraisalTemplateQuestionForm";
import ManageAppraisalTemplatePreviewForm from "../Master/ManageAppraisalTemplatePreviewForm";
import AppraisalTemplateDetailModel from "../../model/AppraisalTemplate/AppraisalTemplateDetailModel";
import { RowState } from "../../model/common/RowState";
import { useTranslation } from "react-i18next";

const ManageAppraisalTemplateForm = ({
  isLoading,
  appraisalTemplate,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formSection] = Form.useForm();
  const [isFormSectionInitialized, setIsFormSectionInitialized] =
    useState(false);
  useEffect(
    () => formSection.resetFields(),
    [isFormSectionInitialized, formSection]
  );

  const [formQuestion] = Form.useForm();
  const [isFormQuestionInitialized, setIsFormQuestionInitialized] =
    useState(false);
  useEffect(
    () => formQuestion.resetFields(),
    [isFormQuestionInitialized, formQuestion]
  );

  const formats = { cellSpacing: 20 };
  const disableField =
    appraisalTemplate.RcdType === RecordType.USER ? false : true;
  const newSection = AppraisalTemplateDetailModel({});
  const isInUsed = appraisalTemplate.IsInUsed;
  const [selectedSection, setSelectedSection] = useState(newSection);
  const [selectedQuestion, setSelectedQuestion] = useState(newSection);
  const [showSection, setShowSection] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showErorrMsg, setshowErorrMsg] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 0,
  });

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  function next() {
    form
      .validateFields()
      .then(() => {
        let totalWeight = 0;
        let totalQuestionWeight = 0;
        let allowNextStep = false;
        let sectionError = "";

        if (appraisalTemplate.AppraisalTemplateDet.length > 0) {
          appraisalTemplate.AppraisalTemplateDet.forEach((y) => {
            if (y.ATDetInd === "H" && y.RowState !== RowState.DELETED) {
              totalWeight += y.ATDetWeight;
              totalQuestionWeight = 0;
              appraisalTemplate.AppraisalTemplateDet.filter(
                (x) => x.RefATDetKey === y.ATDetKey
              ).forEach((z) => {
                if (z.ATDetInd === "D" && z.RowState !== RowState.DELETED) {
                  totalQuestionWeight += z.ATDetWeight;
                }
              });

              if (totalQuestionWeight !== 100) {
                sectionError += y.ATDetTitle + ", ";
              }
            }
          });
        }

        if (currentStep === 0 && totalWeight === 100) {
          allowNextStep = true;
        } else if (currentStep === 1 && sectionError === "") {
          allowNextStep = true;
          setPagination({
            current: 1,
            minIndex: 0,
            maxIndex: 1,
          });
        } else if (currentStep === 1 && sectionError !== "") {
          let errorMsgs =
            t(
              "appraisalTemplateForm.total_criteria_validation_error_message_1"
            ) +
            sectionError.substring(0, sectionError.length - 2) +
            t(
              "appraisalTemplateForm.total_criteria_validation_error_message_2"
            );
          setErrorMsg(errorMsgs);
          setshowErorrMsg(true);
        }

        if (allowNextStep) {
          const current = currentStep + 1;
          setCurrentStep(current);
        }
      })
      .catch(() => {});
  }

  function prev() {
    const current = currentStep - 1;
    setCurrentStep(current);
    setshowErorrMsg(false);
  }

  //#region Appraisal Section
  const SectionActions = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => editSection(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display:
            record.RcdType === RecordType.USER && !isInUsed ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteSection(value)} />
      </Col>
    </Row>
  );

  const SectionSummaryActions = (value) => (
    <Row>
      <Col
        span={24}
        style={{
          display: !isInUsed ? "" : "none",
        }}
      >
        <AddButton fncAdd={() => addQuestionSection(value)} />
      </Col>
    </Row>
  );

  const sectionColumns = [
    {
      title: t("general.title"),
      dataIndex: "ATDetTitle",
      key: "ATDetTitle",
    },
    {
      title: t("appraisalTemplateForm.subtitle"),
      dataIndex: "ATDetSubTitle",
      key: "ATDetSubTitle",
    },
    {
      title: t("appraisalTemplateForm.relative_weight_percent"),
      dataIndex: "ATDetWeight",
      align: "right",
      key: "ATDetWeight",
      width: 170,
    },
    {
      title: t("general.action"),
      dataIndex: "ATDetKey",
      key: "SectionActions",
      align: "center",
      width: 100,
      render: SectionActions,
    },
  ];

  const sectionSummaryColumns = [
    {
      title: t("general.title"),
      dataIndex: "ATDetTitle",
      key: "ATDetTitle",
    },
    {
      title: t("appraisalTemplateForm.subtitle"),
      dataIndex: "ATDetSubTitle",
      key: "ATDetSubTitle",
    },
    {
      title: t("appraisalTemplateForm.relative_weight_percent"),
      dataIndex: "ATDetWeight",
      align: "right",
      key: "ATDetWeight",
      width: 170,
    },
    {
      title: t("general.action"),
      dataIndex: "ATDetKey",
      key: "SectionSummaryActions",
      align: "center",
      width: 100,
      render: SectionSummaryActions,
    },
  ];

  function getFilterAppraisalTemplateSectionData() {
    return appraisalTemplate.AppraisalTemplateDet.filter(
      (x) => x.RowState !== RowState.DELETED && x.ATDetInd === "H"
    );
  }

  function addNewSection() {
    setSelectedSection(newSection);
    setShowSection(true);
    setIsFormSectionInitialized(!isFormSectionInitialized);
  }

  function editSection(aTDetKey) {
    let refLimits = appraisalTemplate.AppraisalTemplateDet.filter(
      (item) => item.ATDetKey === aTDetKey
    );
    let selectedLimit = { ...refLimits };
    setSelectedSection(selectedLimit[0]);
    setShowSection(true);
    setIsFormSectionInitialized(!isFormSectionInitialized);
  }

  function deleteSection(aTDetKey) {
    //change state to DELETED for removal in db
    let newData = appraisalTemplate.AppraisalTemplateDet.filter(
      //exclude those new record which is added and deleted before save the form
      (y) =>
        !(
          (y.ATDetKey === aTDetKey || y.RefATDetKey === aTDetKey) &&
          y.RowState === RowState.ADDED
        )
    ).map((x) => {
      return (x.ATDetKey === aTDetKey || x.RefATDetKey === aTDetKey) &&
        x.RowState !== RowState.DELETED
        ? {
            ...x,
            RowState: RowState.DELETED,
            AppraisalSubDetail: deleteAllAnswer(x.AppraisalSubDetail),
          }
        : x;
    });
    onDataChanged({ ...appraisalTemplate, AppraisalTemplateDet: newData });
  }

  function saveSection(section) {
    if (section !== undefined || section !== null) {
      let index = appraisalTemplate.AppraisalTemplateDet.findIndex(
        (x) => x.ATDetKey === section.ATDetKey
      );
      if (index === -1) {
        onDataChanged({
          ...appraisalTemplate,
          AppraisalTemplateDet: [
            ...appraisalTemplate.AppraisalTemplateDet,
            { ...section },
          ],
        });
      } else {
        let temp = appraisalTemplate.AppraisalTemplateDet.map((item) =>
          item.ATDetKey === section.ATDetKey
            ? item.RowState === RowState.ADDED
              ? section
              : { ...section, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...appraisalTemplate, AppraisalTemplateDet: temp });
      }
      setShowSection(false);
    }
  }

  function cancelInputSection() {
    setSelectedSection(newSection);
    setShowSection(false);
  }

  //#endregion

  //#region Appraisal Question
  const SectionSummaryQuestionActions = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => editQuestion(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display:
            record.RcdType === RecordType.USER && !isInUsed ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteQuestion(value)} />
      </Col>
    </Row>
  );

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: t("appraisalTemplateForm.criteria"),
        dataIndex: "ATDetTitle",
        key: "ATDetTitle",
      },
      {
        title: t("general.description"),
        dataIndex: "ATDetSubTitle",
        key: "ATDetSubTitle",
      },
      {
        title: t("appraisalTemplateForm.relative_weight_percent"),
        dataIndex: "ATDetWeight",
        align: "right",
        key: "ATDetWeight",
        width: 170,
      },
      {
        title: t("general.action"),
        dataIndex: "ATDetKey",
        key: "SectionSummaryQuestionActions",
        align: "center",
        width: 100,
        render: SectionSummaryQuestionActions,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={getFilterAppraisalTemplateSectionQuestionData(
          record.ATDetKey
        )}
        rowKey={(record) => record.ATDetKey}
        pagination={false}
        footer={(pageData) => {
          let totalWeight = 0;
          let totalColor = "green";
          pageData.forEach(({ ATDetWeight }) => {
            totalWeight += ATDetWeight;
          });
          if (totalWeight !== 100) {
            totalColor = "red";
          }
          return (
            <>
              <Row>
                <Col
                  span={16}
                  style={{
                    width: "50%",
                    color: totalColor,
                    display: totalColor === "red" ? "block" : "none",
                  }}
                >
                  {t(
                    "appraisalTemplateForm.total_relative_weight_validation_error_message"
                  )}
                </Col>
                <Col
                  span={8}
                  style={{
                    textAlign: "right",
                    width: totalColor === "red" ? "50%" : "100%",
                    paddingRight: "52px",
                    color: totalColor,
                    display: totalColor === "red" ? "block" : "none",
                  }}
                >
                  {totalWeight} / 100%
                </Col>
                <Col
                  span={24}
                  style={{
                    textAlign: "right",
                    width: totalColor === "red" ? "50%" : "100%",
                    paddingRight: "52px",
                    color: totalColor,
                    display: totalColor !== "red" ? "block" : "none",
                  }}
                >
                  {totalWeight} / 100%
                </Col>
              </Row>
            </>
          );
        }}
      />
    );
  };

  function getFilterAppraisalTemplateSectionQuestionData(record) {
    return appraisalTemplate.AppraisalTemplateDet.filter(
      (x) =>
        x.RowState !== RowState.DELETED &&
        x.ATDetInd === "D" &&
        x.RefATDetKey === record
    );
  }

  function addQuestionSection(aTDetKey) {
    setSelectedQuestion({ ...newSection, RefATDetKey: aTDetKey });
    setShowQuestion(true);
    setshowErorrMsg(false);
    setIsFormQuestionInitialized(!isFormQuestionInitialized);
  }

  function editQuestion(aTSecQuestKey) {
    let refLimits = appraisalTemplate.AppraisalTemplateDet.filter(
      (item) => item.ATDetKey === aTSecQuestKey
    );
    let selectedLimit = { ...refLimits };
    setSelectedQuestion(selectedLimit[0]);
    setShowQuestion(true);
    setshowErorrMsg(false);
    setIsFormQuestionInitialized(!isFormQuestionInitialized);
  }

  function deleteQuestion(aTSecQuestKey) {
    setshowErorrMsg(false);
    //change state to DELETED for removal in db
    let newData = appraisalTemplate.AppraisalTemplateDet.filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.ATDetKey === aTSecQuestKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.ATDetKey === aTSecQuestKey && x.RowState !== RowState.DELETED
        ? {
            ...x,
            RowState: RowState.DELETED,
            AppraisalSubDetail: deleteAllAnswer(x.AppraisalSubDetail),
          }
        : x;
    });
    onDataChanged({ ...appraisalTemplate, AppraisalTemplateDet: newData });
  }

  function deleteAllAnswer(datas) {
    let newData = datas.map((x) => {
      return { ...x, RowState: RowState.DELETED };
    });

    return newData;
  }

  function saveQuestion(question) {
    if (question !== undefined || question !== null) {
      let index = appraisalTemplate.AppraisalTemplateDet.findIndex(
        (x) => x.ATDetKey === question.ATDetKey
      );
      if (index === -1) {
        onDataChanged({
          ...appraisalTemplate,
          AppraisalTemplateDet: [
            ...appraisalTemplate.AppraisalTemplateDet,
            { ...question },
          ],
        });
      } else {
        let temp = appraisalTemplate.AppraisalTemplateDet.map((item) =>
          item.ATDetKey === question.ATDetKey
            ? item.RowState === RowState.ADDED
              ? question
              : { ...question, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...appraisalTemplate, AppraisalTemplateDet: temp });
      }
      setShowQuestion(false);
    }
  }

  function cancelInputQuestion() {
    setSelectedQuestion(newSection);
    setShowQuestion(false);
  }

  function closeErrorMsg() {
    setshowErorrMsg(false);
  }
  //#endregion

  function onChangePagination(page) {
    setPagination({
      current: page,
      minIndex: (page - 1) * 1,
      maxIndex: page * 1,
    });
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("appraisalTemplateForm.appraisal_template_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={appraisalTemplate.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <div
                style={{
                  display: currentStep === 0 ? "block" : "none",
                }}
              >
                <Row
                  gutter={formats.cellSpacing}
                  style={{
                    marginTop: 16,
                  }}
                >
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t("appraisalTemplateForm.appraisal_template_code")}
                      name="ATCode"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t(
                            "appraisalTemplateForm.appraisal_template_code_required_validation"
                          ),
                        },
                        {
                          max: 100,
                          message: t(
                            "appraisalTemplateForm.appraisal_template_code_max_length_validation"
                          ),
                        },
                      ]}
                      initialValue={appraisalTemplate.ATCode}
                    >
                      <Input
                        placeholder={t(
                          "appraisalTemplateForm.appraisal_template_code_placeholder"
                        )}
                        disabled={disableField}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t("general.description")}
                      name="ATDesc"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.description_required_validation"),
                        },
                        {
                          max: 250,
                          message: t(
                            "general.description_max_length_validation"
                          ),
                        },
                      ]}
                      initialValue={appraisalTemplate.ATDesc}
                    >
                      <Input
                        placeholder={t("general.description_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <h3 className="form-subtitle">
                  {t("appraisalTemplateForm.sections")}
                </h3>
                <div>
                  <Table
                    dataSource={getFilterAppraisalTemplateSectionData()}
                    columns={sectionColumns}
                    size="middle"
                    pagination={false}
                    rowKey={(record) => record.ATDetKey}
                    bordered
                    footer={(pageData) => {
                      let totalWeight = 0;
                      let totalColor = "green";
                      pageData.forEach(({ ATDetWeight }) => {
                        totalWeight += ATDetWeight;
                      });
                      if (totalWeight !== 100) {
                        totalColor = "red";
                      }
                      return (
                        <>
                          <Row>
                            <Col
                              span={16}
                              style={{
                                width: "50%",
                                color: totalColor,
                                display:
                                  totalColor === "red" ? "block" : "none",
                              }}
                            >
                              {t(
                                "appraisalTemplateForm.total_relative_weight_validation_error_message_2"
                              )}
                            </Col>
                            <Col
                              span={8}
                              style={{
                                textAlign: "right",
                                width: totalColor === "red" ? "50%" : "100%",
                                paddingRight: "52px",
                                color: totalColor,
                                display:
                                  totalColor === "red" ? "block" : "none",
                              }}
                            >
                              {totalWeight} / 100%
                            </Col>
                            <Col
                              span={24}
                              style={{
                                textAlign: "right",
                                width: totalColor === "red" ? "50%" : "100%",
                                paddingRight: "52px",
                                color: totalColor,
                                display:
                                  totalColor !== "red" ? "block" : "none",
                              }}
                            >
                              {totalWeight} / 100%
                            </Col>
                          </Row>
                        </>
                      );
                    }}
                  />
                  <Button
                    type="primary"
                    style={{ marginTop: 16, display: !isInUsed ? "" : "none" }}
                    onClick={() => addNewSection()}
                  >
                    {t("general.add_new")}
                  </Button>
                </div>
                <ManageAppraisalTemplateSectionForm
                  isVisible={showSection}
                  section={selectedSection}
                  onSave={saveSection}
                  onCancel={cancelInputSection}
                  form={formSection}
                  setIsDirty={setIsDirty}
                />
              </div>

              <div
                style={{
                  display: currentStep === 1 ? "block" : "none",
                }}
              >
                <h3 className="form-subtitle">
                  {t("appraisalTemplateForm.sections")}
                </h3>
                <div>
                  <Table
                    dataSource={getFilterAppraisalTemplateSectionData()}
                    columns={sectionSummaryColumns}
                    size="middle"
                    pagination={false}
                    rowKey={(record) => record.ATDetKey}
                    bordered
                    expandedRowRender={expandedRowRender}
                  />
                </div>

                {showErorrMsg ? (
                  <Alert
                    message={errorMsg}
                    type="error"
                    afterClose={closeErrorMsg}
                    closable
                    showIcon
                    closeText={t("general.close_now")}
                  />
                ) : null}

                <ManageAppraisalTemplateQuestionForm
                  isVisible={showQuestion}
                  question={selectedQuestion}
                  onQuestionChanged={setSelectedQuestion}
                  onSave={saveQuestion}
                  onCancel={cancelInputQuestion}
                  inUsed={isInUsed}
                  setIsDirty={setIsDirty}
                  form={formQuestion}
                />
              </div>

              <div
                style={{
                  display: currentStep === 2 ? "block" : "none",
                }}
              >
                <h3 className="form-subtitle">
                  {t("appraisalTemplateForm.preview")}
                </h3>
                {appraisalTemplate.AppraisalTemplateDet.filter(
                  (f) => f.ATDetInd === "H" && f.RowState !== RowState.DELETED
                ).map(
                  (x, index) =>
                    index >= pagination.minIndex &&
                    index < pagination.maxIndex && (
                      <ManageAppraisalTemplatePreviewForm
                        key={x.ATDetKey}
                        sectionHeader={x}
                        sectionDetail={appraisalTemplate.AppraisalTemplateDet.filter(
                          (fd) =>
                            fd.RefATDetKey === x.ATDetKey &&
                            fd.RowState !== RowState.DELETED
                        )}
                      />
                    )
                )}
                <Pagination
                  defaultCurrent={1}
                  pageSize={1}
                  current={pagination.current}
                  total={
                    appraisalTemplate.AppraisalTemplateDet.filter(
                      (f) =>
                        f.ATDetInd === "H" && f.RowState !== RowState.DELETED
                    ).length
                  }
                  onChange={onChangePagination}
                  style={{ textAlign: "center" }}
                />
              </div>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="link"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="default"
                  onClick={prev}
                  style={{
                    marginRight: "5px",
                    display: currentStep !== 0 ? "" : "none",
                  }}
                >
                  <LeftOutlined />
                  {t("general.previous")}
                </Button>
                <Button
                  size="large"
                  type="default"
                  onClick={next}
                  style={{
                    display: currentStep !== 2 ? "" : "none",
                  }}
                >
                  {t("general.next")} <RightOutlined />
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{
                    display: currentStep === 2 ? "" : "none",
                  }}
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={appraisalTemplate}
              showFooter={appraisalTemplate.ATKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageAppraisalTemplateForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisalTemplate: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageAppraisalTemplateForm;
