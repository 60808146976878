export default function RestDayProfileModel({
  WDKey = -1,
  UserKey = -1,
  EmpyID = "",
  EmpyName = "",
  Email = "",
  Sun = false,
  Mon = false,
  Tue = false,
  Wed = false,
  Thu = false,
  Fri = false,
  Sat = false,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
}) {
  return Object.freeze({
    WDKey: WDKey,
    UserKey: UserKey,
    EmpyID: EmpyID,
    EmpyName: EmpyName,
    Email: Email,
    Sun: Sun,
    Mon: Mon,
    Tue: Tue,
    Wed: Wed,
    Thu: Thu,
    Fri: Fri,
    Sat: Sat,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
  });
}
