import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import NotifConfgForm from "../../forms/AdminPortal/NotificationConfgForm";
import { Form } from "antd";
import { getNotifConfgUser } from "../../../api/notificationConfgApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export default function NotificationConfgPage({ history }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [notifConfgs, setNotifConfgs] = useState([]);
  const [filteredNotifConfgs, setFilteredNotifConfgs] = useState([]);
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const location = useLocation();
  //remain ou and dept key state when close input form
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOuKey : 0
  );    
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [rowSelected, setRowSelected] = useState({});
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getNotifConfg, []);

  function getNotifConfg(){
    setIsLoading(true);
    let promises = [getNotifConfgUser(), getOU(), getDepartment()];

    Promise.all(promises)
      .then((values) => {
        setNotifConfgs(values[0] ? values[0] : []);
        setFilteredNotifConfgs(
          values[0].filter((x) =>
            (ouKey === 0 || x.OUKey === ouKey) &&
            (deptKey === 0 || x.DeptKey === deptKey))
        );
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function editNotifConfg(userKey) {
    history("/home/adminportal/settings/notification/" + userKey, {
      state: {
        filterOuKey: ouKey,
        filterDeptKey: deptKey,
        currentPage: currentPage,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  function filterRecord(ouKey, deptKey){
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setFilteredNotifConfgs(
      notifConfgs.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey)
      )
    )
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    setRowSelected(value);
  }

  return (
    <NotifConfgForm
      isLoading={isLoading}
      notifConfgs={filteredNotifConfgs}
      onEdit={editNotifConfg}
      allOus={allOus}
      allDepartments={allDepartments}
      filterRecord={filterRecord}
      ouKey={ouKey}
      deptKey={deptKey}
      form={form}
      rowSelected={rowSelected}
      onRowSelected={onRowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

NotificationConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
};