export default function AppraisalRatingModel({
  ARKey = -1,
  ARCode = "",
  ARDesc = "",
  ARDecimalPoints = 0,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
  AppraisalRatingDet = [],
}) {
  return Object.freeze({
    ARKey: ARKey,
    ARCode: ARCode,
    ARDesc: ARDesc,
    ARDecimalPoints: ARDecimalPoints,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
    AppraisalRatingDet: AppraisalRatingDet,
  });
}
