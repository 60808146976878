import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "vehiclerequisitionapproval/";

export function getPendingApprovalVehicleRequisitions() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalVehicleRequisitions",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedVehicleRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedVehicleRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidVehicleRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidVehicleRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledVehicleRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledVehicleRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAssignedVehicleRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAssignedVehicleRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveVehicleRequisitions(vehicleRequisition) {
  return axios({
    method: "POST",
    url: base + "ApproveVehicleRequisitions",
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then()
    .catch(handleError);
}

export function rejectVehicleRequisitions(
  vehicleRequisition,
  rejectReason,
  isAllowAmend
) {
  return axios({
    method: "POST",
    url: base + "RejectVehicleRequisitions",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: vehicleRequisition,
  })
    .then()
    .catch(handleError);
}

export function voidVehicleRequisitions(vehicleRequisition, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidVehicleRequisitions",
    headers: authHeader(),
    params: { reason: voidReason },
    data: vehicleRequisition,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledVehicleRequisitions(vehicleRequisition) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledVehicleRequisitions",
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledVehicleRequisitions(
  vehicleRequisition,
  rejectReason
) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledVehicleRequisitions",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: vehicleRequisition,
  })
    .then()
    .catch(handleError);
}
