import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "training/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllTrainings() {
  return axios({
    method: "GET",
    url: base + "GetAllTrainings",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getTraining(trainingKey) {
  return axios({
    method: "GET",
    url: base + "GetTraining",
    headers: authHeader(),
    params: {
      key: trainingKey,
      environment: env
    }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getTrainingTitle() {
  return axios({
    method: "GET",
    url: base + "GetTrainingTitle",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPublishedTrainings() {
  return axios({
    method: "GET",
    url: base + "GetPublishedTrainings",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addTraining(training) {
  return axios({
    method: "POST",
    url: base + "AddTraining",
    headers: authHeader(),
    data: training
  })
    .then()
    .catch(handleError);
}

export function updateTraining(training) {
  return axios({
    method: "PUT",
    url: base + "UpdateTraining",
    headers: authHeader(),
    params: { key: training.TrainingKey },
    data: training
  })
    .then()
    .catch(handleError);
}

export function deleteTraining(trainingKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteTraining",
    headers: authHeader(),
    params: { key: trainingKey }
  })
    .then()
    .catch(handleError);
}

