import React, { useEffect, useState } from "react";
import { ReportDisplayForm } from "../../forms/Report/ReportDisplayForm";
import { GetReportRoute } from "../../../mock/reportRoute";
import PropTypes from "prop-types";
import { useParams } from 'react-router-dom';

export default function ReportDisplayPage({ history }) {
  const [selectedReport, setSelectedReport] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const {ReportName} = useParams();

  useEffect(() => {
    let reportName = ReportName;
    if (reportName !== "") {
      let { UserRole } = JSON.parse(sessionStorage.getItem("auth"));
      let getSelectedReport = GetReportRoute(UserRole).filter(
        (x) => x.paramName === reportName
      );
      if (getSelectedReport.length > 0) {
        setSelectedReport(getSelectedReport[0]);
        setIsLoading(false);
      } else {
        history("/home/report");
      }
    } else {
      history("/home/report");
    }
  }, [ReportName, history]);

  return (
    <ReportDisplayForm isLoading={isLoading} selectedReport={selectedReport} />
  );
}

ReportDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};
