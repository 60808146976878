import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { localLogin } from "./api/apiUtils";

import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import ForgotPasswordPage from "./components/pages/ForgotPasswordPage";
import ExtendPage from "./components/pages/ExtendPage";
import PageNotFound from "./components/pages/PageNotFound";

localLogin();

const ProtectedRoute = () => {
  return sessionStorage.getItem("auth") ? <Outlet /> : <Navigate to="/" />;
};

function App() {
  return (
    <div className="container-fluid">
      <BrowserRouter>
        <Routes>
          <Route index path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
          <Route path={"/extend/:resetPassword"} element={<ExtendPage />} />
          <Route path="/" element={<ProtectedRoute />}>
            <Route path="home/*" element={<HomePage />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer autoClose={4000} hideProgressBar />
    </div>
  );
}

export default App;
