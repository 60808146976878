import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Excel from "exceljs";
import FileSaver from "file-saver";
import LeaveMigrationForm from "../../forms/Leave/LeaveMigrationForm";
import { getAllLeaveTypes } from "../../../api/leaveTypeApi";
import { getESSEmployee } from "../../../api/employeeApi";
import { importMigrationData } from "../../../api/leaveMigrationApi";
import { attachFiles } from "../../../api/fileUploadApi";
import { useTranslation } from "react-i18next";

export default function LeaveMigrationPage({ history }) {
  const { t } = useTranslation();
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [actionMsg, setActionMsg] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(validatePreCondition, [currentStep]);

  function validatePreCondition() {
    if (currentStep === 0) loadLeaveTypes();
    if (currentStep === 1) loadEmployees();
  }

  function loadLeaveTypes() {
    setIsLoading(true);
    getAllLeaveTypes()
      .then((value) => {
        setLeaveTypes(value);
        setIsLoading(false);
        if (value && value.length > 0) {
          setCurrentStep(1);
          removeMessage();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("migrationForm.fail_to_load_leave_types"), error);
      });
  }

  function loadEmployees() {
    setIsLoading(true);
    getESSEmployee()
      .then((value) => {
        setEmployees(value);
        setIsLoading(false);
        if (value && value.length > 0) {
          setCurrentStep(2);
          removeMessage();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("migrationForm.fail_to_load_employee"), error);
      });
  }

  function downloadTemplate() {
    setIsLoading(true);
    //set timeout so that loading can be shown before start generating template
    //without timeout, setState will be blocked until generate template completed
    setTimeout(function () {
      generateTemplate();
    }, 500);
  }

  function generateTemplate() {
    let workbook = new Excel.Workbook();

    createIdentityWorksheet(workbook);
    createEmployeeWorksheet(workbook);
    createLeaveTypeWorksheet(workbook);
    createSubmissionWorksheet(workbook).then(() => {
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          FileSaver.saveAs(new Blob([buffer]), "LeaveMigration_Template.xlsx");
          setIsLoading(false);
          removeMessage();
        })
        .catch((err) => {
          setIsLoading(false);
          showErrorMessage(t("migrationForm.error_writing_excel_export"), err);
        });
    });
  }

  function createIdentityWorksheet(workbook) {
    let identityWS = workbook.addWorksheet("Identity");

    identityWS.addRow(["ESS_Lintr@Max85_Leave"]);
    identityWS.state = "veryHidden";
  }

  function createEmployeeWorksheet(workbook) {
    let empyWS = workbook.addWorksheet("Employee");
    empyWS.columns = [
      { header: "User Key", key: "UserKey" },
      { header: "Employee ID_Name", key: "EmpyID_Name", width: 60 }
    ];

    let empyData = employees.map((x) => {
      return {
        UserKey: x.UserKey,
        EmpyID_Name: x.EmpyID + " - " + x.EmpyName
      };
    });
    empyWS.addRows(empyData);
    //hide worksheet so that user can't modify setup data
    empyWS.state = "veryHidden";
  }

  function createLeaveTypeWorksheet(workbook) {
    let leaveTypeWS = workbook.addWorksheet("LeaveType");
    leaveTypeWS.columns = [
      { header: "Key", key: "LeaveTypeKey" },
      { header: "Leave Type Code", key: "LeaveTypeCode", width: 20 },
      { header: "Description", key: "LeaveTypeDesc", width: 50 },
    ];

    let leaveData = leaveTypes.map((x) => {
      return {
        LeaveTypeKey: x.LTKey,
        LeaveTypeCode: x.LTCode,
        LeaveTypeDesc: x.LTDesc,
      };
    });
    leaveTypeWS.addRows(leaveData);
    //hide worksheet so that user can't modify setup data
    leaveTypeWS.state = "veryHidden";
  }

  async function createSubmissionWorksheet(workbook) {
    let submissionWS = workbook.addWorksheet("Leave Submission");

    submissionWS.columns = [
      {
        header: "Employee",
        key: "User",
        width: 60,
      },
      {
        header: "Leave Type",
        key: "LeaveType",
        width: 20,
      },
      {
        header: "From Date",
        key: "FromDate",
        width: 15,
        style: { numFmt: "YYYY-MM-DD" },
      },
      {
        header: "To Date",
        key: "ToDate",
        width: 15,
        style: { numFmt: "YYYY-MM-DD" },
      },
      {
        header: "No. Of Apply Day(s)",
        key: "TotalDays",
        width: 20,
        style: { numFmt: "#,##0.0" },
      },
      {
        header: "Emergency Leave",
        key: "IsEmergencyLeave",
        width: 20,
      },
      {
        header: "Applied Date",
        key: "AppliedDate",
        width: 15,
        style: { numFmt: "YYYY-MM-DD" },
      },
      {
        header: "Adjustment in Year",
        key: "Adjustment",
        width: 18,
      },
      {
        header: "Remarks",
        key: "Remarks",
        width: 100,
      },
    ];

    //unlock input columns (by default it is locked)
    submissionWS.getColumn("User").protection = { locked: false };
    submissionWS.getColumn("LeaveType").protection = { locked: false };
    submissionWS.getColumn("FromDate").protection = { locked: false };
    submissionWS.getColumn("ToDate").protection = { locked: false };
    submissionWS.getColumn("TotalDays").protection = { locked: false };
    submissionWS.getColumn("AppliedDate").protection = { locked: false };
    submissionWS.getColumn("Adjustment").protection = { locked: false };
    submissionWS.getColumn("Remarks").protection = { locked: false };

    //lock header row only
    submissionWS.getRow(1).protection = { locked: true };

    //set data validation for input columns
    //row 1 is header so start with row 2, set max to 10000 bcs more than that the file will become unreadable
    //set to 5000 for now to improve speed when saving edited file
    for (var i = 2; i <= 5000; i++) {
      let row = submissionWS.getRow(i);

      row.getCell("User").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ["Employee!$B$2:$B$65535"],
        errorStyle: "error",
        errorTitle: "Employee",
        error: "The value must be selected from the list",
      };

      row.getCell("LeaveType").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ["LeaveType!$B$2:$B$65535"],
        errorStyle: "error",
        errorTitle: "Leave Type",
        error: "The value must be selected from the list",
      };

      row.getCell("FromDate").dataValidation = {
        type: "date",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [new Date(1900, 0, 1)],
        errorStyle: "error",
        errorTitle: "From Date",
        error: "Invalid date",
        promptTitle: "From Date",
        prompt: "The value must be a valid date value",
      };

      row.getCell("ToDate").dataValidation = {
        type: "date",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [new Date(1900, 0, 1)],
        errorStyle: "error",
        errorTitle: "To Date",
        error: "Invalid date",
        promptTitle: "To Date",
        prompt: "The value must be a valid date value",
      };

      row.getCell("TotalDays").dataValidation = {
        type: "decimal",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        errorStyle: "error",
        errorTitle: "No of Apply Day(s)",
        error: "The value must be greater than 0",
        promptTitle: "No of Apply Day(s)",
        prompt: "The value must be greater than 0",
      };

      row.getCell("IsEmergencyLeave").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ['"Y, N"'],
        errorStyle: "error",
        errorTitle: "Emergency Leave",
        error: "The value must be selected from the list",
      };

      row.getCell("AppliedDate").dataValidation = {
        type: "date",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [new Date(1900, 0, 1)],
        errorStyle: "error",
        errorTitle: "Applied Date",
        error: "Invalid date",
        promptTitle: "Applied Date",
        prompt: "The value must be a valid date value",
      };

      row.getCell("Adjustment").dataValidation = {
        type: "decimal",
        operator: "greaterThanOrEqual",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        errorStyle: "error",
        errorTitle: "Adjustment in Year",
        error: "The value must be greater than or equals to 0",
        promptTitle: "Adjustment in Year",
        prompt: "The value must be greater than or equals to 0",
      };
    }

    await submissionWS.protect("Lintr@Max85");
  }

  function importData(fileList) {
    let file = fileList.length > 0 ? fileList[0] : undefined;
    if (file) {
      setIsLoading(true);
      removeMessage();

      let attachmentID = "Leave_" + new Date().getTime().toString();
      let formData = new FormData();
      formData.append("files[]", file);

      attachFiles(formData, "Migration", attachmentID)
        .then((result) => {
          importMigrationData(result.data[0].UploadedUrl)
            .then(() => {
              setIsLoading(false);
              setActionMsg(t("migrationForm.data_imported_successfully"));
              setFileList([]);
              setIsError(false);
            })
            .catch((err) => {
              setIsLoading(false);
              showErrorMessage(t("migrationForm.fail_to_import_data"), err);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          showErrorMessage(t("migrationForm.fail_to_upload_file"), err);
        });
    }
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    setActionMsg(message + (errorMsg ? errorMsg : ""));
    setIsError(true);
  }

  function removeMessage() {
    setActionMsg("");
    setIsError(false);
  }

  return (
    <LeaveMigrationForm
      history={history}
      isLoading={isLoading}
      currentStep={currentStep}
      loadEmployees={loadEmployees}
      onTemplateDownloaded={downloadTemplate}
      onDataImported={importData}
      fileList={fileList}
      setFileList={setFileList}
      actionMsg={actionMsg}
      isError={isError}
      removeMessage={removeMessage}
    />
  );
}

LeaveMigrationPage.propTypes = {
  history: PropTypes.func.isRequired,
};
