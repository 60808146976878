import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import JobRecruitmentApprovalForm from "../../forms/JobRecruitment/JobRecruitmentApprovalForm";
import {
  getPendingAndApprovedJobRecruitments,
  approveJobRecruitments,
  rejectJobRecruitments,
} from "../../../api/jobRecruitmentApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function JobRecruitmentApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [jobRecruitments, setJobRecruitments] = useState([]);
  const [selectedJobRecruitments, setSelectedJobRecruitments] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );

  useEffect(getJobRecruitments, [currentYear]);

  function getJobRecruitments() {
    setIsLoading(true);
    getPendingAndApprovedJobRecruitments(currentYear)
      .then((value) => {
        setJobRecruitments(value ? value : []);
        setSelectedJobRecruitments([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewJobRecruitment() {
    history("/home/jobrecruitment/jobrecruitments");
  }

  function onApproveJobRecruitments(selectedJobRecruitments) {
    setIsLoading(true);

    approveJobRecruitments(selectedJobRecruitments)
      .then(() => {
        getJobRecruitments();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectJobRecruitments(
    selectedJobRecruitments,
    reason,
    isAllowAmend
  ) {
    setIsLoading(true);

    rejectJobRecruitments(selectedJobRecruitments, reason, isAllowAmend)
      .then(() => {
        getJobRecruitments();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewJobRecruitment(jobRecruitmentKey) {
    history("/home/jobrecruitment/jobrecruitments/" + jobRecruitmentKey, {
      state: { fromApproval: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <JobRecruitmentApprovalForm
      isLoading={isLoading}
      data={jobRecruitments}
      selectedItems={selectedJobRecruitments}
      onAdd={onAddNewJobRecruitment}
      onView={viewJobRecruitment}
      onApproved={onApproveJobRecruitments}
      onRejected={onRejectJobRecruitments}
      onItemSelected={setSelectedJobRecruitments}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
}

JobRecruitmentApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
