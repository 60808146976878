import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseTravelAuthorization = API_ROOT + "travelAuthorization/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllTravel(month, year) {
  return axios
    .get(baseTravelAuthorization + "GetAllTravel", {
      headers: authHeader(),
      params: { month: month, year: year },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTravel(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseTravelAuthorization + "GetAllTravelsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTravelByTransKey(transKey) {
  return axios
    .get(baseTravelAuthorization + "GetTravelByTransKey", {
      headers: authHeader(),
      params: {
        transKey: transKey,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTravelToClaimByTransKey(transKey) {
  return axios
    .get(baseTravelAuthorization + "GetTravelToClaimByTransKey", {
      headers: authHeader(),
      params: {
        transKey: transKey,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllApprovedTravel(travelDate) {
  return axios({
    method: "GET",
    url: baseTravelAuthorization + "GetAllApprovedTravel",
    headers: authHeader(),
    params: { travelDate: travelDate },
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function addTravel(travel) {
  return axios({
    method: "POST",
    url: baseTravelAuthorization + "AddTravel",
    headers: authHeader(),
    data: travel,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateTravel(travel) {
  return axios({
    method: "POST",
    url:
      baseTravelAuthorization + "UpdateTravel?key=" + travel.TravelTransHdrKey,
    headers: authHeader(),
    data: travel,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawTravel(travelTransHdrKey, travel) {
  return axios({
    method: "POST",
    url: baseTravelAuthorization + "WithdrawTravel?key=" + travelTransHdrKey,
    headers: authHeader(),
    data: travel,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelTravel(
  travelTransHdrKey,
  travel,
  cancelReason
) {
  return axios({
    method: "POST",
    url: baseTravelAuthorization + "RequestCancel?key=" + travelTransHdrKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: travel,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteTravel(travelTransHdrKey, travel) {
  return axios({
    method: "POST",
    url: baseTravelAuthorization + "DeleteTravel?key=" + travelTransHdrKey,
    headers: authHeader(),
    data: travel,
  })
    .then(handleResponse)
    .catch(handleError);
}
