import React, { useEffect } from "react";
import { Modal, DatePicker, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const RepairDateInput = ({ isVisible, onSaved, onCancelled }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [isVisible, form]);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let fromDate = values.RepairDate[0];
        fromDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        let toDate = values.RepairDate[1];
        toDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        onSaved(fromDate.startOf("day").$d, toDate.startOf("day").$d);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("propMaintForm.repair_date_placeholder")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("propMaintForm.repair_date")}
          name="RepairDate"
          rules={[
            {
              required: true,
              message: t("propMaintForm.repair_date_required_validation"),
            },
          ]}
        >
          <RangePicker
            style={{ width: "100%" }}
            allowClear={true}
            showToday={false}
            format="DD/MM/YYYY"
            placeholder={[t("general.start_date"), t("general.end_date")]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

RepairDateInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export const RepairDateModal = RepairDateInput;
