import React, { useState, useEffect } from "react";
import ManageJobRecruitmentForm from "../../forms/JobRecruitment/ManageJobRecruitmentForm";
import JobRecruitmentModel from "../../model/JobRecruitment/JobRecruitmentModel";
import {
  getJobRecruitmentByKey,
  addJobRecruitment,
  updateJobRecruitment,
  deleteJobRecruitment,
  withdrawJobRecruitment,
  requestCancelJobRecruitment,
  feedbackJobRecruitment,
  completeJobRecruitment,
} from "../../../api/jobRecruitmentApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";
import { getUserCompCurrency } from "../../../api/currApi";
import { getOUCode } from "../../../api/ouApi";
import { getEmpyGrade } from "../../../api/empyGradeApi";

const newJobRecruitment = JobRecruitmentModel({});

const ManageJobRecruitmentPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const JOB_RECRUITMENT_FORM_MESSAGE = {
    JOB_RECRUITMENT_SAVED: t("jobRecruitmentForm.job_recruitment_saved"),
    JOB_RECRUITMENT_SUBMITTED: t(
      "jobRecruitmentForm.job_recruitment_submitted"
    ),
    JOB_RECRUITMENT_WITHDRAWN: t(
      "jobRecruitmentForm.job_recruitment_withdrawn"
    ),
    JOB_RECRUITMENT_DELETED: t("jobRecruitmentForm.job_recruitment_deleted"),
    JOB_RECRUITMENT_CANCEL_REQUEST: t(
      "jobRecruitmentForm.job_recruitment_cancel_request"
    ),
    JOB_RECRUITMENT_COMPLETE: t("jobRecruitmentForm.job_recruitment_complete"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newJobRecruitment);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isAssignor, setIsAssignor] = useState(false);
  const location = useLocation();
  const [userCompCurr, setUserCompCurr] = useState({});
  const [ous, setOUs] = useState([]);
  const [empyGrades, setEmpyGrades] = useState([]);

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/jobrecruitmentapproval");
      } else if (location.state.fromVoid) {
        history("/home/jobrecruitmentvoid");
      } else if (location.state.fromCancel) {
        history("/home/jobrecruitmentcancellationapproval");
      } else if (location.state.fromValidation) {
        history("/home/jobrecruitmentclosure");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/recruitment", {state: location.state});
      } else {
        history("/home/jobrecruitmentdisplay");
      }
    } else {
      history("/home/jobrecruitmentdisplay");
    }
  }

  function onSaved(jobRecruitmentData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...jobRecruitmentData,
      Title: jobRecruitmentData.Title.trim(),
      Description: jobRecruitmentData.Description.trim(),
    };

    let upsertJobRecruitmentData =
      dataSubmit.JobRecruitmentKey !== -1
        ? updateJobRecruitment
        : addJobRecruitment;

    return upsertJobRecruitmentData(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_SAVED
          : isStatusSubmitted(result.Status)
          ? JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_SUBMITTED
          : JOB_RECRUITMENT_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/jobrecruitment/jobrecruitments/", {
            state: { force: true },
          });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history(
            "/home/jobrecruitment/jobrecruitments/" + result.JobRecruitmentKey,
            {
              state: { force: true },
            }
          );
          loadPageData(result.JobRecruitmentKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onDelete(jobRecruitmentData) {
    setIsLoading(true);
    return deleteJobRecruitment({ ...editItem, ...jobRecruitmentData })
      .then(() => {
        toast.success(JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_DELETED);
        history("/home/jobrecruitment/jobrecruitments/", {
          state: { force: true },
        });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onWithdraw(jobRecruitmentData) {
    setIsLoading(true);
    return withdrawJobRecruitment({ ...editItem, ...jobRecruitmentData })
      .then(() => {
        toast.success(JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_WITHDRAWN);
        loadPageData(editItem.JobRecruitmentKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onRequestCancel(jobRecruitmentData, reason) {
    setIsLoading(true);
    return requestCancelJobRecruitment(
      {
        ...editItem,
        ...jobRecruitmentData,
      },
      reason
    )
      .then(() => {
        toast.success(
          JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_CANCEL_REQUEST
        );
        loadPageData(editItem.JobRecruitmentKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onFeedbackJobRecruitment(jobRecruitmentData) {
    setIsLoading(true);
    return feedbackJobRecruitment({
      ...editItem,
      ...jobRecruitmentData,
      ClosureRemarks: jobRecruitmentData.ClosureRemarks.trim(),
    })
      .then(() => {
        setIsDirty(false);
        toast.success(JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_SAVED);
        loadPageData(editItem.JobRecruitmentKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onCompleteJobRecruitment(jobRecruitmentData) {
    setIsLoading(true);
    return completeJobRecruitment({
      ...editItem,
      ...jobRecruitmentData,
      ClosureRemarks: jobRecruitmentData.ClosureRemarks.trim(),
    })
      .then(() => {
        setIsDirty(false);
        toast.success(JOB_RECRUITMENT_FORM_MESSAGE.JOB_RECRUITMENT_COMPLETE);
        loadPageData(editItem.JobRecruitmentKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "JobRecruitment")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];

    promises.push(getUserCompCurrency(), getOUCode(), getEmpyGrade());

    if (editKey) promises.push(getJobRecruitmentByKey(editKey));

    Promise.all(promises)
      .then((values) => {
        setUserCompCurr(values[0] ? values[0] : {});
        setOUs(values[1] ? values[1] : []);
        setEmpyGrades(values[2] ? values[2] : []);
        let jobRecruitmentToEdit = values[3];

        if (jobRecruitmentToEdit) {
          let editedFiles = jobRecruitmentToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });

          setFileList(editedFiles);

          jobRecruitmentToEdit.StatusDesc = Status[jobRecruitmentToEdit.Status];
          jobRecruitmentToEdit.TransDate = new Date(
            jobRecruitmentToEdit.TransDate
          );

          setEditItem(jobRecruitmentToEdit);
          setIsOwner(
            isLoginUser(jobRecruitmentToEdit.UserKey) ||
              isEditor(jobRecruitmentToEdit.CreatedBy)
          );
          setIsAssignor(jobRecruitmentToEdit.IsAssignor);
        } else {
          setFileList([]);
          setEditItem(newJobRecruitment);
          setIsOwner(true);
          setIsAssignor(false);
        }

        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.ExceptionMessage);
      });
  }

  return (
    <ManageJobRecruitmentForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onFeedbackJobRecruitment={onFeedbackJobRecruitment}
      onCompleteJobRecruitment={onCompleteJobRecruitment}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      editItem={editItem}
      onDataChanged={setEditItem}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      fileList={fileList}
      setFileList={setFileList}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      isAssignor={isAssignor}
      setIsDirty={setIsDirty}
      form={form}
      userCompCurr={userCompCurr}
      ous={ous}
      gradeSelectionData={empyGrades}
    />
  );
};

ManageJobRecruitmentPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageJobRecruitmentPage;
