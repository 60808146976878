import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import RoomRequisitionAssignmentForm from "../../forms/RoomReq/RoomRequisitionAssignmentForm";
import { getPendingAssignedRoomRequisitions } from "../../../api/roomRequisitionApprovalApi";
import { assignRoomAndExpenses } from "../../../api/roomRequisitionApi";
import { getAvailableRoomsForAssignment } from "../../../api/roomApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function RoomRequisitionAssignmentPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [roomRequisitions, setRoomRequisitions] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getRoomRequisitions, [currentYear]);

  function getRoomRequisitions() {
    setIsLoading(true);
    getPendingAssignedRoomRequisitions(currentYear)
      .then((value) => {
        setRoomRequisitions(value ? value : []);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function getAvailableRooms(roomRequisitionKey, onDataLoaded) {
    getAvailableRoomsForAssignment(roomRequisitionKey)
      .then((value) => {
        setAllRooms(value ? value : []);

        if (onDataLoaded != null) {
          onDataLoaded();
        }
      })
      .catch((error) => {
        showErrorMessage(
          t("roomRequisitionForm.fail_to_get_room_record"),
          error
        );
      });
  }

  function onAddNewRoomRequisition() {
    history("/home/meetingroomrequisition/roomtrans");
  }

  function onAssignRoom(selectedRoomRequisition) {
    setIsLoading(true);

    assignRoomAndExpenses(selectedRoomRequisition)
      .then(() => {
        getRoomRequisitions();
        toast.success(
          t("roomRequisitionForm.meeting_room_is_assigned_successfully")
        );
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("roomRequisitionForm.fail_to_assign_meeting_room_to_record"),
          error
        );
      });
  }

  function viewRoomRequisitions(roomRequisitionKey) {
    history("/home/meetingroomrequisition/roomtrans/" + roomRequisitionKey, {
      state: { fromAssignment: true },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <RoomRequisitionAssignmentForm
      isLoading={isLoading}
      data={roomRequisitions}
      allRooms={allRooms}
      onAdd={onAddNewRoomRequisition}
      onView={viewRoomRequisitions}
      onAssigned={onAssignRoom}
      getAvailableRooms={getAvailableRooms}
      setCurrentYear={setCurrentYear}
    />
  );
}

RoomRequisitionAssignmentPage.propTypes = {
  history: PropTypes.func.isRequired,
};
