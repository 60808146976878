import React from "react";
import { Input, Row, Col, Button, Switch, Card, Spin, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const RoomInputs = ({
  isLoading,
  room,
  ous,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const disableField = room.RcdType === RecordType.USER ? false : true;

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("meetingRoomForm.meeting_room_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={room.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("meetingRoomForm.meeting_room_code")}
                    name="RoomCode"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t(
                          "meetingRoomForm.meeting_room_code_required_validation"
                        ),
                      },
                      {
                        max: 100,
                        message: t(
                          "meetingRoomForm.meeting_room_code_max_length_validation"
                        ),
                      },
                    ]}
                    initialValue={room.RoomCode}
                  >
                    <Input
                      placeholder={t(
                        "meetingRoomForm.meeting_room_code_placeholder"
                      )}
                      disabled={disableField}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.description")}
                    name="RoomDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.description_max_length_validation"),
                      },
                    ]}
                    initialValue={room.RoomDesc}
                  >
                    <Input placeholder={t("general.description_placeholder")} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={formats.cellSpacing}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.operating_unit")}
                    style={{ margin: 0 }}
                    name="OU"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "general.operating_unit_required_validation"
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            !ous.some((x) => x.Key === value.value)
                          ) {
                            return Promise.reject(
                              new Error(
                                t("general.operating_unit_invalid_validation")
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    initialValue={
                      room.OUKey > 0
                        ? {
                            key: room.OUKey,
                            value: room.OUKey,
                            label: room.OUCodeDesc,
                          }
                        : undefined
                    }
                  >
                    <SelectionInput
                      ref={React.createRef()}
                      labelInValue={true}
                      data={ous}
                      valueProp={"Key"}
                      textProp={"CodeDesc"}
                      disabledProp={"Active"}
                      placeholder={t("general.operating_unit_placeholder_full")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={room}
              showFooter={room.RoomKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

RoomInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  room: PropTypes.object.isRequired,
  ous: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const ManageRoomForm = RoomInputs;
