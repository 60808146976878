import React, { useState, useEffect } from "react";
import {
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Select,
  Radio,
  Tooltip,
  Table,
  Divider,
  Checkbox,
  Tree,
  Tabs,
  Form,
} from "antd";
import EntitlementRuleForm from "../Leave/EntitlementRuleForm";
import PropTypes from "prop-types";
import LeaveTypeRuleModel from "../../model/Leave/LeaveTypeRuleModel";
import { RowState } from "../../model/common/RowState";
import { EditButton, DeleteButton } from "../../common/TableCols";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ManageLeaveTypeForm = ({
  isLoading,
  leaveType,
  gradeSelectionData,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formEntitlement] = Form.useForm();
  const [isFormEntitlementInitialized, setIsFormEntitlementInitialized] =
    useState(false);
  useEffect(
    () => formEntitlement.resetFields(),
    [isFormEntitlementInitialized, formEntitlement]
  );
  Form.useWatch("LTEntitlementRule", form);
  Form.useWatch("LTResetRule", form);
  Form.useWatch("IsLTELeave", form);

  const formats = { cellSpacing: 20 };
  const newEntitlementRule = LeaveTypeRuleModel({});
  const [activeTab, setActiveTab] = useState("0");
  const [showRuleForm, setShowRuleForm] = useState(false);
  const [selectedEntitlementRule, setSelectedEntitlementRule] =
    useState(newEntitlementRule);
  const disableField = leaveType.RcdType === RecordType.USER ? false : true;
  const company = leaveType.Company ? leaveType.Company : [];
  let checkedOUList =
    leaveType.checkedOUList.length > 0 ? leaveType.checkedOUList : [];

  function getFilterLeaveTypeRuleData() {
    return leaveType.LeaveTypeRule.filter(
      (x) => x.RowState !== RowState.DELETED
    );
  }

  const EntitleRuleActions = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => editEntitlementRule(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteEntitlementRule(value)} />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("leaveTypesForm.grade"),
      dataIndex: "EmpyGradeCodeGradeDesc",
      key: "EmpyGradeCodeGradeDesc",
    },
    {
      title: t("leaveTypesForm.effective_after_months"),
      dataIndex: "EffectiveAfter",
      align: "right",
      key: "EffectiveAfter",
      width: 170,
    },
    {
      title: t("leaveTypesForm.day_entitled"),
      dataIndex: "EntitledDay",
      align: "right",
      key: "EntitledDay",
      width: 110,
    },
    {
      title: t("leaveTypesForm.carry_forward"),
      dataIndex: "CarryForwardDay",
      align: "right",
      key: "CarryForwardDay",
      width: 110,
    },
    {
      title: t("general.action"),
      dataIndex: "LTRuleKey",
      key: "EntitleRuleActions",
      align: "center",
      width: 100,
      render: EntitleRuleActions,
    },
  ];

  function addNewEntitlementRule() {
    setSelectedEntitlementRule(newEntitlementRule);
    setShowRuleForm(true);
    setIsFormEntitlementInitialized(!isFormEntitlementInitialized);
  }

  function editEntitlementRule(lTRuleKey) {
    let refLimits = leaveType.LeaveTypeRule.filter(
      (item) => item.LTRuleKey === lTRuleKey
    );
    let selectedLimit = { ...refLimits };

    setSelectedEntitlementRule(selectedLimit[0]);
    setShowRuleForm(true);
    setIsFormEntitlementInitialized(!isFormEntitlementInitialized);
  }

  function deleteEntitlementRule(lTRuleKey) {
    //change state to DELETED for removal in db
    let newData = leaveType.LeaveTypeRule.filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.LTRuleKey === lTRuleKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.LTRuleKey === lTRuleKey && x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    });
    onDataChanged({ ...leaveType, LeaveTypeRule: newData });
  }

  function saveEntitlementRule(entitlementRule) {
    if (entitlementRule !== undefined || entitlementRule !== null) {
      let index = leaveType.LeaveTypeRule.findIndex(
        (x) => x.LTRuleKey === entitlementRule.LTRuleKey
      );

      if (index === -1) {
        onDataChanged({
          ...leaveType,
          LeaveTypeRule: [...leaveType.LeaveTypeRule, { ...entitlementRule }],
        });
      } else {
        let temp = leaveType.LeaveTypeRule.map((item) =>
          item.LTRuleKey === entitlementRule.LTRuleKey
            ? item.RowState === RowState.ADDED
              ? entitlementRule
              : { ...entitlementRule, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...leaveType, LeaveTypeRule: temp });
      }
      setShowRuleForm(false);
    }
  }

  function cancelInputEntitlementRule() {
    setSelectedEntitlementRule(newEntitlementRule);
    setShowRuleForm(false);
  }

  function validateEntitlementRule(entitlementRule) {
    if (entitlementRule !== undefined || entitlementRule !== null) {
      let index = leaveType.LeaveTypeRule.findIndex(
        (x) =>
          x.LTRuleKey !== entitlementRule.LTRuleKey &&
          x.EmpyGradeKey === entitlementRule.EmpyGradeKey &&
          x.EffectiveAfter === entitlementRule.EffectiveAfter
      );

      if (index === -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  function onChangeLTEDay(value) {
    onDataChanged({ ...leaveType, LTEDay: value });
  }

  function onChangeLTResetLimitDay(value) {
    onDataChanged({ ...leaveType, LTResetLimit: value });
  }

  const renderTreeNodes = (data) =>
    data.length === 0
      ? []
      : data.map((item) => {
          if (item.SelectionDetData) {
            return {
              title: item.CodeDesc,
              key: item.Code,
              children: renderTreeNodes(item.SelectionDetData),
            };
          }
          return {
            title: item.CodeDesc,
            key: item.Key,
          };
        });

  const onCheck = (checkedKeys) => {
    checkedOUList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedOUList.push(checkedKeys[index]);
      }
    }
    setIsDirty(true);
    onDataChanged({ ...leaveType, checkedOUList: checkedOUList });
  };

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      values["checkedOUList"] = checkedOUList;
      onSubmitted(values);
    });
  };

  const handleSubmitFailed = () => {
    setActiveTab("0");
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("leaveTypesForm.leave_type_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={leaveType.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={[
                  {
                    key: "0",
                    label: t("general.general"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("leaveTypesForm.leave_type_code")}
                              name="LTCode"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "leaveTypesForm.leave_type_code_required_validation"
                                  ),
                                },
                                {
                                  max: 100,
                                  message: t(
                                    "leaveTypesForm.leave_type_code_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={leaveType.LTCode}
                            >
                              <Input
                                placeholder={t(
                                  "leaveTypesForm.leave_type_code_placeholder"
                                )}
                                disabled={disableField}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("general.description")}
                              name="LTDesc"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "general.description_required_validation"
                                  ),
                                },
                                {
                                  max: 250,
                                  message: t(
                                    "general.description_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={leaveType.LTDesc}
                            >
                              <Input
                                placeholder={t(
                                  "general.description_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label={t("general.type")}
                              name="LTType"
                              initialValue={leaveType.LTType}
                            >
                              <Select
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                              >
                                <Option value={1}>
                                  {t("leaveTypesForm.paid")}
                                </Option>
                                <Option value={2}>
                                  {t("leaveTypesForm.unpaid")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label={t("leaveTypesForm.gender")}
                              name="LTGender"
                              initialValue={leaveType.LTGender}
                            >
                              <Select
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                              >
                                <Option value={0}>
                                  {t("leaveTypesForm.both")}
                                </Option>
                                <Option value={1}>
                                  {t("leaveTypesForm.male")}
                                </Option>
                                <Option value={2}>
                                  {t("leaveTypesForm.female")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("leaveTypesForm.entitlement_rule")}
                              name="LTEntitlementRule"
                              initialValue={leaveType.LTEntitlementRule}
                            >
                              <Radio.Group>
                                <Radio value={1}>
                                  {t(
                                    "leaveTypesForm.accrual_on_pro_rata_basis"
                                  )}
                                </Radio>
                                <br />
                                <Radio value={2}>
                                  {t(
                                    "leaveTypesForm.full_entitlement_on_pro_rata_basis"
                                  )}
                                </Radio>
                                <br />
                                <Radio value={3}>
                                  {t("leaveTypesForm.fixed_entitlement")}
                                </Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col
                            xs={24}
                            lg={12}
                            style={{
                              display:
                                form.getFieldValue("LTEntitlementRule") !== 3
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Form.Item
                              label={t("leaveTypesForm.rounding_rule")}
                              name="LTRoundingMethod"
                              initialValue={leaveType.LTRoundingMethod}
                            >
                              <Radio.Group>
                                <Tooltip
                                  title={t("leaveTypesForm.round_to_nearest")}
                                >
                                  <Radio value={1}>
                                    {t("leaveTypesForm.auto")}
                                  </Radio>
                                </Tooltip>
                                <Tooltip
                                  title={t("leaveTypesForm.always_round_down")}
                                >
                                  <Radio value={2}>
                                    {t("leaveTypesForm.round_down")}
                                  </Radio>
                                </Tooltip>
                                <Tooltip
                                  title={t("leaveTypesForm.always_round_up")}
                                >
                                  <Radio value={3}>
                                    {t("leaveTypesForm.round_up")}
                                  </Radio>
                                </Tooltip>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col span={24}>
                            <Form.Item
                              label={t("leaveTypesForm.reset_rule")}
                              name="LTResetRule"
                              initialValue={leaveType.LTResetRule}
                            >
                              <Radio.Group>
                                <Tooltip
                                  title={t("leaveTypesForm.reset_annually")}
                                >
                                  <Radio value={1}>
                                    {t("leaveTypesForm.annually")}
                                  </Radio>
                                </Tooltip>
                                <Tooltip
                                  title={t("leaveTypesForm.reset_per_event")}
                                >
                                  <Radio value={2}>
                                    {t("leaveTypesForm.event")}
                                  </Radio>
                                </Tooltip>
                                <Tooltip
                                  title={t("leaveTypesForm.never_reset")}
                                >
                                  <Radio value={3}>
                                    {t("leaveTypesForm.never_reset_message_1")}
                                    <InputNumber
                                      size="small"
                                      step="1"
                                      precision={0}
                                      min={0}
                                      formatter={inputLocaleFormatter(0)}
                                      parser={inputLocaleParser(0)}
                                      value={leaveType.LTResetLimit}
                                      disabled={
                                        form.getFieldValue("LTResetRule") === 3
                                          ? false
                                          : true
                                      }
                                      onChange={onChangeLTResetLimitDay}
                                    />
                                    {t("leaveTypesForm.never_reset_message_2")}
                                  </Radio>
                                </Tooltip>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col span={24}>
                            <Form.Item
                              label={t("leaveTypesForm.emergency_leave")}
                            >
                              <Form.Item
                                name="IsLTELeave"
                                valuePropName="checked"
                                initialValue={leaveType.IsLTELeave}
                              >
                                <Checkbox>
                                  {t("leaveTypesForm.emergency_leave_message")}
                                  <InputNumber
                                    size="small"
                                    step="1"
                                    precision={0}
                                    min={0}
                                    formatter={inputLocaleFormatter(0)}
                                    parser={inputLocaleParser(0)}
                                    value={leaveType.LTEDay}
                                    disabled={
                                      form.getFieldValue("IsLTELeave") === false
                                        ? true
                                        : false
                                    }
                                    onChange={onChangeLTEDay}
                                  />{" "}
                                  {t("general.days")}
                                </Checkbox>
                              </Form.Item>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t(
                                "leaveTypesForm.carry_forward_leave_effective_year"
                              )}
                              name="NoOfCarryForwardYear"
                              initialValue={leaveType.NoOfCarryForwardYear}
                            >
                              <Select
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                              >
                                <Option value={1}>1</Option>
                                <Option value={2}>2</Option>
                                <Option value={3}>3</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t(
                                "leaveTypesForm.carry_forward_leave_expiration_month"
                              )}
                              tooltip={t(
                                "leaveTypesForm.carry_forward_leave_expiration_tooltip"
                              )}
                              name="CFExpiryMth"
                              initialValue={leaveType.CFExpiryMth}
                            >
                              <Select
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                              >
                                <Option value={1}>{t("general.jan")}</Option>
                                <Option value={2}>{t("general.feb")}</Option>
                                <Option value={3}>{t("general.mar")}</Option>
                                <Option value={4}>{t("general.apr")}</Option>
                                <Option value={5}>{t("general.may")}</Option>
                                <Option value={6}>{t("general.jun")}</Option>
                                <Option value={7}>{t("general.jul")}</Option>
                                <Option value={8}>{t("general.aug")}</Option>
                                <Option value={9}>{t("general.sep")}</Option>
                                <Option value={10}>{t("general.oct")}</Option>
                                <Option value={11}>{t("general.nov")}</Option>
                                <Option value={12}>{t("general.dec")}</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col span={24}>
                            <Form.Item
                              name="IsIncludeHoliday"
                              valuePropName="checked"
                              initialValue={leaveType.IsIncludeHoliday}
                            >
                              <Checkbox>
                                {" "}
                                {t("leaveTypesForm.include_holiday_for_leave")}
                              </Checkbox>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider />
                        <h3 className="form-subtitle">
                          {t("leaveTypesForm.entitlement_rule")}
                        </h3>
                        <div>
                          <Table
                            dataSource={getFilterLeaveTypeRuleData()}
                            columns={columns}
                            size="middle"
                            pagination={false}
                            rowKey={(record) => record.LTRuleKey}
                            bordered
                          />
                          <Button
                            type="primary"
                            style={{ marginTop: 16 }}
                            onClick={() => addNewEntitlementRule()}
                          >
                            {t("general.add_new")}
                          </Button>
                        </div>
                        <EntitlementRuleForm
                          isVisible={showRuleForm}
                          entitlementRule={selectedEntitlementRule}
                          onSave={saveEntitlementRule}
                          onCancel={cancelInputEntitlementRule}
                          gradeSelectionData={gradeSelectionData}
                          onValidateEntitlementRule={validateEntitlementRule}
                          setIsDirty={setIsDirty}
                          form={formEntitlement}
                        />
                      </>
                    ),
                  },
                  {
                    key: "1",
                    label: t("general.permission"),
                    children: (
                      <Tree
                        checkable
                        defaultExpandAll={true}
                        defaultCheckedKeys={
                          leaveType.checkedOUList.length > 0
                            ? leaveType.checkedOUList
                            : []
                        }
                        onCheck={onCheck}
                        treeData={renderTreeNodes(company)}
                      ></Tree>
                    ),
                  },
                ]}
              ></Tabs>
            </Card>

            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={leaveType}
              showFooter={leaveType.LTKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageLeaveTypeForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  leaveType: PropTypes.object.isRequired,
  gradeSelectionData: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageLeaveTypeForm;
