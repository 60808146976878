import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import TravelAuthorizationDisplayAllForm from "../../../forms/Claim/Travel/TravelAuthorizationDisplayAllForm";
import { getAllTravel } from "../../../../api/travelAuthorizationApi";
import { getOU } from "../../../../api/ouApi";
import { getDepartment } from "../../../../api/deptApi";
import { getESSEmployee } from "../../../../api/employeeApi";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { StatusByHexColor } from "../../../../components/model/common/Status";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function TravelAuthorizationDisplayAllPage() {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [userTravelAuthorization, setUserTravelAuthorization] = useState([]);
  const [filteredUserTravelAuthorization, setFilteredUserTravelAuthorization] =
    useState([]);
  const [currentMonth, setCurrentMonth] = useState(dayjs().month() + 1);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [ouKey, setOUKey] = useState(0);
  const [deptKey, setDeptKey] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [userKey, setUserKey] = useState(0);

  useEffect(getTravels, []);

  function getTravels() {
    let promises = [
      getTravelAuthorizationDataSource(currentMonth, currentYear),
      getOU(),
      getDepartment(),
      getESSEmployee(),
    ];

    Promise.all(promises)
      .then((values) => {
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setAllUsers(values[3] ? values[3] : []);
        setInitialUsers(values[3] ? values[3] : []);
        setTimeout(() => {
          scrollToView();
        }, 300);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function scrollToView() {
    //scroll to current date to ease view
    let now = new Date();
    //date x cell width to get scroll position
    let date = now.getDate();
    var tableBody = document.querySelector("div.ant-table-body");
    if (tableBody != null) {
      tableBody.scrollLeft = (date - 1) * 35;
    }
  }

  function getListData(value, lvalue) {
    let listData = [];

    if (lvalue.length > 0) {
      let x = 0;
      let formatDate = value.startOf("day");
      for (x = 0; x < lvalue.length; x++) {
        let fromFormatDate = dayjs(lvalue[x].FromTravelDate).startOf("day");
        let toFormatDate = dayjs(lvalue[x].ToTravelDate).startOf("day");

        if (formatDate.isBetween(fromFormatDate, toFormatDate, "days", "[]")) {
          listData.push({
            type: StatusByHexColor[lvalue[x].Status],
            day: value.format("dddd"),
          });
          break;
        }
      }
    }

    return listData || [];
  }

  function dateCellRender(travelTransData, month, year) {
    const result = {};
    const map = new Map();

    for (let x = 1; x < 32; x++) {
      let tempToStringDate = x.toString().padStart(2, "0");
      let tempToStringMth = month.toString().padStart(2, "0");
      let tempToStringYr = year.toString().padStart(4, "0");
      let tempToStringFull =
        tempToStringYr + "-" + tempToStringMth + "-" + tempToStringDate;

      tempToStringDate = dayjs(tempToStringFull);

      const listData = getListData(tempToStringDate, travelTransData);

      if (listData.length > 0) {
        if (!map.has(x)) {
          map.set(x, true);
          result[x] = listData[0];
        }
      }
    }
    return result;
  }

  function getTravelAuthorizationDataSource(month, year) {
    setIsLoading(true);

    let promises = [getAllTravel(month, year)];

    Promise.all(promises)
      .then((values) => {
        let y = 0;
        let tempData = [];

        if (values[0]) {
          for (y = 0; y < values[0].length; y++) {
            let TravelTrans = values[0][y].TravelTrans
              ? values[0][y].TravelTrans
              : [];

            let travelAuthorizationData = dateCellRender(
              TravelTrans,
              month,
              year
            );

            travelAuthorizationData.EmpyID = values[0][y].EmpyID;
            travelAuthorizationData.EmployeeName = values[0][y].EmpyName;
            travelAuthorizationData.UserKey = values[0][y].UserKey;
            travelAuthorizationData.OUKey = values[0][y].OUKey;
            travelAuthorizationData.DeptKey = values[0][y].DeptKey;

            tempData.push(travelAuthorizationData);
          }
        }
        setUserTravelAuthorization(tempData);
        setFilteredUserTravelAuthorization(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey) &&
              (userKey === 0 || x.UserKey === userKey)
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function filterRecord(ouKey, deptKey, userKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setUserKey(userKey);
    setFilteredUserTravelAuthorization(
      userTravelAuthorization.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (userKey === 0 || x.UserKey === userKey)
      )
    );
  }

  return (
    <TravelAuthorizationDisplayAllForm
      isLoading={isLoading}
      userTravelAuthorization={filteredUserTravelAuthorization}
      getTravelAuthorizationDataSource={getTravelAuthorizationDataSource}
      setCurrentMonth={setCurrentMonth}
      setCurrentYear={setCurrentYear}
      allOus={allOus}
      allDepartments={allDepartments}
      allUsers={allUsers}
      initialUsers={initialUsers}
      setAllUsers={setAllUsers}
      filterRecord={filterRecord}
      form={form}
    />
  );
}

TravelAuthorizationDisplayAllPage.propTypes = {
  history: PropTypes.func.isRequired,
};
