import React, { useState } from "react";
import {
  ActiveCheckbox,
  EditButton,
  DeleteButton,
} from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const VehiclesForm = ({
  isLoading,
  vehicles,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const VehicleActiveCheckbox = (value) => <ActiveCheckbox isChecked={value} />;

  const [searchCodeText, setSearchCodeText] = useState("");
  const [searchDescText, setSearchDescText] = useState("");

  const VehicleActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearchCode = (selectedKeys, confirm) => {
    confirm();
    setSearchCodeText(selectedKeys[0]);
  };

  const handleResetCode = (clearFilters) => {
    clearFilters();
    setSearchCodeText("");
  };

  const handleSearchDesc = (selectedKeys, confirm) => {
    confirm();
    setSearchDescText(selectedKeys[0]);
  };

  const handleResetDesc = (clearFilters) => {
    clearFilters();
    setSearchDescText("");
  };

  const columns = [
    {
      title: t("vehicleForm.vehicle_code"),
      dataIndex: "VehicleCode",
      key: "VehicleCode",
      width: 200,
      sorter: (a, b) => a.VehicleCode.localeCompare(b.VehicleCode),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "VehicleCode",
        searchCodeText,
        handleSearchCode,
        handleResetCode,
        "Vehicle Code",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "VehicleDesc",
      key: "VehicleDesc",
      sorter: (a, b) => a.VehicleDesc.localeCompare(b.VehicleDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "VehicleDesc",
        searchDescText,
        handleSearchDesc,
        handleResetDesc,
        "Description",
        t
      ),
    },
    {
      title: t("general.active"),
      dataIndex: "Active",
      key: "Active",
      align: "center",
      width: 100,
      render: VehicleActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "VehicleKey",
      key: "VehicleActions",
      align: "center",
      width: 100,
      render: VehicleActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">{t("vehicleForm.vehicle_setup")}</span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          {t("general.new")}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={vehicles}
          columns={columns}
          size="middle"
          rowKey={(record) => record.VehicleKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

VehiclesForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vehicles: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
