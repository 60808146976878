import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col } from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import { JobRecruitmentList } from "./JobRecruitmentList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function JobRecruitmentClosureForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onValidated,
  onItemSelected,
  onAttachmentClicked,
  setCurrentYear,
}) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [tempItem, setTempItem] = useState();

  function submitNoHiringReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //no hiring single item
      onValidated([tempItem], false, reason);
    } else {
      //no hiring multiple items
      onValidated(selectedItems, false, reason);
    }
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected(data.filter((x) => !x.IsViewOnly));
      } else {
        onItemSelected([]);
      }
    }
  }

  function unsuccessfulHiring(selectedJobRecruitment) {
    setTempItem(selectedJobRecruitment);
    setShowReasonForm(true);
  }

  function unsuccessfulHirings() {
    setTempItem(null);
    setShowReasonForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="default"
          size="default"
          style={{
            marginRight: 10,
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => unsuccessfulHiring(item)}
        >
          {t("jobRecruitmentForm.hiring_unsuccessful")}
        </Button>
        <Button
          type="primary"
          size="default"
          style={{
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => onValidated([item], true)}
        >
          {t("jobRecruitmentForm.hiring_successful")}
        </Button>
      </div>,
    ];
  }

  const footerElement = () => (
    <Row>
      <Col span={6} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length &&
            selectedItems.length < data.filter((x) => !x.IsViewOnly).length
          }
          checked={
            selectedItems.length &&
            selectedItems.length === data.filter((x) => !x.IsViewOnly).length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={18} style={{ textAlign: "right" }}>
        <Button
          type="default"
          style={{ marginRight: "5px", marginLeft: "10px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={unsuccessfulHirings}
        >
          {t("jobRecruitmentForm.hiring_unsuccessful")}
        </Button>
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => onValidated(selectedItems, true)}
        >
          {t("jobRecruitmentForm.hiring_successful")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <JobRecruitmentList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        filterCheckedText={t(
          "jobRecruitmentForm.show_unvalidated_recruitment_only"
        )}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Hiring Unsuccessful"}
        onSaved={submitNoHiringReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
}

JobRecruitmentClosureForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onValidated: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
};
