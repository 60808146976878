import React, { useState } from "react";
import { Spin, Card, List, DatePicker } from "antd";
import { PropTypes } from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function EmployeeOutOfOfficeWidget({
  isLoading,
  attendance,
  travel,
  onChangeDate,
}) {
  const { t } = useTranslation();
  const [activeTabKey, setActiveTabKey] = useState(0);
  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const tabListHeader = [
    {
      key: 0,
      tab:
        t("employeeOnLeaveWidget.employee_on_leave") +
        (attendance.absent > 0 ? " (" + attendance.absent + ")" : ""),
    },
    {
      key: 1,
      tab:
        t("employeeOnTravelWidget.employee_on_travel") +
        (travel.travel > 0 ? " (" + travel.travel + ")" : ""),
    },
  ];

  const contentList = {
    0: (
      <div>
        <Spin
          style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
        />
        <div style={{ display: !isLoading ? "block" : "none" }}>
          <div
            style={{
              //marginTop: "10px",
              height: "323px",
              backgroundColor: "#f5f7fa",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={attendance.employeeAttendance}
              renderItem={(item) => (
                <List.Item>
                  <div
                    className="attendance-code"
                    style={{ color: item.attdColor }}
                  >
                    {item.attdCode}
                  </div>
                  <List.Item.Meta
                    style={{ padding: "0 10px 0 10px" }}
                    title={item.name}
                    description={item.LTDesc}
                  />
                  <div style={{ padding: "0 10px 0 10px" }}>
                    <span style={{ fontSize: "12px" }}>
                      {dayjs(
                        item.FromLeaveApplicationDate,
                        "YYYY-MM-DD"
                      ).format("DD/MM/YYYY")}
                      {" - "}
                      {dayjs(item.ToLeaveApplicationDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                      <br></br>
                      <center style={{ color: "rgba(0,0,0,0.45)" }}>
                        {item.TotalDay} {t("general.days")}
                      </center>
                    </span>
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div className="dashboard-card-button" style={{ textAlign: "right" }}>
            <DatePicker
              allowClear={false}
              format="DD/MM/YYYY"
              defaultValue={dayjs()}
              onChange={onChangeDate}
            />
          </div>
        </div>
      </div>
    ),
    1: (
      <div>
        <Spin
          style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
        />
        <div style={{ display: !isLoading ? "block" : "none" }}>
          <div
            style={{
              //marginTop: "10px",
              height: "323px",
              backgroundColor: "#f5f7fa",
              borderRadius: "10px",
              overflow: "auto",
            }}
          >
            <List
              itemLayout="horizontal"
              dataSource={travel.employeeOnTravel}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    style={{ padding: "0 10px 0 10px" }}
                    title={item.EmpyName}
                  />
                  <div style={{ padding: "0 10px 0 10px" }}>
                    <span style={{ fontSize: "12px" }}>
                      {dayjs(item.FromTravelDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                      {" - "}
                      {dayjs(item.ToTravelDate, "YYYY-MM-DD").format(
                        "DD/MM/YYYY"
                      )}
                      <br></br>
                      <center style={{ color: "rgba(0,0,0,0.45)" }}>
                        {dayjs(item.ToTravelDate, "YYYY-MM-DD").diff(
                          dayjs(item.FromTravelDate, "YYYY-MM-DD"),
                          "day"
                        ) + 1}{" "}
                        {t("general.days")}
                      </center>
                    </span>
                  </div>
                </List.Item>
              )}
            />
          </div>
          <div className="dashboard-card-button" style={{ textAlign: "right" }}>
            <DatePicker
              allowClear={false}
              format="DD/MM/YYYY"
              defaultValue={dayjs()}
              onChange={onChangeDate}
            />
          </div>
        </div>
      </div>
    ),
  };

  return (
    <Card
      tabList={tabListHeader}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      className="dashboard-card"
    >
      {contentList[activeTabKey]}
    </Card>
  );
}

EmployeeOutOfOfficeWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  attendance: PropTypes.object.isRequired,
  travel: PropTypes.object.isRequired,
  onChangeDate: PropTypes.func.isRequired,
};
