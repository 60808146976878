import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18nBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./locales/en/translation.json";
import translationID from "./locales/id/translation.json";
import translationMY from "./locales/my/translation.json";

const fallbackLng = ["en"];
const availableLanguages = ["en", "id", "my"];

const resources = {
  en: {
    translation: translationEN,
  },
  id: {
    translation: translationID,
  },
  my: {
    translation: translationMY,
  },
};

i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: {
      checkWhitelist: true, // options for language detection
    },
    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
