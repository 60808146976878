export default function TrainingModel({
  TrainingKey = -1,
  Title = "",
  Description = "",
  TrainingFromDate = new Date(),
  TrainingToDate = new Date(),
  Location = "",
  NoOfApplicants = -1,
  ApplicationStartDate = new Date(),
  ApplicationEndDate = new Date(),
  RequireFeedback = true,
  IsPublish = false,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
  Attaches = [],
  checkedOUList = [],
}) {
  return Object.freeze({
    TrainingKey: TrainingKey,
    Title: Title,
    Description: Description,
    TrainingFromDate: TrainingFromDate,
    TrainingToDate: TrainingToDate,
    Location: Location,
    NoOfApplicants: NoOfApplicants,
    ApplicationStartDate: ApplicationStartDate,
    ApplicationEndDate: ApplicationEndDate,
    RequireFeedback: RequireFeedback,
    IsPublish: IsPublish,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
    Attaches: Attaches,
    checkedOUList: checkedOUList,
  });
}
