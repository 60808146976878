import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "room/";

export function getAllRooms() {
  return axios({
    method: "GET",
    url: base + "GetAllRooms",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoom(roomKey) {
  return axios({
    method: "GET",
    url: base + "GetRoom",
    headers: authHeader(),
    params: { key: roomKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoomCode() {
  return axios({
    method: "GET",
    url: base + "getRoomCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAvailableRoomsForAssignment(key) {
  return axios({
    method: "GET",
    url: base + "GetAvailableRoomsForAssignment",
    headers: authHeader(),
    params: { roomRequisitionKey: key }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addRoom(room) {
  return axios({
    method: "POST",
    url: base + "AddRoom",
    headers: authHeader(),
    data: room
  })
    .then()
    .catch(handleError);
}

export function updateRoom(room) {
  return axios({
    method: "PUT",
    url: base + "UpdateRoom",
    headers: authHeader(),
    params: { key: room.RoomKey },
    data: room
  })
    .then()
    .catch(handleError);
}

export function deleteRoom(roomKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteRoom",
    headers: authHeader(),
    params: { key: roomKey }
  })
    .then()
    .catch(handleError);
}

