import { RowState } from "../common/RowState";

export default function AppraisalTemplateDetailModel({
  ATDetKey = Math.floor(Math.random() * 999999),
  ATKey = -1,
  RefATDetKey = -1,
  ATDetTitle = "",
  ATDetSubTitle = "",
  ATDetWeight = 1,
  ATDetInd = "",
  ATDetType = 1,
  ATDetComment = false,
  ATDetComments = "",
  RcdType = 0,
  RcdTypeDesc = "User",
  RowVersion = null,
  rowState = RowState.ADDED,
  AppraisalSubDetail = [],
}) {
  return Object.freeze({
    ATDetKey: ATDetKey,
    ATKey: ATKey,
    RefATDetKey: RefATDetKey,
    ATDetTitle: ATDetTitle,
    ATDetSubTitle: ATDetSubTitle,
    ATDetWeight: ATDetWeight,
    ATDetInd: ATDetInd,
    ATDetType: ATDetType,
    ATDetComment: ATDetComment,
    ATDetComments: ATDetComments,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    RowVersion: RowVersion,
    RowState: rowState,
    AppraisalSubDetail: AppraisalSubDetail,
  });
}
