import { handleResponse, handleError, authHeader } from "./apiUtils";
import Axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

const basicBase = API_ROOT;
const base = API_ROOT + "empy/";
const postBase = API_ROOT + "EmpyPerMasPost";

export const dataSourceService = {
  getDataSource,
  getBank,
  getCountry,
  getPendingData,
  getEmployeeData,
  getDashboardEmployeeProfile,
  getEmployeeChild,
  getEmployeeEdu,
  getAllPendingData,
  approveEmpy,
  rejectEmpy,
  saveEmployee,
  savePendingEmployee,
  requestAttachmentID,
  postAttachment,
  getEmpyPhoto,
  getEmpyID,
  getEmpyAttachment,
  postEmpyAttachment,
  deleteEmpyAttachment,
};

function getBank(source) {
  return Axios({
    method: "GET",
    url:
      basicBase +
      "BNMCode?$format=json&$orderby=BankName&$select=BNMCode,FormatCode,BankName",
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then(handleResponse)
    .catch(handleError);
}

function getCountry(source) {
  return Axios({
    method: "GET",
    url:
      basicBase +
      "Country?isFromGridRead=true&$orderby=CtryCode&$format=json&%24inlinecount=allpages&filter=Active%20eq%20true",
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then(handleResponse)
    .catch(handleError);
}

function getDataSource(dsInfo, source) {
  return Axios.get(
    dsInfo.name === "Bank"
      ? basicBase +
          "BNMCode?$format=json&$orderby=BankName&$select=BNMCode,FormatCode,BankName"
      : dsInfo.name === "Country"
      ? basicBase +
        "Country?isFromGridRead=true&$orderby=CtryCode&$format=json&%24inlinecount=allpages&filter=Active%20eq%20true"
      : basicBase,
    {
      headers: authHeader(),
      cancelToken: source.token,
    }
  )
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .then((data) => {
      let returnDS = data.map((rtnDS) => {
        return {
          key: rtnDS[dsInfo.key],
          value: rtnDS[dsInfo.key],
          text:
            rtnDS[dsInfo.text.Code] === "" ||
            typeof rtnDS[dsInfo.text.Code] == "undefined"
              ? rtnDS[dsInfo.text.Desc] === "" ||
                typeof rtnDS[dsInfo.text.Desc] == "undefined"
                ? ""
                : rtnDS[dsInfo.text.Desc]
              : rtnDS[dsInfo.text.Desc] === "" ||
                typeof rtnDS[dsInfo.text.Desc] == "undefined"
              ? rtnDS[dsInfo.text.Code]
              : rtnDS[dsInfo.text.Code] + " - " + rtnDS[dsInfo.text.Desc],
        };
      });
      return returnDS;
    })
    .catch(handleError);
}

function getPendingData(source) {
  return Axios.get(base + "GetEmployeeApproval", {
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getAllPendingData() {
  return Axios.get(base + "GetAllEmployeeApproval", {
    headers: authHeader(),
    params: {
      environment: env,
    },
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getEmployeeData(source) {
  return Axios.get(base + "EmpyPerMas?$format=json", {
    headers: authHeader(),
    params: {
      environment: env,
    },
    cancelToken: source.token,
  })
    .then((res) => {
      if (!sessionStorage.getItem("localUserKey")) {
        sessionStorage.setItem(
          "localUserKey",
          JSON.stringify(res.data[0].UserKey)
        );
      }
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getDashboardEmployeeProfile(source) {
  return Axios.get(base + "GetDashboardEmpyProfile", {
    headers: authHeader(),
    params: {
      environment: env,
    },
    cancelToken: source.token,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getESSEmployee() {
  return Axios.get(base + "GetESSEmployee", {
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

function getEmployeeChild(source) {
  return Axios.get(base + "EmpyChildDet?$format=json&%24inlinecount=allpages", {
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function getEmployeeEdu(source) {
  return Axios.get(base + "EmpyEduDet?$format=json&%24inlinecount=allpages", {
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then((res) => {
      return typeof res.data.value == "undefined" ? res.data : res.data.value;
    })
    .catch(handleError);
}

function saveEmployee(dataToPost, attachID, environment) {
  let env = environment === "qa" ? "qaa" : environment;
  return Axios({
    method: "POST",
    url:
      postBase +
      "?AttachmentID=%22" +
      attachID +
      "%22&FileExtension=.jpg&Environment=" +
      env,
    data: dataToPost,
    headers: authHeader(),
    // cancelToken: source.token
  })
    .then()
    .catch(handleError);
}

function savePendingEmployee(pendingEmpy, isNotificationSent = false) {
  return Axios({
    method: "POST",
    url: postBase + "/SaveEmployee?IsNotificationSent=" + isNotificationSent,
    data: pendingEmpy,
    headers: authHeader(),
    // cancelToken: source.token
  })
    .then()
    .catch(handleError);
}

function approveEmpy(empyDets) {
  return Axios({
    method: "POST",
    url: postBase + "/ApproveEmployee",
    headers: authHeader(),
    data: empyDets,
    // cancelToken: source.token
  })
    .then()
    .catch(handleError);
}

function rejectEmpy(empyDets, reason) {
  return Axios({
    method: "POST",
    url: postBase + "/RejectEmployee",
    headers: authHeader(),
    params: { reason: reason },
    data: empyDets,
    // cancelToken: source.token
  })
    .then()
    .catch(handleError);
}

function requestAttachmentID() {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  return Axios({
    url: basicBase + "Attach/RequestAttachmentID",
    method: "GET",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + auth.access_token,
    },
  }).then((res) => {
    return res.data;
  });
}

function getEmpyAttachment(refKey) {
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  return Axios({
    url: basicBase + "GetEmpyAttach?RefKey=" + refKey + "&Environment=" + env,
    method: "GET",
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarynA73Uz10KDPXQeZv",
      Authorization: "Bearer " + auth.access_token,
    },
  }).then((res) => {
    return res.data;
  });
}

function postEmpyAttachment(attachmentID, filename, dataToPost) {
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  return Axios({
    method: "POST",
    url:
      basicBase +
      "PostEmpyAttach?AttachmentID=%22" +
      attachmentID +
      "%22&FileName=" +
      filename +
      "&Environment=" +
      env,
    data: dataToPost,
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarynA73Uz10KDPXQeZv",
      Authorization: "Bearer " + auth.access_token,
    },
  });
}

function deleteEmpyAttachment(attachmentID, filename) {
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  return Axios({
    method: "DELETE",
    url:
      basicBase +
      "DeleteEmpyAttach?AttachmentID=" +
      attachmentID +
      "&FileName=" +
      filename +
      "&Environment=" +
      env,
    headers: {
      Authorization: "Bearer " + auth.access_token,
    },
  });
}

function postAttachment(attachID, imageData, environment) {
  let env = environment === "qa" ? "qaa" : environment;
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  return Axios({
    method: "POST",
    url:
      basicBase +
      "PostEmpyAttach?AttachmentID=%22" +
      attachID +
      "%22&FileName=Photo&FileExtension=.jpg&Environment=" +
      env,
    // data: dataToPost,
    data: imageData,
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarynA73Uz10KDPXQeZv",
      Authorization: "Bearer " + auth.access_token,
    },
  });
}

export function getEmployee() {
  return Axios({
    method: "GET",
    url: base + "GetEmployee",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmpyPhoto() {
  return Axios({
    method: "GET",
    url: base + "GetEmpyPhoto",
    headers: authHeader(),
    params: {
      environment: env,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmpyID(editKey) {
  if (editKey === undefined) editKey = -1;

  return Axios({
    method: "GET",
    url: base + "GetEmpyID",
    headers: authHeader(),
    params: {
      environment: env,
      transKey: editKey,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
