import React from "react";
import { Modal, InputNumber, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export const CurrExRateInput = ({
  isVisible,
  exRateCurrency,
  allCurrencies,
  onCancelled,
  onSaved,
  form,
  selectionData = [],
}) => {
  const { t } = useTranslation();

  function saveForm(e) {
    form
      .validateFields()
      .then((values) => {
        onSaved(values);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("exRateForm.currency_exchange_rate")}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical" preserve={false}>
        <Form.Item
          label={t("general.currency")}
          name="Currency"
          rules={[
            {
              required: true,
              message: t("general.currency_required_validation"),
            },
            {
              validator: (_, value) => {
                if (
                  value &&
                  !allCurrencies.some((x) => x.Key === value.value)
                ) {
                  return Promise.reject(
                    new Error(t("general.invalid_currency"))
                  );
                }

                return Promise.resolve();
              },
            },
          ]}
          initialValue={
            exRateCurrency && exRateCurrency.CurrKey !== -1
              ? {
                  key: exRateCurrency.CurrKey,
                  value: exRateCurrency.CurrKey,
                  label: exRateCurrency.CurrCodeDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            ref={React.createRef()}
            data={selectionData}
            valueProp={"Key"}
            textProp={"CodeDesc"}
            disabledProp={"Active"}
            placeholder={t("general.currency_placeholder")}
            labelInValue={true}
          />
        </Form.Item>
        <Form.Item
          label={t("general.exchange_rate")}
          name="ExRateFunc"
          rules={[
            {
              required: true,
              message: t("general.exchange_rate_required_validation"),
            },
          ]}
          initialValue={
            exRateCurrency && exRateCurrency.ExRateFunc
              ? exRateCurrency.ExRateFunc
              : 0
          }
        >
          <InputNumber
            step={0.1}
            min={0.000001}
            precision={6}
            style={{ width: "100%" }}
            formatter={inputLocaleFormatter(6)}
            parser={inputLocaleParser(6)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

CurrExRateInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  exRateCurrency: PropTypes.object.isRequired,
  allCurrencies: PropTypes.array.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  selectionData: PropTypes.array,
};

export const CurrExRateForm = CurrExRateInput;
