import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import ClinicVisitApprovalForm from "../../forms/Clinic/ClinicVisitApprovalForm";
import {
  getPendingAndApprovedClinics,
  approveClinics,
  rejectClinics,
} from "../../../api/clinicApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function ClinicVisitApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [clinicTrans, setClinicTrans] = useState([]);
  const [selectedClinicTrans, setSelectedClinicTrans] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );

  useEffect(getClinicTrans, [currentYear]);

  function getClinicTrans() {
    setIsLoading(true);
    getPendingAndApprovedClinics(currentYear)
      .then((value) => {
        setClinicTrans(value ? value : []);
        setSelectedClinicTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewClinic() {
    history("/home/clinicvisit/clinictrans");
  }

  function approveClinicTrans(selectedClinics) {
    setIsLoading(true);

    approveClinics(selectedClinics)
      .then(() => {
        getClinicTrans();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function rejectClinicTrans(selectedClinics, reason, isAllowAmend) {
    setIsLoading(true);

    rejectClinics(selectedClinics, reason, isAllowAmend)
      .then(() => {
        getClinicTrans();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewClinicTrans(clinicTransKey) {
    history("/home/clinicvisit/clinictrans/" + clinicTransKey, {
      state: { fromApproval: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ClinicVisitApprovalForm
      isLoading={isLoading}
      data={clinicTrans}
      selectedItems={selectedClinicTrans}
      onAdd={onAddNewClinic}
      onView={viewClinicTrans}
      onApproved={approveClinicTrans}
      onRejected={rejectClinicTrans}
      onItemSelected={setSelectedClinicTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
}

ClinicVisitApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
