import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button, Modal, Alert, Form } from "antd";
import { useTranslation } from "react-i18next";

const ReloginForm = ({
  submit,
  showLoginModal,
  logout,
  data,
  isLoading,
  errorMessage,
  showErrMsg,
  isValidated,
  handleClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (showLoginModal) {
      sessionStorage.removeItem("auth");
    }
  }, [showLoginModal]);
  const handleSubmit = async () => {
    const values = await form.validateFields();
    let loginData = { ...data, ...values, isValidated };
    submit(loginData);
  };

  return (
    <Modal
      title={t("general.login")}
      centered
      destroyOnClose={true}
      closable={false}
      maskClosable={false}
      open={showLoginModal}
      afterClose={() => {
        form.resetFields();
      }}
      footer={[
        <Button key="back" onClick={logout}>
          {t("general.logout")}
        </Button>,
        <Button
          type="primary"
          key="submit"
          loading={isLoading}
          onClick={handleSubmit}
        >
          {t("general.login")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        className="login-form"
        style={{ maxWidth: 340, margin: "0 auto" }}
      >
        <Alert
          style={{ marginBottom: 24 }}
          message={t("general.idle_message")}
          type="info"
          showIcon
        />

        {showErrMsg ? (
          <Alert
            message={errorMessage}
            type="error"
            afterClose={handleClose}
            showIcon
            style={{ marginBottom: 24 }}
          />
        ) : null}

        <Form.Item
          name={"email"}
          rules={[
            { required: true, message: t("general.email_required_validation") },
          ]}
          initialValue={data.email}
        >
          <Input
            size="large"
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={t("general.email_placeholder")}
            onChange={handleClose}
            disabled={true}
          />
        </Form.Item>
        <Form.Item
          name={"password"}
          rules={[
            {
              required: true,
              message: t("general.password_required_validation"),
            },
          ]}
          initialValue={data.password}
        >
          <Input
            type="password"
            size="large"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder={t("general.password_placeholder")}
            onChange={handleClose}
            disabled={isValidated}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ReloginForm.propTypes = {
  submit: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  showLoginModal: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  showErrMsg: PropTypes.bool.isRequired,
  isValidated: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default ReloginForm;
