export default function VehicleRequisitionModel({
  vehicleRequisitionKey = -1,
  vehicleRequisitionCode = "",
  vehicleRequisitionDesc = "",
  vehicleKey = null,
  transDate = new Date(),
  fromReqDate = null,
  toReqDate = null,
  noOfPassengers = 1,
  startKM = 0,
  endKM = 0,
  remarks = "",
  status = 0,
  statusDesc = "",
  docNum = "Auto No.",
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  attaches = [],
  passengers = []
}) {
  return Object.freeze({
    VehicleRequisitionKey: vehicleRequisitionKey,
    VehicleRequisitionCode: vehicleRequisitionCode,
    VehicleRequisitionDesc: vehicleRequisitionDesc,
    VehicleKey: vehicleKey,
    TransDate: transDate,
    FromReqDate: fromReqDate,
    ToReqDate: toReqDate,
    NoOfPassengers: noOfPassengers,
    StartKM: startKM,
    EndKM: endKM,
    Remarks: remarks,
    Status: status,
    StatusDesc: statusDesc,
    DocNum: docNum,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    Attaches: attaches,
    Passengers: passengers
  });
}
