import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "paycode/";

export function getPayrollReimbursementCode() {
  return axios({
    method: "GET",
    url: base + "GetPayrollReimbursementCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPayrollDeductionCode() {
  return axios({
    method: "GET",
    url: base + "GetPayrollDeductionCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}
