import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Input, Row, Col, Card, Button, Spin, Table, Form } from "antd";
import { EditButton } from "../../common/TableCols";
import { RowState } from "../../model/common/RowState";
import ManageLeaveAdjustmentLeaveTypeForm from "../Admin/ManageLeaveAdjustmentLeaveTypeForm";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const ManageLeaveAdjustmentForm = ({
  isLoading,
  leaveAdjustment,
  userInfo,
  onChangeLeaveAdjustment,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formAdjustment] = Form.useForm();
  const [isFormAdjustmentInitialized, setIsFormAdjustmentInitialized] =
    useState(false);
  useEffect(
    () => formAdjustment.resetFields(),
    [isFormAdjustmentInitialized, formAdjustment]
  );

  const formats = { cellSpacing: 20 };
  const [showLeaveTypeForm, setShowLeaveTypeForm] = useState(false);
  const [selectedLeaveType, setSelectedLeaveType] = useState({});
  const LeaveTypeEditButton = (value) => (
    <EditButton fncEdit={() => editLeaveType(value)} />
  );

  function editLeaveType(lTKey) {
    let tempValue = leaveAdjustment.filter((item) => item.LTKey === lTKey);
    let tempselectedLeaveType = { ...tempValue };

    setSelectedLeaveType(tempselectedLeaveType[0]);
    setShowLeaveTypeForm(true);
    setIsFormAdjustmentInitialized(!isFormAdjustmentInitialized);
  }

  function saveLeaveType(laLeaveTypeData) {
    if (laLeaveTypeData !== undefined || laLeaveTypeData !== null) {
      let temp = leaveAdjustment.map((item) =>
        item.LTKey === laLeaveTypeData.LTKey
          ? item.LeaveAdjustmentKey === -1
            ? { ...item, ...laLeaveTypeData, RowState: RowState.ADDED }
            : { ...item, ...laLeaveTypeData, RowState: RowState.MODIFIED }
          : item
      );
      onChangeLeaveAdjustment(temp);
      setShowLeaveTypeForm(false);
    }
  }

  function calculateNewLeave(
    LTKey,
    totalAvailableLeave,
    totalLeave,
    adjustmentDay
  ) {
    let temp = leaveAdjustment
      .map((x) => {
        return x.LTKey === LTKey
          ? {
              ...x,
              NewTotalAvailableLeave: totalAvailableLeave + adjustmentDay,
              NewTotalLeave: totalLeave + adjustmentDay,
            }
          : x;
      })
      .filter((item) => item.LTKey === LTKey);
    setSelectedLeaveType(temp[0]);
  }

  function cancelInputLeaveType() {
    setIsDirty(false);
    setSelectedLeaveType({});
    setShowLeaveTypeForm(false);
  }

  const handleSubmit = async () => {
    form
      .validateFields()
      .then(() => {
        let submittedData = { ...leaveAdjustment };
        onSubmitted(submittedData);
      })
      .catch(() => {});
  };

  const columns = [
    {
      title: t("general.leave_type"),
      dataIndex: "LTCodeLTDesc",
      key: "LTCodeLTDesc",
    },
    {
      title: t("leaveAdjustmentForm.leave_used"),
      dataIndex: "UsedLeave",
      key: "UsedLeave",
      width: 100,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("leaveAdjustmentForm.leave_balance"),
      dataIndex: "TotalAvailableLeave",
      key: "TotalAvailableLeave",
      width: 110,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("leaveAdjustmentForm.total_leave"),
      dataIndex: "TotalLeave",
      key: "TotalLeave",
      width: 100,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("leaveAdjustmentForm.adjustment_day"),
      dataIndex: "AdjustmentDay",
      key: "AdjustmentDay",
      width: 120,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("leaveAdjustmentForm.new_leave_balance"),
      dataIndex: "NewTotalAvailableLeave",
      key: "NewTotalAvailableLeave",
      width: 150,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("leaveAdjustmentForm.new_total_leave"),
      dataIndex: "NewTotalLeave",
      key: "NewTotalLeave",
      width: 140,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("general.action"),
      dataIndex: "LTKey",
      key: "EditLeaveType",
      align: "center",
      width: 50,
      render: LeaveTypeEditButton,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"horizontal"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("leaveAdjustmentForm.leave_adjustment")}
                </span>
              }
            >
              <Row gutter={formats.cellSpacing}>
                <Col span={12}>
                  <Form.Item
                    label={t("general.user_name")}
                    name="EmpyName"
                    initialValue={
                      userInfo.EmpyID === undefined
                        ? undefined
                        : userInfo.EmpyID + " - " + userInfo.EmpyName
                    }
                  >
                    <Input placeholder="Username" disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("general.email")}
                    name="Email"
                    initialValue={
                      userInfo.Email === undefined ? undefined : userInfo.Email
                    }
                  >
                    <Input placeholder="Email" disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              <div>
                <Table
                  dataSource={leaveAdjustment}
                  columns={columns}
                  size="middle"
                  pagination={false}
                  rowKey={(record) => record.LTKey}
                  bordered
                />
              </div>
            </Card>

            <ManageLeaveAdjustmentLeaveTypeForm
              isVisible={showLeaveTypeForm}
              leaveType={selectedLeaveType}
              onSave={saveLeaveType}
              onDataChanged={calculateNewLeave}
              onCancel={cancelInputLeaveType}
              setIsDirty={setIsDirty}
              form={formAdjustment}
            />

            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  type="default"
                  size="large"
                  onClick={onCancelled}
                  style={{ marginRight: 10 }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageLeaveAdjustmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  leaveAdjustment: PropTypes.array.isRequired,
  userInfo: PropTypes.object.isRequired,
  onChangeLeaveAdjustment: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageLeaveAdjustmentForm;
