import { RowState } from "../common/RowState";

export default function AppraisalTemplateSubDetailModel({
  ATSubDetKey = Math.floor(Math.random() * 999999),
  ATDetKey = -1,
  ATSubDetDesc = "Measure Definition",
  ATSubDetType = 1,
  ATSubDetSeqNo = 0,
  RcdType = 0,
  RcdTypeDesc = "User",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    ATSubDetKey: ATSubDetKey,
    ATDetKey: ATDetKey,
    ATSubDetDesc: ATSubDetDesc,
    ATSubDetType: ATSubDetType,
    ATSubDetSeqNo: ATSubDetSeqNo,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
