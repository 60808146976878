import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "roomrequisitionapproval/";

export function getPendingApprovalRoomRequisitions() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalRoomRequisitions",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedRoomRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedRoomRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidRoomRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidRoomRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledRoomRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledRoomRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAssignedRoomRequisitions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAssignedRoomRequisitions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveRoomRequisitions(roomRequisition) {
  return axios({
    method: "POST",
    url: base + "ApproveRoomRequisitions",
    headers: authHeader(),
    data: roomRequisition,
  })
    .then()
    .catch(handleError);
}

export function rejectRoomRequisitions(
  roomRequisition,
  rejectReason,
  isAllowAmend
) {
  return axios({
    method: "POST",
    url: base + "RejectRoomRequisitions",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: roomRequisition,
  })
    .then()
    .catch(handleError);
}

export function voidRoomRequisitions(roomRequisition, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidRoomRequisitions",
    headers: authHeader(),
    params: { reason: voidReason },
    data: roomRequisition,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledRoomRequisitions(roomRequisition) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledRoomRequisitions",
    headers: authHeader(),
    data: roomRequisition,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledRoomRequisitions(roomRequisition, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledRoomRequisitions",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: roomRequisition,
  })
    .then()
    .catch(handleError);
}
