import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { ExRateForm } from "../../forms/Master/ExRateForm";
import PropTypes from "prop-types";
import ExRateModel from "../../model/Master/ExRateModel";
import { getCurrency } from "../../../api/currApi";
import {
  getMonthlyExRate,
  getLastMonthExRateDets,
  isLastMonthExRateDetsExists,
  addMonthlyExRate,
  updateMonthlyExRate,
} from "../../../api/exRateApi";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);
const newExRate = ExRateModel({});
const defaultExDate = dayjs().startOf("month");
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function ExRatePage({ history, setIsDirty, isDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [isRemarksInitialized, setIsRemarksInitialized] = useState(false);
  const [currencies, setCurrencies] = useState([]);
  const [exRate, setExRate] = useState(newExRate);
  const [exDate, setExDate] = useState(defaultExDate);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => form.resetFields(), [isFormInitialized, form]);
  useEffect(() => form.resetFields(["Remarks"]), [isRemarksInitialized, form]);

  useEffect(() => {
    let promises = [getCurrency(), getMonthlyExRate(defaultExDate.format("L"))];

    Promise.all(promises)
      .then((values) => {
        setCurrencies(values[0] ? values[0] : []);

        if (values[1]) {
          if (values[1].CreatedDate) {
            values[1].CreatedOn = values[1].CreatedDate;
          }

          if (values[1].UpdatedDate) {
            values[1].UpdatedOn = values[1].UpdatedDate;
          }
        }

        setExRate(values[1] ? values[1] : newExRate);
        setExDate(values[1] ? dayjs(values[1].ExDate) : defaultExDate);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [t]);

  function getCurrExRate(exDate) {
    setIsLoading(true);

    if (exDate) {
      getMonthlyExRate(exDate.format("L"))
        .then((values) => {
          setIsDirty(false);
          if (values) {
            if (values.CreatedDate) {
              values.CreatedOn = values.CreatedDate;
            }
            if (values.UpdatedDate) {
              values.UpdatedOn = values.UpdatedDate;
            }
          }
          setExRate(values ? values : newExRate);
          setExDate(values ? dayjs(values.ExDate) : exDate);
          setIsLoading(false);
          setIsRemarksInitialized(!isRemarksInitialized);
        })
        .catch((error) => {
          showErrorMessage(t("general.fail_to_load_record"), error);
        });
    }
  }

  function getLastMonthExRateCurrencies(currentExDateMoment) {
    setIsLoading(true);

    let currentMoment = dayjs(currentExDateMoment);
    let prevMonth = currentMoment.subtract(1, "months").format("L");
    getLastMonthExRateDets(prevMonth)
      .then((values) => {
        setIsDirty(true);
        setExRate({ ...exRate, ExRateDets: values });
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("exRateForm.fail_to_load_detail_record"), error);
      });
  }

  function checkLastMonthDataExists(currentExDateMoment) {
    setIsLoading(true);

    let currentMoment = dayjs(currentExDateMoment);
    let prevMonth = currentMoment.subtract(1, "months").format("L");
    return isLastMonthExRateDetsExists(prevMonth)
      .then((value) => {
        setIsLoading(false);
        return value;
      })
      .catch((error) => {
        showErrorMessage(
          t("exRateForm.fail_to_check_last_month_record"),
          error
        );
      });
  }

  function saveCurrExRate(values) {
    let currExRateSubmit = {
      ...exRate,
      ExDate: exDate.toDate(),
      Remarks: values.Remarks.trim(),
    };

    let upsertCurrExRate =
      currExRateSubmit.ExRateHdrKey !== -1
        ? updateMonthlyExRate
        : addMonthlyExRate;

    setIsLoading(true);

    upsertCurrExRate(currExRateSubmit)
      .then(() => {
        getCurrExRate(values.ExDate);
        setIsDirty(false);

        let yearMonth =
          monthNames[exDate.toDate().getMonth()] +
          " " +
          exDate.toDate().getFullYear();

        let message =
          currExRateSubmit.ExRateHdrKey !== -1
            ? t("exRateForm.record_updated_successfully_ex_rate_message") +
              yearMonth +
              t(
                "exRateForm.record_added_updated_successfully_ex_rate_message_1"
              )
            : t("exRateForm.record_added_successfully_ex_rate_message") +
              yearMonth +
              t(
                "exRateForm.record_added_updated_successfully_ex_rate_message_1"
              );
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          currExRateSubmit.ExRateHdrKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputCurrExRate() {
    history("/home");
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ExRateForm
      isLoading={isLoading}
      exRate={exRate}
      currencies={currencies}
      defaultExDate={defaultExDate}
      exDate={exDate}
      onSubmitted={saveCurrExRate}
      onCancelled={cancelInputCurrExRate}
      onDataChanged={setExRate}
      getCurrExRate={getCurrExRate}
      getLastMonthExRateCurrencies={getLastMonthExRateCurrencies}
      checkLastMonthDataExists={checkLastMonthDataExists}
      setIsDirty={setIsDirty}
      isDirty={isDirty}
      form={form}
    />
  );
}

ExRatePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  isDirty: PropTypes.bool,
};
