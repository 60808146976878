import React from "react";
import PropTypes from "prop-types";
import { RoomRequisitionList } from "./RoomRequisitionList";
import { useTranslation } from "react-i18next";

const RoomRequisitionForm = ({
  isLoading,
  isFromAdmin = false,
  onAdd,
  onView,
  data,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) => {
  const { t } = useTranslation();

  const footerElement = (footerValue) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      {t("general.total")} :{" "}
      <b style={{ color: "#0070cc" }}>
        {data.length && data[0] ? data[0].FuncCurrSymb + " " : ""}
        {footerValue}
      </b>
    </div>
  );

  return (
    <div>
      <RoomRequisitionList
        isLoading={isLoading}
        isFromAdmin={isFromAdmin}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isShowStatus={true}
        isMultipleAction={false}
        footerElement={footerElement}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

RoomRequisitionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFromAdmin: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default RoomRequisitionForm;
