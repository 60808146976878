import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

// Will change to this method
//const baseUrl = process.env.API_URL + "/LeaveType/";

const base = API_ROOT + "APRLvlConfg/";

export function GetAPRLvlConfg() {
  return axios({
    method: "GET",
    url: base + "GetAPRLvlConfg",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function GetSelectedAPRLvlConfg(subjectKey) {
  return axios({
    method: "GET",
    url: base + "GetSelectedAPRLvlConfg",
    headers: authHeader(),
    params: { key: subjectKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function UpdateAPRLvlConfg(aprLvlConfg) {
  let urlString = base;

  urlString = base + "UpdateAPRLvlConfg";

  return axios({
    method: "PUT",
    url: urlString,
    headers: authHeader(),
    params: { key: aprLvlConfg.SubjectKey },
    data: aprLvlConfg
  })
    .then()
    .catch(handleError);
}
