import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "Notification/";

export function GetUserNotifications() {
  return axios({
    method: "GET",
    url: base + "GetUserNotifications",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function LoadUserNotificationsOnDemand(nextKey) {
  return axios({
    method: "GET",
    url: base + "LoadUserNotificationsOnDemand",
    headers: authHeader(),
    params: { key: nextKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function MarkNotificationAsRead(NotificationKey) {
  return axios({
    method: "PUT",
    url: base + "MarkNotificationAsRead",
    headers: authHeader(),
    params: { key: NotificationKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function MarkAllNotificationAsRead(typeKey) {
  return axios({
    method: "PUT",
    url: base + "MarkAllNotificationAsRead",
    headers: authHeader(),
    params: { key: typeKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function MarkSelectedNotificationAsRead(data) {
  return axios({
    method: "PUT",
    url: base + "MarkSelectedNotificationAsRead",
    headers: authHeader(),
    data: data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function MarkNotificationAsDelete(NotificationKey) {
  return axios({
    method: "PUT",
    url: base + "MarkNotificationAsDelete",
    headers: authHeader(),
    params: { key: NotificationKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function MarkSelectedNotificationAsDelete(data) {
  return axios({
    method: "PUT",
    url: base + "MarkSelectedNotificationAsDelete",
    headers: authHeader(),
    data: data,
  })
    .then(handleResponse)
    .catch(handleError);
}
