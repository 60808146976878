import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageAnnouncementForm from "../../forms/Master/ManageAnnouncementForm";
import PropTypes from "prop-types";
import AnnouncementModel from "../../model/Announcement/AnnouncementModel";
import {
  getAnnouncement,
  addAnnouncement,
  updateAnnouncement,
} from "../../../api/announcementApi";
import { attachFiles } from "../../../api/fileUploadApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newAnnouncement = AnnouncementModel({});

export default function ManageAnnouncementPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [announcement, setAnnouncement] = useState(newAnnouncement);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { AnnouncementKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = AnnouncementKey;
    if (editKey > 0) {
      promises.push(getAnnouncement(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
          if (values[0].CreatedDate) {
            values[0].CreatedOn = values[0].CreatedDate;
          }
        }
        setAnnouncement(values[0] ? values[0] : newAnnouncement);
        let tempAttaches = values[0] ? values[0].Attaches : [];
        tempAttaches.forEach((attachment) => {
          attachment.uid = attachment.AttchKey;
          attachment.name = attachment.FileName;
          attachment.status = "done";
          attachment.url = attachment.UploadedUrl;
        });
        setFileList(tempAttaches);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [AnnouncementKey]);

  function saveAnnouncement(values, attachment) {
    let announcementSubmit = {
      ...announcement,
      ...values,
      AnnouncementTitle: values.AnnouncementTitle.trim(),
      AnnouncementDesc: values.AnnouncementDesc.trim(),
    };

    if (attachment.data) {
      announcementSubmit = {
        ...announcementSubmit,
        Attaches: [...announcement.Attaches, ...attachment.data],
      };
    }

    let upsertAnnouncement =
      announcementSubmit.AnnouncementKey !== -1
        ? updateAnnouncement
        : addAnnouncement;
    setIsLoading(true);
    upsertAnnouncement(announcementSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/announcements", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          announcementSubmit.AnnouncementKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputAnnouncement() {
    history("/home/announcements", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Announcements")
      .then((result) => {
        return result;
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }

  return (
    <ManageAnnouncementForm
      isLoading={isLoading}
      announcement={announcement}
      onSubmitted={saveAnnouncement}
      onCancelled={cancelInputAnnouncement}
      onDataChanged={setAnnouncement}
      setIsDirty={setIsDirty}
      fileList={fileList}
      setFileList={setFileList}
      onUploadAttachment={onUploadAttachment}
      form={form}
    />
  );
}

ManageAnnouncementPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
