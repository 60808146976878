import React from "react";
import { Spin, Card } from "antd";
import PropTypes from "prop-types";

export const ReportDisplayForm = ({ isLoading, selectedReport }) => {
  let ReportTag = () => <></>;
  let ReportName = "";
  if (selectedReport.report !== undefined) {
    ReportTag = selectedReport.report;
    ReportName = selectedReport.name;
  }

  return (
    <Card title={<span className="form-title">{ReportName}</span>}>
      <Spin spinning={isLoading}>
        <ReportTag />
      </Spin>
    </Card>
  );
};

ReportDisplayForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedReport: PropTypes.object.isRequired,
};

export default ReportDisplayForm;
