import React from "react";
import { Table } from "antd";
import { EditButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const RoleTypesPermissionByRoleTable = ({ data, onEdited }) => {
  const { t } = useTranslation();
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isSuperAdmin = !auth
    ? false
    : auth.UserRoleKey === "1";

  const RoleTypesPermissionActionButtons = (value) => (
    <EditButton fncEdit={() => onEdited(value)} disabled={!isSuperAdmin && value === 1}/>
  );

  const columns = [
    {
      title: t("general.role"),
      dataIndex: "code",
      key: "code",
      width: 150,
    },
    {
      title: t("general.action"),
      dataIndex: "key",
      key: "editRoleTypesPermissionByRole",
      align: "center",
      width: 100,
      render: RoleTypesPermissionActionButtons,
    },
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        size="middle"
        pagination={false}
        rowKey={(record) => record.key}
      />
    </div>
  );
};

RoleTypesPermissionByRoleTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdited: PropTypes.func.isRequired,
};
