import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "propmaintapproval/";

export function getPendingApprovalPropMaints() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalPropMaints",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedPropMaints(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedPropMaints",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidPropMaints(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidPropMaints",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledPropMaints(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledPropMaints",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedPropMaints(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedPropMaints",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approvePropMaints(propMaints) {
  return axios({
    method: "POST",
    url: base + "ApprovePropMaints",
    headers: authHeader(),
    data: propMaints,
  })
    .then()
    .catch(handleError);
}

export function rejectPropMaints(propMaints, rejectReason, isAllowAmend) {
  return axios({
    method: "POST",
    url: base + "RejectPropMaints",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: propMaints,
  })
    .then()
    .catch(handleError);
}

export function voidPropMaints(propMaints, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidPropMaints",
    headers: authHeader(),
    params: { reason: voidReason },
    data: propMaints,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledPropMaints(propMaints) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledPropMaints",
    headers: authHeader(),
    data: propMaints,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledPropMaints(propMaints, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledPropMaints",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: propMaints,
  })
    .then()
    .catch(handleError);
}

export function validatePropMaints(propMaints, reason) {
  return axios({
    method: "POST",
    url: base + "ValidatePropMaints",
    headers: authHeader(),
    params: { reason: reason },
    data: propMaints,
  })
    .then()
    .catch(handleError);
}
