import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Upload,
  message,
  Form,
  Modal,
} from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import { RowState } from "../../model/common/RowState";
import { toast } from "react-toastify";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";
import {
  Status,
  isStatusPublished,
  isStatusOpen,
} from "../../model/common/Status";

const ManageAnnouncementForm = ({
  isLoading,
  announcement,
  onSubmitted,
  onCancelled,
  form,
  fileList,
  setFileList,
  onDataChanged,
  onUploadAttachment,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const { confirm } = Modal;
  const formats = { cellSpacing: 20 };
  const disableField = announcement.RcdType === RecordType.USER ? false : true;
  const isPublished = isStatusPublished(announcement.Status);
  const isOpen = isStatusOpen(announcement.Status);

  const handleSubmit = async () => {
    handleSubmitFunc(0);
  };

  function handleSubmitFunc(statusAction) {
    form.validateFields().then((values) => {
      let filterData = {
        ...values,
        Status: statusAction,
      };

      uploadAttachments()
        .then((result) => {
          onSubmitted(filterData, result);
        })
        .catch(() => {
          toast.error(t("general.fail_to_upload_file"));
        });
    });
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        let newData = announcement.Attaches.map((x) => {
          return x.uid === file.uid && x.RowState !== RowState.DELETED
            ? { ...x, RowState: RowState.DELETED }
            : x;
        });
        onDataChanged({ ...announcement, Attaches: newData });

        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
      },
      fileList,
    };
  }

  function showPublishConfirm() {
    form.validateFields().then(() => {
      confirm({
        title: t("general.publish_confirmation"),
        content: t(
          "announcementWidget.publish_announcement_confirmation_message"
        ),
        okText: t("general.yes"),
        cancelText: t("general.no"),
        onOk() {
          handleSubmitFunc(66);
        },
        onCancel() {},
      });
    });
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("announcementsForm.announcement_setup")}
                </span>
              }
              extra={
                <Form.Item
                  name="Active"
                  style={{ textAlign: "right", margin: 0 }}
                  valuePropName="checked"
                  initialValue={announcement.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={20}>
                  <Form.Item
                    label={t("general.title")}
                    name="AnnouncementTitle"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.title_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.title_max_length_validation"),
                      },
                    ]}
                    initialValue={announcement.AnnouncementTitle}
                  >
                    <Input
                      placeholder={t("general.title_placeholder")}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={4}>
                  <Form.Item
                    label={t("general.status")}
                    name="StatusDesc"
                    initialValue={Status[announcement.Status]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.description")}
                    name="AnnouncementDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                    ]}
                    initialValue={announcement.AnnouncementDesc}
                  >
                    <TextArea
                      placeholder={t("general.description_placeholder")}
                      autoSize={{ minRows: 2, maxRows: 10 }}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.attachment")}
                    name="Attachment"
                    rules={[{ required: false }]}
                  >
                    <Upload {...uploadProp()} listType="picture">
                      <Button disabled={disableField || !isOpen}>
                        <UploadOutlined /> {t("general.browse")}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>

                <Button
                  size="large"
                  type="default"
                  htmlType="submit"
                  style={{
                    marginRight: "5px",
                    display: !isPublished ? "" : "none",
                  }}
                >
                  {t("general.save")}
                </Button>

                <Button
                  size="large"
                  type="primary"
                  onClick={showPublishConfirm}
                  style={{
                    display: !isPublished ? "" : "none",
                  }}
                >
                  {t("general.publish")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={announcement}
              showFooter={announcement.AnnouncementKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageAnnouncementForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  announcement: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageAnnouncementForm;
