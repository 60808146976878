import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseJobRecruitment = API_ROOT + "jobrecruitment/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export async function getJobRecruitment(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseJobRecruitment + "GetAllJobRecruitmentsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getJobRecruitmentByKey(key) {
  return axios
    .get(baseJobRecruitment + "GetJobRecruitmentByKey", {
      headers: authHeader(),
      params: {
        key: key,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url: baseJobRecruitment + "AddJobRecruitment",
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "UpdateJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "DeleteJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "WithdrawJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelJobRecruitment(
  jobRecruitment,
  cancelReason
) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "RequestCancelJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function feedbackJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "FeedbackJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function completeJobRecruitment(jobRecruitment) {
  return axios({
    method: "POST",
    url:
      baseJobRecruitment +
      "CompleteJobRecruitment?key=" +
      jobRecruitment.JobRecruitmentKey,
    headers: authHeader(),
    data: jobRecruitment,
  })
    .then(handleResponse)
    .catch(handleError);
}
