import React from "react";
import PropTypes from "prop-types";
import { TrainingApplicationList } from "./TrainingApplicationList";

const TrainingApplicationForm = ({
  isLoading,
  isFromAdmin = false,
  onAdd,
  onView,
  onViewProgram,
  data,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) => {
  return (
    <div>
      <TrainingApplicationList
        isLoading={isLoading}
        isFromAdmin={isFromAdmin}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        onProgramViewed={onViewProgram}
        isShowStatus={true}
        isMultipleAction={false}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

TrainingApplicationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFromAdmin: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onViewProgram: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default TrainingApplicationForm;
