import React, { useEffect, useState } from "react";
import { SelectOutlined, UploadOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Divider,
  Upload,
  Spin,
  Card,
  Modal,
  Checkbox,
  message,
  Tag,
  Table,
  Alert,
  Form,
} from "antd";
import { SelectionInput } from "../../../common/SelectionInput";
import { ApprovalReasonModal } from "../../../common/ApprovalReasonModal";
import EntryFormFooter from "../../../common/EntryFormFooter";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../../model/common/Status";
import { EditButton, DeleteButton } from "../../../common/TableCols";
import { RecordType } from "../../../model/common/RecordType";
import { RowState } from "../../../model/common/RowState";
import TravelAuthorizationDetModel from "../../../model/Claim/Travel/TravelAuthorizationDetModel";
import ManageTravelAuthorizationDetForm from "../Travel/ManageTravelAuthorizationDetForm";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
  precisionDecimal,
} from "../../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";
import { ReportViewer } from "../../../reports/ReportViewer";
import { getEmpyInfo } from "../../../../api/reportApi";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

let enableCalc = false;

const ManageTravelAuthorizationForm = ({
  isLoading,
  form,
  editItem,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onUploadAttachment,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  fileList,
  setFileList,
  allUOMs,
  allClaimTypes,
  allProjects,
  allCurrencies,
  isOwner,
  isViewOnly = false,
  claimConfg,
  userCompCurr,
  selectedCurrency,
  onCurrencyChanged,
  onDataChanged,
  transferDet,
  setIsDirty,
  onRemoveAttachment,
  region,
  employeeID,
}) => {
  const { t } = useTranslation();
  const REPORT_NAME = "Travel Authorization Report";
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [reportParam, setReportParam] = useState({});
  const [empyInfo, setEmpyInfo] = useState(null);
  const [showTravelReport, setShowTravelReport] = useState(false);

  const isEdit = editItem.TravelTransHdrKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isFullyApproved = isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen &&
    editItem.Reason.length > 0 &&
    (editItem.RejectedBy > 0 || editItem.ValidatedBy > 0);
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [showDetForm, setShowDetForm] = useState(false);
  const newDet = TravelAuthorizationDetModel({});
  const [selectedDet, setSelectedDet] = useState(newDet);
  const [showErorrMsg, setshowErorrMsg] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { TravelTransHdrKey } = useParams();

  const [formTravelDet] = Form.useForm();
  const [isFormTravelDetInitialized, setIsFormTravelDetInitialized] =
    useState(false);
  useEffect(
    () => formTravelDet.resetFields(),
    [isFormTravelDetInitialized, formTravelDet]
  );

  useEffect(() => {
    loadPageData(TravelTransHdrKey, form.resetFields);
    // eslint-disable-next-line
  }, [TravelTransHdrKey]);

  useEffect(() => {
    if (enableCalc) {
      calcHdrTotalAmount();
      enableCalc = false;
    }
    // eslint-disable-next-line
  }, [editItem]);

  useEffect(() => {
    let promises = [getEmpyInfo()];

    Promise.all(promises)
      .then((data) => {
        setEmpyInfo(data[0] ? data[0][0] : {});
      })
      .catch(() => {
        toast.error(t("general.fail_to_load_report"));
      });
  }, [t]);

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("travelAuthorizationForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("travelAuthorizationForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("travelAuthorizationForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) item.RowState = RowState.DELETED;
          setDeletedAttachments([...deletedAttachments, file.url]);
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: isOpen && isOwner,
      },
      fileList,
    };
  }

  function calcFuncTotalAmount(origTotalAmount, exRate) {
    if (userCompCurr && userCompCurr.ConvertType) {
      if (userCompCurr.ConvertType === "*") {
        return (origTotalAmount * exRate).toFixed(2);
      } else if (userCompCurr.ConvertType === "/") {
        return (origTotalAmount / exRate).toFixed(2);
      } else {
        return origTotalAmount;
      }
    } else {
      return origTotalAmount;
    }
  }

  function onChangeCurrency(value) {
    let exRate = 1,
      funcTotalAmount = form.getFieldValue("TotalAmount"),
      currentCurr = { ...userCompCurr };
    if (value) {
      let newCurrency = allCurrencies.filter((x) => x.CurrKey === value.value);
      if (newCurrency.length && newCurrency[0]) {
        exRate = newCurrency[0].ExRate;
        funcTotalAmount = calcFuncTotalAmount(funcTotalAmount, exRate);

        currentCurr = newCurrency[0];
      }
    }

    onCurrencyChanged(currentCurr);
    form.setFieldsValue({
      ExRate: exRate,
      FuncTotalAmount: funcTotalAmount,
    });

    if (claimConfg && claimConfg.IsMultiCurrency === true) {
      let temp = editItem.TravelTransDet.map((value) => {
        return {
          ...value,
          FuncTotalAmount: calcFuncTotalAmount(value.TotalAmount, exRate),
          RowState: RowState.MODIFIED,
        };
      });

      onDataChanged({ ...editItem, TravelTransDet: temp });
    }
  }

  function calcHdrTotalAmount() {
    let totalAmount = 0;
    let funcTotalAmount = 0;

    editItem.TravelTransDet.forEach((item) => {
      if (item.RowState !== RowState.DELETED) {
        totalAmount += item.TotalAmount;
        funcTotalAmount += item.FuncTotalAmount;
      }
    });

    form.setFieldsValue({
      TotalAmount: totalAmount,
      FuncTotalAmount: funcTotalAmount,
    });
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        if (onValidateDetail(submitData)) {
          setshowErorrMsg(true);
          return;
        }

        if (values.TravelAuthDate) {
          let fromDate = values.TravelAuthDate[0];
          fromDate.set({ second: 0, millisecond: 0 });
          submitData.FromTravelDate = fromDate.startOf("minute").$d;

          let toDate = values.TravelAuthDate[1];
          toDate.set({ second: 0, millisecond: 0 });
          submitData.ToTravelDate = toDate.startOf("minute").$d;
        }
        if (values.Project) {
          submitData.ProjKey = values.Project.key;
        }

        if (values.Currency) {
          submitData.CurrKey = values.Currency.key;
        } else {
          submitData.CurrKey = userCompCurr.CurrKey;
        }

        submitData.Status = isSubmit
          ? StatusByValue.SUBMITTED
          : StatusByValue.OPEN;

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onSubmitted(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function onValidateDetail(data) {
    if (data !== undefined || data !== null) {
      if (data.TravelTransDet.length === 0) {
        return true;
      } else {
        return false;
      }
    }
  }

  function handleCloseErrMsg() {
    setshowErorrMsg(false);
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function getFilterTransDetData() {
    return editItem.TravelTransDet.filter(
      (x) => x.RowState !== RowState.DELETED
    );
  }

  function addNewDet() {
    setSelectedDet(newDet);
    setShowDetForm(true);
    setIsFormTravelDetInitialized(!isFormTravelDetInitialized);
  }

  function editDet(detKey) {
    let refLimits = editItem.TravelTransDet.filter(
      (item) => item.TravelTransDetKey === detKey
    );
    let selectedItem = { ...refLimits };
    setSelectedDet(selectedItem[0]);
    setShowDetForm(true);
    setIsFormTravelDetInitialized(!isFormTravelDetInitialized);
  }

  function deleteDet(detKey) {
    let newData = editItem.TravelTransDet.filter(
      (y) => !(y.TravelTransDetKey === detKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.TravelTransDetKey === detKey && x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    });
    onDataChanged({ ...editItem, TravelTransDet: newData });
    enableCalc = true;
  }

  function saveDet(det) {
    if (det !== undefined || det !== null) {
      let index = editItem.TravelTransDet.findIndex(
        (x) => x.TravelTransDetKey === det.TravelTransDetKey
      );

      if (index === -1) {
        onDataChanged({
          ...editItem,
          TravelTransDet: [...editItem.TravelTransDet, { ...det }],
        });
      } else {
        let temp = editItem.TravelTransDet.map((item) =>
          item.TravelTransDetKey === det.TravelTransDetKey
            ? item.RowState === RowState.ADDED
              ? det
              : { ...det, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...editItem, TravelTransDet: temp });
      }
      setShowDetForm(false);
      enableCalc = true;
    }
  }

  function cancelInputDet() {
    setSelectedDet(newDet);
    setShowDetForm(false);
  }

  const DetActions = (value, record) => (
    <Row>
      <Col
        span={12}
        style={{
          display: isOpen && isOwner ? "" : "none",
        }}
      >
        <EditButton fncEdit={() => editDet(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: isOpen && isOwner ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteDet(value)} />
      </Col>
      <Col
        span={24}
        style={{
          display:
            isFullyApproved && isOwner && !record.IsLink && record.IsClaimable
              ? ""
              : "none",
        }}
      >
        <Button
          type="link"
          icon={<SelectOutlined />}
          size="small"
          onClick={() => transferDet(record)}
        />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("general.claim_type"),
      align: "left",
      dataIndex: "ClaimTypeCodeClaimTypeDesc",
      key: "ClaimTypeCodeClaimTypeDesc",
    },
    {
      title: t("general.remarks"),
      dataIndex: "Remarks",
      align: "left",
      key: "Remarks",
      width: 170,
    },
    {
      title: t("general.uom"),
      dataIndex: "UOMCodeUOMDesc",
      align: "left",
      key: "UOMCodeUOMDesc",
      width: 80,
    },
    {
      title: t("general.qty"),
      dataIndex: "Quantity",
      align: "right",
      key: "Quantity",
      width: 80,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("general.unit_cost"),
      dataIndex: "UnitCost",
      align: "right",
      key: "UnitCost",
      width: 80,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: precisionDecimal(selectedCurrency.CurrCode),
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("general.amount"),
      dataIndex: "TotalAmount",
      align: "right",
      key: "TotalAmount",
      width: 80,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: precisionDecimal(selectedCurrency.CurrCode),
          maximumFractionDigits: 2,
        }),
    },
    {
      title: t("general.action"),
      dataIndex: "TravelTransDetKey",
      key: "DetActions",
      align: "center",
      width: 100,
      render: DetActions,
    },
  ];

  function printTravelReport() {
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );
    setReportParam({
      ESSUserKey: empyInfo.UserKey,
      TravelTransHdrKey: TravelTransHdrKey,
    });
    setReportGenerate(true);
    setShowTravelReport(true);
  }

  function onCloseReportModal() {
    setShowTravelReport(false);
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("travelAuthorizationForm.travel_authorization")}
                </span>
                <Tag
                  color="green"
                  style={{
                    marginLeft: 10,
                    display:
                      editItem.IsRequireValidation && editItem.IsValidated
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.validated")}
                </Tag>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t("general.document_no")}
                      name="DocNum"
                      initialValue={editItem.DocNum}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={16} lg={16} xl={8}>
                    <Form.Item
                      label={<span>{t("general.date")}</span>}
                      name="TravelAuthDate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "travelAuthorizationForm.travel_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={editItem.TravelAuthDate}
                    >
                      <RangePicker
                        showToday={false}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        format="DD/MM/YYYY HH:mm"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        placeholder={[
                          t("general.start_date"),
                          t("general.end_date"),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display: isShowReason ? "" : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        isRequestCancelRejected
                          ? t("general.reason_of_cancel_request_rejected")
                          : t("general.reason")
                      }
                    >
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={true}
                        value={editItem.Reason}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={16}
                    lg={16}
                    xl={16}
                    style={{
                      display:
                        claimConfg && claimConfg.IsGroupByProj === true
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("claimSubmissionForm.project")}</span>}
                      name="Project"
                      rules={[
                        {
                          required:
                            claimConfg && claimConfg.IsGroupByProj === true,
                          message: t(
                            "claimSubmissionForm.project_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              claimConfg &&
                              claimConfg.IsGroupByProj === true &&
                              !allProjects.some((x) => x.Key === value.value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  t("claimSubmissionForm.invalid_project")
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.ProjKey > 0
                          ? {
                              key: editItem.ProjKey,
                              value: editItem.ProjKey,
                              label:
                                editItem.ProjCode + " - " + editItem.ProjDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allProjects}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t(
                          "claimSubmissionForm.project_placeholder"
                        )}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        region === "M"
                          ? t("employeeForm.new_ic")
                          : t("employeeForm.ktp_no")
                      }
                      name="NewIC"
                      initialValue={
                        region === "M" ? employeeID.IDNo : employeeID.KTPNoI
                      }
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        claimConfg && claimConfg.IsMultiCurrency === true
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("general.currency")}</span>}
                      name="Currency"
                      rules={[
                        {
                          required:
                            claimConfg && claimConfg.IsMultiCurrency === true,
                          message: t("general.currency_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              claimConfg &&
                              claimConfg.IsMultiCurrency === true &&
                              !allCurrencies.some(
                                (x) => x.CurrKey === value.value
                              )
                            ) {
                              return Promise.reject(
                                new Error(t("general.invalid_currency"))
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.CurrKey > 0
                          ? {
                              key: editItem.CurrKey,
                              value: editItem.CurrKey,
                              label:
                                editItem.CurrCode + " - " + editItem.CurrDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allCurrencies}
                        valueProp={"CurrKey"}
                        textProp={"CurrCodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("general.currency_placeholder")}
                        onChange={onChangeCurrency}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={
                        <span>
                          {t("general.total_amount")}
                          {selectedCurrency && selectedCurrency.CurrCode
                            ? " (" + selectedCurrency.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="TotalAmount"
                      initialValue={editItem.TotalAmount}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={precisionDecimal(selectedCurrency.CurrCode)}
                        prefix={
                          selectedCurrency && selectedCurrency.CurrSymb
                            ? selectedCurrency.CurrSymb
                            : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(selectedCurrency.CurrCode)
                        )}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        claimConfg &&
                        claimConfg.IsMultiCurrency === true &&
                        form.getFieldValue("Currency") &&
                        form.getFieldValue("Currency").key !==
                          userCompCurr.CurrKey
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("general.exchange_rate")}</span>}
                      name="ExRate"
                      initialValue={editItem.ExRate}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={6}
                        disabled={true}
                        formatter={inputLocaleFormatter(6)}
                        parser={inputLocaleParser(6)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        claimConfg &&
                        claimConfg.IsMultiCurrency === true &&
                        form.getFieldValue("Currency") &&
                        userCompCurr &&
                        form.getFieldValue("Currency").key !==
                          userCompCurr.CurrKey
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        <span>
                          {t("general.total_amount")}
                          {userCompCurr && userCompCurr.CurrCode
                            ? " (" + userCompCurr.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="FuncTotalAmount"
                      initialValue={editItem.FuncTotalAmount}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        precision={2}
                        prefix={
                          userCompCurr.CurrSymb ? userCompCurr.CurrSymb : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.remarks")}</span>}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.remarks_required_validation"),
                        },
                        {
                          max: 250,
                          message: t("general.remarks_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Divider />
                  <h3 className="form-subtitle">{t("general.details")}</h3>
                  <div style={{ width: "100%" }}>
                    <Table
                      dataSource={getFilterTransDetData()}
                      columns={columns}
                      size="middle"
                      pagination={false}
                      rowKey={(record) => record.TravelTransDetKey}
                      bordered
                    />
                    {showErorrMsg ? (
                      <Alert
                        message={t("general.details_placeholder")}
                        type="error"
                        afterClose={handleCloseErrMsg}
                        closable
                        showIcon
                        closeText={t("general.close_now")}
                      />
                    ) : null}
                    <Button
                      type="primary"
                      onClick={() => addNewDet()}
                      style={{
                        marginTop: 16,
                        display: isOpen && isOwner && !isViewOnly ? "" : "none",
                      }}
                    >
                      {t("general.add_new")}
                    </Button>
                  </div>
                  <ManageTravelAuthorizationDetForm
                    isVisible={showDetForm}
                    selectedDet={selectedDet}
                    onSave={saveDet}
                    onCancel={cancelInputDet}
                    uomData={allUOMs}
                    claimTypesData={allClaimTypes}
                    selectedCurrency={selectedCurrency}
                    setIsDirty={setIsDirty}
                    userCompCurr={userCompCurr}
                    claimConfg={claimConfg}
                    currKey={
                      form.getFieldValue("Currency")
                        ? form.getFieldValue("Currency").key !==
                          userCompCurr.CurrKey
                          ? true
                          : false
                        : false
                    }
                    exRate={
                      form.getFieldValue("ExRate")
                        ? form.getFieldValue("ExRate")
                        : 0
                    }
                    form={formTravelDet}
                  />

                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.attachment")}</span>}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            !(isOpen && isOwner) || isViewOnly || isSystemRcd
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={printTravelReport}
                          style={{
                            marginRight: 10,
                            display:
                              isStatusApproved(editItem.Status) && isOwner
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.print")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  open={showTravelReport}
                  title={
                    <span className="form-subtitle">
                      {t("travelAuthorizationForm.travel_authorization_report")}
                    </span>
                  }
                  okText={t("general.close")}
                  onOk={onCloseReportModal}
                  onCancel={onCloseReportModal}
                  destroyOnClose={true}
                  maskClosable={false}
                  footer={[
                    <Button key="back" onClick={onCloseReportModal}>
                      {t("general.close")}
                    </Button>,
                  ]}
                  width={"70%"}
                  style={{ top: 20, width: "100%" }}
                >
                  <div className="report-container">
                    <ReportViewer
                      Collapsed={false}
                      CustomStyle={true}
                      ReportParam={reportParam}
                      ReportName={reportName}
                      ReportGenerate={isReportGenerate}
                    />
                  </div>
                </Modal>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.TravelTransHdrKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Travel"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageTravelAuthorizationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  allUOMs: PropTypes.array.isRequired,
  allClaimTypes: PropTypes.array.isRequired,
  allProjects: PropTypes.array.isRequired,
  allCurrencies: PropTypes.array.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  claimConfg: PropTypes.object.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  selectedCurrency: PropTypes.object.isRequired,
  onCurrencyChanged: PropTypes.func.isRequired,
  transferDet: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  region: PropTypes.string.isRequired,
  employeeID: PropTypes.object.isRequired,
};

export default ManageTravelAuthorizationForm;
