export default function RoleTypeModel({
  roleTypeKey = -1,
  roleTypeCode = "",
  roleTypeDesc = "",
  rcdType = 0,
  rcdTypeDesc = "User",
  type = 0,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  users = []
}) {
  return Object.freeze({
    RoleTypeKey: roleTypeKey,
    RoleTypeCode: roleTypeCode,
    RoleTypeDesc: roleTypeDesc,
    RcdType: rcdType,
    RcdTypeDesc: rcdTypeDesc,
    Type: type,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    Users: users
  });
}
