import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidJobRecruitments,
  voidJobRecruitments,
} from "../../../api/jobRecruitmentApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import ApprovedJobRecruitmentVoidForm from "../../forms/JobRecruitment/ApprovedJobRecruitmentVoidForm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function ApprovedJobRecruitmentVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [jobRecruitments, setJobRecruitments] = useState([]);
  const [selectedJobRecruitments, setSelectedJobRecruitments] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getJobRecruitments, [currentYear]);

  function getJobRecruitments() {
    setIsLoading(true);
    getApprovedAndVoidJobRecruitments(currentYear)
      .then((value) => {
        setJobRecruitments(value ? value : []);
        setSelectedJobRecruitments([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewJobRecruitment() {
    history("/home/jobrecruitment/jobrecruitments");
  }

  function onVoidJobRecruitments(selectedJobRecruitments, reason) {
    setIsLoading(true);

    voidJobRecruitments(selectedJobRecruitments, reason)
      .then(() => {
        getJobRecruitments();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewJobRecruitments(jobRecruitmentKey) {
    history("/home/jobrecruitment/jobrecruitments/" + jobRecruitmentKey, {
      state: { fromVoid: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ApprovedJobRecruitmentVoidForm
      isLoading={isLoading}
      data={jobRecruitments}
      selectedItems={selectedJobRecruitments}
      onAdd={onAddNewJobRecruitment}
      onView={viewJobRecruitments}
      onVoided={onVoidJobRecruitments}
      onItemSelected={setSelectedJobRecruitments}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

ApprovedJobRecruitmentVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
