import { RowState } from "../common/RowState";

export default function JobRecruitmentDetModel({
  jobRecruitmentDetKey = Math.floor(Math.random() * 999999),
  jobRecruitmentKey = -1,
  name = "",
  description = "",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    JobRecruitmentDetKey: jobRecruitmentDetKey,
    JobRecruitmentKey: jobRecruitmentKey,
    Name: name,
    Description: description,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
