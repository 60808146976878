import React, { useState, useEffect } from "react";
import ManagePropMaintForm from "../../forms/PropMaint/ManagePropMaintForm";
import PropMaintModel from "../../model/PropMaint/PropMaintModel";
import {
  getPropMaintByKey,
  addPropMaint,
  updatePropMaint,
  deletePropMaint,
  withdrawPropMaint,
  requestCancelPropMaint,
  feedbackPropMaint,
  completePropMaint,
} from "../../../api/propMaintApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newPropMaint = PropMaintModel({});

const ManagePropMaintPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const PROP_MAINT_FORM_MESSAGE = {
    PROP_MAINT_SAVED: t("propMaintForm.prop_maint_saved"),
    PROP_MAINT_SUBMITTED: t("propMaintForm.prop_maint_submitted"),
    PROP_MAINT_WITHDRAWN: t("propMaintForm.prop_maint_withdrawn"),
    PROP_MAINT_DELETED: t("propMaintForm.prop_maint_deleted"),
    PROP_MAINT_CANCEL_REQUEST: t("propMaintForm.prop_maint_cancel_request"),
    PROP_MAINT_COMPLETE: t("propMaintForm.prop_maint_complete"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newPropMaint);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isAssignor, setIsAssignor] = useState(false);
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/propmaintapproval");
      } else if (location.state.fromVoid) {
        history("/home/propmaintvoid");
      } else if (location.state.fromCancel) {
        history("/home/propmaintcancellationapproval");
      } else if (location.state.fromValidation) {
        history("/home/propmaintvalidation");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/facilities/propertiesdisplay", {state: location.state});
      } else {
        history("/home/propmaintdisplay");
      }
    } else {
      history("/home/propmaintdisplay");
    }
  }

  function onSaved(propMaintData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...propMaintData,
      Title: propMaintData.Title.trim(),
      Description: propMaintData.Description.trim(),
    };

    let upsertPropMaintData =
      dataSubmit.PropMaintKey !== -1 ? updatePropMaint : addPropMaint;

    return upsertPropMaintData(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? PROP_MAINT_FORM_MESSAGE.PROP_MAINT_SAVED
          : isStatusSubmitted(result.Status)
          ? PROP_MAINT_FORM_MESSAGE.PROP_MAINT_SUBMITTED
          : PROP_MAINT_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/propmaint/proptrans/", { state: { force: true } });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history("/home/propmaint/proptrans/" + result.PropMaintKey, {
            state: { force: true },
          });
          loadPageData(result.PropMaintKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onDelete(propMaintData) {
    setIsLoading(true);
    return deletePropMaint({ ...editItem, ...propMaintData })
      .then(() => {
        toast.success(PROP_MAINT_FORM_MESSAGE.PROP_MAINT_DELETED);
        history("/home/propmaint/proptrans/", { state: { force: true } });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onWithdraw(propMaintData) {
    setIsLoading(true);
    return withdrawPropMaint({ ...editItem, ...propMaintData })
      .then(() => {
        toast.success(PROP_MAINT_FORM_MESSAGE.PROP_MAINT_WITHDRAWN);
        loadPageData(editItem.PropMaintKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onRequestCancel(propMaintData, reason) {
    setIsLoading(true);
    return requestCancelPropMaint(
      {
        ...editItem,
        ...propMaintData,
      },
      reason
    )
      .then(() => {
        toast.success(PROP_MAINT_FORM_MESSAGE.PROP_MAINT_CANCEL_REQUEST);
        loadPageData(editItem.PropMaintKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onFeedbackPropMaint(propMaintData) {
    setIsLoading(true);
    return feedbackPropMaint({
      ...editItem,
      ...propMaintData,
      MaintRemarks: propMaintData.MaintRemarks.trim(),
    })
      .then(() => {
        setIsDirty(false);
        toast.success(PROP_MAINT_FORM_MESSAGE.PROP_MAINT_SAVED);
        loadPageData(editItem.PropMaintKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onCompletePropMaint(propMaintData) {
    setIsLoading(true);
    return completePropMaint({
      ...editItem,
      ...propMaintData,
      MaintRemarks: propMaintData.MaintRemarks.trim(),
    })
      .then(() => {
        setIsDirty(false);
        toast.success(PROP_MAINT_FORM_MESSAGE.PROP_MAINT_COMPLETE);
        loadPageData(editItem.PropMaintKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "PropMaint")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];

    if (editKey) promises.push(getPropMaintByKey(editKey));

    Promise.all(promises)
      .then((values) => {
        let propMaintToEdit = values[0];

        if (propMaintToEdit) {
          let editedFiles = propMaintToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });

          setFileList(editedFiles);

          propMaintToEdit.StatusDesc = Status[propMaintToEdit.Status];
          propMaintToEdit.TransDate = new Date(propMaintToEdit.TransDate);
          propMaintToEdit.RepairDate = [
            dayjs(propMaintToEdit.RepairStartDate),
            dayjs(propMaintToEdit.RepairEndDate),
          ];

          setEditItem(propMaintToEdit);
          setIsOwner(
            isLoginUser(propMaintToEdit.UserKey) ||
              isEditor(propMaintToEdit.CreatedBy)
          );
          setIsAssignor(propMaintToEdit.IsAssignor);
        } else {
          setFileList([]);
          setEditItem(newPropMaint);
          setIsOwner(true);
          setIsAssignor(false);
        }

        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.ExceptionMessage);
      });
  }

  return (
    <ManagePropMaintForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onFeedbackPropMaint={onFeedbackPropMaint}
      onCompletePropMaint={onCompletePropMaint}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      editItem={editItem}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      fileList={fileList}
      setFileList={setFileList}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      isAssignor={isAssignor}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
};

ManagePropMaintPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManagePropMaintPage;
