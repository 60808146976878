export function UserProfileDashboardModel({
  profileImage = "",
  displayName = "",
  Department = "",
  Position = "",
  Grade = "",
  ContactNo = "",
}) {
  return Object.freeze({
    profileImage,
    displayName,
    Department,
    Position,
    Grade,
    ContactNo,
  });
}

export function UserLeaveInfoDashboardModel({
  ALCarryForward = 0,
  ALEntitled = 0,
  ALTotal = 0,
  ALRemain = 0,
  ALExpiredCarryForward = 0,
  ALAdjustment = 0,
}) {
  return Object.freeze({
    ALCarryForward,
    ALEntitled,
    ALTotal,
    ALRemain,
    ALExpiredCarryForward,
    ALAdjustment,
  });
}

export function UserClaimInfoDashboardModel({
  cutOffDay = 1,
  claimsApplied = [],
}) {
  return Object.freeze({
    cutOffDay,
    claimsApplied,
  });
}

export function AttendanceDashboardModel({
  absent = 0,
  employeeAttendance = [],
}) {
  return Object.freeze({
    absent,
    employeeAttendance,
  });
}

export function pendingLeaveApprovalDashboardModel({
  pendingAmount = 0,
  pendingleaveInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingleaveInfo,
    isMixStatus,
  });
}

export function pendingClaimsApprovalDashboardModel({
  pendingAmount = 0,
  claimsInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    claimsInfo,
    isMixStatus,
  });
}

export function pendingJobRecruitmentApprovalDashboardModel({
  pendingAmount = 0,
  pendingJobRecruitmentInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingJobRecruitmentInfo,
    isMixStatus,
  });
}

export function pendingTravelAuthApprovalDashboardModel({
  pendingAmount = 0,
  pendingTravelAuthInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingTravelAuthInfo,
    isMixStatus,
  });
}

export function pendingClinicVisitApprovalDashboardModel({
  pendingAmount = 0,
  pendingClinicVisitInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingClinicVisitInfo,
    isMixStatus,
  });
}

export function pendingTrainingApprovalDashboardModel({
  pendingAmount = 0,
  pendingTrainingInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingTrainingInfo,
    isMixStatus,
  });
}

export function pendingVehicleReqApprovalDashboardModel({
  pendingAmount = 0,
  pendingVehicleReqInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingVehicleReqInfo,
    isMixStatus,
  });
}

export function pendingRoomReqApprovalDashboardModel({
  pendingAmount = 0,
  pendingRoomReqInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingRoomReqInfo,
    isMixStatus,
  });
}

export function pendingPropMaintApprovalDashboardModel({
  pendingAmount = 0,
  pendingPropMaintInfo = [],
  isMixStatus = false,
}) {
  return Object.freeze({
    pendingAmount,
    pendingPropMaintInfo,
    isMixStatus,
  });
}

export function AnnouncementDashboardModel({ announcement = [] }) {
  return Object.freeze({
    announcement,
  });
}

export function TravelDashboardModel({ travel = 0, employeeOnTravel = [] }) {
  return Object.freeze({
    travel,
    employeeOnTravel,
  });
}
