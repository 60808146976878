import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "jobrecruitmentapproval/";

export function getPendingApprovalJobRecruitments() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalJobRecruitments",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedJobRecruitments(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedJobRecruitments",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidJobRecruitments(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidJobRecruitments",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledJobRecruitments(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledJobRecruitments",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedJobRecruitments(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedJobRecruitments",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveJobRecruitments(jobRecruitments) {
  return axios({
    method: "POST",
    url: base + "ApproveJobRecruitments",
    headers: authHeader(),
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}

export function rejectJobRecruitments(
  jobRecruitments,
  rejectReason,
  isAllowAmend
) {
  return axios({
    method: "POST",
    url: base + "RejectJobRecruitments",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}

export function voidJobRecruitments(jobRecruitments, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidJobRecruitments",
    headers: authHeader(),
    params: { reason: voidReason },
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledJobRecruitments(jobRecruitments) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledJobRecruitments",
    headers: authHeader(),
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledJobRecruitments(jobRecruitments, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledJobRecruitments",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}

export function validateJobRecruitments(jobRecruitments, reason) {
  return axios({
    method: "POST",
    url: base + "ValidateJobRecruitments",
    headers: authHeader(),
    params: { reason: reason },
    data: jobRecruitments,
  })
    .then()
    .catch(handleError);
}
