import React, { useState, useEffect } from "react";
import ManageTravelAuthorizationForm from "../../../forms/Claim/Travel/ManageTravelAuthorizationForm";
import TravelAuthorizationModel from "../../../model/Claim/Travel/TravelAuthorizationModel";
import {
  addTravel,
  getTravelByTransKey,
  updateTravel,
  withdrawTravel,
  deleteTravel,
  requestCancelTravel,
} from "../../../../api/travelAuthorizationApi";
import { getEntitledClaimTypes } from "../../../../api/claimTypeApi";
import { getUOM } from "../../../../api/uomApi";
import { getProjectCode } from "../../../../api/projectApi";
import {
  getMonthlyExCurrency,
  getUserCompCurrency,
} from "../../../../api/currApi";
import { attachFiles, deleteFile } from "../../../../api/fileUploadApi";
import { getClaimConfg } from "../../../../api/claimConfgApi";
import { getEmpyID } from "../../../../api/employeeApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../../model/common/Status";
import { isLoginUser, isEditor } from "../../../common/ValidateLoginUser";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newTravelAuthorization = TravelAuthorizationModel({});

const ManageTravelAuthorizationPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const TRAVEL_FORM_MESSAGE = {
    TRAVEL_SAVED: t("travelAuthorizationForm.travel_saved"),
    TRAVEL_SUBMITTED: t("travelAuthorizationForm.travel_submitted"),
    TRAVEL_WITHDRAWN: t("travelAuthorizationForm.travel_withdrawn"),
    TRAVEL_DELETED: t("travelAuthorizationForm.travel_deleted"),
    TRAVEL_CANCEL_REQUEST: t("travelAuthorizationForm.travel_cancel_request"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newTravelAuthorization);
  const [defaultClaimYearMonth, setDefaultClaimYearMonth] = useState(
    dayjs().startOf("month")
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [claimConfg, setClaimConfg] = useState({});
  const [allClaimTypes, setAllClaimTypes] = useState([]);
  const [allUOMs, setAllUOMs] = useState([]);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [allProjects, setAllProjects] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [employeeID, setEmployeeID] = useState({});
  const location = useLocation();
  const [region, setRegion] = useState("M");

  useEffect(() => {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth && auth.Region) {
      if (auth.Region === "M") {
        setRegion("M");
      } else {
        setRegion("I");
      }
    }
    form.resetFields();
  }, [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/travelauthapproval");
      } else if (location.state.fromVoid) {
        history("/home/travelauthvoid");
      } else if (location.state.fromCancel) {
        history("/home/travelauthcancellationapproval");
      } else if (location.state.fromValidation) {
        history("/home/travelauthvalidation");
      } else if (location.state.fromClaim) {
        history(-1);
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/travel", { state: location.state });
      } else {
        history("/home/travelauthdisplay");
      }
    } else {
      history("/home/travelauthdisplay");
    }
  }

  function onSaved(travelData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...travelData,
      Remarks: travelData.Remarks.trim(),
    };

    let upsertTravelAuthorization =
      dataSubmit.TravelTransHdrKey !== -1 ? updateTravel : addTravel;

    return upsertTravelAuthorization(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? TRAVEL_FORM_MESSAGE.TRAVEL_SAVED
          : isStatusSubmitted(result.Status)
          ? TRAVEL_FORM_MESSAGE.TRAVEL_SUBMITTED
          : TRAVEL_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/travelauth/travelTrans/", { state: { force: true } });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history("/home/travelauth/travelTrans/" + result.TravelTransHdrKey, {
            state: { force: true },
          });
          loadPageData(result.TravelTransHdrKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onWithdraw(travelData) {
    setIsLoading(true);
    return withdrawTravel(editItem.TravelTransHdrKey, {
      ...editItem,
      ...travelData,
    })
      .then(() => {
        toast.success(TRAVEL_FORM_MESSAGE.TRAVEL_WITHDRAWN);
        loadPageData(editItem.TravelTransHdrKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onDelete(travelData) {
    setIsLoading(true);
    return deleteTravel(editItem.TravelTransHdrKey, {
      ...editItem,
      ...travelData,
    })
      .then(() => {
        toast.success(TRAVEL_FORM_MESSAGE.TRAVEL_DELETED);
        history("/home/travelauth/travelTrans/", { state: { force: true } });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRequestCancel(travelData, reason) {
    setIsLoading(true);
    return requestCancelTravel(
      editItem.TravelTransHdrKey,
      {
        ...editItem,
        ...travelData,
      },
      reason
    )
      .then(() => {
        toast.success(TRAVEL_FORM_MESSAGE.TRAVEL_CANCEL_REQUEST);
        loadPageData(editItem.TravelTransHdrKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Travel")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];
    promises.push(
      getClaimConfg(),
      getEntitledClaimTypes(),
      getUOM(),
      getUserCompCurrency(),
      getEmpyID(editKey)
    );
    if (editKey) promises.push(getTravelByTransKey(editKey));
    Promise.all(promises)
      .then((values) => {
        let currentDateMoment = dayjs();

        let claimConfig = values[0];
        let claimTypes = values[1];
        let uoms = values[2];
        let compCurr = values[3];
        let employeeID = values[4];
        let travelToEdit = values[5];

        setClaimConfg(claimConfig);
        setAllClaimTypes(claimTypes);
        setAllUOMs(uoms);
        setUserCompCurr(compCurr);
        setEmployeeID(employeeID);

        if (claimConfig) {
          setDefaultClaimYearMonth(currentDateMoment);

          if (claimConfig.IsGroupByProj) {
            getProjectCode()
              .then((projects) => {
                setAllProjects(projects);
              })
              .catch((error) => {
                setIsLoading(false);
                toast.error(error.response.data.error_description);
              });
          } else {
            setAllProjects([]);
          }

          if (claimConfig.IsMultiCurrency) {
            getMonthlyExRateAndCurrency(
              travelToEdit
                ? travelToEdit.CreatedOn
                : defaultClaimYearMonth.toDate(),
              travelToEdit
                ? travelToEdit.CurrKey
                : newTravelAuthorization.CurrKey
            );
          } else {
            setAllCurrencies([]);
            setSelectedCurrency(compCurr);
          }
        }

        if (travelToEdit) {
          let editedFiles = travelToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });
          travelToEdit.StatusDesc = Status[travelToEdit.Status];
          travelToEdit.TravelAuthDate = [
            dayjs(travelToEdit.FromTravelDate),
            dayjs(travelToEdit.ToTravelDate),
          ];
          setFileList(editedFiles);

          setEditItem(travelToEdit);
          setIsOwner(
            isLoginUser(travelToEdit.UserKey) ||
              isEditor(travelToEdit.CreatedBy)
          );
        } else {
          setFileList([]);

          setIsOwner(true);
          setEditItem(newTravelAuthorization);
        }

        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly(
          location.state && location.state.fromAdmin
            ? location.state.fromAdmin
            : false
        );
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.error_description);
      });
  }

  function getMonthlyExRateAndCurrency(claimYrMth, currKey) {
    if (claimYrMth) {
      setIsLoading(true);
      return getMonthlyExCurrency(claimYrMth)
        .then((currencies) => {
          setAllCurrencies(currencies);
          setIsLoading(false);

          let temp = {};
          if (currencies && currencies.length) {
            let current = currencies.filter((x) => x.CurrKey === currKey);
            if (current.length && current[0]) {
              temp = current[0];
            }
          }
          setSelectedCurrency(temp);
          return temp;
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.error_description);
        });
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  function transferDet(data) {
    history("/home/claimsubmission/claimTrans", {
      state: {
        fromTravel: true,
        travelHdrKey: data.TravelTransHdrKey,
        travelDetKey: data.TravelTransDetKey,
        force: true,
      },
    });
  }

  return (
    <ManageTravelAuthorizationForm
      isLoading={isLoading}
      editItem={editItem}
      onDataChanged={setEditItem}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onCancelled={onCancelled}
      onWithdraw={onWithdraw}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onUploadAttachment={onUploadAttachment}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      fileList={fileList}
      setFileList={setFileList}
      allUOMs={allUOMs}
      allClaimTypes={allClaimTypes}
      allProjects={allProjects}
      allCurrencies={allCurrencies}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      claimConfg={claimConfg}
      userCompCurr={userCompCurr}
      selectedCurrency={selectedCurrency}
      onCurrencyChanged={setSelectedCurrency}
      transferDet={transferDet}
      onRemoveAttachment={onRemoveAttachment}
      region={region}
      setIsDirty={setIsDirty}
      form={form}
      employeeID={employeeID}
    />
  );
};

ManageTravelAuthorizationPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageTravelAuthorizationPage;
