import React from "react";
import { List, Row, Col, Card, Checkbox, Tag } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";

export const EmpySingleList = ({
  isLoading,
  data,
  isSelectable = false,
  selectedDetails = [],
  onDetailsSelected,
  selectedItems = [],
  onItemSelected,
  onListActionsRendered,
}) => {
  function selectItem(isSelected, key) {
    if (onDetailsSelected) {
      if (isSelected) {
        if (selectedDetails.findIndex((x) => x.EmpyAprKey === key) === -1) {
          let selectedDetail = data.find((x) => x.EmpyAprKey === key);
          onDetailsSelected([...selectedDetails, selectedDetail]);
        }
      } else {
        onDetailsSelected(selectedDetails.filter((x) => x.EmpyAprKey !== key));
        //remove parent item
        let selectedDetail = data.find((x) => x.EmpyAprKey === key);
        if (
          selectedDetails.findIndex(
            (x) => x.EmpyKey === selectedDetail.EmpyKey && x.EmpyAprKey !== key
          ) === -1
        ) {
          onItemSelected(
            selectedItems.filter((x) => x.EmpyKey !== selectedDetail.EmpyKey)
          );
        }
      }
    }
  }

  return (
    <div>
      <List
        loading={isLoading}
        split={false}
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item style={{ paddingLeft: 0 }}>
            {isSelectable && (
              <Checkbox
                style={{ marginRight: 10 }}
                checked={
                  selectedDetails.findIndex(
                    (x) => x.EmpyAprKey === item.EmpyAprKey
                  ) >= 0
                }
                onChange={(event) =>
                  selectItem(event.target.checked, item.EmpyAprKey)
                }
              />
            )}
            <Card
              style={{ width: "100%" }}
              className="right-align-actions"
              actions={onListActionsRendered && onListActionsRendered(item)}
            >
              <Row
                style={{ padding: "10px 15px 0px 15px" }}
                type="flex"
                justify="space-between"
              >
                <Col style={{ width: "50%" }}>
                  <List.Item.Meta
                    title={item.Category}
                    description={
                      <span>
                        <i style={{ fontSize: "11px" }}>
                          {" "}
                          {dayjs(item.SubmitDate, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )}
                        </i>
                      </span>
                    }
                  />
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <Tag color="blue">
                    {item.AprStatus === "PD" ? "Pending" : "Partially Approved"}
                  </Tag>
                </Col>
              </Row>

              <Row type="flex" justify="space-between">
                <Col style={{ padding: "0 15px" }}>
                  <p style={{ margin: "0" }}>
                    <span>
                      {item.Category === "General Details" ? (
                        item.Field === "PhyBankAcc" ? (
                          "Physical Bank Account : " + item.PhyBankAcc
                        ) : item.Field === "BNMCode" ? (
                          "Bank Name : " + item.BankName
                        ) : item.Field === "MaritalStatus" ? (
                          item.MaritalStatus === "D" ? (
                            "Marital Status : Divorce"
                          ) : item.MaritalStatus === "S" ? (
                            "Marital Status : Single"
                          ) : item.MaritalStatus === "M" ? (
                            "Marital Status : Married"
                          ) : (
                            "Marital Status : Widowed"
                          )
                        ) : item.Field === "SpouseName" ? (
                          "Spouse Name : " + item.SpouseName
                        ) : item.Field === "SpouseID" ? (
                          "Spouse ID : " + item.SpouseID
                        ) : item.Field === "SpouseDOB" ? (
                          "Spouse Date of Birth : " +
                          dayjs(item.SpouseDOB, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        ) : item.Field === "SpouseDOM" ? (
                          "Spouse Marriage Date : " +
                          dayjs(item.SpouseDOM, "YYYY-MM-DD").format(
                            "DD/MM/YYYY"
                          )
                        ) : item.Field === "IsDisableSpouse" ? (
                          "Spouse Disabled : " +
                          (item.IsDisableSpouse ? "Yes" : "No")
                        ) : item.Field === "IsSpouseWork" ? (
                          "Spouse Working : " +
                          (item.IsSpouseWork ? "Yes" : "No")
                        ) : (
                          ""
                        )
                      ) : item.Category === "Children" ? (
                        <b>{item.EmpyChildName}</b>
                      ) : (
                        item.Education
                      )}
                    </span>
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children"
                      ? "Date Of Birth : " +
                        dayjs(item.EmpyChildDOB, "YYYY-MM-DD").format(
                          "DD/MM/YYYY"
                        )
                      : item.Category === "Education "
                      ? "Remarks : " + item.Remarks
                      : ""}
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children"
                      ? item.EduLevel.toUpperCase() === "HIGHEDU"
                        ? "Education Level : High Education"
                        : item.EduLevel.toUpperCase() === "FORM6"
                        ? "Education Level : Secondary (Form 6)"
                        : item.EduLevel.toUpperCase() === "SECEDU"
                        ? "Education Level : Secondary"
                        : item.EduLevel.toUpperCase() === "PRIEDU"
                        ? "Education Level : Primary"
                        : item.EduLevel.toUpperCase() === "PRESCHL"
                        ? "Education Level : Preschool"
                        : item.EduLevel.toUpperCase() === "OTHERS"
                        ? "Education Level : Others"
                        : "Education Level : Not Available"
                      : ""}
                  </p>
                  <p style={{ margin: "0" }}>
                    {item.Category === "Children"
                      ? item.IDNo === null
                        ? ""
                        : "IC No / Passport No : " + item.IDNo
                      : ""}
                  </p>
                </Col>
              </Row>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
};

EmpySingleList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSelectable: PropTypes.bool,
  selectedDetails: PropTypes.array,
  selectedItems: PropTypes.array,
  onItemSelected: PropTypes.func,
  onDetailsSelected: PropTypes.func,
  data: PropTypes.array,
  onListActionsRendered: PropTypes.func,
};
