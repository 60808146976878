import { Status } from "../common/Status";

export default function AppraisalSubmissionModel({
  appraisalTransHdrKey = -1,
  apKey = -1,
  remarks = "",
  status = 0,
  statusDesc = Status[0],
  aPCodeAPDesc = "",
  reason = "",
  userRole = "user",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  fromSelfAppraisalDate = new Date(),
  toSelfAppraisalDate = new Date(),
  fromReviewDate = new Date(),
  toReviewDate = new Date(),
  dets = [],
}) {
  return Object.freeze({
    AppraisalTransHdrKey: appraisalTransHdrKey,
    APKey: apKey,
    Remarks: remarks,
    Status: status,
    StatusDesc: statusDesc,
    APCodeAPDesc: aPCodeAPDesc,
    Reason: reason,
    UserRole: userRole,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    FromSelfAppraisalDate: fromSelfAppraisalDate,
    ToSelfAppraisalDate: toSelfAppraisalDate,
    FromReviewDate: fromReviewDate,
    ToReviewDate: toReviewDate,
    Dets: dets,
  });
}
