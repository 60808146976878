import React, { useState, useEffect } from "react";
import ManageVehicleRequisitionForm from "../../forms/VehicleReq/ManageVehicleRequisitionForm";
import VehicleRequisitionModel from "../../model/VehicleReq/VehicleRequisitionModel";
import VehicleReqPassengerModel from "../../model/VehicleReq/VehicleReqPassengerModel";
import {
  getVehicleRequisitionByKey,
  addVehicleRequisition,
  updateVehicleRequisition,
  deleteVehicleRequisition,
  withdrawVehicleRequisition,
  requestCancelVehicleRequisition,
  assignVehicleAndMiles,
  completeVehicleRequisition,
} from "../../../api/vehicleRequisitionApi";
import { getAvailableVehiclesForAssignment } from "../../../api/vehicleApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newVehicleRequisition = VehicleRequisitionModel({ passengers: [VehicleReqPassengerModel({ type: "D", typeDesc: "Driver" })] });

const ManageVehicleRequisitionPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const VEH_REQ_FORM_MESSAGE = {
    VEH_REQ_SAVED: t("vehicleRequisitionForm.veh_req_saved"),
    VEH_REQ_SUBMITTED: t("vehicleRequisitionForm.veh_req_submitted"),
    VEH_REQ_WITHDRAWN: t("vehicleRequisitionForm.veh_req_withdrawn"),
    VEH_REQ_DELETED: t("vehicleRequisitionForm.veh_req_deleted"),
    VEH_REQ_CANCEL_REQUEST: t("vehicleRequisitionForm.veh_req_cancel_request"),
    VEH_REQ_COMPLETE: t("vehicleRequisitionForm.veh_req_complete"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newVehicleRequisition);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isAssignor, setIsAssignor] = useState(false);
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/vehiclerequisitionapproval");
      } else if (location.state.fromVoid) {
        history("/home/vehiclerequisitionvoid");
      } else if (location.state.fromCancel) {
        history("/home/vehiclerequisitioncancellationapproval");
      } else if (location.state.fromAssignment) {
        history("/home/vehicleassignment");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/facilities/vehicledisplay", {state: location.state});
      } else {
        history("/home/vehiclerequisitiondisplay");
      }
    } else {
      history("/home/vehiclerequisitiondisplay");
    }
  }

  function onSaved(vehicleRequisitionData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...vehicleRequisitionData,
      Remarks: vehicleRequisitionData.Remarks.trim(),
    };

    let upsertVehicleRequisitionData =
      dataSubmit.VehicleRequisitionKey !== -1
        ? updateVehicleRequisition
        : addVehicleRequisition;

    return upsertVehicleRequisitionData(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? VEH_REQ_FORM_MESSAGE.VEH_REQ_SAVED
          : isStatusSubmitted(result.Status)
            ? VEH_REQ_FORM_MESSAGE.VEH_REQ_SUBMITTED
            : VEH_REQ_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/vehiclerequisition/vehicletrans/", {
            state: { force: true },
          });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history(
            "/home/vehiclerequisition/vehicletrans/" +
            result.VehicleRequisitionKey,
            {
              state: { force: true },
            }
          );
          loadPageData(result.VehicleRequisitionKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onDelete(vehicleRequisitionData) {
    setIsLoading(true);
    return deleteVehicleRequisition({ ...editItem, ...vehicleRequisitionData })
      .then(() => {
        toast.success(VEH_REQ_FORM_MESSAGE.VEH_REQ_DELETED);
        history("/home/vehiclerequisition/vehicletrans/", {
          state: { force: true },
        });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onWithdraw(vehicleRequisitionData) {
    setIsLoading(true);
    return withdrawVehicleRequisition({
      ...editItem,
      ...vehicleRequisitionData,
    })
      .then(() => {
        toast.success(VEH_REQ_FORM_MESSAGE.VEH_REQ_WITHDRAWN);
        loadPageData(editItem.VehicleRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onRequestCancel(vehicleRequisitionData, reason) {
    setIsLoading(true);
    return requestCancelVehicleRequisition(
      {
        ...editItem,
        ...vehicleRequisitionData,
      },
      reason
    )
      .then(() => {
        toast.success(VEH_REQ_FORM_MESSAGE.VEH_REQ_CANCEL_REQUEST);
        loadPageData(editItem.VehicleRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onAssignVehicleAndMiles(vehicleRequisitionData) {
    setIsLoading(true);
    return assignVehicleAndMiles({
      ...editItem,
      ...vehicleRequisitionData,
    })
      .then(() => {
        setIsDirty(false);
        toast.success(VEH_REQ_FORM_MESSAGE.VEH_REQ_SAVED);
        loadPageData(editItem.VehicleRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onCompleteVehicleRequisition(vehicleRequisitionData) {
    setIsLoading(true);
    return completeVehicleRequisition({
      ...editItem,
      ...vehicleRequisitionData,
    })
      .then(() => {
        setIsDirty(false);
        toast.success(VEH_REQ_FORM_MESSAGE.VEH_REQ_COMPLETE);
        loadPageData(editItem.VehicleRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Vehicle")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];
    promises.push(getAvailableVehiclesForAssignment(editKey ? editKey : -1));

    if (editKey) promises.push(getVehicleRequisitionByKey(editKey));

    Promise.all(promises)
      .then((values) => {
        let allVehicles = values[0] ? values[0] : [];
        let vehicleRequisitionToEdit = values[1];

        if (vehicleRequisitionToEdit) {
          let editedFiles = vehicleRequisitionToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });

          setFileList(editedFiles);

          vehicleRequisitionToEdit.StatusDesc =
            Status[vehicleRequisitionToEdit.Status];
          vehicleRequisitionToEdit.ReqDate = [
            dayjs(vehicleRequisitionToEdit.FromReqDate),
            dayjs(vehicleRequisitionToEdit.ToReqDate),
          ];
          vehicleRequisitionToEdit.TransDate = new Date(
            vehicleRequisitionToEdit.TransDate
          );

          setEditItem(vehicleRequisitionToEdit);
          setIsOwner(
            isLoginUser(vehicleRequisitionToEdit.UserKey) ||
            isEditor(vehicleRequisitionToEdit.CreatedBy)
          );
          setIsAssignor(vehicleRequisitionToEdit.IsAssignor);
        } else {
          setFileList([]);
          setEditItem(newVehicleRequisition);
          setIsOwner(true);
          setIsAssignor(false);
        }

        setAllVehicles(allVehicles);
        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.ExceptionMessage);
      });
  }

  return (
    <ManageVehicleRequisitionForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onAssignVehicleAndMiles={onAssignVehicleAndMiles}
      onCompleteVehicleRequisition={onCompleteVehicleRequisition}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      editItem={editItem}
      onDataChanged={setEditItem}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      fileList={fileList}
      setFileList={setFileList}
      allVehicles={allVehicles}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      isAssignor={isAssignor}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
};

ManageVehicleRequisitionPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageVehicleRequisitionPage;
