import React, { useState, useEffect } from "react";
import { Input, Row, Col, Button, Card, Spin, Tabs, Form } from "antd";
import { APRSubjectTable } from "../Master/APRSubjectTable";
import { APRSubjectForm } from "../Master/APRSubjectForm";
import { APRBySubjectTable } from "../Master/APRBySubjectTable";
import { APRBySubjectForm } from "../Master/APRBySubjectForm";
import PropTypes from "prop-types";
import * as UserType from "../../../redux/actions/actionTypes";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const APRConfgInputs = ({
  isLoading,
  aprConfg,
  empys,
  allEmpys,
  approvers,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formBySubject] = Form.useForm();
  const [formSubject] = Form.useForm();
  const formats = { cellSpacing: 20 };
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [showApprovedByForm, setshowApprovedByForm] = useState(false);
  const [showApproverForm, setshowApproverForm] = useState(false);
  const [selectedAPRConfg, setSelectedAPRConfg] = useState([]);
  const [activeTab, setActiveTab] = useState("0");
  const [rowSelected, setRowSelected] = useState({});
  const [isApplyToAll, setIsApplyToAll] = useState(false);

  useEffect(() => {
    formBySubject.resetFields();
    formSubject.resetFields();
  }, [isFormInitialized, formBySubject, formSubject]);

  function editAPRSubject(refKey) {
    let refAPRConfg = aprConfg.APRUserConfg.filter(
      (item) => item.Subject === refKey
    );

    let newAprConfgApprover = {
      AprConfgApproverKey: "-1",
      AprConfgKey: refAPRConfg[0].APRConfgKey,
      aprConfgApprovedBy1Key: [],
      aprConfgApprovedBy2Key: [],
      aprConfgApprover1Key: [],
      aprConfgApprover2Key: [],
    };

    let newAprConfgValidator = {
      AprConfgValidatorKey: "-1",
      AprConfgKey: refAPRConfg[0].APRConfgKey,
      aprConfgValidatedByKeys: [],
      aprConfgValidatorKeys: [],
    };

    if (refAPRConfg[0].AprConfgApprover.length === 0)
      refAPRConfg[0].AprConfgApprover.push(newAprConfgApprover);
    if (refAPRConfg[0].AprConfgValidator.length === 0)
      refAPRConfg[0].AprConfgValidator.push(newAprConfgValidator);
    setSelectedAPRConfg(refAPRConfg);
    setIsApplyToAll(false);
    if (activeTab === "0") setshowApprovedByForm(true);
    else setshowApproverForm(true);
    setIsFormInitialized(!isFormInitialized);
  }

  function cancelInputAPRConfg() {
    if (activeTab === "0") {
      setshowApprovedByForm(false);
    } else {
      setshowApproverForm(false);
    }
  }

  function saveAPRConfg(values) {
    let temp1 = aprConfg.APRUserConfg.filter(
      (y) => y.SubjectDesc === values.Subject
    );

    if (isApplyToAll) {
      temp1 = aprConfg.APRUserConfg;
    }

    for (let d = 0; d < temp1.length; d++) {
      let newAprConfgApprover = {
        AprConfgApproverKey: "-1",
        AprConfgKey: temp1[d].APRConfgKey,
        aprConfgApprovedBy1Key: [],
        aprConfgApprovedBy2Key: [],
        aprConfgApprover1Key: [],
        aprConfgApprover2Key: [],
      };

      let newAprConfgValidator = {
        AprConfgValidatorKey: "-1",
        AprConfgKey: temp1[d].APRConfgKey,
        aprConfgValidatedByKeys: [],
        aprConfgValidatorKeys: [],
      };

      if (temp1[d].AprConfgApprover.length === 0)
        temp1[d].AprConfgApprover.push(newAprConfgApprover);
      if (temp1[d].AprConfgValidator.length === 0)
        temp1[d].AprConfgValidator.push(newAprConfgValidator);

      let updatedAprConfgApprover = {
        AprConfgApproverKey: temp1[d].AprConfgApprover[0].AprConfgApproverKey,
        AprConfgKey: temp1[d].AprConfgApprover[0].AprConfgKey,
        aprConfgApprovedBy1Key:
          activeTab === "0"
            ? values.aprConfgApprovedBy1Key
            : temp1[d].AprConfgApprover[0].aprConfgApprovedBy1Key,
        aprConfgApprovedBy2Key:
          activeTab === "0"
            ? values.aprConfgApprovedBy2Key === undefined ||
              temp1[d].SubjectAppLvl < 2
              ? []
              : values.aprConfgApprovedBy2Key
            : temp1[d].AprConfgApprover[0].aprConfgApprovedBy2Key,
        aprConfgApprover1Key:
          activeTab === "1"
            ? values.aprConfgApprover1Key
            : temp1[d].AprConfgApprover[0].aprConfgApprover1Key,
        aprConfgApprover2Key:
          activeTab === "1"
            ? values.aprConfgApprover2Key === undefined ||
              temp1[d].SubjectAppLvl < 2
              ? []
              : values.aprConfgApprover2Key
            : temp1[d].AprConfgApprover[0].aprConfgApprover2Key,
      };

      let updatedAprConfgValidator = {
        AprConfgValidatorKey:
          temp1[d].AprConfgValidator[0].AprConfgValidatorKey,
        AprConfgKey: temp1[d].AprConfgValidator[0].AprConfgKey,
        aprConfgValidatedByKeys:
          activeTab === "0"
            ? values.aprConfgValidatedByKeys
            : temp1[d].AprConfgValidator[0].aprConfgValidatedByKeys,
        aprConfgValidatorKeys:
          activeTab === "1"
            ? values.aprConfgValidatorKeys
            : temp1[d].AprConfgValidator[0].aprConfgValidatorKeys,
      };

      //update in both tab if current user is being added/removed in either tab
      if (activeTab === "0") {
        if (
          values.aprConfgApprovedBy1Key.includes(temp1[d].UserKey) &&
          !updatedAprConfgApprover.aprConfgApprover1Key.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgApprover.aprConfgApprover1Key.push(temp1[d].UserKey);
        } else if (!values.aprConfgApprovedBy1Key.includes(temp1[d].UserKey)) {
          updatedAprConfgApprover.aprConfgApprover1Key =
            updatedAprConfgApprover.aprConfgApprover1Key.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
        if (
          values.aprConfgApprovedBy2Key.includes(temp1[d].UserKey) &&
          !updatedAprConfgApprover.aprConfgApprover2Key.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgApprover.aprConfgApprover2Key.push(temp1[d].UserKey);
        } else if (!values.aprConfgApprovedBy2Key.includes(temp1[d].UserKey)) {
          updatedAprConfgApprover.aprConfgApprover2Key =
            updatedAprConfgApprover.aprConfgApprover2Key.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
        if (
          values.aprConfgValidatedByKeys.includes(temp1[d].UserKey) &&
          !updatedAprConfgValidator.aprConfgValidatorKeys.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgValidator.aprConfgValidatorKeys.push(temp1[d].UserKey);
        } else if (!values.aprConfgValidatedByKeys.includes(temp1[d].UserKey)) {
          updatedAprConfgValidator.aprConfgValidatorKeys =
            updatedAprConfgValidator.aprConfgValidatorKeys.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
      } else {
        if (
          values.aprConfgApprover1Key.includes(temp1[d].UserKey) &&
          !updatedAprConfgApprover.aprConfgApprovedBy1Key.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgApprover.aprConfgApprovedBy1Key.push(temp1[d].UserKey);
        } else if (!values.aprConfgApprover1Key.includes(temp1[d].UserKey)) {
          updatedAprConfgApprover.aprConfgApprovedBy1Key =
            updatedAprConfgApprover.aprConfgApprovedBy1Key.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
        if (
          values.aprConfgApprover2Key.includes(temp1[d].UserKey) &&
          !updatedAprConfgApprover.aprConfgApprovedBy2Key.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgApprover.aprConfgApprovedBy2Key.push(temp1[d].UserKey);
        } else if (!values.aprConfgApprover2Key.includes(temp1[d].UserKey)) {
          updatedAprConfgApprover.aprConfgApprovedBy2Key =
            updatedAprConfgApprover.aprConfgApprovedBy2Key.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
        if (
          values.aprConfgValidatorKeys.includes(temp1[d].UserKey) &&
          !updatedAprConfgValidator.aprConfgValidatedByKeys.includes(
            temp1[d].UserKey
          )
        ) {
          updatedAprConfgValidator.aprConfgValidatedByKeys.push(
            temp1[d].UserKey
          );
        } else if (!values.aprConfgValidatorKeys.includes(temp1[d].UserKey)) {
          updatedAprConfgValidator.aprConfgValidatedByKeys =
            updatedAprConfgValidator.aprConfgValidatedByKeys.filter(
              (x) => x !== temp1[d].UserKey
            );
        }
      }

      //update name from selected keys to display in table
      temp1[d].L1ApprovedBy = allEmpys
        .filter((x) =>
          updatedAprConfgApprover.aprConfgApprovedBy1Key.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].L2ApprovedBy = allEmpys
        .filter((x) =>
          updatedAprConfgApprover.aprConfgApprovedBy2Key.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].ValidatedBy = allEmpys
        .filter((x) =>
          updatedAprConfgValidator.aprConfgValidatedByKeys.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].L1Approvers = allEmpys
        .filter((x) =>
          updatedAprConfgApprover.aprConfgApprover1Key.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].L2Approvers = allEmpys
        .filter((x) =>
          updatedAprConfgApprover.aprConfgApprover2Key.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].Validators = allEmpys
        .filter((x) =>
          updatedAprConfgValidator.aprConfgValidatorKeys.includes(x.UserKey)
        )
        .map((x) => x.EmpyID + " - " + x.EmpyName);

      temp1[d].AprConfgApprover[0] = updatedAprConfgApprover;
      temp1[d].AprConfgValidator[0] = updatedAprConfgValidator;
      temp1[d].EmailNotification = values.EmailNotification;
    }

    let temp = aprConfg.APRUserConfg.map((y) => {
      for (let e = 0; e < temp1.length; e++) {
        return y.Subject === temp1[e].Subject ? temp1[e] : y;
      }
      return y;
    });

    if (temp) {
      onDataChanged({ ...aprConfg, APRUserConfg: temp });
      if (activeTab === "0") setshowApprovedByForm(false);
      else setshowApproverForm(false);
    }
    setIsFormInitialized(!isFormInitialized);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    setRowSelected(value);
  }

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  function getTabItems() {
    let items = [
      {
        key: "0",
        label: t("aprConfigForm.approved_by"),
        children: (
          <APRBySubjectTable
            data={aprConfg.APRUserConfg}
            onEdited={editAPRSubject}
            onRowSelected={onRowSelected}
          />
        ),
      },
    ];

    if (aprConfg.RoleTypeCode.toUpperCase() !== UserType.USER_ROLE_USER) {
      items.push({
        key: "1",
        label: t("aprConfigForm.approver"),
        children: (
          <APRSubjectTable
            data={aprConfg.APRUserConfg}
            onEdited={editAPRSubject}
            onRowSelected={onRowSelected}
          />
        ),
      });
    }

    return items;
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("aprConfigForm.approval_settings")}
                </span>
              }
            >
              <Row gutter={formats.cellSpacing}>
                <Col span={12}>
                  <Form.Item
                    label={t("general.employee_name")}
                    name={"EmpyName"}
                    initialValue={
                      aprConfg.EmpyID === ""
                        ? undefined
                        : aprConfg.EmpyID + " - " + aprConfg.EmpyName
                    }
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={getTabItems()}
              ></Tabs>
            </Card>

            <APRBySubjectForm
              isVisible={showApprovedByForm}
              empys={approvers}
              allEmpys={allEmpys}
              selectedAPRConfg={selectedAPRConfg}
              onSaved={saveAPRConfg}
              onCancelled={cancelInputAPRConfg}
              setIsDirty={setIsDirty}
              isActiveUser={aprConfg.IsActive}
              onIsApplyToAllChange={setIsApplyToAll}
              isApplyToAllData={isApplyToAll}
              form={formBySubject}
            />

            <APRSubjectForm
              isVisible={showApproverForm}
              empys={empys}
              allEmpys={allEmpys}
              selectedAPRConfg={selectedAPRConfg}
              onSaved={saveAPRConfg}
              onCancelled={cancelInputAPRConfg}
              setIsDirty={setIsDirty}
              onIsApplyToAllChange={setIsApplyToAll}
              isApplyToAllData={isApplyToAll}
              form={formSubject}
            />

            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: 10 }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  style={{ marginRight: 10 }}
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={rowSelected}
              showFooter={
                rowSelected.UpdatedBy && rowSelected.UpdatedBy !== -1
                  ? true
                  : false
              }
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

APRConfgInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  aprConfg: PropTypes.object.isRequired,
  empys: PropTypes.array.isRequired,
  allEmpys: PropTypes.array.isRequired,
  approvers: PropTypes.array.isRequired,
  subjects: PropTypes.array.isRequired,
  getAPRConfgInputSelectionData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export const ManageAPRConfgForm = APRConfgInputs;
