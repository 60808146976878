import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "travelconfg/";

export function getTravelConfg() {
  return axios({
    method: "GET",
    url: base + "GetTravelConfg",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addTravelConfg(travelConfg) {
  return axios({
    method: "POST",
    url: base + "AddTravelConfg",
    headers: authHeader(),
    data: travelConfg,
  })
    .then()
    .catch(handleError);
}

export function updateTravelConfg(travelConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateTravelConfg",
    headers: authHeader(),
    params: { key: travelConfg.TravelConfgKey },
    data: travelConfg,
  })
    .then()
    .catch(handleError);
}
