import React, { useState } from "react";
import ReloginForm from "../forms/ReloginForm";
import PropTypes from "prop-types";
import { stopSignalR } from "../../service/signalRService";
import { useNavigate } from "react-router-dom";
import { login } from "../../api/userApi";

function ReloginPage({ showLoginModal, setShowLoginModal }) {
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const [data] = useState({
    email: auth !== null ? auth.UserEmail : "",
    password: "",
    rememberMe: "",
    client: 1,
    isValidated: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [isValidated, setIsValidated] = useState(false);
  const navigate = useNavigate();

  function handleClose() {
    setShowErrMsg(false);
  }

  function submit(data) {
    setIsLoading(true);
    login(data)
      .then(() => {
        setShowLoginModal(false);
        setIsLoading(false);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.error_description);
        setIsLoading(false);
        setShowErrMsg(true);
        setIsValidated(false);
      });
  }

  function logout() {
    setShowLoginModal(false);
    sessionStorage.removeItem("auth");
    sessionStorage.removeItem("localUserKey");
    stopSignalR();
    navigate("/login", { replace: true });
  }

  return (
    <ReloginForm
      submit={submit}
      showLoginModal={showLoginModal}
      logout={logout}
      data={data}
      isLoading={isLoading}
      errorMessage={errorMessage}
      showErrMsg={showErrMsg}
      isValidated={isValidated}
      handleClose={handleClose}
    />
  );
}

ReloginPage.propTypes = {
  history: PropTypes.func.isRequired,
  setShowLoginModal: PropTypes.func.isRequired,
  showLoginModal: PropTypes.bool.isRequired,
};

export default ReloginPage;
