import React, { useEffect, useState } from "react";
import AppraisalSubmissionDisplayForm from "../../forms/Appraisal/AppraisalSubmissionDisplayForm";
import PropTypes from "prop-types";
import { getAppraisalSubmissions } from "../../../api/appraisalSubmissionApi";
import { toast } from "react-toastify";
import Axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const AppraisalSubmissionDisplayPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [appraisalSubmissionDisplay, setAppraisalSubmissionDisplay] = useState(
    []
  );
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getAppraisalSubmissions(source, currentYear)
      .then((result) => {
        setAppraisalSubmissionDisplay(result);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    return () => {
      source.cancel();
    };
  }, [currentYear, t]);

  function onAddNewAppraisal(atKey, userKey, apKey, arKey) {
    history("/home/appraisalsubmission/appraisaltrans/", {
      state: {
        templateKey: atKey,
        userKey: userKey,
        appraisalKey: apKey,
        appraisalRatingKey: arKey,
      },
    });
  }

  function viewAppraisal(appraisalTransHdrKey) {
    history("/home/appraisalsubmission/appraisaltrans/" + appraisalTransHdrKey);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <AppraisalSubmissionDisplayForm
      isLoading={isLoading}
      data={appraisalSubmissionDisplay}
      onAdd={onAddNewAppraisal}
      onView={viewAppraisal}
      setCurrentYear={setCurrentYear}
    />
  );
};

AppraisalSubmissionDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default AppraisalSubmissionDisplayPage;
