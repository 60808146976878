export default function ProfileEducationModel({
  empyAprKey = -1,
  empyEduDetKey = -1,
  empyKey = -1,
  education = "",
  remarks = "",
  year = new Date().getFullYear(),
  isSelect = false,
  rowState = 0,
}) {
  return Object.freeze({
    EmpyAprKey: empyAprKey,
    EmpyEduDetKey: empyEduDetKey,
    EmpyKey: empyKey,
    Education: education,
    Remarks: remarks,
    Year: year,
    isSelect: isSelect,
    RowState: rowState,
  });
}
