export default function ExRateModel({
  exRateHdrKey = -1,
  exDate = new Date(),
  remarks = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  exRateDets = [],
  rowVersion = null
}) {
  return Object.freeze({
    ExRateHdrKey: exRateHdrKey,
    ExDate: exDate,
    Remarks: remarks,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    ExRateDets: exRateDets,
    RowVersion: rowVersion
  });
}
