import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col } from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import { ClinicList } from "./ClinicList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function ClinicVisitApprovalForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onApproved,
  onRejected,
  onItemSelected,
  onAttachmentClicked,
  setCurrentYear,
  currentYear,
}) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [tempItem, setTempItem] = useState();
  const [isAllowAmend, setIsAllowAmend] = useState(false);

  function submitRejectReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //reject single item
      onRejected([tempItem], reason, isAllowAmend);
    } else {
      //reject multiple items
      onRejected(selectedItems, reason, isAllowAmend);
    }
  }

  function rejectClinic(selectedClinic, isAllowAmend) {
    setTempItem(selectedClinic);
    setIsAllowAmend(isAllowAmend);
    setShowReasonForm(true);
  }

  function rejectClinics(isAllowAmend) {
    setTempItem(null);
    setIsAllowAmend(isAllowAmend);
    setShowReasonForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="default"
          size="default"
          style={{ marginRight: 10, display: !item.IsViewOnly ? "" : "none" }}
          onClick={() => rejectClinic(item, true)}
        >
          {t("general.reject_with_amendment")}
        </Button>
        <Button
          type="default"
          size="default"
          style={{ marginRight: 10, display: !item.IsViewOnly ? "" : "none" }}
          onClick={() => rejectClinic(item, false)}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          size="default"
          onClick={() => onApproved([item])}
          style={{
            display: !item.IsViewOnly ? "" : "none",
          }}
        >
          {t("general.approve")}
        </Button>
      </div>,
    ];
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected(data.filter((x) => !x.IsViewOnly));
      } else {
        onItemSelected([]);
      }
    }
  }

  const footerElement = (footerValue) => (
    <Row>
      <Col span={6} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length &&
            selectedItems.length < data.filter((x) => !x.IsViewOnly).length
          }
          checked={
            selectedItems.length &&
            selectedItems.length === data.filter((x) => !x.IsViewOnly).length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={18} style={{ textAlign: "right" }}>
        {t("general.total")} : <b style={{ color: "#0070cc" }}>{footerValue}</b>
        <Button
          type="default"
          style={{ marginRight: "5px", marginLeft: "10px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => rejectClinics(true)}
        >
          {t("general.reject_with_amendment")}
        </Button>
        <Button
          type="default"
          style={{ marginRight: "5px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => rejectClinics(false)}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => onApproved(selectedItems)}
        >
          {t("general.approve")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <ClinicList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        filterCheckedText={t("clinicVisitForm.show_pending_clinic_only")}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        currentYear={currentYear}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Reject"}
        onSaved={submitRejectReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
}

ClinicVisitApprovalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
  onRejected: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
};
