export const inputLocaleFormatter = (precision) => (value, userTyping) => {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.Region) {
    if (auth.Region === "M") {
      if (userTyping.userTyping === true)
        return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else {
        if (
          isNaN(parseFloat(`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")))
        ) {
          return (0).toFixed(precision);
        } else {
          return `${parseFloat(value).toFixed(precision)}`.replace(
            /\B(?=(\d{3})+(?!\d))/g,
            ","
          );
        }
      }
    } else if (auth.Region === "I") {
      if (userTyping.userTyping === true)
        return `${value}`
          .replace(/\./, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      else {
        if (
          isNaN(
            parseFloat(
              `${value}`
                .replace(/\./, ",")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            )
          )
        ) {
          return (0).toFixed(precision);
        } else {
          return `${parseFloat(value).toFixed(precision)}`
            .replace(/\./, ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
      }
    } else {
      return (0).toFixed(precision);
    }
  } else {
    return (0).toFixed(precision);
  }
};

export const inputLocaleParser = (precision) => (value) => {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.Region) {
    if (auth.Region === "M") {
      if (value.replace(/\$\s?|(,*)/g, "") === "") {
        return (0).toFixed(precision);
      } else {
        return value.replace(/\$\s?|(,*)/g, "");
      }
    } else if (auth.Region === "I") {
      if (
        `${value}`.replace(/,/, "#").replace(/\./g, "").replace(/#/, ".") === ""
      ) {
        return (0).toFixed(precision);
      } else {
        return `${value}`
          .replace(/,/, "#")
          .replace(/\./g, "")
          .replace(/#/, ".");
      }
    } else {
      return (0).toFixed(precision);
    }
  } else {
    return (0).toFixed(precision);
  }
};

export const textLocaleFormatter = (value, precision) => {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.Region) {
    if (auth.Region === "M") {
      if (isNaN(parseFloat(`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")))) {
        return (0).toFixed(precision);
      } else {
        return `${parseFloat(value).toFixed(precision)}`.replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        );
      }
    } else if (auth.Region === "I") {
      if (
        isNaN(
          parseFloat(
            `${value}`.replace(/\./, ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          )
        )
      ) {
        return (0).toFixed(precision);
      } else {
        return `${parseFloat(value).toFixed(precision)}`
          .replace(/\./, ",")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    } else {
      return (0).toFixed(precision);
    }
  } else {
    return (0).toFixed(precision);
  }
};

export const regionLocale = () => {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.Region) {
    if (auth.Region === "M") {
      return "en-MY";
    } else if (auth.Region === "I") {
      return "id-ID";
    }
  }
};

export const stepPrecision = (currCode) => {
  if (currCode === "IDR") {
    return 1;
  } else {
    return 0.1;
  }
};

export const precisionDecimal = (currCode) => {
  if (currCode === "IDR") {
    return 0;
  } else {
    return 2;
  }
};
