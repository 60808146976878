import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import VehicleRequisitionCancellationApprovalForm from "../../forms/VehicleReq/VehicleRequisitionCancellationApprovalForm";
import {
  getCancelledVehicleRequisitions,
  approveCancelledVehicleRequisitions,
  rejectCancelledVehicleRequisitions,
} from "../../../api/vehicleRequisitionApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function VehicleRequisitionCancellationApprovalPage({
  history,
}) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [vehicleRequisitions, setVehicleRequisitions] = useState([]);
  const [selectedVehicleRequisitions, setSelectedVehicleRequisitions] =
    useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );
  useEffect(getVehicleRequisitions, [currentYear]);

  function getVehicleRequisitions() {
    setIsLoading(true);
    getCancelledVehicleRequisitions(currentYear)
      .then((value) => {
        setVehicleRequisitions(value ? value : []);
        setSelectedVehicleRequisitions([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewVehicleRequisition() {
    history("/home/vehiclerequisition/vehicletrans");
  }

  function onApproveVehicleRequisitions(selectedVehicleRequisitions) {
    setIsLoading(true);

    approveCancelledVehicleRequisitions(selectedVehicleRequisitions)
      .then(() => {
        getVehicleRequisitions();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectVehicleRequisitions(selectedVehicleRequisitions, reason) {
    setIsLoading(true);

    rejectCancelledVehicleRequisitions(selectedVehicleRequisitions, reason)
      .then(() => {
        getVehicleRequisitions();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewVehicleRequisitions(vehicleRequisitionKey) {
    history("/home/vehiclerequisition/vehicletrans/" + vehicleRequisitionKey, {
      state: { fromCancel: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <VehicleRequisitionCancellationApprovalForm
      isLoading={isLoading}
      data={vehicleRequisitions}
      selectedItems={selectedVehicleRequisitions}
      onAdd={onAddNewVehicleRequisition}
      onView={viewVehicleRequisitions}
      onApproved={onApproveVehicleRequisitions}
      onRejected={onRejectVehicleRequisitions}
      onItemSelected={setSelectedVehicleRequisitions}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
}

VehicleRequisitionCancellationApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
