import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { Row, Col } from "antd";
import ProfileWidget from "../forms/Dashboard/ProfileWidget";
// import EmployeeOnLeaveWidget from "../forms/Dashboard/EmployeeOnLeaveWidget";
// import EmployeeOnTravelWidget from "../forms/Dashboard/EmployeeOnTravelWidget";
import EmployeeOutOfOfficeWidget from "../forms/Dashboard/EmployeeOutOfOfficeWidget";
import LeaveApplicationWidget from "../forms/Dashboard/LeaveApplicationWidget";
import PendingApprovalSummaryWidget from "../forms/Dashboard/PendingApprovalSummaryWidget";
import ClaimSubmissionWidget from "../forms/Dashboard/ClaimSubmissionWidget";
import AnnouncementWidget from "../forms/Dashboard/AnnouncementWidget";
import { dataSourceService } from "../../api/employeeApi";
import { getClaimDashboard } from "../../api/claimSubmissionApi";
import { getClaimConfg } from "../../api/claimConfgApi";
import { getPendingDashboardData } from "../../api/dashboardPendingDataApi";
import { getEmpyAttendance } from "../../api/widgetAttendanceApi";
import { getAllApprovedTravel } from "../../api/travelAuthorizationApi";
import { getDashboardLeaveTypeCode } from "../../api/leaveTypeApi";
import { getUserCompCurrency } from "../../api/currApi";
import { getAllPublishedAnnouncements } from "../../api/announcementApi";
import { StatusByHexColor } from "../model/common/Status";
import * as UserType from "../../redux/actions/actionTypes";
import "../../styles/dashboard.scss";
import {
  UserProfileDashboardModel,
  UserLeaveInfoDashboardModel,
  UserClaimInfoDashboardModel,
  AttendanceDashboardModel,
  pendingLeaveApprovalDashboardModel,
  pendingClaimsApprovalDashboardModel,
  pendingVehicleReqApprovalDashboardModel,
  pendingRoomReqApprovalDashboardModel,
  pendingPropMaintApprovalDashboardModel,
  pendingJobRecruitmentApprovalDashboardModel,
  pendingTrainingApprovalDashboardModel,
  pendingTravelAuthApprovalDashboardModel,
  pendingClinicVisitApprovalDashboardModel,
  AnnouncementDashboardModel,
  TravelDashboardModel,
} from "../model/Dashboard/dashboardModel";
import dayjs from "dayjs";

export default function DashboardPage({ history }) {
  const localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(UserProfileDashboardModel({}));
  const [userLeaveInfo, setUserLeaveInfo] = useState(
    UserLeaveInfoDashboardModel({})
  );
  const [userClaimInfo, setUserClaimInfo] = useState(
    UserClaimInfoDashboardModel({})
  );
  const [attendance, setAttendance] = useState(AttendanceDashboardModel({}));
  const [pendingLeaveApproval, setPendingLeaveApproval] = useState(
    pendingLeaveApprovalDashboardModel({})
  );
  const [pendingClaimsApproval, setPendingClaimsApproval] = useState(
    pendingClaimsApprovalDashboardModel({})
  );
  const [pendingJobRecruitmentApproval, setPendingJobRecruitmentApproval] =
    useState(pendingJobRecruitmentApprovalDashboardModel({}));
  const [pendingTravelAuthApproval, setPendingTravelAuthApproval] = useState(
    pendingTravelAuthApprovalDashboardModel({})
  );
  const [pendingClinicVisitApproval, setPendingClinicVisitApproval] = useState(
    pendingClinicVisitApprovalDashboardModel({})
  );
  const [pendingTrainingApproval, setPendingTrainingApproval] = useState(
    pendingTrainingApprovalDashboardModel({})
  );
  const [pendingVehicleReqApproval, setPendingVehicleReqApproval] = useState(
    pendingVehicleReqApprovalDashboardModel({})
  );
  const [pendingRoomReqApproval, setPendingRoomReqApproval] = useState(
    pendingRoomReqApprovalDashboardModel({})
  );
  const [pendingPropMaintApproval, setPendingPropMaintApproval] = useState(
    pendingPropMaintApprovalDashboardModel({})
  );
  const [isApprovalLevel, setIsApprovalLevel] = useState(false);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [announcement, setAnnouncement] = useState(
    AnnouncementDashboardModel({})
  );
  const [travel, setTravel] = useState(TravelDashboardModel({}));

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    const auth = JSON.parse(sessionStorage.getItem("auth"));
    const role = !auth ? "USER" : auth.UserRoleType;

    let promises = [
      dataSourceService.getDashboardEmployeeProfile(source),
      getClaimDashboard(source),
      getClaimConfg(source),
      getEmpyAttendance(dayjs().format("MM/DD/YYYY")),
      getUserCompCurrency(source),
      getAllPublishedAnnouncements(source),
      getAllApprovedTravel(dayjs().format("MM/DD/YYYY")),
      getDashboardLeaveTypeCode(source),
    ];

    switch (role) {
      case UserType.USER_ROLE_ADMIN:
      case UserType.USER_ROLE_APPROVER:
        setIsApprovalLevel(true);
        promises.push(getPendingDashboardData(source));
        break;
      case UserType.USER_ROLE_USER:
        setIsApprovalLevel(false);
        break;
      default:
        setIsApprovalLevel(false);
    }

    Promise.all(promises).then((data) => {
      if (data[0]) {
        if (data[0].length > 0) {
          setUserProfile((prevState) => {
            return {
              ...prevState,
              profileImage: data[0][0].EmpyPerMasPhotoUrl,
              displayName: data[0][0].EmpyName,
              Position: data[0][0].PostDesc,
              Department: data[0][0].DeptCodeDeptDesc,
              Grade: data[0][0].GradeDesc,
              ContactNo: data[0][0].Mobile || data[0][0].Tel,
            };
          });
        }
      }

      if (data[1]) {
        var claims = [];
        if (data[1].length > 0) {
          var claimList = data[1];
          claimList.forEach((element) => {
            const item = {
              key: element.ClaimTransKey,
              date: element.TransDate,
              amount: element.FuncTotalAmount,
              status: element.Status,
              dcolor: StatusByHexColor[element.Status],
              ClaimYearMonth: element.ClaimYearMonth,
            };
            claims.push(item);
          }, claims);
        }
      }

      if (data[2]) {
        setUserClaimInfo((prevState) => {
          return {
            ...prevState,
            cutOffDay: data[2] ? data[2].CutOffDay : 1,
            claimsApplied: claims,
          };
        });
      }

      if (data[3]) {
        setAttendance((prevState) => {
          return {
            ...prevState,
            absent: data[3].length,
            employeeAttendance: data[3],
          };
        });
      }

      if (data[4]) {
        setUserCompCurr(data[4] ? data[4] : {});
      }

      if (data[5]) {
        setAnnouncement((prevState) => {
          return {
            ...prevState,
            announcement: data[5].filter((item) => item.Active === true),
          };
        });
      }

      if (data[6]) {
        setTravel((prevState) => {
          return {
            ...prevState,
            travel: data[6].length,
            employeeOnTravel: data[6],
          };
        });
      }

      if (data[7]) {
        var alLeave = [];
        if (data[7].length > 0) {
          alLeave = data[7].filter((x) => x.Code === "AL");
        }

        setUserLeaveInfo((prevState) => {
          return {
            ...prevState,
            ALCarryForward:
              alLeave.length > 0 ? alLeave[0].TotalCarryForward : 0,
            ALEntitled:
              alLeave.length > 0
                ? alLeave[0].TotalLeave -
                  alLeave[0].TotalCarryForward -
                  alLeave[0].AdjustmentDay
                : 0,
            ALTotal: alLeave.length > 0 ? alLeave[0].TotalLeave : 0,
            ALRemain: alLeave.length > 0 ? alLeave[0].TotalAvailableLeave : 0,
            ALExpiredCarryForward:
              alLeave.length > 0 ? alLeave[0].ExpiredCarryForward : 0,
            ALAdjustment: alLeave.length > 0 ? alLeave[0].AdjustmentDay : 0,
          };
        });
      }

      if (data[8]) {
        setPendingClaimsApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "CLM");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            claimsInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingLeaveApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "LVE");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingleaveInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingVehicleReqApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "VEH");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingVehicleReqInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingRoomReqApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "ROOM");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingRoomReqInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingPropMaintApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "PROP");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingPropMaintInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingJobRecruitmentApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "JOBR");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingJobRecruitmentInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingTrainingApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "TRN");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingTrainingInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingTravelAuthApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "TRV");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingTravelAuthInfo: recs,
            isMixStatus: isMix,
          };
        });

        setPendingClinicVisitApproval((prevState) => {
          var recs = data[8].filter((x) => x.Subject === "CLI");
          var totalRec = recs.length;
          var isMix = false;

          if (
            recs.some((x) => x.Status === 2) &&
            recs.some((x) => x.Status !== 2)
          ) {
            isMix = true;
          }

          return {
            ...prevState,
            pendingAmount: totalRec,
            pendingClinicVisitInfo: recs,
            isMixStatus: isMix,
          };
        });
      }

      setIsLoading(false);
    });

    return () => {
      source.cancel();
    };
  }, []);

  // function onChangeTravelDate(travelDate) {
  //   getAllApprovedTravel(travelDate.format("L"))
  //     .then((data) => {
  //       setTravel((prevState) => {
  //         return {
  //           ...prevState,
  //           travel: data.length,
  //           employeeOnTravel: data,
  //         };
  //       });
  //     })
  //     .catch(() => {});
  // }

  // function onChangeLeaveDate(leaveDate) {
  //   getEmpyAttendance(leaveDate.format("L"))
  //     .then((data) => {
  //       setAttendance((prevState) => {
  //         return {
  //           ...prevState,
  //           absent: data.length,
  //           employeeAttendance: data,
  //         };
  //       });
  //     })
  //     .catch(() => {});
  // }

  function onChangeOutOfOfficeDate(date) {
    getAllApprovedTravel(date.format("L"))
      .then((data) => {
        setTravel((prevState) => {
          return {
            ...prevState,
            travel: data.length,
            employeeOnTravel: data,
          };
        });
      })
      .catch(() => {});

    getEmpyAttendance(date.format("L"))
      .then((data) => {
        setAttendance((prevState) => {
          return {
            ...prevState,
            absent: data.length,
            employeeAttendance: data,
          };
        });
      })
      .catch(() => {});
  }

  return (
    <div className="dashboard">
      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <ProfileWidget isLoading={isLoading} userProfile={userProfile} />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          xxl={6}
          style={{ display: isApprovalLevel ? "block" : "none" }}
        >
          <PendingApprovalSummaryWidget
            isLoading={isLoading}
            pendingLeaveApproval={pendingLeaveApproval}
            pendingClaimsApproval={pendingClaimsApproval}
            pendingJobRecruitmentApproval={pendingJobRecruitmentApproval}
            pendingTravelAuthApproval={pendingTravelAuthApproval}
            pendingClinicVisitApproval={pendingClinicVisitApproval}
            pendingTrainingApproval={pendingTrainingApproval}
            pendingVehicleReqApproval={pendingVehicleReqApproval}
            pendingRoomReqApproval={pendingRoomReqApproval}
            pendingPropMaintApproval={pendingPropMaintApproval}
            history={history}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={12}>
          <EmployeeOutOfOfficeWidget
            isLoading={isLoading}
            attendance={attendance}
            travel={travel}
            onChangeDate={onChangeOutOfOfficeDate}
          />
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <EmployeeOnLeaveWidget
            isLoading={isLoading}
            attendance={attendance}
            onChangeDate={onChangeLeaveDate}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <EmployeeOnTravelWidget
            isLoading={isLoading}
            travel={travel}
            onChangeDate={onChangeTravelDate}
          />
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={12}>
          <AnnouncementWidget
            isLoading={isLoading}
            announcement={announcement}
            history={history}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <LeaveApplicationWidget
            isLoading={isLoading}
            userLeaveInfo={userLeaveInfo}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={6}>
          <ClaimSubmissionWidget
            isLoading={isLoading}
            userClaimInfo={userClaimInfo}
            userCompCurr={userCompCurr}
          />
        </Col>
      </Row>
    </div>
  );
}

DashboardPage.propTypes = {
  history: PropTypes.func.isRequired,
};
