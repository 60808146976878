import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "appraisalsubmission/";

export async function getAppraisalSubmissions(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(base + "GetAllAppraisalSubmissionsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAppraisalSubmissionByKey(transKey) {
  return axios
    .get(base + "GetAppraisalSubmissionByKey", {
      headers: authHeader(),
      params: {
        key: transKey,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addAppraisalSubmission(appraisalSubmission) {
  return axios({
    method: "POST",
    url: base + "AddAppraisalSubmission",
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateAppraisalSubmission(appraisalSubmission) {
  return axios({
    method: "POST",
    url:
      base +
      "UpdateAppraisalSubmission?key=" +
      appraisalSubmission.AppraisalTransHdrKey,
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteAppraisalSubmission(appraisalSubmission) {
  return axios({
    method: "POST",
    url:
      base +
      "DeleteAppraisalSubmission?key=" +
      appraisalSubmission.AppraisalTransHdrKey,
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawAppraisalSubmission(appraisalSubmission) {
  return axios({
    method: "POST",
    url:
      base +
      "WithdrawAppraisalSubmission?key=" +
      appraisalSubmission.AppraisalTransHdrKey,
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelAppraisalSubmission(
  appraisalSubmission,
  cancelReason
) {
  return axios({
    method: "POST",
    url:
      base +
      "RequestCancelAppraisalSubmission?key=" +
      appraisalSubmission.AppraisalTransHdrKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function revertAppraisalSubmission(appraisalSubmission) {
  return axios({
    method: "POST",
    url:
      base +
      "RevertAppraisalSubmission?key=" +
      appraisalSubmission.AppraisalTransHdrKey,
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then(handleResponse)
    .catch(handleError);
}
