import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col } from "antd";
import { JobRecruitmentList } from "./JobRecruitmentList";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function JobRecruitmentCancellationApprovalForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onApproved,
  onRejected,
  onItemSelected,
  onAttachmentClicked,
  setCurrentYear,
  currentYear,
}) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [tempItem, setTempItem] = useState();

  function submitRejectReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //reject single item
      onRejected([tempItem], reason);
    } else {
      //reject multiple items
      onRejected(selectedItems, reason);
    }
  }

  function rejectCancelledJobRecruitment(selectedJobRecruitment) {
    setTempItem(selectedJobRecruitment);
    setShowReasonForm(true);
  }

  function rejectCancelledJobRecruitments() {
    setTempItem(null);
    setShowReasonForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <Row>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <div
              style={{
                float: "left",
                fontStyle: "italic",
                marginLeft: "30px",
                paddingTop: "5px",
                textAlign: "left",
                lineHeight: "1.1",
                wordBreak: "break-all",
                paddingBottom: "10px",
                color: StatusByHexColor[item.Status],
              }}
            >
              {Status[item.Status]}
              <br /> <b>{t("general.reason")}:</b> {item.Reason}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Button
              type="default"
              size="default"
              style={{ marginRight: 10 }}
              onClick={() => rejectCancelledJobRecruitment(item)}
            >
              {t("general.reject")}
            </Button>
            <Button
              type="primary"
              size="default"
              onClick={() => onApproved([item])}
            >
              {t("general.approve")}
            </Button>
          </Col>
        </Row>
      </div>,
    ];
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected([...data]);
      } else {
        onItemSelected([]);
      }
    }
  }

  const footerElement = () => (
    <Row>
      <Col span={6} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length && selectedItems.length < data.length
          }
          checked={selectedItems.length === data.length}
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={18} style={{ textAlign: "right" }}>
        <Button
          type="default"
          style={{ marginRight: "5px", marginLeft: "10px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={rejectCancelledJobRecruitments}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={() => onApproved(selectedItems)}
        >
          {t("general.approve")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <JobRecruitmentList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        currentYear={currentYear}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Reject Cancellation Request"}
        onSaved={submitRejectReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
}

JobRecruitmentCancellationApprovalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onApproved: PropTypes.func.isRequired,
  onRejected: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  currentYear: PropTypes.number.isRequired,
};
