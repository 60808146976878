import React, { useState } from "react";
import { EditButton } from "../../common/TableCols";
import { Table, Tag, Spin, Card, Row, Col, Switch, Form } from "antd";
import PropTypes from "prop-types";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import { SelectionInput } from "../../common/SelectionInput";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const APRConfgForm = ({
  isLoading,
  aprConfgs,
  onEdit,
  allOus,
  allDepartments,
  filterRecord,
  form,
  ouKey,
  deptKey,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const APRConfgEditButton = (value) => (
    <EditButton fncEdit={() => onEdit(value)} />
  );

  const [searchText, setSearchText] = useState("");
  const RenderTags = (value) => {
    let color = "";
    if (value === "Incomplete") {
      color = "volcano";
    } else {
      color = "green";
    }
    return (
      <Tag color={color} key={value}>
        {t("aprConfigForm." + value).toUpperCase()}
      </Tag>
    );
  };

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
      width: 150,
      ...FilterGridDropdown(
        "EmpyID",
        searchText,
        handleSearch,
        handleReset,
        "Employee ID",
        t
      ),
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmpyName",
      key: "EmpyName",
      width: 500,
      sorter: (a, b) => a.EmpyName.localeCompare(b.EmpyName),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "EmpyName",
        searchText,
        handleSearch,
        handleReset,
        "Employee Name",
        t
      ),
    },
    {
      title: t("aprConfigForm.approver_setup_status"),
      dataIndex: "IsSetupComplete",
      key: "IsSetupComplete",
      width: 200,
      render: RenderTags,
    },
    {
      title: t("general.action"),
      dataIndex: "UserKey",
      key: "EditAPRConfg",
      align: "center",
      width: 60,
      render: APRConfgEditButton,
    },
  ];

  function onChangeOU(value) {
    let dept = form.getFieldValue("Dept");
    let active = form.getFieldValue("Active");
    filterRecord(value.value, dept.value, active);
  }

  function onChangeDept(value) {
    let ou = form.getFieldValue("OU");
    let active = form.getFieldValue("Active");
    filterRecord(ou.value, value.value, active);
  }

  function onChangeActive(value) {
    let dept = form.getFieldValue("Dept");
    let ou = form.getFieldValue("OU");
    filterRecord(ou.value, dept.value, value);
  }

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Form layout="vertical" form={form}>
      <Card
        title={
          <span className="form-title">
            {t("aprConfigForm.approval_settings")}
          </span>
        }
        extra={
          <Form.Item
            style={{ textAlign: "right", margin: 0 }}
            name="Active"
            initialValue={true}
            valuePropName="checked"
          >
            <Switch
              checkedChildren={t("general.active")}
              unCheckedChildren={t("general.inactive")}
              onChange={onChangeActive}
            />
          </Form.Item>
        }
      >
        <Spin spinning={isLoading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t("general.operating_unit")}
                name="OU"
                initialValue={{ value: ouKey }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allOus}
                  valueProp={"OUKey"}
                  textProp={"OUCodeOUDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.operating_unit_placeholder")}
                  onChange={onChangeOU}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t("general.department")}
                name="Dept"
                initialValue={{
                  value: deptKey,
                }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allDepartments}
                  valueProp={"Key"}
                  textProp={"CodeDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.department_placeholder")}
                  onChange={onChangeDept}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Table
            dataSource={aprConfgs}
            columns={columns}
            size="middle"
            rowKey={(record) => record.UserKey}
            pagination={{
              defaultCurrent: currentPage,
              onChange: tablePaginationChanged,
            }}
            scroll={{ y: "calc(100vh - 270px)" }}
            onRow={(r) => ({ onClick: () => onRowSelected(r) })}
          />
        </Spin>
        <EntryFormFooter
          footerData={rowSelected}
          showFooter={
            rowSelected.UpdatedBy && rowSelected.UpdatedBy !== -1 ? true : false
          }
          isApproved={false}
          isVoid={false}
          isPending={false}
          t={t}
        ></EntryFormFooter>
      </Card>
    </Form>
  );
};

APRConfgForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  aprConfgs: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  allOus: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  filterRecord: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  ouKey: PropTypes.number.isRequired,
  deptKey: PropTypes.number.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default APRConfgForm;
