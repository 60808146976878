export default function ClaimSubmissionModel({
  claimTransKey = -1,
  claimTypeKey = null,
  claimTypeCode = "",
  claimTypeDesc = "",
  uomKey = -1,
  uomCode = "",
  uomDesc = "",
  transDate = new Date(),
  claimYearMonth = "",
  quantity = 1,
  unitCost = 0,
  totalAmount = 0,
  currKey = -1,
  currCode = "",
  currDesc = "",
  exRate = 1,
  funcTotalAmount = 0,
  remarks = "",
  projKey = -1,
  projCode = "",
  projDesc = "",
  isBillable = false,
  status = 0,
  statusDesc = "",
  docNum = "Auto No.",
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  attaches = []
}) {
  return Object.freeze({
    ClaimTransKey: claimTransKey,
    ClaimTypeKey: claimTypeKey,
    claimTypeCode: claimTypeCode,
    ClaimTypeDesc: claimTypeDesc,
    UOMKey: uomKey,
    UOMCode: uomCode,
    UOMDesc: uomDesc,
    TransDate: transDate,
    ClaimYearMonth: claimYearMonth,
    Quantity: quantity,
    UnitCost: unitCost,
    TotalAmount: totalAmount,
    CurrKey: currKey,
    CurrCode: currCode,
    CurrDesc: currDesc,
    ExRate: exRate,
    FuncTotalAmount: funcTotalAmount,
    Remarks: remarks,
    ProjKey: projKey,
    ProjCode: projCode,
    ProjDesc: projDesc,
    IsBillable: isBillable,
    Status: status,
    StatusDesc: statusDesc,
    DocNum: docNum,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    Attaches: attaches
  });
}
