import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AnnouncementDisplayForm from "../forms/AnnouncementDisplayForm";
import PropTypes from "prop-types";
import AnnouncementModel from "../model/Announcement/AnnouncementModel";
import { getAnnouncement } from "../../api/announcementApi";
import { useParams } from 'react-router-dom';

const newAnnouncement = AnnouncementModel({});

export default function AnnouncementDisplayPage({ history }) {
  const [announcement, setAnnouncement] = useState(newAnnouncement);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {AnnouncementKey} = useParams();

  useEffect(() => {
    let promises = [];
    let editKey = AnnouncementKey;
    if (editKey > 0) {
      promises.push(getAnnouncement(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setAnnouncement(values[0] ? values[0] : newAnnouncement);
        let tempAttaches = values[0] ? values[0].Attaches : [];
        tempAttaches.forEach((attachment) => {
          attachment.uid = attachment.AttchKey;
          attachment.name = attachment.FileName;
          attachment.status = "done";
          attachment.url = attachment.UploadedUrl;
        });
        setFileList(tempAttaches);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [AnnouncementKey]);

  function cancelInputAnnouncement() {
    history("/home");
  }

  return (
    <AnnouncementDisplayForm
      isLoading={isLoading}
      announcement={announcement}
      onCancelled={cancelInputAnnouncement}
      onDataChanged={setAnnouncement}
      fileList={fileList}
    />
  );
}

AnnouncementDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};
