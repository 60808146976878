import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "docmanager/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllDocuments() {
  return axios({
    method: "GET",
    url: base + "GetAllDocManagers",
    headers: authHeader(),
    params: {
      environment: env
    }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addDocument(document) {
  return axios({
    method: "POST",
    url: base + "AddDocManager",
    headers: authHeader(),
    data: document,
    params: {
      environment: env
    }
  })
    .then()
    .catch(handleError);
}

export function updateDocument(document) {
  return axios({
    method: "PUT",
    url: base + "UpdateDocManager",
    headers: authHeader(),
    data: document,
  })
    .then()
    .catch(handleError);
}

export function deleteDocument(document) {
  return axios({
    method: "DELETE",
    url: base + "DeleteDocManager",
    headers: authHeader(),
    data: document,
    params: {
      environment: env
    }
  })
    .then()
    .catch(handleError);
}
