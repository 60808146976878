import dayjs from "dayjs";
import React,{ useEffect, useState } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import Axios from "axios";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { getTravel } from "../../../api/travelAuthorizationApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getESSEmployee } from "../../../api/employeeApi";
import TravelAdminForm from "../../forms/AdminPortal/TravelAdminForm";
import { useLocation } from "react-router-dom";


export default function TravelAdminPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [allTravelDS, setAllTravelDS] = useState([]);
  const [filteredTravelDS, setFilteredTravelDS] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();   //remain filter values when close input form
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.filterYear : dayjs().year()
  );
  const [currentMonth, setCurrentMonth] = useState(
    location.state ? location.state.filterMonth : dayjs().month() + 1
  );
  const [allOUs, setAllOUs] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOUKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  const [userKey, setUserKey] = useState(
    location.state ? location.state.filterUserKey : 0
  );
  const [pagination, setPagination] = useState(
    {
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });

  useEffect(getTravels, []);

  function getTravels() {
    let promises = [
      getTravelDataSource(currentYear, currentMonth),
      getOU(),
      getDepartment(),
      getESSEmployee(),
    ];

    Promise.all(promises).then((values) => {
      resetPagination();

      setAllOUs(values[1] ? values[1].filter((x) => x.Active) : []);
      setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
      setAllUsers(values[3] ? values[3] : []);
      setInitialUsers(values[3] ? values[3] : []);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      setAllTravelDS([]);
      setFilteredTravelDS([]);
      setAllOUs([]);
      setAllDepartments([]);
      setAllUsers([]);
      setInitialUsers([]);
      showErrorMessage(
        t('general.fail_to_load_record') + 
          error.response.data.error_description
      );
    });
  }

  function getTravelDataSource(currentYear, currentMonth) {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    getTravel(source, currentYear, currentMonth, true)
      .then((results) => {
        setAllTravelDS(results);
        setFilteredTravelDS(
          results.filter(
            (x) => 
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey) &&
              (userKey === 0 || x.UserKey === userKey)
            )
        );

        setIsLoading(false);
      }).catch((error) => {
        setIsLoading(false);
        setAllTravelDS([]);
        setFilteredTravelDS([]);
        showErrorMessage(
          t('general.fail_to_load_record') + 
            error.response.data.error_description
        );
      });
  }

  function onViewTravelTrans(TravelTransKey) {
    history("/home/travelauth/travelTrans/" + TravelTransKey,
      { state: { 
        fromAdmin: true,
        filterOUKey: ouKey,
        filterDeptKey: deptKey,
        filterUserKey: userKey,
        filterYear: currentYear,
        filterMonth: currentMonth,
      }}
    );
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function filterRecord(ouKey, deptKey, userKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setUserKey(userKey);
    setFilteredTravelDS(
      allTravelDS.filter(
        (x) => 
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (userKey === 0 || x.UserKey === userKey)
        )
    );
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  return (
    <TravelAdminForm 
      isLoading={isLoading}
      travelDS={filteredTravelDS}
      getTravelDataSource={getTravelDataSource}
      form={form}
      onViewTravel={onViewTravelTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      setCurrentMonth={setCurrentMonth}
      allOUs={allOUs}
      allDepartments={allDepartments}
      allUsers={allUsers}
      initialUsers={initialUsers}
      setAllUsers={setAllUsers}
      ouKey={ouKey}
      deptKey={deptKey}
      userKey={userKey}
      currentYear={currentYear}
      currentMonth={currentMonth}
      filterRecord={filterRecord}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

TravelAdminPage.propTypes = {
  history: PropTypes.func.isRequired,
};  