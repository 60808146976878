import React, { useState, useEffect } from "react";
import { getESSUser } from "../../../api/roleAssignmentApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import LeaveAdjustmentForm from "../../forms/Admin/LeaveAdjustmentForm";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function LeaveAdjustmentPage({ history }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [leaveAdjustments, setLeaveAdjustments] = useState([]);
  const [filteredLeaveAdjustments, setFilteredLeaveAdjustments] = useState([]);
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const location = useLocation();
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOuKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(getItems, []);

  function getItems() {
    let promises = [getESSUser(), getOU(), getDepartment()];

    Promise.all(promises)
      .then((values) => {
        let tempData = values[0]
          ? values[0].filter((x) => x.IsLMStaff === false)
          : [];
        setLeaveAdjustments(tempData);
        setFilteredLeaveAdjustments(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey)
          )
        );
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function editItem(userKey) {
    history("/home/leaveadjustments/" + userKey, {
      state: {
        filterOuKey: ouKey,
        filterDeptKey: deptKey,
        currentPage: currentPage,
      },
    });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function filterRecord(ouKey, deptKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setFilteredLeaveAdjustments(
      leaveAdjustments.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey)
      )
    );
  }

  return (
    <LeaveAdjustmentForm
      isLoading={isLoading}
      leaveAdjustments={filteredLeaveAdjustments}
      onEdit={editItem}
      allOus={allOus}
      allDepartments={allDepartments}
      filterRecord={filterRecord}
      ouKey={ouKey}
      deptKey={deptKey}
      form={form}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

LeaveAdjustmentPage.propTypes = {
  history: PropTypes.func.isRequired,
};
