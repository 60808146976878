import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "Widget/";

export function getEmpyAttendance(leaveDate) {
  return axios({
    method: "GET",
    url: base + "GetEmpyAttendance",
    headers: authHeader(),
    params: { leaveDate: leaveDate },
  })
    .then(handleResponse)
    .catch(handleError);
}
