export default function PublishControl({
  publishControlKey = -1,
  type = "",
  ouKey = -1,
  yr = 0,
  mth = 0,
  isPublish = false,
  publishBy = -1,
  publishByCode = "",
  publishedDate = new Date(),
  revertBy = -1,
  revertByCode = "",
  revertedDate = new Date(),
  rowVersion = null
}) {
  return Object.freeze({
    PublishControlKey: publishControlKey,
    Type: type,
    OUKey: ouKey,
    Yr: yr,
    Mth: mth,
    IsPublish: isPublish,
    PublishBy: publishBy,
    PublishByCode: publishByCode,
    PublishedDate: publishedDate,
    RevertBy: revertBy,
    RevertByCode: revertByCode,
    RevertedDate: revertedDate,
    RowVersion: rowVersion
  });
}
