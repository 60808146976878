import React from "react";
import { Input, Row, Col, Button, Card, Spin, Checkbox, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ManageRestDayProfileForm = ({
  isLoading,
  restDayProfile,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const handleSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitted(values);
      })
      .catch(() => {});
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("restDayProfileForm.rest_day_profile_setup")}
                </span>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col span={12}>
                  <Form.Item
                    label={t("general.employee_id")}
                    name="EmpyID"
                    initialValue={restDayProfile.EmpyID}
                  >
                    <Input placeholder="Employee ID" disabled={true} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("general.employee_name")}
                    name="EmpyName"
                    initialValue={restDayProfile.EmpyName}
                  >
                    <Input placeholder="Employee Name" disabled={true} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t("restDayProfileForm.rest_day_maintenance")}
                    style={{
                      marginBottom: 0,
                    }}
                    name="Sun"
                    valuePropName="checked"
                    initialValue={restDayProfile.Sun}
                  >
                    <Checkbox>{t("general.sun")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Mon"
                    valuePropName="checked"
                    initialValue={restDayProfile.Mon}
                  >
                    <Checkbox>{t("general.mon")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Tue"
                    valuePropName="checked"
                    initialValue={restDayProfile.Tue}
                  >
                    <Checkbox>{t("general.tue")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Wed"
                    valuePropName="checked"
                    initialValue={restDayProfile.Wed}
                  >
                    <Checkbox>{t("general.wed")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Thu"
                    valuePropName="checked"
                    initialValue={restDayProfile.Thu}
                  >
                    <Checkbox>{t("general.thu")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Fri"
                    valuePropName="checked"
                    initialValue={restDayProfile.Fri}
                  >
                    <Checkbox>{t("general.fri")}</Checkbox>
                  </Form.Item>
                  <Form.Item
                    style={{
                      marginBottom: 0,
                    }}
                    name="Sat"
                    valuePropName="checked"
                    initialValue={restDayProfile.Sat}
                  >
                    <Checkbox>{t("general.sat")}</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  {t("restDayProfileForm.rest_day_maintenance_message")}
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageRestDayProfileForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  restDayProfile: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageRestDayProfileForm;
