import React, { useState } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Checkbox,
  DatePicker,
  Divider,
  Tabs,
  Table,
  Alert,
  Modal,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { SelectionInput } from "../../common/SelectionInput";
import { DeleteButton } from "../../common/TableCols";
import { RowState } from "../../model/common/RowState";
import AppraiseeModel from "../../model/Appraisal/AppraiseeModel";
import FilterModel from "../../model/Appraisal/FilterModel";
import {
  Status,
  isStatusPublished,
  isStatusOpen,
} from "../../model/common/Status";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const ManageAppraisalForm = ({
  isLoading,
  appraisal,
  appraisalTemplates,
  ous,
  depts,
  grades,
  posts,
  empys,
  ratings,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { MonthPicker } = DatePicker;
  const { confirm } = Modal;
  const isEdit = appraisal.APKey > 0;
  const formats = { cellSpacing: 20 };
  const disableField = appraisal.RcdType === RecordType.USER ? false : true;
  const isPublished = isStatusPublished(appraisal.Status);
  const isOpen = isStatusOpen(appraisal.Status);
  const [activeTab, setActiveTab] = useState("1");
  const [showErorrMsg, setshowErorrMsg] = useState(false);

  const defaultAppraisalYearMonth = dayjs().startOf("month");
  Form.useWatch("IsSelfAppraisal", form);

  const handleSubmit = async () => {
    handleSubmitFunc(0);
  };

  const AppraisalActionButtons = (value) => (
    <Row>
      <Col
        span={24}
        style={{
          display: isOpen ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteAppraisee(value)} />
      </Col>
    </Row>
  );

  function handleSubmitFunc(statusAction) {
    form.validateFields().then((values) => {
      if (
        appraisal.Appraisee.filter((x) => x.RowState !== RowState.DELETED)
          .length === 0
      ) {
        setshowErorrMsg(true);
      } else {
        let ouData = values.OUKey.map((x) => {
          let check = appraisal.Filter.filter(
            (item) => item.Type === 0 && item.TypeKey === x.key
          );
          if (check.length > 0) {
            return check[0];
          } else {
            return FilterModel({
              Type: 0,
              TypeKey: x.key,
            });
          }
        });
        let deptData = values.DeptKey.map((x) => {
          let check = appraisal.Filter.filter(
            (item) => item.Type === 1 && item.TypeKey === x.key
          );
          if (check.length > 0) {
            return check[0];
          } else {
            return FilterModel({
              Type: 1,
              TypeKey: x.key,
            });
          }
        });
        let gradeData = values.EmpyGradeKey.map((x) => {
          let check = appraisal.Filter.filter(
            (item) => item.Type === 2 && item.TypeKey === x.key
          );
          if (check.length > 0) {
            return check[0];
          } else {
            return FilterModel({
              Type: 2,
              TypeKey: x.key,
            });
          }
        });
        let postData = values.PostKey.map((x) => {
          let check = appraisal.Filter.filter(
            (item) => item.Type === 3 && item.TypeKey === x.key
          );
          if (check.length > 0) {
            return check[0];
          } else {
            return FilterModel({
              Type: 3,
              TypeKey: x.key,
            });
          }
        });
        let allFilterCriteria = ouData
          .concat(deptData)
          .concat(gradeData)
          .concat(postData);
        let allExistingFilter = appraisal.Filter.map((y) => {
          let check = allFilterCriteria.filter(
            (item) => item.Type === y.Type && item.TypeKey === y.TypeKey
          );
          if (check.length === 0) {
            return { ...y, RowState: RowState.DELETED };
          } else {
            return y;
          }
        });
        let finalData = [...allExistingFilter, ...allFilterCriteria];
        let filterData = {
          ...values,
          APDate: dayjs(values.APDate).format("YYYY/MM/DD"),
          Filter: finalData,
          Status: statusAction,
        };
        onSubmitted(filterData);
      }
    });
  }

  function deleteAppraisee(deletedKey) {
    //change state to DELETED for removal in db
    let newData = appraisal.Appraisee.filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.UserKey === deletedKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.UserKey === deletedKey && x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    });
    onDataChanged({ ...appraisal, Appraisee: newData });
  }

  function getFilterAppraiseeData() {
    return appraisal.Appraisee.filter((x) => x.RowState !== RowState.DELETED);
  }

  function filterAppraisee() {
    let ousKey = form.getFieldValue("OUKey").map((x) => x.value);
    let deptsKey = form.getFieldValue("DeptKey").map((x) => x.value);
    let gradesKey = form.getFieldValue("EmpyGradeKey").map((x) => x.value);
    let postsKey = form.getFieldValue("PostKey").map((x) => x.value);

    let empysData = empys;

    if (!ousKey.includes(0)) {
      empysData = empysData.filter((y) => ousKey.includes(y.OUKey));
    }
    if (!deptsKey.includes(0)) {
      empysData = empysData.filter((y) => deptsKey.includes(y.DeptKey));
    }
    if (!gradesKey.includes(0)) {
      empysData = empysData.filter((y) => gradesKey.includes(y.GradeKey));
    }
    if (!postsKey.includes(0)) {
      empysData = empysData.filter((y) => postsKey.includes(y.PostKey));
    }

    let modelData = empysData.map((x) => {
      return AppraiseeModel({
        UserKey: x.UserKey,
        EmpyKey: x.EmpyKey,
        EmpyID: x.EmpyID,
        EmpyName: x.EmpyName,
        RowState: RowState.ADDED,
      });
    });

    let newData = appraisal.Appraisee.filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.RowState === RowState.ADDED)
    ).map((x) => {
      let filterUser = modelData.filter((z) => z.UserKey === x.UserKey);

      if (filterUser.length > 0) {
        return x;
      } else {
        return { ...x, RowState: RowState.DELETED };
      }
    });

    for (var i = 0; i < modelData.length; i++) {
      let userKey = modelData[i].UserKey;
      let filterUser = newData.filter(
        (z) => z.UserKey === userKey && z.RowState !== RowState.DELETED
      );
      if (filterUser.length > 0) {
        modelData.splice(i, 1);
        i--;
      }
    }

    onDataChanged({ ...appraisal, Appraisee: newData.concat(modelData) });
    setActiveTab("2");
    setshowErorrMsg(false);
  }

  function changeTab(activeKey) {
    setActiveTab(activeKey);
  }

  function filterCriteria(type) {
    let tempArray = [];
    if (type === 0) {
      appraisal.Filter.forEach((item) => {
        if (item.Type === 0) {
          if (item.TypeKey === 0) {
            tempArray.push({ key: 0 });
          } else {
            tempArray.push({ key: item.TypeKey });
          }
        }
      });
    } else if (type === 1) {
      appraisal.Filter.forEach((item) => {
        if (item.Type === 1) {
          if (item.TypeKey === 0) {
            tempArray.push({ key: 0 });
          } else {
            tempArray.push({ key: item.TypeKey });
          }
        }
      });
    } else if (type === 2) {
      appraisal.Filter.forEach((item) => {
        if (item.Type === 2) {
          if (item.TypeKey === 0) {
            tempArray.push({ key: 0 });
          } else {
            tempArray.push({ key: item.TypeKey });
          }
        }
      });
    } else if (type === 3) {
      appraisal.Filter.forEach((item) => {
        if (item.Type === 3) {
          if (item.TypeKey === 0) {
            tempArray.push({ key: 0 });
          } else {
            tempArray.push({ key: item.TypeKey });
          }
        }
      });
    }

    return tempArray;
  }

  function handleClose() {
    setshowErorrMsg(false);
  }

  function showPublishConfirm() {
    form.validateFields().then(() => {
      if (
        appraisal.Appraisee.filter((x) => x.RowState !== RowState.DELETED)
          .length === 0
      ) {
        setshowErorrMsg(true);
        return;
      }

      confirm({
        title: t("general.publish_confirmation"),
        content: t("appraisalForm.publish_appraisal_confirmation_message"),
        okText: t("general.yes"),
        cancelText: t("general.no"),
        onOk() {
          handleSubmitFunc(66);
        },
        onCancel() {},
      });
    });
  }

  const appraiseeColumns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
      width: 150,
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmpyName",
      key: "EmpyName",
      sorter: (a, b) => a.EmpyName.localeCompare(b.EmpyName),
      sortDirections: ["ascend", "descend"],
      width: 600,
    },
    {
      title: t("general.action"),
      dataIndex: "UserKey",
      key: "EditRoleAssignment",
      align: "center",
      width: 60,
      render: AppraisalActionButtons,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("appraisalForm.appraisal_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={appraisal.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                  <Form.Item
                    label={<span>{t("appraisalForm.appraisal_date")}</span>}
                    name="APDate"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "appraisalForm.appraisal_date_required_validation"
                        ),
                      },
                    ]}
                    initialValue={
                      isEdit && appraisal.APDate
                        ? dayjs(new Date(appraisal.APDate))
                        : defaultAppraisalYearMonth
                    }
                  >
                    <MonthPicker
                      allowClear={false}
                      placeholder={t(
                        "appraisalForm.appraisal_date_placeholder"
                      )}
                      format="MM/YYYY"
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={18}>
                  <Form.Item
                    label={t("appraisalForm.appraisal_code")}
                    name="APCode"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t(
                          "appraisalForm.appraisal_code_required_validation"
                        ),
                      },
                      {
                        max: 100,
                        message: t(
                          "appraisalForm.appraisal_code_max_length_validation"
                        ),
                      },
                    ]}
                    initialValue={appraisal.APCode}
                  >
                    <Input
                      placeholder={t(
                        "appraisalForm.appraisal_code_placeholder"
                      )}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.description")}
                    name="APDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.description_max_length_validation"),
                      },
                    ]}
                    initialValue={appraisal.APDesc}
                  >
                    <Input
                      placeholder={t("general.description_placeholder")}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>

                <Row
                  gutter={formats.cellSpacing}
                  style={{
                    marginTop: 16,
                    marginLeft: 0,
                    marginRight: 0,
                    display: "contents",
                  }}
                >
                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t("appraisalForm.appraisal_template")}
                      name="AppraisalTemplate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "appraisalForm.appraisal_template_required_validation"
                          ),
                        },
                      ]}
                      initialValue={
                        appraisal.ATKey > 0
                          ? {
                              key: appraisal.ATKey,
                              label:
                                appraisal.ATCode + " - " + appraisal.ATDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={appraisalTemplates}
                        valueProp={"ATKey"}
                        textProp={"ATCodeATDesc"}
                        disabledProp={"Active"}
                        disabled={!isOpen}
                        placeholder={t(
                          "appraisalForm.appraisal_template_placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} lg={12}>
                    <Form.Item
                      label={t("appraisalForm.appraisal_rating_template")}
                      name="AppraisalRatingTemplate"
                      initialValue={
                        appraisal.ARKey > 0
                          ? {
                              key: appraisal.ARKey,
                              label:
                                appraisal.ARCode + " - " + appraisal.ARDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={ratings}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        disabled={!isOpen}
                        placeholder={t(
                          "appraisalForm.appraisal_rating_template_placeholder"
                        )}
                        allowClear={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col xs={24} lg={4}>
                  <Form.Item
                    label={t("appraisalForm.self_appraisal")}
                    name="IsSelfAppraisal"
                    valuePropName="checked"
                    initialValue={appraisal.IsSelfAppraisal}
                  >
                    <Checkbox disabled={disableField || !isOpen}>
                      {t("general.enable")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  lg={8}
                  style={{
                    display:
                      form.getFieldValue("IsSelfAppraisal") === true
                        ? "block"
                        : "none",
                  }}
                >
                  <Form.Item
                    label={t("appraisalForm.self_appraisal_period")}
                    name="SelfAppraisalDate"
                    dependencies={["IsSelfAppraisal"]}
                    rules={[
                      {
                        required:
                          form.getFieldValue("IsSelfAppraisal") === true
                            ? true
                            : false,
                        message: t(
                          "appraisalForm.self_appraisal_period_required_validation"
                        ),
                      },
                    ]}
                    initialValue={appraisal.SelfAppraisalDate}
                  >
                    <RangePicker
                      format="DD/MM/YYYY"
                      showToday={false}
                      placeholder={[
                        t("general.start_date"),
                        t("general.end_date"),
                      ]}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={8}>
                  <Form.Item
                    label={t("appraisalForm.review_period")}
                    name="ReviewDate"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "appraisalForm.review_period_required_validation"
                        ),
                      },
                    ]}
                    initialValue={appraisal.ReviewDate}
                  >
                    <RangePicker
                      format="DD/MM/YYYY"
                      showToday={false}
                      placeholder={[
                        t("general.start_date"),
                        t("general.end_date"),
                      ]}
                      disabled={disableField || !isOpen}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} lg={4}>
                  <Form.Item
                    label={t("general.status")}
                    name="StatusDesc"
                    initialValue={Status[appraisal.Status]}
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Divider />

              <h3 className="form-subtitle">
                {t("appraisalForm.applicable_to")}
              </h3>
              <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                onChange={changeTab}
                items={[
                  {
                    key: "1",
                    label: t("appraisalForm.filter"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.operating_unit")}
                              name="OUKey"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "general.operating_unit_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={
                                appraisal.Filter.length > 0
                                  ? filterCriteria(0)
                                  : [{ key: 0, value: 0 }]
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={ous}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "general.operating_unit_placeholder_full"
                                )}
                                isIncludeAllOption={true}
                                isMultiple={true}
                                disabled={!isOpen}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.department")}
                              name="DeptKey"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "general.department_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={
                                appraisal.Filter.length > 0
                                  ? filterCriteria(1)
                                  : [{ key: 0, value: 0 }]
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={depts}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "general.department_placeholder"
                                )}
                                isIncludeAllOption={true}
                                isMultiple={true}
                                disabled={!isOpen}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.grade")}
                              name="EmpyGradeKey"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "general.grade_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={
                                appraisal.Filter.length > 0
                                  ? filterCriteria(2)
                                  : [{ key: 0, value: 0 }]
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={grades}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t("general.grade_placeholder")}
                                isIncludeAllOption={true}
                                isMultiple={true}
                                disabled={!isOpen}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("general.position")}
                              name="PostKey"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "general.position_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={
                                appraisal.Filter.length > 0
                                  ? filterCriteria(3)
                                  : [{ key: 0, value: 0 }]
                              }
                            >
                              <SelectionInput
                                ref={React.createRef()}
                                labelInValue={true}
                                data={posts}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t("general.position_placeholder")}
                                isIncludeAllOption={true}
                                isMultiple={true}
                                disabled={!isOpen}
                              />
                            </Form.Item>
                          </Col>

                          <Col xs={24} lg={24} style={{ textAlign: "right" }}>
                            <Button
                              size="default"
                              type="primary"
                              onClick={filterAppraisee}
                              disabled={!isOpen}
                            >
                              {t("appraisalForm.set")}
                            </Button>
                          </Col>
                        </Row>
                        {showErorrMsg ? (
                          <Alert
                            message={t(
                              "appraisalForm.appraisee_cannot_be_empty"
                            )}
                            type="error"
                            afterClose={handleClose}
                            closable
                            showIcon
                            closeText={t("general.close_now")}
                          />
                        ) : null}
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: t("appraisalForm.appraisee"),
                    children: (
                      <Table
                        dataSource={getFilterAppraiseeData()}
                        columns={appraiseeColumns}
                        size="middle"
                        rowKey={(record) => record.UserKey}
                        bordered
                        pagination={{ pageSize: 50 }}
                        scroll={{ y: 240 }}
                        disabled={!isOpen}
                      />
                    ),
                  },
                ]}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="default"
                  htmlType="submit"
                  style={{
                    marginRight: "5px",
                    display: !isPublished ? "" : "none",
                  }}
                >
                  {t("general.save")}
                </Button>

                <Button
                  size="large"
                  type="primary"
                  onClick={showPublishConfirm}
                  style={{
                    display: !isPublished ? "" : "none",
                  }}
                >
                  {t("general.publish")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={appraisal}
              showFooter={appraisal.APKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageAppraisalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisal: PropTypes.object.isRequired,
  appraisalTemplates: PropTypes.array.isRequired,
  ous: PropTypes.array.isRequired,
  depts: PropTypes.array.isRequired,
  grades: PropTypes.array.isRequired,
  posts: PropTypes.array.isRequired,
  empys: PropTypes.array.isRequired,
  ratings: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageAppraisalForm;
