import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageClaimTypeForm } from "../../forms/Master/ManageClaimTypeForm";
import PropTypes from "prop-types";
import ClaimTypeModel from "../../model/Claim/ClaimTypeModel";
import { LimitLevel } from "../../model/Claim/ClaimEnum";
import { RowState } from "../../model/common/RowState";
import {
  getClaimType,
  addClaimType,
  updateClaimType,
} from "../../../api/claimTypeApi";
import {
  getPayrollReimbursementCode,
  getPayrollDeductionCode,
} from "../../../api/payCodeApi";
import { getCompany } from "../../../api/companyApi";
import { getDepartment } from "../../../api/deptApi";
import { getEmpyGrade } from "../../../api/empyGradeApi";
import { getEmployee } from "../../../api/employeeApi";
import { getUOM } from "../../../api/uomApi";
import { getUserCompCurrency } from "../../../api/currApi";
import { getClaimConfg } from "../../../api/claimConfgApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newClaimType = ClaimTypeModel({});

export default function ManageClaimTypePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [reimbursementTypes, setReimbursementTypes] = useState([]);
  const [deductionTypes, setDeductionTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [empyGrades, setEmpyGrades] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [uoms, setUOMs] = useState([]);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [claimType, setClaimType] = useState(newClaimType);
  const [claimConfg, setClaimConfg] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { ClaimTypeKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [
      getPayrollReimbursementCode(),
      getPayrollDeductionCode(),
      getCompany(),
      getDepartment(),
      getEmpyGrade(),
      getEmployee(),
      getUOM(),
      getUserCompCurrency(),
      getClaimConfg(),
    ];
    let editKey = ClaimTypeKey;
    if (editKey > 0) {
      promises.push(getClaimType(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setReimbursementTypes(values[0] ? values[0] : []);
        setDeductionTypes(values[1] ? values[1] : []);
        setCompanies(values[2] ? values[2] : []);
        setDepartments(values[3] ? values[3] : []);
        setEmpyGrades(values[4] ? values[4] : []);
        setEmployees(values[5] ? values[5] : []);
        setUOMs(values[6] ? values[6] : []);
        setUserCompCurr(values[7] ? values[7] : {});
        setClaimConfg(values[8] ? values[8] : {});

        if (values[9]) {
          if (values[9].UpdatedDate) {
            values[9].UpdatedOn = values[9].UpdatedDate;
          }
          if (values[9].CreatedDate) {
            values[9].CreatedOn = values[9].CreatedDate;
          }
        }

        setClaimType(values[9] ? values[9] : newClaimType);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [ClaimTypeKey, t]);

  function saveClaimType(values) {
    let claimTypeSubmit = {
      ...claimType,
      Active: values.Active,
      IsMedicalClaim: values.IsMedicalClaim,
      IsBillable: values.IsBillable,
      IsRequiredAuth: values.IsRequiredAuth,
      IsClaimable: values.IsClaimable,
      IsDentalClaim: values.IsDentalClaim,
      ClaimTypeCode: values.ClaimTypeCode.trim(),
      ClaimTypeDesc: values.ClaimTypeDesc.trim(),
      DefaultCost: values.DefaultCost ? values.DefaultCost : 0,
      UOMKey: values.UOM ? values.UOM.key : -1,
      ReimbursementKey: values.ReimbursementType
        ? values.ReimbursementType.key
        : -1,
      DeductionKey: values.DeductionType ? values.DeductionType.key : -1,
      checkedOUList: values.checkedOUList,
    };

    let upsertClaimType =
      claimTypeSubmit.ClaimTypeKey !== -1 ? updateClaimType : addClaimType;

    setIsLoading(true);
    upsertClaimType(claimTypeSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/claimtypes", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        let message =
          claimTypeSubmit.ClaimTypeKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          claimTypeSubmit.ClaimTypeKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputClaimType() {
    history("/home/claimtypes", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function getLimitInputSelectionData(limitLevel, selectedRefKey) {
    let selectionData, selectedLevel;

    switch (limitLevel) {
      case LimitLevel.COMPANY.Level:
        selectionData = [...companies];
        selectedLevel = LimitLevel.COMPANY.Level;
        break;
      case LimitLevel.DEPARTMENT.Level:
        selectionData = [...departments];
        selectedLevel = LimitLevel.DEPARTMENT.Level;
        break;
      case LimitLevel.GRADE.Level:
        selectionData = [...empyGrades];
        selectedLevel = LimitLevel.GRADE.Level;
        break;
      case LimitLevel.EMPLOYEE.Level:
        selectionData = [...employees];
        selectedLevel = LimitLevel.EMPLOYEE.Level;
        break;
      default:
        selectionData = [];
        selectedLevel = -1;
        break;
    }

    //add all option
    selectionData.unshift({
      Key: 0,
      Code: "All",
      Desc: "All",
      CodeDesc: "All",
      Active: true,
    });

    //filter out selected data
    return selectionData.filter(
      (x) =>
        !claimType.ClaimTypeLimits.find(
          (y) =>
            y.LimitLevel === selectedLevel &&
            y.RefKey === x.Key &&
            y.RefKey !== selectedRefKey &&
            y.RowState !== RowState.DELETED
        )
    );
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ManageClaimTypeForm
      isLoading={isLoading}
      claimType={claimType}
      uoms={uoms}
      reimbursementTypes={reimbursementTypes}
      deductionTypes={deductionTypes}
      getLimitInputSelectionData={getLimitInputSelectionData}
      onSubmitted={saveClaimType}
      onCancelled={cancelInputClaimType}
      onDataChanged={setClaimType}
      companies={companies}
      userCompCurr={userCompCurr}
      claimConfg={claimConfg}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageClaimTypePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
