import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidRoomRequisitions,
  voidRoomRequisitions,
} from "../../../api/roomRequisitionApprovalApi";
import ApprovedRoomRequisitionVoidForm from "../../forms/RoomReq/ApprovedRoomRequisitionVoidForm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function ApprovedRoomRequisitionVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [roomRequisitions, setRoomRequisitions] = useState([]);
  const [selectedRoomRequisitions, setSelectedRoomRequisitions] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getRoomRequisitions, [currentYear]);

  function getRoomRequisitions() {
    setIsLoading(true);
    getApprovedAndVoidRoomRequisitions(currentYear)
      .then((value) => {
        setRoomRequisitions(value ? value : []);
        setSelectedRoomRequisitions([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewRoomRequisition() {
    history("/home/meetingroomrequisition/roomtrans");
  }

  function onVoidRoomRequisitions(selectedRoomRequisitions, reason) {
    setIsLoading(true);

    voidRoomRequisitions(selectedRoomRequisitions, reason)
      .then(() => {
        getRoomRequisitions();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewRoomRequisitions(roomRequisitionKey) {
    history("/home/meetingroomrequisition/roomtrans/" + roomRequisitionKey, {
      state: { fromVoid: true },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ApprovedRoomRequisitionVoidForm
      isLoading={isLoading}
      data={roomRequisitions}
      selectedItems={selectedRoomRequisitions}
      onAdd={onAddNewRoomRequisition}
      onView={viewRoomRequisitions}
      onVoided={onVoidRoomRequisitions}
      onItemSelected={setSelectedRoomRequisitions}
      setCurrentYear={setCurrentYear}
    />
  );
}

ApprovedRoomRequisitionVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
