import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "leaveapproval/";

export function getPendingApprovalLeaves() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalLeaves",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedLeaves(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedLeaves",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidLeaves(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidLeaves",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledLeaves(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledLeaves",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedLeaves(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedLeaves",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveLeaves(leaveTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveLeaves",
    headers: authHeader(),
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectLeaves(leaveTrans, rejectReason, isAllowAmend) {
  return axios({
    method: "POST",
    url: base + "RejectLeaves",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function voidLeaves(LeaveTrans, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidLeaves",
    headers: authHeader(),
    params: { reason: voidReason },
    data: LeaveTrans,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledLeaves(leaveTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledLeaves",
    headers: authHeader(),
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledLeaves(leaveTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledLeaves",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function validateLeaves(leaveTrans) {
  return axios({
    method: "POST",
    url: base + "ValidateLeaves",
    headers: authHeader(),
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function invalidateLeaves(leaveTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "InvalidateLeaves",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: leaveTrans,
  })
    .then()
    .catch(handleError);
}

export function checkPayrollMonthEndClose(leaveTrans) {
  return axios({
    method: "POST",
    url: base + "CheckPayrollMthEndClose",
    headers: authHeader(),
    data: leaveTrans,
  })
    .then(handleResponse)
    .catch(handleError);
}
