import React, { useState, useEffect } from "react";
import * as ctapi from "../../../api/claimTypeApi";
import { toast } from "react-toastify";
import { ClaimTypesForm } from "../../forms/Master/ClaimTypesForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function ClaimTypesPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [claimTypes, setClaimTypes] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getItems, []);

  function getItems() {
    ctapi
      .getAllClaimTypes()
      .then((result) => {
        setIsLoading(false);
        setClaimTypes(result);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/claimtypes/claimtype", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(claimTypeKey) {
    history("/home/claimtypes/claimtype/" + claimTypeKey, {
      state: { currentPage: currentPage },
    });
  }

  function onDelete(claimTypeKey) {
    showDeleteConfirm("Claim Type", claimTypeKey, deleteItem, t);
  }

  function deleteItem(claimTypeKey) {
    setIsLoading(true);
    ctapi
      .deleteClaimType(claimTypeKey)
      .then(() => {
        const newData = claimTypes.filter(
          (item) => item.ClaimTypeKey !== claimTypeKey
        );
        setClaimTypes(newData);
        setIsLoading(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <ClaimTypesForm
      isLoading={isLoading}
      claimTypes={claimTypes}
      onAdd={addNewItem}
      onEdit={editItem}
      onDelete={onDelete}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

ClaimTypesPage.propTypes = {
  history: PropTypes.func.isRequired,
};
