import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const dashboard = API_ROOT + "dashboard/";

export function getPendingDashboardData(source) {
  return axios({
    method: "GET",
    url: dashboard + "GetPendingDashboardData",
    headers: authHeader(),
    cancelToken: source.token,
  })
    .then(handleResponse)
    .catch(handleError);
}
