import React, { useState, useEffect } from "react";
import ManageClaimSubmissionForm from "../../forms/Claim/ManageClaimSubmissionForm";
import ClaimSubmissionModel from "../../model/Claim/ClaimSubmissionModel";
import {
  addClaim,
  getClaimByTransKey,
  updateClaim,
  withdrawClaim,
  deleteClaim,
  requestCancelClaim,
  getClaimLimitAndUsage,
  getLastClosedYrMth,
} from "../../../api/claimSubmissionApi";
import { getTravelToClaimByTransKey } from "../../../api/travelAuthorizationApi";
import { getEntitledClaimTypes } from "../../../api/claimTypeApi";
import { getUOM } from "../../../api/uomApi";
import { getProjectCode } from "../../../api/projectApi";
import {
  getMonthlyExCurrency,
  getUserCompCurrency,
} from "../../../api/currApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { getClaimConfg } from "../../../api/claimConfgApi";
import { getTravelConfg } from "../../../api/travelConfgApi";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newClaimSubmission = ClaimSubmissionModel({});

const ManageClaimSubmissionPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const CLAIM_FORM_MESSAGE = {
    CLAIM_SAVED: t("claimSubmissionForm.claim_saved"),
    CLAIM_SUBMITTED: t("claimSubmissionForm.claim_submitted"),
    CLAIM_WITHDRAWN: t("claimSubmissionForm.claim_withdrawn"),
    CLAIM_DELETED: t("claimSubmissionForm.claim_deleted"),
    CLAIM_CANCEL_REQUEST: t("claimSubmissionForm.claim_cancel_request"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newClaimSubmission);
  const [selectedClaimType, setSelectedClaimType] = useState({});
  const [selectedClaimLimitUsage, setSelectedClaimLimitUsage] = useState({});
  const [defaultClaimYearMonth, setDefaultClaimYearMonth] = useState(
    dayjs().startOf("month")
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [claimConfg, setClaimConfg] = useState({});
  const [travelConfg, setTravelConfg] = useState({});
  const [allClaimTypes, setAllClaimTypes] = useState([]);
  const [allUOMs, setAllUOMs] = useState([]);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [allProjects, setAllProjects] = useState([]);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState({});
  const [isFromTravel, setIsFromTravel] = useState(false);
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/claimsubmissionapproval");
      } else if (location.state.fromVoid) {
        history("/home/claimvoid");
      } else if (location.state.fromCancel) {
        history("/home/claimcancellationapproval");
      } else if (location.state.fromValidation) {
        history("/home/claimvalidation");
      } else if (location.state.fromTravel) {
        history("/home/travelauth/travelTrans/" + location.state.travelHdrKey);
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/claim", {state: location.state});
      } else {
        history("/home/claimsubmissiondisplay");
      }
    } else {
      history("/home/claimsubmissiondisplay");
    }
  }

  function onSaved(claimData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...claimData,
      Remarks: claimData.Remarks.trim(),
      IsBillable:
        claimConfg.IsGroupByProj === true ? claimData.IsBillable : false,
    };

    let upsertClaimSubmission =
      dataSubmit.ClaimTransKey !== -1 ? updateClaim : addClaim;

    return upsertClaimSubmission(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? CLAIM_FORM_MESSAGE.CLAIM_SAVED
          : isStatusSubmitted(result.Status)
          ? CLAIM_FORM_MESSAGE.CLAIM_SUBMITTED
          : CLAIM_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/claimsubmission/claimTrans/", {
            state: { force: true },
          });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history("/home/claimsubmission/claimTrans/" + result.ClaimTransKey, {
            state: { force: true },
          });
          loadPageData(result.ClaimTransKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onWithdraw(claimData) {
    setIsLoading(true);
    return withdrawClaim(editItem.ClaimTransKey, { ...editItem, ...claimData })
      .then(() => {
        toast.success(CLAIM_FORM_MESSAGE.CLAIM_WITHDRAWN);
        loadPageData(editItem.ClaimTransKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onDelete(claimData) {
    setIsLoading(true);
    return deleteClaim(editItem.ClaimTransKey, { ...editItem, ...claimData })
      .then(() => {
        toast.success(CLAIM_FORM_MESSAGE.CLAIM_DELETED);
        history("/home/claimsubmission/claimTrans/", {
          state: { force: true },
        });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRequestCancel(claimData, reason) {
    setIsLoading(true);
    return requestCancelClaim(
      editItem.ClaimTransKey,
      {
        ...editItem,
        ...claimData,
      },
      reason
    )
      .then(() => {
        toast.success(CLAIM_FORM_MESSAGE.CLAIM_CANCEL_REQUEST);
        loadPageData(editItem.ClaimTransKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Claim")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];
    promises.push(
      getClaimConfg(),
      getEntitledClaimTypes(),
      getUOM(),
      getUserCompCurrency(),
      getLastClosedYrMth(),
      getTravelConfg()
    );

    if (location.state) {
      if (location.state.fromTravel && editKey === undefined) {
        setIsFromTravel(true);
        promises.push(getTravelToClaimByTransKey(location.state.travelDetKey));
      } else {
        if (editKey) promises.push(getClaimByTransKey(editKey));
      }
    } else {
      if (editKey) promises.push(getClaimByTransKey(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        let currentDateMoment = dayjs();
        let currentDate = currentDateMoment.date();
        let claimConfig = values[0];
        let claimTypes = values[1];
        let uoms = values[2];
        let compCurr = values[3];
        let lastClosedDateMoment =
          values[4] != null ? dayjs(values[4]) : dayjs().subtract(1, "months"); //if no last closed date, default to last month
        let travelConfig = values[5];
        let claimToEdit = values[6];

        setClaimConfg(claimConfig);
        setTravelConfg(travelConfig);
        let tempClaimTypes = claimTypes.filter(
          (x) => x.IsRequiredAuth === false && x.IsClaimable === true
        );
        setAllUOMs(uoms);
        setUserCompCurr(compCurr);

        if (claimConfig) {
          let validClaimDateMoment = currentDateMoment;
          if (currentDate > claimConfig.CutOffDay) {
            let nextMonthDate = currentDateMoment.add(1, "months");
            validClaimDateMoment = nextMonthDate;
          }

          //only check against Quarto payroll date if claim is set post to Quarto
          if (
            claimConfig.IsPostToQuarto &&
            validClaimDateMoment <= lastClosedDateMoment
          ) {
            validClaimDateMoment = lastClosedDateMoment.add(1, "months");
          }
          setDefaultClaimYearMonth(validClaimDateMoment);

          if (claimConfig.IsGroupByProj) {
            getProjectCode()
              .then((projects) => {
                setAllProjects(projects);
              })
              .catch((error) => {
                setIsLoading(false);
                toast.error(error.response.data.error_description);
              });
          } else {
            setAllProjects([]);
          }

          if (claimConfig.IsMultiCurrency) {
            getMonthlyExRateAndCurrency(
              claimToEdit
                ? claimToEdit.ClaimYearMonth
                : defaultClaimYearMonth.toDate(),
              claimToEdit ? claimToEdit.CurrKey : newClaimSubmission.CurrKey
            );
          } else {
            setAllCurrencies([]);
            setSelectedCurrency(compCurr);
          }
        } else {
          //if no claim config
          let validClaimDateMoment = currentDateMoment;
          setDefaultClaimYearMonth(validClaimDateMoment);
          setAllProjects([]);
          setAllCurrencies([]);
          setSelectedCurrency(compCurr);
        }

        if (claimToEdit) {
          let editedFiles = claimToEdit.Attaches.map((value) => {
            return {
              uid: value.AttchKey,
              name: value.FileName,
              url: value.UploadedUrl,
              status: "done",
            };
          });
          claimToEdit.StatusDesc = Status[claimToEdit.Status];

          setFileList(editedFiles);

          let editClaimType = claimTypes
            ? claimTypes.find(
                (x) => x.ClaimTypeKey === claimToEdit.ClaimTypeKey
              )
            : {};
          setSelectedClaimType(editClaimType ? editClaimType : {});
          setSelectedClaimLimitUsage({
            ClaimTypeLimits: claimToEdit.ClaimTypeLimits,
            ClaimUsage: claimToEdit.ClaimUsage,
          });

          if (location.state) {
            if (location.state.travelDetKey) {
              claimToEdit.TravelTransDetKey = location.state.travelDetKey;
            }

            if (
              location.state.fromTravel &&
              editClaimType &&
              editClaimType.DefaultCost > 0
            ) {
              claimToEdit.TotalAmount =
                claimToEdit.Quantity * editClaimType.DefaultCost;
              claimToEdit.FuncTotalAmount = calcFuncTotalAmount(
                claimToEdit.Quantity * editClaimType.DefaultCost,
                claimToEdit.exRate ? claimToEdit.ExRate : 1
              );
            }
          }

          if (claimToEdit.TravelTransDetKey !== -1) {
            tempClaimTypes = claimTypes.filter((x) => x.IsClaimable === true);
          }

          if (claimToEdit.IsLink) {
            setIsFromTravel(true);
          }

          setEditItem(claimToEdit);
          setIsOwner(
            isLoginUser(claimToEdit.UserKey) || isEditor(claimToEdit.CreatedBy)
          );
        } else {
          setFileList([]);
          setSelectedClaimType({});
          setSelectedClaimLimitUsage({});
          setIsOwner(true);
          setEditItem(newClaimSubmission);
        }

        setAllClaimTypes(tempClaimTypes);
        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.error_description);
      });
  }

  function getTotalClaimAndLimitUsage(claimTypeKey, claimYearMonth) {
    if (claimTypeKey && claimYearMonth) {
      setIsLoading(true);
      getClaimLimitAndUsage(claimTypeKey, claimYearMonth.toDate())
        .then((value) => {
          setSelectedClaimLimitUsage(value);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.error_description);
        });
    } else {
      setSelectedClaimLimitUsage({});
    }
  }

  function getMonthlyExRateAndCurrency(claimYrMth, currKey) {
    if (claimYrMth) {
      setIsLoading(true);
      return getMonthlyExCurrency(claimYrMth)
        .then((currencies) => {
          setAllCurrencies(currencies);
          setIsLoading(false);

          let temp = {};
          if (currencies && currencies.length) {
            let current = currencies.filter((x) => x.CurrKey === currKey);
            if (current.length && current[0]) {
              temp = current[0];
            }
          }
          setSelectedCurrency(temp);
          return temp;
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error.response.data.error_description);
        });
    } else {
      return new Promise((resolve) => resolve({}));
    }
  }

  function calcFuncTotalAmount(origTotalAmount, exRate) {
    if (userCompCurr && userCompCurr.ConvertType) {
      if (userCompCurr.ConvertType === "*") {
        return (origTotalAmount * exRate).toFixed(2);
      } else if (userCompCurr.ConvertType === "/") {
        return (origTotalAmount / exRate).toFixed(2);
      } else {
        return origTotalAmount;
      }
    } else {
      return origTotalAmount;
    }
  }

  function viewTravelTrans(TravelTransKey) {
    history("/home/travelauth/travelTrans/" + TravelTransKey, {
      state: { fromClaim: true },
    });
  }

  return (
    <ManageClaimSubmissionForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onUploadAttachment={onUploadAttachment}
      editItem={editItem}
      defaultClaimYearMonth={defaultClaimYearMonth}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      fileList={fileList}
      setFileList={setFileList}
      onClaimTypeChanged={setSelectedClaimType}
      getTotalClaimAndLimitUsage={getTotalClaimAndLimitUsage}
      getMonthlyExRateAndCurrency={getMonthlyExRateAndCurrency}
      allUOMs={allUOMs}
      allClaimTypes={allClaimTypes}
      allProjects={allProjects}
      allCurrencies={allCurrencies}
      selectedClaimType={selectedClaimType}
      selectedClaimLimitUsage={selectedClaimLimitUsage}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      claimConfg={claimConfg}
      travelConfg={travelConfg}
      userCompCurr={userCompCurr}
      selectedCurrency={selectedCurrency}
      onCurrencyChanged={setSelectedCurrency}
      isFromTravel={isFromTravel}
      calcFuncTotalAmount={calcFuncTotalAmount}
      onRemoveAttachment={onRemoveAttachment}
      setIsDirty={setIsDirty}
      form={form}
      onViewTravel={viewTravelTrans}
    />
  );
};

ManageClaimSubmissionPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageClaimSubmissionPage;
