import React, { Component } from "react";
import Icon, { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { toast } from "react-toastify";

function beforeUpload(file) {
  const isImgType = file.type === "image/jpeg" || file.type === "image/png";
  if (!isImgType) {
    toast.error("You can only upload JPG and PNG file!");
  }
  const isLt4M = file.size / 1024 / 1024 <= 4;
  if (!isLt4M) {
    toast.error("Maximum file size is 4MB.");
  }
  return isImgType && isLt4M;
}

class ImageInput extends Component {
  state = {
    loading: false,
  };

  handleChange = (info) => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      // getBase64(info.file.originFileObj, imageUrl => {
      //   this.setState({
      //     imageUrl,
      //     loading: false
      //   });
      // });
    }
  };

  //just to handle mandatory action, add dummy to prevent the error
  dummyRequest({ file, onSuccess }) {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  }

  render() {
    const uploadButton = (
      <div>
        <Icon component={this.state.loading ? LoadingOutlined : PlusOutlined} />

        <div className="ant-upload-text">Upload</div>
      </div>
    );
    const imageUrl = this.props.imageURL;
    return (
      <Upload
        accept="image/jpeg"
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
        // action={file => {
        //   return new Promise((resolve, reject) => {
        //     resolve(file);
        //   })
        // }}
        customRequest={this.dummyRequest}
        beforeUpload={beforeUpload}
        onChange={this.props.onChange}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            style={{ width: "100%", height: "auto" }}
            alt="profile"
          />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

export default ImageInput;
