import React from "react";
import {
  Table,
  Spin,
  Card,
  Avatar,
  Divider,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { StatusByHexColor } from "../../../../components/model/common/Status";
import dayjs from "dayjs";
import { SelectionInput } from "../../../common/SelectionInput";
import { useTranslation } from "react-i18next";

const { MonthPicker } = DatePicker;

const TravelAuthorizationDisplayAllForm = ({
  isLoading,
  userTravelAuthorization,
  getTravelAuthorizationDataSource,
  setCurrentMonth,
  setCurrentYear,
  form,
  allOus,
  allDepartments,
  allUsers,
  initialUsers,
  setAllUsers,
  filterRecord,
}) => {
  const { t } = useTranslation();
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        let currentMonthField = values.MonthYear.month() + 1;
        let currentYearField = values.MonthYear.year();
        getTravelAuthorizationDataSource(currentMonthField, currentYearField);
      })
      .catch(() => {});
  };

  function onYearMonthChanged(value) {
    if (value) {
      setCurrentMonth(value.month() + 1);
      setCurrentYear(value.year());

      form
        .validateFields()
        .then(() => {
          let currentMonthField = value.month() + 1;
          let currentYearField = value.year();
          getTravelAuthorizationDataSource(currentMonthField, currentYearField);
        })
        .catch(() => {});
    }
  }

  const TravelIndicators = (value) => (
    <Tooltip title={value ? value.day : ""}>
      <Avatar
        shape="round"
        size="small"
        style={{
          width: "17px",
          height: "17px",
          backgroundColor: value ? value.type : "#ffffff",
        }}
      />
    </Tooltip>
  );

  const columns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      width: 120,
      fixed: "left",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmployeeName",
      key: "EmployeeName",
      width: 300,
      fixed: "left",
      sorter: (a, b) => a.EmployeeName.localeCompare(b.EmployeeName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "1",
      dataIndex: "1",
      key: "1",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "2",
      dataIndex: "2",
      key: "2",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "3",
      dataIndex: "3",
      key: "3",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "4",
      dataIndex: "4",
      key: "4",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "5",
      dataIndex: "5",
      key: "5",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "6",
      dataIndex: "6",
      key: "6",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "7",
      dataIndex: "7",
      key: "7",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "8",
      dataIndex: "8",
      key: "8",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "9",
      dataIndex: "9",
      key: "9",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "10",
      dataIndex: "10",
      key: "10",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "11",
      dataIndex: "11",
      key: "11",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "12",
      dataIndex: "12",
      key: "12",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "13",
      dataIndex: "13",
      key: "13",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "14",
      dataIndex: "14",
      key: "14",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "15",
      dataIndex: "15",
      key: "15",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "16",
      dataIndex: "16",
      key: "16",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "17",
      dataIndex: "17",
      key: "17",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "18",
      dataIndex: "18",
      key: "18",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "19",
      dataIndex: "19",
      key: "19",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "20",
      dataIndex: "20",
      key: "20",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "21",
      dataIndex: "21",
      key: "21",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "22",
      dataIndex: "22",
      key: "22",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "23",
      dataIndex: "23",
      key: "23",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "24",
      dataIndex: "24",
      key: "24",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "25",
      dataIndex: "25",
      key: "25",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "26",
      dataIndex: "26",
      key: "26",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "27",
      dataIndex: "27",
      key: "27",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "28",
      dataIndex: "28",
      key: "28",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "29",
      dataIndex: "29",
      key: "29",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "30",
      dataIndex: "30",
      key: "30",
      width: 35,
      render: TravelIndicators,
    },
    {
      title: "31",
      dataIndex: "31",
      key: "31",
      width: 35,
      render: TravelIndicators,
    },
  ];

  const getResponsiveColumns = () => {
    if (form.getFieldValue("MonthYear") === undefined) return;

    let respMonthField = form.getFieldValue("MonthYear").month() + 1;
    let respYearField = form.getFieldValue("MonthYear").year();

    if (
      respMonthField === 4 ||
      respMonthField === 6 ||
      respMonthField === 9 ||
      respMonthField === 11
    ) {
      return columns.filter((item) => item.key !== "31");
    } else if (respMonthField === 2) {
      if (respYearField % 4 === 0) {
        return columns.filter((item) => item.key !== "30" && item.key !== "31");
      } else {
        return columns.filter(
          (item) => item.key !== "29" && item.key !== "30" && item.key !== "31"
        );
      }
    }
    return columns;
  };

  function onChangeOU(value) {
    let dept = form.getFieldValue("Dept");
    let filteredUsers = initialUsers.filter(
      (x) =>
        (dept.value === 0 || x.DeptKey === dept.value) &&
        (value.value === 0 || x.OUKey === value.value)
    );
    setAllUsers(filteredUsers);

    form.setFieldsValue({
      User: { key: 0, value: 0, label: "All" },
      Dept: { key: 0, value: 0, label: "All" },
    });
    filterRecord(value.value, 0, 0);
  }

  function onChangeDept(value) {
    let ou = form.getFieldValue("OU");
    let filteredUsers = initialUsers.filter(
      (x) =>
        (value.value === 0 || x.DeptKey === value.value) &&
        (ou.value === 0 || x.OUKey === ou.value)
    );
    setAllUsers(filteredUsers);

    form.setFieldsValue({
      User: { key: 0, value: 0, label: "All" },
    });
    filterRecord(ou.value, value.value, 0);
  }

  function onUserChange(value) {
    let ou = form.getFieldValue("OU");
    let dept = form.getFieldValue("Dept");
    filterRecord(ou.value, dept.value, value.value);
  }

  return (
    <Card
      title={
        <span className="form-title">
          {t("travelAuthorizationForm.display_all_travel_authorization")}
        </span>
      }
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.month_year")}
                name="MonthYear"
                rules={[
                  {
                    required: true,
                    message: t("general.month_year_required_validation"),
                  },
                ]}
                initialValue={dayjs(dayjs(), "MM/YYYY")}
              >
                <MonthPicker
                  allowClear={false}
                  placeholder={t("general.month_year_placeholder")}
                  format="MM/YYYY"
                  onChange={onYearMonthChanged}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.operating_unit")}
                name="OU"
                initialValue={{ value: 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allOus}
                  valueProp={"OUKey"}
                  textProp={"OUCodeOUDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.operating_unit_placeholder")}
                  onChange={onChangeOU}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.department")}
                name="Dept"
                initialValue={{ value: 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allDepartments}
                  valueProp={"Key"}
                  textProp={"CodeDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.department_placeholder")}
                  onChange={onChangeDept}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.employee")}
                name="User"
                initialValue={{ value: 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allUsers}
                  valueProp={"UserKey"}
                  textProp={"EmpyIDEmpyName"}
                  placeholder={t("general.employee_placeholder")}
                  onChange={onUserChange}
                  isIncludeAllOption={allUsers.length > 0}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          bordered
          dataSource={userTravelAuthorization}
          columns={getResponsiveColumns()}
          size="small"
          rowKey={(record) => record.UserKey}
          pagination={true}
          scroll={{ x: "max-content", y: "calc(100vh - 100px)" }}
        />

        <Divider orientation="left" style={{ color: "#0070cc" }}>
          {t("general.legend")}
        </Divider>
        <Row>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #ccc",
                backgroundColor: "#ffffff",
              }}
            />{" "}
            {t("general.not_applied")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[0],
              }}
            />{" "}
            {t("general.open")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[1],
              }}
            />{" "}
            {t("general.submitted")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[2],
              }}
            />{" "}
            {t("general.request_cancel")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[3],
              }}
            />{" "}
            {t("general.rejected")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[4],
              }}
            />{" "}
            {t("general.partial_approved")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[5],
              }}
            />{" "}
            {t("general.approved")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: StatusByHexColor[6],
              }}
            />{" "}
            {t("general.void")}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

TravelAuthorizationDisplayAllForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userTravelAuthorization: PropTypes.array.isRequired,
  getTravelAuthorizationDataSource: PropTypes.func.isRequired,
  setCurrentMonth: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  allOus: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  allUsers: PropTypes.array.isRequired,
  initialUsers: PropTypes.array.isRequired,
  setAllUsers: PropTypes.func.isRequired,
  filterRecord: PropTypes.func.isRequired,
};

export default TravelAuthorizationDisplayAllForm;
