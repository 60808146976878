import { RowState } from "../common/RowState";

export default function AppraiseeModel({
  APUserKey = Math.floor(Math.random() * 999999),
  APKey = -1,
  UserKey = -1,
  EmpyKey = -1,
  EmpyID = "",
  EmpyName = "",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    APUserKey: APUserKey,
    APKey: APKey,
    UserKey: UserKey,
    EmpyKey: EmpyKey,
    EmpyID: EmpyID,
    EmpyName: EmpyName,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
