import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Input,
  Select,
  Row,
  Col,
  Card,
  Button,
  Spin,
  Switch,
  Modal,
  Form,
} from "antd";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { confirm } = Modal;

const APRLvlConfgInputs = ({
  isLoading,
  aprLvlConfgs,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const [isDisable, setIsDisable] = useState(false);
  function toggle(value) {
    if (value === 0 || value === 1) {
      form.setFieldsValue({ IsOverride: false });
      aprLvlConfgs.IsDisable = true;
      setIsDisable(true);
    } else {
      aprLvlConfgs.IsDisable = false;
      setIsDisable(false);
    }
  }

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      if (
        (aprLvlConfgs.AppLvl === 2 && values.AppLvl === 1) ||
        (aprLvlConfgs.AppLvl === 1 && values.AppLvl === 0)
      ) {
        confirm({
          title: t("aprLvlConfigForm.update_confirmation"),
          content: t("aprLvlConfigForm.update_approval_settings_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            onSubmitted(values);
          },
          onCancel() {},
        });
      } else {
        onSubmitted(values);
      }
    });
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={handleSubmit}
      onValuesChange={() => setIsDirty(true)}
    >
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <span className="form-title">
                {t("aprLvlConfigForm.approval_level_settings")}
              </span>
            }
          >
            <Spin spinning={isLoading}>
              <Row gutter={formats.cellSpacing}>
                <Col span={12}>
                  <Form.Item
                    label={t("aprLvlConfigForm.subject")}
                    name="Subject"
                    initialValue={
                      aprLvlConfgs.Subject === undefined
                        ? undefined
                        : aprLvlConfgs.Subject
                    }
                  >
                    <Input
                      placeholder={t("aprLvlConfigForm.subject")}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("general.description")}
                    name="SubjectDesc"
                    initialValue={
                      aprLvlConfgs.SubjectDesc === undefined
                        ? undefined
                        : aprLvlConfgs.SubjectDesc
                    }
                  >
                    <Input
                      placeholder={t("general.description")}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={formats.cellSpacing}>
                <Col span={8}>
                  <Form.Item
                    label={t("aprLvlConfigForm.approval_level")}
                    style={{ margin: 0 }}
                    name="AppLvl"
                    initialValue={
                      aprLvlConfgs.AppLvl === -1
                        ? undefined
                        : aprLvlConfgs.AppLvl === 0
                        ? t("aprLvlConfigForm.not_required")
                        : aprLvlConfgs.AppLvl
                    }
                  >
                    <Select
                      showSearch
                      placeholder={t(
                        "aprLvlConfigForm.approval_level_placeholder"
                      )}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={toggle}
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      <Option key="0" value={0}>
                        {t("aprLvlConfigForm.not_required")}
                      </Option>
                      <Option key="1" value={1}>
                        1
                      </Option>
                      <Option key="2" value={2}>
                        2
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label=""
                    style={{ margin: 25 }}
                    name="IsOverride"
                    valuePropName="checked"
                    initialValue={aprLvlConfgs.IsOverride}
                  >
                    <Switch
                      disabled={aprLvlConfgs.IsDisable || isDisable}
                      checkedChildren={t("aprLvlConfigForm.override")}
                      unCheckedChildren={t("aprLvlConfigForm.no_override")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </Card>

          <Row className="save-section">
            <Col style={{ textAlign: "right", padding: "10px" }}>
              <Button
                size="large"
                type="default"
                onClick={onCancelled}
                style={{ marginRight: 10 }}
              >
                {t("general.cancel")}
              </Button>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                style={{ marginRight: 10 }}
              >
                {t("general.save")}
              </Button>
            </Col>
          </Row>
          <EntryFormFooter
            footerData={aprLvlConfgs}
            showFooter={aprLvlConfgs.SubjectKey !== -1 ? true : false}
            isApproved={false}
            isVoid={false}
            isPending={false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>
    </Form>
  );
};

APRLvlConfgInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  aprLvlConfgs: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const ManageAPRLvlConfgForm = APRLvlConfgInputs;
