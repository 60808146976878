import { RowState } from "../common/RowState";

export default function FilterModel({
  APFilterKey = Math.floor(Math.random() * 999999),
  APKey = -1,
  Type = -1,
  TypeKey = "",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    APFilterKey: APFilterKey,
    APKey: APKey,
    Type: Type,
    TypeKey: TypeKey,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
