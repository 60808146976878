import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import RoomRequisitionApprovalForm from "../../forms/RoomReq/RoomRequisitionApprovalForm";
import {
  getPendingAndApprovedRoomRequisitions,
  approveRoomRequisitions,
  rejectRoomRequisitions,
} from "../../../api/roomRequisitionApprovalApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function RoomRequisitionApprovalPage({ history }) {
  const location = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [roomRequisitions, setRoomRequisitions] = useState([]);
  const [selectedRoomRequisitions, setSelectedRoomRequisitions] = useState([]);
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.year : dayjs().year()
  );

  useEffect(getRoomRequisitions, [currentYear]);

  function getRoomRequisitions() {
    setIsLoading(true);
    getPendingAndApprovedRoomRequisitions(currentYear)
      .then((value) => {
        setRoomRequisitions(value ? value : []);
        setSelectedRoomRequisitions([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewRoomRequisition() {
    history("/home/meetingroomrequisition/roomtrans");
  }

  function onApproveRoomRequisitions(selectedRoomRequisitions) {
    setIsLoading(true);

    approveRoomRequisitions(selectedRoomRequisitions)
      .then(() => {
        getRoomRequisitions();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function onRejectRoomRequisitions(
    selectedRoomRequisitions,
    reason,
    isAllowAmend
  ) {
    setIsLoading(true);

    rejectRoomRequisitions(selectedRoomRequisitions, reason, isAllowAmend)
      .then(() => {
        getRoomRequisitions();
        toast.success(t("general.record_rejected_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_reject_record"), error);
      });
  }

  function viewRoomRequisition(roomRequisitionKey) {
    history("/home/meetingroomrequisition/roomtrans/" + roomRequisitionKey, {
      state: { fromApproval: true },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <RoomRequisitionApprovalForm
      isLoading={isLoading}
      data={roomRequisitions}
      selectedItems={selectedRoomRequisitions}
      onAdd={onAddNewRoomRequisition}
      onView={viewRoomRequisition}
      onApproved={onApproveRoomRequisitions}
      onRejected={onRejectRoomRequisitions}
      onItemSelected={setSelectedRoomRequisitions}
      setCurrentYear={setCurrentYear}
      currentYear={currentYear}
    />
  );
}

RoomRequisitionApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
