import React, { useState, useEffect } from "react";
import {
  DownOutlined,
  IssuesCloseOutlined,
  StarFilled,
} from "@ant-design/icons";
import { List, Button, Badge, Empty, Dropdown, Menu } from "antd";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

export default function PendingApprovalVehicleReqWidget({
  isLoading,
  pendingVehicleReqApproval,
  history,
}) {
  const { t } = useTranslation();
  const [hasPendingApproval, setHasPendingApproval] = useState(false);

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: t("pendingApprovalVehicleReqWidget.vehicle_approval"),
          key: "1",
        },
        {
          label: t("pendingApprovalVehicleReqWidget.vehicle_cancellation"),
          key: "2",
        },
      ]}
    ></Menu>
  );

  function onView() {
    const approveVehicleReq =
      pendingVehicleReqApproval.pendingVehicleReqInfo.filter(
        (x) => x.Status === 1 || x.Status === 4
      );

    const cancelVehicleReq =
      pendingVehicleReqApproval.pendingVehicleReqInfo.filter(
        (x) => x.Status !== 1 && x.Status !== 4
      );

    if (approveVehicleReq.length > 0) {
      const minYear = Math.min(...approveVehicleReq.map((item) => item.Year));
      history("/home/vehiclerequisitionapproval", {
        state: {
          year: minYear,
        },
      });
    } else {
      const minYear = Math.min(...cancelVehicleReq.map((item) => item.Year));
      history("/home/vehiclerequisitioncancellationapproval", {
        state: {
          year: minYear,
        },
      });
    }
  }

  function handleMenuClick(e) {
    const approveVehicleReq =
      pendingVehicleReqApproval.pendingVehicleReqInfo.filter(
        (x) => x.Status === 1 || x.Status === 4
      );

    const cancelVehicleReq =
      pendingVehicleReqApproval.pendingVehicleReqInfo.filter(
        (x) => x.Status !== 1 && x.Status !== 4
      );

    if (e.key === "1") {
      const minYear = Math.min(...approveVehicleReq.map((item) => item.Year));
      history("/home/vehiclerequisitionapproval", {
        state: {
          year: minYear,
        },
      });
    } else {
      const minYear = Math.min(...cancelVehicleReq.map((item) => item.Year));
      history("/home/vehiclerequisitioncancellationapproval", {
        state: {
          year: minYear,
        },
      });
    }
  }

  useEffect(() => {
    setHasPendingApproval(pendingVehicleReqApproval.pendingAmount > 0);
  }, [pendingVehicleReqApproval]);

  return (
    <div style={{ display: !isLoading ? "block" : "none" }}>
      <div style={{ textAlign: "center", margin: "10px auto" }}>
        <Badge
          className="dashboard-badge"
          count={
            <StarFilled
              style={
                hasPendingApproval ? { display: "none" } : { color: "#3498db" }
              }
            />
          }
        >
          <div className="pending-number">
            {pendingVehicleReqApproval.pendingAmount}
          </div>
        </Badge>
      </div>
      <div
        style={
          hasPendingApproval
            ? {
                marginTop: "15px",
                height: "195px",
                backgroundColor: "#f5f7fa",
                borderRadius: "10px",
                overflow: "auto",
              }
            : { display: "none" }
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={pendingVehicleReqApproval.pendingVehicleReqInfo}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                style={{ padding: "0 10px 0 10px" }}
                title={item.EmpyName}
              />
            </List.Item>
          )}
        />
      </div>
      <div
        style={
          hasPendingApproval ? { display: "none" } : { padding: "10px 0 10px" }
        }
      >
        <Empty
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
          imageStyle={{
            height: 60,
            display: "none",
          }}
          description={
            <span>
              {t("pendingApprovalVehicleReqWidget.no_more_pending_request")}
            </span>
          }
        ></Empty>
      </div>
      <div className="dashboard-card-button" style={{ textAlign: "right" }}>
        <Button
          type="primary"
          icon={<IssuesCloseOutlined />}
          onClick={() => onView && onView()}
          style={{
            display: !pendingVehicleReqApproval.isMixStatus ? "" : "none",
          }}
        >
          {t("pendingApprovalVehicleReqWidget.view_request")}
        </Button>

        <Dropdown
          dropdownRender={() => menu}
          style={{
            display: pendingVehicleReqApproval.isMixStatus ? "" : "none",
          }}
        >
          <Button
            type="primary"
            icon={<IssuesCloseOutlined />}
            style={{
              display: pendingVehicleReqApproval.isMixStatus ? "" : "none",
            }}
          >
            {t("pendingApprovalVehicleReqWidget.view_request")} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}

PendingApprovalVehicleReqWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pendingVehicleReqApproval: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};
