import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ManageAppraisalSubmissionForm from "../../forms/Appraisal/ManageAppraisalSubmissionForm";
import PropTypes from "prop-types";
import AppraisalTemplateHeaderModel from "../../model/AppraisalTemplate/AppraisalTemplateHeaderModel";
import AppraisalSubmissionModel from "../../model/Appraisal/AppraisalSubmissionModel";
import AppraisalRatingModel from "../../model/AppraisalTemplate/AppraisalRatingModel";
import AppraisalSubmissionDetModel from "../../model/Appraisal/AppraisalSubmissionDetModel";
import {
  getAppraisalSubmissionByKey,
  addAppraisalSubmission,
  updateAppraisalSubmission,
  revertAppraisalSubmission,
} from "../../../api/appraisalSubmissionApi";
import {
  getAppraisalTemplate,
  getAppraisalEmployeeInfo,
} from "../../../api/appraisalTemplateApi";
import { getAppraisal } from "../../../api/appraisalApi";
import { approveAppraisalSubmissions } from "../../../api/appraisalSubmissionApprovalApi";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import { getEmpyGrade, getEmpyPosition } from "../../../api/empyGradeApi";
import { getAppraisalRating } from "../../../api/appraisalRatingApi";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newAppraisalSubmission = AppraisalSubmissionModel({});
const newAppraisalTemplate = AppraisalTemplateHeaderModel({});
const newAppraisalRating = AppraisalRatingModel({});
export default function ManageAppraisalSubmissionPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [appraisalTemplate, setAppraisalTemplate] =
    useState(newAppraisalTemplate);
  const [empyInfo, setEmpyInfo] = useState({});
  const [appraisalSubmission, setAppraisalSubmission] = useState(
    newAppraisalSubmission
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(true);
  const [isLv1Appraiser, setIsLv1Appraiser] = useState(false);
  const [isLv2Appraiser, setIsLv2Appraiser] = useState(false);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [grades, setGrades] = useState([]);
  const [positions, setPositions] = useState([]);
  const [appraisalRating, setAppraisalRating] = useState(newAppraisalRating);
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function saveAppraisalSubmission(values) {
    let toastStatus = 0;
    toastStatus =
      values.Status === 0
        ? appraisalSubmission.Status === 1
          ? 3
          : values.Status
        : values.Status;

    let appraisalDets = appraisalSubmission.Dets.map((x) => {
      return { ...x, AppraisalSelection: x.AppraisalSelection.toString() };
    });

    let appraisalSubmit = {
      ...appraisalSubmission,
      ...values,
      Remarks: values.Remarks.trim(),
      Dets: appraisalDets,
    };

    let upsertAppraisal =
      appraisalSubmit.AppraisalTransHdrKey !== -1
        ? updateAppraisalSubmission
        : addAppraisalSubmission;

    setIsLoading(true);

    upsertAppraisal(appraisalSubmit)
      .then((result) => {
        if (result) {
          setIsDirty(false);
          history(
            "/home/appraisalsubmission/appraisaltrans/" +
              result.AppraisalTransHdrKey,
            {
              state: { force: true },
            }
          );
          loadPageData(result.AppraisalTransHdrKey);

          if (toastStatus === 1) {
            toast.success(t("general.record_submitted_successfully"));
          } else if (toastStatus === 2) {
            toast.success(t("general.record_request_cancel_successfully"));
          } else if (toastStatus === 3) {
            toast.success(t("general.record_withdrawn_successfully"));
          } else {
            toast.success(t("general.record_saved_successfully"));
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          appraisalSubmit.AppraisalTransHdrKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.message);
      });
  }

  function approveAppraisalSubmission(selectedAppraisal) {
    setIsLoading(true);

    let appraisalDets = appraisalSubmission.Dets.map((x) => {
      return { ...x, AppraisalSelection: x.AppraisalSelection.toString() };
    });

    let appraisalSubmit = [
      {
        ...selectedAppraisal,
        Dets: appraisalDets,
      },
    ];

    approveAppraisalSubmissions(appraisalSubmit)
      .then(() => {
        setIsDirty(false);
        toast.success(t("general.record_approved_successfully"));
        history("/home/appraisalsubmissionapproval", {
          state: { force: true },
        });
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(t("general.fail_to_approve_record"), error);
      });
  }

  function onRevertAppraisalSubmission(selectedAppraisal) {
    setIsLoading(true);

    let appraisalDets = appraisalSubmission.Dets.map((x) => {
      return { ...x, AppraisalSelection: x.AppraisalSelection.toString() };
    });

    let appraisalSubmit = {
      ...selectedAppraisal,
      Dets: appraisalDets,
    };

    revertAppraisalSubmission(appraisalSubmit)
      .then((result) => {
        if (result) {
          setIsDirty(false);
          history(
            "/home/appraisalsubmission/appraisaltrans/" +
              result.AppraisalTransHdrKey,
            {
              state: { force: true },
            }
          );
          loadPageData(result.AppraisalTransHdrKey);
          toast.success(t("general.record_reverted_successfully"));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(t("general.fail_to_revert_record"), error);
      });
  }

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/appraisalsubmissionapproval");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/appraisal", {state: location.state});
      } else {
        history("/home/appraisalsubmissiondisplay");
      }
    } else {
      history("/home/appraisalsubmissiondisplay");
    }
  }

  function loadPageData(editKey) {
    if (editKey > 0) {
      getAppraisalSubmissionByKey(editKey).then((result) => {
        let promises = [
          getAppraisalTemplate(result.ATKey),
          getAppraisalEmployeeInfo(result.UserKey),
          getAppraisal(result.APKey),
          getEmpyGrade(),
          getEmpyPosition(),
          getAppraisalRating(result.ARKey),
          result,
        ];
        promiseLoadPage(promises);
      });
    } else {
      if (location.state) {
        let promises = [
          getAppraisalTemplate(location.state.templateKey),
          getAppraisalEmployeeInfo(location.state.userKey),
          getAppraisal(location.state.appraisalKey),
          getEmpyGrade(),
          getEmpyPosition(),
          getAppraisalRating(location.state.appraisalRatingKey),
        ];
        promiseLoadPage(promises);
      } else {
        history("/home/appraisalsubmissiondisplay", {
          state: { force: true },
        });
      }
    }
  }

  function promiseLoadPage(promises) {
    Promise.all(promises)
      .then((values) => {
        setAppraisalTemplate(values[0] ? values[0] : []);
        setEmpyInfo(values[1] ? values[1] : []);
        setGrades(values[3] ? values[3] : []);
        setPositions(values[4] ? values[4] : []);
        setAppraisalRating(values[5] ? values[5] : []);

        let tempTemplateDets = values[0] ? values[0].AppraisalTemplateDet : [];

        if (values[6]) {
          setIsOwner(
            isLoginUser(values[6].UserKey) || isEditor(values[6].CreatedBy)
          );

          let defaultAprDets = [];
          let haveApr1Data = false;
          let haveApr2Data = false;

          values[6].Dets.forEach((det) => {
            det.AppraisalSelection = transformData(
              false,
              det.ATDetType,
              det.AppraisalSelection
            );
            if (det.UserRole === "aprlv1") haveApr1Data = true;
            if (det.UserRole === "aprlv2") haveApr2Data = true;

            if (values[6].UserRole === "aprlv1" && det.UserRole === "user") {
              insertAprRecord(
                false,
                defaultAprDets,
                [det],
                "aprlv1",
                det.AppraisalSelection
              );
            } else if (
              values[6].UserRole === "aprlv2" &&
              det.UserRole === "aprlv1"
            ) {
              insertAprRecord(
                false,
                defaultAprDets,
                [det],
                "aprlv2",
                det.AppraisalSelection
              );
            }
          });

          //check whether apr1 or apr2
          if (location.state) {
            if (location.state.fromApproval) {
              if (values[6].UserRole === "aprlv1") {
                if (!haveApr1Data)
                  values[6].Dets = [...values[6].Dets, ...defaultAprDets];
                setIsLv1Appraiser(true);
              } else if (values[6].UserRole === "aprlv2") {
                if (!haveApr2Data)
                  values[6].Dets = [...values[6].Dets, ...defaultAprDets];
                setIsLv2Appraiser(true);
              }
            }
          }
          setAppraisalSubmission(values[6]);
        } else {
          let tempDets = [];
          insertAprRecord(true, tempDets, tempTemplateDets, "user");
          setAppraisalSubmission({
            ...appraisalSubmission,
            Dets: tempDets,
            APCodeAPDesc: values[2].APCodeAPDesc,
            APCode: values[2].APCode,
            APDesc: values[2].APDesc,
            APKey: values[2].APKey,
            FromSelfAppraisalDate: values[2].FromSelfAppraisalDate,
            ToSelfAppraisalDate: values[2].ToSelfAppraisalDate,
            FromReviewDate: values[2].FromReviewDate,
            ToReviewDate: values[2].ToReviewDate,
          });
        }
        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);

        setIsViewOnly(
          (location.state && location.state.fromAdmin) 
            ? location.state.fromAdmin 
            : false
          );
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }

  function insertAprRecord(isNew, data, template, level, defaultValue) {
    template.forEach((det) => {
      data.push(
        AppraisalSubmissionDetModel({
          ATDetKey: det.ATDetKey,
          ATDetType: det.ATDetType,
          RefATDetKey: det.RefATDetKey,
          AppraisalSelection: isNew
            ? transformData(true, det.ATDetType)
            : defaultValue,
          AppraisalTotal: isNew
            ? det.AppraisalSubDetail.length
            : det.AppraisalTotal,
          AppraisalWeight: det.ATDetWeight,
          AppraisalTitle: det.ATDetTitle,
          ATDetInd: det.ATDetInd,
          Comment: "",
          UserRole: level,
        })
      );
    });
  }

  function transformData(isNew, type, value) {
    if (type === 1) {
      if (isNew) return 1;
      else return parseInt(value);
    } else if (type === 2) {
      if (isNew) return false;
      else return value === "true";
    } else if (type === 3) {
      if (isNew) return 1;
      else return parseInt(value);
    } else if (type === 4) {
      if (isNew) return [];
      else {
        if (value === "") {
          return [];
        } else {
          return value.split(",").map(Number);
        }
      }
    } else {
      return "";
    }
  }

  return (
    <ManageAppraisalSubmissionForm
      isLoading={isLoading}
      appraisalSubmission={appraisalSubmission}
      appraisalTemplate={appraisalTemplate}
      empyInfo={empyInfo}
      grades={grades}
      positions={positions}
      appraisalRating={appraisalRating}
      loadPageData={loadPageData}
      onSubmitted={saveAppraisalSubmission}
      onCancelled={onCancelled}
      onApproved={approveAppraisalSubmission}
      onRevert={onRevertAppraisalSubmission}
      onDataChanged={setAppraisalSubmission}
      isOwner={isOwner}
      isLv1Appraiser={isLv1Appraiser}
      isLv2Appraiser={isLv2Appraiser}
      isViewOnly={isViewOnly}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAppraisalSubmissionPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
