import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import JobRecruitmentClosureForm from "../../forms/JobRecruitment/JobRecruitmentClosureForm";
import {
  getPendingAndValidatedJobRecruitments,
  validateJobRecruitments,
} from "../../../api/jobRecruitmentApprovalApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function JobRecruitmentClosurePage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [jobRecruitments, setJobRecruitments] = useState([]);
  const [selectedJobRecruitments, setSelectedJobRecruitments] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getJobRecruitments, [currentYear]);

  function getJobRecruitments() {
    setIsLoading(true);
    getPendingAndValidatedJobRecruitments(currentYear)
      .then((value) => {
        setJobRecruitments(value ? value : []);
        setSelectedJobRecruitments([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewJobRecruitment() {
    history("/home/jobrecruitment/jobrecruitments");
  }

  function onValidateJobRecruitments(selectedJobRecruitments, isHired, reason) {
    setIsLoading(true);

    let submitData = selectedJobRecruitments.map((x) => {
      x.IsHired = isHired;
      return x;
    });

    validateJobRecruitments(submitData, reason ? reason : "")
      .then(() => {
        getJobRecruitments();
        toast.success(t("general.record_validated_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_validate_record"), error);
      });
  }

  function viewJobRecruitment(jobRecruitmentKey) {
    history("/home/jobrecruitment/jobrecruitments/" + jobRecruitmentKey, {
      state: { fromValidation: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <JobRecruitmentClosureForm
      isLoading={isLoading}
      data={jobRecruitments}
      selectedItems={selectedJobRecruitments}
      onAdd={onAddNewJobRecruitment}
      onView={viewJobRecruitment}
      onValidated={onValidateJobRecruitments}
      onItemSelected={setSelectedJobRecruitments}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

JobRecruitmentClosurePage.propTypes = {
  history: PropTypes.func.isRequired,
};
