import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "clinicconfg/";

export function getClinicConfg() {
  return axios({
    method: "GET",
    url: base + "GetClinicConfg",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addClinicConfg(clinicConfg) {
  return axios({
    method: "POST",
    url: base + "AddClinicConfg",
    headers: authHeader(),
    data: clinicConfg
  })
    .then()
    .catch(handleError);
}

export function updateClinicConfg(clinicConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateClinicConfg",
    headers: authHeader(),
    params: { key: clinicConfg.ClinicConfgKey },
    data: clinicConfg
  })
    .then()
    .catch(handleError);
}
