import { authHeader } from "./apiUtils";
import Axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

const base = API_ROOT + "Attach/";

export function getSignedAttachmentUrl(attaches) {
  return Axios({
    method: "POST",
    url:
      base + "GetSignedAttachmentUrl?environment=" + env,
    data: attaches,
    headers: authHeader()
  });
}