import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function empyReducer(state = initialState.empy, action = {}) {
  switch (action.type) {
    case types.LOAD_EMPY_SUCCESS:
      return action.empy;
    // case types.DELETE_LEAVETYPE_OPTIMISTIC:
    //   return state.filter(leavetype => leavetype.LTKey !== action.leavetype.LTKey);
    default:
      return state;
  }
}
