import React from "react";
import { Input, Row, Col, Button, Card, Spin, Tree, Form, Modal } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const ManageRoleTypesPermissionByForm = ({
  isLoading,
  roleTypesPermissionByForm,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
  menuName,
  menuKey,
  onDataChanged,
  // eslint-disable-next-line react/prop-types
  roleType,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const formPerm = roleType ? roleType : [];
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isSuperAdmin = !auth
    ? false
    : auth.UserRoleKey === "1";
  
  let checkedRoleList = roleTypesPermissionByForm
    ? roleTypesPermissionByForm
    : [];
  checkedRoleList = checkedRoleList.filter((x) => x.RoleTypeKey !== -1);
  const renderTreeNodes = (data) =>
    data.length === 0
      ? []
      : data.map((item) => {
          return {
            key: item.RoleTypeKey,
            title: item.RoleTypeCodeDesc,
            disabled: !isSuperAdmin && item.RoleTypeKey === 1,
          };
        });

  const onCheck = (checkedKeys) => {
    checkedRoleList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedRoleList.push({
          RoleTypeKey: checkedKeys[index],
          ParentMenuKey: roleTypesPermissionByForm[0].ParentMenuKey
        });
      }
    }
    setIsDirty(true);
    onDataChanged({ checkedRoleList });
  };

  const showSaveConfirm = async () => {
    form.validateFields().then((values) => {
      values["checkedRoleList"] = checkedRoleList.map(a => a.RoleTypeKey);
      values["MenuKey"] = menuKey;

      if (checkedRoleList.some(a => a.ParentMenuKey === 139 || a.ParentMenuKey === 147)) {
        confirm({
          title: t("general.save_confirmation"),
          content: t("roleAssignment.delete_notif_confg_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            onSubmitted(values);
          },
          onCancel() {},
        });
      } else {
        onSubmitted(values);
      }
    });
  };
  
  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={showSaveConfirm}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("roleTypesPermissionForm.role_types_permission_by_form")}
                </span>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("roleTypesPermissionForm.menu_name")}
                    name="menuName"
                    initialValue={menuName}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
              {formPerm.length > 0 ? (
                <Tree
                  checkable
                  defaultCheckedKeys={
                    checkedRoleList.length > 0 ? checkedRoleList.map(a => a.RoleTypeKey) : []
                  }
                  onCheck={onCheck}
                  treeData={renderTreeNodes(formPerm)}
                ></Tree>
              ) : null}
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageRoleTypesPermissionByForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roleTypesPermissionByForm: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  menuName: PropTypes.string,
  menuKey: PropTypes.string.isRequired,
  onDataChanged: PropTypes.func.isRequired,
};

export default ManageRoleTypesPermissionByForm;
