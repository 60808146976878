import React, { useState, useEffect } from "react";
import ManageTrainingApplicationForm from "../../forms/Training/ManageTrainingApplicationForm";
import TrainingApplicationModel from "../../model/Training/TrainingApplicationModel";
import {
  getTrainingApplicationByKey,
  withdrawTrainingApplication,
  upsertTrainingFeedback,
  requestCancelTrainingApplication,
} from "../../../api/trainingApplicationApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Status } from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newTrainingApplication = TrainingApplicationModel({});

const ManageTrainingApplicationPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const TRN_APP_FORM_MESSAGE = {
    TRN_APP_SAVED: t("trainingApplicationForm.trn_app_saved"),
    TRN_APP_SUBMITTED: t("trainingApplicationForm.trn_app_submitted"),
    TRN_APP_WITHDRAWN: t("trainingApplicationForm.trn_app_withdrawn"),
    TRN_APP_CANCEL_REQUEST: t("trainingApplicationForm.trn_app_cancel_request"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newTrainingApplication);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [fileList, setFileList] = useState([]);
  const location = useLocation();
  const [isShowOU, setIsShowOU] = useState(false);

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/trainingapplicationapproval");
      } else if (location.state.fromVoid) {
        history("/home/trainingapplicationvoid");
      } else if (location.state.fromCancel) {
        history("/home/trainingapplicationcancellationapproval");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/training", { state: location.state });
      } else {
        history("/home/trainingapplicationdisplay");
      }
    } else {
      history("/home/trainingapplicationdisplay");
    }
  }

  function onSaved(trainingApplicationData, isSubmit) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...trainingApplicationData,
      Feedback: trainingApplicationData.Feedback.trim(),
      IsFeedbackSubmitted: isSubmit,
    };

    return upsertTrainingFeedback(dataSubmit, isSubmit)
      .then((result) => {
        let msgSuccess = isSubmit
          ? TRN_APP_FORM_MESSAGE.TRN_APP_SUBMITTED
          : TRN_APP_FORM_MESSAGE.TRN_APP_SAVED;
        toast.success(msgSuccess);
        setIsDirty(false);

        if (isSubmit) {
          history("/home/trainingapplicationdisplay", {
            state: { force: true },
          });
        } else {
          history(
            "/home/trainingapplication/trainingtrans/" +
              result.TrainingApplicationKey,
            {
              state: { force: true },
            }
          );
        }
        loadPageData(result.TrainingApplicationKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onWithdraw(trainingApplicationData) {
    setIsLoading(true);
    return withdrawTrainingApplication({
      ...editItem,
      ...trainingApplicationData,
    })
      .then(() => {
        toast.success(TRN_APP_FORM_MESSAGE.TRN_APP_WITHDRAWN);
        history("/home/trainingapplicationdisplay", {
          state: { force: true },
        });
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onRequestCancel(trainingApplicationData, reason) {
    setIsLoading(true);
    return requestCancelTrainingApplication(
      {
        ...editItem,
        ...trainingApplicationData,
      },
      reason
    )
      .then(() => {
        toast.success(TRN_APP_FORM_MESSAGE.TRN_APP_CANCEL_REQUEST);
        loadPageData(editItem.TrainingApplicationKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "TrainingFeedback")
      .then((result) => {
        setIsLoading(false);
        return result.data;
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  function viewTrainingProgram(trainingKey){
    history("/home/trainings/training/" + trainingKey, {
      state: {
        ...location.state,
        fromTrans: true, 
        trainingApplicationKey: editItem ? editItem.TrainingApplicationKey : -1,
      }
    });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];

    if (editKey) promises.push(getTrainingApplicationByKey(editKey));

    Promise.all(promises)
      .then((values) => {
        let trainingApplicationToEdit = values[0];

        if (trainingApplicationToEdit) {
          trainingApplicationToEdit.StatusDesc =
            Status[trainingApplicationToEdit.Status];
          trainingApplicationToEdit.TrainingDate = [
            dayjs(trainingApplicationToEdit.TrainingFromDate),
            dayjs(trainingApplicationToEdit.TrainingToDate),
          ];

          setEditItem(trainingApplicationToEdit);
          setIsOwner(
            isLoginUser(trainingApplicationToEdit.UserKey) ||
              isEditor(trainingApplicationToEdit.CreatedBy)
          );

          let editedFiles = trainingApplicationToEdit.FeedbackAttaches.map(
            (value) => {
              return {
                uid: value.AttchKey,
                name: value.FileName,
                url: value.UploadedUrl,
                status: "done",
              };
            }
          );
          setFileList(editedFiles);
        } else {
          setEditItem(newTrainingApplication);
          setIsOwner(true);
          setFileList([]);
        }

        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
          
        if (location.state) {
          setIsViewOnly(location.state.fromAdmin ? location.state.fromAdmin : false);
          setIsShowOU(location.state.fromDisplay ? false : true);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.ExceptionMessage);
      });
  }

  return (
    <ManageTrainingApplicationForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onRequestCancel={onRequestCancel}
      editItem={editItem}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      isShowOU={isShowOU}
      fileList={fileList}
      setFileList={setFileList}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      onViewProgram={viewTrainingProgram}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
};

ManageTrainingApplicationPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageTrainingApplicationPage;
