import React from "react";
import { Modal, Input, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ManageVehicleReqPassengerForm = ({
  isVisible,
  selectedDet,
  onCancel,
  onSave,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let passengerInfo = {
          ...selectedDet,
          ...{
            Name: values.Name ? values.Name : "",
          },
        };

        onSave(passengerInfo);
      })
      .catch(() => { });
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">
          {t("vehicleRequisitionForm.passenger")}
        </span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Form.Item
          label={<span>{t("general.name")}</span>}
          name="Name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("general.name_required_validation"),
            },
            {
              max: 250,
              message: t("general.name_max_length_validation"),
            },
          ]}
          initialValue={selectedDet.Name}
        >
          <Input placeholder={t("general.name_placeholder")} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ManageVehicleReqPassengerForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedDet: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageVehicleReqPassengerForm;
