import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "clinicapproval/";

export function getPendingApprovalClinics() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalClinics",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedClinics(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedClinics",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidClinics(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidClinics",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledClinics(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledClinics",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedClinics(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedClinics",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveClinics(clinicTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveClinics",
    headers: authHeader(),
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectClinics(clinicTrans, rejectReason, isAllowAmend) {
  return axios({
    method: "POST",
    url: base + "RejectClinics",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function voidClinics(clinicTrans, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidClinics",
    headers: authHeader(),
    params: { reason: voidReason },
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledClinics(clinicTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledClinics",
    headers: authHeader(),
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledClinics(clinicTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledClinics",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function validateClinics(clinicTrans) {
  return axios({
    method: "POST",
    url: base + "ValidateClinics",
    headers: authHeader(),
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}

export function invalidateClinics(clinicTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "InvalidateClinics",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: clinicTrans,
  })
    .then()
    .catch(handleError);
}
