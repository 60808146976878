import React from "react";
import { Input, Row, Col, Button, Switch, Card, Spin, Form } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const ManageProjectForm = ({
  isLoading,
  project,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };

  const disableField = project.RcdType === RecordType.USER ? false : true;

  const handleSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitted(values);
      })
      .catch(() => {});
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("projectsForm.project_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={project.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("projectsForm.project_code")}
                    name="ProjCode"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t(
                          "projectsForm.project_code_required_validation"
                        ),
                      },
                      {
                        max: 100,
                        message: t(
                          "projectsForm.project_code_max_length_validation"
                        ),
                      },
                    ]}
                    initialValue={project.ProjCode}
                  >
                    <Input
                      placeholder={t("projectsForm.project_code_placeholder")}
                      disabled={disableField}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.description")}
                    name="ProjDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.description_max_length_validation"),
                      },
                    ]}
                    initialValue={project.ProjDesc}
                  >
                    <Input placeholder={t("general.description_placeholder")} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={project}
              showFooter={project.ProjKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageProjectForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  project: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageProjectForm;
