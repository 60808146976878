import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function notificationReducer(
  state = initialState.notification,
  action = {}
) {
  switch (action.type) {
    case types.LOAD_NOTIFICATION_SUCCESS:
      var returnData = action.notifications;
      if (action.onDemand === 1) {
        if (state.length > 0) {
          if (action.notifications[0].child.length > 0) {
            var notificationDemandList = state[0].child.concat(
              action.notifications[0].child
            );
            var notificationCombinedData = {
              ...state[0],
              child: notificationDemandList,
            };
            returnData = [notificationCombinedData];
          }
        }
      }

      return returnData;
    case types.UPDATE_NOTIFICATION_SUCCESS:
      var data = [];
      for (let i = 0; i < state.length; i++) {
        let childData = state[i].child.map((item) =>
          item.NotificationKey === action.notification
            ? { ...item, IsRead: true }
            : item
        );

        let parentData = { ...state[i], child: childData };
        data = [...data, parentData];
      }
      return data;
    case types.UPDATE_ALLNOTIFICATION_SUCCESS:
      var allData = [];
      for (let i = 0; i < state.length; i++) {
        if (state[i].TypeKey === action.typeKey) {
          let childData = state[i].child.map((item) => ({
            ...item,
            IsRead: true,
          }));
          let parentData = { ...state[i], child: childData };
          allData = [...allData, parentData];
        } else {
          allData = [...allData, state[i]];
        }
      }
      return allData;
    case types.UPDATE_SELECTED_NOTIFICATION_SUCCESS:
      var selectedData = [];
      for (let i = 0; i < state.length; i++) {
        let childData = state[i].child;
        for (let j = 0; j < action.notifications.length; j++) {
          childData = childData.map((item) =>
            item.NotificationKey === action.notifications[j].NotificationKey
              ? { ...item, IsRead: true }
              : item
          );
        }
        let parentData = { ...state[i], child: childData };
        selectedData = [...selectedData, parentData];
      }
      return selectedData;
    case types.DELETE_NOTIFICATION_SUCCESS:
      var delData = [];
      for (let i = 0; i < state.length; i++) {
        let childData = state[i].child.filter(
          (item) => item.NotificationKey !== action.notification
        );
        let deletedRow =
          state[i].DeletedRecords === undefined ? 0 : state[i].DeletedRecords;
        let parentData = {
          ...state[i],
          child: childData,
          DeletedRecords: deletedRow + 1,
        };
        delData = [...delData, parentData];
      }
      return delData;
    case types.DELETE_SELECTED_NOTIFICATION_SUCCESS:
      var delSelectedData = [];
      for (let i = 0; i < state.length; i++) {
        let childData = state[i].child;
        for (let j = 0; j < action.notifications.length; j++) {
          childData = childData.filter(
            (item) =>
              item.NotificationKey !== action.notifications[j].NotificationKey
          );
        }

        let deletedRow =
          state[i].DeletedRecords === undefined ? 0 : state[i].DeletedRecords;

        let parentData = {
          ...state[i],
          child: childData,
          DeletedRecords: deletedRow + action.notifications.length,
        };
        delSelectedData = [...delSelectedData, parentData];
      }
      return delSelectedData;
    case types.ADD_NOTIFICATION:
      return state.map((item) => {
        let childData = [...item.child];
        if (item.TypeKey === action.notification.Type) {
          childData.unshift(action.notification);
        }
        let addedRow = item.AddedRecords === undefined ? 0 : item.AddedRecords;
        return { ...item, child: childData, AddedRecords: addedRow + 1 };
      });
    default:
      return state;
  }
}
