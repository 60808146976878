import { RowState } from "../common/RowState";

export default function AppraisalSubmissionDetModel({
  AppraisalTransDetKey = Math.floor(Math.random() * 999999),
  AppraisalTransHdrKey = -1,
  ATDetKey = -1,
  RefATDetKey = -1,
  UserKey = -1,
  ATDetType = -1,
  AppraisalSelection = "",
  AppraisalTotal = 0,
  AppraisalWeight = 0,
  AppraisalTitle = "",
  ATDetInd = "",
  Comment = "",
  UserRole = "user",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    AppraisalTransDetKey: AppraisalTransDetKey,
    AppraisalTransHdrKey: AppraisalTransHdrKey,
    ATDetKey: ATDetKey,
    RefATDetKey: RefATDetKey,
    UserKey: UserKey,
    ATDetType: ATDetType,
    AppraisalSelection: AppraisalSelection,
    AppraisalTotal: AppraisalTotal,
    AppraisalWeight: AppraisalWeight,
    AppraisalTitle: AppraisalTitle,
    ATDetInd: ATDetInd,
    Comment: Comment,
    UserRole: UserRole,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
