import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageRoleTypesPermissionByRole from "../../forms/Master/ManageRoleTypesPermissionByRole";
import PropTypes from "prop-types";
import * as menuapi from "../../../api/menuApi";
import * as roleapi from "../../../api/roleTypeApi";
import { Form } from "antd";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

let newRoleTypesPermissionByRole = [];

export default function ManageRoleTypesPermissionByRolePage({
  history,
  setIsDirty,
}) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [roleTypesPermissionByRole, setRoleTypesPermissionByRole] = useState(
    newRoleTypesPermissionByRole
  );
  const [menu, setMenu] = useState("");
  const [roleName, setRoleName] = useState("");
  const [roleType, setRoleType] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const { RoleKey } = useParams();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = RoleKey;
    if (editKey > 0) {
      promises.push(menuapi.getMenuPermByRole(editKey));
      promises.push(roleapi.getRoleTypeCode());
      promises.push(menuapi.getAllMenus());
    }

    function recurvsiveChild(item) {
      if (item.ChildMenu.length === 0) {
        return {
          key: item.MenuKey,
          title: item.MenuName,
          parentMenuKey: item.ParentMenuKey,
          menuUrl: item.MenuUrl,
        };
      } else {
        return {
          key: item.MenuKey,
          title: item.MenuName,
          parentMenuKey: item.ParentMenuKey,
          menuUrl: item.MenuUrl,
          children: item.ChildMenu.map((child) => {
            return recurvsiveChild(child);
          }),
        };
      }
    }

    Promise.all(promises)
      .then((values) => {
        let tempRole = values[1]
          ? values[1].filter((x) => String(x.Key) === RoleKey)
          : [];

        let tempMenu = [];
        values[2].map((item) => {
          return tempMenu.push({
            key: item.MenuKey,
            title: item.MenuName,
            parentMenuKey: item.ParentMenuKey,
            menuUrl: item.MenuUrl,
            children:
              item.ChildMenu.length !== 0
                ? item.ChildMenu.map((child) => {
                    return recurvsiveChild(child);
                  })
                : null,
          });
        });

        setRoleTypesPermissionByRole(values[0]);
        setRoleName(tempRole ? tempRole[0].CodeDesc : "");
        setRoleType(tempRole ? tempRole[0].Type : -1);
        setMenu(tempMenu ? tempMenu : "");
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [RoleKey]);

  function saveRoleTypesPermissionByRole(values) {
    let roleTypesPermissionByRoleSubmit = {
      ...values,
    };

    let upsertRoleTypesPermissionByRole =
      menuapi.updateRoleTypesPermissionByRole;
    setIsLoading(true);
    upsertRoleTypesPermissionByRole(roleTypesPermissionByRoleSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/roletypesperm", { state: { force: true } });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message = t("general.fail_to_update_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputRoleTypesPermissionByRole() {
    history("/home/roletypesperm");
  }

  function updateRoleTypesPermissionByRole(values) {
    setRoleTypesPermissionByRole(values.checkedKeyList);
  }

  return (
    <ManageRoleTypesPermissionByRole
      isLoading={isLoading}
      roleTypesPermissionByRole={roleTypesPermissionByRole}
      onSubmitted={saveRoleTypesPermissionByRole}
      onCancelled={cancelInputRoleTypesPermissionByRole}
      setIsDirty={setIsDirty}
      roleName={roleName}
      roleKey={RoleKey}
      roleType={roleType}
      onDataChanged={updateRoleTypesPermissionByRole}
      menu={menu}
      form={form}
    />
  );
}

ManageRoleTypesPermissionByRolePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
