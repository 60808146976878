import React, { useEffect, useState } from "react";
import TravelAuthorizationDisplayForm from "../../../forms/Claim/Travel/TravelAuthorizationDisplayForm";
import PropTypes from "prop-types";
import { getTravel } from "../../../../api/travelAuthorizationApi";
import { getSignedAttachmentUrl } from "../../../../api/attachApi";
import { toast } from "react-toastify";
import Axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const TravelAuthorizationDisplayPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [travelDisplay, setTravelDisplay] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getTravel(source, currentYear)
      .then((result) => {
        setTravelDisplay(result);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    return () => {
      source.cancel();
    };
  }, [currentYear, t]);

  function onAddNewTravel() {
    history("/home/travelauth/travelTrans");
  }

  function viewTravelTrans(TravelTransKey) {
    history("/home/travelauth/travelTrans/" + TravelTransKey);
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TravelAuthorizationDisplayForm
      isLoading={isLoading}
      data={travelDisplay}
      onAdd={onAddNewTravel}
      onView={viewTravelTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
};

TravelAuthorizationDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default TravelAuthorizationDisplayPage;
