import React, { useState } from "react";
import { EditButton } from "../../common/TableCols";
import { Table, Spin, Card, Row, Col, Form } from "antd";
import PropTypes from "prop-types";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import { SelectionInput } from "../../common/SelectionInput";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const NotifConfgForm = ({
  isLoading,
  notifConfgs,
  onEdit,
  allOus,
  allDepartments,
  filterRecord,
  form,
  ouKey,
  deptKey,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isSuperAdmin = !auth
    ? false
    : auth.UserRoleKey === '1';

  const NotifConfgEditButton = (value, roleTypeKey) => (
    <EditButton fncEdit={() => onEdit(value)} disabled={!isSuperAdmin && roleTypeKey === 1}/>
  );

  const [searchText, setSearchText] = useState("");
  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
      width: 150,
      ...FilterGridDropdown(
        "EmpyID",
        searchText,
        handleSearch,
        handleReset,
        "Employee ID",
        t
      ),
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmpyName",
      key: "EmpyName",
      width: 500,
      sorter: (a, b) => a.EmpyName.localeCompare(b.EmpyName),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "EmpyName",
        searchText,
        handleSearch,
        handleReset,
        "Employee Name",
        t
      ),
    },
    {
      title: t("general.action"),
      dataIndex: "UserKey",
      key: "EditNotifConfg",
      align: "center",
      width: 60,
      render: (value, record) => NotifConfgEditButton(value, record.RoleTypeKey),
    },
  ];

  function onChangeOU(value) {
    let dept = form.getFieldValue("Dept");
    let active = form.getFieldValue("Active");
    filterRecord(value.value, dept.value, active);
  }

  function onChangeDept(value) {
    let ou = form.getFieldValue("OU");
    let active = form.getFieldValue("Active");
    filterRecord(ou.value, value.value, active);
  }

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Form layout="vertical" form={form}>
      <Card
        title={
          <span className="form-title">
            {t("notifConfigForm.notification_settings")}
          </span>
        }
      >
        <Spin spinning={isLoading}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t("general.operating_unit")}
                name="OU"
                initialValue={{ value: ouKey }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allOus}
                  valueProp={"OUKey"}
                  textProp={"OUCodeOUDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.operating_unit_placeholder")}
                  onChange={onChangeOU}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Form.Item
                label={t("general.department")}
                name="Dept"
                initialValue={{
                  value: deptKey,
                }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allDepartments}
                  valueProp={"Key"}
                  textProp={"CodeDesc"}
                  disabledProp={"Active"}
                  placeholder={t("general.department_placeholder")}
                  onChange={onChangeDept}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Table
            dataSource={notifConfgs}
            columns={columns}
            size="middle"
            rowKey={(record) => record.UserKey}
            pagination={{
              defaultCurrent: currentPage,
              onChange: tablePaginationChanged,
            }}
            scroll={{ y: "calc(100vh - 270px)" }}
            onRow={(r) => ({ onClick: () => onRowSelected(r) })}
          />
        </Spin>
        <EntryFormFooter
          footerData={rowSelected}
          showFooter={
            rowSelected.UpdatedBy && rowSelected.UpdatedBy !== -1 ? true : false
          }
          isApproved={false}
          isVoid={false}
          isPending={false}
          t={t}
        ></EntryFormFooter>
      </Card>
    </Form>
  );
};

NotifConfgForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  notifConfgs: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  allOus: PropTypes.array.isRequired,
  allDepartments: PropTypes.array.isRequired,
  filterRecord: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  ouKey: PropTypes.number.isRequired,
  deptKey: PropTypes.number.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default NotifConfgForm;
