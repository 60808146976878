export const REGISTER_REQUEST = "USERS_REGISTER_REQUEST";
export const REGISTER_SUCCESS = "USERS_REGISTER_SUCCESS";
export const REGISTER_FAILURE = "USERS_REGISTER_FAILURE";

export const LOGIN_REQUEST = "USERS_LOGIN_REQUEST";
export const LOGIN_SUCCESS = "USERS_LOGIN_SUCCESS";
export const LOGIN_FAILURE = "USERS_LOGIN_FAILURE";

export const USER_LOGGED_IN = "USER_LOGGED_IN";

export const LOGOUT = "USERS_LOGOUT";

export const GETALL_REQUEST = "USERS_GETALL_REQUEST";
export const GETALL_SUCCESS = "USERS_GETALL_SUCCESS";
export const GETALL_FAILURE = "USERS_GETALL_FAILURE";

export const DELETE_REQUEST = "USERS_DELETE_REQUEST";
export const DELETE_SUCCESS = "USERS_DELETE_SUCCESS";
export const DELETE_FAILURE = "USERS_DELETE_FAILURE";

export const CREATE_LEAVETYPE = "CREATE_LEAVETYPE";
export const LOAD_LEAVETYPES_SUCCESS = "LOAD_LEAVETYPES_SUCCESS";
export const CREATE_LEAVETYPE_SUCCESS = "CREATE_LEAVETYPE_SUCCESS";
export const UPDATE_LEAVETYPE_SUCCESS = "UPDATE_LEAVETYPE_SUCCESS";
export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const USER_ROLE_SUPPORT = "SUPPORT";
export const USER_ROLE_ADMIN = "ADMIN";
export const USER_ROLE_APPROVER = "APPROVER";
export const USER_ROLE_USER = "USER";
export const LOAD_ESSUSER_SUCCESS = "LOAD_ESSUSER_SUCCESS";

export const LOAD_NOTIFICATION_SUCCESS = "LOAD_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_ALLNOTIFICATION_SUCCESS = "UPDATE_ALLNOTIFICATION_SUCCESS";
export const UPDATE_SELECTED_NOTIFICATION_SUCCESS =
  "UPDATE_SELECTED_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_SELECTED_NOTIFICATION_SUCCESS =
  "DELETE_SELECTED_NOTIFICATION_SUCCESS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
// If it had one, our apiCallsInProgress counter would be decremented below zero
// because we're not incrementing the number of apiCallInProgress when the delete request begins.
export const DELETE_LEAVETYPE_OPTIMISTIC = "DELETE_LEAVETYPE_OPTIMISTIC";

//used for Empy
export const LOAD_EMPY_SUCCESS = "LOAD_EMPY_SUCCESS";
