import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";
import dayjs from "dayjs";
// Will change to this method
//const baseUrl = process.env.API_URL + "/LeaveType/";

const base = API_ROOT + "LeaveType/";

export function getAllLeaveTypes() {
  return axios({
    method: "GET",
    url: base + "GetAllLeaveTypes",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLeaveType(leaveTypeKey) {
  return axios({
    method: "GET",
    url: base + "GetLeaveType",
    headers: authHeader(),
    params: { key: leaveTypeKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLeaveTypeCode(
  year = dayjs().year(),
  userKey = -1,
  empyKey = -1
) {
  return axios({
    method: "GET",
    url: base + "GetLeaveTypeCode",
    headers: authHeader(),
    params: { year: year, userKey: userKey, empyKey: empyKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getDashboardLeaveTypeCode(source, userKey = -1, empyKey = -1) {
  return axios({
    method: "GET",
    url: base + "GetDashboardLeaveTypeCode",
    headers: authHeader(),
    params: { userKey: userKey, empyKey: empyKey },
    cancelToken: source.token,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addLeaveType(leaveType) {
  return axios({
    method: "POST",
    url: base + "AddLeaveType",
    headers: authHeader(),
    data: leaveType,
  })
    .then()
    .catch(handleError);
}

export function updateLeaveType(leaveType) {
  return axios({
    method: "PUT",
    url: base + "UpdateLeaveType",
    headers: authHeader(),
    params: { key: leaveType.LTKey },
    data: leaveType,
  })
    .then()
    .catch(handleError);
}

export function deleteLeaveType(leaveTypeKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteLeaveType",
    headers: authHeader(),
    params: { key: leaveTypeKey },
  })
    .then()
    .catch(handleError);
}
