import React, { useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { withRouter } from "../../customHooks/withRouter";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../redux/actions/notificationAction";
import signalRService from "../../service/signalRService";
import PropTypes from "prop-types";

//Pages
import HeaderNav from "../../containers/HeaderNav/HeaderNav";
import DashboardPage from "../../components/pages/DashboardPage";
import ReportPage from "./Report/ReportPage";
import ReportDisplayPage from "./Report/ReportDisplayPage";
import NotificationPage from "../../components/pages/NotificationPage";
import LeaveTypesPage from "./Master/LeaveTypesPage";
import ClinicsPage from "./Master/ClinicsPage";
import ManageLeaveTypePage from "./Master/ManageLeaveTypePage";
import RoleAssignmentPage from "./Admin/RoleAssignmentPage";
import ManageRoleAssignmentPage from "./Admin/ManageRoleAssignmentPage";
import LeaveAdjustmentPage from "./Admin/LeaveAdjustmentPage";
import ManageLeaveAdjustmentPage from "./Admin/ManageLeaveAdjustmentPage";
import LeaveApplicationDisplayPage from "./Leave/LeaveApplicationDisplayPage";
import LeaveApplicationDisplayAllPage from "./Leave/LeaveApplicationDisplayAllPage";
import LeaveApplicationApprovalPage from "./Leave/LeaveApplicationApprovalPage";
import LeaveApplicationVoidPage from "./Leave/LeaveApplicationVoidPage";
import LeaveApplicationCancellationPage from "./Leave/LeaveApplicationCancellationPage";
import ManageClinicPage from "./Master/ManageClinicPage";
import ClinicVisitDisplayPage from "./Clinic/ClinicVisitDisplayPage";
import ClinicVisitApprovalPage from "./Clinic/ClinicVisitApprovalPage";
import ClinicVisitVoidPage from "./Clinic/ClinicVisitVoidPage";
import ClinicVisitCancellationPage from "./Clinic/ClinicVisitCancellationPage";
import MedicalDeductionPage from "./Clinic/MedicalDeductionPage";
import ManageLeaveApplicationPage from "./Leave/ManageLeaveApplicationPage";
import ManageClinicVisitPage from "./Clinic/ManageClinicVisitPage";
import ClaimSubmissionPage from "./Claim/ClaimSubmissionPage";
import ManageClaimSubmissionPage from "./Claim/ManageClaimSubmissionPage";
import ClaimTypesPage from "./Master/ClaimTypesPage";
import ManageClaimTypePage from "./Master/ManageClaimTypePage";
import SettingsPage from "./Master/SettingsPage";
import HolidayProfilePage from "./Master/HolidayProfilePage";
import ManageHolidayProfilePage from "./Master/ManageHolidayProfilePage";
import ClaimSubmissionApprovalPage from "./Claim/ClaimSubmissionApprovalPage";
import APRLvlConfgPage from "./Master/APRLvlConfgPage";
import ManageAPRLvlConfgPage from "./Master/ManageAPRLvlConfgPage";
import ApprovedClaimVoidPage from "./Claim/ApprovedClaimVoidPage";
import APRConfgPage from "./Master/APRConfgPage";
import ManageAPRConfgPage from "./Master/ManageAPRConfgPage";
import EmployeeApprovalPage from "./Profile/EmployeeProfileApprovalPage";
import ClaimCancellationApprovalPage from "./Claim/ClaimCancellationApprovalPage";
import PublishControlPage from "./Admin/PublishControlPage";
import ManageTravelAuthorizationPage from "./Claim/Travel/ManageTravelAuthorizationPage";
import TravelAuthorizationApprovalPage from "./Claim/Travel/TravelAuthorizationApprovalPage";
import TravelAuthorizationCancellationPage from "./Claim/Travel/TravelAuthorizationCancellationPage";
import TravelAuthorizationDisplayPage from "./Claim/Travel/TravelAuthorizationDisplayPage";
import TravelAuthorizationDisplayAllPage from "./Claim/Travel/TravelAuthorizationDisplayAllPage";
import TravelAuthorizationValidationPage from "./Claim/Travel/TravelAuthorizationValidationPage";
import TravelAuthorizationVoidPage from "./Claim/Travel/TravelAuthorizationVoidPage";
import VehiclesPage from "./Master/VehiclesPage";
import ManageVehiclePage from "./Master/ManageVehiclePage";
import VehicleRequisitionPage from "./VehicleReq/VehicleRequisitionPage";
import ManageVehicleRequisitionPage from "./VehicleReq/ManageVehicleRequisitionPage";
import VehicleRequisitionApprovalPage from "./VehicleReq/VehicleRequisitionApprovalPage";
import ApprovedVehicleRequisitionVoidPage from "./VehicleReq/ApprovedVehicleRequisitionVoidPage";
import VehicleRequisitionCancellationApprovalPage from "./VehicleReq/VehicleRequisitionCancellationApprovalPage";
import VehicleRequisitionAssignmentPage from "./VehicleReq/VehicleRequisitionAssignmentPage";
import VehicleAvailabilityDisplayPage from "./VehicleReq/VehicleAvailabilityDisplayPage";
import RoomsPage from "./Master/RoomsPage";
import ManageRoomPage from "./Master/ManageRoomPage";
import RoomRequisitionPage from "./RoomReq/RoomRequisitionPage";
import ManageRoomRequisitionPage from "./RoomReq/ManageRoomRequisitionPage";
import RoomRequisitionApprovalPage from "./RoomReq/RoomRequisitionApprovalPage";
import ApprovedRoomRequisitionVoidPage from "./RoomReq/ApprovedRoomRequisitionVoidPage";
import RoomRequisitionCancellationApprovalPage from "./RoomReq/RoomRequisitionCancellationApprovalPage";
import RoomRequisitionAssignmentPage from "./RoomReq/RoomRequisitionAssignmentPage";
import RoomAvailabilityDisplayPage from "./RoomReq/RoomAvailabilityDisplayPage";
import PropMaintPage from "./PropMaint/PropMaintPage";
import ManagePropMaintPage from "./PropMaint/ManagePropMaintPage";
import PropMaintApprovalPage from "./PropMaint/PropMaintApprovalPage";
import ApprovedPropMaintVoidPage from "./PropMaint/ApprovedPropMaintVoidPage";
import PropMaintCancellationApprovalPage from "./PropMaint/PropMaintCancellationApprovalPage";
import PropMaintValidationPage from "./PropMaint/PropMaintValidationPage";
import TrainingsPage from "./Master/TrainingsPage";
import ManageTrainingPage from "./Master/ManageTrainingPage";
import TrainingsCalendarPage from "./Training/TrainingsCalendarPage";
import TrainingApplicationsPage from "./Training/TrainingApplicationsPage";
import ManageTrainingApplicationPage from "./Training/ManageTrainingApplicationPage";
import TrainingApplicationApprovalPage from "./Training/TrainingApplicationApprovalPage";
import ApprovedTrainingApplicationVoidPage from "./Training/ApprovedTrainingApplicationVoidPage";
import TrainingApplicationCancellationApprovalPage from "./Training/TrainingApplicationCancellationApprovalPage";
import ClaimMigrationPage from "./Claim/ClaimMigrationPage";
import LeaveMigrationPage from "./Leave/LeaveMigrationPage";
import ProjectsPage from "./Master/ProjectsPage";
import ManageProjectPage from "./Master/ManageProjectPage";
import EmployeePage from "./Profile/EmployeePage";
import ClaimValidationPage from "./Claim/ClaimValidationPage";
import ClinicValidationPage from "./Clinic/ClinicValidationPage";
import LeaveValidationPage from "./Leave/LeaveValidationPage";
import ExRatePage from "./Master/ExRatePage";
import RoleTypesPage from "./Master/RoleTypesPage";
import ManageRoleTypesPage from "./Master/ManageRoleTypesPage";
import RoleTypesPermissionPage from "./Master/RoleTypesPermissionPage";
import ManageRoleTypesPermissionByFormPage from "./Master/ManageRoleTypesPermissionByFormPage";
import ManageRoleTypesPermissionByRolePage from "./Master/ManageRoleTypesPermissionByRolePage";
import KnowledgeBasePage from "./KnowledgeBasePage";
import AnnouncementsPage from "./Master/AnnouncementsPage";
import ManageAnnouncementPage from "./Master/ManageAnnouncementPage";
import AnnouncementDisplayPage from "./AnnouncementDisplayPage";
import OrgChartPage from "./OrgChart/OrgChartPage";
import RestDayProfilePage from "./Master/RestDayProfilePage";
import ManageRestDayProfilePage from "./Master/ManageRestDayProfilePage";
import AppraisalTemplatePage from "./Master/AppraisalTemplatePage";
import ManageAppraisalTemplatePage from "./Master/ManageAppraisalTemplatePage";
import AppraisalPage from "./Master/AppraisalPage";
import ManageAppraisalPage from "./Master/ManageAppraisalPage";
import AppraisalSubmissionDisplayPage from "./Appraisal/AppraisalSubmissionDisplayPage";
import ManageAppraisalSubmissionPage from "./Appraisal/ManageAppraisalSubmissionPage";
import AppraisalSubmissionApprovalPage from "./Appraisal/AppraisalSubmissionApprovalPage";
import AppraisalRatingPage from "./Master/AppraisalRatingPage";
import ManageAppraisalRatingPage from "./Master/ManageAppraisalRatingPage";
import JobRecruitmentPage from "./JobRecruitment/JobRecruitmentPage";
import ManageJobRecruitmentPage from "./JobRecruitment/ManageJobRecruitmentPage";
import JobRecruitmentApprovalPage from "./JobRecruitment/JobRecruitmentApprovalPage";
import ApprovedJobRecruitmentVoidPage from "./JobRecruitment/ApprovedJobRecruitmentVoidPage";
import JobRecruitmentCancellationApprovalPage from "./JobRecruitment/JobRecruitmentCancellationApprovalPage";
import JobRecruitmentClosurePage from "./JobRecruitment/JobRecruitmentClosurePage";
import DocumentManagerPage from "./Master/DocumentManagerPage";
import LeaveAdminPage from "./AdminPortal/LeaveAdminPage";
import ClaimAdminPage from "./AdminPortal/ClaimAdminPage";
import AppraisalAdminPage from "./AdminPortal/AppraisalAdminPage";
import JobRecruitmentAdminPage from "./AdminPortal/JobRecruitmentAdminPage";
import TravelAdminPage from "./AdminPortal/TravelAdminPage";
import ClinicVisitAdminPage from "./AdminPortal/ClinicVisitAdminPage";
import TrainingAdminPage from "./AdminPortal/TrainingAdminPage";
import VehicleReqAdminPage from "./AdminPortal/VehicleReqAdminPage";
import RoomReqAdminPage from "./AdminPortal/RoomReqAdminPage";
import PropertiesAdminPage from "./AdminPortal/PropertiesAdminPage";
import NotificationConfgPage from "./AdminPortal/NotificationConfgPage";
import ManageNotifConfgPage from "./AdminPortal/ManageNotificationConfgPage";

const HomePage = ({ notifications, actions }) => {
  const history = useNavigate();
  const [isDirty, setIsDirty] = useState(false);

  signalRService(history, notifications, actions);

  return (
    <Routes>
      <Route
        path={"/"}
        element={
          <HeaderNav
            history={history}
            isDirty={isDirty}
            setIsDirty={setIsDirty}
          />
        }
      >
        <Route index element={<DashboardPage history={history} />} />
        <Route
          index
          path={"dashboard"}
          element={<DashboardPage history={history} />}
        />
        <Route
          path={"report"}
          exact
          element={<ReportPage history={history} />}
        />
        <Route
          path={"report/:ReportName"}
          exact
          element={<ReportDisplayPage history={history} />}
        />
        <Route
          path={"profile/"}
          exact
          element={<EmployeePage history={history} setIsDirty={setIsDirty} />}
        />
        <Route
          path={"notification"}
          exact
          element={<NotificationPage history={history} />}
        />
        <Route
          path={"leavetypes"}
          exact
          element={<LeaveTypesPage history={history} />}
        />
        <Route
          path={"leavetypes/leavetype/:LTKey"}
          element={
            <ManageLeaveTypePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"leavetypes/leavetype"}
          element={
            <ManageLeaveTypePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"clinics"}
          exact
          element={<ClinicsPage history={history} />}
        />
        <Route
          path={"clinics/clinic/:ClinicKey"}
          element={
            <ManageClinicPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"clinics/clinic"}
          element={
            <ManageClinicPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"medicaldeduction"}
          exact
          element={<MedicalDeductionPage history={history} />}
        />
        <Route
          path={"leaveapplicationdisplayall"}
          exact
          element={<LeaveApplicationDisplayAllPage history={history} />}
        />
        <Route
          path={"leaveapplicationdisplay"}
          exact
          element={<LeaveApplicationDisplayPage history={history} />}
        />
        <Route
          path={"leaveapplication/leavetrans/:LeaveTransKey"}
          exact
          element={
            <ManageLeaveApplicationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"leaveapplication/leavetrans"}
          exact
          element={
            <ManageLeaveApplicationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"leaveapplicationapproval"}
          exact
          element={<LeaveApplicationApprovalPage history={history} />}
        />
        <Route
          path={"leaveapplicationvoid"}
          exact
          element={<LeaveApplicationVoidPage history={history} />}
        />
        <Route
          path={"leaveapplicationcancel"}
          exact
          element={<LeaveApplicationCancellationPage history={history} />}
        />
        <Route
          path={"leavevalidation"}
          exact
          element={<LeaveValidationPage history={history} />}
        />
        <Route
          path={"leavemigration"}
          exact
          element={<LeaveMigrationPage history={history} />}
        />
        <Route
          path={"clinicvisit/clinictrans/:ClinicTransKey"}
          exact
          element={
            <ManageClinicVisitPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"clinicvisit/clinictrans"}
          exact
          element={
            <ManageClinicVisitPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"clinicvisitdisplay"}
          exact
          element={<ClinicVisitDisplayPage history={history} />}
        />
        <Route
          path={"clinicvisitapproval"}
          exact
          element={<ClinicVisitApprovalPage history={history} />}
        />
        <Route
          path={"clinicvisitvoid"}
          exact
          element={<ClinicVisitVoidPage history={history} />}
        />
        <Route
          path={"clinicvisitcancel"}
          exact
          element={<ClinicVisitCancellationPage history={history} />}
        />
        <Route
          path={"clinicvalidation"}
          exact
          element={<ClinicValidationPage history={history} />}
        />
        <Route
          path={"claimtypes"}
          exact
          element={<ClaimTypesPage history={history} />}
        />
        <Route
          path={"claimtypes/claimtype/:ClaimTypeKey"}
          exact
          element={
            <ManageClaimTypePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"claimtypes/claimtype"}
          exact
          element={
            <ManageClaimTypePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"claimsubmissiondisplay"}
          exact
          element={<ClaimSubmissionPage history={history} />}
        />
        <Route
          path={"claimsubmission/claimTrans/:ClaimTransKey"}
          exact
          element={
            <ManageClaimSubmissionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"claimsubmission/claimTrans"}
          exact
          element={
            <ManageClaimSubmissionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"claimsubmissionapproval"}
          exact
          element={<ClaimSubmissionApprovalPage history={history} />}
        />
        <Route
          path={"claimvoid"}
          exact
          element={<ApprovedClaimVoidPage history={history} />}
        />
        <Route
          path={"claimcancellationapproval"}
          exact
          element={<ClaimCancellationApprovalPage history={history} />}
        />
        <Route
          path={"claimvalidation"}
          exact
          element={<ClaimValidationPage history={history} />}
        />
        <Route
          path={"claimmigration"}
          exact
          element={<ClaimMigrationPage history={history} />}
        />
        <Route
          path={"roletypes"}
          exact
          element={<RoleTypesPage history={history} />}
        />
        <Route
          path={"roletypes/roletype/:RoleTypeKey"}
          exact
          element={
            <ManageRoleTypesPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"roletypes/roletype"}
          exact
          element={
            <ManageRoleTypesPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"roleassignments"}
          exact
          element={<RoleAssignmentPage history={history} />}
        />
        <Route
          path={"roleassignments/roleassignment/:UserKey"}
          exact
          element={
            <ManageRoleAssignmentPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"leaveadjustments"}
          exact
          element={<LeaveAdjustmentPage history={history} />}
        />
        <Route
          path={"leaveadjustments/:UserKey"}
          exact
          element={
            <ManageLeaveAdjustmentPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"holiday"}
          exact
          element={<HolidayProfilePage history={history} />}
        />
        <Route
          path={"holiday/holidayprofile/:ProfileKey"}
          exact
          element={
            <ManageHolidayProfilePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"holiday/holidayprofile"}
          exact
          element={
            <ManageHolidayProfilePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"aprlvlconfgs"}
          exact
          element={<APRLvlConfgPage history={history} />}
        />
        <Route
          path={"aprlvlconfgs/aprlvlconfg/:SubjectKey"}
          exact
          element={
            <ManageAPRLvlConfgPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"aprlvlconfgs/aprlvlconfg"}
          exact
          element={
            <ManageAPRLvlConfgPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"settings"}
          exact
          element={<SettingsPage history={history} setIsDirty={setIsDirty} />}
        />
        <Route
          path={"aprconfgs"}
          exact
          element={<APRConfgPage history={history} />}
        />
        <Route
          path={"aprconfgs/aprconfg/:UserKey"}
          exact
          element={
            <ManageAPRConfgPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"aprconfgs/aprconfg"}
          exact
          element={
            <ManageAPRConfgPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"empyapr"}
          exact
          element={<EmployeeApprovalPage history={history} />}
        />
        <Route
          path={"projects"}
          exact
          element={<ProjectsPage history={history} />}
        />
        <Route
          path={"projects/project/:ProjKey"}
          exact
          element={
            <ManageProjectPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"projects/project"}
          exact
          element={
            <ManageProjectPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"exrate"}
          exact
          element={
            <ExRatePage
              history={history}
              setIsDirty={setIsDirty}
              isDirty={isDirty}
            />
          }
        />
        <Route
          path={"publishcontrol"}
          exact
          element={<PublishControlPage history={history} />}
        />
        <Route
          path={"roletypes"}
          exact
          element={<RoleTypesPage history={history} />}
        />
        <Route
          path={"roletypes/roletype/:RoleTypeKey"}
          exact
          element={
            <ManageRoleTypesPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"roletypes/roletype"}
          exact
          element={
            <ManageRoleTypesPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"roletypesperm"}
          exact
          element={<RoleTypesPermissionPage history={history} />}
        />
        <Route
          path={"roletypesperm/roletypespermbyform/:MenuKey"}
          exact
          element={
            <ManageRoleTypesPermissionByFormPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"roletypesperm/roletypespermbyform"}
          exact
          element={
            <ManageRoleTypesPermissionByFormPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"roletypesperm/roletypespermbyrole/:RoleKey"}
          exact
          element={
            <ManageRoleTypesPermissionByRolePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"roletypesperm/roletypespermbyrole"}
          exact
          element={
            <ManageRoleTypesPermissionByRolePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"knowledgebase"}
          exact
          element={<KnowledgeBasePage history={history} />}
        />
        <Route
          path={"announcements"}
          exact
          element={<AnnouncementsPage history={history} />}
        />
        <Route
          path={"announcements/announcement/:AnnouncementKey"}
          exact
          element={
            <ManageAnnouncementPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"announcements/announcement"}
          exact
          element={
            <ManageAnnouncementPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"announcementdisplay/:AnnouncementKey"}
          exact
          element={<AnnouncementDisplayPage history={history} />}
        />
        <Route
          path={"travelauth/travelTrans/:TravelTransHdrKey"}
          exact
          element={
            <ManageTravelAuthorizationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"travelauth/travelTrans"}
          exact
          element={
            <ManageTravelAuthorizationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"travelauthapproval"}
          exact
          element={<TravelAuthorizationApprovalPage history={history} />}
        />
        <Route
          path={"travelauthcancellationapproval"}
          exact
          element={<TravelAuthorizationCancellationPage history={history} />}
        />
        <Route
          path={"travelauthdisplay"}
          exact
          element={<TravelAuthorizationDisplayPage history={history} />}
        />
        <Route
          path={"travelauthdisplayall"}
          exact
          element={<TravelAuthorizationDisplayAllPage history={history} />}
        />
        <Route
          path={"travelauthvalidation"}
          exact
          element={<TravelAuthorizationValidationPage history={history} />}
        />
        <Route
          path={"travelauthvoid"}
          exact
          element={<TravelAuthorizationVoidPage history={history} />}
        />
        <Route
          path={"restdayprofiles"}
          exact
          element={<RestDayProfilePage history={history} />}
        />
        <Route
          path={"restdayprofiles/restdayprofile/:UserKey"}
          exact
          element={
            <ManageRestDayProfilePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"restdayprofiles/restdayprofile"}
          exact
          element={
            <ManageRestDayProfilePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"docmanager"}
          exact
          element={<DocumentManagerPage history={history} />}
        />
        <Route
          path={"orgchart"}
          exact
          element={<OrgChartPage history={history} />}
        />
        <Route
          path={"appraisaltemplates"}
          exact
          element={<AppraisalTemplatePage history={history} />}
        />
        <Route
          path={"appraisaltemplates/appraisaltemplate/:ATKey"}
          exact
          element={
            <ManageAppraisalTemplatePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"appraisaltemplates/appraisaltemplate"}
          exact
          element={
            <ManageAppraisalTemplatePage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"vehicles"}
          exact
          element={<VehiclesPage history={history} />}
        />
        <Route
          path={"vehicles/vehicle/:VehicleKey"}
          exact
          element={
            <ManageVehiclePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"vehicles/vehicle"}
          exact
          element={
            <ManageVehiclePage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"vehiclerequisitiondisplay"}
          exact
          element={<VehicleRequisitionPage history={history} />}
        />
        <Route
          path={"vehiclerequisition/vehicletrans/:VehicleRequisitionKey"}
          exact
          element={
            <ManageVehicleRequisitionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"vehiclerequisition/vehicletrans"}
          exact
          element={
            <ManageVehicleRequisitionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"vehiclerequisitionapproval"}
          exact
          element={<VehicleRequisitionApprovalPage history={history} />}
        />
        <Route
          path={"vehiclerequisitionvoid"}
          exact
          element={<ApprovedVehicleRequisitionVoidPage history={history} />}
        />
        <Route
          path={"vehiclerequisitioncancellationapproval"}
          exact
          element={
            <VehicleRequisitionCancellationApprovalPage history={history} />
          }
        />
        <Route
          path={"vehicleassignment"}
          exact
          element={<VehicleRequisitionAssignmentPage history={history} />}
        />
        <Route
          path={"vehicleavailabilitydisplay"}
          exact
          element={<VehicleAvailabilityDisplayPage history={history} />}
        />
        <Route
          path={"meetingrooms"}
          exact
          element={<RoomsPage history={history} />}
        />
        <Route
          path={"meetingrooms/room/:RoomKey"}
          exact
          element={<ManageRoomPage history={history} setIsDirty={setIsDirty} />}
        />
        <Route
          path={"meetingrooms/room"}
          exact
          element={<ManageRoomPage history={history} setIsDirty={setIsDirty} />}
        />
        <Route
          path={"meetingroomrequisitiondisplay"}
          exact
          element={<RoomRequisitionPage history={history} />}
        />
        <Route
          path={"meetingroomrequisition/roomtrans/:RoomRequisitionKey"}
          exact
          element={
            <ManageRoomRequisitionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"meetingroomrequisition/roomtrans"}
          exact
          element={
            <ManageRoomRequisitionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"meetingroomrequisitionapproval"}
          exact
          element={<RoomRequisitionApprovalPage history={history} />}
        />
        <Route
          path={"meetingroomrequisitionvoid"}
          exact
          element={<ApprovedRoomRequisitionVoidPage history={history} />}
        />
        <Route
          path={"meetingroomrequisitioncancellationapproval"}
          exact
          element={
            <RoomRequisitionCancellationApprovalPage history={history} />
          }
        />
        <Route
          path={"meetingroomassignment"}
          exact
          element={<RoomRequisitionAssignmentPage history={history} />}
        />
        <Route
          path={"meetingroomavailabilitydisplay"}
          exact
          element={<RoomAvailabilityDisplayPage history={history} />}
        />
        <Route
          path={"appraisals"}
          exact
          element={<AppraisalPage history={history} />}
        />
        <Route
          path={"appraisals/appraisal/:APKey"}
          exact
          element={
            <ManageAppraisalPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"appraisals/appraisal"}
          exact
          element={
            <ManageAppraisalPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"propmaintdisplay"}
          exact
          element={<PropMaintPage history={history} />}
        />
        <Route
          path={"propmaint/proptrans/:PropMaintKey"}
          exact
          element={
            <ManagePropMaintPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"propmaint/proptrans"}
          exact
          element={
            <ManagePropMaintPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"propmaintapproval"}
          exact
          element={<PropMaintApprovalPage history={history} />}
        />
        <Route
          path={"propmaintvoid"}
          exact
          element={<ApprovedPropMaintVoidPage history={history} />}
        />
        <Route
          path={"propmaintcancellationapproval"}
          exact
          element={<PropMaintCancellationApprovalPage history={history} />}
        />
        <Route
          path={"propmaintvalidation"}
          exact
          element={<PropMaintValidationPage history={history} />}
        />
        <Route
          path={"appraisalsubmission/appraisaltrans/:AppraisalTransHdrKey"}
          exact
          element={
            <ManageAppraisalSubmissionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"appraisalsubmission/appraisaltrans"}
          exact
          element={
            <ManageAppraisalSubmissionPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"appraisalsubmissiondisplay"}
          exact
          element={<AppraisalSubmissionDisplayPage history={history} />}
        />
        <Route
          path={"appraisalsubmissionapproval"}
          exact
          element={<AppraisalSubmissionApprovalPage history={history} />}
        />
        <Route
          path={"appraisalratings"}
          exact
          element={<AppraisalRatingPage history={history} />}
        />
        <Route
          path={"appraisalratings/appraisalrating/:ARKey"}
          exact
          element={
            <ManageAppraisalRatingPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"appraisalratings/appraisalrating"}
          exact
          element={
            <ManageAppraisalRatingPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"jobrecruitmentdisplay"}
          exact
          element={<JobRecruitmentPage history={history} />}
        />
        <Route
          path={"jobrecruitment/jobrecruitments/:JobRecruitmentKey"}
          exact
          element={
            <ManageJobRecruitmentPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"jobrecruitment/jobrecruitments"}
          exact
          element={
            <ManageJobRecruitmentPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"jobrecruitmentapproval"}
          exact
          element={<JobRecruitmentApprovalPage history={history} />}
        />
        <Route
          path={"jobrecruitmentvoid"}
          exact
          element={<ApprovedJobRecruitmentVoidPage history={history} />}
        />
        <Route
          path={"jobrecruitmentcancellationapproval"}
          exact
          element={<JobRecruitmentCancellationApprovalPage history={history} />}
        />
        <Route
          path={"jobrecruitmentclosure"}
          exact
          element={<JobRecruitmentClosurePage history={history} />}
        />
        <Route
          path={"trainings"}
          exact
          element={<TrainingsPage history={history} />}
        />
        <Route
          path={"trainings/training/:TrainingKey"}
          exact
          element={
            <ManageTrainingPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"trainings/training"}
          exact
          element={
            <ManageTrainingPage history={history} setIsDirty={setIsDirty} />
          }
        />
        <Route
          path={"trainingcalendar"}
          exact
          element={<TrainingsCalendarPage history={history} />}
        />
        <Route
          path={"trainingapplicationdisplay"}
          exact
          element={<TrainingApplicationsPage history={history} />}
        />
        <Route
          path={"trainingapplication/trainingtrans/:TrainingApplicationKey"}
          exact
          element={
            <ManageTrainingApplicationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"trainingapplication/trainingtrans"}
          exact
          element={
            <ManageTrainingApplicationPage
              history={history}
              setIsDirty={setIsDirty}
            />
          }
        />
        <Route
          path={"trainingapplicationapproval"}
          exact
          element={<TrainingApplicationApprovalPage history={history} />}
        />
        <Route
          path={"trainingapplicationvoid"}
          exact
          element={<ApprovedTrainingApplicationVoidPage history={history} />}
        />
        <Route
          path={"trainingapplicationcancellationapproval"}
          exact
          element={
            <TrainingApplicationCancellationApprovalPage history={history} />
          }
        />
        <Route
          path={"adminportal/leave"}
          exact
          element={<LeaveAdminPage history={history} />}
        />
        <Route
          path={"adminportal/claim"}
          exact
          element={<ClaimAdminPage history={history} />}
        />
        <Route
          path={"adminportal/appraisal"}
          exact
          element={<AppraisalAdminPage history={history} />}
        />
        <Route
          path={"adminportal/recruitment"}
          exact
          element={<JobRecruitmentAdminPage history={history} />}
        />
        <Route
          path={"adminportal/travel"}
          exact
          element={<TravelAdminPage history={history} />}
        />
        <Route
          path={"adminportal/clinicvisit"}
          exact
          element={<ClinicVisitAdminPage history={history} />}
        />
        <Route
          path={"adminportal/training"}
          exact
          element={<TrainingAdminPage history={history} />}
        />
        <Route
          path={"adminportal/facilities/vehicledisplay"}
          exact
          element={<VehicleReqAdminPage history={history} />}
        />
        <Route
          path={"adminportal/facilities/meetingroomdisplay"}
          exact
          element={<RoomReqAdminPage history={history} />}
        />
        <Route
          path={"adminportal/facilities/propertiesdisplay"}
          exact
          element={<PropertiesAdminPage history={history} />}
        />
        <Route
          path={"adminportal/settings/notification"}
          exact
          element={<NotificationConfgPage history={history} />}
        />
        <Route
          path={"adminportal/settings/notification/:UserKey"}
          exact
          element={<ManageNotifConfgPage history={history} setIsDirty={setIsDirty}/>}
        />
      </Route>
    </Routes>
  );
};

HomePage.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadNotifications: bindActionCreators(
        notificationActions.loadNotifications,
        dispatch
      ),
      addNotification: bindActionCreators(
        notificationActions.addNotification,
        dispatch
      ),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HomePage));
