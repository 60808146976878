import StaffEAform from "./StaffEAform";
import StaffPayslip from "./StaffPayslip";
import StaffClaim from "./StaffClaim";
import StaffClaimDetails from "./StaffClaimDetails";
import StaffLeaveConsumption from "./StaffLeaveConsumption";
import StaffLeaveEntitlement from "./StaffLeaveEntitlement";
import StaffLeaveEntitlementDetails from "./StaffLeaveEntitlementDetails";
import StaffClaimEntitlement from "./StaffClaimEntitlement";
import VehicleRequisition from "./VehicleRequisition";
import MeetingRoomRequisition from "./MeetingRoomRequisition";
import AppraisalDetails from "./AppraisalDetails";
import TrainingParticipants from "./TrainingParticipants";
import EmployeeTrainings from "./EmployeeTrainings";
import PropertiesMaintenance from "./PropertiesMaintenance";
import JobRecruitmentDetails from "./JobRecruitmentDetails";
import TravelAuthorizationDetails from "./TravelAuthorizationDetails";
import MedicalLimitDeductionDetails from "./MedicalLimitDeductionDetails";
import TravelClaim from "./TravelClaim";

export const combineReport = {
  StaffEAform,
  StaffPayslip,
  StaffClaim,
  StaffClaimDetails,
  TravelClaim,
  StaffLeaveConsumption,
  StaffLeaveEntitlement,
  StaffLeaveEntitlementDetails,
  StaffClaimEntitlement,
  VehicleRequisition,
  MeetingRoomRequisition,
  AppraisalDetails,
  TrainingParticipants,
  EmployeeTrainings,
  PropertiesMaintenance,
  JobRecruitmentDetails,
  TravelAuthorizationDetails,
  MedicalLimitDeductionDetails,
};
