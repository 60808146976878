import React, { useState, useEffect } from "react";
import * as aprLvlConfgApi from "../../../api/aprLvlConfgApi";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { APRLvlConfgForm } from "../../forms/Master/APRLvlConfgForm";
import { useTranslation } from "react-i18next";

export default function APRLvlConfgPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [aprLvlConfgs, setAprLvlConfgs] = useState([]);
  const [rowSelected, setRowSelected] = useState({});

  useEffect(getItems, []);

  function getItems() {
    aprLvlConfgApi
      .GetAPRLvlConfg()
      .then((result) => {
        setIsLoading(false);
        setAprLvlConfgs(result);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function editItem(subjectKey) {
    history("/home/aprlvlconfgs/aprlvlconfg/" + subjectKey);
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    setRowSelected(value);
  }

  return (
    <APRLvlConfgForm
      isLoading={isLoading}
      aprLvlConfgs={aprLvlConfgs}
      onEdit={editItem}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
    />
  );
}

APRLvlConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
};
