import React from "react";
import { Modal, InputNumber, Row, Col, Input, Form } from "antd";
import PropTypes from "prop-types";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const ManageAppraisalRatingDetailForm = ({
  isVisible,
  rating,
  appraisalRating,
  decimalPoint,
  onCancel,
  isNewRating,
  onSave,
  form,
}) => {
  const { t } = useTranslation();
  let tempMinValue = 0;
  let tempMaxValue = 0;

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let ratingSubmit = {
          ...rating,
          ...{
            ARDetDesc: values.ARDetDesc ? values.ARDetDesc : "",
            ARDetMaxRange: values.ARDetMaxRange ? values.ARDetMaxRange : 0,
            ARDetMinRange: tempMinValue,
          },
        };

        onSave(ratingSubmit);
      })
      .catch(() => {});
  }

  function getDefaultRating(minMax, value) {
    const index = appraisalRating.AppraisalRatingDet.findIndex(
      (item) => rating.ARDetKey === item.ARDetKey
    );

    let itemMin = appraisalRating.AppraisalRatingDet.at(index);
    let itemMax = 0;

    if (isNewRating) {
      if (minMax === 0) {
        tempMinValue =
          itemMin !== undefined
            ? itemMin.ARDetMaxRange + getDefaultDecimal(decimalPoint)
            : value;
      }
      return itemMin !== undefined
        ? itemMin.ARDetMaxRange + getDefaultDecimal(decimalPoint)
        : value;
    } else {
      itemMin = appraisalRating.AppraisalRatingDet.at(index - 1);
      itemMax = appraisalRating.AppraisalRatingDet.at(index + 1);

      if (minMax === 0) {
        tempMinValue =
          index === 0
            ? 0
            : itemMin !== undefined
            ? itemMin.ARDetMaxRange + getDefaultDecimal(decimalPoint)
            : value;
        tempMaxValue =
          itemMax !== undefined
            ? itemMax.ARDetMaxRange - getDefaultDecimal(decimalPoint)
            : 0;
        return index === 0
          ? 0
          : itemMin !== undefined
          ? itemMin.ARDetMaxRange + getDefaultDecimal(decimalPoint)
          : value;
      }
      return value;
    }
  }

  function getDefaultDecimal(decimal) {
    if (decimal === 0) {
      return 1;
    } else if (decimal === 1) {
      return 0.1;
    } else if (decimal === 2) {
      return 0.01;
    }
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">{t("appraisalRatingForm.rating")}</span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("general.description")}
          name="ARDetDesc"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("general.description_required_validation"),
            },
            {
              max: 500,
              message: t("general.description_max_length_500_validation"),
            },
          ]}
          initialValue={rating.ARDetDesc}
        >
          <Input placeholder={t("general.description_placeholder")} />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item label={t("appraisalRatingForm.rating_range")} noStyle>
              <Form.Item
                name="ARDetMinRange"
                initialValue={getDefaultRating(0, rating.ARDetMinRange)}
                noStyle
              >
                <InputNumber
                  disabled
                  precision={decimalPoint}
                  formatter={inputLocaleFormatter(decimalPoint)}
                  parser={inputLocaleParser(decimalPoint)}
                />
              </Form.Item>
              {" - "}
              <Form.Item
                name="ARDetMaxRange"
                rules={[
                  {
                    required: true,
                    message: t(
                      "appraisalRatingForm.rating_range_required_validation"
                    ),
                  },
                ]}
                initialValue={getDefaultRating(1, rating.ARDetMaxRange)}
                noStyle
              >
                <InputNumber
                  min={tempMinValue}
                  max={tempMaxValue !== 0 ? tempMaxValue : Infinity}
                  step={
                    decimalPoint === 0
                      ? "1"
                      : decimalPoint === 1
                      ? "0.1"
                      : "0.01"
                  }
                  precision={decimalPoint}
                  formatter={inputLocaleFormatter(decimalPoint)}
                  parser={inputLocaleParser(decimalPoint)}
                />
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ManageAppraisalRatingDetailForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  rating: PropTypes.object.isRequired,
  appraisalRating: PropTypes.object.isRequired,
  decimalPoint: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isNewRating: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageAppraisalRatingDetailForm;
