import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Row, Col, Checkbox } from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import "../../../App.css";
import { EmpyApprovalList } from "./EmpyApprovalList";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { useTranslation } from "react-i18next";

export default function EmployeeProfileApprovalForm({ ...props }) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [tempItem, setTempItem] = useState();

  function submitRejectReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //reject single item
      props.onRejected([tempItem], reason);
    } else {
      //reject multiple items
      props.onRejected(props.selectedDetails, reason);
    }
  }

  function rejectEmpy(selectedEmpy) {
    setTempItem(selectedEmpy);
    setShowReasonForm(true);
  }

  function rejectEmpys() {
    setTempItem(null);
    setShowReasonForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="default"
          size="default"
          style={{ marginRight: 10 }}
          onClick={() => rejectEmpy(item)}
        >
          {t("general.reject")}
        </Button>
        <Button type="primary" onClick={() => props.onApproved([item])}>
          {t("general.approve")}
        </Button>
      </div>,
    ];
  }

  function selectAllItems(isSelected) {
    if (props.onItemSelected) {
      if (isSelected) {
        props.onItemSelected([...props.data]);
        props.onDetailsSelected([...props.initialData]);
      } else {
        props.onItemSelected([]);
        props.onDetailsSelected([]);
      }
    }
  }

  const footerElement = () => (
    <Row>
      <Col span={6} style={{ paddingTop: 5, paddingLeft: 10 }}>
        <Checkbox
          indeterminate={
            (props.selectedItems.length &&
              props.selectedItems.length < props.data.length) ||
            (props.selectedDetails.length &&
              props.selectedDetails.length < props.initialData.length)
          }
          checked={
            props.selectedItems.length === props.data.length ||
            props.selectedDetails.length === props.initialData.length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={18} style={{ textAlign: "right" }}>
        <Button
          type="default"
          style={{ marginRight: 5, marginLeft: 10 }}
          disabled={props.selectedDetails.length === 0 || props.isLoading}
          onClick={rejectEmpys}
        >
          {t("general.reject")}
        </Button>
        <Button
          type="primary"
          disabled={props.selectedDetails.length === 0 || props.isLoading}
          onClick={() => props.onApproved(props.selectedDetails)}
        >
          {t("general.approve")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <EmpyApprovalList
        isLoading={props.isLoading}
        data={props.data}
        initialData={props.initialData}
        selectedDetails={props.selectedDetails}
        onDetailsSelected={props.onDetailsSelected}
        isSelectable={true}
        selectedItems={props.selectedItems}
        onItemSelected={props.onItemSelected}
        onListActionsRendered={getListActions}
        footerElement={footerElement}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Reject"}
        onSaved={submitRejectReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
}

EmployeeProfileApprovalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  initialData: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  selectedDetails: PropTypes.array.isRequired,
  onApproved: PropTypes.func.isRequired,
  onRejected: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  onDetailsSelected: PropTypes.func.isRequired,
};
