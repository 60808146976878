import React, { useState } from "react";
import {
  ActiveCheckbox,
  EditButton,
  DeleteButton,
} from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const AppraisalTemplateForm = ({
  isLoading,
  appraisalTemplate,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();

  const AppraisalTemplateActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const [searchText, setSearchText] = useState("");

  const AppraisalTemplateActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display:
            record.RcdType === RecordType.USER && !record.IsInUsed
              ? ""
              : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("appraisalTemplateForm.appraisal_template_code"),
      dataIndex: "ATCode",
      key: "ATCode",
      width: 250,
      sorter: (a, b) => a.ATCode.localeCompare(b.ATCode),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "ATCode",
        searchText,
        handleSearch,
        handleReset,
        "Appraisal Template Code",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "ATDesc",
      key: "ATDesc",
      sorter: (a, b) => a.ATDesc.localeCompare(b.ATDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "ATDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("general.active"),
      dataIndex: "Active",
      key: "Active",
      align: "center",
      width: 100,
      render: AppraisalTemplateActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "ATKey",
      key: "AppraisalTemplateActions",
      align: "center",
      width: 100,
      render: AppraisalTemplateActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">
          {t("appraisalTemplateForm.appraisal_template_setup")}
        </span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          {t("general.new")}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={appraisalTemplate}
          columns={columns}
          size="middle"
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
          rowKey={(record) => record.ATKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

AppraisalTemplateForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisalTemplate: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default AppraisalTemplateForm;
