import React, { useState, useEffect } from "react";
import { getESSUser } from "../../../api/roleAssignmentApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import RoleAssignmentForm from "../../forms/Admin/RoleAssignmentForm";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function RoleAssignmentPage({ history }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [roleAssignments, setRoleAssignments] = useState([]);
  const [filteredRoleAssignments, setFilteredRoleAssignments] = useState([]);
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOuKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );

  useEffect(getItems, []);

  function getItems() {
    let promises = [getESSUser(), getOU(), getDepartment()];

    Promise.all(promises)
      .then((values) => {
        let tempData = values[0]
          ? values[0].filter((x) => x.IsLMStaff === false)
          : [];
        setRoleAssignments(tempData);
        setFilteredRoleAssignments(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey)
          )
        );
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function editItem(userKey) {
    history("/home/roleassignments/roleassignment/" + userKey, {
      state: {
        filterOuKey: ouKey,
        filterDeptKey: deptKey,
        currentPage: currentPage,
      },
    });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function filterRecord(ouKey, deptKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setFilteredRoleAssignments(
      roleAssignments.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey)
      )
    );
  }

  return (
    <RoleAssignmentForm
      isLoading={isLoading}
      roleAssignments={filteredRoleAssignments}
      onEdit={editItem}
      allOus={allOus}
      allDepartments={allDepartments}
      filterRecord={filterRecord}
      ouKey={ouKey}
      deptKey={deptKey}
      form={form}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

RoleAssignmentPage.propTypes = {
  history: PropTypes.func.isRequired,
};
