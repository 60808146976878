import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageRestDayProfileForm from "../../forms/Master/ManageRestDayProfileForm";
import PropTypes from "prop-types";
import RestDayProfileModel from "../../model/Master/RestDayProfileModel";
import {
  getRestDayProfile,
  addRestDayProfile,
  updateRestDayProfile,
} from "../../../api/restDayProfileApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newRestDayProfile = RestDayProfileModel({});

export default function ManageRestDayProfilePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [restDayProfile, setRestDayProfile] = useState(newRestDayProfile);
  const [isLoading, setIsLoading] = useState(true);
  const { UserKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = UserKey;
    if (editKey > 0) {
      promises.push(getRestDayProfile(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setRestDayProfile(values[0] ? values[0] : newRestDayProfile);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [UserKey]);

  function saveRestDayProfile(values) {
    let restDayProfileSubmit = {
      ...restDayProfile,
      ...values,
    };

    let upsertRestDayProfile =
      restDayProfileSubmit.WDKey !== -1
        ? updateRestDayProfile
        : addRestDayProfile;
    setIsLoading(true);
    upsertRestDayProfile(restDayProfileSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/restdayprofiles", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          restDayProfileSubmit.WDKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputRestDayProfile() {
    history("/home/restdayprofiles", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageRestDayProfileForm
      isLoading={isLoading}
      restDayProfile={restDayProfile}
      onSubmitted={saveRestDayProfile}
      onCancelled={cancelInputRestDayProfile}
      onDataChanged={setRestDayProfile}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageRestDayProfilePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
