export default function RoomRequisitionModel({
  roomRequisitionKey = -1,
  roomRequisitionCode = "",
  roomRequisitionDesc = "",
  roomKey = null,
  transDate = new Date(),
  fromReqDate = null,
  toReqDate = null,
  noOfParticipants = 1,
  isIncludeMeal = false,
  expenses = 0,
  remarks = "",
  status = 0,
  statusDesc = "",
  docNum = "Auto No.",
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
}) {
  return Object.freeze({
    RoomRequisitionKey: roomRequisitionKey,
    RoomRequisitionCode: roomRequisitionCode,
    RoomRequisitionDesc: roomRequisitionDesc,
    RoomKey: roomKey,
    TransDate: transDate,
    FromReqDate: fromReqDate,
    ToReqDate: toReqDate,
    NoOfParticipants: noOfParticipants,
    IsIncludeMeal: isIncludeMeal,
    Expenses: expenses,
    Remarks: remarks,
    Status: status,
    StatusDesc: statusDesc,
    DocNum: docNum,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion
  });
}
