import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, Input, DatePicker, Select, Form } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function EmpyChildModal({
  handleSave,
  handleCancel,
  isVisible,
  setIsDirty,
  form,
  child,
  region,
}) {
  const { t } = useTranslation();
  const [isEduFlag, setEduFlag] = useState(false);

  useEffect(() => {
    child.EduLevel === "NA" || child.EduLevel === ""
      ? setEduFlag(false)
      : setEduFlag(true);
  }, [isVisible, child.EduLevel]);

  function setFlag(value) {
    value === "NA" ? setEduFlag(false) : setEduFlag(true);
  }

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let childSubmit = {
          ...child,
          ...values,
        };
        handleSave(childSubmit);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">{t("claimTypesForm.children")}</span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Form.Item
          label={t("general.name")}
          name={"EmpyChildName"}
          rules={[
            {
              required: true,
              message: t("general.name_required_validation"),
            },
            {
              max: 50,
              message: t("general.name_max_length_50_validation"),
            },
          ]}
          initialValue={child.EmpyChildName}
        >
          <Input type="text" placeholder={t("general.name_placeholder")} />
        </Form.Item>

        <Form.Item
          label={t("employeeForm.date_of_birth")}
          name={"EmpyChildDOB"}
          rules={[
            {
              required: true,
              message: t("employeeForm.date_of_birth_required_validation"),
            },
          ]}
          initialValue={dayjs(new Date(child.EmpyChildDOB))}
        >
          <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label={t("employeeForm.education")}
          name="EduLevel"
          rules={[
            {
              required: true,
              message: t("employeeForm.education_required_validation"),
            },
          ]}
          initialValue={child.EduLevel !== "" ? child.EduLevel : undefined}
        >
          <Select showSearch placeholder="Select Education" onChange={setFlag}>
            <Option value="HIGHEDU">High Education</Option>
            <Option value="FORM6">Secondary (Form 6)</Option>
            <Option value="SECEDU">Secondary</Option>
            <Option value="PRIEDU">Primary</Option>
            <Option value="PRESCHL">Preschool</Option>
            <Option value="OTHERS">Others</Option>
            <Option value="NA">Not Available</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label={t("employeeForm.school")}
          name={"SchName"}
          rules={[
            {
              required: isEduFlag,
              message: t("employeeForm.school_required_validation"),
            },
            {
              max: 100,
              message: t("employeeForm.school_max_length_validation"),
            },
          ]}
          initialValue={child.SchName}
        >
          <Input
            type="text"
            placeholder={t("employeeForm.school_placeholder")}
          />
        </Form.Item>

        <Form.Item
          label={
            region === "M"
              ? t("employeeForm.ic_no_passport_no")
              : t("employeeForm.ktp_no")
          }
          name={"IDNo"}
          rules={[
            {
              required: true,
              message:
                region === "M"
                  ? t("employeeForm.ic_no_passport_no_required_validation")
                  : t("employeeForm.ktp_no_required_validation"),
            },
            {
              max: 30,
              message:
                region === "M"
                  ? t("employeeForm.ic_no_passport_no_max_length_validation")
                  : t("employeeForm.ktp_no_max_length_validation"),
            },
          ]}
          initialValue={child.IDNo}
        >
          <Input
            type="text"
            placeholder={
              region === "M"
                ? t("employeeForm.ic_no_passport_no_placeholder")
                : t("employeeForm.ktp_no_placeholder")
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EmpyChildModal.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  child: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
};

export default EmpyChildModal;
