import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";
import CacheBuster from "react-cache-buster";
import { version } from "../package.json";

import "./i18n";

const store = configureStore();

const container = document.getElementById("root");
const root = createRoot(container);
console.log("Latest version : " + version);

root.render(
  <Provider store={store}>
    <Suspense fallback={null}>
      <React.Fragment>
        <CacheBuster
          currentVersion={version}
          isEnabled={true} //If false, the library is disabled.
          isVerboseMode={false} //If true, the library writes verbose logs to console.
          metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
        >
          <App />
        </CacheBuster>
      </React.Fragment>
    </Suspense>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
