import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageVehicleForm } from "../../forms/Master/ManageVehicleForm";
import PropTypes from "prop-types";
import VehicleModel from "../../model/VehicleReq/VehicleModel";
import {
  getQuartoVehicles,
  getVehicle,
  addVehicle,
  updateVehicle,
} from "../../../api/vehicleApi";
import { getOUCode } from "../../../api/ouApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newVehicle = VehicleModel({});

export default function ManageVehiclePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [ous, setOUs] = useState([]);
  const [qVehicles, setQVehicles] = useState([]);
  const [vehicle, setVehicle] = useState(newVehicle);
  const [vehiclesInOU, setVehiclesInOU] = useState([]);
  const [selectedQVehicle, setSelectedQVehicle] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { VehicleKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let editKey = VehicleKey;
    let promises = [getOUCode(), getQuartoVehicles(editKey > 0 ? editKey : -1)];
    if (editKey > 0) {
      promises.push(getVehicle(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setOUs(values[0] ? values[0] : []);

        let qVehicles = values[1] ? values[1] : [];
        setQVehicles(qVehicles);
        if (values[2]) {
          if (values[2].UpdatedDate) {
            values[2].UpdatedOn = values[2].UpdatedDate;
          }
          if (values[2].CreatedDate) {
            values[2].CreatedOn = values[2].CreatedDate;
          }

          let vehicles = qVehicles.filter(
            (x) => x.OUKey === values[2].OURefKey
          );
          setVehiclesInOU(vehicles ? vehicles : []);

          let selectedVehicleInfo = qVehicles.find(
            (x) => x.Key === values[2].VehRefKey
          );
          setSelectedQVehicle(selectedVehicleInfo ? selectedVehicleInfo : {});
        }

        setVehicle(values[2] ? values[2] : newVehicle);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [VehicleKey, t]);

  function getVehiclesInOU(ouKey) {
    var vehicles = qVehicles.filter((x) => x.OUKey === ouKey);
    setVehiclesInOU(vehicles ? vehicles : []);
    setSelectedQVehicle({});
  }

  function getVehicleInfo(vehicleKey) {
    var vehicleInfo = qVehicles.find((x) => x.Key === vehicleKey);
    setSelectedQVehicle(vehicleInfo);
  }

  function saveVehicle(values) {
    let vehicleSubmit = {
      ...vehicle,
      Active: values.Active,
      VehicleCode: values.VehicleCode.trim(),
      VehicleDesc: values.VehicleDesc.trim(),
      OURefKey: values.OURef ? values.OURef.key : -1,
      VehRefKey: values.VehRef ? values.VehRef.key : -1,
    };

    let upsertVehicle =
      vehicleSubmit.VehicleKey !== -1 ? updateVehicle : addVehicle;

    setIsLoading(true);
    upsertVehicle(vehicleSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/vehicles", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        let message =
          vehicleSubmit.VehicleKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          vehicleSubmit.VehicleKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputVehicle() {
    history("/home/vehicles", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ManageVehicleForm
      isLoading={isLoading}
      vehicle={vehicle}
      ous={ous}
      vehiclesInOU={vehiclesInOU}
      selectedQVehicle={selectedQVehicle}
      onSubmitted={saveVehicle}
      onCancelled={cancelInputVehicle}
      getVehiclesInOU={getVehiclesInOU}
      getVehicleInfo={getVehicleInfo}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageVehiclePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
