import React, { useState, useEffect } from "react";
import { getAllClinicVisitByUser } from "../../../api/clinicVisitApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import ClinicVisitDisplayForm from "../../forms/Clinic/ClinicVisitDisplayForm";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const ClinicVisitDisplayPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [clinicVisit, setClinicVisit] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getClinicVisits, [currentYear]);

  function getClinicVisits() {
    setIsLoading(true);
    getAllClinicVisitByUser(currentYear)
      .then((result) => {
        setIsLoading(false);
        setClinicVisit(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setClinicVisit([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function onAddNewClinic() {
    history("/home/clinicvisit/clinictrans");
  }

  function onViewClinic(clinicTransKey) {
    history("/home/clinicvisit/clinictrans/" + clinicTransKey);
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  return (
    <ClinicVisitDisplayForm
      isLoading={isLoading}
      clinicVisits={clinicVisit}
      onAdd={onAddNewClinic}
      onView={onViewClinic}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
};

ClinicVisitDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default ClinicVisitDisplayPage;
