import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Divider,
  Upload,
  Spin,
  Card,
  Modal,
  Checkbox,
  message,
  Tag,
  Table,
  Form,
  Collapse,
  DatePicker,
  Select,
} from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RowState } from "../../model/common/RowState";
import { RecordType } from "../../model/common/RecordType";
import { EditButton, DeleteButton } from "../../common/TableCols";
import JobRecruitmentDetModel from "../../model/JobRecruitment/JobRecruitmentDetModel";
import ManageJobRecruitmentDetForm from "./ManageJobRecruitmentDetForm";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { SelectionInput } from "../../common/SelectionInput";

const { TextArea } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;
const { Option } = Select;

const ManageJobRecruitmentForm = ({
  isLoading,
  form,
  editItem,
  onDataChanged,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onFeedbackJobRecruitment,
  onCompleteJobRecruitment,
  onUploadAttachment,
  onRemoveAttachment,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  fileList,
  setFileList,
  isOwner,
  isViewOnly = false,
  isAssignor,
  setIsDirty,
  userCompCurr,
  ous,
  gradeSelectionData,
}) => {
  const { t } = useTranslation();
  const isEdit = editItem.JobRecruitmentKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen && editItem.Reason.length > 0 && editItem.RejectedBy > 0;
  const isNotHired = editItem.IsValidated && !editItem.IsHired;
  const isRequestCancelOrRejected =
    isStatusRequestCancel(editItem.Status) || isRequestCancelRejected;
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isRequestCancelOrRejected ||
    isRejectWithAmendment ||
    isNotHired;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const [showDetForm, setShowDetForm] = useState(false);
  const newDet = JobRecruitmentDetModel({});
  const [selectedDet, setSelectedDet] = useState(newDet);
  const { JobRecruitmentKey } = useParams();

  useEffect(() => {
    loadPageData(JobRecruitmentKey, form.resetFields);
    // eslint-disable-next-line
  }, [JobRecruitmentKey]);

  const [formRecruitmentDet] = Form.useForm();
  const [isFormRecruitmentDetInitialized, setIsFormRecruitmentDetInitialized] =
    useState(false);
  useEffect(
    () => formRecruitmentDet.resetFields(),
    [isFormRecruitmentDetInitialized, formRecruitmentDet]
  );

  Form.useWatch("EXReason", form);

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("jobRecruitmentForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      ccontent: t("jobRecruitmentForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("jobRecruitmentForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        if (
          submitData.EXDateReq === null ||
          submitData.EXDateReq === undefined
        ) {
          submitData.EXDateReq = new Date("1900-01-01");
        }

        if (values.EXOUKey !== null && values.EXOUKey !== undefined) {
          submitData.EXOUKey = values.EXOUKey.key;
        }

        if (submitData.EXLevel === null || submitData.EXLevel === undefined) {
          submitData.EXLevel = -1;
        }

        if (values.EXGradeKey !== null && values.EXGradeKey !== undefined) {
          submitData.EXGradeKey = values.EXGradeKey.key;
        }

        if (
          submitData.EXWHStatus === null ||
          submitData.EXWHStatus === undefined
        ) {
          submitData.EXWHStatus = -1;
        }

        if (submitData.EXReason === null || submitData.EXReason === undefined) {
          submitData.EXReason = -1;
          submitData.EXOtherReason = "";
        } else {
          if (submitData.EXReason !== 8) {
            submitData.EXOtherReason = "";
          }
        }

        submitData.Status = isSubmit
          ? StatusByValue.SUBMITTED
          : StatusByValue.OPEN;

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onSubmitted(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function feedbackJobRecruitment() {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
        };

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onFeedbackJobRecruitment(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function completeJobRecruitment() {
    form
      .validateFields()
      .then((values) => {
        confirm({
          title: t("jobRecruitmentForm.job_recruitment_closure"),
          content: t("jobRecruitmentForm.closure_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            let submitData = {
              ...editItem,
              ...values,
            };

            uploadAttachments()
              .then((result) => {
                submitData.Attaches = [
                  ...submitData.Attaches.slice(),
                  ...result,
                ];
                onCompleteJobRecruitment(submitData);
              })
              .catch(() => {
                toast.error(t("general.fail_to_upload_file"));
              });
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon:
          (isOpen && isOwner) ||
          (!isOpen &&
            ((!editItem.IsRequireApproval && isSubmitted) ||
              isStatusApproved(editItem.Status)) &&
            editItem.IsValidated &&
            editItem.IsHired &&
            isAssignor),
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  // Details
  function getFilterJobRecruitmentDetData() {
    return editItem.Dets.filter((x) => x.RowState !== RowState.DELETED);
  }

  function addNewDet() {
    setSelectedDet(newDet);
    setShowDetForm(true);
    setIsFormRecruitmentDetInitialized(!isFormRecruitmentDetInitialized);
  }

  function editDet(detKey) {
    let hiredInfos = editItem.Dets.filter(
      (item) => item.JobRecruitmentDetKey === detKey
    );
    let selectedItem = { ...hiredInfos };
    setSelectedDet(selectedItem[0]);
    setShowDetForm(true);
    setIsFormRecruitmentDetInitialized(!isFormRecruitmentDetInitialized);
  }

  function deleteDet(detKey) {
    let newData = editItem.Dets.filter(
      (y) =>
        !(y.JobRecruitmentDetKey === detKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.JobRecruitmentDetKey === detKey &&
        x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    });
    onDataChanged({ ...editItem, Dets: newData });
  }

  function saveDet(det) {
    if (det !== undefined || det !== null) {
      let index = editItem.Dets.findIndex(
        (x) => x.JobRecruitmentDetKey === det.JobRecruitmentDetKey
      );

      if (index === -1) {
        onDataChanged({
          ...editItem,
          Dets: [...editItem.Dets, { ...det }],
        });
      } else {
        let temp = editItem.Dets.map((item) =>
          item.JobRecruitmentDetKey === det.JobRecruitmentDetKey
            ? item.RowState === RowState.ADDED
              ? det
              : { ...det, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...editItem, Dets: temp });
      }
      setShowDetForm(false);
    }
  }

  function cancelInputDet() {
    setSelectedDet(newDet);
    setShowDetForm(false);
  }

  const DetActions = (value) => (
    <Row>
      <Col
        span={12}
        style={{
          display: isStatusApproved(editItem.Status) ? "" : "none",
        }}
      >
        <EditButton fncEdit={() => editDet(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: isStatusApproved(editItem.Status) ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteDet(value)} />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("general.name"),
      align: "left",
      dataIndex: "Name",
      key: "JobRecruitmentDetKey",
    },
    {
      title: t("general.action"),
      dataIndex: "JobRecruitmentDetKey",
      key: "DetActions",
      align: "center",
      width: 100,
      render: DetActions,
    },
  ];

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("jobRecruitmentForm.recruitment")}
                </span>
                <Tag
                  color={editItem.IsHired ? "green" : "magenta"}
                  style={{
                    marginLeft: 10,
                    display: editItem.IsValidated ? "" : "none",
                  }}
                >
                  {editItem.IsHired
                    ? t("jobRecruitmentForm.hiring_successful")
                    : t("jobRecruitmentForm.hiring_unsuccessful")}
                </Tag>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={<span>{t("general.title")}</span>}
                      name="Title"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.title_required_validation"),
                        },
                        {
                          max: 250,
                          message: t("general.title_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Title}
                    >
                      <Input
                        placeholder={t("general.title_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Col
                  span={24}
                  style={{
                    display: isShowReason ? "" : "none",
                  }}
                >
                  <Form.Item
                    label={
                      editItem.IsValidated && !editItem.IsHired
                        ? t("jobRecruitmentForm.reason_of_hiring_unsuccessful")
                        : isRequestCancelRejected
                        ? t("general.reason_of_cancel_request_rejected")
                        : t("general.reason")
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      disabled={true}
                      value={editItem.Reason}
                    />
                  </Form.Item>
                </Col>

                <Row gutter={12}>
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.description")}</span>}
                      name="Description"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.description_required_validation"),
                        },
                      ]}
                      initialValue={editItem.Description}
                    >
                      <TextArea
                        placeholder={t("general.description_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 12 }}>
                    <Collapse size="small">
                      <Panel
                        header={
                          <span style={{ color: "#5c7499", fontWeight: 500 }}>
                            {t("general.additional_info")}
                          </span>
                        }
                        key="0"
                      >
                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={
                                <span>
                                  {t("jobRecruitmentForm.date_required")}
                                </span>
                              }
                              name="EXDateReq"
                              initialValue={
                                isEdit && editItem.EXDateReq
                                  ? dayjs(
                                      new Date(editItem.EXDateReq)
                                    ).year() !== 1900
                                    ? dayjs(new Date(editItem.EXDateReq))
                                    : null
                                  : undefined
                              }
                            >
                              <DatePicker
                                placeholder={t(
                                  "jobRecruitmentForm.date_required"
                                )}
                                format="DD/MM/YYYY"
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("general.operating_unit")}
                              name="EXOUKey"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    if (
                                      value &&
                                      !ous.some((x) => x.Key === value.value)
                                    ) {
                                      return Promise.reject(
                                        new Error(
                                          t(
                                            "general.operating_unit_invalid_validation"
                                          )
                                        )
                                      );
                                    }
                                    return Promise.resolve();
                                  },
                                },
                              ]}
                              initialValue={
                                editItem.EXOUKey > 0
                                  ? {
                                      key: editItem.EXOUKey,
                                      value: editItem.EXOUKey,
                                      label: editItem.EXOUCodeDesc,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                allowClear={true}
                                ref={React.createRef()}
                                labelInValue={true}
                                data={ous}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t(
                                  "general.operating_unit_placeholder_full"
                                )}
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={t("jobRecruitmentForm.level")}
                              name="EXLevel"
                              initialValue={
                                editItem.EXLevel >= 0
                                  ? editItem.EXLevel
                                  : undefined
                              }
                            >
                              <Select
                                allowClear={true}
                                placeholder={t(
                                  "jobRecruitmentForm.level_placeholder"
                                )}
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              >
                                <Option value={0}>
                                  {t("jobRecruitmentForm.executive_level")}
                                </Option>
                                <Option value={1}>
                                  {t("jobRecruitmentForm.non_executive_level")}
                                </Option>
                                <Option value={2}>
                                  {t("jobRecruitmentForm.daily_rated")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("jobRecruitmentForm.job_level_grade")}
                              name="EXGradeKey"
                              initialValue={
                                editItem.EXGradeKey > 0
                                  ? {
                                      key: editItem.EXGradeKey,
                                      value: editItem.EXGradeKey,
                                      label: editItem.EXGradeCodeEXDesc,
                                    }
                                  : undefined
                              }
                            >
                              <SelectionInput
                                allowClear={true}
                                ref={React.createRef()}
                                labelInValue={true}
                                data={gradeSelectionData}
                                valueProp={"Key"}
                                textProp={"CodeDesc"}
                                disabledProp={"Active"}
                                placeholder={t("general.grade_placeholder")}
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={
                                <span>{t("jobRecruitmentForm.job_title")}</span>
                              }
                              name="EXTitle"
                              rules={[
                                {
                                  max: 250,
                                  message: t(
                                    "general.title_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={editItem.EXTitle}
                            >
                              <Input
                                placeholder={t(
                                  "jobRecruitmentForm.job_title_placeholder"
                                )}
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("jobRecruitmentForm.no_of_request")}
                              name="EXNoOfReq"
                              initialValue={editItem.EXNoOfReq}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step="1"
                                min={0}
                                precision={0}
                                formatter={inputLocaleFormatter(0)}
                                parser={inputLocaleParser(0)}
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col span={12}>
                            <Form.Item
                              label={
                                <span>
                                  {t("jobRecruitmentForm.salary_recommended")}
                                </span>
                              }
                              name="EXSalaryRec"
                              initialValue={editItem.EXSalaryRec}
                            >
                              <InputNumber
                                style={{ width: "100%" }}
                                step={stepPrecision(userCompCurr.CurrCode)}
                                min={0}
                                precision={precisionDecimal(
                                  userCompCurr.CurrCode
                                )}
                                prefix={
                                  userCompCurr && userCompCurr.CurrSymb
                                    ? userCompCurr.CurrSymb
                                    : ""
                                }
                                formatter={inputLocaleFormatter(
                                  precisionDecimal(userCompCurr.CurrCode)
                                )}
                                parser={inputLocaleParser(
                                  precisionDecimal(userCompCurr.CurrCode)
                                )}
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t(
                                "jobRecruitmentForm.working_hour_status"
                              )}
                              name="EXWHStatus"
                              initialValue={
                                editItem.EXWHStatus >= 0
                                  ? editItem.EXWHStatus
                                  : undefined
                              }
                            >
                              <Select
                                allowClear={true}
                                placeholder={t(
                                  "jobRecruitmentForm.working_hour_status_placeholder"
                                )}
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              >
                                <Option value={0}>
                                  {t("jobRecruitmentForm.normal")}
                                </Option>
                                <Option value={1}>
                                  {t("jobRecruitmentForm.shift")}
                                </Option>
                                <Option value={2}>
                                  {t(
                                    "jobRecruitmentForm.flexible_working_hours"
                                  )}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={12}>
                          <Col span={24}>
                            <Form.Item
                              label={t(
                                "jobRecruitmentForm.reason_for_requisition"
                              )}
                              name="EXReason"
                              initialValue={
                                editItem.EXReason >= 0
                                  ? editItem.EXReason
                                  : undefined
                              }
                            >
                              <Select
                                allowClear="true"
                                placeholder={t(
                                  "jobRecruitmentForm.reason_for_requisition_placeholder"
                                )}
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                                disabled={!isOpen || !isOwner || isSystemRcd}
                              >
                                <Option value={0}>
                                  {t(
                                    "jobRecruitmentForm.resignation_of_employee"
                                  )}
                                </Option>
                                <Option value={1}>
                                  {t("jobRecruitmentForm.employee_dismissed")}
                                </Option>
                                <Option value={2}>
                                  {t("jobRecruitmentForm.employee_terminated")}
                                </Option>
                                <Option value={3}>
                                  {t("jobRecruitmentForm.transfer_of_employee")}
                                </Option>
                                <Option value={4}>
                                  {t(
                                    "jobRecruitmentForm.retirement_of_employee"
                                  )}
                                </Option>
                                <Option value={5}>
                                  {t(
                                    "jobRecruitmentForm.employee_has_been_promoted"
                                  )}
                                </Option>
                                <Option value={6}>
                                  {t("jobRecruitmentForm.new_post")}
                                </Option>
                                <Option value={7}>
                                  {t("jobRecruitmentForm.backlog_of_work")}
                                </Option>
                                <Option value={8}>
                                  {t("jobRecruitmentForm.other_reasons")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col
                            span={24}
                            style={{
                              display:
                                form.getFieldValue("EXReason") === 8
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <Form.Item
                              name="EXOtherReason"
                              initialValue={editItem.EXOtherReason}
                            >
                              <TextArea
                                placeholder={t(
                                  "jobRecruitmentForm.other_reasons_placeholder"
                                )}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                disabled={
                                  !isOpen ||
                                  !isOwner ||
                                  isViewOnly ||
                                  isSystemRcd
                                }
                                value={editItem.EXOtherReason}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Panel>
                    </Collapse>
                  </Col>

                  <Col
                    span={24}
                    style={{
                      display:
                        editItem.IsValidated && editItem.IsHired ? "" : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("general.closure_remarks")}</span>}
                      name="ClosureRemarks"
                      initialValue={editItem.ClosureRemarks}
                    >
                      <TextArea
                        placeholder={t("general.closure_remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          !isAssignor ||
                          isViewOnly ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>

                    <h3 className="form-subtitle">
                      {t("jobRecruitmentForm.new_hire")}
                    </h3>
                    <div>
                      <Table
                        dataSource={getFilterJobRecruitmentDetData()}
                        columns={columns}
                        size="middle"
                        pagination={false}
                        rowKey={(record) => record.JobRecruitmentDetKey}
                        bordered
                      />
                      <Button
                        type="primary"
                        onClick={() => addNewDet()}
                        style={{
                          marginTop: 16,
                          marginBottom: 16,
                          display:
                            !isViewOnly && isStatusApproved(editItem.Status)
                              ? ""
                              : "none",
                        }}
                      >
                        {t("general.add_new")}
                      </Button>
                    </div>
                    <ManageJobRecruitmentDetForm
                      isVisible={showDetForm}
                      selectedDet={selectedDet}
                      onSave={saveDet}
                      onCancel={cancelInputDet}
                      setIsDirty={setIsDirty}
                      form={formRecruitmentDet}
                    />
                  </Col>

                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.attachment")}</span>}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            (!isOpen &&
                              !(!editItem.IsRequireApproval && isSubmitted) &&
                              !isStatusApproved(editItem.Status)) ||
                            (!isOpen && !isOwner && !isAssignor) ||
                            (!isOpen &&
                              (!editItem.IsValidated ||
                                !editItem.IsHired ||
                                !isAssignor)) ||
                            isSystemRcd ||
                            isViewOnly
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        {/* Save for recruitment completion */}
                        <Button
                          size="large"
                          onClick={() => feedbackJobRecruitment()}
                          style={{
                            marginRight: isOwner ? 10 : 10,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              editItem.IsValidated &&
                              editItem.IsHired &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              !editItem.IsValidated
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => completeJobRecruitment()}
                          style={{
                            background: "mediumpurple",
                            borderColor: "mediumpurple",
                            marginLeft: isOwner ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              editItem.IsValidated &&
                              editItem.IsHired &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.complete")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.JobRecruitmentKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Recruitment"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageJobRecruitmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onFeedbackJobRecruitment: PropTypes.func.isRequired,
  onCompleteJobRecruitment: PropTypes.func.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  isAssignor: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  ous: PropTypes.array.isRequired,
  gradeSelectionData: PropTypes.array.isRequired,
};

export default ManageJobRecruitmentForm;
