import * as types from "./actionTypes";
import * as notificationApi from "../../api/notificationApi";

export function loadNotificationSuccess(notifications, onDemand) {
  return { type: types.LOAD_NOTIFICATION_SUCCESS, notifications, onDemand };
}

export function updateNotificationSuccess(notification) {
  return { type: types.UPDATE_NOTIFICATION_SUCCESS, notification };
}

export function updateAllNotificationSuccess(typeKey) {
  return { type: types.UPDATE_ALLNOTIFICATION_SUCCESS, typeKey };
}

export function updateSelectedNotificationSuccess(notifications) {
  return { type: types.UPDATE_SELECTED_NOTIFICATION_SUCCESS, notifications };
}

export function deleteNotificationSuccess(notification) {
  return { type: types.DELETE_NOTIFICATION_SUCCESS, notification };
}

export function deleteSelectedNotificationSuccess(notifications) {
  return { type: types.DELETE_SELECTED_NOTIFICATION_SUCCESS, notifications };
}

export function newNotification(notification) {
  return { type: types.ADD_NOTIFICATION, notification };
}

export function loadNotifications() {
  return function (dispatch) {
    return notificationApi
      .GetUserNotifications()
      .then((notifications) => {
        dispatch(loadNotificationSuccess(notifications, 0));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function loadNotificationsOnDemand(runningNum) {
  return function (dispatch) {
    return notificationApi
      .LoadUserNotificationsOnDemand(runningNum)
      .then((notifications) => {
        dispatch(loadNotificationSuccess(notifications, 1));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateNotifications(NotificationKey) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    return notificationApi
      .MarkNotificationAsRead(NotificationKey)
      .then((notification) => {
        dispatch(updateNotificationSuccess(notification));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateAllNotifications(NotificationKey) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    return notificationApi
      .MarkAllNotificationAsRead(NotificationKey)
      .then((typeKey) => {
        dispatch(updateAllNotificationSuccess(typeKey));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function updateSelectedNotifications(data) {
  //eslint-disable-next-line no-unused-vars
  return function (dispatch, getState) {
    return notificationApi
      .MarkSelectedNotificationAsRead(data)
      .then((notification) => {
        dispatch(updateSelectedNotificationSuccess(notification));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteNotifications(NotificationKey) {
  return function (dispatch) {
    return notificationApi
      .MarkNotificationAsDelete(NotificationKey)
      .then((notification) => {
        dispatch(deleteNotificationSuccess(notification));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function deleteSelectedNotifications(data) {
  return function (dispatch) {
    return notificationApi
      .MarkSelectedNotificationAsDelete(data)
      .then((notification) => {
        dispatch(deleteSelectedNotificationSuccess(notification));
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addNotification(notification) {
  return function (dispatch) {
    dispatch(newNotification(notification));
  };
}
