export default function ProjectModel({
  ProjKey = -1,
  ProjCode = "",
  ProjDesc = "",
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null
}) {
  return Object.freeze({
    ProjKey: ProjKey,
    ProjCode: ProjCode,
    ProjDesc: ProjDesc,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion
  });
}
