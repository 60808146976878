import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "AppraisalRating/";

export function getAllAppraisalRatings() {
  return axios({
    method: "GET",
    url: base + "GetAllAppraisalRatings",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAppraisalRating(appraisalRatingKey) {
  return axios({
    method: "GET",
    url: base + "GetAppraisalRating",
    headers: authHeader(),
    params: { key: appraisalRatingKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getARCode() {
  return axios({
    method: "GET",
    url: base + "GetARCode",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addAppraisalRating(appraisalRating) {
  return axios({
    method: "POST",
    url: base + "AddAppraisalRating",
    headers: authHeader(),
    data: appraisalRating,
  })
    .then()
    .catch(handleError);
}

export function updateAppraisalRating(appraisalRating) {
  return axios({
    method: "PUT",
    url: base + "UpdateAppraisalRating",
    headers: authHeader(),
    params: { key: appraisalRating.ARKey },
    data: appraisalRating,
  })
    .then()
    .catch(handleError);
}

export function deleteAppraisalRating(appraisalRatingKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteAppraisalRating",
    headers: authHeader(),
    params: { key: appraisalRatingKey },
  })
    .then()
    .catch(handleError);
}
