export default function TravelConfgModel({
  travelConfgKey = -1,
  isValidateTrans = false,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  isAllowOverClaim = false,
}) {
  return Object.freeze({
    TravelConfgKey: travelConfgKey,
    IsValidateTrans: isValidateTrans,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    IsAllowOverClaim: isAllowOverClaim,
  });
}
