import { RowState } from "../common/RowState";

export default function LeaveTypeRuleModel({
  LTRuleKey = Math.floor(Math.random() * 999999),
  EmpyGradeKey = -1,
  EmpyGradeCodeGradeDesc = "All",
  EffectiveAfter = 0,
  EntitledDay = 8,
  CarryForwardDay = 0,
  RcdType = 0,
  rowState = RowState.ADDED
}) {
  return Object.freeze({
    LTRuleKey: LTRuleKey,
    EmpyGradeKey: EmpyGradeKey,
    EmpyGradeCodeGradeDesc: EmpyGradeCodeGradeDesc,
    EffectiveAfter: EffectiveAfter,
    EntitledDay: EntitledDay,
    CarryForwardDay: CarryForwardDay,
    RcdType: RcdType,
    RowState: rowState
  });
}
