import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageRoomForm } from "../../forms/Master/ManageRoomForm";
import PropTypes from "prop-types";
import RoomModel from "../../model/RoomReq/RoomModel";
import { getRoom, addRoom, updateRoom } from "../../../api/roomApi";
import { getOUCode } from "../../../api/ouApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newRoom = RoomModel({});

export default function ManageRoomPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [ous, setOUs] = useState([]);
  const [room, setRoom] = useState(newRoom);
  const [isLoading, setIsLoading] = useState(true);
  const { RoomKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let editKey = RoomKey;
    let promises = [getOUCode()];
    if (editKey > 0) {
      promises.push(getRoom(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setOUs(values[0] ? values[0] : []);

        if (values[1]) {
          if (values[1].UpdatedDate) {
            values[1].UpdatedOn = values[1].UpdatedDate;
          }
          if (values[1].CreatedDate) {
            values[1].CreatedOn = values[1].CreatedDate;
          }
        }

        setRoom(values[1] ? values[1] : newRoom);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [RoomKey, t]);

  function saveRoom(values) {
    let roomSubmit = {
      ...room,
      Active: values.Active,
      RoomCode: values.RoomCode.trim(),
      RoomDesc: values.RoomDesc.trim(),
      OUKey: values.OU ? values.OU.key : -1,
    };

    let upsertRoom = roomSubmit.RoomKey !== -1 ? updateRoom : addRoom;

    setIsLoading(true);
    upsertRoom(roomSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/meetingrooms", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        let message =
          roomSubmit.RoomKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          roomSubmit.RoomKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputRoom() {
    history("/home/meetingrooms", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ManageRoomForm
      isLoading={isLoading}
      room={room}
      ous={ous}
      onSubmitted={saveRoom}
      onCancelled={cancelInputRoom}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageRoomPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
