import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "leaveSubmission/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllLeaveApplication(month, year) {
  return axios({
    method: "GET",
    url: base + "GetAllLeaveApplication",
    headers: authHeader(),
    params: { month: month, year: year },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllLeaveApplicationByUser(currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios({
    method: "GET",
    url: base + "GetAllLeaveApplicationByUser",
    headers: authHeader(),
    params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllLeaveApplicationByUserDashboard() {
  return axios({
    method: "GET",
    url: base + "GetAllLeaveApplicationByUserDashboard",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllLeaveApplicationByUserKey(userKey = -1) {
  return axios({
    method: "GET",
    url: base + "GetAllLeaveApplicationByUserKey",
    headers: authHeader(),
    params: { userKey: userKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLeaveApplication(leaveTransKey) {
  return axios({
    method: "GET",
    url: base + "GetLeaveApplication",
    headers: authHeader(),
    params: { key: leaveTransKey, environment: env },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addLeaveApplication(leaveApplication) {
  return axios({
    method: "POST",
    url: base + "AddLeaveApplication",
    headers: authHeader(),
    data: leaveApplication,
  })
    .then()
    .catch(handleError);
}

export function updateLeaveApplication(leaveApplication) {
  return axios({
    method: "PUT",
    url: base + "UpdateLeaveApplication",
    headers: authHeader(),
    params: { key: leaveApplication.LeaveTransKey },
    data: leaveApplication,
  })
    .then()
    .catch(handleError);
}

export function deleteLeaveApplication(leaveTransKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteLeaveApplication",
    headers: authHeader(),
    params: { key: leaveTransKey },
  })
    .then()
    .catch(handleError);
}

export function getMonthEndClose(userKey, year, month) {
  return axios({
    method: "GET",
    url: base + "GetMonthEndClose",
    headers: authHeader(),
    params: { userKey: userKey, year: year, month: month },
  })
    .then(handleResponse)
    .catch(handleError);
}
