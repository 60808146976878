import axios from "axios";
import interceptorUtil from "./interceptorUtil";
import { ROOT } from "./api-config";

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // return new Promise((resolve, reject) => {
    //   if (
    //     error.response.status === 401 &&
    //     error.response.statusText === "Unauthorized"
    //   ) {
    //     onRefreshToken({
    //       initialRequest: error.response.config,
    //       resolve: resolve,
    //       reject: reject
    //     });
    //   } else {
    //     reject(error);
    //     //onLogout();
    //   }
    // });

    if (error.response !== undefined) {
      const originalRequest = error.response.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return axios(originalRequest);
            })
            .catch((err) => {
              return err;
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
          const auth = JSON.parse(sessionStorage.getItem("auth"));
          var data =
            "grant_type=refresh_token&refresh_token=" + auth.refresh_token;
          axios.interceptors.request.eject(interceptorUtil.getInterceptor());

          axios
            .post(ROOT + "token", data, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            })
            .then(({ data }) => {
              saveTokens(data);
              originalRequest.headers["Authorization"] =
                "Bearer " + data.access_token;
              processQueue(null, data.access_token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  }
);

export function authHeader() {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.access_token) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.access_token,
    };
  } else {
    return {};
  }
}

export async function handleResponse(response) {
  if (response.status === 200) return response.data;
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    throw new Error(error);
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  //console.error("API call failed. " + error.response.data.error_description);
  const INTERNAL_SERVER_ERROR = "Internal Server Error";

  if (error.response) {
    if (error.response.status === 500) {
      if (error.response.data.InnerException) {
        if (
          error.response.data.InnerException.InnerException.ExceptionMessage.toLowerCase().includes(
            "conflicted with the REFERENCE constraint".toLowerCase()
          )
        ) {
          error.message = "This record is currently in used.";
        }
      }
    }
  }

  if (error.response) throw error;
  var customError = {
    response: {
      data: {
        error: INTERNAL_SERVER_ERROR,
        error_description: "Could not connect to server",
      },
      status: 500,
      statusText: INTERNAL_SERVER_ERROR,
    },
  };
  throw customError;
}

export function localLogin() {
  let auth = JSON.parse(sessionStorage.getItem("auth"));

  if (!auth) {
    onLogout();
  } else {
    //Save in store
  }
}

function onLogout() {
  sessionStorage.clear();
  axios.interceptors.request.eject(interceptorUtil.getInterceptor());
}

function saveTokens(params) {
  const { access_token } = params;

  sessionStorage.setItem("auth", JSON.stringify(params));
  localStorage.setItem("openSessionStorage", JSON.stringify(params));
  localStorage.removeItem("openSessionStorage");

  let interceptor = axios.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + access_token;
    return config;
  });

  interceptorUtil.setInterceptor(interceptor);
}

// function onRefreshToken(params) {
//   let auth = JSON.parse(localStorage.getItem("auth"));

//   if (auth) {
//     var data = "grant_type=refresh_token&refresh_token=" + auth.refresh_token;

//     axios.interceptors.request.eject(interceptorUtil.getInterceptor());
//     axios
//       .post("http://localhost:52002/token", data, {
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded"
//         }
//       })
//       .then(response => {
//         saveTokens(response.data);

//         // Replay request
//         axios(params.initialRequest)
//           .then(response => {
//             params.resolve(response);
//           })
//           .catch(response => {
//             params.reject(response);
//           });
//       })
//       .catch(() => {
//         onLogout();
//       });
//   } else {
//     onLogout();
//   }
// }
