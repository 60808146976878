import React from "react";
import PropTypes from "prop-types";
import { JobRecruitmentList } from "./JobRecruitmentList";

const JobRecruitmentForm = ({
  isLoading,
  isFromAdmin = false,
  onAdd,
  onView,
  data,
  onAttachmentClicked,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination = () => {},
}) => {
  return (
    <div>
      <JobRecruitmentList
        isLoading={isLoading}
        data={data}
        isFromAdmin={isFromAdmin}
        onAdd={onAdd}
        onItemViewed={onView}
        isShowStatus={true}
        isMultipleAction={false}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

JobRecruitmentForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  isFromAdmin: PropTypes.bool,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default JobRecruitmentForm;
