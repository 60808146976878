import React, { useState } from "react";
import {
  CalendarOutlined,
  FileOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Table,
  Button,
  Spin,
  Card,
  Row,
  Col,
  DatePicker,
  Radio,
  Calendar,
  Badge,
  Switch,
  Form,
} from "antd";
import { Status } from "../../model/common/Status";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import { useTranslation } from "react-i18next";

const colors = [
  "pink",
  "red",
  "yellow",
  "orange",
  "cyan",
  "green",
  "blue",
  "purple",
  "geekblue",
  "magenta",
  "volcano",
  "gold",
  "lime",
];

const TrainingCalendarInputs = ({
  isLoading,
  filteredTrainings,
  filterDataByYear,
  trainingYear,
  isShowAvailable,
  onDisplayOptionChanged,
  onView,
  onApply,
  onAttachmentClicked,
}) => {
  const { t } = useTranslation();
  const [searchTitleText, setSearchTitleText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("0"); //0 is list, 1 is calendar
  const [calendarDate, setCalendarDate] = useState(trainingYear);

  const TrainingViewButton = (value, record) => (
    <Row>
      <Col span={6}>
        <Button
          key={"Attch_" + value}
          size={"large"}
          icon={<FileOutlined />}
          type="link"
          style={{
            display:
              record.Attaches && record.Attaches.length > 0 ? "" : "none",
          }}
          onClick={() => viewAttachment(record.Attaches)}
        />
      </Col>
      <Col span={18}>
        <Button type="link" onClick={() => onView(value)}>
          {t("general.view")}
        </Button>
      </Col>
    </Row>
  );

  function viewAttachment(attachments) {
    if (onAttachmentClicked) {
      onAttachmentClicked(attachments).then((result) => {
        if (result && result.data) {
          result.data.forEach((attach) => {
            window.open(attach.UploadedUrl, "_blank");
          });
        }
      });
    }
  }

  const TrainingApplicationActionButtons = (value, record) => (
    <Row>
      <Col span={24}>
        <Button
          type="link"
          onClick={() => onApply(record.TrainingKey)}
          style={{
            display:
              record.IsOpenForApplication &&
              !record.IsApplyByUser &&
              (record.NoOfApplicants === -1 ||
                record.TotalConfirmedApplications < record.NoOfApplicants)
                ? ""
                : "none",
          }}
        >
          {t("general.apply")}
        </Button>
        <span style={{ display: record.IsApplyByUser ? "" : "none" }}>
          {value === -1 ? "" : Status[value]}
        </span>
      </Col>
    </Row>
  );

  const handleSearchTitle = (selectedKeys, confirm) => {
    confirm();
    setSearchTitleText(selectedKeys[0]);
  };

  const handleResetTitle = (clearFilters) => {
    clearFilters();
    setSearchTitleText("");
  };

  const columns = [
    {
      title: t("trainingForm.training_date"),
      dataIndex: "TrainingDate",
      key: "TrainingDate",
      width: 100,
      sorter: (a, b) =>
        dayjs(a.TrainingFromDate) - dayjs(b.TrainingFromDate) ||
        dayjs(a.TrainingToDate) - dayjs(b.TrainingToDate),
      sortDirections: ["ascend", "descend"],
      render: (date, record) =>
        dayjs(record.TrainingFromDate).format("DD/MM/YYYY") +
        " - " +
        dayjs(record.TrainingToDate).format("DD/MM/YYYY"),
    },
    {
      title: t("trainingApplicationForm.total_days"),
      dataIndex: "TotalDays",
      key: "TotalDays",
      width: 70,
      align: "right",
      sorter: (a, b) => a.TotalDays - b.TotalDays,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("trainingApplicationForm.training_program"),
      dataIndex: "Title",
      key: "Title",
      sorter: (a, b) => a.Title.localeCompare(b.Title),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "Title",
        searchTitleText,
        handleSearchTitle,
        handleResetTitle,
        "Training Program",
        t
      ),
    },
    {
      title: "",
      dataIndex: "TrainingKey",
      key: "TrainingView",
      align: "center",
      width: 100,
      render: TrainingViewButton,
    },
    {
      title: t("trainingForm.application_date"),
      dataIndex: "ApplicationDate",
      key: "ApplicationDate",
      width: 100,
      sorter: (a, b) =>
        dayjs(a.ApplicationStartDate) - dayjs(b.ApplicationStartDate) ||
        dayjs(a.ApplicationEndDate) - dayjs(b.ApplicationEndDate),
      sortDirections: ["ascend", "descend"],
      render: (date, record) =>
        dayjs(record.ApplicationStartDate).format("DD/MM/YYYY") +
        " - " +
        dayjs(record.ApplicationEndDate).format("DD/MM/YYYY"),
    },
    {
      title: t("trainingApplicationForm.availability"),
      dataIndex: "TotalConfirmedApplications",
      key: "Availability",
      align: "center",
      width: 110,
      render: (totalApplications, record) =>
        record.IsApplicationClosed
          ? t("general.not_available")
          : !record.IsOpenForApplication
          ? " - "
          : record.NoOfApplicants !== -1 &&
            totalApplications >= record.NoOfApplicants
          ? t("general.full")
          : t("general.available"),
    },
    {
      title: t("trainingApplicationForm.application_status"),
      dataIndex: "UserApplicationStatus",
      key: "TrainingApplicationActions",
      align: "center",
      width: 110,
      render: TrainingApplicationActionButtons,
    },
  ];

  function onYearChanged(value) {
    setIsOpen(false);
    filterDataByYear(value, isShowAvailable);

    if (mode === "0") {
      setCalendarDate(value);
    }
  }

  function onModeChanged(e) {
    setMode(e.target.value);
  }

  const onCalendarChange = (value) => {
    setCalendarDate(value);

    if (value.year() !== trainingYear.year()) {
      let current = dayjs();
      current.set({ year: value.year() });
      onYearChanged(current);
    }
  };

  const dateCellRender = (value) => {
    const listData = filteredTrainings.filter((x) =>
      value
        .startOf("day")
        .isBetween(
          dayjs(x.TrainingFromDate).startOf("day"),
          dayjs(x.TrainingToDate).startOf("day"),
          undefined,
          "[]"
        )
    );
    return (
      <ul style={{ listStyleType: "none" }}>
        {listData.map((item, index) => (
          <li key={item.TrainingKey}>
            <Badge
              color={
                index < colors.length
                  ? colors[index]
                  : colors[index - colors.length]
              }
              text={item.Title}
              status={
                item.IsOpenForApplication && !item.IsApplyByUser
                  ? "processing"
                  : "default"
              }
            />
          </li>
        ))}
      </ul>
    );
  };

  const monthCellRender = (value) => {
    const listData = filteredTrainings.filter(
      (x) =>
        value.month() >= dayjs(x.TrainingFromDate).month() &&
        value.month() <= dayjs(x.TrainingToDate).month()
    );
    return (
      <ul style={{ listStyleType: "none" }}>
        {listData.map((item, index) => (
          <li key={item.TrainingKey}>
            <Badge
              color={
                index < colors.length
                  ? colors[index]
                  : colors[index - colors.length]
              }
              status={
                item.IsOpenForApplication && !item.IsApplyByUser
                  ? "processing"
                  : "default"
              }
              text={item.Title}
            />
          </li>
        ))}
      </ul>
    );
  };

  function cellRender(current, info) {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  }

  return (
    <Card
      title={
        <span className="form-title">
          {t("trainingApplicationForm.training_calendar")}
        </span>
      }
      extra={
        <div>
          <Switch
            checkedChildren={t("trainingApplicationForm.available_only")}
            unCheckedChildren={t("general.all")}
            defaultChecked={isShowAvailable}
            onChange={(value) => onDisplayOptionChanged(value)}
            style={{ marginRight: 20 }}
          />
          <Radio.Group defaultValue={mode} onChange={onModeChanged}>
            <Radio.Button value="0">
              <UnorderedListOutlined />
            </Radio.Button>
            <Radio.Button value="1">
              <CalendarOutlined />
            </Radio.Button>
          </Radio.Group>
        </div>
      }
    >
      <div style={{ display: mode === "0" ? "" : "none" }}>
        <Form layout="vertical">
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item label={t("trainingApplicationForm.training_year")}>
                {
                  <DatePicker
                    allowClear={false}
                    value={trainingYear}
                    mode="year"
                    open={isOpen}
                    placeholder={t(
                      "trainingApplicationForm.training_year_placeholder"
                    )}
                    format="YYYY"
                    onOpenChange={(status) => {
                      if (status) {
                        setIsOpen(true);
                      } else {
                        setIsOpen(false);
                      }
                    }}
                    onPanelChange={onYearChanged}
                  />
                }
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Spin spinning={isLoading}>
          <Table
            dataSource={filteredTrainings}
            columns={columns}
            size="middle"
            rowKey={(record) => record.TrainingKey}
            scroll={{ y: "calc(100vh - 270px)" }}
            pagination={false}
          />
        </Spin>
      </div>

      <div style={{ display: mode === "1" ? "" : "none" }}>
        <Spin spinning={isLoading}>
          <Calendar
            value={calendarDate}
            onChange={onCalendarChange}
            cellRender={cellRender}
          />
        </Spin>
      </div>
    </Card>
  );
};

TrainingCalendarInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  filteredTrainings: PropTypes.array.isRequired,
  filterDataByYear: PropTypes.func.isRequired,
  trainingYear: PropTypes.object.isRequired,
  isShowAvailable: PropTypes.bool.isRequired,
  onDisplayOptionChanged: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired,
};

export const TrainingsCalendarForm = TrainingCalendarInputs;
