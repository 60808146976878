import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "announcementConfg/";

export function getAnnouncementConfg() {
  return axios({
    method: "GET",
    url: base + "GetAnnouncementConfg",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addAnnouncementConfg(announcementConfg) {
  return axios({
    method: "POST",
    url: base + "AddAnnouncementConfg",
    headers: authHeader(),
    data: announcementConfg,
  })
    .then()
    .catch(handleError);
}

export function updateAnnouncementConfg(announcementConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateAnnouncementConfg",
    headers: authHeader(),
    params: { key: announcementConfg.AnnouncementConfgKey },
    data: announcementConfg,
  })
    .then()
    .catch(handleError);
}
