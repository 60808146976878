import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageProjectForm from "../../forms/Master/ManageProjectForm";
import PropTypes from "prop-types";
import ProjectModel from "../../model/Project/ProjectModel";
import { getProject, addProject, updateProject } from "../../../api/projectApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newProject = ProjectModel({});

export default function ManageProjectPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [project, setProject] = useState(newProject);
  const [isLoading, setIsLoading] = useState(true);
  const { ProjKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = ProjKey;
    if (editKey > 0) {
      promises.push(getProject(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
          if (values[0].CreatedDate) {
            values[0].CreatedOn = values[0].CreatedDate;
          }
        }
        setProject(values[0] ? values[0] : newProject);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [ProjKey]);

  function saveProject(values) {
    let projectSubmit = {
      ...project,
      ...values,
      ProjCode: values.ProjCode.trim(),
      ProjDesc: values.ProjDesc.trim(),
    };

    let upsertProj = projectSubmit.ProjKey !== -1 ? updateProject : addProject;
    setIsLoading(true);
    upsertProj(projectSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/projects", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          projectSubmit.ProjKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputProject() {
    history("/home/projects", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageProjectForm
      isLoading={isLoading}
      project={project}
      onSubmitted={saveProject}
      onCancelled={cancelInputProject}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageProjectPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
