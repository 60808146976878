import React from "react";
import PropTypes from "prop-types";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Spin, Steps, Row, Col, Card, Button, Upload, Alert } from "antd";
import { useTranslation } from "react-i18next";

export default function ClaimMigrationForm({
  history,
  isLoading,
  currentStep,
  loadEmployees,
  loadUOMs,
  onTemplateDownloaded,
  onDataImported,
  fileList,
  setFileList,
  actionMsg,
  isError,
  removeMessage,
}) {
  const { t } = useTranslation();
  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  return (
    <Row>
      <Col
        xs={{ span: 24, offset: 0 }}
        sm={{ span: 24, offset: 0 }}
        xl={{ span: 24, offset: 0 }}
        xxl={{ span: 16, offset: 4 }}
      >
        <Spin spinning={isLoading}>
          <Card
            title={
              <span className="form-title">
                {t("migrationForm.claim_migration")}
              </span>
            }
          >
            <Steps
              current={currentStep}
              items={[
                {
                  title: t("migrationForm.setup_claim_type"),
                },
                {
                  title: t("migrationForm.setup_unit_of_measurement"),
                },
                {
                  title: t("migrationForm.setup_employee"),
                },
                {
                  title: t("migrationForm.import_data"),
                },
              ]}
            ></Steps>
            <div
              style={{
                marginTop: 40,
                display: currentStep === 0 ? "" : "none",
              }}
            >
              {t("migrationForm.claim_type_not_found_message")}
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={() => history("../home/claimtypes/claimtype")}
              >
                {t("migrationForm.setup_claim_type")}
              </Button>
              .
            </div>

            <div
              style={{
                marginTop: 40,
                display: currentStep === 1 ? "" : "none",
              }}
            >
              {t("migrationForm.unit_of_measurement_not_found_message")}
              <a
                href="https://www.quarto.cloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quarto
              </a>
              .<br />
              <br />
              {t("migrationForm.already_setup_message_1")}
              <Button style={{ padding: 0 }} type="link" onClick={loadUOMs}>
                {t("migrationForm.already_setup_message_2")}
              </Button>
              {t("migrationForm.already_setup_message_3")}
            </div>

            <div
              style={{
                marginTop: 40,
                display: currentStep === 2 ? "" : "none",
              }}
            >
              {t("migrationForm.employee_not_found_message")}
              <a
                href="https://www.quarto.cloud"
                target="_blank"
                rel="noopener noreferrer"
              >
                Quarto
              </a>
              .<br />
              <br />
              {t("migrationForm.already_setup_message_1")}
              <Button
                style={{ padding: 0 }}
                type="link"
                onClick={loadEmployees}
              >
                {t("migrationForm.already_setup_message_2")}
              </Button>
              {t("migrationForm.already_setup_message_3")}
            </div>

            <div
              style={{
                marginTop: 40,
                display: currentStep === 3 ? "" : "none",
              }}
            >
              {t("migrationForm.download_template_message")}
              <b>{t("migrationForm.download_template")}</b>
              {t("migrationForm.download_template_message_1")}
              <b>{t("migrationForm.upload_data")}</b>
              {t("migrationForm.download_template_message_2")}
              <b>{t("migrationForm.import")}</b>.
              <br />
              <br />
              <Row>
                <Col span={12}>
                  <Button onClick={onTemplateDownloaded}>
                    <DownloadOutlined />
                    {t("migrationForm.download_template")}
                  </Button>
                </Col>
                <Col span={12}>
                  <Upload
                    {...uploadProps}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  >
                    <Button>
                      <UploadOutlined />
                      {t("migrationForm.upload_data")}
                    </Button>
                  </Upload>
                </Col>
              </Row>
            </div>
            {actionMsg && actionMsg.length > 0 && (
              <Alert
                message={actionMsg}
                type={isError ? "error" : "success"}
                afterClose={removeMessage}
                closable
                showIcon
                closeText={t("general.close_now")}
                style={{ marginTop: 20 }}
              />
            )}
          </Card>
          <Row
            style={{
              border: "1px solid #e8e8e8",
              borderTop: "0",
              backgroundColor: "#f0f3f5",
              display: currentStep === 3 ? "block" : "none",
            }}
          >
            <Col style={{ textAlign: "right", padding: "10px" }}>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={fileList.length === 0}
                onClick={() => onDataImported(fileList)}
              >
                {t("migrationForm.import")}
              </Button>
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
}

ClaimMigrationForm.propTypes = {
  history: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  loadUOMs: PropTypes.func.isRequired,
  loadEmployees: PropTypes.func.isRequired,
  onTemplateDownloaded: PropTypes.func.isRequired,
  onDataImported: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  actionMsg: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  removeMessage: PropTypes.func.isRequired,
};
