import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ManageClinicVisitForm from "../../forms/Clinic/ManageClinicVisitForm.js";
import PropTypes from "prop-types";
import ClinicVisitModel from "../../model/Clinic/ClinicVisitModel";
import {
  getClinicVisit,
  addClinicVisit,
  updateClinicVisit,
  deleteClinicVisit,
  checkIsMedicalLimitReached,
  getDependant,
} from "../../../api/clinicVisitApi";
import { getClinicCode } from "../../../api/clinicApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newClinicVisit = ClinicVisitModel({});

export default function ManageClinicVisitPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [dependants, setDependants] = useState([]);
  const [clinicVisit, setClinicVisit] = useState(newClinicVisit);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const { ClinicTransKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function saveClinicVisit(values, attachment) {
    let toastStatus = 0;
    toastStatus =
      values.Status === 0
        ? clinicVisit.Status === 1
          ? 3
          : values.Status
        : values.Status;
    let clinicVisitSubmit = {
      ...clinicVisit,
      ...values,
      Remarks: values.Remarks.trim(),
    };

    if (attachment.data) {
      clinicVisitSubmit = {
        ...clinicVisitSubmit,
        Attaches: [...clinicVisit.Attaches, ...attachment.data],
      };
    }

    let upsertClinicVisit =
      clinicVisitSubmit.ClinicTransKey !== -1
        ? updateClinicVisit
        : addClinicVisit;

    setIsLoading(true);
    checkIsMedicalLimitReached(
      clinicVisitSubmit.ClinicApplicationDate.toDate(),
      clinicVisitSubmit.ClinicKey
    )
      .then((value) => {
        if (value && value.IsEntitled) {
          if (value.IsOverLimit) {
            setIsLoading(false);
            toast.error(t("clinicVisitForm.fully_utilized_medical_claims"));
          } else {
            upsertClinicVisit(clinicVisitSubmit)
              .then((result) => {
                if (result.data) {
                  if (isSaveAndNew) {
                    setIsDirty(false);
                    history("/home/clinicvisit/clinictrans", {
                      state: { force: true },
                    });
                    loadPageData(undefined);
                  } else {
                    setIsDirty(false);
                    history(
                      "/home/clinicvisit/clinictrans/" +
                        result.data.ClinicTransKey,
                      { state: { force: true } }
                    );
                    loadPageData(result.data.ClinicTransKey);
                  }

                  if (toastStatus === 1) {
                    toast.success(t("general.record_submitted_successfully"));
                  } else if (toastStatus === 2) {
                    toast.success(
                      t("general.record_request_cancel_successfully")
                    );
                  } else if (toastStatus === 3) {
                    toast.success(t("general.record_withdrawn_successfully"));
                  } else {
                    toast.success(t("general.record_saved_successfully"));
                  }
                }
              })
              .catch((error) => {
                setIsLoading(false);
                let message =
                  clinicVisitSubmit.ClinicTransKey !== -1
                    ? t("general.fail_to_update_record")
                    : t("general.fail_to_add_record");
                toast.error(message + error.message);
              });
          }
        } else {
          setIsLoading(false);
          let clinicType = clinics.filter(
            (x) => x.Key === clinicVisitSubmit.Clinic.value
          )[0].Type;
          if (clinicType === 1) {
            toast.error(
              t("clinicVisitForm.not_entitled_to_any_dental_benefit")
            );
          } else {
            toast.error(t("clinicVisitForm.not_entitled_to_medical_benefit"));
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
      });
  }

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/clinicvisitapproval");
      } else if (location.state.fromVoid) {
        history("/home/clinicvisitvoid");
      } else if (location.state.fromCancel) {
        history("/home/clinicvisitcancel");
      } else if (location.state.fromValidation) {
        history("/home/clinicvalidation");
      } else if (location.state.fromMedicalDeduction) {
        history("/home/medicaldeduction");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/clinicvisit", { state: location.state });
      } else {
        history("/home/clinicvisitdisplay");
      }
    } else {
      history("/home/clinicvisitdisplay");
    }
  }

  function onDeleteClinicVisit() {
    let editKey = ClinicTransKey;

    deleteClinicVisit(editKey)
      .then(() => {
        history("/home/clinicvisit/clinictrans", { state: { force: true } });
        loadPageData(undefined);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        let message = t("general.fail_to_delete_record");
        toast.error(message + error.message);
      });
  }

  function loadPageData(editKey) {
    let promises = [getClinicCode(), getDependant()];
    if (editKey > 0) {
      promises.push(getClinicVisit(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setClinics(values[0] ? values[0] : []);
        setDependants(values[1] ? values[1] : []);
        if (values[2]) {
          values[2].TransDate = new Date(values[2].TransDate);
          setIsOwner(
            isLoginUser(values[2].UserKey) || isEditor(values[2].CreatedBy)
          );
        } else {
          setIsOwner(true);
        }
        setClinicVisit(values[2] ? values[2] : newClinicVisit);

        let tempAttaches = values[2] ? values[2].Attaches : [];
        tempAttaches.forEach((attachment) => {
          attachment.uid = attachment.AttchKey;
          attachment.name = attachment.FileName;
          attachment.status = "done";
          attachment.url = attachment.UploadedUrl;
        });
        setFileList(tempAttaches);
        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly(
          location.state && location.state.fromAdmin
            ? location.state.fromAdmin
            : false
        );
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Clinics")
      .then((result) => {
        return result;
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  return (
    <ManageClinicVisitForm
      isLoading={isLoading}
      clinicVisit={clinicVisit}
      fileList={fileList}
      setFileList={setFileList}
      clinicSelectionData={clinics}
      dependantSelectionData={dependants}
      loadPageData={loadPageData}
      onSubmitted={saveClinicVisit}
      onDeleted={onDeleteClinicVisit}
      onCancelled={onCancelled}
      onDataChanged={setClinicVisit}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      onUploadAttachment={onUploadAttachment}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      onRemoveAttachment={onRemoveAttachment}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageClinicVisitPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
