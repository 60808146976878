import React, { useEffect, useState } from "react";
import { Modal, InputNumber, Row, Col, Input, Form } from "antd";
import { SelectionInput } from "../../../common/SelectionInput";
import PropTypes from "prop-types";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  stepPrecision,
  precisionDecimal,
} from "../../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const ManageTravelAuthorizationDetForm = ({
  isVisible,
  selectedDet,
  onCancel,
  onSave,
  form,
  uomData = [],
  claimTypesData = [],
  selectedCurrency,
  claimConfg,
  userCompCurr,
  currKey,
  exRate,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [selectedClaimType, setSelectedClaimType] = useState({});

  useEffect(() => {
    if (selectedDet) {
      const tempData = claimTypesData.find(
        (item) => item.ClaimTypeKey === selectedDet.ClaimTypeKey
      );

      setSelectedClaimType(tempData);
    }
    // eslint-disable-next-line
  }, [selectedDet]);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let travelDetSubmit = {
          ...selectedDet,
          ...{
            ClaimTypeKey:
              values.ClaimType && values.ClaimType.key !== undefined
                ? values.ClaimType.key
                : -1,
            ClaimTypeCodeClaimTypeDesc:
              values.ClaimType && values.ClaimType.label !== undefined
                ? values.ClaimType.label
                : "",
            UOMKey:
              values.UOM && values.UOM.key !== undefined ? values.UOM.key : -1,
            UOMCodeUOMDesc:
              values.UOM && values.UOM.label !== undefined
                ? values.UOM.label
                : "",
            Quantity: values.Quantity ? values.Quantity : 0,
            UnitCost: values.UnitCost ? values.UnitCost : 0,
            TotalAmount: values.TotalAmount ? values.TotalAmount : 0,
            ExRate: values.ExRate ? values.ExRate : 0,
            FuncTotalAmount: values.FuncTotalAmount
              ? values.FuncTotalAmount
              : 0,
            Remarks: values.Remarks ? values.Remarks : "",
          },
        };

        onSave(travelDetSubmit);
      })
      .catch(() => {});
  }

  function onQuantityChanged(value) {
    const unitCost = +form.getFieldValue("UnitCost");
    form.setFieldsValue({
      TotalAmount: value * unitCost,
      FuncTotalAmount: calcFuncTotalAmount(
        value * unitCost,
        exRate ? exRate : 1
      ),
    });
  }

  function onUnitCostChanged(value) {
    const quantity = +form.getFieldValue("Quantity");
    form.setFieldsValue({
      TotalAmount: quantity * value,
      FuncTotalAmount: calcFuncTotalAmount(
        quantity * value,
        exRate ? exRate : 1
      ),
    });
  }

  function calcFuncTotalAmount(origTotalAmount, exRate) {
    if (userCompCurr && userCompCurr.ConvertType) {
      if (userCompCurr.ConvertType === "*") {
        return Number((origTotalAmount * exRate).toFixed(2));
      } else if (userCompCurr.ConvertType === "/") {
        return Number((origTotalAmount / exRate).toFixed(2));
      } else {
        return origTotalAmount;
      }
    } else {
      return origTotalAmount;
    }
  }

  function onChangeClaimType(value) {
    //set unit cost and recalculate total
    const tempData = claimTypesData.find(
      (item) => item.ClaimTypeKey === value.value
    );

    let quantity = +form.getFieldValue("Quantity");
    let exRate = form.getFieldValue("ExRate");

    let unitCost =
      tempData && tempData.DefaultCost > 0 ? tempData.DefaultCost : 0;
    form.setFieldsValue({
      UnitCost: unitCost,
    });
    let totalAmount = quantity * unitCost;
    form.setFieldsValue({
      TotalAmount: totalAmount,
      FuncTotalAmount: calcFuncTotalAmount(totalAmount, exRate ? exRate : 1),
    });

    form.setFieldsValue({
      UOM:
        tempData.UOMKey > -1
          ? {
              key: tempData.UOMKey,
              label: tempData.UOMCode + " - " + tempData.UOMDesc,
            }
          : undefined,
    });

    //set selected claim type
    setSelectedClaimType(tempData);
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">
          {t("travelAuthorizationForm.travel_detail")}
        </span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      width={1000}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} md={16} lg={16} xl={16}>
            <Form.Item
              label={<span>{t("general.claim_type")}</span>}
              name="ClaimType"
              rules={[
                {
                  required: true,
                  message: t("general.claim_type_required_validation"),
                },
              ]}
              initialValue={
                selectedDet.ClaimTypeKey > 0
                  ? {
                      key: selectedDet.ClaimTypeKey,
                      label: selectedDet.ClaimTypeCodeClaimTypeDesc,
                    }
                  : undefined
              }
            >
              <SelectionInput
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                ref={React.createRef()}
                labelInValue={true}
                data={claimTypesData}
                valueProp={"ClaimTypeKey"}
                textProp={"ClaimTypeCodeDesc"}
                disabledProp={"Active"}
                placeholder={t("general.claim_type_placeholder")}
                onChange={onChangeClaimType}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label={<span>{t("claimTypesForm.unit_of_measurement")}</span>}
              name="UOM"
              rules={[
                {
                  required: true,
                  message: t(
                    "claimTypesForm.unit_of_measurement_required_validation"
                  ),
                },
              ]}
              initialValue={
                selectedDet.UOMKey > 0
                  ? {
                      key: selectedDet.UOMKey,
                      label: selectedDet.UOMCodeUOMDesc,
                    }
                  : undefined
              }
            >
              <SelectionInput
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                ref={React.createRef()}
                labelInValue={true}
                data={uomData}
                valueProp={"Key"}
                textProp={"CodeDesc"}
                disabledProp={"Active"}
                placeholder={t(
                  "claimTypesForm.unit_of_measurement_placeholder"
                )}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label={<span>{t("general.quantity")}</span>}
              name="Quantity"
              rules={[
                {
                  required: true,
                  message: t("general.quantity_required_validation"),
                },
                {
                  validator: (_, value) => {
                    if (value <= 0)
                      return Promise.reject(
                        new Error(t("claimSubmissionForm.quantity_must_be_>_0"))
                      );
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={selectedDet.Quantity}
            >
              <InputNumber
                style={{ width: "100%" }}
                step="0.1"
                min={0}
                precision={2}
                formatter={inputLocaleFormatter(2)}
                parser={inputLocaleParser(2)}
                onChange={onQuantityChanged}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label={
                <span>
                  {t("general.unit_cost")}
                  {selectedCurrency && selectedCurrency.CurrCode
                    ? " (" + selectedCurrency.CurrCode + ")"
                    : ""}
                </span>
              }
              name="UnitCost"
              rules={[
                {
                  required: true,
                  message: t("general.unit_cost_required_validation"),
                },
                {
                  validator: (_, value) => {
                    if (value <= 0)
                      return Promise.reject(
                        new Error(
                          t("claimSubmissionForm.unit_cost_must_be_>_0")
                        )
                      );
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={selectedDet.UnitCost}
            >
              <InputNumber
                style={{ width: "100%" }}
                step={stepPrecision(selectedCurrency.CurrCode)}
                min={0}
                precision={precisionDecimal(selectedCurrency.CurrCode)}
                prefix={
                  selectedCurrency && selectedCurrency.CurrSymb
                    ? selectedCurrency.CurrSymb
                    : ""
                }
                formatter={inputLocaleFormatter(
                  precisionDecimal(selectedCurrency.CurrCode)
                )}
                parser={inputLocaleParser(
                  precisionDecimal(selectedCurrency.CurrCode)
                )}
                onChange={onUnitCostChanged}
                disabled={
                  selectedClaimType && selectedClaimType.DefaultCost > 0
                }
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Item
              label={
                <span>
                  {t("general.total_amount")}
                  {selectedCurrency && selectedCurrency.CurrCode
                    ? " (" + selectedCurrency.CurrCode + ")"
                    : ""}
                </span>
              }
              name="TotalAmount"
              initialValue={selectedDet.TotalAmount}
            >
              <InputNumber
                style={{ width: "100%" }}
                precision={precisionDecimal(selectedCurrency.CurrCode)}
                prefix={
                  selectedCurrency && selectedCurrency.CurrSymb
                    ? selectedCurrency.CurrSymb
                    : ""
                }
                formatter={inputLocaleFormatter(
                  precisionDecimal(selectedCurrency.CurrCode)
                )}
                parser={inputLocaleParser(
                  precisionDecimal(selectedCurrency.CurrCode)
                )}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col
            xs={24}
            sm={24}
            md={{ span: 8, offset: 8 }}
            lg={{ span: 8, offset: 8 }}
            xl={{ span: 8, offset: 8 }}
            style={{
              display:
                claimConfg && claimConfg.IsMultiCurrency === true && currKey
                  ? ""
                  : "none",
            }}
          >
            <Form.Item
              label={<span>{t("general.exchange_rate")}</span>}
              name="ExRate"
              initialValue={exRate}
            >
              <InputNumber
                style={{ width: "100%" }}
                precision={6}
                disabled={true}
                formatter={inputLocaleFormatter(6)}
                parser={inputLocaleParser(6)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={8}
            lg={8}
            xl={8}
            style={{
              display:
                claimConfg && claimConfg.IsMultiCurrency === true && currKey
                  ? ""
                  : "none",
            }}
          >
            <Form.Item
              label={
                <span>
                  {t("general.total_amount")}
                  {userCompCurr && userCompCurr.CurrCode
                    ? " (" + userCompCurr.CurrCode + ")"
                    : ""}
                </span>
              }
              name="FuncTotalAmount"
              initialValue={selectedDet.FuncTotalAmount}
            >
              <InputNumber
                style={{ width: "100%" }}
                precision={precisionDecimal(userCompCurr.CurrCode)}
                prefix={userCompCurr.CurrSymb ? userCompCurr.CurrSymb : ""}
                formatter={inputLocaleFormatter(
                  precisionDecimal(userCompCurr.CurrCode)
                )}
                parser={inputLocaleParser(
                  precisionDecimal(userCompCurr.CurrCode)
                )}
                disabled={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={<span>{t("general.remarks")}</span>}
              name="Remarks"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: t("general.remarks_required_validation"),
                },
                {
                  max: 250,
                  message: t("general.remarks_max_length_validation"),
                },
              ]}
              initialValue={selectedDet.Remarks}
            >
              <TextArea
                placeholder={t("general.remarks_placeholder")}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ManageTravelAuthorizationDetForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedDet: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  uomData: PropTypes.array.isRequired,
  claimTypesData: PropTypes.array.isRequired,
  selectedCurrency: PropTypes.object.isRequired,
  claimConfg: PropTypes.object.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  currKey: PropTypes.bool.isRequired,
  exRate: PropTypes.number.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageTravelAuthorizationDetForm;
