import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import AppraisalSubmissionApprovalForm from "../../forms/Appraisal/AppraisalSubmissionApprovalForm";
import {
  getPendingAndApprovedAppraisalSubmissions,
  approveAppraisalSubmissions,
} from "../../../api/appraisalSubmissionApprovalApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function AppraisalSubmissionApprovalPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [appraisals, setAppraisals] = useState([]);
  const [selectedAppraisals, setSelectedAppraisals] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getAppraisalSubmissions, [currentYear]);

  function getAppraisalSubmissions() {
    setIsLoading(true);
    getPendingAndApprovedAppraisalSubmissions(currentYear)
      .then((value) => {
        setAppraisals(value ? value : []);
        setSelectedAppraisals([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewAppraisal() {
    history("/home/appraisalsubmission/appraisaltrans");
  }

  function onApproveAppraisalSubmissions(selectedAppraisal) {
    setIsLoading(true);

    approveAppraisalSubmissions(selectedAppraisal)
      .then(() => {
        getAppraisalSubmissions();
        toast.success(t("general.record_approved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_approve_record"), error);
      });
  }

  function viewAppraisal(appraisalTransHdrKey) {
    history(
      "/home/appraisalsubmission/appraisaltrans/" + appraisalTransHdrKey,
      { state: { fromApproval: true } }
    );
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <AppraisalSubmissionApprovalForm
      isLoading={isLoading}
      data={appraisals}
      selectedItems={selectedAppraisals}
      onAdd={onAddNewAppraisal}
      onView={viewAppraisal}
      onApproved={onApproveAppraisalSubmissions}
      onItemSelected={setSelectedAppraisals}
      setCurrentYear={setCurrentYear}
    />
  );
}

AppraisalSubmissionApprovalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
