import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "exrate/";

export function getMonthlyExRate(exDate) {
  return axios({
    method: "GET",
    url: base + "GetMonthlyExRate",
    headers: authHeader(),
    params: { exDate: exDate }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getLastMonthExRateDets(lastMthExDate) {
  return axios({
    method: "GET",
    url: base + "GetLastMonthExRateDets",
    headers: authHeader(),
    params: { lastMthExDate: lastMthExDate }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function isLastMonthExRateDetsExists(lastMthExDate) {
  return axios({
    method: "GET",
    url: base + "IsLastMonthExRateDetsExists",
    headers: authHeader(),
    params: { lastMthExDate: lastMthExDate }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addMonthlyExRate(exRate) {
  return axios({
    method: "POST",
    url: base + "AddMonthlyExRate",
    headers: authHeader(),
    data: exRate
  })
    .then()
    .catch(handleError);
}

export function updateMonthlyExRate(exRate) {
  return axios({
    method: "PUT",
    url: base + "UpdateMonthlyExRate",
    headers: authHeader(),
    params: { key: exRate.ExRateHdrKey },
    data: exRate
  })
    .then()
    .catch(handleError);
}
