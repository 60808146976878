import React, { useState, useEffect } from "react";
import { getAPRConfgUser } from "../../../api/aprConfgApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { toast } from "react-toastify";
import APRConfgForm from "../../forms/Master/APRConfgForm";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function APRConfgPage({ history }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [aprConfgs, setAprConfgs] = useState([]);
  const [filteredAprConfgs, setFilteredAprConfgs] = useState([]);
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const location = useLocation();
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOuKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  const [rowSelected, setRowSelected] = useState({});
  const [isActive, setIsActive] = useState(true);
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getItems, []);

  function getItems() {
    let promises = [getAPRConfgUser(), getOU(), getDepartment()];

    Promise.all(promises)
      .then((values) => {
        let tempData = values[0]
          ? values[0].filter((x) => x.IsLMStaff === false)
          : [];
        setAprConfgs(tempData);
        setFilteredAprConfgs(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey) &&
              (isActive === true ? x.IsActive === true : true)
          )
        );
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function editItem(userKey) {
    history("/home/aprconfgs/aprconfg/" + userKey, {
      state: {
        filterOuKey: ouKey,
        filterDeptKey: deptKey,
        currentPage: currentPage,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  function filterRecord(ouKey, deptKey, active) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setIsActive(active);
    setFilteredAprConfgs(
      aprConfgs.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (active === true ? x.IsActive === true : true)
      )
    );
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    setRowSelected(value);
  }

  return (
    <APRConfgForm
      isLoading={isLoading}
      aprConfgs={filteredAprConfgs}
      onEdit={editItem}
      allOus={allOus}
      allDepartments={allDepartments}
      filterRecord={filterRecord}
      ouKey={ouKey}
      deptKey={deptKey}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      form={form}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

APRConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
};
