export const LimitLevel = Object.freeze({
  COMPANY: Object.freeze({ Level: 0, Desc: "Company" }),
  DEPARTMENT: Object.freeze({ Level: 10, Desc: "Department" }),
  GRADE: Object.freeze({ Level: 20, Desc: "Grade" }),
  EMPLOYEE: Object.freeze({ Level: 30, Desc: "Employee" }),
});

export const LimitType = Object.freeze({
  NONE: Object.freeze({ Type: -1, Desc: "None" }),
  TRANS: Object.freeze({ Type: 5, Desc: "Transaction" }),
  MTH: Object.freeze({ Type: 10, Desc: "Monthly" }),
  YR: Object.freeze({ Type: 15, Desc: "Yearly" }),
});
