import { combineReport } from "../components/reports";

export function GetReportRoute(role) {
  return ReportMenu();
}

function ReportMenu() {
  return [
    {
      id: 52,
      route: "/home/report/EAForm",
      paramName: "EAForm",
      name: "EA Form",
      breadcrumbParentId: "5",
      menuParentId: 49,
      report: combineReport.StaffEAform,
    },
    {
      id: 53,
      route: "/home/report/PaySlip",
      paramName: "PaySlip",
      name: "PaySlip",
      breadcrumbParentId: "5",
      menuParentId: 49,
      report: combineReport.StaffPayslip,
    },
    {
      id: 54,
      route: "/home/report/Claim",
      paramName: "Claim",
      name: "Claim Summary",
      breadcrumbParentId: "5",
      menuParentId: 50,
      report: combineReport.StaffClaim,
    },
    {
      id: 55,
      route: "/home/report/ClaimDetails",
      paramName: "ClaimDetails",
      name: "Claim Details",
      breadcrumbParentId: "5",
      menuParentId: 50,
      report: combineReport.StaffClaimDetails,
    },
    {
      id: 56,
      route: "/home/report/LeaveConsumption",
      paramName: "LeaveConsumption",
      name: "Employee Leave Consumption",
      breadcrumbParentId: "5",
      menuParentId: 51,
      report: combineReport.StaffLeaveConsumption,
    },
    {
      id: 57,
      route: "/home/report/LeaveEntitlement",
      paramName: "LeaveEntitlement",
      name: "Employee Leave Summary",
      breadcrumbParentId: "5",
      menuParentId: 51,
      report: combineReport.StaffLeaveEntitlement,
    },
    {
      id: 58,
      route: "/home/report/LeaveEntitlementDetails",
      paramName: "LeaveEntitlementDetails",
      name: "Employee Leave Details",
      breadcrumbParentId: "5",
      menuParentId: 51,
      report: combineReport.StaffLeaveEntitlementDetails,
    },
    {
      id: 66,
      route: "/home/report/ClaimEntitlement",
      paramName: "ClaimEntitlement",
      name: "Claim Limit and Balance",
      breadcrumbParentId: "5",
      menuParentId: 50,
      report: combineReport.StaffClaimEntitlement,
    },
    {
      id: 104,
      route: "/home/report/VehicleRequisition",
      paramName: "VehicleRequisition",
      name: "Vehicle Requisition Details",
      breadcrumbParentId: "5",
      menuParentId: 103,
      report: combineReport.VehicleRequisition,
    },
    {
      id: 105,
      route: "/home/report/MeetingRoomRequisition",
      paramName: "MeetingRoomRequisition",
      name: "Meeting Room Requisition Details",
      breadcrumbParentId: "5",
      menuParentId: 103,
      report: combineReport.MeetingRoomRequisition,
    },
    {
      id: 134,
      route: "/home/report/PropertiesMaintenance",
      paramName: "PropertiesMaintenance",
      name: "Properties Maintenance Details",
      breadcrumbParentId: "5",
      menuParentId: 103,
      report: combineReport.PropertiesMaintenance,
    },
    {
      id: 108,
      route: "/home/report/AppraisalDetails",
      paramName: "AppraisalDetails",
      name: "Appraisal Details",
      breadcrumbParentId: "5",
      menuParentId: 107,
      report: combineReport.AppraisalDetails,
    },
    {
      id: 124,
      route: "/home/report/TrainingParticipants",
      paramName: "TrainingParticipants",
      name: "Training Participants",
      breadcrumbParentId: "5",
      menuParentId: 123,
      report: combineReport.TrainingParticipants,
    },
    {
      id: 125,
      route: "/home/report/EmployeeTrainings",
      paramName: "EmployeeTrainings",
      name: "Employee Trainings",
      breadcrumbParentId: "5",
      menuParentId: 123,
      report: combineReport.EmployeeTrainings,
    },
    {
      id: 136,
      route: "/home/report/JobRecruitmentDetails",
      paramName: "JobRecruitmentDetails",
      name: "Recruitment Details",
      breadcrumbParentId: "5",
      menuParentId: 135,
      report: combineReport.JobRecruitmentDetails,
    },
    {
      id: 138,
      route: "/home/report/TravelAuthorizationDetails",
      paramName: "TravelAuthorizationDetails",
      name: "Travel Authorization Details",
      breadcrumbParentId: "5",
      menuParentId: 137,
      report: combineReport.TravelAuthorizationDetails,
    },
    {
      id: 154,
      route: "/home/report/MedicalLimitDeductionDetails",
      paramName: "MedicalLimitDeductionDetails",
      name: "Medical Limit Deduction Details",
      breadcrumbParentId: "5",
      menuParentId: 153,
      report: combineReport.MedicalLimitDeductionDetails,
    },
    {
      id: 155,
      route: "/home/report/TravelClaim",
      paramName: "TravelClaim",
      name: "Travel Claim",
      breadcrumbParentId: "5",
      menuParentId: 50,
      report: combineReport.TravelClaim,
    },
  ];
}
