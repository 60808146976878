import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ManageLeaveAdjustmentForm from "../../forms/Admin/ManageLeaveAdjustmentForm";
import { toast } from "react-toastify";
import {
  getLeaveAdjustment,
  updateLeaveAdjustment,
} from "../../../api/leaveAdjustmentApi";
import { getSelectedUser } from "../../../api/roleAssignmentApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function ManageLeaveAdjustmentPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [leaveAdjustment, setLeaveAdjustment] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { UserKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = UserKey;
    if (editKey > 0) {
      promises.push(getLeaveAdjustment(editKey));
      promises.push(getSelectedUser(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setLeaveAdjustment(values[0] ? values[0] : []);
        setUserInfo(values[1] ? values[1] : {});
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [UserKey]);

  function saveLeaveAdjustment(values) {
    setIsDirty(false);
    let result = Object.keys(values).map(function (key) {
      return values[key];
    });

    let leaveAdjustmentubmit = {
      ...userInfo,
      LeaveAdjustment: result,
    };

    setIsLoading(true);
    updateLeaveAdjustment(leaveAdjustmentubmit)
      .then(() => {
        setIsLoading(false);

        let filterOuKey = 0,
          filterDeptKey = 0;

        if (location.state) {
          if (location.state.filterOuKey) {
            filterOuKey = location.state.filterOuKey;
          }
          if (location.state.filterDeptKey) {
            filterDeptKey = location.state.filterDeptKey;
          }
        }

        history("/home/leaveadjustments", {
          state: {
            filterOuKey: filterOuKey,
            filterDeptKey: filterDeptKey,
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          userInfo.UserKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.user_does_not_exists");
        toast.error(message + error.message);
      });
  }

  function cancelInputLeaveAdjustment() {
    let filterOuKey = 0,
      filterDeptKey = 0;

    if (location.state) {
      if (location.state.filterOuKey) {
        filterOuKey = location.state.filterOuKey;
      }
      if (location.state.filterDeptKey) {
        filterDeptKey = location.state.filterDeptKey;
      }
    }

    history("/home/leaveadjustments", {
      state: {
        filterOuKey: filterOuKey,
        filterDeptKey: filterDeptKey,
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageLeaveAdjustmentForm
      isLoading={isLoading}
      leaveAdjustment={leaveAdjustment}
      userInfo={userInfo}
      onChangeLeaveAdjustment={setLeaveAdjustment}
      onSubmitted={saveLeaveAdjustment}
      onCancelled={cancelInputLeaveAdjustment}
      history={history}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageLeaveAdjustmentPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
