import React, { useState, useEffect } from "react";
import * as rtApi from "../../../api/roleTypeApi";
import { toast } from "react-toastify";
import { RoleTypesForm } from "../../forms/Master/RoleTypesForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { ReassignUserRoleModal } from "../../common/ReassignUserRoleModal";
import RoleTypeModel from "../../model/Master/RoleTypeModel";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const newRoleType = RoleTypeModel({});

export default function RoleTypesPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [roleTypes, setRoleTypes] = useState([]);
  const [showReassignUserRoleForm, setShowReassignUserRoleForm] =
    useState(false);
  const [deletedRoleType, setDeletedRoleType] = useState(newRoleType);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getItems, []);

  function getItems() {
    rtApi
      .getAllRoleTypes()
      .then((result) => {
        setIsLoading(false);
        setRoleTypes(result);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/roletypes/roletype", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(roleTypeKey) {
    history("/home/roletypes/roletype/" + roleTypeKey, {
      state: { currentPage: currentPage },
    });
  }

  function onDelete(roleTypeKey) {
    let roleType = roleTypes.filter((x) => x.RoleTypeKey === roleTypeKey);
    setDeletedRoleType(roleType[0]);

    if (roleType[0].Users.length > 0) {
      setShowReassignUserRoleForm(true);
    } else {
      showDeleteConfirm("Role Type", roleTypeKey, deleteItem, t);
    }
  }

  function deleteItem(roleTypeKey, deletedItem = {}) {
    setIsLoading(true);
    rtApi
      .deleteRoleType(roleTypeKey, deletedItem)
      .then(() => {
        const newData = roleTypes.filter(
          (item) => item.RoleTypeKey !== roleTypeKey
        );
        setRoleTypes(newData);
        setIsLoading(false);
        setShowReassignUserRoleForm(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <div>
      <RoleTypesForm
        isLoading={isLoading}
        roleTypes={roleTypes}
        onAdd={addNewItem}
        onEdit={editItem}
        onDelete={onDelete}
        onRowSelected={onRowSelected}
        rowSelected={rowSelected}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />

      <ReassignUserRoleModal
        isVisible={showReassignUserRoleForm}
        deletedRoleType={deletedRoleType}
        roleTypes={roleTypes.filter(
          (x) => x.RoleTypeKey !== deletedRoleType.RoleTypeKey
        )}
        onSaved={deleteItem}
        onCancelled={() => setShowReassignUserRoleForm(false)}
      />
    </div>
  );
}

RoleTypesPage.propTypes = {
  history: PropTypes.func.isRequired,
};
