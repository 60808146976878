import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "LeaveAdjustment/";

export function getLeaveAdjustment(userKey) {
  return axios({
    method: "GET",
    url: base + "GetLeaveAdjustment",
    headers: authHeader(),
    params: { key: userKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateLeaveAdjustment(leaveAdjustment) {
  return axios({
    method: "PUT",
    url: base + "UpdateLeaveAdjustment",
    headers: authHeader(),
    //params: { key: leaveAdjustment.UserKey },
    data: leaveAdjustment
  })
    .then()
    .catch(handleError);
}
