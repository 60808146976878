export default function ProfileAttachmentModel({
  attchKey = -1,
  fileName = "",
  remarks = "",
  uploadedUrl = null,
  rowState = 0,
}) {
  return Object.freeze({
    AttchKey: attchKey,
    FileName: fileName,
    Remarks: remarks,
    UploadedUrl: uploadedUrl,
    RowState: rowState,
  });
}
