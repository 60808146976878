import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const baseRoomRequisition = API_ROOT + "roomrequisition/";

export async function getRoomRequisitions(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseRoomRequisition + "GetAllRoomRequisitionsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getRoomRequisitionByKey(transKey) {
  return axios
    .get(baseRoomRequisition + "GetRoomRequisitionByKey", {
      headers: authHeader(),
      params: {
        key: transKey,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllRoomsAvailability(isByYrMth, filterDate) {
  return axios
    .get(baseRoomRequisition + "GetAllRoomsAvailability", {
      headers: authHeader(),
      params: {
        isByYrMth: isByYrMth,
        filterDate: filterDate,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addRoomRequisition(roomRequisition) {
  return axios({
    method: "POST",
    url: baseRoomRequisition + "AddRoomRequisition",
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateRoomRequisition(roomRequisition) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "UpdateRoomRequisition?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteRoomRequisition(roomRequisition) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "DeleteRoomRequisition?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawRoomRequisition(roomRequisition) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "WithdrawRoomRequisition?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelRoomRequisition(
  roomRequisition,
  cancelReason
) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "RequestCancelRoomRequisition?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function assignRoomAndExpenses(roomRequisition) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "AssignRoomAndExpenses?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function completeRoomRequisition(roomRequisition) {
  return axios({
    method: "POST",
    url:
      baseRoomRequisition +
      "CompleteRoomRequisition?key=" +
      roomRequisition.RoomRequisitionKey,
    headers: authHeader(),
    data: roomRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}
