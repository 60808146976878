import React, { useEffect } from "react";
import { Modal, Input, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ApprovalReasonInput = ({
  isVisible,
  reasonType,
  onSaved,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [isVisible, form]);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        onSaved(values.Reason);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("approvalReason.Reason to " + reasonType)}
      okText={t("general.submit")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} preserve={false} layout="vertical">
        <Form.Item
          label={t("general.reason")}
          name="Reason"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("general.reason_required_validation"),
            },
          ]}
        >
          <Input.TextArea
            rows={3}
            placeholder={t("general.reason_placeholder")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

ApprovalReasonInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  reasonType: PropTypes.string.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export const ApprovalReasonModal = ApprovalReasonInput;
