import { Status } from "../common/Status";

export default function ClinicVisitModel({
  clinicTransKey = -1,
  transDate = new Date(),
  docNum = "Auto No.",
  clinicKey = -1,
  clinicApplicationDate = new Date(),
  remarks = "",
  status = 0,
  statusDesc = Status[0],
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  isdependant = false,
  dependantName = "",
  rowVersion = null,
  attaches = [],
}) {
  return Object.freeze({
    ClinicTransKey: clinicTransKey,
    TransDate: transDate,
    DocNum: docNum,
    ClinicKey: clinicKey,
    ClinicApplicationDate: clinicApplicationDate,
    Remarks: remarks,
    Status: status,
    StatusDesc: statusDesc,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    IsDependant: isdependant,
    DependantName: dependantName,
    RowVersion: rowVersion,
    Attaches: attaches,
  });
}
