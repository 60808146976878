import React, { useState, useEffect } from "react";
import * as aprapi from "../../../api/appraisalRatingApi";
import { toast } from "react-toastify";
import { AppraisalRatingForm } from "../../forms/Master/AppraisalRatingForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AppraisalRatingPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [appraisalRating, setAppraisalRating] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getAppraisalRating, []);

  function getAppraisalRating() {
    aprapi
      .getAllAppraisalRatings()
      .then((result) => {
        setIsLoading(false);
        setAppraisalRating(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setAppraisalRating([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/appraisalratings/appraisalrating", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(appraisalRatingKey) {
    history("/home/appraisalratings/appraisalrating/" + appraisalRatingKey, {
      state: { currentPage: currentPage },
    });
  }

  function onDelete(appraisalRatingKey) {
    showDeleteConfirm(
      "Appraisal Rating",
      appraisalRatingKey,
      deleteAppraisalRating,
      t
    );
  }

  function deleteAppraisalRating(appraisalRatingKey) {
    setIsLoading(true);
    aprapi
      .deleteAppraisalRating(appraisalRatingKey)
      .then(() => {
        const newData = appraisalRating.filter(
          (item) => item.ARKey !== appraisalRatingKey
        );
        setAppraisalRating(newData);
        setIsLoading(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <AppraisalRatingForm
      isLoading={isLoading}
      appraisalRating={appraisalRating}
      onAdd={addNewItem}
      onEdit={editItem}
      onDelete={onDelete}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

AppraisalRatingPage.propTypes = {
  history: PropTypes.func.isRequired,
};
