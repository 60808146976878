import React, { useState, useEffect } from "react";
import * as roomApi from "../../../api/roomApi";
import { toast } from "react-toastify";
import { RoomsForm } from "../../forms/Master/RoomsForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function RoomsPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [rooms, setRooms] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getRooms, []);

  function getRooms() {
    roomApi
      .getAllRooms()
      .then((result) => {
        setIsLoading(false);
        setRooms(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setRooms([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/meetingrooms/room", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(roomKey) {
    history("/home/meetingrooms/room/" + roomKey, {
      state: { currentPage: currentPage },
    });
  }

  function onDelete(roomKey) {
    showDeleteConfirm("Meeting Room", roomKey, deleteRoom, t);
  }

  function deleteRoom(roomKey) {
    setIsLoading(true);
    roomApi
      .deleteRoom(roomKey)
      .then(() => {
        const newData = rooms.filter((item) => item.RoomKey !== roomKey);
        setRooms(newData);
        setIsLoading(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <RoomsForm
      isLoading={isLoading}
      rooms={rooms}
      onAdd={addNewItem}
      onEdit={editItem}
      onDelete={onDelete}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

RoomsPage.propTypes = {
  history: PropTypes.func.isRequired,
};
