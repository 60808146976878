import React from "react";
import PropTypes from "prop-types";
import {
  NotificationOutlined,
  MailOutlined,
  MessageOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { Card, Spin, List, Button, Row, Col, Avatar, Checkbox } from "antd";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const NotificationForm = ({
  isLoading,
  data,
  onMark,
  onMarkSelected,
  onDelete,
  onDeleteSelected,
  onItemClick,
  selectedItems = [],
  onItemSelected,
}) => {
  const { t } = useTranslation();

  let allData = [];
  for (let i = 0; i < data.length; i++) {
    if (data[i].child.length > 0) {
      allData = [...allData, ...data[i].child];
    }
  }

  if (allData.length === 0) {
    return (
      <Card
        title={
          <span className="form-title">
            {t("notificationForm.your_notifications")}
          </span>
        }
      >
        <Spin spinning={isLoading}>
          <div className="notFound">
            <Avatar
              size={64}
              style={{
                display: "inline-block",
                marginBottom: 16,
              }}
              icon={<NotificationOutlined />}
            />
            <div>{t("notificationForm.all_caught_up")}</div>
          </div>
        </Spin>
      </Card>
    );
  }

  function selectItem(isSelected, key) {
    if (onItemSelected) {
      if (isSelected) {
        if (selectedItems.findIndex((x) => x.NotificationKey === key) === -1) {
          let selectedItem = allData.find((x) => x.NotificationKey === key);
          onItemSelected([...selectedItems, selectedItem]);
        }
      } else {
        onItemSelected(selectedItems.filter((x) => x.NotificationKey !== key));
      }
    }
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected([...allData]);
      } else {
        onItemSelected([]);
      }
    }
  }

  const iconEnum = {
    mail: <MailOutlined />,
    message: <MessageOutlined />,
    schedule: <ScheduleOutlined />,
  };

  return (
    <>
      <Row>
        <Col
          style={{
            height: "calc(100vh - 146px)",
            overflow: "auto",
            backgroundColor: "#e4e6eb",
          }}
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <span className="form-title">
                {t("notificationForm.your_notifications")}
              </span>
            }
          >
            <Spin spinning={isLoading}>
              <div className="notification">
                <List
                  itemLayout="horizontal"
                  dataSource={allData}
                  renderItem={(item, i) => {
                    const leftIcon = item.Icon ? (
                      <Avatar
                        style={{
                          backgroundColor: item.IconBgColor,
                          marginTop: 4,
                        }}
                        icon={iconEnum[item.Icon]}
                      />
                    ) : null;

                    const itemCls = classNames("item", {
                      read: item.IsRead,
                    });

                    return (
                      <List.Item
                        key={item.NotificationKey || i}
                        style={{
                          paddingTop: 12,
                          paddingBottom: 12,
                          paddingRight: 24,
                          paddingLeft: 24,
                          overflow: "hidden",
                          cursor: "pointer",
                          transition: "all .3s",
                        }}
                        className={itemCls}
                        actions={[
                          // eslint-disable-next-line react/jsx-key
                          <Button
                            type="link"
                            onClick={() => onMark && onMark(item)}
                            style={{
                              display: item.IsRead ? "none" : "",
                            }}
                          >
                            {t("notificationForm.mark_as_read")}
                          </Button>,
                          // eslint-disable-next-line react/jsx-key
                          <Button
                            type="link"
                            onClick={() => onDelete && onDelete(item)}
                          >
                            {t("notificationForm.delete")}
                          </Button>,
                        ]}
                      >
                        <div>
                          <Checkbox
                            style={{ marginRight: 15, marginBottom: 8 }}
                            checked={
                              selectedItems.findIndex(
                                (x) =>
                                  x.NotificationKey === item.NotificationKey
                              ) >= 0
                            }
                            onChange={(event) =>
                              selectItem(
                                event.target.checked,
                                item.NotificationKey
                              )
                            }
                          />
                        </div>

                        <List.Item.Meta
                          onClick={() => onItemClick && onItemClick(item)}
                          style={{ width: "100%", textAlign: "left" }}
                          avatar={leftIcon}
                          title={
                            <div
                              style={{
                                marginBottom: 8,
                                fontWeight: "normal",
                              }}
                            >
                              {item.Title}
                              <div
                                style={{
                                  float: "right",
                                  marginTop: -1.5,
                                  marginRight: 0,
                                  fontWeight: "normal",
                                }}
                              >
                                {item.Extra}
                              </div>
                            </div>
                          }
                          description={
                            <div>
                              <div style={{ fontSize: 12, lineHeight: 1.5 }}>
                                {item.Message}
                              </div>
                              <div
                                style={{
                                  margintop: 4,
                                  fontSize: 12,
                                  lineHeight: 1.5,
                                }}
                              >
                                {item.DateTime}
                              </div>
                            </div>
                          }
                        />
                      </List.Item>
                    );
                  }}
                />
              </div>
            </Spin>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          {allData.length > 0 ? (
            <div
              style={{
                fontSize: "18px",
                padding: "10px 15px 10px 48px",
                backgroundColor: "#ffffff",
                border: "1px solid #ccc",
              }}
            >
              <b>
                <Checkbox
                  indeterminate={
                    selectedItems.length &&
                    selectedItems.length < allData.length
                  }
                  checked={selectedItems.length === allData.length}
                  onChange={(event) => selectAllItems(event.target.checked)}
                >
                  {t("general.select_all")}
                </Checkbox>
                <Button
                  type="link"
                  disabled={
                    selectedItems.length === 0 ||
                    isLoading ||
                    selectedItems.findIndex((x) => x.IsRead === false) < 0
                  }
                  onClick={() => onMarkSelected(selectedItems)}
                >
                  {t("notificationForm.mark_as_read")}
                </Button>
                <Button
                  type="link"
                  disabled={selectedItems.length === 0 || isLoading}
                  onClick={() => onDeleteSelected(selectedItems)}
                >
                  {t("notificationForm.delete")}
                </Button>
              </b>
            </div>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

NotificationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  onMark: PropTypes.func.isRequired,
  onMarkSelected: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDeleteSelected: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onItemSelected: PropTypes.func.isRequired,
};

export default NotificationForm;
