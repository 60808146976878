import React from "react";
import PropTypes from "prop-types";
import { LeaveList } from "./LeaveList";
import { useTranslation } from "react-i18next";

const LeaveApplicationDisplayForm = ({
  isLoading,
  leaveApplications,
  isFromAdmin = false,
  onAdd,
  onView,
  onAttachmentClicked,
  setCurrentYear,
  pagination,
  setPagination,
  resetPagination,
}) => {
  const { t } = useTranslation();
  const footerElement = (footerValue) => (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      {t("general.total")} :{" "}
      <b style={{ color: "#0070cc" }}>
        {footerValue} {t("general.days")}
      </b>
    </div>
  );

  return (
    <div>
      <LeaveList
        isLoading={isLoading}
        data={leaveApplications}
        isFromAdmin={isFromAdmin}
        onAdd={onAdd}
        onItemViewed={onView}
        isShowStatus={true}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    </div>
  );
};

LeaveApplicationDisplayForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  leaveApplications: PropTypes.array.isRequired,
  isFromAdmin: PropTypes.bool,
  onAdd: PropTypes.func,
  onView: PropTypes.func.isRequired,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  resetPagination: PropTypes.func,
};

export default LeaveApplicationDisplayForm;
