import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedVoidClinics,
  addMedicalDeduction,
  updateMedicalDeduction,
  getMedicalClaimLimit,
} from "../../../api/medicalDeductionApi";
import { getUserCompCurrency } from "../../../api/currApi";
import { getEntitledClaimTypes } from "../../../api/claimTypeApi";
import MedicalDeductionForm from "../../forms/Clinic/MedicalDeductionForm";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function MedicalDeductionPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [clinicTrans, setClinicTrans] = useState([]);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [selectedClinicTrans, setSelectedClinicTrans] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [pagination, setPagination] = useState({
    current: 1,
    minIndex: 0,
    maxIndex: 100,
    pageSize: 100,
  });

  useEffect(getClinicTrans, [currentYear]);

  function getClinicTrans() {
    setIsLoading(true);
    let promises = [getApprovedVoidClinics(currentYear), getUserCompCurrency()];

    Promise.all(promises)
      .then((values) => {
        // reset pagination
        resetPagination();

        setClinicTrans(values[0] ? values[0] : []);
        setUserCompCurr(values[1] ? values[1] : {});
        setSelectedClinicTrans([]);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function getEntitledMedicalClaimType(empyKey, clinicType) {
    return getEntitledClaimTypes(empyKey).then((claimtypes) => {
      return claimtypes.filter((x) =>
        clinicType === 0
          ? x.IsMedicalClaim === true
          : clinicType === 1
          ? x.IsDentalClaim === true
          : false
      );
    });
  }

  function getMedicalClaimTypeLimit(
    claimTypeKey,
    userKey,
    empyKey,
    clinicApplicationDate
  ) {
    return getMedicalClaimLimit(
      claimTypeKey,
      userKey,
      empyKey,
      clinicApplicationDate
    );
  }

  function onAddNewClinic() {
    history("/home/clinicvisit/clinictrans");
  }

  function saveMedicalDeductionTrans(selectedClinics, values) {
    setIsLoading(true);

    let selectedClinicSubmit = { ...selectedClinics, ...values };

    let upsertMedicalDeduction =
      selectedClinicSubmit.MedicalDeductionTransKey !== 0
        ? updateMedicalDeduction
        : addMedicalDeduction;

    upsertMedicalDeduction(selectedClinicSubmit)
      .then(() => {
        getClinicTrans();
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.response.data.Message);
      });
  }

  function viewClinicTrans(clinicTransKey) {
    history("/home/clinicvisit/clinictrans/" + clinicTransKey, {
      state: { fromMedicalDeduction: true },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  return (
    <MedicalDeductionForm
      isLoading={isLoading}
      data={clinicTrans}
      selectedItems={selectedClinicTrans}
      onAdd={onAddNewClinic}
      onView={viewClinicTrans}
      onSave={saveMedicalDeductionTrans}
      onItemSelected={setSelectedClinicTrans}
      userCompCurr={userCompCurr}
      getEntitledMedicalClaimType={getEntitledMedicalClaimType}
      getMedicalClaimTypeLimit={getMedicalClaimTypeLimit}
      setCurrentYear={setCurrentYear}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
}

MedicalDeductionPage.propTypes = {
  history: PropTypes.func.isRequired,
};
