import { handleResponse, handleError } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "ESSUser/";

export function isUserExists(email) {
  return axios({
    method: "GET",
    url: base + "IsUserExists",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: { email: email }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function sendResetPasswordEmail(email) {
  return axios({
    method: "GET",
    url: base + "SendResetPasswordEmail",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: { email: email }
  })
    .then()
    .catch(handleError);
}

export function validateResetPasswordLink(encrypted) {
  return axios({
    method: "GET",
    url: base + "ValidateResetPasswordLink",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: { encrypted: encrypted }
  })
    .then()
    .catch(handleError);
}

export function resetPasswordThruLink(email, newPassword) {
  return axios({
    method: "GET",
    url: base + "ResetPasswordThruLink",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    params: { email: email, newPassword: newPassword }
  })
    .then()
    .catch(handleError);
}

export function changePassword() {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.UserEmail) {
    return axios({
      method: "GET",
      url: base + "ChangePassword",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      params: { email: auth.UserEmail }
    })
      .then()
      .catch(handleError);
  }
}
