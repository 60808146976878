import { Status } from "../common/Status";

export default function LeaveApplicationModel({
  leaveTransKey = -1,
  ltKey = -1,
  ltDate = new Date(),
  fromLADate = "",
  toLADate = "",
  totalDay = 0,
  docTotalDay = 0,
  totalCFDay = 0,
  docTotalCFDay = 0,
  laDate = "",
  remarks = "",
  status = 0,
  statusDesc = Status[0],
  docNum = "Auto No.",
  reason = "",
  leaveRule = 0,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  attaches = []
}) {
  return Object.freeze({
    LeaveTransKey: leaveTransKey,
    LTKey: ltKey,
    TransDate: ltDate,
    FromLeaveApplicationDate: fromLADate,
    ToLeaveApplicationDate: toLADate,
    TotalDay: totalDay,
    TotalCFDay: totalCFDay,
    DocTotalDay: docTotalDay,
    DocTotalCFDay: docTotalCFDay,
    LeaveApplicationDate: laDate,
    Remarks: remarks,
    Status: status,
    StatusDesc: statusDesc,
    DocNum: docNum,
    Reason: reason,
    LeaveRule: leaveRule,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    Attaches: attaches
  });
}
