import React from "react";
import { Modal, InputNumber, Row, Col, Input, Switch, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ManageAppraisalTemplateSectionForm = ({
  isVisible,
  section,
  onCancel,
  onSave,
  form,
}) => {
  const { t } = useTranslation();

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let sectionSubmit = {
          ...section,
          ...{
            ATDetTitle: values.ATDetTitle ? values.ATDetTitle : "",
            ATDetSubTitle: values.ATDetSubTitle ? values.ATDetSubTitle : "",
            ATDetWeight: values.ATDetWeight ? values.ATDetWeight : 0,
            ATDetComment: values.ATDetComment ? values.ATDetComment : false,
            ATDetInd: "H",
          },
        };

        onSave(sectionSubmit);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">
          {t("appraisalTemplateForm.section")}
        </span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("general.title")}
          name="ATDetTitle"
          rules={[
            {
              required: true,
              whitespace: true,
              message: t("general.title_required_validation"),
            },
            {
              max: 500,
              message: t("general.title_max_length_500_validation"),
            },
          ]}
          initialValue={section.ATDetTitle}
        >
          <Input placeholder={t("general.title_placeholder")} />
        </Form.Item>
        <Form.Item
          label={t("appraisalTemplateForm.subtitle")}
          name="ATDetSubTitle"
          rules={[
            {
              max: 500,
              message: t(
                "appraisalTemplateForm.subtitle_max_length_validation"
              ),
            },
          ]}
          initialValue={section.ATDetSubTitle}
        >
          <Input
            placeholder={t("appraisalTemplateForm.subtitle_placeholder")}
          />
        </Form.Item>
        <Row>
          <Col span={10}>
            <Form.Item
              label={t("appraisalTemplateForm.relative_weight")}
              name="ATDetWeight"
              rules={[
                {
                  required: true,
                  message: t(
                    "appraisalTemplateForm.relative_weight_required_validation"
                  ),
                },
              ]}
              initialValue={section.ATDetWeight}
            >
              <InputNumber
                step="1"
                precision={0}
                min={1}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value.replace("%", "")}
              />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item
              style={{ textAlign: "right", margin: 0 }}
              valuePropName="checked"
              name="ATDetComment"
              initialValue={section.ATDetComment}
            >
              <Switch
                checkedChildren={t(
                  "appraisalTemplateForm.show_comment_section"
                )}
                unCheckedChildren={t(
                  "appraisalTemplateForm.hide_comment_section"
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ManageAppraisalTemplateSectionForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageAppraisalTemplateSectionForm;
