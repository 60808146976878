import { Status } from "../common/Status";

export default function AppraisalModel({
  APKey = -1,
  APCode = "",
  APDesc = "",
  ATKey = -1,
  ATCode = "",
  ATDesc = "",
  IsSelfAppraisal = true,
  status = 0,
  StatusDesc = Status[0],
  FromSelfAppraisalDate = "",
  ToSelfAppraisalDate = "",
  SelfAppraisalDate = "",
  FromReviewDate = "",
  ToReviewDate = "",
  ReviewDate = "",
  FromNormalizeDate = new Date("1900-01-01"),
  ToNormalizeDate = new Date("1900-01-01"),
  NormalizeDate = "",
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  PublishedBy = -1,
  PublishedByCode = "",
  PublishedDate = new Date(),
  VoidedBy = -1,
  VoidedByCode = "",
  VoidedDate = new Date(),
  RowVersion = null,
  Filter = [],
  Appraisee = [],
}) {
  return Object.freeze({
    APKey: APKey,
    APCode: APCode,
    APDesc: APDesc,
    ATKey: ATKey,
    ATCode: ATCode,
    ATDesc: ATDesc,
    IsSelfAppraisal: IsSelfAppraisal,
    Status: status,
    StatusDesc: StatusDesc,
    FromSelfAppraisalDate: FromSelfAppraisalDate,
    ToSelfAppraisalDate: ToSelfAppraisalDate,
    SelfAppraisalDate: SelfAppraisalDate,
    FromReviewDate: FromReviewDate,
    ToReviewDate: ToReviewDate,
    ReviewDate: ReviewDate,
    FromNormalizeDate: FromNormalizeDate,
    ToNormalizeDate: ToNormalizeDate,
    NormalizeDate: NormalizeDate,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    PublishedBy: PublishedBy,
    PublishedByCode: PublishedByCode,
    PublishedDate: PublishedDate,
    VoidedBy: VoidedBy,
    VoidedByCode: VoidedByCode,
    VoidedDate: VoidedDate,
    RowVersion: RowVersion,
    Filter: Filter,
    Appraisee: Appraisee,
  });
}
