import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "claimapproval/";

export function getPendingApprovalClaims() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalClaims",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedClaims(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedClaims",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidClaims(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidClaims",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledClaims(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledClaims",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedClaims(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedClaims",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveClaims(claimTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveClaims",
    headers: authHeader(),
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectClaims(claimTrans, rejectReason, isAllowAmend) {
  return axios({
    method: "POST",
    url: base + "RejectClaims",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function voidClaims(claimTrans, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidClaims",
    headers: authHeader(),
    params: { reason: voidReason },
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledClaims(claimTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledClaims",
    headers: authHeader(),
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledClaims(claimTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledClaims",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function validateClaims(claimTrans) {
  return axios({
    method: "POST",
    url: base + "ValidateClaims",
    headers: authHeader(),
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function invalidateClaims(claimTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "InvalidateClaims",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: claimTrans,
  })
    .then()
    .catch(handleError);
}

export function checkPayrollMonthEndClose(claimTrans) {
  return axios({
    method: "POST",
    url: base + "CheckPayrollMthEndClose",
    headers: authHeader(),
    data: claimTrans,
  })
    .then(handleResponse)
    .catch(handleError);
}
