import React from "react";
import { EditOutlined } from "@ant-design/icons";
import { Spin, Card, Button, Avatar } from "antd";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

export default function ProfileWidget({ isLoading, userProfile }) {
  const { t } = useTranslation();

  return (
    <Card
      title={
        <span style={{ color: "#0070cc" }}>{t("profileWidget.profile")}</span>
      }
      className="dashboard-card"
    >
      <Spin
        style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
      />
      <div style={{ display: !isLoading ? "block" : "none" }}>
        <div style={{ textAlign: "center" }}>
          {/* <div className="quarto-profile-image">
            <img src={userProfile.profileImage} alt="LintraMax" />
          </div> */}
          <Avatar size={128} src={userProfile.profileImage} />
          <h3
            style={{ marginTop: "15px", marginBottom: "15px", lineHeight: "1" }}
          >
            {userProfile.displayName}
          </h3>
          <div style={{ height: "150px", overflowY: "auto" }}>
            <table
              style={{ textAlign: "left", margin: "auto", lineHeight: "1.3" }}
            >
              <tbody>
                <tr>
                  <td>{t("general.department")}</td>
                  <td>&nbsp;:&nbsp;</td>
                  <td className="dashboard-label">{userProfile.Department}</td>
                </tr>
                <tr>
                  <td>{t("general.position")}</td>
                  <td>&nbsp;:&nbsp;</td>
                  <td className="dashboard-label">{userProfile.Position}</td>
                </tr>
                <tr>
                  <td>{t("general.grade")}</td>
                  <td>&nbsp;:&nbsp;</td>
                  <td className="dashboard-label">{userProfile.Grade}</td>
                </tr>
                <tr>
                  <td>{t("profileWidget.contact_no")}</td>
                  <td>&nbsp;:&nbsp;</td>
                  <td className="dashboard-label">{userProfile.ContactNo}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="dashboard-card-button" style={{ textAlign: "right" }}>
          <Link to={{ pathname: "profile" }}>
            <Button type="primary" icon={<EditOutlined />}>
              {t("profileWidget.update")}
            </Button>
          </Link>
        </div>
      </div>
    </Card>
  );
}

ProfileWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userProfile: PropTypes.object.isRequired,
};
