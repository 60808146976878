import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageRoleTypesPermissionByForm from "../../forms/Master/ManageRoleTypesPermissionByForm";
import PropTypes from "prop-types";
import * as menuapi from "../../../api/menuApi";
import * as roleapi from "../../../api/roleTypeApi";
import { useParams } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newRoleTypesPermissionByForm = [];

export default function ManageRoleTypesPermissionByFormPage({
  history,
  setIsDirty,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [roleTypesPermissionByForm, setRoleTypesPermissionByForm] = useState(
    newRoleTypesPermissionByForm
  );
  const [menuName, setMenuName] = useState("");
  const [roleType, setRoleType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { MenuKey } = useParams();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = MenuKey;
    if (editKey > 0) {
      promises.push(menuapi.getMenuPermByKey(editKey));
      promises.push(menuapi.getMenuCode(editKey));
      promises.push(roleapi.getAllRoleTypes());
    }

    Promise.all(promises)
      .then((values) => {
        setRoleTypesPermissionByForm(values[0]);
        setMenuName(values[1] ? values[1] : "");
        setRoleType(values[2] ? values[2] : "");
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [MenuKey]);

  function saveRoleTypesPermissionByForm(values) {
    let roleTypesPermissionByFormSubmit = {
      ...values,
    };

    let upsertRoleTypesPermissionByForm =
      menuapi.updateRoleTypesPermissionByForm;
    setIsLoading(true);
    upsertRoleTypesPermissionByForm(roleTypesPermissionByFormSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/roletypesperm", { state: { force: true } });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message = t("general.fail_to_update_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputRoleTypesPermissionByForm() {
    history("/home/roletypesperm");
  }

  function updateRoleTypesPermissionByForm(values) {
    setRoleTypesPermissionByForm(values.checkedRoleList);
  }

  return (
    <ManageRoleTypesPermissionByForm
      isLoading={isLoading}
      roleTypesPermissionByForm={roleTypesPermissionByForm}
      onSubmitted={saveRoleTypesPermissionByForm}
      onCancelled={cancelInputRoleTypesPermissionByForm}
      setIsDirty={setIsDirty}
      menuName={menuName.Code}
      menuKey={MenuKey}
      roleType={roleType}
      onDataChanged={updateRoleTypesPermissionByForm}
      form={form}
    />
  );
}

ManageRoleTypesPermissionByFormPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
