import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Excel from "exceljs";
import FileSaver from "file-saver";
import ClaimMigrationForm from "../../forms/Claim/ClaimMigrationForm";
import { getAllClaimTypes } from "../../../api/claimTypeApi";
import { getUOM } from "../../../api/uomApi";
import { getESSEmployee } from "../../../api/employeeApi";
import { importMigrationData } from "../../../api/claimMigrationApi";
import { attachFiles } from "../../../api/fileUploadApi";
import { useTranslation } from "react-i18next";

export default function ClaimMigrationPage({ history }) {
  const { t } = useTranslation();
  const [claimTypes, setClaimTypes] = useState([]);
  const [uoms, setUOMs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [actionMsg, setActionMsg] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(validatePreCondition, [currentStep]);

  function validatePreCondition() {
    if (currentStep === 0) loadClaimTypes();
    if (currentStep === 1) loadUOMs();
    if (currentStep === 2) loadEmployees();
  }

  function loadClaimTypes() {
    setIsLoading(true);
    getAllClaimTypes()
      .then((value) => {
        setClaimTypes(value.filter((x) => x.Active));
        setIsLoading(false);
        if (value && value.length > 0) {
          setCurrentStep(1);
          removeMessage();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("migrationForm.fail_to_load_claim_types"), error);
      });
  }

  function loadUOMs() {
    setIsLoading(true);
    getUOM()
      .then((value) => {
        setUOMs(value);
        setIsLoading(false);
        if (value && value.length > 0) {
          setCurrentStep(2);
          removeMessage();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("migrationForm.fail_to_load_unit_of_measurements"),
          error
        );
      });
  }

  function loadEmployees() {
    setIsLoading(true);
    getESSEmployee()
      .then((value) => {
        setEmployees(value);
        setIsLoading(false);
        if (value && value.length > 0) {
          setCurrentStep(3);
          removeMessage();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("migrationForm.fail_to_load_employee"), error);
      });
  }

  function downloadTemplate() {
    setIsLoading(true);
    //set timeout so that loading can be shown before start generating template
    //without timeout, setState will be blocked until generate template completed
    setTimeout(function () {
      generateTemplate();
    }, 500);
  }

  function generateTemplate() {
    let workbook = new Excel.Workbook();

    createIdentityWorksheet(workbook);
    createEmployeeWorksheet(workbook);
    createClaimTypeWorksheet(workbook);
    createUOMWorksheet(workbook);
    createSubmissionWorksheet(workbook).then(() => {
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          FileSaver.saveAs(new Blob([buffer]), "ClaimMigration_Template.xlsx");
          setIsLoading(false);
          removeMessage();
        })
        .catch((err) => {
          setIsLoading(false);
          showErrorMessage(t("migrationForm.error_writing_excel_export"), err);
        });
    });
  }

  function createIdentityWorksheet(workbook) {
    let identityWS = workbook.addWorksheet("Identity");

    identityWS.addRow(["ESS_Lintr@Max85_Claim"]);
    identityWS.state = "veryHidden";
  }

  function createEmployeeWorksheet(workbook) {
    let empyWS = workbook.addWorksheet("Employee");
    empyWS.columns = [
      { header: "User Key", key: "UserKey" },
      { header: "Employee ID_Name", key: "EmpyID_Name", width: 60 },
    ];

    let empyData = employees.map((x) => {
      return {
        UserKey: x.UserKey,
        EmpyID_Name: x.EmpyID + " - " + x.EmpyName
      };
    });
    empyWS.addRows(empyData);
    //hide worksheet so that user can't modify setup data
    empyWS.state = "veryHidden";
  }

  function createClaimTypeWorksheet(workbook) {
    let claimTypeWS = workbook.addWorksheet("ClaimType");
    claimTypeWS.columns = [
      { header: "Key", key: "ClaimTypeKey" },
      { header: "Claim Type Code", key: "ClaimTypeCode", width: 20 },
      { header: "Description", key: "ClaimTypeDesc", width: 50 },
    ];

    let claimData = claimTypes.map((x) => {
      return {
        ClaimTypeKey: x.ClaimTypeKey,
        ClaimTypeCode: x.ClaimTypeCode,
        ClaimTypeDesc: x.ClaimTypeDesc,
      };
    });
    claimTypeWS.addRows(claimData);
    //hide worksheet so that user can't modify setup data
    claimTypeWS.state = "veryHidden";
  }

  function createUOMWorksheet(workbook) {
    let uomWS = workbook.addWorksheet("UOM");
    uomWS.columns = [
      { header: "Key", key: "UOMKey" },
      { header: "Claim Type Code", key: "UOMCode", width: 20 },
      { header: "Description", key: "UOMDesc", width: 50 },
    ];

    let uomData = uoms.map((x) => {
      return {
        UOMKey: x.Key,
        UOMCode: x.Code,
        UOMDesc: x.Desc,
      };
    });
    uomWS.addRows(uomData);
    //hide worksheet so that user can't modify setup data
    uomWS.state = "veryHidden";
  }

  async function createSubmissionWorksheet(workbook) {
    let submissionWS = workbook.addWorksheet("Claim Submission");

    submissionWS.columns = [
      {
        header: "Claim Year",
        key: "ClaimYear",
        width: 15,
      },
      {
        header: "Claim Month",
        key: "ClaimMonth",
        width: 15,
      },
      {
        header: "Employee",
        key: "User",
        width: 60,
      },
      {
        header: "Claim Type",
        key: "ClaimType",
        width: 20,
      },
      {
        header: "Unit of Measurement",
        key: "UOM",
        width: 20,
      },
      {
        header: "Quantity",
        key: "Qty",
        width: 20,
        style: { numFmt: "#,##0.00" },
      },
      {
        header: "Unit Cost (RM)",
        key: "UnitCost",
        width: 20,
        style: { numFmt: "#,##0.00" },
      },
      {
        header: "Remarks",
        key: "Remarks",
        width: 100,
      },
    ];

    //unlock input columns (by default it is locked)
    submissionWS.getColumn("ClaimYear").protection = { locked: false };
    submissionWS.getColumn("ClaimMonth").protection = { locked: false };
    submissionWS.getColumn("User").protection = { locked: false };
    submissionWS.getColumn("ClaimType").protection = { locked: false };
    submissionWS.getColumn("UOM").protection = { locked: false };
    submissionWS.getColumn("Qty").protection = { locked: false };
    submissionWS.getColumn("UnitCost").protection = { locked: false };
    submissionWS.getColumn("Remarks").protection = { locked: false };

    //lock header row only
    submissionWS.getRow(1).protection = { locked: true };

    //set data validation for input columns
    //row 1 is header so start with row 2, set max to 10000 bcs more than that the file will become unreadable
    //set to 5000 for now to improve speed when saving edited file
    for (var i = 2; i <= 5000; i++) {
      let row = submissionWS.getRow(i);

      row.getCell("ClaimYear").dataValidation = {
        type: "whole",
        operator: "between",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [1900, 9999],
        errorStyle: "error",
        errorTitle: "Year",
        error: "The value must between 1900 and 9999",
        promptTitle: "Year",
        prompt: "The value must between 1900 and 9999",
      };

      row.getCell("ClaimMonth").dataValidation = {
        type: "whole",
        operator: "between",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [1, 12],
        errorStyle: "error",
        errorTitle: "Month",
        error: "The value must between 1 and 12",
        promptTitle: "Month",
        prompt: "The value must between 1 and 12",
      };

      row.getCell("User").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ["Employee!$B$2:$B$65535"],
        errorStyle: "error",
        errorTitle: "Employee",
        error: "The value must be selected from the list",
      };

      row.getCell("ClaimType").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ["ClaimType!$B$2:$B$65535"],
        errorStyle: "error",
        errorTitle: "Claim Type",
        error: "The value must be selected from the list",
      };

      row.getCell("UOM").dataValidation = {
        type: "list",
        showErrorMessage: true,
        formulae: ["UOM!$B$2:$B$65535"],
        errorStyle: "error",
        errorTitle: "Unit of Measurement",
        error: "The value must be selected from the list",
      };

      row.getCell("Qty").dataValidation = {
        type: "decimal",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        errorStyle: "error",
        errorTitle: "Quantity",
        error: "The value must be greater than 0",
        promptTitle: "Quantity",
        prompt: "The value must be greater than 0",
      };

      row.getCell("UnitCost").dataValidation = {
        type: "decimal",
        operator: "greaterThan",
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0],
        errorStyle: "error",
        errorTitle: "Unit Cost",
        error: "The value must be greater than 0",
        promptTitle: "Unit Cost",
        prompt: "The value must be greater than 0",
      };
    }

    await submissionWS.protect("Lintr@Max85");
  }

  function importData(fileList) {
    let file = fileList.length > 0 ? fileList[0] : undefined;
    if (file) {
      setIsLoading(true);
      removeMessage();

      let attachmentID = "Claim_" + new Date().getTime().toString();
      let formData = new FormData();
      formData.append("files[]", file);

      attachFiles(formData, "Migration", attachmentID)
        .then((result) => {
          importMigrationData(result.data[0].UploadedUrl)
            .then(() => {
              setIsLoading(false);
              setActionMsg(t("migrationForm.data_imported_successfully"));
              setFileList([]);
              setIsError(false);
            })
            .catch((err) => {
              setIsLoading(false);
              showErrorMessage(t("migrationForm.fail_to_import_data"), err);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          showErrorMessage(t("migrationForm.fail_to_upload_file"), err);
        });
    }
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    setActionMsg(message + (errorMsg ? errorMsg : ""));
    setIsError(true);
  }

  function removeMessage() {
    setActionMsg("");
    setIsError(false);
  }

  return (
    <ClaimMigrationForm
      history={history}
      isLoading={isLoading}
      currentStep={currentStep}
      loadUOMs={loadUOMs}
      loadEmployees={loadEmployees}
      onTemplateDownloaded={downloadTemplate}
      onDataImported={importData}
      fileList={fileList}
      setFileList={setFileList}
      actionMsg={actionMsg}
      isError={isError}
      removeMessage={removeMessage}
    />
  );
}

ClaimMigrationPage.propTypes = {
  history: PropTypes.func.isRequired,
};
