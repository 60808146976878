import React, { useState } from "react";
import { Modal, InputNumber, Row, Col, Alert, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const EntitlementRuleForm = ({
  isVisible,
  entitlementRule,
  onCancel,
  onSave,
  form,
  gradeSelectionData = [],
  onValidateEntitlementRule,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [showErorrMsg, setshowErorrMsg] = useState(false);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let entitlementRuleSubmit = {
          ...entitlementRule,
          ...{
            EmpyGradeKey:
              values.Ref && values.Ref.key !== undefined ? values.Ref.key : -1,
            EmpyGradeCodeGradeDesc:
              values.Ref && values.Ref.label !== undefined
                ? values.Ref.label
                : "",
            CarryForwardDay: values.CarryForwardDay
              ? values.CarryForwardDay
              : 0,
            EntitledDay: values.EntitledDay ? values.EntitledDay : 0,
            EffectiveAfter: values.EffectiveAfter ? values.EffectiveAfter : 0,
          },
        };

        if (onValidateEntitlementRule(entitlementRuleSubmit)) {
          setshowErorrMsg(true);
        } else {
          onSave(entitlementRuleSubmit);
        }
      })
      .catch(() => {});
  }

  function handleClose() {
    setshowErorrMsg(false);
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">
          {t("leaveTypesForm.entitlement_rule")}
        </span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      afterClose={handleClose}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Form.Item
          label={t("general.grade")}
          name="Ref"
          rules={[
            { required: true, message: t("general.grade_required_validation") },
          ]}
          initialValue={
            entitlementRule
              ? {
                  key: entitlementRule.EmpyGradeKey,
                  label: entitlementRule.EmpyGradeCodeGradeDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            ref={React.createRef()}
            data={gradeSelectionData}
            placeholder={t("general.grade_placeholder")}
            labelInValue={true}
            valueProp={"Key"}
            textProp={"CodeDesc"}
            disabledProp={"Active"}
            disabled={entitlementRule.RcdType === 0 ? false : true}
          />
        </Form.Item>
        <Row>
          <Col span={10}>
            <Form.Item
              label={t("leaveTypesForm.effective_after_months")}
              name="EffectiveAfter"
              initialValue={entitlementRule.EffectiveAfter}
            >
              <InputNumber
                step="1"
                precision={0}
                min={0}
                formatter={inputLocaleFormatter(0)}
                parser={inputLocaleParser(0)}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={t("leaveTypesForm.day_entitled")}
              name="EntitledDay"
              initialValue={entitlementRule.EntitledDay}
            >
              <InputNumber
                step="1"
                precision={0}
                min={0}
                formatter={inputLocaleFormatter(0)}
                parser={inputLocaleParser(0)}
              />
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              label={t("leaveTypesForm.carry_forward")}
              name="CarryForwardDay"
              initialValue={entitlementRule.CarryForwardDay}
            >
              <InputNumber
                step="1"
                precision={0}
                min={0}
                formatter={inputLocaleFormatter(0)}
                parser={inputLocaleParser(0)}
              />
            </Form.Item>
          </Col>
        </Row>
        {showErorrMsg ? (
          <Alert
            message={t("leaveTypesForm.duplicate_entitlement_rule")}
            type="error"
            afterClose={handleClose}
            closable
            showIcon
            closeText={t("general.close_now")}
          />
        ) : null}
      </Form>
    </Modal>
  );
};

EntitlementRuleForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  entitlementRule: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  gradeSelectionData: PropTypes.array.isRequired,
  onValidateEntitlementRule: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default EntitlementRuleForm;
