import React from "react";
import { ENVIRONMENT } from "../../api/api-config";

const srcUrl =
  ENVIRONMENT === "qa"
    ? "https://quartodochrqa.z23.web.core.windows.net/HTML/quarto-hr-frequently-asked-question-faq.html"
    : "https://quartodoc2.z23.web.core.windows.net/HTML/quarto-hr-frequently-asked-question-faq.html";

export default function KnowledgeBasePage() {
  return (
    <div className="iframe-container">
      <iframe title="iframe" src={srcUrl}></iframe>
    </div>
  );
}
