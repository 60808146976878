import React, { useState, useEffect } from "react";
import { CheckCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import {
  Input,
  Row,
  Col,
  Button,
  Card,
  Spin,
  DatePicker,
  Upload,
  Divider,
  Descriptions,
  Modal,
  Checkbox,
  message,
  Tag,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { RowState } from "../../model/common/RowState";
import { ReportViewer } from "../../reports/ReportViewer";
import { getEmpyInfo } from "../../../api/reportApi";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { confirm } = Modal;

const ManageClinicVisitForm = ({
  isLoading,
  clinicVisit,
  fileList,
  setFileList,
  clinicSelectionData,
  dependantSelectionData,
  loadPageData,
  onSubmitted,
  onDeleted,
  onCancelled,
  onDataChanged,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  onUploadAttachment,
  isOwner,
  isViewOnly = false,
  form,
  onRemoveAttachment,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const REPORT_NAME = "Medical Chit";
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [reportParam, setReportParam] = useState({});
  const [empyInfo, setEmpyInfo] = useState(null);
  const [showMedicalChit, setShowMedicalChit] = useState(false);
  const [reportTitle, setReportTitle] = useState(
    t("clinicVisitForm.medical_chit")
  );

  const isSystemRcd = clinicVisit.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(clinicVisit.Status);
  const isSubmitted = isStatusSubmitted(clinicVisit.Status);
  const isVoid = isStatusVoid(clinicVisit.Status);
  const isApproved =
    isStatusPartialApproved(clinicVisit.Status) ||
    isStatusApproved(clinicVisit.Status);
  const isRequestCancelRejected =
    isApproved &&
    clinicVisit.RejectRequestCancelBy > 0 &&
    clinicVisit.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen &&
    clinicVisit.Reason.length > 0 &&
    (clinicVisit.RejectedBy > 0 || clinicVisit.ValidatedBy > 0);

  const isShowReason =
    isStatusVoid(clinicVisit.Status) ||
    isStatusRejected(clinicVisit.Status) ||
    isStatusRequestCancel(clinicVisit.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { ClinicTransKey } = useParams();
  Form.useWatch("IsDependant", form);

  useEffect(() => {
    loadPageData(ClinicTransKey, form.resetFields);
    // eslint-disable-next-line
  }, [ClinicTransKey]);

  useEffect(() => {
    let promises = [getEmpyInfo()];

    Promise.all(promises)
      .then((data) => {
        setEmpyInfo(data[0] ? data[0][0] : {});
      })
      .catch(() => {
        toast.error(t("general.fail_to_load_report"));
      });
  }, [t]);

  const handleSubmit = () => {
    handleSubmitFunc(0, clinicVisit.Reason); //to maintain rejected reason for amendment when save but not submit
  };

  function handleSubmitFunc(statusAction, cancelReason = "") {
    form
      .validateFields()
      .then((values) => {
        let tempValue = Object.assign({}, values);
        tempValue.Status = statusAction;
        tempValue.ClinicKey = values.Clinic.key;
        tempValue.Reason = cancelReason;
        if (values.IsDependant) {
          tempValue.DependantName = values.DependantName.key;
        }
        uploadAttachments()
          .then((result) => {
            onSubmitted(tempValue, result);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    handleSubmitFunc(2, reason);
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        clinicVisit.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        let newData = clinicVisit.Attaches.map((x) => {
          return x.uid === file.uid && x.RowState !== RowState.DELETED
            ? { ...x, RowState: RowState.DELETED }
            : x;
        });
        onDataChanged({ ...clinicVisit, Attaches: newData });

        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon: isOpen && isOwner,
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("clinicVisitForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk() {
        onDeleted();
      },
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("clinicVisitForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk() {
        handleSubmitFunc(0);
      },
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("clinicVisitForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmitFunc(1);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function printMedicalChit() {
    let chitTitle =
      clinicVisit.ClinicType === 1
        ? t("clinicVisitForm.dental_chit")
        : t("clinicVisitForm.medical_chit");
    setReportTitle(chitTitle);

    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );
    setReportParam({
      ESSUserKey: empyInfo.UserKey,
      ClinicTransKey: ClinicTransKey,
    });
    setReportGenerate(true);
    setShowMedicalChit(true);
  }

  function onCloseReportModal() {
    setShowMedicalChit(false);
  }

  function onChangeIsDependant() {
    form.resetFields(["DependantName"]);

    let tempClinicVisit = {
      DependantName: "",
    };

    onDataChanged({ ...clinicVisit, ...tempClinicVisit });
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <>
                <span className="form-title">{t("general.clinic_visit")}</span>
                <CheckCircleTwoTone
                  twoToneColor="#52c41a"
                  style={{
                    paddingLeft: 10,
                    display: clinicVisit.IsVerified ? "" : "none",
                  }}
                />
                <Tag
                  color="green"
                  style={{
                    marginLeft: 10,
                    display:
                      clinicVisit.IsRequireValidation && clinicVisit.IsValidated
                        ? ""
                        : "none",
                  }}
                >
                  {t("general.validated")}
                </Tag>
              </>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={t("general.document_no")}
                      name="DocNum"
                      initialValue={clinicVisit.DocNum}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t("general.status")}
                      name="StatusDesc"
                      initialValue={Status[clinicVisit.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col
                    span={24}
                    style={{
                      display: isShowReason ? "" : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        isRequestCancelRejected
                          ? t("general.reason_of_cancel_request_rejected")
                          : t("general.reason")
                      }
                      name="Reason"
                      initialValue={clinicVisit.Reason}
                    >
                      <TextArea
                        disabled={true}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form.Item
                      label={t("general.clinic")}
                      name="Clinic"
                      rules={[
                        {
                          required: true,
                          message: t("general.clinic_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !clinicSelectionData.some(
                                (x) => x.Key === value.value
                              )
                            ) {
                              return Promise.reject(
                                new Error(t("general.invalid_clinic"))
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        clinicVisit.ClinicKey === -1
                          ? undefined
                          : {
                              key: clinicVisit.ClinicKey,
                              value: clinicVisit.ClinicKey,
                              label:
                                clinicVisit.ClinicCode +
                                " - " +
                                clinicVisit.ClinicName,
                            }
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={clinicSelectionData}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("general.clinic_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={t("general.visit_date")}
                      name="ClinicApplicationDate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "general.clinic_visit_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={dayjs(
                        new Date(clinicVisit.ClinicApplicationDate)
                      )}
                    >
                      <DatePicker
                        format="DD/MM/YYYY"
                        placeholder={t("general.clinic_visit_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("general.remarks")}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.remarks_required_validation"),
                        },
                        {
                          max: 500,
                          message: t(
                            "general.remarks_max_length_500_validation"
                          ),
                        },
                      ]}
                      initialValue={clinicVisit.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label=""
                      name="IsDependant"
                      valuePropName="checked"
                      initialValue={clinicVisit.IsDependant}
                    >
                      <Checkbox
                        onChange={onChangeIsDependant}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        {t("clinicVisitForm.dependant")}
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      label={t("clinicVisitForm.dependant_name")}
                      name="DependantName"
                      rules={[
                        {
                          required: form.getFieldValue("IsDependant"),
                          message: t(
                            "clinicVisitForm.dependant_name_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !dependantSelectionData.some(
                                (x) => x.Code === value.value
                              )
                            ) {
                              return Promise.reject(
                                new Error(
                                  t("clinicVisitForm.invalid_dependant")
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        clinicVisit.DependantName === ""
                          ? undefined
                          : {
                              key: clinicVisit.DependantName,
                              value: clinicVisit.DependantName,
                              label: clinicVisit.DependantName,
                            }
                      }
                      style={{
                        display: form.getFieldValue("IsDependant")
                          ? ""
                          : "none",
                      }}
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={dependantSelectionData}
                        valueProp={"Code"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("clinicVisitForm.dependant_placeholder")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={t("general.attachment")}
                      name="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload
                        {...uploadProp()}
                        listType="picture"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      >
                        <Button
                          disabled={
                            !isOpen || !isOwner || isViewOnly || isSystemRcd
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Form.Item>
                      <Descriptions bordered size="small">
                        <Descriptions.Item label={t("general.date_applied")}>
                          {clinicVisit.TransDate.toLocaleDateString("en-GB")}
                        </Descriptions.Item>
                      </Descriptions>
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          htmlType="submit"
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen &&
                              clinicVisit.ClinicTransKey !== -1 &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={printMedicalChit}
                          style={{
                            marginRight: 10,
                            display:
                              isStatusApproved(clinicVisit.Status) &&
                              !clinicVisit.IsVerified &&
                              isOwner
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.print")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              !clinicVisit.IsVerified &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Modal
                  open={showMedicalChit}
                  title={<span className="form-subtitle">{reportTitle}</span>}
                  okText={t("general.close")}
                  onOk={onCloseReportModal}
                  onCancel={onCloseReportModal}
                  destroyOnClose={true}
                  maskClosable={false}
                  footer={[
                    <Button key="back" onClick={onCloseReportModal}>
                      {t("general.close")}
                    </Button>,
                  ]}
                  width={"70%"}
                  style={{ top: 20, width: "100%" }}
                >
                  <div className="report-container">
                    <ReportViewer
                      Collapsed={false}
                      CustomStyle={true}
                      ReportParam={reportParam}
                      ReportName={reportName}
                      ReportGenerate={isReportGenerate}
                    />
                  </div>
                </Modal>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={clinicVisit}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              clinicVisit.Status === 4 || clinicVisit.Status === 1
                ? true
                : false
            }
            showFooter={clinicVisit.ClinicTransKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>
      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Clinic Visit"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageClinicVisitForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clinicVisit: PropTypes.object.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  clinicSelectionData: PropTypes.array.isRequired,
  dependantSelectionData: PropTypes.array.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  onUploadAttachment: PropTypes.func.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  form: PropTypes.object.isRequired,
  onRemoveAttachment: PropTypes.func.isRequired,
};

export default ManageClinicVisitForm;
