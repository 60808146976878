import React, { useEffect, useState } from "react";
import TrainingApplicationForm from "../../forms/Training/TrainingApplicationsForm";
import PropTypes from "prop-types";
import { getTrainingApplications } from "../../../api/trainingApplicationApi";
import { toast } from "react-toastify";
import Axios from "axios";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const TrainingApplicationPage = ({ history }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [trainingApplicationDisplay, setTrainingApplicationDisplay] = useState(
    []
  );
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(() => {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    getTrainingApplications(source, currentYear)
      .then((result) => {
        setTrainingApplicationDisplay(result);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    return () => {
      source.cancel();
    };
  }, [currentYear, t]);

  function onAddNewTrainingApplication() {
    history("/home/trainingcalendar");
  }

  function viewTrainingApplication(TrainingApplicationKey) {
    history(
      "/home/trainingapplication/trainingtrans/" + TrainingApplicationKey,
      { state: {
          fromDisplay: true
      }}
    );
  }

  function viewTrainingProgram(trainingKey) {
    history("/home/trainings/training/" + trainingKey, {
      state: {
        fromDisplay: true,
        fromList: true,
      }
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TrainingApplicationForm
      isLoading={isLoading}
      data={trainingApplicationDisplay}
      onAdd={onAddNewTrainingApplication}
      onView={viewTrainingApplication}
      onViewProgram={viewTrainingProgram}
      setCurrentYear={setCurrentYear}
    />
  );
};

TrainingApplicationPage.propTypes = {
  history: PropTypes.func.isRequired,
};

export default TrainingApplicationPage;
