import React, { useState, useEffect } from "react";
import * as trainingApplicationApi from "../../../api/trainingApplicationApi";
import { getPublishedTrainings } from "../../../api/trainingApi";
import { toast } from "react-toastify";
import { TrainingsCalendarForm } from "../../forms/Training/TrainingsCalendarForm";
import TrainingApplicationModel from "../../model/Training/TrainingApplicationModel";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { useTranslation } from "react-i18next";

export default function TrainingsCalendarPage({ history }) {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [publishedTrainings, setPublishedTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const location = useLocation();
  const [trainingYear, setTrainingYear] = useState(
    location.state && location.state.trainingYear
      ? dayjs(location.state.trainingYear)
      : dayjs()
  );
  const [isShowAvailable, setShowAvailable] = useState(
    location.state ? location.state.isShowAvailable : true
  );

  useEffect(getPublishedTrainingList, []);

  function getPublishedTrainingList() {
    getPublishedTrainings()
      .then((result) => {
        setIsLoading(false);
        setPublishedTrainings(result);
        let filteredData = result.filter(
          (x) =>
            x.TrainingYear === trainingYear.year() &&
            (!isShowAvailable ||
              (isShowAvailable &&
                x.IsOpenForApplication &&
                (x.NoOfApplicants === -1 ||
                  x.TotalConfirmedApplications < x.NoOfApplicants)))
        );
        setFilteredTrainings(filteredData);
      })
      .catch((error) => {
        setIsLoading(false);
        setPublishedTrainings([]);
        setFilteredTrainings([]);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function viewTraining(trainingKey) {
    history("/home/trainings/training/" + trainingKey, {
      state: {
        fromApplication: true,
        isShowAvailable: isShowAvailable,
        trainingYear: trainingYear.toString(),
      },
    });
  }

  function applyTraining(applyTrainingKey) {
    setIsLoading(true);
    let trainingApplication = TrainingApplicationModel({
      trainingKey: applyTrainingKey,
    });

    trainingApplicationApi
      .applyTraining(trainingApplication)
      .then(() => {
        toast.success(t("trainingForm.training_applied_successfully"));
        getPublishedTrainingList();
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("trainingForm.training_applied_failed"), error);
      });
  }

  function filterDataByYear(date, isShowAvailableOnly) {
    setTrainingYear(date);

    if (date) {
      let filteredData = publishedTrainings.filter(
        (x) =>
          x.TrainingYear === date.year() &&
          (!isShowAvailableOnly ||
            (isShowAvailableOnly &&
              x.IsOpenForApplication &&
              (x.NoOfApplicants === -1 ||
                x.TotalConfirmedApplications < x.NoOfApplicants)))
      );
      setFilteredTrainings(filteredData);
    } else {
      setFilteredTrainings([]);
    }
  }

  function setDisplayOption(isShowAvailableOnly) {
    setShowAvailable(isShowAvailableOnly);
    filterDataByYear(trainingYear, isShowAvailableOnly);
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <TrainingsCalendarForm
      isLoading={isLoading}
      filteredTrainings={filteredTrainings}
      filterDataByYear={filterDataByYear}
      trainingYear={trainingYear}
      isShowAvailable={isShowAvailable}
      onDisplayOptionChanged={setDisplayOption}
      onView={viewTraining}
      onApply={applyTraining}
      onAttachmentClicked={getValidAttachments}
    />
  );
}

TrainingsCalendarPage.propTypes = {
  history: PropTypes.func.isRequired,
};
