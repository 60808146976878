import React, { useState } from "react";
import PropTypes from "prop-types";
import { CalendarOutlined } from "@ant-design/icons";
import { Tooltip, Calendar, Badge, Button, Modal, Col, Row, Spin } from "antd";
import "./CalendarIcon.css";
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown";
import { withRouter } from "../../customHooks/withRouter";
import { toast } from "react-toastify";
import { getOffRestDay } from "../../api/leaveConfgApi";
import { getOffHolidayProfile } from "../../api/holidayProfileApi";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

function CalendarIcon({ dayjs }) {
  const [isVisible, setIsVisible] = useState(false);
  const [offDays, setOffDays] = useState([]);
  const [holidayProfiles, setHolidayProfiles] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [isPublicHolidayVisible, setIsPublicHolidayVisible] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  function showErrorMessage(message) {
    toast.error(message);
  }

  const trigger = (
    <span
      style={{
        display: "inline-block",
        height: "100%",
        padding: "0 12px",
        cursor: "pointer",
        transition: "all .3s",
      }}
    >
      <Tooltip placement="bottomRight" title={t("calendarIcon.calendar")}>
        <CalendarOutlined
          className="icon"
          style={{ padding: 4, verticalAlign: "middle", fontSize: 16 }}
        />
      </Tooltip>
    </span>
  );

  const onPanelChange = (newValue) => {
    setCurrentYear(newValue.year());
  };

  function getNotificationBox() {
    return (
      <Spin spinning={isLoading}>
        <div
          style={{
            boxShadow: "0 2px 8px rgba(0,0,0,.15)",
            borderRadius: "inherit",
          }}
        >
          <div style={{ margin: 10 }}>
            <Button
              onClick={() => showPublicHoliday()}
              style={{
                position: "absolute",
                marginTop: 12,
              }}
            >
              {t("calendarIcon.list_of_public_holidays")} {currentYear}
            </Button>

            <Calendar cellRender={cellRender} onPanelChange={onPanelChange} />

            <Modal
              title={t("calendarIcon.list_of_public_holidays") + currentYear}
              open={isPublicHolidayVisible}
              zIndex={9999}
              footer={[
                <Button key="back" onClick={onClosePublicHoliday}>
                  {t("general.close")}
                </Button>,
              ]}
              onCancel={onClosePublicHoliday}
            >
              {holidayProfiles
                .filter(
                  (filtered) =>
                    filtered.MomentHolidayDate.year() === currentYear
                )
                .sort(function (a, b) {
                  return dayjs(a.HolidayDate) - dayjs(b.HolidayDate);
                })
                .map((item, index) => (
                  <Row key={index}>
                    <Col span={18}>{item.HolidayDesc}</Col>
                    <Col
                      span={6}
                      style={{
                        textAlign: "right",
                      }}
                    >
                      {dayjs(item.HolidayDate).format("DD/MM/YYYY")}
                    </Col>
                  </Row>
                ))}
            </Modal>
          </div>
        </div>
      </Spin>
    );
  }

  function handleVisibleChange(isVisible) {
    if (!isDataLoaded) {
      setIsDataLoaded(true);
      let promises = [getOffRestDay(), getOffHolidayProfile()];

      Promise.all(promises)
        .then((values) => {
          setOffDays(values[0] ? values[0] : []);
          if (values[1]) {
            values[1].forEach((r) => {
              r.MomentHolidayDate = dayjs(r.HolidayDate);
            });
          }
          setHolidayProfiles(values[1] ? values[1] : []);
          setIsLoading(false);
        })
        .catch((error) => {
          showErrorMessage(t("general.fail_to_load_record"), error);
        });
    }

    setIsVisible(isVisible);
  }

  function formatLocaleDay(day) {
    if (day === "Isn" || day === "Sen") {
      day = "Mon";
    } else if (day === "Sel") {
      day = "Tue";
    } else if (day === "Rab") {
      day = "Wed";
    } else if (day === "Kha" || day === "Kam") {
      day = "Thu";
    } else if (day === "Jum") {
      day = "Fri";
    } else if (day === "Sab") {
      day = "Sat";
    } else if (day === "Ahd" || day === "Min") {
      day = "Sun";
    }

    return day;
  }

  function getListData(value, hvalue, ovalue) {
    let listData = [];

    if (hvalue.length > 0) {
      let x = 0;
      let formatDate = value.startOf("day");
      for (x = 0; x < hvalue.length; x++) {
        let formatDateHValue = hvalue[x].MomentHolidayDate.startOf("day");
        if (formatDate.isSame(formatDateHValue)) {
          listData.push({ type: "success", content: hvalue[x].HolidayDesc });
        }
      }
    }

    if (ovalue.length > 0) {
      let x = 0;
      let formatDay = formatLocaleDay(value.format("ddd"));
      for (x = 0; x < ovalue.length; x++) {
        if (ovalue[x][formatDay]) {
          listData.push({
            type: "default",
            content: t("calendarIcon.rest_day"),
          });
        }
      }
    }

    return listData || [];
  }

  function getMonthData(value, hvalue) {
    let numPH = 0;
    if (hvalue.length > 0) {
      let x = 0;
      let month = value.month() + 1;
      let year = value.year();
      for (x = 0; x < hvalue.length; x++) {
        let monthHValue = hvalue[x].MomentHolidayDate.month() + 1;
        let yearHValue = hvalue[x].MomentHolidayDate.year();
        if (month === monthHValue && year === yearHValue) {
          numPH += 1;
        }
      }
    }
    return numPH;
  }

  function monthCellRender(value) {
    const num = getMonthData(value, holidayProfiles);
    return num ? (
      <div>
        <section>{num}</section>
        <span>{t("calendarIcon.public_holiday")}</span>
      </div>
    ) : null;
  }

  function dateCellRender(value) {
    const listData = getListData(value, holidayProfiles, offDays);

    return (
      <div>
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type} text={item.content} />
          </li>
        ))}
      </div>
    );
  }

  function cellRender(current, info) {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  }

  function showPublicHoliday() {
    setIsPublicHolidayVisible(true);
  }

  function onClosePublicHoliday() {
    setIsPublicHolidayVisible(false);
  }

  const notificationBox = getNotificationBox();

  return (
    <HeaderDropdown
      placement="bottomRight"
      dropdownRender={() => notificationBox}
      overlayClassName="popoverCalendar"
      trigger={["click"]}
      open={isVisible}
      onOpenChange={handleVisibleChange}
    >
      {trigger}
    </HeaderDropdown>
  );
}

CalendarIcon.propTypes = {
  dayjs: PropTypes.func.isRequired,
};

export default connect()(withRouter(CalendarIcon));
