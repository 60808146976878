import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Spin, Card, List, Button, Avatar } from "antd";
import { PropTypes } from "prop-types";
import PendingApprovalLeaveWidget from "../Dashboard/PendingApprovalLeaveWidget";
import PendingApprovalClaimWidget from "../Dashboard/PendingApprovalClaimWidget";
import PendingApprovalVehicleReqWidget from "../Dashboard/PendingApprovalVehicleReqWidget";
import PendingApprovalRoomReqWidget from "../Dashboard/PendingApprovalRoomReqWidget";
import PendingApprovalPropMaintWidget from "../Dashboard/PendingApprovalPropMaintWidget";
import PendingApprovalJobRecruitmentWidget from "../Dashboard/PendingApprovalJobRecruitmentWidget";
import PendingApprovalTrainingWidget from "../Dashboard/PendingApprovalTrainingWidget";
import PendingApprovalTravelAuthWidget from "../Dashboard/PendingApprovalTravelAuthWidget";
import PendingApprovalClinicVisitWidget from "../Dashboard/PendingApprovalClinicVisitWidget";
import { useTranslation } from "react-i18next";

export default function PendingApprovalSummaryWidget({
  isLoading,
  pendingLeaveApproval,
  pendingClaimsApproval,
  pendingJobRecruitmentApproval,
  pendingTravelAuthApproval,
  pendingClinicVisitApproval,
  pendingTrainingApproval,
  pendingVehicleReqApproval,
  pendingRoomReqApproval,
  pendingPropMaintApproval,
  history,
}) {
  const { t } = useTranslation();

  const PendingApprovalCategory = Object.freeze({
    leave: t("pendingApprovalSummaryWidget.leave"),
    claims: t("pendingApprovalSummaryWidget.claims"),
    job_recruitment: t("general.recruitment"),
    travel_auth: t("pendingApprovalSummaryWidget.travel_auth"),
    clinic_visit: t("general.clinic_visit"),
    training: t("pendingApprovalSummaryWidget.training_application"),
    vehicle_req: t("pendingApprovalSummaryWidget.vehicle_req"),
    room_req: t("pendingApprovalSummaryWidget.room_req"),
    prop_maint: t("pendingApprovalSummaryWidget.prop_maint"),
  });

  const labelPendingApproval = t(
    "pendingApprovalSummaryWidget.pending_approvals"
  );
  const [pendingApprovalList, setPendingApprovalList] = useState({});
  const [hasViewPendingApproval, setViewPendingApproval] = useState(false);
  const [pendingApprovalTitle, setPendingApprovalTitle] =
    useState(labelPendingApproval);
  const child = document.getElementById("view-content");
  const root = document.getElementById("summary");
  const [renderChild, setRenderChild] = useState(null);

  useEffect(() => {
    if (child !== null) {
      setRenderChild(createRoot(child));
    }
  }, [child]);

  function onView(key) {
    setViewPendingApproval(true);

    if (key === PendingApprovalCategory.leave) {
      setPendingApprovalTitle(t("pendingApprovalSummaryWidget.leave_approval"));
      return renderChild.render(
        <PendingApprovalLeaveWidget
          isLoading={isLoading}
          pendingLeaveApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.claims) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.claims_approval")
      );
      return renderChild.render(
        <PendingApprovalClaimWidget
          isLoading={isLoading}
          pendingClaimsApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.job_recruitment) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.job_recruitment_approval")
      );
      return renderChild.render(
        <PendingApprovalJobRecruitmentWidget
          isLoading={isLoading}
          pendingJobRecruitmentApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.travel_auth) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.travel_auth_approval")
      );
      return renderChild.render(
        <PendingApprovalTravelAuthWidget
          isLoading={isLoading}
          pendingTravelAuthApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.clinic_visit) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.clinic_visit_approval")
      );
      return renderChild.render(
        <PendingApprovalClinicVisitWidget
          isLoading={isLoading}
          pendingClinicVisitApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.training) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.training_approval")
      );
      return renderChild.render(
        <PendingApprovalTrainingWidget
          isLoading={isLoading}
          pendingTrainingApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.vehicle_req) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.vehicle_req_approval")
      );
      return renderChild.render(
        <PendingApprovalVehicleReqWidget
          isLoading={isLoading}
          pendingVehicleReqApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.room_req) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.room_req_approval")
      );
      return renderChild.render(
        <PendingApprovalRoomReqWidget
          isLoading={isLoading}
          pendingRoomReqApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    } else if (key === PendingApprovalCategory.prop_maint) {
      setPendingApprovalTitle(
        t("pendingApprovalSummaryWidget.prop_maint_approval")
      );
      return renderChild.render(
        <PendingApprovalPropMaintWidget
          isLoading={isLoading}
          pendingPropMaintApproval={pendingApprovalList[key]}
          history={history}
        />
      );
    }
  }

  function onBack() {
    setViewPendingApproval(false);
    setPendingApprovalTitle(labelPendingApproval);
    root.style.display = "block";
    child.style.display = "none";
  }

  useEffect(() => {
    if (pendingLeaveApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.leave]: pendingLeaveApproval },
      }));
    }
    if (pendingClaimsApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.claims]: pendingClaimsApproval },
      }));
    }
    if (pendingJobRecruitmentApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{
          [PendingApprovalCategory.job_recruitment]:
            pendingJobRecruitmentApproval,
        },
      }));
    }
    if (pendingTravelAuthApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.travel_auth]: pendingTravelAuthApproval },
      }));
    }
    if (pendingClinicVisitApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{
          [PendingApprovalCategory.clinic_visit]: pendingClinicVisitApproval,
        },
      }));
    }
    if (pendingTrainingApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.training]: pendingTrainingApproval },
      }));
    }
    if (pendingVehicleReqApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.vehicle_req]: pendingVehicleReqApproval },
      }));
    }
    if (pendingRoomReqApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.room_req]: pendingRoomReqApproval },
      }));
    }
    if (pendingPropMaintApproval.pendingAmount > 0) {
      setPendingApprovalList((object) => ({
        ...object,
        ...{ [PendingApprovalCategory.prop_maint]: pendingPropMaintApproval },
      }));
    }
  }, [
    pendingLeaveApproval,
    pendingClaimsApproval,
    pendingJobRecruitmentApproval,
    pendingTravelAuthApproval,
    pendingClinicVisitApproval,
    pendingTrainingApproval,
    pendingVehicleReqApproval,
    pendingRoomReqApproval,
    pendingPropMaintApproval,
    PendingApprovalCategory.leave,
    PendingApprovalCategory.claims,
    PendingApprovalCategory.job_recruitment,
    PendingApprovalCategory.travel_auth,
    PendingApprovalCategory.clinic_visit,
    PendingApprovalCategory.training,
    PendingApprovalCategory.vehicle_req,
    PendingApprovalCategory.room_req,
    PendingApprovalCategory.prop_maint,
  ]);

  return (
    <Card
      className="dashboard-card"
      title={
        <div>
          <Button
            type="link"
            onClick={() => onBack()}
            style={{
              padding: 0,
              border: 0,
              height: "19px",
              display: hasViewPendingApproval ? "inline-block" : "none",
            }}
          >
            <ArrowLeftOutlined />
          </Button>
          <span style={{ color: "#0070cc", paddingLeft: 10 }}>
            {pendingApprovalTitle}
          </span>
        </div>
      }
    >
      <Spin
        style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
      />
      <div
        id="summary"
        style={{
          display: !isLoading && !hasViewPendingApproval ? "block" : "none",
        }}
      >
        <div
          style={{
            //marginTop: "10px",
            height: "370px",
            backgroundColor: "#f5f7fa",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          {Object.keys(pendingApprovalList).map((key, index) => {
            return (
              <List.Item key={index} style={{ display: "flex" }}>
                {/* <div className="attendance-code">
                  {pendingApprovalList[key].pendingAmount}
                </div> */}
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        paddingLeft: 5,
                        alignSelf: "center",
                        marginInlineEnd: "16px",
                      }}
                    >
                      {pendingApprovalList[key].pendingAmount}
                    </Avatar>
                  }
                  style={{
                    padding: "0 10px 0 10px",
                    lineHeight: 0,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  title={key}
                />
                <div style={{ paddingRight: 5, alignSelf: "center" }}>
                  <Button type="primary" onClick={() => onView(key)}>
                    {t("general.view")}
                  </Button>
                </div>
              </List.Item>
            );
          })}

          {/* Show no data if there is no pending approval */}
          <List
            style={{
              display:
                Object.keys(pendingApprovalList).length > 0 ? "none" : "block",
            }}
            itemLayout="horizontal"
            renderItem={() => {}}
          />
        </div>
      </div>
      {/* View the selected pending approval by user */}
      <div
        id="view-content"
        style={{ display: hasViewPendingApproval ? "block" : "none" }}
      ></div>
    </Card>
  );
}

PendingApprovalSummaryWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pendingLeaveApproval: PropTypes.object.isRequired,
  pendingClaimsApproval: PropTypes.object.isRequired,
  pendingJobRecruitmentApproval: PropTypes.object.isRequired,
  pendingTravelAuthApproval: PropTypes.object.isRequired,
  pendingClinicVisitApproval: PropTypes.object.isRequired,
  pendingTrainingApproval: PropTypes.object.isRequired,
  pendingVehicleReqApproval: PropTypes.object.isRequired,
  pendingRoomReqApproval: PropTypes.object.isRequired,
  pendingPropMaintApproval: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};
