import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Select,
  Table,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import AppraisalRatingDetailModel from "../../model/AppraisalTemplate/AppraisalRatingDetailModel";
import ManageAppraisalRatingDetailForm from "../Master/ManageAppraisalRatingDetailForm";
import { RowState } from "../../model/common/RowState";
import { EditButton, DeleteButton } from "../../common/TableCols";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { Option } = Select;

const ManageAppraisalRatingForm = ({
  isLoading,
  appraisalRating,
  onAppraisalRatingChanged,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [formRatingDetail] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const formats = { cellSpacing: 20 };
  const newRating = AppraisalRatingDetailModel({});
  const [showRating, setShowRating] = useState(false);
  const [isNewRating, setIsNewRating] = useState(false);
  const [selectedRating, setselectedRating] = useState(newRating);
  const isInUsed = appraisalRating.IsInUsed;
  Form.useWatch("ARDecimalPoints", form);

  useEffect(() => {
    formRatingDetail.resetFields();
  }, [isFormInitialized, formRatingDetail]);

  const disableField =
    appraisalRating.RcdType === RecordType.USER ? false : true;
  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  const RatingActions = (value) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => editRating(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: !isInUsed ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => deleteRating(value)} />
      </Col>
    </Row>
  );

  const ratingColumns = [
    {
      title: t("general.description"),
      dataIndex: "ARDetDesc",
      key: "ARDetDesc",
    },
    {
      title: t("appraisalRatingForm.from_range"),
      dataIndex: "ARDetMinRange",
      align: "right",
      key: "ARDetMinRange",
      width: 170,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: form.getFieldValue("ARDecimalPoints"),
          maximumFractionDigits: form.getFieldValue("ARDecimalPoints"),
        }),
    },
    {
      title: t("appraisalRatingForm.to_range"),
      dataIndex: "ARDetMaxRange",
      align: "right",
      key: "ARDetMaxRange",
      width: 170,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: form.getFieldValue("ARDecimalPoints"),
          maximumFractionDigits: form.getFieldValue("ARDecimalPoints"),
        }),
    },
    {
      title: t("general.action"),
      dataIndex: "ARDetKey",
      key: "RatingActions",
      align: "center",
      width: 100,
      render: RatingActions,
    },
  ];

  function addNewRating() {
    setselectedRating(newRating);
    setShowRating(true);
    setIsNewRating(true);
    setIsFormInitialized(!isFormInitialized);
  }

  function editRating(aRDetKey) {
    let refLimits = appraisalRating.AppraisalRatingDet.filter(
      (item) => item.ARDetKey === aRDetKey
    );
    let selectedLimit = { ...refLimits };
    setselectedRating(selectedLimit[0]);
    setShowRating(true);
    setIsNewRating(false);
    setIsFormInitialized(!isFormInitialized);
  }

  function deleteRating(aRDetKey) {
    let index = appraisalRating.AppraisalRatingDet.findIndex(
      (x) => x.ARDetKey === aRDetKey
    );
    let currentItem = appraisalRating.AppraisalRatingDet.at(index);
    let nextItem = appraisalRating.AppraisalRatingDet.at(index + 1);

    //change state to DELETED for removal in db
    let newData = appraisalRating.AppraisalRatingDet.filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.ARDetKey === aRDetKey && y.RowState === RowState.ADDED)
    ).map((x) => {
      return x.ARDetKey === aRDetKey && x.RowState !== RowState.DELETED
        ? {
            ...x,
            RowState: RowState.DELETED,
          }
        : nextItem !== undefined
        ? x.ARDetKey === nextItem.ARDetKey && x.RowState !== RowState.DELETED
          ? {
              ...x,
              ARDetMinRange: currentItem.ARDetMinRange,
            }
          : x
        : x;
    });
    onAppraisalRatingChanged({
      ...appraisalRating,
      AppraisalRatingDet: newData,
    });
  }

  function saveRating(rating) {
    if (rating !== undefined || rating !== null) {
      let index = appraisalRating.AppraisalRatingDet.findIndex(
        (x) => x.ARDetKey === rating.ARDetKey
      );
      if (index === -1) {
        onAppraisalRatingChanged({
          ...appraisalRating,
          AppraisalRatingDet: [
            ...appraisalRating.AppraisalRatingDet,
            { ...rating },
          ],
        });
      } else {
        let nextItem = appraisalRating.AppraisalRatingDet.at(index + 1);

        let temp = appraisalRating.AppraisalRatingDet.map((item) =>
          item.ARDetKey === rating.ARDetKey
            ? item.RowState === RowState.ADDED
              ? rating
              : { ...rating, RowState: RowState.MODIFIED }
            : nextItem !== undefined
            ? item.ARDetKey === nextItem.ARDetKey
              ? {
                  ...item,
                  ARDetMinRange:
                    rating.ARDetMaxRange +
                    getDefaultDecimal(form.getFieldValue("ARDecimalPoints")),
                }
              : item
            : item
        );
        onAppraisalRatingChanged({
          ...appraisalRating,
          AppraisalRatingDet: temp,
        });
      }

      setShowRating(false);
    }
  }

  function cancelInputRating() {
    setselectedRating(newRating);
    setShowRating(false);
  }

  function getFilterAppraisalRatingData() {
    return appraisalRating.AppraisalRatingDet.filter(
      (x) => x.RowState !== RowState.DELETED
    ).reverse();
  }

  function getDefaultDecimal(decimal) {
    if (decimal === 0) {
      return 1;
    } else if (decimal === 1) {
      return 0.1;
    } else if (decimal === 2) {
      return 0.01;
    }
  }

  function handleDecimaChange(decimal) {
    let temp = appraisalRating.AppraisalRatingDet.filter(
      (x) => x.RowState !== RowState.DELETED
    ).map((item, index, array) =>
      index === 0
        ? item
        : {
            ...item,
            ARDetMinRange:
              array[index - 1].ARDetMaxRange + getDefaultDecimal(decimal),
          }
    );

    onAppraisalRatingChanged({
      ...appraisalRating,
      AppraisalRatingDet: temp,
    });
  }

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => {
          setIsDirty(true);
        }}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("appraisalRatingForm.appraisal_rating_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={appraisalRating.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("appraisalRatingForm.appraisal_rating_code")}
                    name="ARCode"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t(
                          "appraisalRatingForm.appraisal_rating_code_required_validation"
                        ),
                      },
                      {
                        max: 100,
                        message: t(
                          "appraisalRatingForm.appraisal_rating_code_max_length_validation"
                        ),
                      },
                    ]}
                    initialValue={appraisalRating.ARCode}
                  >
                    <Input
                      placeholder={t(
                        "appraisalRatingForm.appraisal_rating_code_placeholder"
                      )}
                      disabled={disableField}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.description")}
                    name="ARDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.description_max_length_validation"),
                      },
                    ]}
                    initialValue={appraisalRating.ARDesc}
                  >
                    <Input placeholder={t("general.description_placeholder")} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("appraisalRatingForm.decimal_points")}
                    name="ARDecimalPoints"
                    initialValue={appraisalRating.ARDecimalPoints}
                  >
                    <Select
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                      onChange={handleDecimaChange}
                    >
                      <Option value={0}>0</Option>
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h3 className="form-subtitle">
                {t("appraisalRatingForm.rating")}
              </h3>
              <div>
                <Table
                  dataSource={getFilterAppraisalRatingData()}
                  columns={ratingColumns}
                  size="middle"
                  pagination={false}
                  rowKey={(record) => record.ARDetKey}
                  bordered
                />

                <Button
                  type="primary"
                  style={{ marginTop: 16, display: !isInUsed ? "" : "none" }}
                  onClick={() => addNewRating()}
                >
                  {t("general.add_new")}
                </Button>
              </div>
              <ManageAppraisalRatingDetailForm
                isVisible={showRating}
                rating={selectedRating}
                appraisalRating={appraisalRating}
                decimalPoint={form.getFieldValue("ARDecimalPoints")}
                onSave={saveRating}
                onCancel={cancelInputRating}
                isNewRating={isNewRating}
                setIsDirty={setIsDirty}
                form={formRatingDetail}
              />
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={appraisalRating}
              showFooter={appraisalRating.ARKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageAppraisalRatingForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisalRating: PropTypes.object.isRequired,
  onAppraisalRatingChanged: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageAppraisalRatingForm;
