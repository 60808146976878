import React, { useEffect, useState } from "react";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Spin,
  Card,
  Modal,
  Checkbox,
  Switch,
  Form,
} from "antd";
import dayjs from "dayjs";
import { SelectionInput } from "../../common/SelectionInput";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
} from "../../model/common/Status";
import { RecordType } from "../../model/common/RecordType";
import { useParams } from "react-router-dom";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManageRoomRequisitionForm = ({
  isLoading,
  form,
  editItem,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onAssignRoomAndExpenses,
  onCompleteRoomRequisition,
  onIsSaveAndNewChange,
  isSaveAndNewData,
  allRooms,
  userCompCurr,
  isOwner,
  isViewOnly = false,
  isAssignor,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const isEdit = editItem.RoomRequisitionKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen && editItem.Reason.length > 0 && editItem.RejectedBy > 0;
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const { RoomRequisitionKey } = useParams();

  useEffect(() => {
    loadPageData(RoomRequisitionKey, form.resetFields);
    // eslint-disable-next-line
  }, [RoomRequisitionKey]);

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("roomRequisitionForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("roomRequisitionForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("roomRequisitionForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          ...values,
          RoomKey: -1,
        };

        let fromDate = values.ReqDate[0];
        fromDate.set({ second: 0, millisecond: 0 });
        submitData.FromReqDate = fromDate.startOf("minute").$d;

        let toDate = values.ReqDate[1];
        toDate.set({ second: 0, millisecond: 0 });
        submitData.ToReqDate = toDate.startOf("minute").$d;
        submitData.Status = isSubmit
          ? StatusByValue.SUBMITTED
          : StatusByValue.OPEN;
        onSubmitted(submitData);
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function assignRoomAndExpenses() {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          RoomKey: values.Room ? values.Room.key : -1,
          Expenses: values.Expenses,
        };
        onAssignRoomAndExpenses(submitData);
      })
      .catch(() => {});
  }

  function completeRoomRequisition() {
    form
      .validateFields()
      .then((values) => {
        confirm({
          title: t("roomRequisitionForm.meeting_room_requisition_closure"),
          content:
            t("roomRequisitionForm.closure_message") +
            values.Expenses.toLocaleString(regionLocale(), {
              minimumFractionDigits: precisionDecimal(userCompCurr.CurrCode),
              maximumFractionDigits: 2,
            }) +
            "?",
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            let submitData = {
              ...editItem,
              RoomKey: values.Room ? values.Room.key : -1,
              Expenses: values.Expenses,
            };
            onCompleteRoomRequisition(submitData);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("roomRequisitionForm.meeting_room_requisition")}
                </span>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.requisition_date")}</span>}
                      name="ReqDate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "general.requisition_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={editItem.ReqDate}
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        showToday={false}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        format="DD/MM/YYYY HH:mm"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        placeholder={[
                          t("general.from_date"),
                          t("general.to_date"),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.no_of_participants")}</span>}
                      name="NoOfParticipants"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "roomRequisitionForm.no_of_participants_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (value <= 0)
                              return Promise.reject(
                                new Error(
                                  t(
                                    "roomRequisitionForm.no_of_participants_must_be_>_0"
                                  )
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.NoOfParticipants}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step="1"
                        min={0}
                        precision={0}
                        formatter={inputLocaleFormatter(0)}
                        parser={inputLocaleParser(0)}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Col
                  span={24}
                  style={{
                    display: isShowReason ? "" : "none",
                  }}
                >
                  <Form.Item
                    label={
                      isRequestCancelRejected
                        ? t("general.reason_of_cancel_request_rejected")
                        : t("general.reason")
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      disabled={true}
                      value={editItem.Reason}
                    />
                  </Form.Item>
                </Col>

                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("roomRequisitionForm.meal")}</span>}
                      name="IsIncludeMeal"
                      valuePropName="checked"
                      initialValue={
                        editItem.IsIncludeMeal
                          ? editItem.IsIncludeMeal
                          : undefined
                      }
                    >
                      <Switch
                        checkedChildren={t("general.yes")}
                        unCheckedChildren={t("general.no")}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        editItem.RoomKey > 0 ||
                        (((!editItem.IsRequireApproval && isSubmitted) ||
                          isStatusApproved(editItem.Status)) &&
                          isAssignor)
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={
                        <span>{t("roomRequisitionForm.meeting_room")}</span>
                      }
                      name="Room"
                      rules={[
                        {
                          required:
                            ((!editItem.IsRequireApproval && isSubmitted) ||
                              isStatusApproved(editItem.Status)) &&
                            editItem.RoomKey === -1 &&
                            isAssignor,
                          message: t(
                            "roomRequisitionForm.meeting_room_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !allRooms.some((x) => x.Key === value.value)
                            ) {
                              return Promise.reject(
                                new Error(
                                  t("roomRequisitionForm.invalid_meeting_room")
                                )
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.RoomKey > 0
                          ? {
                              key: editItem.RoomKey,
                              value: editItem.RoomKey,
                              label:
                                editItem.RoomCode + " - " + editItem.RoomDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allRooms}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t(
                          "roomRequisitionForm.meeting_room_placeholder"
                        )}
                        disabled={
                          !isAssignor ||
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ display: editItem.RoomKey > 0 ? "" : "none" }}
                  >
                    <Form.Item
                      label={
                        <span>
                          {t("roomRequisitionForm.total_expenses")}{" "}
                          {userCompCurr && userCompCurr.CurrCode
                            ? " (" + userCompCurr.CurrCode + ")"
                            : ""}
                        </span>
                      }
                      name="Expenses"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value < 0)
                              return Promise.reject(
                                new Error(
                                  t("roomRequisitionForm.expenses_must_be_>=_0")
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.Expenses}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step={stepPrecision(userCompCurr.CurrCode)}
                        min={0}
                        precision={precisionDecimal(userCompCurr.CurrCode)}
                        prefix={
                          userCompCurr && userCompCurr.CurrSymb
                            ? userCompCurr.CurrSymb
                            : ""
                        }
                        formatter={inputLocaleFormatter(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        parser={inputLocaleParser(
                          precisionDecimal(userCompCurr.CurrCode)
                        )}
                        disabled={
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          !isAssignor ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.purpose")}</span>}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.purpose_required_validation"),
                        },
                        {
                          max: 1000,
                          message: t("general.purpose_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.purpose_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        {/* Save for meeting room assignment and expenses entry */}
                        <Button
                          size="large"
                          onClick={() => assignRoomAndExpenses()}
                          style={{
                            marginRight: isOwner || isAssignor ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => completeRoomRequisition()}
                          style={{
                            background: "mediumpurple",
                            borderColor: "mediumpurple",
                            marginLeft: isOwner ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              editItem.RoomKey > 0
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.complete")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.RoomRequisitionKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Meeting Room Requisition"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageRoomRequisitionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onAssignRoomAndExpenses: PropTypes.func.isRequired,
  onCompleteRoomRequisition: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  allRooms: PropTypes.array.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  isAssignor: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageRoomRequisitionForm;
