import React from "react";
import PropTypes from "prop-types";
import { Input, Select, Row, Col, Card, Button, Spin, Modal, Form } from "antd";
import { useTranslation } from "react-i18next";

const { Option } = Select;
const { confirm } = Modal;

const RoleAssignmentInputs = ({
  isLoading,
  roleAssignment,
  roleTypes,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const auth = JSON.parse(sessionStorage.getItem("auth"));
  const isSuperAdmin = !auth
    ? false
    : auth.UserRoleKey === '1';

  function generateRoleTypesOption() {
    let dataOptions = [];
    if (roleTypes && roleTypes.length) {
      dataOptions = roleTypes.map((type) => (
        //to avoid other user or approver to change himself into Admin role
        <Option key={type.Key} value={type.Key} disabled={!isSuperAdmin && type.Key === 1}>
          {type.Code}
        </Option>
      ));
    }

    return dataOptions;
  }

  const showSaveConfirm = async () => {
    form.validateFields().then((values) => {
      if (values["RoleTypeKey"] === 3) {
        confirm({
          title: t("general.save_confirmation"),
          content: t("roleAssignment.delete_user_role_confirmation_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            onSubmitted(values);
          },
          onCancel() {},
        });
      } else {
        onSubmitted(values);
      }
    });
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      onFinish={showSaveConfirm}
      onValuesChange={() => setIsDirty(true)}
    >
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <span className="form-title">
                {t("roleAssignment.role_assignment")}
              </span>
            }
          >
            <Spin spinning={isLoading}>
              <Row gutter={formats.cellSpacing}>
                <Col span={12}>
                  <Form.Item
                    label={t("general.user_name")}
                    name="EmpyName"
                    initialValue={
                      roleAssignment.EmpyID === undefined
                        ? undefined
                        : roleAssignment.EmpyID +
                          " - " +
                          roleAssignment.EmpyName
                    }
                  >
                    <Input
                      placeholder={t("general.user_name")}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("general.email")}
                    name="Email"
                    initialValue={
                      roleAssignment.Email === undefined
                        ? undefined
                        : roleAssignment.Email
                    }
                  >
                    <Input placeholder={t("general.email")} disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={formats.cellSpacing}>
                <Col span={4}>
                  <Form.Item
                    label={t("roleTypesForm.role_type")}
                    style={{ margin: 0 }}
                    name="RoleTypeKey"
                    initialValue={
                      roleAssignment.RoleTypeKey === -1
                        ? undefined
                        : roleAssignment.RoleTypeKey
                    }
                  >
                    <Select
                      showSearch
                      placeholder={t("roleTypesForm.role_type_placeholder")}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={() =>
                        document.getElementById("scroll-container")
                      }
                    >
                      {generateRoleTypesOption()}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Spin>
          </Card>
          <Row className="save-section">
            <Col style={{ textAlign: "right", padding: "10px" }}>
              <Button
                size="large"
                type="default"
                onClick={onCancelled}
                style={{ marginRight: 10 }}
              >
                {t("general.cancel")}
              </Button>
              <Button size="large" type="primary" htmlType="submit">
                {t("general.save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

RoleAssignmentInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roleAssignment: PropTypes.object.isRequired,
  roleTypes: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  history: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const ManageRoleAssignmentForm = RoleAssignmentInputs;
