import React, { useState } from "react";
import {
  ActiveCheckbox,
  EditButton,
  DeleteButton,
} from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const AppraisalForm = ({
  isLoading,
  appraisal,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();

  const AppraisalActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const [searchText, setSearchText] = useState("");

  const AppraisalActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("appraisalForm.appraisal_code"),
      dataIndex: "APCode",
      key: "APCode",
      width: 250,
      sorter: (a, b) => a.APCode.localeCompare(b.APCode),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "APCode",
        searchText,
        handleSearch,
        handleReset,
        "Appraisal Code",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "APDesc",
      key: "APDesc",
      sorter: (a, b) => a.APDesc.localeCompare(b.APDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "APDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("general.status"),
      dataIndex: "StatusDesc",
      key: "StatusDesc",
      align: "center",
      width: 100,
    },
    {
      title: t("general.active"),
      dataIndex: "Active",
      key: "Active",
      align: "center",
      width: 100,
      render: AppraisalActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "APKey",
      key: "AppraisalActions",
      align: "center",
      width: 100,
      render: AppraisalActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">{t("appraisalForm.appraisal_setup")}</span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          {t("general.new")}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={appraisal}
          columns={columns}
          size="middle"
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
          rowKey={(record) => record.APKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

AppraisalForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  appraisal: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default AppraisalForm;
