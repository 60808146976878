export function isLoginUser(userKey) {
  if (sessionStorage.getItem("auth")) {
    let authData = JSON.parse(sessionStorage.getItem("auth"));
    if (Number(authData.LocalUserKey) === userKey) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function isEditor(userKey) {
  if (sessionStorage.getItem("auth")) {
    let authData = JSON.parse(sessionStorage.getItem("auth"));
    if (Number(authData.LocalEditorKey) === userKey) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
