import React, { useState, useEffect } from "react";
import {
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Card,
  Divider,
  Tree,
  Tabs,
  Modal,
  Spin,
  Form,
} from "antd";
import PropTypes from "prop-types";
import HolidayProfileList from "../../forms/Master/components/HolidayProfileList";
import HolidayProfileModal from "../../forms/Master/components/HolidayProfileModal";
import { toast } from "react-toastify";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const HolidayProfileInputs = ({
  isLoading,
  holidayProfile,
  history,
  form,
  holidayProfileDet,
  setHolidayProfileDet,
  deletedDet,
  deleteHolidayDet,
  tempHoliday,
  setTempHoliday,
  onSubmit,
  allHoliday,
  profileKey,
  onYearChange,
  setIsDirty,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [formHolidayProfileModal] = Form.useForm();
  const [
    isFormHolidayProfileModalInitialized,
    setIsFormHolidayProfileModalInitialized,
  ] = useState(false);
  useEffect(
    () => formHolidayProfileModal.resetFields(),
    [isFormHolidayProfileModalInitialized, formHolidayProfileModal]
  );

  const formats = { cellSpacing: 20 };
  const { confirm } = Modal;
  const [activeTab, setActiveTab] = useState("0");
  const [isNewHoliday, setHolidayNew] = useState(false);
  const [isEditHoliday, setHolidayEdit] = useState(false);
  // eslint-disable-next-line
  const [a, setA] = useState([]);

  const disableField =
    holidayProfile.RcdType === RecordType.USER ? false : true;

  const company = holidayProfile.Company ? holidayProfile.Company : [];
  let checkedOUList =
    holidayProfile.HPStpOUKey.length > 0 ? holidayProfile.HPStpOUKey : [];

  const renderTreeNodes = (data) =>
    data.length === 0
      ? []
      : data.map((item) => {
          if (item.SelectionDetData) {
            return {
              title: item.CodeDesc,
              key: item.Code,
              children: renderTreeNodes(item.SelectionDetData),
              disabled: item.SelectionDetData.every((x) => x.disabled),
            };
          }
          return {
            title: item.CodeDesc,
            key: item.Key,
            disabled: item.disabled,
          };
        });

  const onCheck = (checkedKeys) => {
    checkedOUList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedOUList.push(checkedKeys[index]);
      }
    }
    holidayProfile.HPStpOUKey = checkedOUList;
    setIsDirty(true);
    setA(checkedOUList);
  };

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      values["checkedOUList"] = checkedOUList;
      allHoliday.forEach((res) => {
        if (
          res.ProfileCode === values.ProfileCode &&
          res.ProfileKey !== profileKey
        ) {
          form.setFields([
            {
              name: "ProfileCode",
              errors: ["Duplicate Holiday Profile Code"],
            },
          ]);
        }
      });

      if (holidayProfileDet.length === 0) {
        toast.error("Please Add Public Holiday.");
      }

      if (
        holidayProfileDet.length > 0 // to check for empy holiday list
      ) {
        onSubmit(values);
      }
    });
  };

  const handleSubmitFailed = () => {
    setActiveTab("0");
  };

  function handleModalCancelFlag() {
    setHolidayNew(false);
    setHolidayEdit(false);
    setIsDirty(false);
  }

  function handleHolidayAdd() {
    setHolidayNew(true);
    setIsFormHolidayProfileModalInitialized(
      !isFormHolidayProfileModalInitialized
    );
  }

  function handleHolidayEdit(temp) {
    var tempDS = Object.assign({}, temp);
    setTempHoliday(tempDS);
    setHolidayEdit(true);
    setIsFormHolidayProfileModalInitialized(
      !isFormHolidayProfileModalInitialized
    );
  }

  function handleHolidayModalSave(props, ds) {
    props.form
      .validateFields()
      .then((values) => {
        const newData = [...holidayProfileDet];

        const index = newData.findIndex(
          (item) => ds.ProfileDetKey === item.ProfileDetKey
        );
        if (index > -1) {
          const item = newData[index];
          //to get the correct date
          item.HolidayDate = props.form
            .getFieldValue("HolidayDate")
            .format("YYYY/MM/DD");

          item.RowState = item.RowState === 0 ? 2 : item.RowState;
          values.HolidayDate = values.HolidayDate.format("YYYY/MM/DD");
          values.HolidayDesc = values.HolidayDesc.trim();
          newData.splice(index, 1, {
            ...item,
            ...values,
          });
          setHolidayProfileDet(newData);
          setHolidayEdit(false);
        } else {
          values["ProfileDetKey"] = Math.floor(Math.random() * 999999);
          values["RowState"] = 1;
          values.HolidayDate = values.HolidayDate.format("YYYY/MM/DD");
          values.HolidayDesc = values.HolidayDesc.trim();
          //havent handle for add new row
          newData.push(values);
          setHolidayProfileDet(newData);
          setHolidayNew(false);
        }
      })
      .catch(() => {});
  }

  function handleHolidayDelete(ds) {
    const dataSource = [...holidayProfileDet];
    const dltDataSource = [...deletedDet];
    const dlt = dataSource.filter(
      (item) => item.ProfileDetKey === ds.ProfileDetKey
    )[0];
    if (dlt.RowState !== 1) {
      dlt.RowState = 3;
      dltDataSource.push(dlt);
    }
    setHolidayProfileDet(
      dataSource.filter((item) => item.ProfileDetKey !== ds.ProfileDetKey)
    );
    deleteHolidayDet(dltDataSource.filter((item) => item.ProfileDetKey !== ""));
    setIsDirty(true);
  }

  const TabChange = (data) => {
    setActiveTab(data);
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("holidayProfileForm.holiday_profile_setup")}
                </span>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={TabChange}
                items={[
                  {
                    key: "0",
                    label: t("general.general"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col span={12}>
                            <Form.Item
                              label={t(
                                "holidayProfileForm.holiday_profile_code"
                              )}
                              name="ProfileCode"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "holidayProfileForm.holiday_profile_code_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={holidayProfile.ProfileCode}
                            >
                              <Input
                                placeholder={t(
                                  "holidayProfileForm.holiday_profile_code_placeholder"
                                )}
                                style={{ textTransform: "uppercase" }}
                                disabled={disableField}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              label={t("general.description")}
                              name="ProfileDesc"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "general.description_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={holidayProfile.ProfileDesc}
                            >
                              <Input
                                placeholder={t(
                                  "general.description_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={6}>
                            <Form.Item
                              label={t("general.year")}
                              name="Yr"
                              initialValue={holidayProfile.Yr}
                            >
                              <InputNumber
                                precision={0}
                                min={1900}
                                max={9999}
                                style={{ width: "100%" }}
                                onBlur={() => {
                                  if (checkedOUList.length > 0) {
                                    confirm({
                                      title: t(
                                        "holidayProfileForm.change_year_confirmation"
                                      ),
                                      content: t(
                                        "holidayProfileForm.change_year_confirmation_message"
                                      ),
                                      okText: t("general.yes"),
                                      cancelText: t("general.no"),
                                      onOk() {
                                        onYearChange(
                                          form.getFieldValue("Yr"),
                                          company
                                        );
                                        holidayProfile.HPStpOUKey.length = 0;
                                      },
                                      onCancel() {
                                        form.setFieldsValue({
                                          Yr: holidayProfile.Yr,
                                        });
                                      },
                                    });
                                  } else {
                                    onYearChange(
                                      form.getFieldValue("Yr"),
                                      company
                                    );
                                    holidayProfile.HPStpOUKey.length = 0;
                                  }
                                }}
                                disabled={
                                  holidayProfile
                                    ? holidayProfile.ProfileKey === -1
                                      ? false
                                      : true
                                    : false
                                }
                              />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Divider />

                        <h3
                          className="form-subtitle"
                          style={{ marginBottom: 0 }}
                        >
                          {t("holidayProfileForm.public_holiday_listing")}
                        </h3>

                        <HolidayProfileList
                          dataSource={holidayProfileDet}
                          title={"HolidayDesc"}
                          content={"HolidayDate"}
                          onAdd={handleHolidayAdd}
                          onEdit={handleHolidayEdit}
                          onDelete={handleHolidayDelete}
                        />
                        <HolidayProfileModal
                          show={isNewHoliday || isEditHoliday}
                          handleSave={handleHolidayModalSave}
                          handleCancel={handleModalCancelFlag}
                          title={t("holidayProfileForm.holiday")}
                          dataSource={isNewHoliday ? "" : tempHoliday}
                          year={form.getFieldValue("Yr")}
                          setIsDirty={setIsDirty}
                          form={formHolidayProfileModal}
                        />
                      </>
                    ),
                  },
                  {
                    key: "1",
                    label: t("general.permission"),
                    children: (
                      <Tree
                        id={"TreeView"}
                        checkable
                        defaultExpandAll={true}
                        defaultCheckedKeys={
                          checkedOUList.length > 0 ? checkedOUList : []
                        }
                        checkedKeys={
                          checkedOUList.length > 0 ? checkedOUList : []
                        }
                        onCheck={onCheck}
                        treeData={renderTreeNodes(company, Tree)}
                      ></Tree>
                    ),
                  },
                ]}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: 10 }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={holidayProfile}
              showFooter={holidayProfile.ProfileKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

HolidayProfileInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  holidayProfile: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  holidayProfileDet: PropTypes.array.isRequired,
  setHolidayProfileDet: PropTypes.func.isRequired,
  deletedDet: PropTypes.array,
  deleteHolidayDet: PropTypes.func.isRequired,
  tempHoliday: PropTypes.object,
  setTempHoliday: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  allHoliday: PropTypes.array.isRequired,
  profileKey: PropTypes.number.isRequired,
  onYearChange: PropTypes.func,
  setIsDirty: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export const ManageHolidayProfileForm = HolidayProfileInputs;
