import React from "react";
import { Table, Row, Col } from "antd";
import { EditButton, DeleteButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export const CurrExRateTable = ({ data, onEdited, onDeleted }) => {
  const { t } = useTranslation();
  const CurrExRateActions = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdited(value)} />
      </Col>
      <Col span={12}>
        <DeleteButton
          style={{ display: record.IsCurrencyUsed ? "none" : "" }}
          fncDelete={() => onDeleted(value)}
        />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("general.currency"),
      dataIndex: "CurrCodeDesc",
      key: "CurrCodeDesc",
    },
    {
      title: t("general.exchange_rate"),
      dataIndex: "ExRateFunc",
      key: "ExRateFunc",
      align: "right",
      width: 150,
      render: (text) =>
        text.toLocaleString(regionLocale(), {
          minimumFractionDigits: 6,
          maximumFractionDigits: 6,
        }),
    },
    {
      title: t("general.action"),
      dataIndex: "CurrKey",
      key: "CurrExRateActions",
      align: "center",
      width: 85,
      render: CurrExRateActions,
    },
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns.filter((x) => x !== null)}
        size="small"
        pagination={false}
        rowKey={(record) => record.CurrKey}
        bordered
      />
    </div>
  );
};

CurrExRateTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdited: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
};
