import React from "react";
import {
  Table,
  Spin,
  Card,
  Avatar,
  Divider,
  Row,
  Col,
  DatePicker,
  Button,
  Modal,
  Tooltip,
  Form,
} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import "../../../App.css";
import { useTranslation } from "react-i18next";

const PublishControlForm = ({
  isLoading,
  publishControlDataSource,
  getPublishControlDataSource,
  setCurrentYear,
  datePickerState,
  setDatePickerState,
  onPublish,
  form,
}) => {
  const { t } = useTranslation();
  const { confirm } = Modal;

  function onYearMonthChanged(value) {
    if (value) {
      setCurrentYear(value.year());
      form.setFieldsValue({ MonthYear: value });

      form.validateFields().then(() => {
        let currentYearField = value.year();
        getPublishControlDataSource(currentYearField);
      });
    }
  }

  const LeaveIndicators = (value) => (
    <Row>
      <Col
        style={{
          display: value.StatusC1 === "NA" ? "none" : "",
        }}
      >
        <Tooltip placement="top" title="Cycle 1">
          <Button
            onClick={() => Update(value, value.TypeC1)}
            disabled={
              value
                ? value.StatusC1 === "O" || value.StatusC1 === "X"
                  ? true
                  : false
                : false
            }
            size="small"
            icon={value ? value.StatusIconC1 : ""}
            style={{
              backgroundColor: value ? value.StatusColorC1 : "#ffffff",
              color: "#ffffff",
              borderColor: "#ffffff",
            }}
            className="customBtn"
          ></Button>
        </Tooltip>
      </Col>

      <Col>
        <Tooltip
          placement="bottom"
          title={value.StatusC1 !== "NA" ? "Cycle 2" : ""}
        >
          <Button
            onClick={() => Update(value, value.Type)}
            disabled={
              value
                ? value.Status === "O" || value.Status === "X"
                  ? true
                  : false
                : false
            }
            size="small"
            icon={value ? value.StatusIcon : ""}
            style={{
              backgroundColor: value ? value.StatusColor : "#ffffff",
              color: "#ffffff",
              borderColor: "#ffffff",
            }}
            className="customBtn"
          ></Button>
        </Tooltip>
      </Col>
    </Row>
  );

  const Update = (value, type) => {
    if (type === "P1") {
      if (value.StatusC1 === "C") showPublishConfirm(value, type);
      else if (value.StatusC1 === "P") showRevertConfirm(value, type);
    } else {
      if (value.Status === "C") showPublishConfirm(value, type);
      else if (value.Status === "P") showRevertConfirm(value, type);
    }
  };

  function showPublishConfirm(value, type) {
    confirm({
      title: t("general.publish_confirmation"),
      width: 520,
      content: (
        <div>
          <p>
            {t(
              "publishControlForm.Are you sure you want to publish " +
                (value.Type === "P" ? "this Payslip" : "last year EA Form") +
                (type === "P1" ? " (Cycle 1)?" : "?")
            )}
          </p>
          <Row span={24}>
            <Col span={6}>{t("general.operating_unit")}:</Col>
            <Col span={18}>{value.OUCodeOUDesc}</Col>
          </Row>
          <Row span={24}>
            <Col span={6}>{t("general.year")}:</Col>
            <Col
              span={18}
              style={{
                display: value.Type === "P" ? "none" : "",
              }}
            >
              {value.Year - 1}
            </Col>
            <Col
              span={18}
              style={{
                display: value.Type === "P" ? "" : "none",
              }}
            >
              {value.Year}
            </Col>
          </Row>
          <Row
            span={24}
            style={{
              display: value.Type === "P" ? "" : "none",
            }}
          >
            <Col span={6}>{t("general.month")}:</Col>
            <Col span={18}>{value.Month}</Col>
          </Row>
        </div>
      ),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk() {
        onPublish(value, type);
      },
      onCancel() {},
    });
  }

  function showRevertConfirm(value, type) {
    confirm({
      title: t("general.revert_confirmation"),
      width: 520,
      content: (
        <div>
          <p>
            {t(
              "publishControlForm.Are you sure you want to revert this published " +
                (value.Type === "P" ? "Payslip" : "EA Form") +
                (type === "P1" ? " (Cycle 1)?" : "?")
            )}
          </p>
          <Row span={24}>
            <Col span={6}>{t("general.operating_unit")}:</Col>
            <Col span={18}>{value.OUCodeOUDesc}</Col>
          </Row>
          <Row span={24}>
            <Col span={6}>{t("general.year")}:</Col>
            <Col
              span={18}
              style={{
                display: value.Type === "P" ? "none" : "",
              }}
            >
              {value.Year - 1}
            </Col>
            <Col
              span={18}
              style={{
                display: value.Type === "P" ? "" : "none",
              }}
            >
              {value.Year}
            </Col>
          </Row>
          <Row
            span={24}
            style={{
              display: value.Type === "P" ? "" : "none",
            }}
          >
            <Col span={6}>{t("general.month")}:</Col>
            <Col span={18}>{value.Month}</Col>
          </Row>
        </div>
      ),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk() {
        onPublish(value, type);
      },
      onCancel() {},
    });
  }

  const columns = [
    {
      title: t("general.operating_unit"),
      dataIndex: "OUCodeOUDesc",
      key: "OUCodeOUDesc",
      sorter: (a, b) => a.OUCodeOUDesc.localeCompare(b.OUCodeOUDesc),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.jan"),
      dataIndex: "January",
      key: "January",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.feb"),
      dataIndex: "February",
      key: "February",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.mar"),
      dataIndex: "March",
      key: "March",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.apr"),
      dataIndex: "April",
      key: "April",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.may"),
      dataIndex: "May",
      key: "May",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.jun"),
      dataIndex: "June",
      key: "June",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.jul"),
      dataIndex: "July",
      key: "July",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.aug"),
      dataIndex: "August",
      key: "August",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.sep"),
      dataIndex: "September",
      key: "September",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.oct"),
      dataIndex: "October",
      key: "October",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.nov"),
      dataIndex: "November",
      key: "November",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: t("general.dec"),
      dataIndex: "December",
      key: "December",
      width: 46,
      render: LeaveIndicators,
    },
    {
      title: "EA",
      dataIndex: "EA",
      key: "EA",
      width: 46,
      render: LeaveIndicators,
    },
  ];

  const handlePanelChange = (value) => {
    setDatePickerState({
      time: value,
      isOpen: false,
    });
    onYearMonthChanged(value);
  };

  const handleOpenChange = (status) => {
    if (status) {
      setDatePickerState({
        isOpen: true,
        time: datePickerState.time,
      });
    } else {
      setDatePickerState({
        isOpen: false,
        time: datePickerState.time,
      });
    }
  };

  const clearValue = () => {
    setDatePickerState({ time: null });
  };

  const getRegionColumns = () => {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth.Region === "I") {
      return columns.filter((item) => item.key !== "EA");
    }
    return columns;
  };

  return (
    <Card
      title={
        <span className="form-title">
          {t("publishControlForm.publish_control")}
        </span>
      }
    >
      <Spin spinning={isLoading}>
        <Form layout="vertical" form={form}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={16} lg={16} xl={16}>
              <Form.Item
                label={t("general.year")}
                name="MonthYear"
                rules={[
                  {
                    required: true,
                    message: t("general.year_required_validation"),
                  },
                ]}
                initialValue={datePickerState.time || dayjs(dayjs(), "YYYY")}
              >
                <DatePicker
                  allowClear={false}
                  placeholder={t("general.year_placeholder")}
                  format="YYYY"
                  mode="year"
                  open={datePickerState.isOpen}
                  onOpenChange={handleOpenChange}
                  onPanelChange={handlePanelChange}
                  onChange={clearValue}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          bordered
          dataSource={publishControlDataSource}
          columns={getRegionColumns()}
          size="medium"
          rowKey={(record) => record.OUKey}
        />

        <Divider orientation="left" style={{ color: "#0070cc" }}>
          {t("general.legend")}
        </Divider>
        <Row>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: "#8ac249",
              }}
            />{" "}
            {t("publishControlForm.published")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: "#fc8d56",
              }}
            />{" "}
            {t("publishControlForm.ready_to_publish")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: "#e81d62",
              }}
            />{" "}
            {t("publishControlForm.not_ready")}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

PublishControlForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  publishControlDataSource: PropTypes.array.isRequired,
  getPublishControlDataSource: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
  datePickerState: PropTypes.object.isRequired,
  setDatePickerState: PropTypes.func.isRequired,
  onPublish: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default PublishControlForm;
