import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function leaveTypeReducer(
  state = initialState.leaveType,
  action = {}
) {
  switch (action.type) {
    // case types.CREATE_LEAVETYPE_SUCCESS:
    //   return [...state, { ...action.leavetype }];
    // case types.UPDATE_LEAVETYPE_SUCCESS:
    //   return state.map(leavetype =>
    //     leavetype.LTKey === action.leavetype.LTKey
    //       ? action.leavetype
    //       : leavetype
    //   );
    case types.LOAD_LEAVETYPES_SUCCESS:
      return action.leavetypes;
    // case types.DELETE_LEAVETYPE_OPTIMISTIC:
    //   return state.filter(leavetype => leavetype.LTKey !== action.leavetype.LTKey);
    default:
      return state;
  }
}
