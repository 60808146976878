import React from "react";
import {
  MailOutlined,
  MessageOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { notification, Avatar } from "antd";
import { ROOT } from "../api/api-config";
import PropTypes from "prop-types";

const iconEnum = {
  mail: <MailOutlined />,
  message: <MessageOutlined />,
  schedule: <ScheduleOutlined />,
};

let mainSignalR = {
  connection: window.jQuery.hubConnection(ROOT),
  isConnecting: false,
  notificationHub: null,
  transport: ["webSockets", "serverSentEvents", "longPolling"],
  logging: false,
  qs: null,
  authData: null,
  isGracefulDisconnect: false,
};

let notificationData = [];

export default function signalRService(history, notifications, actions) {
  notificationData = [...notifications];

  if (mainSignalR.connection) {
    startSignalR(history, actions);
  } else {
    console.log("Fail to create a connection");
  }
}

function isConnected() {
  return (
    mainSignalR.connection.state ===
    window.jQuery.signalR.connectionState.connected
  );
}

function isDisconnected() {
  return (
    mainSignalR.connection.state ===
    window.jQuery.signalR.connectionState.disconnected
  );
}

function startSignalR(history, actions) {
  try {
    //only start signalR if it is not connected
    if (!isConnected() && !mainSignalR.isConnecting) {
      let authData = JSON.parse(sessionStorage.getItem("auth"));

      if (authData) {
        let notificationHub =
          mainSignalR.connection.createHubProxy("notificationHub");

        //this function will be triggered when server send notifications
        notificationHub.on("receiveMessage", function (data) {
          let args = {
            message: data.Title,
            description: data.Message,
            placement: "bottomRight",
            icon: (
              <Avatar
                style={{
                  backgroundColor: data.IconBgColor,
                }}
                icon={iconEnum[data.Icon]}
              />
            ),
            onClick: () => history(data.Route),
          };

          //only show & add notifications if local does not exist (avoid duplication)
          if (!isNotificationExist(data.Type, data.NotificationKey)) {
            notification.open(args); //show notification message

            actions.addNotification(data); //add notification into store (show in notification list)
          }
        });

        mainSignalR.authData = authData;
        //required so that user who login with same email in different devices can receive notification
        mainSignalR.connection.qs = {
          email: authData.UserEmail,
          clientId: authData.ClientID,
        };
        mainSignalR.isConnecting = true;

        mainSignalR.connection.reconnected(function () {
          //if want to reload all notifications after reconnect, uncomment below code
          // actions
          //   .loadNotifications()
          //   .then(result => {})
          //   .catch(error => {});
        });

        mainSignalR.connection.disconnected(function () {
          if (mainSignalR.isGracefulDisconnect) {
            //stopped gracefully, ignore
          } else {
            //if there is internet connection, restart signalR
            if (window.navigator.onLine) {
              startSignalR(history, actions);
            } else {
              if (!window.ononline)
                //start signalR when window is online again
                window.ononline = function () {
                  startSignalR(history, actions);
                };
            }
          }
        });

        mainSignalR.connection
          .start({
            transport: mainSignalR.transport,
          })
          .then(() => {
            mainSignalR.isGracefulDisconnect = false;
            mainSignalR.isConnecting = false;
            window.ononline = null;
            console.log("Connection started!");
          })
          .catch((err) => {
            mainSignalR.isConnecting = false;
            console.log("Error while establishing connection :(");
            //on disconnected unexpectedly, restart signalR after 1 mins (to avoid memory crash)
            setTimeout(() => startSignalR(history, actions), 60000);
          });

        //stop signalR when refresh or close browser
        window.onunload = function () {
          stopSignalR();
        };
      } else {
        mainSignalR.authData = null;
        console.log("Failed to join chat! Invalid authentication!");
      }
    }
  } catch (err) {
    mainSignalR.isConnecting = false;
    console.log(err);
  }
}

export function stopSignalR() {
  try {
    mainSignalR.isGracefulDisconnect = true;

    if (!isDisconnected()) {
      /* 
      Parameter 1: whether stop function to run asynchronously (false: force to disconnect completely before going to the next line of code)
      Parameter 2: inform server that client is disconnecting
       */
      mainSignalR.connection.stop(false, true);
      console.log("Connection stopped.");
    }
  } catch (err) {
    console.log(err);
  }
}

function isNotificationExist(type, key) {
  let parent = notificationData.filter((x) => x.TypeKey === type);

  if (parent.length > 0) {
    return parent[0].child.filter((y) => y.NotificationKey === key).length > 0;
  }
  return false;
}

signalRService.propTypes = {
  history: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
};
