import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getEmpyInfo,
  getOU,
  getESSEmployee,
  getDepartment,
  getEmployeeUnderApproverClaim,
  getClinic,
} from "../../api/reportApi";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col, Select, DatePicker, Input } from "antd";
import dayjs from "dayjs";
import "../../styles/report.scss";
import * as UserType from "../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../api/companyApi";
import {
  TreeSelectionInput,
  getAllDsOption,
} from "../common/TreeSelectionInput";

const { Option } = Select;

export default function MedicalLimitDeductionDetails() {
  const { t } = useTranslation();
  const REPORT_NAME = "Medical Limit Deduction Details Report";

  const [userRoleType, setUserRoleType] = useState(UserType.USER_ROLE_USER);
  const [empyInfo, setEmpyInfo] = useState({ EmpyKey: null });
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [dsOU, setOUOptions] = useState([]);
  const [dsEmpy, setEmpyOptions] = useState([]);
  const [dsDept, setDeptOptions] = useState([]);
  const [dsClinic, setClinicOptions] = useState([]);
  const [dsComp, setCompOptions] = useState([]);
  const [initialOU, setInitialOU] = useState([]);
  const [initialEmpy, setInitialEmpy] = useState([]);
  const [initialApprovalEmpyKey, setInitialApprEmpyKey] = useState([]);
  const [initialDept, setInitialDept] = useState([]);
  const [initialClinic, setInitialClinic] = useState([]);
  const [selectedOU, setSelectedOU] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedEmpy, setSelectedEmpy] = useState([]);
  const [selectedFromDate, setFromDate] = useState();
  const [selectedToDate, setToDate] = useState();
  const [selectedClinic, setSelectedClinic] = useState([]);
  const [selectedComp, setSelectedComp] = useState();
  const [selectedReceiptNo, setSelectedReceiptNo] = useState("");
  const [isReportGenerating, setReportGenerating] = useState(false);

  const onSingleCompanyChange = (value) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value > -1) {
      setSelectedComp(value);
      const tempOU = Object.assign(
        [],
        initialOU.filter((x) => x.compKey === value)
      );
      setOUOptions(mapData(tempOU));
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setClinicOptions([]);
      setSelectedClinic([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedComp();
      setOUOptions([]);
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setClinicOptions([]);
      setSelectedClinic([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleOUChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempDept = Object.assign([], initialDept);
      let tempClinic = Object.assign([], initialClinic);
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedOU(getAllDsOption(dsOU, true));
          tempDept = Object.assign(
            [],
            initialDept.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
          tempClinic = Object.assign(
            [],
            initialClinic.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
        } else {
          setSelectedOU([]);
          tempDept = [];
          tempClinic = [];
        }
      } else {
        setSelectedOU(
          value.filter((x) => x !== -1).length === dsOU.length
            ? getAllDsOption(dsOU, true)
            : value.filter((x) => x !== -1)
        );
        tempDept = Object.assign(
          [],
          initialDept.filter((x) => value.includes(x.OUKey))
        );
        tempClinic = Object.assign(
          [],
          initialClinic.filter((x) => value.includes(x.OUKey))
        );
      }

      setDeptOptions(mapData(tempDept));
      setSelectedDept([]);
      setClinicOptions(mapData(tempClinic));
      setSelectedClinic([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setClinicOptions([]);
      setSelectedClinic([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleDeptChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempEmpy = Object.assign([], initialEmpy);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedDept(getAllDsOption(dsDept, true));
          tempEmpy = Object.assign(
            [],
            initialEmpy.filter(
              (x) =>
                selectedOU.includes(x.OUKey) &&
                dsDept.map((y) => y.value).includes(x.DeptKey)
            )
          );
        } else {
          setSelectedDept([]);
          tempEmpy = [];
        }
      } else {
        setSelectedDept(
          value.filter((x) => x !== -1).length === dsDept.length
            ? getAllDsOption(dsDept, true)
            : value.filter((x) => x !== -1)
        );

        tempEmpy = Object.assign(
          [],
          initialEmpy.filter(
            (x) => selectedOU.includes(x.OUKey) && value.includes(x.DeptKey)
          )
        );
      }

      setEmpyOptions(mapData(tempEmpy));
      setSelectedEmpy([]);
    } else {
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleClinicChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedClinic(getAllDsOption(dsClinic, true));
        } else {
          setSelectedClinic([]);
        }
      } else {
        if (dsClinic.length > 0) {
          setSelectedClinic(
            value.filter((x) => x !== -1).length === dsClinic.length
              ? getAllDsOption(dsClinic, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setSelectedClinic([]);
    }
  };

  const onMultipleEmpyChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedEmpy(getAllDsOption(dsEmpy, true));
        } else {
          setSelectedEmpy([]);
        }
      } else {
        setSelectedEmpy(
          value.filter((x) => x !== -1).length === dsEmpy.length
            ? getAllDsOption(dsEmpy, true)
            : value.filter((x) => x !== -1)
        );
      }
    } else {
      setSelectedEmpy([]);
    }
  };

  const onFromDateChange = (value) => {
    value === null ? setFromDate() : setFromDate(value.format("DD/MM/YYYY"));
  };

  const onToDateChange = (value) => {
    value === null ? setToDate() : setToDate(value.format("DD/MM/YYYY"));
  };

  function disabledToDate(current) {
    //disable date that is before from date
    return selectedFromDate
      ? current < dayjs(selectedFromDate, "DD/MM/YYYY")
      : null;
  }

  function disabledFromDate(current) {
    //disable date that is before from date
    return selectedToDate
      ? current > dayjs(selectedToDate, "DD/MM/YYYY").add(1, "days")
      : null;
  }

  function onReceiptNoChange(e) {
    setSelectedReceiptNo(e.target.value);
  }

  const generateReport = (e) => {
    setReportGenerating(true);
    e.preventDefault();
    setReportGenerate(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );

    var tempEmpyList = [];
    dsEmpy.forEach((x) =>
      initialApprovalEmpyKey.includes(x.value) ? tempEmpyList.push(x.value) : {}
    );

    setReportParam({
      CompKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.CompKey
          : selectedComp,
      OUKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.OUKey
          : selectedOU.includes(-1)
          ? [null]
          : selectedOU,
      DeptKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.DeptKey
          : selectedDept.includes(-1)
          ? [null]
          : selectedDept,
      EmpyKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.EmpyKey
          : userRoleType === UserType.USER_ROLE_APPROVER
          ? selectedEmpy.includes(-1)
            ? tempEmpyList
            : selectedEmpy.includes(-1)
            ? [null]
            : selectedEmpy
          : selectedEmpy.includes(-1)
          ? [null]
          : selectedEmpy,
      ClinicKey: selectedClinic.includes(-1) ? [null] : selectedClinic,
      FromDate:
        dayjs(selectedFromDate, "DD/MM/YYYY").format(
          "YYYY-MM-DDTHH:mm:ss.SSS"
        ) + "Z",
      ToDate:
        dayjs(selectedToDate, "DD/MM/YYYY").format("YYYY-MM-DDTHH:mm:ss.SSS") +
        "Z",
      ReceiptNo: selectedReceiptNo,
      PayMtd: null,
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
      BreakEmpy: false,
      HideSignature: false,
      HideBorder: false,
      IsESS: true,
    });
  };

  const validate = (
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedFromDate,
    selectedToDate,
    selectedClinic,
    selectedComp
  ) => {
    var valid = false;
    if (
      selectedOU.length > 0 &&
      selectedEmpy.length > 0 &&
      selectedDept.length > 0 &&
      selectedFromDate !== undefined &&
      selectedToDate !== undefined &&
      selectedClinic.length > 0 &&
      selectedComp !== undefined
    ) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(
      selectedOU,
      selectedEmpy,
      selectedDept,
      selectedFromDate,
      selectedToDate,
      selectedClinic,
      selectedComp,
      selectedReceiptNo
    );
  }, [
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedFromDate,
    selectedToDate,
    selectedClinic,
    selectedComp,
    selectedReceiptNo,
  ]);

  useEffect(() => {
    let { UserRoleType } = JSON.parse(sessionStorage.getItem("auth"));
    let promises = [
      getEmpyInfo(),
      getEmployeeUnderApproverClaim(),
      getESSEmployee(),
      getCompany(),
      getOU(),
      getDepartment(),
      getClinic(),
    ];
    setUserRoleType(UserRoleType);
    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setEmpyInfo(data[0][0]);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setSelectedComp(data[0][0].CompKey);
            setSelectedEmpy([data[0][0].EmpyKey]);
            setSelectedOU([data[0][0].OUKey]);
            setSelectedDept([data[0][0].DeptKey]);
          }
        }

        if (data[2] && data[2].length > 0) {
          const EmpyArr = [];
          let filteredEmpy = data[2];
          if (UserRoleType === UserType.USER_ROLE_APPROVER) {
            if (data[1] && data[1].length > 0) {
              setInitialApprEmpyKey(data[1]);
              filteredEmpy = data[2].filter((x) => data[1].includes(x.EmpyKey));
            }
          }
          filteredEmpy.forEach((element) => {
            EmpyArr.push({
              text: element.EmpyID + " - " + element.EmpyName,
              value: element.EmpyKey,
              OUKey: element.OUKey,
              DeptKey: element.DeptKey,
            });
          });

          setInitialEmpy(EmpyArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setEmpyOptions(
              EmpyArr.filter(
                (x) =>
                  x.value === data[0][0].EmpyKey &&
                  x.OUKey === data[0][0].OUKey &&
                  x.DeptKey === data[0][0].DeptKey
              )
            );
          }
        }

        if (data[3] && data[3].length > 0) {
          const CompArr = [];
          data[3].forEach((element) => {
            CompArr.push({
              text: element.CodeDesc,
              value: element.Key,
            });
          });
          setCompOptions(CompArr);
        }

        if (data[4] && data[4].length > 0) {
          const OUArr = [];
          data[4].forEach((element) => {
            OUArr.push({
              text: element.OUCode + " - " + element.OUDesc,
              value: element.OUKey,
              compKey: element.CompKey,
            });
          });
          setInitialOU(OUArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setOUOptions(
              OUArr.filter(
                (x) =>
                  x.value === data[0][0].OUKey &&
                  x.compKey === data[0][0].CompKey
              )
            );
          }
        }

        if (data[5] && data[5].length > 0) {
          const DeptArr = [];
          data[5].forEach((element) => {
            DeptArr.push({
              text: element.DeptCode + " - " + element.DeptDesc,
              value: element.DeptKey,
              OUKey: element.OUKey,
            });
          });

          setInitialDept(DeptArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setDeptOptions(
              DeptArr.filter(
                (x) =>
                  x.value === data[0][0].DeptKey && x.OUKey === data[0][0].OUKey
              )
            );
          }
        }

        if (data[6] && data[6].length > 0) {
          const ClinicArr = [];
          data[6].forEach((element) => {
            ClinicArr.push({
              text: element.ClinicCode + " - " + element.ClinicDesc,
              value: element.ClinicKey,
              OUKey: element.OUKey,
            });
          });
          setInitialClinic(ClinicArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            let tempClinicArr = Object.assign(
              [],
              ClinicArr.filter((x) => x.OUKey === data[0][0].OUKey)
            );
            setClinicOptions(mapData(tempClinicArr));
          }
        }
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  function mapData(data) {
    const mapResult = [];
    const newMap = new Map();
    for (const item of data) {
      if (!newMap.has(item.value)) {
        newMap.set(item.value, true);
        mapResult.push({
          text: item.text,
          value: item.value,
        });
      }
    }
    return mapResult;
  }

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.company")}</label>
          <br />
          <Select
            mode="single"
            style={{
              width: 240,
              maxHeight: 100,
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            placeholder={t("general.company_placeholder")}
            onChange={onSingleCompanyChange}
            optionLabelProp="label"
            value={selectedComp}
            filterOption={(input, option) =>
              option.props.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={userRoleType === UserType.USER_ROLE_USER}
          >
            {dsComp.map((element) => (
              <Option
                key={element.value}
                value={element.value}
                label={element.text}
              >
                <div>{element.text}</div>
              </Option>
            ))}
          </Select>
          <br />

          <label>{t("general.operating_unit")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.operating_unit_placeholder_full")}
            fncOnChange={onMultipleOUChange}
            value={selectedOU}
            dsOption={dsOU}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />

          <label>{t("general.clinic")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.clinic_placeholder")}
            fncOnChange={onMultipleClinicChange}
            value={selectedClinic}
            dsOption={dsClinic}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />

          <label>{t("general.department")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.department_placeholder")}
            fncOnChange={onMultipleDeptChange}
            value={selectedDept}
            dsOption={dsDept}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />

          <label>{t("general.employee")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.employee_placeholder")}
            fncOnChange={onMultipleEmpyChange}
            value={selectedEmpy}
            dsOption={dsEmpy}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />

          <label>{t("general.from_visit_date")}</label>
          <br />
          <DatePicker
            placeholder={t("general.from_visit_date")}
            format={"DD/MM/YYYY"}
            onChange={onFromDateChange}
            disabledDate={disabledFromDate}
            style={{ width: "100%" }}
          />
          <br />

          <label>{t("general.to_visit_date")}</label>
          <br />
          <DatePicker
            placeholder={t("general.to_visit_date")}
            format={"DD/MM/YYYY"}
            onChange={onToDateChange}
            disabledDate={disabledToDate}
            style={{ width: "100%" }}
          />
          <br />

          <label>{t("general.receipt_no")}</label>
          <br />
          <Input
            placeholder={t("general.receipt_no_placeholder")}
            onChange={onReceiptNoChange}
            style={{ width: "100%" }}
          />
          <br />

          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
