import React, { useEffect, useState } from "react";
import { cloneDeep } from "lodash";
import {
  DashboardOutlined,
  CalendarOutlined,
  DollarOutlined,
  MedicineBoxOutlined,
  FileTextOutlined,
  ControlOutlined,
  UserOutlined,
  SettingOutlined,
  NotificationOutlined,
  QuestionCircleOutlined,
  GlobalOutlined,
  FolderOutlined,
  ApartmentOutlined,
  CarOutlined,
  HomeOutlined,
  RadarChartOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  SolutionOutlined,
  BuildOutlined,
  ClusterOutlined,
  AuditOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
} from "@ant-design/icons";
import { Menu, AutoComplete } from "antd";
import { useLocation } from "react-router-dom";
import { withRouter } from "../../customHooks/withRouter";
import { getUserMenu } from "../../api/menuApi";
import { useTranslation } from "react-i18next";

let routes,
  prevSelectedKey,
  currentSelectedKey,
  prevPath,
  currentPath = [];

const iconEnum = {
  1: <DashboardOutlined />,
  2: <CalendarOutlined />,
  3: <DollarOutlined />,
  4: <MedicineBoxOutlined />,
  5: <FileTextOutlined />,
  6: <ControlOutlined />,
  7: <UserOutlined />,
  8: <SettingOutlined />,
  9: <NotificationOutlined />,
  47: <QuestionCircleOutlined />,
  59: <GlobalOutlined />,
  68: <FolderOutlined />,
  70: <ApartmentOutlined />,
  73: <CarOutlined />,
  83: <HomeOutlined />,
  90: <RadarChartOutlined />,
  94: <ToolOutlined />,
  109: <UsergroupAddOutlined />,
  117: <SolutionOutlined />,
  126: <BuildOutlined />,
  127: <ClusterOutlined />,
  128: <CalendarOutlined />,
  129: <DollarOutlined />,
  130: <RadarChartOutlined />,
  131: <BuildOutlined />,
  132: <AuditOutlined />,
  133: <FormOutlined />,
  139: <FundProjectionScreenOutlined />,
  140: <CalendarOutlined />,
  141: <DollarOutlined />,
  142: <RadarChartOutlined />,
  143: <UserOutlined />,
  144: <GlobalOutlined />,
  145: <MedicineBoxOutlined />,
  146: <SolutionOutlined />,
  147: <BuildOutlined />,
  148: <CarOutlined />,
  149: <HomeOutlined />,
  150: <ToolOutlined />,
  151: <SettingOutlined />,
  152: <NotificationOutlined />,
};

function arrayToTree(
  array,
  id = "id",
  parentId = "pid",
  children = "children"
) {
  let result = [];
  const hash = {};
  const data = cloneDeep(array);

  data
    ? data.forEach((item, index) => {
        hash[data[index][id]] = data[index];
      })
    : (result = []);

  data
    ? data.forEach((item) => {
        const hashParent = hash[item[parentId]];
        if (hashParent) {
          !hashParent[children] && (hashParent[children] = []);
          hashParent[children].push(item);
        } else {
          result.push(item);
        }
      })
    : (result = []);
  return result;
}

function queryAncestors(array, current, parentId, id = "id") {
  const result = [current];
  const hashMap = new Map();

  array.forEach((item) => hashMap.set(item[id], item));

  const getPath = (current) => {
    const currentParentId = hashMap.get(current[id])[parentId];
    if (currentParentId !== -1) {
      result.push(hashMap.get(currentParentId));
      getPath(hashMap.get(currentParentId));
    }
  };

  getPath(current);
  return result;
}

function pathMatch(regexp, pathname) {
  if (regexp === "/home") {
    return pathname === regexp;
  }
  return pathname.includes(regexp);
}

const SideBar = ({ dirty, setIsDirty, history }) => {
  const location = useLocation();
  const [openMenuKeys, setOpenKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    var routesFiltered = [];
    let promises = [getUserMenu()];

    function recursiveChildMenu(parent, child) {
      parent.push({
        id: child.MenuKey,
        route: child.MenuUrl,
        icon: child.MenuIcon,
        name: child.MenuName,
        menuParentId: child.ParentMenuKey,
        hasChild: child.ChildMenu.length > 0,
      });

      if (child.ChildMenu.length !== 0) {
        child.ChildMenu.map((child) => recursiveChildMenu(parent, child));
      }

      return parent;
    }

    Promise.all(promises).then((values) => {
      let temp = [];
      values[0].map((item) => {
        return (
          temp.push({
            id: item.MenuKey,
            route: item.MenuUrl,
            icon: item.MenuIcon,
            name: item.MenuName,
            menuParentId: item.ParentMenuKey,
            hasChild: item.ChildMenu.length > 0,
          }),
          item.ChildMenu.map((child) => recursiveChildMenu(temp, child))
        );
      });
      routes = temp;
      routes
        ? routes.forEach((item) => {
            if (item !== undefined)
              if (item.id !== "") routesFiltered.push(item);
          })
        : (routesFiltered = []);
      const currentMenu1 = routesFiltered
        ? routesFiltered.find(
            (_) => _.route && pathMatch(_.route, window.location.pathname)
          )
        : [];
      const openKeys = currentMenu1 ? [currentMenu1.menuParentId] : [];

      //handle multiple menu
      if (currentMenu1) {
        if (
          currentMenu1.menuParentId === 128 ||
          currentMenu1.menuParentId === 129 ||
          currentMenu1.menuParentId === 130 ||
          currentMenu1.menuParentId === 131 ||
          currentMenu1.menuParentId === 132 ||
          currentMenu1.menuParentId === 133
        ) {
          openKeys.push(6);
        } else if (
          currentMenu1.menuParentId === 73 ||
          currentMenu1.menuParentId === 83 ||
          currentMenu1.menuParentId === 94
        ) {
          openKeys.push(126);
        } else if (
          currentMenu1.menuParentId === 147 ||
          currentMenu1.menuParentId === 151
        ) {
          openKeys.push(139);
        }
      }
      setOpenKeys(
        openKeys.map(function (value) {
          return String(value);
        })
      );
    });
  }, []);

  function onOpenChange(openKeys) {
    const menus = routes;
    var menuFiltered = [];
    menus.forEach((item) => {
      if (item !== undefined) if (item.id !== "") menuFiltered.push(item);
    });
    const rootSubmenuKeys = menuFiltered
      .filter((_) => _.menuParentId === -1)
      .map((_) => String(_.id));
    const latestOpenKey = openKeys.find(
      (key) => openMenuKeys.indexOf(key) === -1
    );

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      //Special handle multi-level
      if (
        latestOpenKey === "73" ||
        latestOpenKey === "83" ||
        latestOpenKey === "94"
      ) {
        setOpenKeys(["126", latestOpenKey]);
      } else if (
        latestOpenKey === "128" ||
        latestOpenKey === "129" ||
        latestOpenKey === "130" ||
        latestOpenKey === "131" ||
        latestOpenKey === "132" ||
        latestOpenKey === "133"
      ) {
        setOpenKeys(["6", latestOpenKey]);
      } else if (latestOpenKey === "147" || latestOpenKey === "151") {
        setOpenKeys(["139", latestOpenKey]);
      } else {
        setOpenKeys(openKeys);
      }
    } else {
      latestOpenKey ? setOpenKeys([latestOpenKey]) : setOpenKeys([]);
    }
  }

  function isEqual(a, b) {
    // if length is not equal
    if (a.length !== b.length) return false;
    else {
      // comapring each element of array
      for (var i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
      return true;
    }
  }

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  function generateMenus(data) {
    let result = [];
    if (data.length > 0) {
      data.forEach((item) => {
        if (item.children && item.id !== 5) {
          result.push(
            getItem(item.name, item.id, iconEnum[item.id], recurringMenu(item))
          );
        } else {
          result.push(getItem(item.name, item.id, iconEnum[item.id]));
        }
      });
    }
    return result;
  }

  function recurringMenu(data) {
    let temp = [];
    if (data.children && data.id !== 5) {
      data.children.forEach((item) => {
        if (item.children) {
          temp.push(
            getItem(item.name, item.id, iconEnum[item.id], recurringMenu(item))
          );
        } else {
          temp.push(getItem(item.name, item.id, iconEnum[item.id]));
        }
      });
    } else {
      temp.push(getItem(data.name, data.id, iconEnum[data.id]));
    }
    return temp;
  }

  function onOpenMenu(item) {
    let menuRoute = routes.find((x) => x.id.toString() === item.key).route;
    if (menuRoute !== "") {
      history(menuRoute);
    }
  }

  var routesFiltered = [];

  routes
    ? routes.forEach((item) => {
        if (item !== undefined) if (item.id !== "") routesFiltered.push(item);
      })
    : (routesFiltered = []);
  const menuTree = arrayToTree(routesFiltered, "id", "menuParentId");

  const currentMenu = routesFiltered
    ? routesFiltered.find(
        (_) => _.route && pathMatch(_.route, window.location.pathname)
      )
    : [];

  // Find the key that should be selected according to the current menu.
  const selectedKeys = currentMenu
    ? queryAncestors(routesFiltered, currentMenu, "menuParentId").map(
        (_) => _.id
      )
    : [];

  currentSelectedKey = [...selectedKeys];
  currentPath = location.pathname;

  if (
    dirty &&
    (!isEqual(prevSelectedKey, currentSelectedKey) || currentPath !== prevPath)
  ) {
    setIsDirty(false);
  }

  prevSelectedKey = [...currentSelectedKey];
  prevPath = currentPath;

  const onSelect = (value) => {
    var redirectPath = routesFiltered.find(
      (item) => item.id.toString() === value
    );
    setSearchValue("");
    history(redirectPath.route);
  };

  const onChange = (value) => {
    setSearchValue(value);
  };

  var searchOptions = [];

  routesFiltered
    ? routesFiltered.forEach((item) => {
        if ((item.menuParentId === -1 && !item.hasChild) || item.id === 5)
          searchOptions.push({
            label: item.name,
            value: item.id.toString(),
          });

        if (item.menuParentId === -1 && item.hasChild) {
          recurringSearch(item, "");
        }
      })
    : (searchOptions = []);

  searchOptions.forEach((parent) => {
    routesFiltered
      ? routesFiltered.forEach((item) => {
          if (
            parent.value === item.menuParentId.toString() &&
            item.menuParentId !== 5 &&
            !item.hasChild
          )
            parent.options.push({
              label: item.name,
              value: item.id.toString(),
            });
        })
      : (parent.options = []);
  });

  // routesFiltered
  //   ? routesFiltered.forEach((item) => {
  //       if (item.route !== "")
  //         searchOptions.push({
  //           label: item.name,
  //           value: item.id.toString(),
  //         });
  //     })
  //   : (searchOptions = []);

  function recurringSearch(items, parentTitle) {
    let hasChild = false;
    routesFiltered
      .filter((x) => x.menuParentId === items.id)
      .forEach((item) => {
        if (item.hasChild) {
          recurringSearch(item, items.name);
          hasChild = true;
        }
      });
    if (!hasChild || items.id === 6) {
      searchOptions.push({
        label:
          parentTitle === "" ? items.name : parentTitle + " > " + items.name,
        value: items.id.toString(),
        options: [],
      });
    }
  }

  return (
    <div>
      <AutoComplete
        style={{
          width: "100%",
          padding: "5px 5px 5px 5px",
          zIndex: 0,
        }}
        options={searchOptions}
        placeholder={t("general.search")}
        onSelect={onSelect}
        onChange={onChange}
        value={searchValue}
        filterOption={(inputValue, option) =>
          option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        listHeight={412}
        dropdownMatchSelectWidth={false}
      />
      <Menu
        style={{ borderRight: 0 }}
        mode="inline"
        theme="light"
        onOpenChange={onOpenChange}
        selectedKeys={selectedKeys.map(function (value) {
          return String(value);
        })}
        openKeys={openMenuKeys.map(function (value) {
          return String(value);
        })}
        getPopupContainer={() => document.getElementById("scroll-container")}
        onClick={onOpenMenu}
        items={generateMenus(menuTree)}
      ></Menu>
    </div>
  );
};

export default withRouter(SideBar);
