import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "Appraisal/";

export function getAllAppraisals() {
  return axios({
    method: "GET",
    url: base + "GetAllAppraisals",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAppraisal(appraisalKey) {
  return axios({
    method: "GET",
    url: base + "GetAppraisal",
    headers: authHeader(),
    params: { key: appraisalKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addAppraisal(appraisal) {
  return axios({
    method: "POST",
    url: base + "AddAppraisal",
    headers: authHeader(),
    data: appraisal,
  })
    .then()
    .catch(handleError);
}

export function updateAppraisal(appraisal) {
  return axios({
    method: "PUT",
    url: base + "UpdateAppraisal",
    headers: authHeader(),
    params: { key: appraisal.APKey },
    data: appraisal,
  })
    .then()
    .catch(handleError);
}

export function deleteAppraisal(appraisalKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteAppraisal",
    headers: authHeader(),
    params: { key: appraisalKey },
  })
    .then()
    .catch(handleError);
}
