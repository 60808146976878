import { RowState } from "../common/RowState";

export default function AppraisalRatingDetailModel({
  ARDetKey = Math.floor(Math.random() * 999999),
  ARKey = -1,
  ARDetDesc = "",
  ARDetMinRange = 0,
  ARDetMaxRange = 0,
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    ARDetKey: ARDetKey,
    ARKey: ARKey,
    ARDetDesc: ARDetDesc,
    ARDetMinRange: ARDetMinRange,
    ARDetMaxRange: ARDetMaxRange,
    RowVersion: RowVersion,
    RowState: rowState,
  });
}
