import React from "react";
import { Table, Checkbox } from "antd";
import { ActiveCheckbox, EditButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { StatusByCode, StatusesBySubject } from "../../model/common/Status";

export const NotifSubjectTable = ({ data, onEdited, onRowSelected }) => {
  const { t } = useTranslation();

  const IsOverrideActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );
  const NotifSubjectEditButton = (value) => (
    <EditButton fncEdit={() => onEdited(value)} />
  );

  const expandedRowRender = (result) => {
    const statusIncluded = StatusesBySubject[result.SubjectKey];
    const columns = [
      {
        title: t("general.status"),
        dataIndex: "status",
        key: "status",
        width: 150,
        render: () => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {statusIncluded.map((status) => (
              <div key={status} style={{ display: "flex", alignItems: "center" }}>
                <Checkbox checked={result[status]} disabled />
                <span style={{ marginLeft: "8px" }}>{StatusByCode[status]}</span>
              </div>
            ))}
          </div>
        ),
      }
    ];
    const dataSource = [{ key: "status" }];

    return <Table columns={columns} dataSource={dataSource} pagination={false} />;
  };

  const columns = [
    {
      title: t("notifConfigForm.subject"),
      dataIndex: "Subject",
      key: "Subject",
    },
    {
      title: t("general.description"),
      dataIndex: "SubjectDesc",
      key: "SubjectDesc",
    },
    {
      title: t("notifConfigForm.email_notification"),
      dataIndex: "EmailNotification",
      key: "EmailNotification",
      align: "center",
      width: 150,
      render: IsOverrideActiveCheckbox,
    },
    data && data.length > 0
      ? {
          title: t("general.action"),
          dataIndex: "Subject",
          key: "EditNotifSubject",
          align: "center",
          width: 50,
          render: NotifSubjectEditButton,
        }
      : null,
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns.filter((x) => x !== null)}
        expandedRowRender={expandedRowRender}
        size="small"
        pagination={false}
        rowKey={(record) =>
          record.NotifConfgKey + record.SubjectKey + record.Subject
        }
        onRow={(r) => ({ onClick: () => onRowSelected(r) })}
      />
    </div>
  );
}

NotifSubjectTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdited: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
};