import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ManageLeaveApplicationForm from "../../forms/Leave/ManageLeaveApplicationForm";
import PropTypes from "prop-types";
import LeaveApplicationModel from "../../model/Leave/LeaveApplicationModel";
import {
  getLeaveApplication,
  addLeaveApplication,
  updateLeaveApplication,
  deleteLeaveApplication,
  getAllLeaveApplicationByUserKey,
} from "../../../api/leaveApplicationApi";
import dayjs from "dayjs";
import { getLeaveTypeCode } from "../../../api/leaveTypeApi";
import { getOffRestDay, getLeaveConfg } from "../../../api/leaveConfgApi";
import { getOffHolidayProfile } from "../../../api/holidayProfileApi";
import { attachFiles, deleteFile } from "../../../api/fileUploadApi";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import { getESSUser } from "../../../api/roleAssignmentApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newLeaveApplication = LeaveApplicationModel({});

export default function ManageLeaveApplicationPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [offDays, setOffDays] = useState([]);
  const [holidayProfiles, setHolidayProfiles] = useState([]);
  const [appliedLeaveDays, setAppliedLeaveDays] = useState([]);
  const [empys, setEmpys] = useState([]);
  const [leaveConfg, setLeaveConfg] = useState({});
  const [leaveApplication, setLeaveApplication] = useState(newLeaveApplication);
  const [fileList, setFileList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ltSelection, setLTSelection] = useState({});
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const { LeaveTransKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function saveLeaveApplication(values, attachment) {
    let toastStatus = 0;
    toastStatus =
      values.Status === 0
        ? leaveApplication.Status === 1
          ? 3
          : values.Status
        : values.Status;
    let leaveApplicationSubmit = {
      ...leaveApplication,
      ...values,
      Remarks: values.Remarks.trim(),
    };

    if (attachment.data) {
      leaveApplicationSubmit = {
        ...leaveApplicationSubmit,
        Attaches: [...leaveApplication.Attaches, ...attachment.data],
      };
    }

    let upsertLeaveType =
      leaveApplicationSubmit.LeaveTransKey !== -1
        ? updateLeaveApplication
        : addLeaveApplication;

    setIsLoading(true);
    upsertLeaveType(leaveApplicationSubmit)
      .then((result) => {
        if (result.data) {
          if (isSaveAndNew) {
            setIsDirty(false);
            history("/home/leaveapplication/leavetrans", {
              state: { force: true },
            });
            loadPageData(undefined);
          } else {
            setIsDirty(false);
            history(
              "/home/leaveapplication/leavetrans/" + result.data.LeaveTransKey,
              {
                state: { force: true },
              }
            );
            loadPageData(result.data.LeaveTransKey);
          }

          if (toastStatus === 1) {
            toast.success(t("general.record_submitted_successfully"));
          } else if (toastStatus === 2) {
            toast.success(t("general.record_request_cancel_successfully"));
          } else if (toastStatus === 3) {
            toast.success(t("general.record_withdrawn_successfully"));
          } else {
            toast.success(t("general.record_saved_successfully"));
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          leaveApplicationSubmit.LeaveTransKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.message);
      });
  }

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/leaveapplicationapproval");
      } else if (location.state.fromVoid) {
        history("/home/leaveapplicationvoid");
      } else if (location.state.fromCancel) {
        history("/home/leaveapplicationcancel");
      } else if (location.state.fromValidation) {
        history("/home/leavevalidation");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/leave", {state: location.state});
      } else {
        history("/home/leaveapplicationdisplay");
      }
    } else {
      history("/home/leaveapplicationdisplay");
    }
  }

  function onDeleteLeaveApplication() {
    let editKey = LeaveTransKey;

    deleteLeaveApplication(editKey)
      .then(() => {
        history("/home/leaveapplication/leavetrans", {
          state: { force: true },
        });
        loadPageData(undefined);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        let message = t("general.fail_to_delete_record");
        toast.error(message + error.message);
      });
  }

  function loadPageData(editKey) {
    let promises = [
      getLeaveTypeCode(),
      getOffRestDay(),
      getOffHolidayProfile(),
      getLeaveConfg(),
      getESSUser(),
      getAllLeaveApplicationByUserKey(),
    ];
    if (editKey > 0) {
      promises.push(getLeaveApplication(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setLeaveTypes(values[0] ? values[0] : []);
        setOffDays(values[1] ? values[1] : []);
        if (values[2]) {
          values[2].forEach((r) => {
            r.MomentHolidayDate = dayjs(r.HolidayDate);
          });
        }
        setHolidayProfiles(values[2] ? values[2] : []);
        setLeaveConfg(values[3] ? values[3] : {});
        setEmpys(
          values[4] ? values[4].filter((x) => x.IsLMStaff === false) : []
        );
        setAppliedLeaveDays(values[5] ? values[5] : []);

        if (values[6]) {
          values[6].LeaveApplicationDate = [
            dayjs(values[6].FromLeaveApplicationDate),
            dayjs(values[6].ToLeaveApplicationDate),
          ];
          values[6].TransDate = new Date(values[6].TransDate);
          values[6].DocTotalDay = values[6].TotalDay;
          values[6].DocTotalCFDay = values[6].TotalCFDay;
          setIsOwner(
            isLoginUser(values[6].UserKey) || isEditor(values[6].CreatedBy)
          );

          if (
            values[6].UserKey !== values[6].CreatedBy ||
            values[6].TransDate.getFullYear() !== dayjs().year()
          ) {
            onEmployeeChange(
              values[6].UserKey,
              values[6].EmpyKey,
              values[6].LTKey,
              values[6].TransDate.getFullYear()
            );
          }
        } else {
          setIsOwner(true);
        }
        setLeaveApplication(values[6] ? values[6] : newLeaveApplication);

        let tempLTK = values[6] ? values[6].LTKey : -1;
        let tempLTS = values[0]
          ? values[0].filter((item) => item.Key === tempLTK)
          : {};
        setLTSelection(tempLTS.length > 0 ? tempLTS[0] : {});

        let tempAttaches = values[6] ? values[6].Attaches : [];
        tempAttaches.forEach((attachment) => {
          attachment.uid = attachment.AttchKey;
          attachment.name = attachment.FileName;
          attachment.status = "done";
          attachment.url = attachment.UploadedUrl;
        });
        setFileList(tempAttaches);
        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }

  function onEmployeeChange(userKey, empyKey, ltKey, year) {
    let promises = [
      getLeaveTypeCode(year, userKey, empyKey),
      getOffRestDay(userKey, empyKey),
      getOffHolidayProfile(empyKey),
      getAllLeaveApplicationByUserKey(userKey),
    ];

    Promise.all(promises)
      .then((values) => {
        setLeaveTypes(values[0] ? values[0] : []);
        setOffDays(values[1] ? values[1] : []);
        if (values[2]) {
          values[2].forEach((r) => {
            r.MomentHolidayDate = dayjs(r.HolidayDate);
          });
        }
        setHolidayProfiles(values[2] ? values[2] : []);
        setAppliedLeaveDays(values[3] ? values[3] : []);

        let tempLTS = values[0]
          ? values[0].filter((item) => item.Key === ltKey)
          : {};
        setLTSelection(tempLTS.length > 0 ? tempLTS[0] : {});
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }

  function onUploadAttachment(formData) {
    setIsLoading(true);
    return attachFiles(formData, "Leaves")
      .then((result) => {
        return result;
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }

  function onRemoveAttachment(file) {
    setIsLoading(true);
    return deleteFile(file)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.Message);
      });
  }

  return (
    <ManageLeaveApplicationForm
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      leaveApplication={leaveApplication}
      fileList={fileList}
      setFileList={setFileList}
      leaveTypesSelectionData={leaveTypes}
      holidayProfilesData={holidayProfiles}
      offDaysData={offDays}
      appliedLeaveDays={appliedLeaveDays}
      empysData={empys}
      leaveConfgData={leaveConfg}
      loadPageData={loadPageData}
      onEmployeeChange={onEmployeeChange}
      onSubmitted={saveLeaveApplication}
      onDeleted={onDeleteLeaveApplication}
      onCancelled={onCancelled}
      onDataChanged={setLeaveApplication}
      leaveTypeSelection={ltSelection}
      onLTDataChanged={setLTSelection}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      onUploadAttachment={onUploadAttachment}
      onRemoveAttachment={onRemoveAttachment}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageLeaveApplicationPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
