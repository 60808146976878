import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseAttach = API_ROOT;

export async function attachFiles(
  formData,
  folder,
  attachmentID = "",
  type = 0
) {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;
  
  return axios({
    method: "POST",
    url:
      baseAttach +
      "AttachFiles?StorageFolder=" +
      folder +
      "&AttachmentID=" +
      attachmentID +
      "&Environment=" +
      env +
      "&Type=" +
      type,
    data: formData,
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundarynA73Uz10KDPXQeZv",
      Authorization: "Bearer " + auth.access_token,
    },
  });
}
export async function deleteFile(
  file
) {
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  const bodyFormData = new FormData();

  file.forEach((item) => {
      bodyFormData.append('url_list[]', item);
  });
  return axios({
    method: "POST",
    url:
      baseAttach +
      "DeleteFile?Environment=" +
      env,
      data: bodyFormData,
      headers: {
        "Content-Type":
        'application/x-www-form-urlencoded',
        Authorization: "Bearer " + auth.access_token,
      },
  });
}
