import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getEmpyInfo,
  getOU,
  getESSEmployee,
  getDepartment,
  getEmployeeUnderApproverPropMaints,
} from "../../api/reportApi";
import { Status } from "../model/common/Status";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col, Select, DatePicker } from "antd";
import "../../styles/report.scss";
import * as UserType from "../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../api/companyApi";
import {
  TreeSelectionInput,
  getAllDsOption,
} from "../common/TreeSelectionInput";

const { Option } = Select;

export default function PropertiesMaintenanceForm() {
  const { t } = useTranslation();
  const REPORT_NAME = "Properties Maintenance Report";

  const [year, setYear] = useState();
  const [month, setMonth] = useState([]);
  const [statusLocal, setStatus] = useState([]);
  const [repairStatusDS, setRepairStatusDS] = useState([
    { text: "ALL SELECTED", value: 0 },
    { text: "Repair Required", value: 1 },
    { text: "No Repair Required", value: 2 },
  ]);
  const [empyInfo, setEmpyInfo] = useState({ EmpyKey: null });
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [dsStatusOptions, setStatusOptions] = useState([]);
  const [dsMonthOptions, setDsMonthOptions] = useState([]);
  const [isReportGenerating, setReportGenerating] = useState(false);
  const [validatedDS, setValidatedDS] = useState([
    { text: "ALL SELECTED", value: 0 },
    { text: "Validated", value: 1 },
    { text: "Non-Validated", value: 2 },
  ]);
  const [isValidated, setValidated] = useState([0, 1, 2]);
  const [isRequireMaint, setRequireMaint] = useState([0, 1, 2]);
  const [isOpen, setIsOpen] = useState(false);
  const [userRoleType, setUserRoleType] = useState(UserType.USER_ROLE_USER);
  const [dsOU, setOUOptions] = useState([]);
  const [dsEmpy, setEmpyOptions] = useState([]);
  const [dsDept, setDeptOptions] = useState([]);
  const [dsComp, setCompOptions] = useState([]);
  const [initialOU, setInitialOU] = useState([]);
  const [initialEmpy, setInitialEmpy] = useState([]);
  const [initialApprovalEmpyKey, setInitialApprEmpyKey] = useState([]);
  const [initialDept, setInitialDept] = useState([]);
  const [selectedOU, setSelectedOU] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedEmpy, setSelectedEmpy] = useState([]);
  const [selectedComp, setSelectedComp] = useState();

  const onSingleCompanyChange = (value) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value > -1) {
      setSelectedComp(value);
      const tempOU = Object.assign(
        [],
        initialOU.filter((x) => x.compKey === value)
      );
      setOUOptions(mapData(tempOU));
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedComp();
      setOUOptions([]);
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleOUChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempDept = Object.assign([], initialDept);
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedOU(getAllDsOption(dsOU, true));
          tempDept = Object.assign(
            [],
            initialDept.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
        } else {
          setSelectedOU([]);
          tempDept = [];
        }
      } else {
        setSelectedOU(
          value.filter((x) => x !== -1).length === dsOU.length
            ? getAllDsOption(dsOU, true)
            : value.filter((x) => x !== -1)
        );

        tempDept = Object.assign(
          [],
          initialDept.filter((x) => value.includes(x.OUKey))
        );
      }

      setDeptOptions(mapData(tempDept));
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleDeptChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempEmpy = Object.assign([], initialEmpy);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedDept(getAllDsOption(dsDept, true));
          tempEmpy = Object.assign(
            [],
            initialEmpy.filter(
              (x) =>
                selectedOU.includes(x.OUKey) &&
                dsDept.map((y) => y.value).includes(x.DeptKey)
            )
          );
        } else {
          setSelectedDept([]);
          tempEmpy = [];
        }
      } else {
        setSelectedDept(
          value.filter((x) => x !== -1).length === dsDept.length
            ? getAllDsOption(dsDept, true)
            : value.filter((x) => x !== -1)
        );

        tempEmpy = Object.assign(
          [],
          initialEmpy.filter(
            (x) => selectedOU.includes(x.OUKey) && value.includes(x.DeptKey)
          )
        );
      }

      setEmpyOptions(mapData(tempEmpy));
      setSelectedEmpy([]);
    } else {
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleEmpyChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedEmpy(getAllDsOption(dsEmpy, true));
        } else {
          setSelectedEmpy([]);
        }
      } else {
        setSelectedEmpy(
          value.filter((x) => x !== -1).length === dsEmpy.length
            ? getAllDsOption(dsEmpy, true)
            : value.filter((x) => x !== -1)
        );
      }
    } else {
      setSelectedEmpy([]);
    }
  };

  function getMonthLabel(value) {
    const mthLabel = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return mthLabel[value];
  }

  const onYrChange = (value) => {
    setIsOpen(false);
    setYear(value);
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }
  };

  const onMonthChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }
    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setMonth(getAllDsOption(dsMonthOptions, true));
        } else {
          setMonth([]);
        }
      } else {
        if (dsMonthOptions.length > 0) {
          setMonth(
            value.filter((x) => x !== -1).length === dsMonthOptions.length
              ? getAllDsOption(dsMonthOptions, true)
              : value.filter((x) => x !== -1)
          );
        } else {
          setMonth(value.filter((x) => x !== -1));
        }
      }
    } else {
      setMonth([]);
    }
  };

  const onStatusChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setStatus(getAllDsOption(dsStatusOptions, true));
        } else {
          setStatus([]);
        }
      } else {
        if (dsStatusOptions.length > 0) {
          setStatus(
            value.filter((x) => x !== -1).length === dsStatusOptions.length
              ? getAllDsOption(dsStatusOptions, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setStatus([]);
    }
  };

  const onValidated = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === 0) {
        if (value.includes(0)) {
          setValidated(getAllDsOption(validatedDS, false));
        } else {
          setValidated([]);
        }
      } else {
        if (validatedDS.length > 0) {
          setValidated(
            value.includes(1) && value.includes(2)
              ? getAllDsOption(validatedDS, false)
              : value.filter((x) => x !== 0)
          );
        }
      }
    } else {
      setValidated([]);
    }
  };

  const onRequireMaint = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === 0) {
        if (value.includes(0)) {
          setRequireMaint(getAllDsOption(repairStatusDS, false));
        } else {
          setRequireMaint([]);
        }
      } else {
        if (repairStatusDS.length > 0) {
          setRequireMaint(
            value.includes(1) && value.includes(2)
              ? getAllDsOption(repairStatusDS, false)
              : value.filter((x) => x !== 0)
          );
        } else {
          setRequireMaint(value.filter((x) => x !== 0));
        }
      }
    } else {
      setRequireMaint([]);
    }
  };

  const generateReport = (e) => {
    setReportGenerating(true);
    e.preventDefault();
    setReportGenerate(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );

    var tempEmpyList = [];
    dsEmpy.forEach((x) =>
      initialApprovalEmpyKey.includes(x.value) ? tempEmpyList.push(x.value) : {}
    );

    setReportParam({
      CompKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.CompKey
          : selectedComp,
      OUKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.OUKey
          : selectedOU.includes(-1)
          ? [null]
          : selectedOU,
      Status: statusLocal.includes(-1) ? [null] : statusLocal,
      DeptKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.DeptKey
          : selectedDept.includes(-1)
          ? [null]
          : selectedDept,
      EmpyKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.EmpyKey
          : userRoleType === UserType.USER_ROLE_APPROVER
          ? selectedEmpy.includes(-1)
            ? tempEmpyList
            : selectedEmpy.includes(-1)
            ? [null]
            : selectedEmpy
          : selectedEmpy.includes(-1)
          ? [null]
          : selectedEmpy,

      Year: year.$y,
      Month: month.includes(-1) ? [null] : month,
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
      IsValidated: isValidated[0],
      IsRequireMaint: isRequireMaint[0],
    });
  };

  const validate = (
    selectedOU,
    selectedEmpy,
    selectedDept,
    year,
    status,
    month,
    isRequireMaint,
    isValidated,
    selectedComp
  ) => {
    var valid = false;
    if (
      selectedOU.length > 0 &&
      selectedEmpy.length > 0 &&
      selectedDept.length > 0 &&
      year > 0 &&
      status.length > 0 &&
      month.length > 0 &&
      isRequireMaint.length > 0 &&
      isValidated.length > 0 &&
      selectedComp !== undefined
    ) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(
      selectedOU,
      selectedEmpy,
      selectedDept,
      year,
      statusLocal,
      month,
      isRequireMaint,
      isValidated,
      selectedComp
    );
  }, [
    selectedOU,
    selectedEmpy,
    selectedDept,
    year,
    statusLocal,
    month,
    isRequireMaint,
    isValidated,
    selectedComp,
  ]);

  useEffect(() => {
    let { UserRoleType } = JSON.parse(sessionStorage.getItem("auth"));
    let promises = [
      getEmpyInfo(),
      getOU(),
      getEmployeeUnderApproverPropMaints(),
      getESSEmployee(),
      getDepartment(),
      getCompany(),
    ];
    const statusArr = [];
    for (var st in Status) {
      // eslint-disable-next-line no-prototype-builtins
      if (Status.hasOwnProperty(st)) {
        if (st >= 0 && st <= 7) {
          statusArr.push({
            text: Status[st],
            value: st,
          });
        }
      }
    }
    const monthArr = [];
    for (var i = 1; i < 13; i++) {
      monthArr.push({
        text: getMonthLabel(i - 1),
        value: i,
      });
    }
    setValidatedDS([
      { text: "ALL SELECTED", value: 0 },
      { text: "Validated", value: 1 },
      { text: "Non-Validated", value: 2 },
    ]);
    setRepairStatusDS([
      { text: "ALL SELECTED", value: 0 },
      { text: "Repair Required", value: 1 },
      { text: "No Repair Required", value: 2 },
    ]);
    setDsMonthOptions(monthArr);
    setStatusOptions(statusArr);
    setStatus(["0", "1", "4", "5", "7"]);
    setUserRoleType(UserRoleType);

    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setEmpyInfo(data[0][0]);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setSelectedComp(data[0][0].CompKey);
            setSelectedEmpy([data[0][0].EmpyKey]);
            setSelectedOU([data[0][0].OUKey]);
            setSelectedDept([data[0][0].DeptKey]);
          }
        }

        if (data[1] && data[1].length > 0) {
          const OUArr = [];
          data[1].forEach((element) => {
            OUArr.push({
              text: element.OUCode + " - " + element.OUDesc,
              value: element.OUKey,
              compKey: element.CompKey,
            });
          });
          setInitialOU(OUArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setOUOptions(
              OUArr.filter(
                (x) =>
                  x.value === data[0][0].OUKey &&
                  x.compKey === data[0][0].CompKey
              )
            );
          }
        }

        if (data[3] && data[3].length > 0) {
          const EmpyArr = [];
          let filteredEmpy = data[3];
          if (UserRoleType === UserType.USER_ROLE_APPROVER) {
            if (data[2] && data[2].length > 0) {
              setInitialApprEmpyKey(data[2]);
              filteredEmpy = data[3].filter((x) => data[2].includes(x.EmpyKey));
            }
          }

          let addedEmpy = [];
          filteredEmpy.forEach((element) => {
            if (!addedEmpy.includes(element.EmpyKey)) {
              EmpyArr.push({
                text: element.EmpyID + " - " + element.EmpyName,
                value: element.EmpyKey,
                OUKey: element.OUKey,
                DeptKey: element.DeptKey,
              });

              addedEmpy.push(element.EmpyKey);
            }
          });

          setInitialEmpy(EmpyArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setEmpyOptions(
              EmpyArr.filter(
                (x) =>
                  x.value === data[0][0].EmpyKey &&
                  x.OUKey === data[0][0].OUKey &&
                  x.DeptKey === data[0][0].DeptKey
              )
            );
          }
        }

        if (data[4] && data[4].length > 0) {
          const DeptArr = [];
          data[4].forEach((element) => {
            DeptArr.push({
              text: element.DeptCode + " - " + element.DeptDesc,
              value: element.DeptKey,
              OUKey: element.OUKey,
            });
          });
          setInitialDept(DeptArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setDeptOptions(
              DeptArr.filter(
                (x) =>
                  x.value === data[0][0].DeptKey && x.OUKey === data[0][0].OUKey
              )
            );
          }
        }

        if (data[5] && data[5].length > 0) {
          const CompArr = [];
          data[5].forEach((element) => {
            CompArr.push({
              text: element.CodeDesc,
              value: element.Key,
            });
          });
          setCompOptions(CompArr);
        }
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  function mapData(data) {
    const mapResult = [];
    const newMap = new Map();
    for (const item of data) {
      if (!newMap.has(item.value)) {
        newMap.set(item.value, true);
        mapResult.push({
          text: item.text,
          value: item.value,
        });
      }
    }
    return mapResult;
  }

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.company")}</label>
          <br />
          <Select
            mode="single"
            style={{
              width: 240,
              maxHeight: 100,
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            placeholder={t("general.company_placeholder")}
            onChange={onSingleCompanyChange}
            optionLabelProp="label"
            value={selectedComp}
            filterOption={(input, option) =>
              option.props.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={userRoleType === UserType.USER_ROLE_USER}
          >
            {dsComp.map((element) => (
              <Option
                key={element.value}
                value={element.value}
                label={element.text}
              >
                <div>{element.text}</div>
              </Option>
            ))}
          </Select>
          <br />
          <label>{t("general.operating_unit")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.operating_unit_placeholder_full")}
            fncOnChange={onMultipleOUChange}
            value={selectedOU}
            dsOption={dsOU}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.department")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.department_placeholder")}
            fncOnChange={onMultipleDeptChange}
            value={selectedDept}
            dsOption={dsDept}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.employee")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.employee_placeholder")}
            fncOnChange={onMultipleEmpyChange}
            value={selectedEmpy}
            dsOption={dsEmpy}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.year")}</label>
          <br />
          <DatePicker
            allowClear={false}
            value={year}
            mode="year"
            open={isOpen}
            placeholder={t("general.year_placeholder_2")}
            format="YYYY"
            onOpenChange={(status) => {
              if (status) {
                setIsOpen(true);
              } else {
                setIsOpen(false);
              }
            }}
            onPanelChange={onYrChange}
            style={{ width: 240 }}
          />
          <br />
          <label>{t("general.month")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.month_placeholder")}
            fncOnChange={onMonthChange}
            value={month}
            dsOption={dsMonthOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.status")}</label>
          <br />{" "}
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.status_placeholder")}
            fncOnChange={onStatusChange}
            value={statusLocal}
            dsOption={dsStatusOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("report.repair_status")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("report.repair_status_placeholder")}
            fncOnChange={onRequireMaint}
            value={isRequireMaint}
            dsOption={repairStatusDS}
            isIncludeAllOption={false}
          ></TreeSelectionInput>
          <br />
          <label>{t("report.is_validated")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("report.is_validated_placeholder")}
            fncOnChange={onValidated}
            value={isValidated}
            dsOption={validatedDS}
            isIncludeAllOption={false}
          ></TreeSelectionInput>
          <br />
          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
