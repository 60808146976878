import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "curr/";

export function getMonthlyExCurrency(claimYrMth) {
  return axios({
    method: "GET",
    url: base + "GetMonthlyExCurrency",
    headers: authHeader(),
    params: {
      claimYrMth: claimYrMth
    }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserCompCurrency() {
  return axios({
    method: "GET",
    url: base + "GetUserCompCurrency",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCurrency() {
  return axios({
    method: "GET",
    url: base + "GetCurrency",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}
