import React, { useState } from "react";
import {
  ActiveCheckbox,
  EditButton,
  DeleteButton,
} from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { regionLocale } from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export const ClaimTypesForm = ({
  isLoading,
  claimTypes,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const ClaimTypeActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const [searchText, setSearchText] = useState("");

  const ClaimTypeActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("claimTypesForm.claim_type_setup"),
      dataIndex: "ClaimTypeCode",
      key: "ClaimTypeCode",
      width: 200,
      sorter: (a, b) => a.ClaimTypeCode.localeCompare(b.ClaimTypeCode),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "ClaimTypeCode",
        searchText,
        handleSearch,
        handleReset,
        "Claim Type Code",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "ClaimTypeDesc",
      key: "ClaimTypeDesc",
      sorter: (a, b) => a.ClaimTypeDesc.localeCompare(b.ClaimTypeDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "ClaimTypeDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("claimTypesForm.controlled_unit_cost"),
      dataIndex: "DefaultCost",
      key: "DefaultCost",
      align: "right",
      width: 200,
      sorter: (a, b) => a.DefaultCost - b.DefaultCost,
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "DefaultCost",
        searchText,
        handleSearch,
        handleReset,
        "Controlled Unit Cost",
        t
      ),
      render: (text) =>
        text > 0
          ? text.toLocaleString(regionLocale(), {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("general.active"),
      dataIndex: "Active",
      key: "Active",
      width: 80,
      align: "center",
      render: ClaimTypeActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "ClaimTypeKey",
      key: "ClaimTypeActions",
      align: "center",
      width: 100,
      render: ClaimTypeActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">
          {t("claimTypesForm.claim_type_setup")}
        </span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          {t("general.new")}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={claimTypes}
          columns={columns}
          size="middle"
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
          rowKey={(record) => record.ClaimTypeKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

ClaimTypesForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  claimTypes: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
