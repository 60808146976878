import React, { useState, useEffect } from "react";
import {
  DownOutlined,
  IssuesCloseOutlined,
  StarFilled,
} from "@ant-design/icons";
import { List, Button, Badge, Empty, Dropdown, Menu } from "antd";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

export default function PendingApprovalTravelAuthWidget({
  isLoading,
  pendingTravelAuthApproval,
  history,
}) {
  const { t } = useTranslation();
  const [hasPendingApproval, setHasPendingApproval] = useState(false);

  const menu = (
    <Menu
      onClick={handleMenuClick}
      items={[
        {
          label: t("pendingApprovalTravelAuthWidget.travel_approval"),
          key: "1",
        },
        {
          label: t("pendingApprovalTravelAuthWidget.travel_cancellation"),
          key: "2",
        },
      ]}
    ></Menu>
  );

  function onView() {
    const approveTravelAuth =
      pendingTravelAuthApproval.pendingTravelAuthInfo.filter(
        (x) => x.Status === 1 || x.Status === 4
      );

    const cancelTravelAuth =
      pendingTravelAuthApproval.pendingTravelAuthInfo.filter(
        (x) => x.Status !== 1 && x.Status !== 4
      );

    if (approveTravelAuth.length > 0) {
      const minYear = Math.min(...approveTravelAuth.map((item) => item.Year));
      history("/home/travelauthapproval", {
        state: {
          year: minYear,
        },
      });
    } else {
      const minYear = Math.min(...cancelTravelAuth.map((item) => item.Year));
      history("/home/travelauthcancellationapproval", {
        state: {
          year: minYear,
        },
      });
    }
  }

  function handleMenuClick(e) {
    const approveTravelAuth =
      pendingTravelAuthApproval.pendingTravelAuthInfo.filter(
        (x) => x.Status === 1 || x.Status === 4
      );

    const cancelTravelAuth =
      pendingTravelAuthApproval.pendingTravelAuthInfo.filter(
        (x) => x.Status !== 1 && x.Status !== 4
      );

    if (e.key === "1") {
      const minYear = Math.min(...approveTravelAuth.map((item) => item.Year));
      history("/home/travelauthapproval", {
        state: {
          year: minYear,
        },
      });
    } else {
      const minYear = Math.min(...cancelTravelAuth.map((item) => item.Year));
      history("/home/travelauthcancellationapproval", {
        state: {
          year: minYear,
        },
      });
    }
  }

  useEffect(() => {
    setHasPendingApproval(pendingTravelAuthApproval.pendingAmount > 0);
  }, [pendingTravelAuthApproval]);

  return (
    <div style={{ display: !isLoading ? "block" : "none" }}>
      <div style={{ textAlign: "center", margin: "10px auto" }}>
        <Badge
          className="dashboard-badge"
          count={
            <StarFilled
              style={
                hasPendingApproval ? { display: "none" } : { color: "#3498db" }
              }
            />
          }
        >
          <div className="pending-number">
            {pendingTravelAuthApproval.pendingAmount}
          </div>
        </Badge>
      </div>
      <div
        style={
          hasPendingApproval
            ? {
                marginTop: "15px",
                height: "195px",
                backgroundColor: "#f5f7fa",
                borderRadius: "10px",
                overflow: "auto",
              }
            : { display: "none" }
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={pendingTravelAuthApproval.pendingTravelAuthInfo}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                style={{ padding: "0 10px 0 10px" }}
                title={item.EmpyName}
              />
            </List.Item>
          )}
        />
      </div>
      <div
        style={
          hasPendingApproval ? { display: "none" } : { padding: "10px 0 10px" }
        }
      >
        <Empty
          image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
          imageStyle={{
            height: 60,
            display: "none",
          }}
          description={
            <span>
              {t("pendingApprovalTravelAuthWidget.no_more_pending_request")}
            </span>
          }
        ></Empty>
      </div>
      <div className="dashboard-card-button" style={{ textAlign: "right" }}>
        <Button
          type="primary"
          icon={<IssuesCloseOutlined />}
          onClick={() => onView && onView()}
          style={{
            display: !pendingTravelAuthApproval.isMixStatus ? "" : "none",
          }}
        >
          {t("pendingApprovalTravelAuthWidget.view_request")}
        </Button>

        <Dropdown
          dropdownRender={() => menu}
          style={{
            display: pendingTravelAuthApproval.isMixStatus ? "" : "none",
          }}
        >
          <Button
            type="primary"
            icon={<IssuesCloseOutlined />}
            style={{
              display: pendingTravelAuthApproval.isMixStatus ? "" : "none",
            }}
          >
            {t("pendingApprovalTravelAuthWidget.view_request")} <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}

PendingApprovalTravelAuthWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  pendingTravelAuthApproval: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};
