export default function TrainingApplicationModel({
  trainingApplicationKey = -1,
  trainingKey = -1,
  feedback = "",
  isFeedbackSubmitted = false,
  status = 0,
  statusDesc = "",
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  feedbackAttaches = []
}) {
  return Object.freeze({
    TrainingApplicationKey: trainingApplicationKey,
    TrainingKey: trainingKey,
    Feedback: feedback,
    IsFeedbackSubmitted: isFeedbackSubmitted,
    Status: status,
    StatusDesc: statusDesc,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    FeedbackAttaches: feedbackAttaches
  });
}
