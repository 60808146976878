import React from "react";
import { Table } from "antd";
import { ActiveCheckbox, EditButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const APRBySubjectTable = ({ data, onEdited, onRowSelected }) => {
  const { t } = useTranslation();

  const APRLvl = (value) =>
    value === 0 ? <div>{t("aprLvlConfigForm.not_required")}</div> : value;

  const IsOverrideActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );
  const APRBySubjectEditButton = (value) => (
    <EditButton fncEdit={() => onEdited(value)} />
  );
  data =
    data && data.length > 0
      ? data.map((record) => {
          return {
            ...record,
            L1ApprovedBy: record.L1ApprovedBy.join(", "),
            L2ApprovedBy: record.L2ApprovedBy.join(", "),
            ValidatedBy: record.ValidatedBy.join(", "),
          };
        })
      : [];
  const expandedRowRender = (result) => {
    const columns = [
      {
        title: t("aprConfigForm.L1_approved_by"),
        dataIndex: "L1ApprovedBy",
        key: "L1ApprovedBy",
        width: 150,
      },
      {
        title: t("aprConfigForm.L2_approved_by"),
        dataIndex: "L2ApprovedBy",
        key: "L2ApprovedBy",
        width: 150,
      },
    ];

    if (result.IsValidateTrans) {
      columns.push({
        title:
          result.Subject === "VEH" || result.Subject === "ROOM"
            ? t("aprConfigForm.assigned_by")
            : t("aprConfigForm.validated_by"),

        dataIndex: "ValidatedBy",
        key: "ValidatedBy",
        width: 150,
      });
    }

    return (
      <Table
        columns={columns}
        rowKey={(record) => record.APRConfgKey}
        dataSource={data.filter(
          (x) =>
            x.APRConfgKey === result.APRConfgKey &&
            x.SubjectKey === result.SubjectKey
        )}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: t("aprLvlConfigForm.subject"),
      dataIndex: "Subject",
      key: "Subject",
    },
    {
      title: t("general.description"),
      dataIndex: "SubjectDesc",
      key: "SubjectDesc",
    },
    {
      title: t("aprLvlConfigForm.approval_level"),
      dataIndex: "SubjectAppLvl",
      key: "AppLvl",
      align: "right",
      render: APRLvl,
    },
    {
      title: t("aprLvlConfigForm.email_notification"),
      dataIndex: "EmailNotification",
      key: "EmailNotification",
      align: "center",
      width: 150,
      render: IsOverrideActiveCheckbox,
    },
    data && data.length > 0
      ? {
          title: t("general.action"),
          dataIndex: "Subject",
          key: "EditAPRSubject",
          align: "center",
          width: 50,
          render: APRBySubjectEditButton,
        }
      : null,
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns.filter((x) => x !== null)}
        expandedRowRender={expandedRowRender}
        size="small"
        pagination={false}
        rowKey={(record) =>
          record.APRConfgKey + record.SubjectKey + record.Subject
        }
        onRow={(r) => ({ onClick: () => onRowSelected(r) })}
      />
    </div>
  );
};

APRBySubjectTable.propTypes = {
  data: PropTypes.array.isRequired,
  onEdited: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
};
