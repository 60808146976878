import React, { useState, useEffect } from "react";
import NotificationForm from "../forms/NotificationForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "../../redux/actions/notificationAction";
import { withRouter } from "../../customHooks/withRouter";

function NotificationPage({
  history,
  actions,
  notifications: notificationData,
}) {
  useEffect(() => {
    actions.loadNotifications();
  }, [actions]);

  const [selectedNotifications, setSelectedNotifications] = useState([]);

  function onItemClick(data) {
    if (data.IsRead === false) {
      actions
        .updateNotifications(data.NotificationKey)
        .then(() => {
          if (data.Route !== "") {
            history(data.Route);
          }
        })
        .catch(() => {});
    } else {
      if (data.Route !== "") {
        history(data.Route);
      }
    }
  }

  function onMark(data) {
    if (data.IsRead === false) {
      actions
        .updateNotifications(data.NotificationKey)
        .then(() => {})
        .catch(() => {});
    }
  }

  function onMarkSelected(data) {
    actions
      .updateSelectedNotifications(data)
      .then(() => {
        setSelectedNotifications([]);
      })
      .catch(() => {});
  }

  function onDelete(data) {
    actions
      .deleteNotifications(data.NotificationKey)
      .then(() => {})
      .catch(() => {});
  }

  function onDeleteSelected(data) {
    actions
      .deleteSelectedNotifications(data)
      .then(() => {
        setSelectedNotifications([]);
      })
      .catch(() => {});
  }

  return (
    <NotificationForm
      isLoading={false}
      data={notificationData}
      onMark={onMark}
      onMarkSelected={onMarkSelected}
      onDelete={onDelete}
      onDeleteSelected={onDeleteSelected}
      onItemClick={onItemClick}
      selectedItems={selectedNotifications}
      onItemSelected={setSelectedNotifications}
    />
  );
}

NotificationPage.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadNotifications: bindActionCreators(
        notificationActions.loadNotifications,
        dispatch
      ),
      updateNotifications: bindActionCreators(
        notificationActions.updateNotifications,
        dispatch
      ),
      deleteNotifications: bindActionCreators(
        notificationActions.deleteNotifications,
        dispatch
      ),
      updateSelectedNotifications: bindActionCreators(
        notificationActions.updateSelectedNotifications,
        dispatch
      ),
      deleteSelectedNotifications: bindActionCreators(
        notificationActions.deleteSelectedNotifications,
        dispatch
      ),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationPage));
