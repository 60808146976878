import React, { useState, useEffect } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Spin, Card, Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { StatusByValue } from "../../model/common/Status";
import PropTypes from "prop-types";
import {
  regionLocale,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export default function ClaimSubmissionWidget({
  isLoading,
  userClaimInfo,
  userCompCurr,
}) {
  const { t } = useTranslation();
  const [totalClaimMth, setTotalClaimMth] = useState(0);
  const [totalClaimYr, setTotalClaimYr] = useState(0);
  const [daysRemaining, setDaysRemaining] = useState(0);

  useEffect(() => {
    const today = new Date();
    let amtYr = 0,
      amtMth = 0;

    if (userClaimInfo) {
      if (today.getDate() > userClaimInfo.cutOffDay) setDaysRemaining(0);
      else setDaysRemaining(userClaimInfo.cutOffDay - today.getDate());

      if (userClaimInfo.claimsApplied.length > 0) {
        userClaimInfo.claimsApplied.forEach((item) => {
          if (
            dayjs(item.ClaimYearMonth).format("YYYY") ===
            dayjs(today).format("YYYY")
          ) {
            if (
              item.status !== StatusByValue.REJECTED &&
              item.status !== StatusByValue.VOID
            )
              amtYr += item.amount;
            if (
              dayjs(item.ClaimYearMonth).format("M") ===
              dayjs(today).format("M")
            ) {
              if (
                item.status !== StatusByValue.REJECTED &&
                item.status !== StatusByValue.VOID
              )
                amtMth += item.amount;
            }
          }
        });
      }
    }

    setTotalClaimYr(amtYr);
    setTotalClaimMth(amtMth);
  }, [userClaimInfo]);

  return (
    <Card
      title={
        <span style={{ color: "#0070cc" }}>
          {t("claimSubmissionWidget.claim_submission")}
        </span>
      }
      className="dashboard-card"
      extra={
        <Link to={{ pathname: "claimsubmissiondisplay" }}>
          <i>{t("claimSubmissionWidget.more")}</i>
        </Link>
      }
    >
      <Spin
        style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
      />
      <Row style={{ display: !isLoading ? "flex" : "none" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row>
            <Col span={24} style={{ padding: "10px", lineHeight: "1" }}>
              <span>{t("claimSubmissionWidget.total_claim_this_month")}</span>
              <h2 className="dashboard-label">
                {(userCompCurr.CurrSymb ? userCompCurr.CurrSymb + " " : "") +
                  totalClaimMth.toLocaleString(regionLocale(), {
                    minimumFractionDigits: precisionDecimal(
                      userCompCurr.CurrCode
                    ),
                    maximumFractionDigits: 2,
                  })}
              </h2>
              <br />
              <span>{t("claimSubmissionWidget.total_claim_this_year")}</span>
              <h3 className="dashboard-label">
                {(userCompCurr.CurrSymb ? userCompCurr.CurrSymb + " " : "") +
                  totalClaimYr.toLocaleString(regionLocale(), {
                    minimumFractionDigits: precisionDecimal(
                      userCompCurr.CurrCode
                    ),
                    maximumFractionDigits: 2,
                  })}
              </h3>
              <br />
              <span>
                {t("claimSubmissionWidget.due_date_claim_submission")}
              </span>
              <h3 className="dashboard-label">
                {daysRemaining} {t("general.days")}
              </h3>
            </Col>
          </Row>
        </Col>
        <div
          className="dashboard-card-button"
          style={{
            textAlign: "right",
          }}
        >
          <Link to={{ pathname: "claimsubmission/claimTrans" }}>
            <Button type="primary" icon={<PlusCircleOutlined />}>
              {t("claimSubmissionWidget.new_claim")}
            </Button>
          </Link>
        </div>
      </Row>
    </Card>
  );
}

ClaimSubmissionWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  userClaimInfo: PropTypes.object.isRequired,
  userCompCurr: PropTypes.object.isRequired,
};
