import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getApprovedAndVoidClaims,
  voidClaims,
  checkPayrollMonthEndClose,
} from "../../../api/claimApprovalApi";
import { getClaimConfg } from "../../../api/claimConfgApi";
import ApprovedClaimVoidForm from "../../forms/Claim/ApprovedClaimVoidForm";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { Modal } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

export default function ApprovedClaimVoidPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [claimTrans, setClaimTrans] = useState([]);
  const [selectedClaimTrans, setSelectedClaimTrans] = useState([]);
  const [invalidClaims, setInvalidClaims] = useState([]);
  const [claimConfg, setClaimConfg] = useState({});
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  useEffect(getClaimTrans, [currentYear]);

  function getClaimTrans() {
    setIsLoading(true);
    let promises = [getApprovedAndVoidClaims(currentYear), getClaimConfg()];

    Promise.all(promises)
      .then((values) => {
        setClaimTrans(values[0] ? values[0] : []);
        setClaimConfg(values[1] ? values[1] : {});
        setSelectedClaimTrans([]);
        setInvalidClaims([]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }

  function onAddNewClaim() {
    history("/home/claimsubmission/claimTrans");
  }

  function checkMthEndClose(selectedItems, reason) {
    setIsLoading(true);

    checkPayrollMonthEndClose(selectedItems)
      .then((value) => {
        setIsLoading(false);
        if (value.length > 0) {
          setInvalidClaims(value);
          confirm({
            title: t("claimSubmissionForm.void_confirmation_closed_payroll"),
            content: t("claimSubmissionForm.void_message_closed_payroll"),
            okText: t("general.proceed"),
            okType: "danger",
            cancelText: t("general.cancel"),
            onOk() {
              voidClaimTrans(selectedItems, reason);
            },
            onCancel() {},
          });
        } else {
          setInvalidClaims([]);
          voidClaimTrans(selectedItems, reason);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("claimSubmissionForm.fail_to_check_payroll_month_end_status"),
          error
        );
      });
  }

  function voidClaimTrans(selectedClaims, reason) {
    setIsLoading(true);

    voidClaims(selectedClaims, reason)
      .then(() => {
        getClaimTrans();
        toast.success(t("general.record_voided_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_void_record"), error);
      });
  }

  function viewClaimTrans(claimTransKey) {
    history("/home/claimsubmission/claimTrans/" + claimTransKey, {
      state: { fromVoid: true },
    });
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ApprovedClaimVoidForm
      isLoading={isLoading}
      data={claimTrans}
      selectedItems={selectedClaimTrans}
      onAdd={onAddNewClaim}
      onView={viewClaimTrans}
      onVoided={
        claimConfg && claimConfg.IsPostToQuarto
          ? checkMthEndClose
          : voidClaimTrans
      }
      onItemSelected={setSelectedClaimTrans}
      invalidClaims={invalidClaims}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
    />
  );
}

ApprovedClaimVoidPage.propTypes = {
  history: PropTypes.func.isRequired,
};
