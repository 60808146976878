import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "roletype/";

export function getAllRoleTypes() {
  return axios({
    method: "GET",
    url: base + "GetAllRoleTypes",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoleType(roleTypeKey) {
  return axios({
    method: "GET",
    url: base + "GetRoleType",
    headers: authHeader(),
    params: { key: roleTypeKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRoleTypeCode() {
  return axios({
    method: "GET",
    url: base + "GetRoleTypeCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserUnderRoleType(roleTypeKey) {
  return axios({
    method: "GET",
    url: base + "GetUserUnderRoleType",
    headers: authHeader(),
    params: { key: roleTypeKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addRoleType(roleType) {
  return axios({
    method: "POST",
    url: base + "AddRoleType",
    headers: authHeader(),
    data: roleType
  })
    .then()
    .catch(handleError);
}

export function updateRoleType(roleType) {
  return axios({
    method: "PUT",
    url: base + "UpdateRoleType",
    headers: authHeader(),
    params: { key: roleType.RoleTypeKey },
    data: roleType
  })
    .then()
    .catch(handleError);
}

export function deleteRoleType(roleTypeKey, deletedRoleType) {
  return axios({
    method: "DELETE",
    url: base + "DeleteRoleType",
    headers: authHeader(),
    params: { key: roleTypeKey },
    data: deletedRoleType
  })
    .then()
    .catch(handleError);
}
