import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseClaimSubmission = API_ROOT + "claimSubmission/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export async function getClaim(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseClaimSubmission + "GetAllClaimsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, 
        isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getClaimDashboard(source) {
  return axios
    .get(baseClaimSubmission + "GetAllClaimsByUserDashboard", {
      headers: authHeader(),
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getClaimByTransKey(transKey) {
  return axios
    .get(baseClaimSubmission + "GetClaimByTransKey", {
      headers: authHeader(),
      params: {
        transKey: transKey,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getClaimLimitAndUsage(claimTypeKey, claimYearMonth) {
  return axios
    .get(baseClaimSubmission + "GetClaimLimitAndUsage", {
      headers: authHeader(),
      params: {
        claimTypeKey: claimTypeKey,
        claimYearMonth: claimYearMonth,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addClaim(claim) {
  return axios({
    method: "POST",
    url: baseClaimSubmission + "AddClaim",
    headers: authHeader(),
    data: claim,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateClaim(claim) {
  return axios({
    method: "POST",
    url: baseClaimSubmission + "UpdateClaim?key=" + claim.ClaimTransKey,
    headers: authHeader(),
    data: claim,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawClaim(claimTransKey, claim) {
  return axios({
    method: "POST",
    url: baseClaimSubmission + "WithdrawClaim?key=" + claimTransKey,
    headers: authHeader(),
    data: claim,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelClaim(claimTransKey, claim, cancelReason) {
  return axios({
    method: "POST",
    url: baseClaimSubmission + "RequestCancel?key=" + claimTransKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: claim,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteClaim(claimTransKey, claim) {
  return axios({
    method: "POST",
    url: baseClaimSubmission + "DeleteClaim?key=" + claimTransKey,
    headers: authHeader(),
    data: claim,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function getLastClosedYrMth() {
  return axios({
    method: "GET",
    url: baseClaimSubmission + "GetLastClosedYrMth",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}
