import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "claimtype/";

export function getAllClaimTypes() {
  return axios({
    method: "GET",
    url: base + "GetAllClaimTypes",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClaimType(claimTypeKey) {
  return axios({
    method: "GET",
    url: base + "GetClaimType",
    headers: authHeader(),
    params: { key: claimTypeKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addClaimType(claimType) {
  return axios({
    method: "POST",
    url: base + "AddClaimType",
    headers: authHeader(),
    data: claimType
  })
    .then()
    .catch(handleError);
}

export function updateClaimType(claimType) {
  return axios({
    method: "PUT",
    url: base + "UpdateClaimType",
    headers: authHeader(),
    params: { key: claimType.ClaimTypeKey },
    data: claimType
  })
    .then()
    .catch(handleError);
}

export function deleteClaimType(claimTypeKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteClaimType",
    headers: authHeader(),
    params: { key: claimTypeKey }
  })
    .then()
    .catch(handleError);
}

export function getEntitledClaimTypes(empyKey = -1) {
  return axios({
    method: "GET",
    url: base + "GetEntitledClaimTypes",
    headers: authHeader(),
    params: { empyKey: empyKey }
  })
    .then(handleResponse)
    .catch(handleError);
}
