import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "publishControl/";

export function getPublishControl(year) {
  return axios({
    method: "GET",
    url: base + "GetPublishControl",
    headers: authHeader(),
    params: { year: year }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addPublishControl(publishControl) {
  return axios({
    method: "POST",
    url: base + "AddPublishControl",
    headers: authHeader(),
    data: publishControl
  })
    .then()
    .catch(handleError);
}

export function updatePublishControl(publishControl) {
  return axios({
    method: "PUT",
    url: base + "UpdatePublishControl",
    headers: authHeader(),
    params: { key: publishControl.PublishControlKey },
    data: publishControl
  })
    .then()
    .catch(handleError);
}
