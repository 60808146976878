export default function LeaveConfgModel({
  leaveConfgKey = -1,
  cutOffDay = 0,
  allowHalfDay = false,
  isValidateTrans = false,
  isPostULToQuarto = false,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
}) {
  return Object.freeze({
    LeaveConfgKey: leaveConfgKey,
    CutOffDay: cutOffDay,
    AllowHalfDay: allowHalfDay,
    IsValidateTrans: isValidateTrans,
    IsPostULToQuarto: isPostULToQuarto,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
  });
}
