import React from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const PaginationFooter = ({
  footerData,
  pagination,
  setPagination
}) => {
  const { t } = useTranslation();

  function onChangePagination(page, pageSize) {
    setPagination({
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
      pageSize: pageSize,
    });
  }

  return (
    <div style={{ textAlign: "right", marginRight: "10px" }}>
      {/* {t("general.total")} : <b style={{ color: "#0070cc" }}>{footerValue}</b> */}
      <Pagination
        defaultCurrent={pagination.current}
        current={pagination.current}
        total={footerData}
        onChange={onChangePagination}
        showTotal={(total) =>
          t("general.total") + ` ${total} ` + t("general.items")
        }
        defaultPageSize={pagination.pageSize}
        pageSize={pagination.pageSize}
      />
    </div>
  );
};

PaginationFooter.propTypes = {
  footerData: PropTypes.number.isRequired,
  pagination: PropTypes.object.isRequired,
  setPagination: PropTypes.func.isRequired,
};

export default PaginationFooter;