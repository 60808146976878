import { combineReducers } from "redux";
import user from "./userReducers";
import leavetypes from "./leaveTypeReducers";
import empy from "./empyReducers";
import roleassignments from "./roleAssignmentReducers";
import notifications from "./notificationReducers";

const rootReducer = combineReducers({
  user,
  leavetypes,
  roleassignments,
  empy,
  notifications
});

export default rootReducer;
