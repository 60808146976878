import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  InputNumber,
  Spin,
  Card,
  Modal,
  Checkbox,
  Divider,
  Upload,
  message,
  Form,
  Table,
} from "antd";
import { EditButton } from "../../common/TableCols";
import dayjs from "dayjs";
import { SelectionInput } from "../../common/SelectionInput";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import EntryFormFooter from "../../common/EntryFormFooter";
import PropTypes from "prop-types";
import {
  Status,
  StatusByValue,
  isStatusOpen,
  isStatusSubmitted,
  isStatusApproved,
  isStatusPartialApproved,
  isStatusRequestCancel,
  isStatusRejected,
  isStatusVoid,
  isStatusClosed,
} from "../../model/common/Status";
import { RowState } from "../../model/common/RowState";
import { RecordType } from "../../model/common/RecordType";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";
import VehicleReqPassengerModel from "../../model/VehicleReq/VehicleReqPassengerModel";
import ManageVehicleReqPassengerForm from "./ManageVehicleReqPassengerForm";

const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { confirm } = Modal;

const ManageVehicleRequisitionForm = ({
  isLoading,
  form,
  editItem,
  onDataChanged,
  loadPageData,
  onSubmitted,
  onCancelled,
  onWithdraw,
  onDelete,
  onRequestCancel,
  onAssignVehicleAndMiles,
  onCompleteVehicleRequisition,
  onIsSaveAndNewChange,
  onUploadAttachment,
  onRemoveAttachment,
  isSaveAndNewData,
  fileList,
  setFileList,
  allVehicles,
  isOwner,
  isViewOnly = false,
  isAssignor,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const isEdit = editItem.VehicleRequisitionKey > 0;
  const isSystemRcd = editItem.RcdType === RecordType.SYSTEM;
  const isOpen = isStatusOpen(editItem.Status);
  const isSubmitted = isStatusSubmitted(editItem.Status);
  const isVoid = isStatusVoid(editItem.Status);
  const isApproved =
    isStatusPartialApproved(editItem.Status) ||
    isStatusApproved(editItem.Status);
  const isRequestCancelRejected =
    isApproved &&
    editItem.RejectRequestCancelBy > 0 &&
    editItem.Reason.length > 0;
  const isRejectWithAmendment =
    isOpen && editItem.Reason.length > 0 && editItem.RejectedBy > 0;
  const isShowReason =
    isStatusVoid(editItem.Status) ||
    isStatusRejected(editItem.Status) ||
    isStatusRequestCancel(editItem.Status) ||
    isRequestCancelRejected ||
    isRejectWithAmendment;
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const { VehicleRequisitionKey } = useParams();

  const [showPassengerForm, setShowPassengerForm] = useState(false);
  const newPassenger = VehicleReqPassengerModel({});
  const [selectedPassenger, setSelectedPassenger] = useState(newPassenger);

  const [formVehicleReqPassenger] = Form.useForm();
  const [
    isFormVehicleReqPassengerInitialized,
    setIsFormVehicleReqPassengerInitialized,
  ] = useState(false);
  useEffect(
    () => formVehicleReqPassenger.resetFields(),
    [isFormVehicleReqPassengerInitialized, formVehicleReqPassenger]
  );

  useEffect(() => {
    loadPageData(VehicleRequisitionKey, form.resetFields);
    // eslint-disable-next-line
  }, [VehicleRequisitionKey]);

  function showDeleteConfirm() {
    confirm({
      title: t("general.delete_confirmation"),
      content: t("vehicleRequisitionForm.delete_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk: onActionDelete,
      onCancel() {},
    });
  }

  function showWithdrawConfirm() {
    confirm({
      title: t("general.withdraw_confirmation"),
      content: t("vehicleRequisitionForm.withdraw_message"),
      okText: t("general.yes"),
      cancelText: t("general.no"),
      onOk: onActionWithdraw,
      onCancel() {},
    });
  }

  function showSubmitConfirm() {
    form
      .validateFields()
      .then(() => {
        confirm({
          title: t("general.submit_confirmation"),
          content: t("vehicleRequisitionForm.submit_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            handleSubmit(true);
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function handleSubmit(isSubmit) {
    form
      .validateFields()
      .then((values) => {
        let isPassengersValid = true;

        editItem.Passengers.forEach((item) => {
          //name is required
          if (item.Name.trim().length === 0) {
            isPassengersValid = false;
            toast.error(
              t(
                item.Type === "D"
                  ? "vehicleRequisitionForm.driver_name_required"
                  : "vehicleRequisitionForm.passenger_name_required"
              )
            );
            return;
          }
        });

        if (isPassengersValid) {
          let submitData = {
            ...editItem,
            ...values,
            VehicleKey: -1,
          };

          let fromDate = values.ReqDate[0];
          fromDate.set({ second: 0, millisecond: 0 });
          submitData.FromReqDate = fromDate.startOf("minute").$d;

          let toDate = values.ReqDate[1];
          toDate.set({ second: 0, millisecond: 0 });
          submitData.ToReqDate = toDate.startOf("minute").$d;
          submitData.Status = isSubmit
            ? StatusByValue.SUBMITTED
            : StatusByValue.OPEN;

          uploadAttachments()
            .then((result) => {
              submitData.Attaches = [...submitData.Attaches.slice(), ...result];
              onSubmitted(submitData);
            })
            .catch(() => {
              toast.error(t("general.fail_to_upload_file"));
            });
        }
      })
      .catch(() => {});
  }

  function onActionWithdraw() {
    onWithdraw(editItem);
  }

  function onActionDelete() {
    onDelete(editItem);
  }

  function submitCancelReason(reason) {
    setShowReasonForm(false);
    onRequestCancel(editItem, reason);
  }

  function onChangeIsSaveAndNew(e) {
    onIsSaveAndNewChange(e.target.checked);
  }

  function assignVehicleAndMiles() {
    form
      .validateFields()
      .then((values) => {
        let submitData = {
          ...editItem,
          VehicleKey: values.Vehicle ? values.Vehicle.key : -1,
          StartKM: values.StartKM,
          EndKM: values.EndKM,
          ClosureRemarks: values.ClosureRemarks.trim(),
        };

        uploadAttachments()
          .then((result) => {
            submitData.Attaches = [...submitData.Attaches.slice(), ...result];
            onAssignVehicleAndMiles(submitData);
          })
          .catch(() => {
            toast.error(t("general.fail_to_upload_file"));
          });
      })
      .catch(() => {});
  }

  function completeVehicleRequisition() {
    form
      .validateFields()
      .then((values) => {
        confirm({
          title: t("vehicleRequisitionForm.vehicle_requisition_closure"),
          content:
            t("vehicleRequisitionForm.closure_message") +
            values.StartKM +
            " KM - " +
            values.EndKM +
            " KM?",
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            let submitData = {
              ...editItem,
              VehicleKey: values.Vehicle ? values.Vehicle.key : -1,
              StartKM: values.StartKM,
              EndKM: values.EndKM,
              ClosureRemarks: values.ClosureRemarks.trim(),
            };

            uploadAttachments()
              .then((result) => {
                submitData.Attaches = [
                  ...submitData.Attaches.slice(),
                  ...result,
                ];
                onCompleteVehicleRequisition(submitData);
              })
              .catch(() => {
                toast.error(t("general.fail_to_upload_file"));
              });
          },
          onCancel() {},
        });
      })
      .catch(() => {});
  }

  function uploadProp() {
    return {
      onRemove: (file) => {
        editItem.Attaches.forEach((item) => {
          if (item.AttchKey === file.uid) {
            item.RowState = RowState.DELETED;
            setDeletedAttachments([...deletedAttachments, file.url]);
          }
        });
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        let tempRcd = fileList.filter((item) => item.name === file.name);
        if (tempRcd.length > 0) {
          message.error(t("general.duplicate_uploaded_file_name"));
        } else {
          file.RowState = 1;
          setFileList([...fileList, file]);
        }

        return false;
      },
      showUploadList: {
        showPreviewIcon: true,
        showRemoveIcon:
          (isOpen && isOwner) ||
          (!isOpen &&
            ((!editItem.IsRequireApproval && isSubmitted) ||
              isStatusApproved(editItem.Status)) &&
            isAssignor),
      },
      fileList,
    };
  }

  function uploadAttachments() {
    const formData = new FormData();

    let newFiles = fileList.filter((x) => x.RowState === 1);
    newFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    if (deletedAttachments.length) removeAttachments(deletedAttachments);
    if (!newFiles.length) {
      return new Promise((resolve) => {
        resolve([]);
      });
    }
    return onUploadAttachment(formData);
  }

  function removeAttachments(file) {
    return onRemoveAttachment(file).then(() => {
      setDeletedAttachments([]);
    });
  }

  // Details
  function getFilterVehicleReqPassengerData() {
    return editItem.Passengers.filter((x) => x.RowState !== RowState.DELETED);
  }

  function onChangeNoOfPassengers(value) {
    if (value > 0) {
      var updatedPassengers = [];
      if (value > editItem.Passengers.length) {
        const additionalPassengers = Array.from(
          { length: value - editItem.Passengers.length },
          (_, index) =>
            VehicleReqPassengerModel({ type: "P", typeDesc: "Passenger" })
        );
        updatedPassengers = [...editItem.Passengers, ...additionalPassengers];
      } else {
        updatedPassengers = editItem.Passengers.slice(0, value);
      }

      onDataChanged({
        ...editItem,
        NoOfPassengers: value,
        Passengers: updatedPassengers,
      });
    }
  }

  function editPassenger(passengerKey) {
    let passenger = editItem.Passengers.filter(
      (item) => item.VehicleReqPassengerKey === passengerKey
    );
    setSelectedPassenger(passenger[0]);
    setShowPassengerForm(true);
    setIsFormVehicleReqPassengerInitialized(
      !isFormVehicleReqPassengerInitialized
    );
  }

  function savePassenger(passenger) {
    if (passenger !== undefined || passenger !== null) {
      let index = editItem.Passengers.findIndex(
        (x) => x.VehicleReqPassengerKey === passenger.VehicleReqPassengerKey
      );

      if (index === -1) {
        onDataChanged({
          ...editItem,
          Passengers: [...editItem.Passengers, { ...passenger }],
        });
      } else {
        let temp = editItem.Passengers.map((item) =>
          item.VehicleReqPassengerKey === passenger.VehicleReqPassengerKey
            ? item.RowState === RowState.ADDED
              ? passenger
              : { ...passenger, RowState: RowState.MODIFIED }
            : item
        );
        onDataChanged({ ...editItem, Passengers: temp });
      }
      setShowPassengerForm(false);
    }
  }

  function cancelInputPassenger() {
    setSelectedPassenger(newPassenger);
    setShowPassengerForm(false);
  }

  const DetActions = (value, record) => (
    <EditButton fncEdit={() => editPassenger(value)} disabled={isViewOnly || !isOpen} />
  );

  const columns = [
    {
      title: t("general.name"),
      align: "left",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: t("general.type"),
      align: "left",
      dataIndex: "TypeDesc",
      key: "TypeDesc",
      width: 100,
    },
    {
      title: t("general.action"),
      dataIndex: "VehicleReqPassengerKey",
      key: "DetActions",
      align: "center",
      width: 100,
      render: DetActions,
    },
  ];

  return (
    <div>
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          sm={{ span: 24, offset: 0 }}
          xl={{ span: 24, offset: 0 }}
          xxl={{ span: 16, offset: 4 }}
        >
          <Card
            title={
              <div>
                <span className="form-title">
                  {t("vehicleRequisitionForm.vehicle_requisition")}
                </span>
              </div>
            }
          >
            <Spin spinning={isLoading}>
              <Form
                form={form}
                layout="vertical"
                onValuesChange={() => setIsDirty(true)}
              >
                <Row gutter={12}>
                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.requisition_date")}</span>}
                      name="ReqDate"
                      rules={[
                        {
                          required: true,
                          message: t(
                            "general.requisition_date_required_validation"
                          ),
                        },
                      ]}
                      initialValue={editItem.ReqDate}
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        allowClear={true}
                        showToday={false}
                        showTime={{
                          defaultValue: dayjs("00:00:00", "HH:mm:ss"),
                          format: "HH:mm",
                        }}
                        format="DD/MM/YYYY HH:mm"
                        disabled={!isOpen || !isOwner || isSystemRcd}
                        placeholder={[
                          t("general.from_date"),
                          t("general.to_date"),
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.no_of_passengers")}</span>}
                      name="NoOfPassengers"
                      onChange={(value) =>
                        onChangeNoOfPassengers(value.target.value)
                      }
                      rules={[
                        {
                          required: true,
                          message: t(
                            "vehicleRequisitionForm.no_of_passengers_required_validation"
                          ),
                        },
                        {
                          validator: (_, value) => {
                            if (value <= 0)
                              return Promise.reject(
                                new Error(
                                  t(
                                    "vehicleRequisitionForm.no_of_passengers_must_be_>_0"
                                  )
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.NoOfPassengers}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step="1"
                        min={0}
                        precision={0}
                        formatter={inputLocaleFormatter(0)}
                        parser={inputLocaleParser(0)}
                        disabled={!isOpen || !isOwner || isSystemRcd}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Form.Item
                      label={<span>{t("general.status")}</span>}
                      name="StatusDesc"
                      initialValue={Status[editItem.Status]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>

                <Col
                  span={24}
                  style={{
                    display: isShowReason ? "" : "none",
                  }}
                >
                  <Form.Item
                    label={
                      isRequestCancelRejected
                        ? t("general.reason_of_cancel_request_rejected")
                        : t("general.reason")
                    }
                  >
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      disabled={true}
                      value={editItem.Reason}
                    />
                  </Form.Item>
                </Col>

                <Row gutter={12}>
                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{
                      display:
                        editItem.VehicleKey > 0 ||
                        (((!editItem.IsRequireApproval && isSubmitted) ||
                          isStatusApproved(editItem.Status)) &&
                          isAssignor)
                          ? ""
                          : "none",
                    }}
                  >
                    <Form.Item
                      label={<span>{t("vehicleForm.vehicle")}</span>}
                      name="Vehicle"
                      rules={[
                        {
                          required:
                            ((!editItem.IsRequireApproval && isSubmitted) ||
                              isStatusApproved(editItem.Status)) &&
                            editItem.VehicleKey === -1 &&
                            isAssignor,
                          message: t("vehicleForm.vehicle_required_validation"),
                        },
                        {
                          validator: (_, value) => {
                            if (
                              value &&
                              !allVehicles.some((x) => x.Key === value.value)
                            ) {
                              return Promise.reject(
                                new Error(t("vehicleForm.invalid_vehicle"))
                              );
                            }

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={
                        editItem.VehicleKey > 0
                          ? {
                              key: editItem.VehicleKey,
                              value: editItem.VehicleKey,
                              label:
                                editItem.VehicleCode +
                                " - " +
                                editItem.VehicleDesc,
                            }
                          : undefined
                      }
                    >
                      <SelectionInput
                        ref={React.createRef()}
                        labelInValue={true}
                        data={allVehicles}
                        valueProp={"Key"}
                        textProp={"CodeDesc"}
                        disabledProp={"Active"}
                        placeholder={t("vehicleForm.vehicle_placeholder")}
                        disabled={
                          !isAssignor ||
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ display: editItem.VehicleKey > 0 ? "" : "none" }}
                  >
                    <Form.Item
                      label={<span>{t("general.start_km")}</span>}
                      name="StartKM"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value < 0)
                              return Promise.reject(
                                new Error(
                                  t(
                                    "vehicleRequisitionForm.start_km_must_be_>_0"
                                  )
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.StartKM}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step="1"
                        min={0}
                        precision={0}
                        formatter={inputLocaleFormatter(0)}
                        parser={inputLocaleParser(0)}
                        disabled={
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          (!isOwner && !isAssignor) ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={24}
                    sm={24}
                    md={8}
                    lg={8}
                    xl={8}
                    style={{ display: editItem.VehicleKey > 0 ? "" : "none" }}
                  >
                    <Form.Item
                      label={<span>{t("general.end_km")}</span>}
                      name="EndKM"
                      rules={[
                        {
                          validator: (_, value) => {
                            if (value < 0)
                              return Promise.reject(
                                new Error(
                                  t("vehicleRequisitionForm.end_km_must_be_>_0")
                                )
                              );

                            return Promise.resolve();
                          },
                        },
                      ]}
                      initialValue={editItem.EndKM}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        step="1"
                        min={0}
                        precision={0}
                        formatter={inputLocaleFormatter(0)}
                        parser={inputLocaleParser(0)}
                        disabled={
                          (!(!editItem.IsRequireApproval && isSubmitted) &&
                            !isStatusApproved(editItem.Status)) ||
                          (!isOwner && !isAssignor) ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.purpose")}</span>}
                      name="Remarks"
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t("general.purpose_required_validation"),
                        },
                        {
                          max: 1000,
                          message: t("general.purpose_max_length_validation"),
                        },
                      ]}
                      initialValue={editItem.Remarks}
                    >
                      <TextArea
                        placeholder={t("general.purpose_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          !isOpen || !isOwner || isViewOnly || isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    span={24}
                    style={{ display: editItem.VehicleKey > 0 ? "" : "none" }}
                  >
                    <Form.Item
                      label={<span>{t("general.closure_remarks")}</span>}
                      name="ClosureRemarks"
                      initialValue={editItem.ClosureRemarks}
                    >
                      <TextArea
                        placeholder={t("general.closure_remarks_placeholder")}
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        disabled={
                          isStatusClosed(editItem.Status) ||
                          !isAssignor ||
                          isViewOnly ||
                          isSystemRcd
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <h3 className="form-subtitle">
                      {t("vehicleRequisitionForm.passenger")}
                    </h3>
                    <div>
                      <Table
                        dataSource={getFilterVehicleReqPassengerData()}
                        columns={columns}
                        size="middle"
                        pagination={false}
                        rowKey={(record) => record.VehicleReqPassengerKey}
                        bordered
                      />
                    </div>
                    <ManageVehicleReqPassengerForm
                      isVisible={showPassengerForm}
                      selectedDet={selectedPassenger}
                      onSave={savePassenger}
                      onCancel={cancelInputPassenger}
                      setIsDirty={setIsDirty}
                      form={formVehicleReqPassenger}
                    />
                  </Col>
                  <Divider />
                  <Col span={24}>
                    <Form.Item
                      label={<span>{t("general.attachment")}</span>}
                      initialValue="Attachment"
                      rules={[{ required: false }]}
                    >
                      <Upload {...uploadProp()} listType="picture">
                        <Button
                          disabled={
                            !(
                              (isOpen && isOwner) ||
                              (!isOpen &&
                                ((!editItem.IsRequireApproval && isSubmitted) ||
                                  isStatusApproved(editItem.Status)) &&
                                isAssignor) ||
                              !isViewOnly
                            )
                          }
                        >
                          <UploadOutlined /> {t("general.browse")}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row className="save-form-section">
                  <Col style={{ textAlign: "right", padding: "10px" }}>
                    <Row>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }}>
                        <Checkbox
                          checked={isSaveAndNewData}
                          onChange={onChangeIsSaveAndNew}
                          style={{
                            float: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save_and_new")}
                        </Checkbox>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                        <Button
                          size="large"
                          type="link"
                          onClick={onCancelled}
                          style={{
                            marginRight: 10,
                          }}
                        >
                          {t("general.back")}
                        </Button>
                        <Button
                          size="large"
                          onClick={() => handleSubmit(false)}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        {/* Save for vehicle assignment and miles entry */}
                        <Button
                          size="large"
                          onClick={() => assignVehicleAndMiles()}
                          style={{
                            marginRight: isOwner || isAssignor ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              (isAssignor ||
                                (isOwner && editItem.VehicleKey > 0)) &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.save")}
                        </Button>
                        <Button
                          size="large"
                          type="link"
                          onClick={showDeleteConfirm}
                          style={{
                            display:
                              isEdit &&
                              isOpen &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.delete")}
                        </Button>
                        <Button
                          size="large"
                          type="primary"
                          onClick={showSubmitConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isOpen && isOwner && !isViewOnly && !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.submit")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={showWithdrawConfirm}
                          style={{
                            marginRight: 10,
                            display:
                              isSubmitted &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.withdraw")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => setShowReasonForm(true)}
                          style={{
                            display:
                              isApproved &&
                              isOwner &&
                              !isViewOnly &&
                              !isSystemRcd
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.request_cancel")}
                        </Button>
                        <Button
                          type="primary"
                          size="large"
                          onClick={() => completeVehicleRequisition()}
                          style={{
                            background: "mediumpurple",
                            borderColor: "mediumpurple",
                            marginLeft: isOwner ? 10 : 0,
                            display:
                              ((!editItem.IsRequireApproval && isSubmitted) ||
                                isStatusApproved(editItem.Status)) &&
                              isAssignor &&
                              !isViewOnly &&
                              !isSystemRcd &&
                              editItem.VehicleKey > 0
                                ? ""
                                : "none",
                          }}
                        >
                          {t("general.complete")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Spin>
          </Card>
          <EntryFormFooter
            footerData={editItem}
            isApproved={isApproved}
            isVoid={isVoid}
            isPending={
              editItem.Status === 4 || editItem.Status === 1 ? true : false
            }
            showFooter={editItem.VehicleRequisitionKey !== -1 ? true : false}
            t={t}
          ></EntryFormFooter>
        </Col>
      </Row>

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"Cancel Vehicle Requisition"}
        onSaved={submitCancelReason}
        onCancelled={() => setShowReasonForm(false)}
      />
    </div>
  );
};

ManageVehicleRequisitionForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  editItem: PropTypes.object.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  loadPageData: PropTypes.func.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onWithdraw: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRequestCancel: PropTypes.func.isRequired,
  onAssignVehicleAndMiles: PropTypes.func.isRequired,
  onCompleteVehicleRequisition: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onIsSaveAndNewChange: PropTypes.func.isRequired,
  isSaveAndNewData: PropTypes.bool.isRequired,
  allVehicles: PropTypes.array.isRequired,
  isOwner: PropTypes.bool.isRequired,
  isViewOnly: PropTypes.bool,
  isAssignor: PropTypes.bool.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageVehicleRequisitionForm;
