import { RowState } from "../common/RowState";

export default function VehicleReqPassengerModel({
  vehicleReqPassengerKey = Math.floor(Math.random() * 999999),
  vehicleRequisitionKey = -1,
  name = "",
  type = "P",
  typeDesc = "Passenger",
  RowVersion = null,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    VehicleReqPassengerKey: vehicleReqPassengerKey,
    VehicleRequisitionKey: vehicleRequisitionKey,
    Name: name,
    Type: type,
    TypeDesc: typeDesc,
    RowVersion: RowVersion,
    RowState: rowState
  });
}
