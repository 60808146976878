export default function ClaimConfgModel({
  claimConfgKey = -1,
  cutOffDay = 0,
  limitResetMth = 0,
  isGroupByProj = false,
  isMultiCurrency = false,
  isValidateTrans = false,
  isPostToQuarto = false,
  isProrated = true,
  prorateDateBased = 'H',
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null
}) {
  return Object.freeze({
    ClaimConfgKey: claimConfgKey,
    CutOffDay: cutOffDay,
    LimitResetMth: limitResetMth,
    IsGroupByProj: isGroupByProj,
    IsMultiCurrency: isMultiCurrency,
    IsValidateTrans: isValidateTrans,
    IsPostToQuarto: isPostToQuarto,
    IsProrated: isProrated,
    ProrateDateBased: prorateDateBased,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion
  });
}
