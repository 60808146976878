import React, { useState, useEffect } from "react";
import * as atapi from "../../../api/appraisalTemplateApi";
import { toast } from "react-toastify";
import { AppraisalTemplateForm } from "../../forms/Master/AppraisalTemplateForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AppraisalTemplatePage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [appraisalTemplate, setAppraisalTemplate] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getAppraisalTemplate, []);

  function getAppraisalTemplate() {
    atapi
      .getAllAppraisalTemplates()
      .then((result) => {
        setIsLoading(false);
        setAppraisalTemplate(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setAppraisalTemplate([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/appraisaltemplates/appraisaltemplate", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(appraisalTemplateKey) {
    history(
      "/home/appraisaltemplates/appraisaltemplate/" + appraisalTemplateKey,
      {
        state: { currentPage: currentPage },
      }
    );
  }

  function onDelete(appraisalTemplateKey) {
    showDeleteConfirm(
      "Appraisal Template",
      appraisalTemplateKey,
      deleteAppraisalTemplate,
      t
    );
  }

  function deleteAppraisalTemplate(appraisalTemplateKey) {
    setIsLoading(true);
    atapi
      .deleteAppraisalTemplate(appraisalTemplateKey)
      .then(() => {
        const newData = appraisalTemplate.filter(
          (item) => item.ATKey !== appraisalTemplateKey
        );
        setAppraisalTemplate(newData);
        setIsLoading(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <AppraisalTemplateForm
      isLoading={isLoading}
      appraisalTemplate={appraisalTemplate}
      onAdd={addNewItem}
      onEdit={editItem}
      onDelete={onDelete}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

AppraisalTemplatePage.propTypes = {
  history: PropTypes.func.isRequired,
};
