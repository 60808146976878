import dayjs from "dayjs";
import React,{ useEffect, useState } from "react";
import Axios from "axios";
import { Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import { getClaim } from "../../../api/claimSubmissionApi";
import { getSignedAttachmentUrl } from "../../../api/attachApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getESSEmployee } from "../../../api/employeeApi";
import { getAllClaimTypes } from "../../../api/claimTypeApi";
import ClaimAdminForm from "../../forms/AdminPortal/ClaimAdminForm";
import { useLocation } from "react-router-dom";


export default function ClaimAdminPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [allClaimDS, setAllClaimDS] = useState([]);
  const [filteredClaimDS, setFilteredClaimDS] = useState([]);
  const [form] = Form.useForm();
  const location = useLocation();   //remain filter values when close input form
  const [currentYear, setCurrentYear] = useState(
    location.state ? location.state.filterYear : dayjs().year()
  );
  const [currentMonth, setCurrentMonth] = useState(
    location.state ? location.state.filterMonth : dayjs().month() + 1
  );
  const [allOUs, setAllOUs] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allClaimTypes, setAllClaimTypes] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [ouKey, setOUKey] = useState(
    location.state ? location.state.filterOUKey : 0
  );
  const [deptKey, setDeptKey] = useState(
    location.state ? location.state.filterDeptKey : 0
  );
  const [userKey, setUserKey] = useState(
    location.state ? location.state.filterUserKey : 0
  );
  const [claimTypeKey, setClaimTypeKey] = useState(
    location.state ? location.state.filterClaimTypeKey : 0
  );
  const [pagination, setPagination] = useState(
    {
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });

  useEffect(getClaims, []);

  function getClaims() {    
    let promises = [
      getClaimDataSource(currentYear, currentMonth),
      getOU(),
      getDepartment(),
      getESSEmployee(),
      getAllClaimTypes(),
    ];

    Promise.all(promises).then((values) => {
      resetPagination();

      setAllOUs(values[1] ? values[1].filter((x) => x.Active) : []);
      setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
      setAllUsers(values[3] ? values[3] : [])
      setInitialUsers(values[3] ? values[3] : []);
      setAllClaimTypes(values[4] ? values[4].filter((x) => x.Active) : []);
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      setAllClaimDS([]);
      setFilteredClaimDS([]);
      setAllOUs([]);
      setAllDepartments([]);
      setAllUsers([]);
      setAllClaimTypes([]);
      showErrorMessage(
        t('general.fail_to_load_record') + 
          error.response.data.error_description
      );
    });
  }

  function getClaimDataSource(currentYear, currentMonth) {
    setIsLoading(true);
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();

    getClaim(source, currentYear, currentMonth, true).then((result) => {
      setAllClaimDS(result);
      setFilteredClaimDS(
        result.filter(
          (x) => 
            (ouKey === 0 || x.OUKey === ouKey) &&
            (deptKey === 0 || x.DeptKey === deptKey) &&
            (userKey === 0 || x.UserKey === userKey) && 
            (claimTypeKey === 0 || x.ClaimTypeKey === claimTypeKey)
          )
      );
      setIsLoading(false);
    })
    .catch((error) => {
      setIsLoading(false);
      setAllClaimDS([]);
      setFilteredClaimDS([]);
      showErrorMessage(
        t('general.fail_to_load_record') + 
          error.response.data.error_description
      );
    });
  }

  function onViewClaimTrans(claimTransKey) {
    history("/home/claimsubmission/claimTrans/" + claimTransKey, { state: { 
      fromAdmin: true,
      filterOUKey: ouKey,
      filterDeptKey: deptKey,
      filterUserKey: userKey,
      filterClaimTypeKey: claimTypeKey,
      filterYear: currentYear,
      filterMonth: currentMonth,
    }});
  }

  function getValidAttachments(attachments) {
    return getSignedAttachmentUrl(attachments);
  }

  function filterRecord(ouKey, deptKey, userKey, claimTypeKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setUserKey(userKey);
    setClaimTypeKey(claimTypeKey);
    setFilteredClaimDS(
      allClaimDS.filter(
        (x) => 
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (userKey === 0 || x.UserKey === userKey) && 
          (claimTypeKey === 0 || x.ClaimTypeKey === claimTypeKey)
        )
    );
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function resetPagination() {
    setPagination({
      current: 1,
      minIndex: 0,
      maxIndex: 100,
      pageSize: 100,
    });
  }

  return (
    <ClaimAdminForm 
      isLoading={isLoading}
      claimDS={filteredClaimDS}
      getClaimDataSource={getClaimDataSource}
      form={form}
      onViewClaimTrans={onViewClaimTrans}
      onAttachmentClicked={getValidAttachments}
      setCurrentYear={setCurrentYear}
      setCurrentMonth={setCurrentMonth}
      allOUs={allOUs}
      allDepartments={allDepartments}
      allUsers={allUsers}
      allClaimTypes={allClaimTypes}
      initialUsers={initialUsers}
      setAllUsers={setAllUsers}
      ouKey={ouKey}
      deptKey={deptKey}
      userKey={userKey}
      claimTypeKey={claimTypeKey}
      currentYear={currentYear}
      currentMonth={currentMonth}
      filterRecord={filterRecord}
      pagination={pagination}
      setPagination={setPagination}
      resetPagination={resetPagination}
    />
  );
};

ClaimAdminPage.propTypes = {
  history: PropTypes.func.isRequired,
};