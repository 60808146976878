import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";
// Will change to this method
//const baseUrl = process.env.API_URL + "/LeaveType/";

const base = API_ROOT + "ESSUser/";

export function getESSUser() {
  return axios({
    method: "GET",
    url: base + "GetAllActiveESSUsers",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllESSUser() {
  return axios({
    method: "GET",
    url: base + "GetAllESSUsers",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserWithApprovelPerm() {
  return axios({
    method: "GET",
    url: base + "GetUserWithApprovalPerm",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSelectedUser(userKey) {
  return axios({
    method: "GET",
    url: base + "GetSelectedUser",
    headers: authHeader(),
    params: { key: userKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSelectedUserByEmail(userEmail) {
  return axios({
    method: "GET",
    url: base + "GetSelectedUserByEmail",
    headers: authHeader(),
    params: { email: userEmail },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function UpdateESSUser(roleassignment) {
  let urlString = base;

  urlString = base + "UpdateESSUser";

  return axios({
    method: "PUT",
    url: urlString,
    headers: authHeader(),
    params: { key: roleassignment.UserKey },
    data: roleassignment,
  })
    .then()
    .catch(handleError);
}
