export default function RoomModel({
  RoomKey = -1,
  RoomCode = "",
  RoomDesc = "",
  OUKey = -1,
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null
}) {
  return Object.freeze({
    RoomKey: RoomKey,
    RoomCode: RoomCode,
    RoomDesc: RoomDesc,
    OUKey: OUKey,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion
  });
}
