import React, { useState } from "react";
import PropTypes from "prop-types";
import { ActiveCheckbox, EditButton } from "../../common/TableCols";
import { Table, Card, Spin } from "antd";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const APRLvlConfgForm = ({
  isLoading,
  aprLvlConfgs,
  onEdit,
  onRowSelected,
  rowSelected,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const IsOverrideActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const APRLvlConfgEditButton = (value) => (
    <EditButton fncEdit={() => onEdit(value)} />
  );
  const APRLvl = (value) =>
    value === 0 ? <div>{t("aprLvlConfigForm.not_required")}</div> : value;

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("aprLvlConfigForm.subject"),
      dataIndex: "Subject",
      key: "Subject",
      width: 200,
      sorter: (a, b) => a.Subject.localeCompare(b.Subject),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "Subject",
        searchText,
        handleSearch,
        handleReset,
        "Subject",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "SubjectDesc",
      key: "SubjectDesc",
      sorter: (a, b) => a.SubjectDesc.localeCompare(b.SubjectDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "SubjectDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("aprLvlConfigForm.approval_level"),
      dataIndex: "AppLvl",
      key: "AppLvl",
      width: 200,
      render: APRLvl,
    },
    {
      title: t("aprLvlConfigForm.override"),
      dataIndex: "IsOverride",
      key: "IsOverride",
      align: "center",
      width: 100,
      render: IsOverrideActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "SubjectKey",
      key: "EditAPRLvlConfg",
      align: "center",
      width: 100,
      render: APRLvlConfgEditButton,
    },
  ];
  return (
    <Card
      title={
        <span className="form-title">
          {t("aprLvlConfigForm.approval_level_settings")}
        </span>
      }
    >
      <Spin spinning={isLoading}>
        {!isLoading ? (
          <Table
            dataSource={aprLvlConfgs}
            columns={columns}
            size="middle"
            rowKey={(record) => record.SubjectKey}
            pagination={false}
            scroll={{ y: "calc(100vh - 270px)" }}
            onRow={(r) => ({ onClick: () => onRowSelected(r) })}
          />
        ) : null}
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

APRLvlConfgForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  aprLvlConfgs: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
};
