import React, { useState, useEffect } from "react";
import { Spin, Card, Button, Tabs, Row, Col, Switch, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const days = Array.from(new Array(28), (val, index) => ({ Value: index + 1 }));
const formats = { cellSpacing: 20 };

export const SettingInputs = ({
  isLoading,
  onSubmitted,
  onCancelled,
  claimConfg,
  leaveConfg,
  clinicConfg,
  travelConfg,
  announcementConfg,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  useEffect(
    () => form.resetFields(),
    [claimConfg, leaveConfg, clinicConfg, travelConfg, announcementConfg, form]
  );

  const LEAVE_TAB = "0";
  const CLAIM_TAB = "1";
  const CLINIC_TAB = "2";
  const TRAVEL_TAB = "3";
  const ANNOUNCEMENT_TAB = "4";

  const [activeTab, setActiveTab] = useState("0");
  const [enableProrateDate, setEnableProrateDate] = useState(
    claimConfg && claimConfg.IsProrated
  );

  const handleSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        onSubmitted(values);
      })
      .catch((errors) => {
        let objPropNames = Object.keys(errors);
        let firstErrField = objPropNames[0]; //form validates according to order of fields, so get first error field to determine tab to switch

        if (firstErrField.toLowerCase().includes("leave")) {
          setActiveTab(LEAVE_TAB);
        } else if (firstErrField.toLowerCase().includes("claim")) {
          setActiveTab(CLAIM_TAB);
        } else if (firstErrField.toLowerCase().includes("clinic")) {
          setActiveTab(CLINIC_TAB);
        } else if (firstErrField.toLowerCase().includes("travel")) {
          setActiveTab(TRAVEL_TAB);
        } else if (firstErrField.toLowerCase().includes("announcement")) {
          setActiveTab(ANNOUNCEMENT_TAB);
        }
      });
  };

  const items = [
    {
      label: t("general.leave"),
      key: LEAVE_TAB,
      children: <LeaveSettings leaveConfg={leaveConfg} />,
    },
    {
      label: t("general.claim"),
      key: CLAIM_TAB,
      forceRender: true,
      children: (
        <ClaimSettings
          claimConfg={claimConfg}
          enableProrateDate={enableProrateDate}
          setEnableProrateDate={setEnableProrateDate}
        />
      ),
    },
    {
      label: t("general.clinic"),
      key: CLINIC_TAB,
      forceRender: true,
      children: <ClinicSettings clinicConfg={clinicConfg} />,
    },
    {
      label: t("general.travel"),
      key: TRAVEL_TAB,
      forceRender: true,
      children: <TravelSettings travelConfg={travelConfg} />,
    },
    {
      label: t("general.announcement"),
      key: ANNOUNCEMENT_TAB,
      forceRender: true,
      children: <AnnouncementSettings announcementConfg={announcementConfg} />,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">{t("settingForm.settings")}</span>
              }
            >
              <Tabs
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={items}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  type="default"
                  size="large"
                  onClick={onCancelled}
                  style={{ marginRight: 10 }}
                >
                  {t("general.cancel")}
                </Button>
                <Button type="primary" size="large" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

//tab content
const LeaveSettings = ({ leaveConfg }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={formats.cellSpacing}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={t("settingForm.allow_half_day_leave")}
          name="LeaveAllowHalfDay"
          valuePropName="checked"
          initialValue={
            leaveConfg && leaveConfg.AllowHalfDay
              ? leaveConfg.AllowHalfDay
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={t("settingForm.validate_transaction")}
          name="LeaveIsValidateTrans"
          valuePropName="checked"
          initialValue={
            leaveConfg && leaveConfg.IsValidateTrans
              ? leaveConfg.IsValidateTrans
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={t("settingForm.post_ul_to_quarto")}
          name="LeaveIsPostULToQuarto"
          valuePropName="checked"
          initialValue={
            leaveConfg && leaveConfg.IsPostULToQuarto
              ? leaveConfg.IsPostULToQuarto
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
    </Row>
  );
};
LeaveSettings.propTypes = {
  leaveConfg: PropTypes.object.isRequired,
};

const ClaimSettings = ({
  claimConfg,
  enableProrateDate,
  setEnableProrateDate,
}) => {
  const { t } = useTranslation();
  const claimProrateDateBased = [
    { Key: "H", Desc: t("settingForm.hired_date") },
    { Key: "W", Desc: t("settingForm.first_work_date") },
  ];

  return (
    <div>
      <Row gutter={formats.cellSpacing}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.monthly_cut_off_day")}
            name="ClaimCutOffDay"
            rules={[
              {
                required: true,
                message: "Monthly Cut-off Day is required",
              },
            ]}
            initialValue={
              claimConfg && claimConfg.CutOffDay
                ? claimConfg.CutOffDay
                : undefined
            }
          >
            <SelectionInput
              ref={React.createRef()}
              data={days}
              valueProp={"Value"}
              textProp={"Value"}
              placeholder={"Select a Day"}
              textAfterInput={"of the Month"}
              style={{ width: "30%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.group_by_project")}
            name="ClaimIsGroupByProj"
            valuePropName="checked"
            initialValue={
              claimConfg && claimConfg.IsGroupByProj
                ? claimConfg.IsGroupByProj
                : undefined
            }
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.multi_currency")}
            name="ClaimIsMultiCurrency"
            valuePropName="checked"
            initialValue={
              claimConfg && claimConfg.IsMultiCurrency
                ? claimConfg.IsMultiCurrency
                : undefined
            }
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.validate_transaction")}
            name="ClaimIsValidateTrans"
            valuePropName="checked"
            initialValue={
              claimConfg && claimConfg.IsValidateTrans
                ? claimConfg.IsValidateTrans
                : undefined
            }
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={formats.cellSpacing}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.post_to_quarto")}
            name="ClaimIsPostToQuarto"
            valuePropName="checked"
            initialValue={
              claimConfg && claimConfg.IsPostToQuarto
                ? claimConfg.IsPostToQuarto
                : undefined
            }
          >
            <Switch checkedChildren="Yes" unCheckedChildren="No" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.is_prorated")}
            name="ClaimIsProrated"
            valuePropName="checked"
            initialValue={
              claimConfg && claimConfg.IsProrated !== undefined
                ? claimConfg.IsProrated
                : true
            }
          >
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(value) => setEnableProrateDate(value)}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Form.Item
            label={t("settingForm.prorate_date_based")}
            name="ClaimProrateDateBased"
            rules={[
              {
                required: true,
                message: "Date which prorate based on is required",
              },
            ]}
            initialValue={
              claimConfg && claimConfg.ProrateDateBased
                ? claimConfg.ProrateDateBased
                : claimProrateDateBased[1].Key
            }
          >
            <SelectionInput
              ref={React.createRef()}
              data={claimProrateDateBased}
              valueProp={"Key"}
              textProp={"Desc"}
              disabled={!enableProrateDate}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};
ClaimSettings.propTypes = {
  claimConfg: PropTypes.object.isRequired,
};

const ClinicSettings = ({ clinicConfg }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={formats.cellSpacing}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={t("settingForm.validate_transaction")}
          name="ClinicIsValidateTrans"
          valuePropName="checked"
          initialValue={
            clinicConfg && clinicConfg.IsValidateTrans
              ? clinicConfg.IsValidateTrans
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
    </Row>
  );
};
ClinicSettings.propTypes = {
  clinicConfg: PropTypes.object.isRequired,
};

const TravelSettings = ({ travelConfg }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={formats.cellSpacing}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={t("settingForm.allow_over_claim")}
          name="IsAllowOverClaim"
          valuePropName="checked"
          initialValue={
            travelConfg && travelConfg.IsAllowOverClaim
              ? travelConfg.IsAllowOverClaim
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <Form.Item
          label={t("settingForm.validate_transaction")}
          name="TravelIsValidateTrans"
          valuePropName="checked"
          initialValue={
            travelConfg && travelConfg.IsValidateTrans
              ? travelConfg.IsValidateTrans
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
    </Row>
  );
};
TravelSettings.propTypes = {
  travelConfg: PropTypes.object.isRequired,
};

const AnnouncementSettings = ({ announcementConfg }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={formats.cellSpacing}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Form.Item
          label={t("settingForm.email_notification")}
          name="AnnouncementEmailNotification"
          valuePropName="checked"
          initialValue={
            announcementConfg && announcementConfg.EmailNotification
              ? announcementConfg.EmailNotification
              : undefined
          }
        >
          <Switch checkedChildren="Yes" unCheckedChildren="No" />
        </Form.Item>
      </Col>
    </Row>
  );
};
AnnouncementSettings.propTypes = {
  announcementConfg: PropTypes.object.isRequired,
};

SettingInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  claimConfg: PropTypes.object.isRequired,
  leaveConfg: PropTypes.object.isRequired,
  clinicConfg: PropTypes.object.isRequired,
  travelConfg: PropTypes.object.isRequired,
  announcementConfg: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const SettingsForm = SettingInputs;
