import React, { useState, useEffect } from "react";
import ForgotPasswordForm from "../forms/ForgotPasswordForm";
import { getESSLogo } from "../../api/userApi";
import { toast } from "react-toastify";

export default function ForgotPasswordPage() {
  const [essLogo, setESSLogo] = useState("");

  useEffect(() => {
    let promises = [getESSLogo()];

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          setESSLogo(values[0]);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, []);

  return <ForgotPasswordForm essLogo={essLogo} />;
}
