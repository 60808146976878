import React from "react";
import PropTypes from "prop-types";
import { Modal, Input, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function EmpyEduModal({
  handleSave,
  handleCancel,
  isVisible,
  setIsDirty,
  form,
  education,
}) {
  const { t } = useTranslation();
  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let educationSubmit = {
          ...education,
          ...values,
        };

        handleSave(educationSubmit);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={
        <span className="form-subtitle">{t("employeeForm.education")}</span>
      }
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Form.Item
          label={t("employeeForm.education")}
          name={"Education"}
          rules={[
            {
              required: true,
              message: t("employeeForm.education_required_validation"),
            },
            {
              max: 200,
              message: t("employeeForm.education_max_length_validation"),
            },
          ]}
          initialValue={education.Education}
        >
          <Input
            type="text"
            placeholder={t("employeeForm.education_placeholder")}
          />
        </Form.Item>

        <Form.Item
          label={t("general.year")}
          name={"Year"}
          rules={[
            {
              required: true,
              message: t("general.year_required_validation_2"),
            },
          ]}
          initialValue={dayjs().year(education.Year)}
        >
          <DatePicker picker="year" />
        </Form.Item>

        <Form.Item
          label={t("general.remarks")}
          name={"Remarks"}
          rules={[
            {
              required: true,
              message: t("general.remarks_required_validation"),
            },
            {
              max: 250,
              message: t("general.remarks_max_length_validation"),
            },
          ]}
          initialValue={education.Remarks}
        >
          <Input type="text" placeholder={t("general.remarks_placeholder")} />
        </Form.Item>
      </Form>
    </Modal>
  );
}

EmpyEduModal.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  education: PropTypes.object.isRequired,
};

export default EmpyEduModal;
