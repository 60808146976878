import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function user(state = initialState.users, action = {}) {
  switch (action.type) {
    case types.USER_LOGGED_IN:
      return action.user;
    default:
      return state;
  }
}
