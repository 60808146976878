import React, { useState } from "react";
import {
  Input,
  InputNumber,
  Row,
  Col,
  Button,
  Switch,
  Card,
  Spin,
  Divider,
  Tree,
  Tabs,
  Form,
  Select,
} from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import {
  inputLocaleFormatter,
  inputLocaleParser,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;
const { Option } = Select;

const ManageClinicForm = ({
  isLoading,
  clinic,
  onSubmitted,
  onCancelled,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const [activeTab, setActiveTab] = useState("0");
  const disableField = clinic.RcdType === RecordType.USER ? false : true;
  const company = clinic.Company ? clinic.Company : [];
  let checkedOUList =
    clinic.checkedOUList.length > 0 ? clinic.checkedOUList : [];

  function generateNextRunningNum({
    prefix = form.getFieldValue("Prefix").trim(),
    numLength = form.getFieldValue("NumLength"),
    nextNum = form.getFieldValue("NextNum"),
  }) {
    if (prefix.length <= 10 && numLength <= 15 && nextNum <= 999999999999999) {
      let tempNum = formatNumberLength(nextNum, numLength);
      let tempRunningNum = prefix + tempNum;
      form.setFieldsValue({ NextRunningNum: tempRunningNum });
      onDataChanged({ ...clinic, NextRunningNum: tempRunningNum });
    }
  }

  function formatNumberLength(num, length) {
    var r = "" + num;
    while (r.length < length) {
      r = "0" + r;
    }
    return r;
  }

  const renderTreeNodes = (data) =>
    data.length === 0
      ? []
      : data.map((item) => {
          if (item.SelectionDetData) {
            return {
              title: item.CodeDesc,
              key: item.Code,
              children: renderTreeNodes(item.SelectionDetData),
            };
          }
          return {
            title: item.CodeDesc,
            key: item.Key,
          };
        });

  const onCheck = (checkedKeys) => {
    checkedOUList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedOUList.push(checkedKeys[index]);
      }
    }
    setIsDirty(true);
    onDataChanged({ ...clinic, checkedOUList: checkedOUList });
  };

  const handleSubmit = async () => {
    form
      .validateFields()
      .then((values) => {
        values["checkedOUList"] = checkedOUList;
        onSubmitted(values);
      })
      .catch(() => {});
  };

  const handleSubmitFailed = () => {
    setActiveTab("0");
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("clinicsForm.clinic_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={clinic.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={[
                  {
                    key: "0",
                    label: t("general.general"),
                    children: (
                      <>
                        <Row
                          gutter={formats.cellSpacing}
                          style={{
                            marginTop: 16,
                          }}
                        >
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("clinicsForm.clinic_code")}
                              name="ClinicCode"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "clinicsForm.clinic_code_required_validation"
                                  ),
                                },
                                {
                                  max: 100,
                                  message: t(
                                    "clinicsForm.clinic_code_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.ClinicCode}
                            >
                              <Input
                                placeholder={t(
                                  "clinicsForm.clinic_code_placeholder"
                                )}
                                disabled={disableField}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("clinicsForm.clinic_name")}
                              name="ClinicName"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "clinicsForm.clinic_name_required_validation"
                                  ),
                                },
                                {
                                  max: 250,
                                  message: t(
                                    "clinicsForm.clinic_name_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.ClinicName}
                            >
                              <Input
                                placeholder={t(
                                  "clinicsForm.clinic_name_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("general.description")}
                              name="ClinicDesc"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "general.description_required_validation"
                                  ),
                                },
                                {
                                  max: 250,
                                  message: t(
                                    "general.description_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.ClinicDesc}
                            >
                              <Input
                                placeholder={t(
                                  "general.description_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("general.type")}
                              name="ClinicType"
                              initialValue={clinic.ClinicType}
                            >
                              <Select
                                getPopupContainer={() =>
                                  document.getElementById("scroll-container")
                                }
                              >
                                <Option value={0}>
                                  {t("clinicsForm.medical_clinic")}
                                </Option>
                                <Option value={1}>
                                  {t("clinicsForm.dental_clinic")}
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t("clinicsForm.clinic_tel_no")}
                              name="ClinicTel"
                              rules={[
                                {
                                  max: 20,
                                  message: t(
                                    "clinicsForm.clinic_tel_no_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.ClinicTel}
                            >
                              <Input
                                placeholder={t(
                                  "clinicsForm.clinic_tel_no_placeholder"
                                )}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={24}>
                            <Form.Item
                              label={t("clinicsForm.clinic_address")}
                              name="ClinicAddress"
                              rules={[
                                {
                                  max: 500,
                                  message: t(
                                    "clinicsForm.clinic_address_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.ClinicAddress}
                            >
                              <TextArea
                                placeholder={t(
                                  "clinicsForm.clinic_address_placeholder"
                                )}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Divider
                          orientation="left"
                          style={{ color: "#0070cc" }}
                        >
                          {t("clinicsForm.auto_running_number")}
                        </Divider>
                        <Row gutter={formats.cellSpacing}>
                          <Col xs={24} lg={8}>
                            <Form.Item
                              label={t("clinicsForm.prefix")}
                              name="Prefix"
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message: t(
                                    "clinicsForm.prefix_required_validation"
                                  ),
                                },
                                {
                                  max: 10,
                                  message: t(
                                    "clinicsForm.prefix_max_length_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.Prefix}
                            >
                              <Input
                                placeholder={t(
                                  "clinicsForm.prefix_placeholder"
                                )}
                                onChange={(e) =>
                                  generateNextRunningNum({
                                    prefix: e.target.value.trim(),
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={4}>
                            <Form.Item
                              label={t("clinicsForm.length")}
                              name="NumLength"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "clinicsForm.length_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.NumLength}
                            >
                              <InputNumber
                                step="1"
                                precision={0}
                                min={0}
                                max={15}
                                formatter={inputLocaleFormatter(0)}
                                parser={inputLocaleParser(0)}
                                onChange={(e) =>
                                  generateNextRunningNum({
                                    numLength: e,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={4}>
                            <Form.Item
                              label={t("clinicsForm.next_no")}
                              name="NextNum"
                              rules={[
                                {
                                  required: true,
                                  message: t(
                                    "clinicsForm.next_no_required_validation"
                                  ),
                                },
                              ]}
                              initialValue={clinic.NextNum}
                            >
                              <InputNumber
                                step="1"
                                precision={0}
                                min={0}
                                max={999999999999999}
                                formatter={inputLocaleFormatter(0)}
                                parser={inputLocaleParser(0)}
                                onChange={(e) =>
                                  generateNextRunningNum({
                                    nextNum: e,
                                  })
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={8}>
                            <Form.Item
                              label={t("clinicsForm.next_running_no")}
                              name="NextRunningNum"
                              initialValue={clinic.NextRunningNum}
                            >
                              <Input
                                placeholder={t(
                                  "clinicsForm.next_running_no_placeholder"
                                )}
                                disabled={true}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    ),
                  },
                  {
                    key: "1",
                    label: t("general.permission"),
                    children: (
                      <Tree
                        checkable
                        defaultExpandAll={true}
                        defaultCheckedKeys={
                          clinic.checkedOUList.length > 0
                            ? clinic.checkedOUList
                            : []
                        }
                        onCheck={onCheck}
                        treeData={renderTreeNodes(company)}
                      ></Tree>
                    ),
                  },
                ]}
              ></Tabs>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={clinic}
              showFooter={clinic.ClinicKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageClinicForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clinic: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default ManageClinicForm;
