import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getEmpyInfo,
  getOU,
  getESSEmployee,
  getEmployeeUnderApproverTrainings,
  getTrainingYear,
  getDepartment,
} from "../../api/reportApi";
import { getAllTrainings } from "../../api/trainingApi";
import { Status } from "../model/common/Status";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col, Select } from "antd";
import "../../styles/report.scss";
import * as UserType from "../../redux/actions/actionTypes";
import { SelectionInput } from "../common/SelectionInput";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../api/companyApi";
import {
  TreeSelectionInput,
  getAllDsOption,
} from "../common/TreeSelectionInput";

const { Option } = Select;

export default function TrainingParticipantsForm() {
  const { t } = useTranslation();
  const REPORT_NAME = "Training Participants Report";

  const [year, setYear] = useState();
  const [statusLocal, setStatus] = useState([]);
  const [userRoleType, setUserRoleType] = useState(UserType.USER_ROLE_USER);
  const [empyInfo, setEmpyInfo] = useState({ EmpyKey: null });
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [dsStatusOptions, setStatusOptions] = useState([]);
  const [dsOU, setOUOptions] = useState([]);
  const [dsEmpy, setEmpyOptions] = useState([]);
  const [dsDept, setDeptOptions] = useState([]);
  const [dsTraining, setTrainingOptions] = useState([]);
  const [dsYear, setYearOptions] = useState([]);
  const [dsComp, setCompOptions] = useState([]);
  const [initialOU, setInitialOU] = useState([]);
  const [initialEmpy, setInitialEmpy] = useState([]);
  const [initialApprovalEmpyKey, setInitialApprEmpyKey] = useState([]);
  const [initialTraining, setInitialTraining] = useState([]);
  const [initialDept, setInitialDept] = useState([]);
  const [initialYear, setInitialYear] = useState([]);
  const [selectedOU, setSelectedOU] = useState([]);
  const [selectedDept, setSelectedDept] = useState([]);
  const [selectedEmpy, setSelectedEmpy] = useState([]);
  const [selectedTraining, setSelectedTraining] = useState([]);
  const [selectedComp, setSelectedComp] = useState();
  const [isReportGenerating, setReportGenerating] = useState(false);

  const onSingleCompanyChange = (value) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value > -1) {
      setSelectedComp(value);
      const tempOU = Object.assign(
        [],
        initialOU.filter((x) => x.compKey === value)
      );
      setOUOptions(mapData(tempOU));
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setSelectedComp();
      setOUOptions([]);
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleOUChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempDept = Object.assign([], initialDept);
      let tempTraining = Object.assign([], initialTraining);
      let tempYear = Object.assign([], initialYear);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedOU(getAllDsOption(dsOU, true));
          tempDept = Object.assign(
            [],
            initialDept.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
          tempTraining = Object.assign(
            [],
            initialTraining.filter((x) =>
              dsOU.map((y) => y.value).some(item => x.checkedOUList.includes(item))
            )
          );
          tempYear = Object.assign(
            [],
            initialYear.filter((x) =>
              dsOU.map((y) => y.value).includes(x.OUKey)
            )
          );
        } else {
          setSelectedOU([]);
          tempDept = [];
          tempTraining = [];
          tempYear = [];
        }
      } else {
        setSelectedOU(
          value.filter((x) => x !== -1).length === dsOU.length
            ? getAllDsOption(dsOU, true)
            : value.filter((x) => x !== -1)
        );

        tempDept = Object.assign(
          [],
          initialDept.filter((x) => value.includes(x.OUKey))
        );
        tempTraining = Object.assign(
          [],
          initialTraining.filter((x) => value.some(item => x.checkedOUList.includes(item)))
        );
        tempYear = Object.assign(
          [],
          initialYear.filter((x) => value.includes(x.OUKey))
        );
      }

      let yrResult = [];
      let mapYr = new Map();
      for (const item of tempYear) {
        if (!mapYr.has(item.Year) && (userRoleType !== UserType.USER_ROLE_USER || (userRoleType === UserType.USER_ROLE_USER && item.OUKey === empyInfo.OUKey))) {
          mapYr.set(item.Year, true);
          yrResult.push({
            text: item.Year,
            value: item.Year,
          });
        }
      }

      setYearOptions(yrResult);
      let latestYear;
      if (yrResult.length > 0) {
        latestYear = yrResult[0].value;
        setYear(latestYear);
      }

      let trainingArr = [];
      let filteredTrainings = tempTraining.filter(
        (training) => training.TrainingYear === latestYear
      );
      filteredTrainings.forEach((element) => {
        trainingArr.push({
          text: element.Title,
          value: element.TrainingKey,
          Year: element.TrainingYear,
        });
      });

      setTrainingOptions(trainingArr);
      setSelectedTraining([]);

      setDeptOptions(mapData(tempDept));
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    } else {
      setYearOptions([]);
      setYear();
      setTrainingOptions([]);
      setSelectedTraining([]);
      setSelectedOU([]);
      setDeptOptions([]);
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleDeptChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      let tempEmpy = Object.assign([], initialEmpy);

      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedDept(getAllDsOption(dsDept, true));
          tempEmpy = Object.assign(
            [],
            initialEmpy.filter(
              (x) =>
                selectedOU.includes(x.OUKey) &&
                dsDept.map((y) => y.value).includes(x.DeptKey)
            )
          );
        } else {
          setSelectedDept([]);
          tempEmpy = [];
        }
      } else {
        setSelectedDept(
          value.filter((x) => x !== -1).length === dsDept.length
            ? getAllDsOption(dsDept, true)
            : value.filter((x) => x !== -1)
        );

        tempEmpy = Object.assign(
          [],
          initialEmpy.filter(
            (x) => selectedOU.includes(x.OUKey) && value.includes(x.DeptKey)
          )
        );
      }

      setEmpyOptions(mapData(tempEmpy));
      setSelectedEmpy([]);
    } else {
      setSelectedDept([]);
      setEmpyOptions([]);
      setSelectedEmpy([]);
    }
  };

  const onMultipleEmpyChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedEmpy(getAllDsOption(dsEmpy, true));
        } else {
          setSelectedEmpy([]);
        }
      } else {
        setSelectedEmpy(
          value.filter((x) => x !== -1).length === dsEmpy.length
            ? getAllDsOption(dsEmpy, true)
            : value.filter((x) => x !== -1)
        );
      }
    } else {
      setSelectedEmpy([]);
    }
  };

  const onYrChange = (value) => {
    setYear(value);
    setSelectedTraining([]);

    if (value) {
      let trainingArr = [];
      let filteredTrainings = initialTraining.filter(
        (training) => training.TrainingYear === value && selectedOU.some(item => training.checkedOUList.includes(item))
      );
      filteredTrainings.forEach((element) => {
        trainingArr.push({
          text: element.Title,
          value: element.TrainingKey,
          Year: element.TrainingYear,
        });
      });
      setTrainingOptions(trainingArr);
    }

    if (isReportGenerate === true) {
      setReportGenerate(false);
    }
  };

  const onStatusChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setStatus(getAllDsOption(dsStatusOptions, true));
        } else {
          setStatus([]);
        }
      } else {
        if (dsStatusOptions.length > 0) {
          setStatus(
            value.filter((x) => x !== -1).length === dsStatusOptions.length
              ? getAllDsOption(dsStatusOptions, true)
              : value.filter((x) => x !== -1)
          );
        }
      }
    } else {
      setStatus([]);
    }
  };

  const onTrainingChange = (value, label, extra) => {
    if (isReportGenerate === true) {
      setReportGenerate(false);
    }

    if (value.length > 0) {
      if (extra.triggerValue === -1) {
        if (value.includes(-1)) {
          setSelectedTraining(getAllDsOption(dsTraining, true));
        } else {
          setSelectedTraining([]);
        }
      } else {
        if (dsTraining.length > 0) {
          setSelectedTraining(
            value.filter((x) => x !== -1).length === dsTraining.length
              ? getAllDsOption(dsTraining, true)
              : value.filter((x) => x !== -1)
          );
        } else {
          setSelectedTraining(value.filter((x) => x !== -1));
        }
      }
    } else {
      setSelectedTraining([]);
    }
  };

  const generateReport = (e) => {
    e.preventDefault();
    setReportGenerate(true);
    setReportGenerating(true);
    setReportName(
      REPORT_NAME +
      "#" +
      empyInfo.ClientID +
      "#" +
      empyInfo.ClientKey +
      "#" +
      empyInfo.UserKey +
      "#" +
      empyInfo.LangKey
    );
    var tempEmpyList = [];
    dsEmpy.forEach((x) =>
      initialApprovalEmpyKey.includes(x.value) ? tempEmpyList.push(x.value) : {}
    );

    setReportParam({
      CompKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.CompKey
          : selectedComp,
      OUKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.OUKey
          : selectedOU.includes(-1)
            ? [null]
            : selectedOU,
      Status: statusLocal.includes(-1) ? [null] : statusLocal,
      Year: year,
      DeptKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.DeptKey
          : selectedDept.includes(-1)
            ? [null]
            : selectedDept,
      EmpyKey:
        userRoleType === UserType.USER_ROLE_USER
          ? empyInfo.EmpyKey
          : userRoleType === UserType.USER_ROLE_APPROVER
            ? selectedEmpy.includes(-1)
              ? tempEmpyList
              : selectedEmpy.includes(-1)
                ? [null]
                : selectedEmpy
            : selectedEmpy.includes(-1)
              ? [null]
              : selectedEmpy,
      TrainingKey: selectedTraining.includes(-1) ? [null] : selectedTraining,
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
    });
  };

  const validate = (
    year,
    status,
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedTraining,
    selectedComp
  ) => {
    var valid = false;
    if (
      year > 0 &&
      status.length > 0 &&
      selectedOU.length > 0 &&
      selectedEmpy.length > 0 &&
      selectedDept.length > 0 &&
      selectedTraining.length > 0 &&
      selectedComp !== undefined
    ) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(
      year,
      statusLocal,
      selectedOU,
      selectedEmpy,
      selectedDept,
      selectedTraining,
      selectedComp
    );
  }, [
    year,
    statusLocal,
    selectedOU,
    selectedEmpy,
    selectedDept,
    selectedTraining,
    selectedComp,
  ]);

  useEffect(() => {
    let { UserRoleType } = JSON.parse(sessionStorage.getItem("auth"));
    let promises = [
      getEmpyInfo(),
      getOU(),
      getEmployeeUnderApproverTrainings(),
      getESSEmployee(),
      getDepartment(),
      getTrainingYear(),
      getAllTrainings(),
      getCompany(),
    ];
    const statusArr = [];
    for (var st in Status) {
      // eslint-disable-next-line no-prototype-builtins
      if (Status.hasOwnProperty(st)) {
        if (st >= 1 && st <= 6) {
          statusArr.push({
            text: Status[st],
            value: st,
          });
        }
      }
    }
    setStatusOptions(statusArr);
    setStatus(["1", "4", "5"]);
    setUserRoleType(UserRoleType);
    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setEmpyInfo(data[0][0]);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setSelectedComp(data[0][0].CompKey);
            setSelectedEmpy([data[0][0].EmpyKey]);
            setSelectedOU([data[0][0].OUKey]);
            setSelectedDept([data[0][0].DeptKey]);
          }
        }

        if (data[1] && data[1].length > 0) {
          const OUArr = [];
          data[1].forEach((element) => {
            OUArr.push({
              text: element.OUCode + " - " + element.OUDesc,
              value: element.OUKey,
              compKey: element.CompKey,
            });
          });
          setInitialOU(OUArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setOUOptions(
              OUArr.filter(
                (x) =>
                  x.value === data[0][0].OUKey &&
                  x.compKey === data[0][0].CompKey
              )
            );
          }
        }

        if (data[3] && data[3].length > 0) {
          const EmpyArr = [];
          let filteredEmpy = data[3];
          if (UserRoleType === UserType.USER_ROLE_APPROVER) {
            if (data[2] && data[2].length > 0) {
              setInitialApprEmpyKey(data[2]);
              filteredEmpy = data[3].filter((x) => data[2].includes(x.EmpyKey));
            }
          }

          let addedEmpy = [];
          filteredEmpy.forEach((element) => {
            if (!addedEmpy.includes(element.EmpyKey)) {
              EmpyArr.push({
                text: element.EmpyID + " - " + element.EmpyName,
                value: element.EmpyKey,
                OUKey: element.OUKey,
                DeptKey: element.DeptKey,
              });

              addedEmpy.push(element.EmpyKey);
            }
          });

          setInitialEmpy(EmpyArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setEmpyOptions(
              EmpyArr.filter(
                (x) =>
                  x.value === data[0][0].EmpyKey &&
                  x.OUKey === data[0][0].OUKey &&
                  x.DeptKey === data[0][0].DeptKey
              )
            );
          }
        }

        if (data[4] && data[4].length > 0) {
          const DeptArr = [];
          data[4].forEach((element) => {
            DeptArr.push({
              text: element.DeptCode + " - " + element.DeptDesc,
              value: element.DeptKey,
              OUKey: element.OUKey,
            });
          });
          setInitialDept(DeptArr);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            setDeptOptions(
              DeptArr.filter(
                (x) =>
                  x.value === data[0][0].DeptKey && x.OUKey === data[0][0].OUKey
              )
            );
          }
        }

        let latestYear = -1;
        if (data[5] && data[5].length > 0) {
          const yrResult = [];
          const mapYr = new Map();

          setInitialYear(data[5]);
          if (UserRoleType === UserType.USER_ROLE_USER) {
            //check if EmpyInfo is back , filter only the one empy
            if (data[0] && data[0].length > 0) {
              for (const item of data[5]) {
                if (item.OUKey === data[0][0].OUKey && !mapYr.has(item.Year)) {
                  mapYr.set(item.Year, true);
                  yrResult.push({
                    text: item.Year,
                    value: item.Year,
                  });
                }
              }
            }
          } else {
            for (const item of data[5]) {
              if (!mapYr.has(item.Year)) {
                mapYr.set(item.Year, true);
                yrResult.push({
                  text: item.Year,
                  value: item.Year,
                });
              }
            }
          }

          setYearOptions(yrResult);
          if (yrResult.length > 0) {
            latestYear = yrResult[0].value;
            setYear(latestYear);
          }
        }

        if (data[6] && data[6].length > 0) {
          const trainingArr = [];
          setInitialTraining(data[6]);

          let filteredTrainings = data[6].filter(
            (training) =>
              latestYear === -1 || training.TrainingYear === latestYear
          );
          filteredTrainings.forEach((element) => {
            trainingArr.push({
              text: element.Title,
              value: element.TrainingKey,
              Year: element.TrainingYear,
            });
          });
          setTrainingOptions(trainingArr);
        }

        if (data[7] && data[7].length > 0) {
          const CompArr = [];
          data[7].forEach((element) => {
            CompArr.push({
              text: element.CodeDesc,
              value: element.Key,
            });
          });
          setCompOptions(CompArr);
        }
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  function mapData(data) {
    const mapResult = [];
    const newMap = new Map();
    for (const item of data) {
      if (!newMap.has(item.value)) {
        newMap.set(item.value, true);
        mapResult.push({
          text: item.text,
          value: item.value,
        });
      }
    }
    return mapResult;
  }

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.company")}</label>
          <br />
          <Select
            mode="single"
            style={{
              width: 240,
              maxHeight: 100,
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            placeholder={t("general.company_placeholder")}
            onChange={onSingleCompanyChange}
            optionLabelProp="label"
            value={selectedComp}
            filterOption={(input, option) =>
              option.props.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            disabled={userRoleType === UserType.USER_ROLE_USER}
          >
            {dsComp.map((element) => (
              <Option
                key={element.value}
                value={element.value}
                label={element.text}
              >
                <div>{element.text}</div>
              </Option>
            ))}
          </Select>
          <br />
          <label>{t("general.operating_unit")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.operating_unit_placeholder_full")}
            fncOnChange={onMultipleOUChange}
            value={selectedOU}
            dsOption={dsOU}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.department")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.department_placeholder")}
            fncOnChange={onMultipleDeptChange}
            value={selectedDept}
            dsOption={dsDept}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.employee")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.employee_placeholder")}
            fncOnChange={onMultipleEmpyChange}
            value={selectedEmpy}
            dsOption={dsEmpy}
            isIncludeAllOption={true}
            disabled={userRoleType === UserType.USER_ROLE_USER}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.year")}</label>
          <br />
          <SelectionInput
            value={year}
            ref={React.createRef()}
            data={dsYear}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.year_placeholder_2")}
            onChange={onYrChange}
            style={{ width: 240 }}
          />
          <label>{t("trainingApplicationForm.training_program")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t(
              "trainingApplicationForm.training_program_placeholder"
            )}
            fncOnChange={onTrainingChange}
            value={selectedTraining}
            dsOption={dsTraining}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <label>{t("general.status")}</label>
          <br />
          <TreeSelectionInput
            allowClear={true}
            placeholder={t("general.status_placeholder")}
            fncOnChange={onStatusChange}
            value={statusLocal}
            dsOption={dsStatusOptions}
            isIncludeAllOption={true}
          ></TreeSelectionInput>
          <br />
          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
