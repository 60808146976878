import React, { forwardRef } from "react";
import { Select } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

//forwardRef is required because this component will be used as child component
export const SelectionInput = forwardRef(
  (
    {
      data,
      valueProp,
      textProp,
      disabledProp = "",
      placeholder = "",
      isIncludeAllOption = false,
      labelInValue = false,
      isMultiple = false,
      textBeforeInput = "",
      textAfterInput = "",
      fncOnSelect = null,
      allowClear = false,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        {textBeforeInput && (
          <span style={{ marginRight: 10 }}>{textBeforeInput}</span>
        )}
        <Select
          getPopupContainer={() => document.getElementById("scroll-container")}
          ref={ref}
          showSearch
          placeholder={placeholder}
          labelInValue={labelInValue}
          optionFilterProp="children"
          mode={isMultiple ? "multiple" : ""}
          allowClear={allowClear}
          filterOption={(input, option) =>
            option.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onSelect={fncOnSelect}
          {...props}
        >
          {generateSelectionOption(
            data,
            valueProp,
            textProp,
            disabledProp,
            isIncludeAllOption
          )}
        </Select>
        {textAfterInput && (
          <span style={{ marginLeft: 10 }}>{textAfterInput}</span>
        )}
      </div>
    );
  }
);

function generateSelectionOption(
  selectionData,
  valueProp,
  textProp,
  disabledProp,
  isIncludeAllOption
) {
  let dataOptions = [];
  if (selectionData && selectionData.length) {
    dataOptions = selectionData.map((data) => (
      <Option
        key={data[valueProp]}
        value={data[valueProp]}
        disabled={
          data[disabledProp] !== undefined ? !data[disabledProp] : false
        }
      >
        {data[textProp] ? data[textProp] : ""}
      </Option>
    ));
  }

  if (isIncludeAllOption) {
    dataOptions.unshift(
      <Option key={0} value={0} label={"ALL"}>
        {"All"}
      </Option>
    );
  }

  return dataOptions;
}

SelectionInput.displayName = "SelectionInput";

SelectionInput.propTypes = {
  data: PropTypes.array.isRequired,
  valueProp: PropTypes.string.isRequired,
  textProp: PropTypes.string.isRequired,
  disabledProp: PropTypes.string,
  placeholder: PropTypes.string,
  isIncludeAllOption: PropTypes.bool,
  labelInValue: PropTypes.bool,
  isMultiple: PropTypes.bool,
  allowClear: PropTypes.bool,
  textBeforeInput: PropTypes.string,
  textAfterInput: PropTypes.string,
  fncOnSelect: PropTypes.func,
};
