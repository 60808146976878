import React from "react";
import { Input, Row, Col, Button, Card, Spin, Tree, Form, Modal } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { confirm } = Modal;

const ManageRoleTypesPermissionByRole = ({
  isLoading,
  roleTypesPermissionByRole,
  onSubmitted,
  onCancelled,
  form,
  setIsDirty,
  roleName,
  roleKey,
  roleType,
  onDataChanged,
  // eslint-disable-next-line react/prop-types
  menu,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const formPerm = menu ? menu : [];

  let checkedKeyList = roleTypesPermissionByRole
    ? roleTypesPermissionByRole
    : [];

  const renderTreeNodes = (data) =>
    data.length === 0
      ? []
      : data.map((item) => {
          if (item.children) {
            return {
              title: item.title,
              key: item.key,
              children: renderTreeNodes(item.children),
            };
          }
          return {
            title: item.title,
            key: item.key,
            disableCheckbox: item.key === 1,
          };
        });

  const onCheck = (checkedKeys, halfCheckedKeys) => {
    checkedKeyList = [];
    for (
      let index = 0;
      index < halfCheckedKeys.halfCheckedKeys.length;
      ++index
    ) {
      if (/^\d+$/.test(halfCheckedKeys.halfCheckedKeys[index]) === true) {
        checkedKeyList.push(halfCheckedKeys.halfCheckedKeys[index]);
      }
    }
    for (let index = 0; index < checkedKeys.length; ++index) {
      if (/^\d+$/.test(checkedKeys[index]) === true) {
        checkedKeyList.push(checkedKeys[index]);
      }
    }
    setIsDirty(true);
    onDataChanged({
      checkedKeyList,
    });
  };

  const showSaveConfirm = async () => {
    form.validateFields().then((values) => {
      values["checkedMenuList"] = checkedKeyList;
      values["RoleTypeKey"] = roleKey;

      //only admin can access to admin portal forms
      if (roleType === 1) {
        confirm({
          title: t("general.save_confirmation"),
          content: t("roleAssignment.delete_notif_confg_message"),
          okText: t("general.yes"),
          cancelText: t("general.no"),
          onOk() {
            onSubmitted(values);
          },
          onCancel() {},
        });
      } else {
        onSubmitted(values);
      }
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout="vertical"
        onFinish={showSaveConfirm}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("roleTypesPermissionForm.role_types_permission_by_role")}
                </span>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("roleTypesPermissionForm.role_name")}
                    name="roleName"
                    initialValue={roleName}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>

              {formPerm.length > 0 ? (
                <Tree
                  checkable
                  defaultExpandAll={true}
                  defaultCheckedKeys={
                    checkedKeyList.length > 0 ? checkedKeyList : []
                  }
                  onCheck={onCheck}
                  treeData={renderTreeNodes(formPerm)}
                ></Tree>
              ) : null}
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ManageRoleTypesPermissionByRole.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roleTypesPermissionByRole: PropTypes.array.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  roleName: PropTypes.string.isRequired,
  roleKey: PropTypes.string.isRequired,
  roleType: PropTypes.number.isRequired,
  onDataChanged: PropTypes.func.isRequired,
};

export default ManageRoleTypesPermissionByRole;
