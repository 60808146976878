import React, { useEffect } from "react";
import { Input, Row, Col, Button, Card, Spin, Upload, Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const AnnouncementDisplayForm = ({
  isLoading,
  announcement,
  onCancelled,
  fileList,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const formats = { cellSpacing: 20 };

  useEffect(() => form.resetFields(), [announcement, form]);

  function uploadProp() {
    return {
      showUploadList: {
        showPreviewIcon: true,
      },
      fileList,
    };
  }

  return (
    <Spin spinning={isLoading}>
      <Form form={form} layout="vertical">
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("announcementWidget.announcement")}
                </span>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.title")}
                    name="AnnouncementTitle"
                    rules={[
                      {
                        whitespace: true,
                        message: t("general.title_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.title_max_length_validation"),
                      },
                    ]}
                    initialValue={announcement.AnnouncementTitle}
                  >
                    <Input
                      placeholder={t("general.title_placeholder")}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.description")}
                    name="AnnouncementDesc"
                    rules={[
                      {
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                    ]}
                    initialValue={announcement.AnnouncementDesc}
                  >
                    <TextArea
                      placeholder={t("general.description_placeholder")}
                      autoSize={{ minRows: 2, maxRows: 10 }}
                      disabled={true}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.attachment")}
                    name="Attachment"
                    rules={[{ required: false }]}
                  >
                    <Upload
                      {...uploadProp()}
                      listType="picture"
                      disabled={true}
                    ></Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col style={{ textAlign: "right", padding: "10px" }}>
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.back")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

AnnouncementDisplayForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  announcement: PropTypes.object.isRequired,
  onCancelled: PropTypes.func.isRequired,
  fileList: PropTypes.array.isRequired,
};

export default AnnouncementDisplayForm;
