import React, { useEffect, useState } from "react";
import { Modal, Row, Col, Switch, Input, Button, Form, Tree } from "antd";
import { StatusesBySubject, StatusByCode } from "../../model/common/Status";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const NotifSubjectForm = ({
  isVisible,
  selectedNotifConfg,
  onCancelled,
  onSaved,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const [checkedStatusList, setCheckedStatusList] = useState([]);

  useEffect(() => {
    const statuses = StatusesBySubject[selectedNotifConfg.SubjectKey] || [];
    const checkedStatuses = Object.keys(selectedNotifConfg).filter(
      (key) => selectedNotifConfg[key] === true && statuses.includes(key)
    );
    setCheckedStatusList(checkedStatuses);
  }, [selectedNotifConfg]);

  const renderTreeNodes = () => {
    const statuses = StatusesBySubject[selectedNotifConfg.SubjectKey] || [];
    const treeNodes = statuses.map((status) => ({
      key: status,
      title: StatusByCode[status] || "",
    }));

    return treeNodes;
  };

  const onCheck = (checkedKeys) => {
    let checkedStatusList = [];
    for (let index = 0; index < checkedKeys.length; ++index) {
      checkedStatusList.push(checkedKeys[index]);
    }
    setCheckedStatusList(checkedStatusList);
    setIsDirty(true);
  };

  function saveForm() {
    form.validateFields().then((values) => {
      values["checkedStatusList"] = checkedStatusList;
      values["SubjectKey"] = selectedNotifConfg.SubjectKey;
      onSaved(values);
    });
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("notifConfigForm.subject_notification")}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={onCancelled}>
          {t("general.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={saveForm}>
          {t("general.save")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("notifConfigForm.subject")}
              name="Subject"
              initialValue={
                selectedNotifConfg
                  ? selectedNotifConfg.SubjectDesc === ""
                    ? undefined
                    : selectedNotifConfg.SubjectDesc
                  : undefined
              }
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Tree
          checkable
          checkedKeys={checkedStatusList.length > 0 ? checkedStatusList : []}
          onCheck={onCheck}
          treeData={renderTreeNodes()}
        ></Tree>
        <br />

        <Row>
          <Col span={12}>
            <Form.Item
              label={t("notifConfigForm.email_notification")}
              name="EmailNotification"
              valuePropName="checked"
              initialValue={
                selectedNotifConfg
                  ? selectedNotifConfg.EmailNotification
                  : false
              }
            >
              <Switch
                checkedChildren={t("general.yes")}
                unCheckedChildren={t("general.no")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

NotifSubjectForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  selectedNotifConfg: PropTypes.object.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default NotifSubjectForm;
