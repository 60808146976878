import React, { useEffect } from "react";
import { Modal, Form } from "antd";
import PropTypes from "prop-types";
import { SelectionInput } from "../../common/SelectionInput";
import { useTranslation } from "react-i18next";

const VehicleAssignmentInput = ({
  isVisible,
  allVehicles,
  selectedVehicle,
  onSaved,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  useEffect(() => form.resetFields(), [isVisible, form]);

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        onSaved(values.Vehicle.key);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("vehicleRequisitionForm.vehicle_assignment")}
      okText={t("general.confirm")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("vehicleForm.vehicle")}
          name="Vehicle"
          rules={[
            {
              required: true,
              message: t("vehicleForm.vehicle_required_validation"),
            },
            {
              validator: (_, value) => {
                if (value && !allVehicles.some((x) => x.Key === value.value)) {
                  return Promise.reject(
                    new Error(t("vehicleForm.invalid_vehicle"))
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
          initialValue={
            selectedVehicle && selectedVehicle.VehicleKey > 0
              ? {
                  key: selectedVehicle.VehicleKey,
                  value: selectedVehicle.VehicleKey,
                  label:
                    selectedVehicle.VehicleCode +
                    " - " +
                    selectedVehicle.VehicleDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            ref={React.createRef()}
            labelInValue={true}
            data={allVehicles}
            valueProp={"Key"}
            textProp={"CodeDesc"}
            disabledProp={"Active"}
            placeholder={t("vehicleForm.vehicle_placeholder")}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

VehicleAssignmentInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  allVehicles: PropTypes.array.isRequired,
  selectedVehicle: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export const VehicleAssignmentModal = VehicleAssignmentInput;
