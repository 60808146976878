import React, { useState } from "react";
import { Modal, Table, Alert } from "antd";
import { SelectionInput } from "../../components/common/SelectionInput";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

export const ReassignUserRoleModal = ({
  isVisible,
  deletedRoleType,
  roleTypes,
  onSaved,
  onCancelled,
}) => {
  const { t } = useTranslation();
  const [showErorrMsg, setShowErorrMsg] = useState(false);

  const RoleTypeSelection = (value, record) => (
    <SelectionInput
      ref={React.createRef()}
      labelInValue={true}
      data={roleTypes}
      valueProp={"RoleTypeKey"}
      textProp={"RoleTypeCodeDesc"}
      placeholder={t("roleTypesForm.role_type_placeholder")}
      onChange={(value) => onChangeRoleType(value, record)}
      defaultValue={{
        key: value,
        label:
          record.RoleTypeCodeDesc !== undefined
            ? record.RoleTypeCodeDesc
            : deletedRoleType.RoleTypeCodeDesc,
      }}
      style={{ width: 180 }}
    />
  );

  const columns = [
    {
      title: t("general.employee"),
      dataIndex: "EmpyIDEmpyName",
      key: "EmpyIDEmpyName",
      sorter: (a, b) => a.EmpyIDEmpyName.localeCompare(b.EmpyIDEmpyName),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("roleTypesForm.role_type"),
      dataIndex: "RoleTypeKey",
      key: "RoleTypeKey",
      align: "center",
      width: 200,
      render: RoleTypeSelection,
    },
  ];

  function onChangeRoleType(value, record) {
    deletedRoleType.Users = deletedRoleType.Users.map((x) => {
      if (x.UserRoleKey === record.UserRoleKey) {
        x.RoleTypeKey = value.value;
        x.RoleTypeCodeDesc = value.label;
      }

      return x;
    });
  }

  function handleClose() {
    setShowErorrMsg(false);
  }

  function saveForm(e) {
    e.preventDefault();

    var usersWithDeletedRoleType = deletedRoleType.Users.filter(
      (x) => x.RoleTypeKey === deletedRoleType.RoleTypeKey
    );

    if (usersWithDeletedRoleType.length > 0) {
      setShowErorrMsg(true);
    } else {
      onSaved(deletedRoleType.RoleTypeKey, deletedRoleType);
    }
  }

  return (
    <Modal
      open={isVisible}
      title={t("general.delete_confirmation")}
      okText={t("general.proceed")}
      onOk={saveForm}
      okType={"danger"}
      onCancel={onCancelled}
      destroyOnClose={true}
      width={800}
    >
      <div>
        <p>
          {t("roleTypesForm.role_type_delete_message_1") +
            deletedRoleType.Users.length +
            t("roleTypesForm.role_type_delete_message_2") +
            deletedRoleType.RoleTypeCode.toUpperCase() +
            t("roleTypesForm.role_type_delete_message_3")}
        </p>
        <Table
          dataSource={deletedRoleType.Users}
          columns={columns}
          size="middle"
          pagination={false}
          rowKey={(record) => record.UserRoleKey}
          scroll={{ y: "calc(100vh - 270px)" }}
        />
        {showErorrMsg ? (
          <Alert
            message={
              t("roleTypesForm.role_type_delete_validation_message") +
              deletedRoleType.RoleTypeCode
            }
            type="error"
            afterClose={handleClose}
            closable
            showIcon
            closeText={t("general.close_now")}
          />
        ) : null}
      </div>
    </Modal>
  );
};

ReassignUserRoleModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  deletedRoleType: PropTypes.object.isRequired,
  roleTypes: PropTypes.array.isRequired,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};
