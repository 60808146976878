export default function AnnouncementConfgModel({
  announcementConfgKey = -1,
  emailNotification = false,
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
}) {
  return Object.freeze({
    AnnouncementConfgKey: announcementConfgKey,
    EmailNotification: emailNotification,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
  });
}
