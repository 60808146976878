import dayjs from "dayjs";
import React,{ useEffect, useState } from "react";
import { Form } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify";
import Axios from "axios";
import { getAppraisalSubmissions } from "../../../api/appraisalSubmissionApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getESSEmployee } from "../../../api/employeeApi";
import { getAllAppraisals } from "../../../api/appraisalApi";
import AppraisalAdminForm from "../../forms/AdminPortal/AppraisalAdminForm";
import { useLocation } from "react-router-dom";

export default function AppraisalAdminPage({ history}) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [allAppraisalDS, setAllAppraisalDS] = useState([]);
    const [filteredAppraisalDS, setFilteredAppraisalDS] = useState([]);
    const [form] = Form.useForm();
    const location = useLocation();   //remain filter values when close input form
    const [currentYear, setCurrentYear] = useState(
      location.state ? location.state.filterYear : dayjs().year()
    );
    const [currentMonth, setCurrentMonth] = useState(
      location.state ? location.state.filterMonth : dayjs().month() + 1
    );
    const [allAppraisalCodes, setallAppraisalCodes] = useState([]);
    const [allOUs, setAllOUs] = useState([]);
    const [allDepartments, setAllDepartments] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [initialUsers, setInitialUsers] = useState([]);
    const [appraisalKey, setAppraisalKey] = useState(
      location.state ? location.state.filterAppraisalKey : 0
    );
    const [ouKey, setOUKey] = useState(
      location.state ? location.state.filterOUKey : 0
    );
    const [deptKey, setDeptKey] = useState(
      location.state ? location.state.filterDeptKey : 0
    );
    const [userKey, setUserKey] = useState(
      location.state ? location.state.filterUserKey : 0
    );
    const [pagination, setPagination] = useState(
      {
        current: 1,
        minIndex: 0,
        maxIndex: 100,
        pageSize: 100,
      });

    useEffect(getAppraisals, []);

    function getAppraisals() {
      let promises = [
        getAppraisalDataSource(currentYear, currentMonth),
        getAllAppraisals(),
        getOU(),
        getDepartment(),
        getESSEmployee(),
      ];

      Promise.all(promises).then((values) => {
        resetPagination();

        setallAppraisalCodes(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllOUs(values[2] ? values[2].filter((x) => x.Active) : []);
        setAllDepartments(values[3] ? values[3].filter((x) => x.Active) : []);
        setAllUsers(values[4] ? values[4] : []);
        setInitialUsers(values[4] ? values[4] : []);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setAllAppraisalDS([]);
        setFilteredAppraisalDS([]);
        setallAppraisalCodes([]);
        setAllOUs([]);
        setAllDepartments([]);
        setAllUsers([]);
        setInitialUsers([]);
        showErrorMessage(
          t('general.fail_to_load_record') + 
            error.response.data.error_description
        );
      });
    }

    function getAppraisalDataSource(currentYear, currentMonth) {
      setIsLoading(true);
      const CancelToken = Axios.CancelToken;
      const source = CancelToken.source();

      getAppraisalSubmissions(source, currentYear, currentMonth, true)
        .then((result) => {
          setAllAppraisalDS(result);
          setFilteredAppraisalDS(
            result.filter(
              (x) => 
                (ouKey === 0 || x.OUKey === ouKey) &&
                (deptKey === 0 || x.DeptKey === deptKey) &&
                (userKey === 0 || x.UserKey === userKey) && 
                (appraisalKey === 0 || x.APKey === appraisalKey)
              )
          );

          setIsLoading(false);
        }).catch((error) => {
          setIsLoading(false);
          setAllAppraisalDS([]);
          setFilteredAppraisalDS([]);
          showErrorMessage(
            t('general.fail_to_load_record') + 
              error.response.data.error_description
          );
        });
    }

    function onViewAppraisal(appraisalTransHdrKey) {
      history("/home/appraisalsubmission/appraisaltrans/" + appraisalTransHdrKey, 
      { state: { 
        fromAdmin: true,
        filterOUKey: ouKey,
        filterDeptKey: deptKey,
        filterUserKey: userKey,
        filterAppraisalKey: appraisalKey,
        filterYear: currentYear,
        filterMonth: currentMonth,
      }});
    }

    function filterRecord(ouKey, deptKey, userKey, appraisalKey) {
      setOUKey(ouKey);
      setDeptKey(deptKey);
      setUserKey(userKey);
      setAppraisalKey(appraisalKey);
      setFilteredAppraisalDS(
        allAppraisalDS.filter(
          (x) => 
            (ouKey === 0 || x.OUKey === ouKey) &&
            (deptKey === 0 || x.DeptKey === deptKey) &&
            (userKey === 0 || x.UserKey === userKey) && 
            (appraisalKey === 0 || x.APKey === appraisalKey)
          )
      );
    }

    function showErrorMessage(message) {
      toast.error(message);
    }

    function resetPagination() {
      setPagination({
        current: 1,
        minIndex: 0,
        maxIndex: 100,
        pageSize: 100,
      });
    }

    return (
      <AppraisalAdminForm 
        isLoading={isLoading}
        appraisalDS={filteredAppraisalDS}
        getAppraisalDataSource={getAppraisalDataSource}
        form={form}
        onViewAppraisal={onViewAppraisal}
        setCurrentYear={setCurrentYear}
        setCurrentMonth={setCurrentMonth}
        allAppraisalCodes={allAppraisalCodes}
        allOUs={allOUs}
        allDepartments={allDepartments}
        allUsers={allUsers}
        initialUsers={initialUsers}
        setAllUsers={setAllUsers}
        ouKey={ouKey}
        deptKey={deptKey}
        userKey={userKey}
        appraisalKey={appraisalKey}
        currentYear={currentYear}
        currentMonth={currentMonth}
        filterRecord={filterRecord}
        pagination={pagination}
        setPagination={setPagination}
        resetPagination={resetPagination}
      />
    );
}

AppraisalAdminPage.propTypes = {
  history: PropTypes.func.isRequired,
};