import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageAppraisalForm from "../../forms/Master/ManageAppraisalForm";
import PropTypes from "prop-types";
import AppraisalModel from "../../model/Appraisal/AppraisalModel";
import {
  getAppraisal,
  addAppraisal,
  updateAppraisal,
} from "../../../api/appraisalApi";
import { getAllActiveAppraisalTemplates } from "../../../api/appraisalTemplateApi";
import { getOUCode } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getEmpyGrade, getEmpyPosition } from "../../../api/empyGradeApi";
import { getESSUser } from "../../../api/roleAssignmentApi";
import { getARCode } from "../../../api/appraisalRatingApi";
import dayjs from "dayjs";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newAppraisal = AppraisalModel({});

export default function ManageAppraisalPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [appraisal, setAppraisal] = useState(newAppraisal);
  const [isLoading, setIsLoading] = useState(true);
  const [appraisalTemplates, setAppraisalTemplates] = useState([]);
  const [ous, setOUs] = useState([]);
  const [depts, setDepts] = useState([]);
  const [grades, setGradess] = useState([]);
  const [posts, setPosts] = useState([]);
  const [empys, setEmpys] = useState([]);
  const [ratings, setRatings] = useState([]);
  const { APKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [
      getAllActiveAppraisalTemplates(),
      getOUCode(),
      getDepartment(),
      getEmpyGrade(),
      getEmpyPosition(),
      getESSUser(),
      getARCode(),
    ];
    let editKey = APKey;
    if (editKey > 0) {
      promises.push(getAppraisal(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setAppraisalTemplates(values[0] ? values[0] : []);
        setOUs(values[1] ? values[1] : []);
        setDepts(values[2] ? values[2] : []);
        setGradess(values[3] ? values[3] : []);
        setPosts(values[4] ? values[4] : []);
        setEmpys(values[5] ? values[5] : []);
        setRatings(values[6] ? values[6] : []);
        if (values[7]) {
          values[7].SelfAppraisalDate = [
            dayjs(values[7].FromSelfAppraisalDate),
            dayjs(values[7].ToSelfAppraisalDate),
          ];
          values[7].ReviewDate = [
            dayjs(values[7].FromReviewDate),
            dayjs(values[7].ToReviewDate),
          ];

          if (values[7].UpdatedDate) {
            values[7].UpdatedOn = values[7].UpdatedDate;
          }
          if (values[7].CreatedDate) {
            values[7].CreatedOn = values[7].CreatedDate;
          }
        }
        setAppraisal(values[7] ? values[7] : newAppraisal);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [APKey]);

  function saveAppraisal(values) {
    if (!values.IsSelfAppraisal) {
      values.SelfAppraisalDate = [
        dayjs(new Date("1900-01-01")),
        dayjs(new Date("1900-01-01")),
      ];
    }

    let appraisalSubmit = {
      ...appraisal,
      ...values,
      APCode: values.APCode.trim(),
      APDesc: values.APDesc.trim(),
      ATKey: values.AppraisalTemplate.key,
      ARKey:
        values.AppraisalRatingTemplate !== undefined
          ? values.AppraisalRatingTemplate.key
          : -1,
      FromSelfAppraisalDate: values.SelfAppraisalDate[0].startOf("day").$d,
      ToSelfAppraisalDate: values.SelfAppraisalDate[1].startOf("day").$d,
      FromReviewDate: values.ReviewDate[0].startOf("day").$d,
      ToReviewDate: values.ReviewDate[1].startOf("day").$d,
    };

    let upsertAppraisal =
      appraisalSubmit.APKey !== -1 ? updateAppraisal : addAppraisal;
    setIsLoading(true);
    upsertAppraisal(appraisalSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/appraisals", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          appraisalSubmit.APKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputAppraisal() {
    history("/home/appraisals", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageAppraisalForm
      isLoading={isLoading}
      appraisal={appraisal}
      appraisalTemplates={appraisalTemplates}
      ous={ous}
      depts={depts}
      grades={grades}
      posts={posts}
      empys={empys}
      ratings={ratings}
      onSubmitted={saveAppraisal}
      onCancelled={cancelInputAppraisal}
      onDataChanged={setAppraisal}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAppraisalPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
