import React from "react";
import { Table, Button, Row, Col } from "antd";
import { EditButton, DeleteButton } from "../../common/TableCols";
import PropTypes from "prop-types";
import {
  regionLocale,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

export const ClaimTypeLimitTable = ({
  limitLevel,
  data,
  onAdded,
  onEdited,
  onDeleted,
  userCompCurr,
}) => {
  const { t } = useTranslation();
  const limitType = 3;

  const ClaimLimitActions = (value) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdited(limitLevel, value)} />
      </Col>
      <Col span={12}>
        <DeleteButton fncDelete={() => onDeleted(limitLevel, value)} />
      </Col>
    </Row>
  );

  const columns = [
    {
      title: t("general." + limitLevel.Desc),
      dataIndex: "RefDesc",
      key: "RefDesc",
      editable: true,
      onCell: (value, index) => {
        return {
          children: value,
          rowSpan: index % limitType === 0 ? limitType : 0,
        };
      },
    },
    {
      title: t("claimTypesForm.limit"),
      dataIndex: "LimitTypeDesc",
      key: "LimitTypeDesc",
    },
    {
      title: t("claimTypesForm.personal"),
      dataIndex: "SelfAmt",
      key: "SelfAmt",
      align: "right",
      editable: true,
      render: (text) =>
        text > 0
          ? text.toLocaleString(regionLocale(), {
              minimumFractionDigits: precisionDecimal(userCompCurr.CurrCode),
              maximumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("claimTypesForm.spouse"),
      dataIndex: "SpouseAmt",
      key: "SpouseAmt",
      align: "right",
      editable: true,
      render: (text) =>
        text > 0
          ? text.toLocaleString(regionLocale(), {
              minimumFractionDigits: precisionDecimal(userCompCurr.CurrCode),
              maximumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("claimTypesForm.children"),
      children: [
        {
          title: t("general.amount"),
          dataIndex: "ChildAmt",
          key: "ChildAmt",
          align: "right",
          editable: true,
          render: (text) =>
            text > 0
              ? text.toLocaleString(regionLocale(), {
                  minimumFractionDigits: precisionDecimal(
                    userCompCurr.CurrCode
                  ),
                  maximumFractionDigits: 2,
                })
              : "-",
        },
        {
          title: t("claimTypesForm.max_num"),
          dataIndex: "MaxChildNum",
          key: "MaxChildNum",
          align: "right",
          editable: true,
          className: "tablegroup",
          render: (text) =>
            text > 0
              ? text.toLocaleString(regionLocale(), {
                  minimumFractionDigits: precisionDecimal(
                    userCompCurr.CurrCode
                  ),
                  maximumFractionDigits: 2,
                })
              : "-",
        },
      ],
    },
    {
      title: t("general.total"),
      dataIndex: "MaxTotalAmt",
      key: "MaxTotalAmt",
      align: "right",
      className: "tablegroup",
      render: (text) =>
        text > 0
          ? text.toLocaleString(regionLocale(), {
              minimumFractionDigits: precisionDecimal(userCompCurr.CurrCode),
              maximumFractionDigits: 2,
            })
          : "-",
    },
    {
      title: t("general.action"),
      dataIndex: "RefKey",
      key: "ClaimLimitActions",
      align: "center",
      width: 85,
      render: ClaimLimitActions,
      onCell: (value, index) => {
        return {
          rowSpan: index % limitType === 0 ? limitType : 0,
        };
      },
    },
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns.filter((x) => x !== null)}
        size="small"
        pagination={false}
        rowKey={(record) => record.RefKey + "-" + record.LimitType}
        bordered
      />
      <Button
        type="primary"
        style={{ marginTop: 16 }}
        onClick={() => onAdded(limitLevel)}
      >
        {t("general.add_new")}
      </Button>
    </div>
  );
};

ClaimTypeLimitTable.propTypes = {
  limitLevel: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onAdded: PropTypes.func.isRequired,
  onEdited: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
  userCompCurr: PropTypes.object.isRequired,
};
