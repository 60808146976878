import React, { useState } from "react";
import { ActiveCheckbox, EditButton } from "../../common/TableCols";
import { Table, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import { useTranslation } from "react-i18next";

export const RestDayProfileForm = ({
  isLoading,
  restDayProfiles,
  onEdit,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const RestDayProfileActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const [searchText, setSearchText] = useState("");

  const RestDayProfileActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(record)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("general.employee_id"),
      dataIndex: "EmpyID",
      key: "EmpyID",
      width: 200,
      fixed: "left",
      sorter: (a, b) => a.EmpyID.localeCompare(b.EmpyID),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "EmpyID",
        searchText,
        handleSearch,
        handleReset,
        "Employee ID",
        t
      ),
    },
    {
      title: t("general.employee_name"),
      dataIndex: "EmpyName",
      key: "EmpyName",
      sorter: (a, b) => a.EmpyName.localeCompare(b.EmpyName),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "EmpyName",
        searchText,
        handleSearch,
        handleReset,
        "Employee Name",
        t
      ),
    },
    {
      title: t("general.sun"),
      dataIndex: "Sun",
      key: "Sun",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.mon"),
      dataIndex: "Mon",
      key: "Mon",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.tue"),
      dataIndex: "Tue",
      key: "Tue",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.wed"),
      dataIndex: "Wed",
      key: "Wed",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.thu"),
      dataIndex: "Thu",
      key: "Thu",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.fri"),
      dataIndex: "Fri",
      key: "Fri",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.sat"),
      dataIndex: "Sat",
      key: "Sat",
      align: "center",
      width: 50,
      render: RestDayProfileActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "AnnouncementKey",
      key: "AnnouncementActions",
      align: "center",
      width: 100,
      render: RestDayProfileActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">
          {t("restDayProfileForm.rest_day_profile_setup")}
        </span>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={restDayProfiles}
          columns={columns}
          size="middle"
          rowKey={(record) => record.UserKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
        />
      </Spin>
    </Card>
  );
};

RestDayProfileForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  restDayProfiles: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
