import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageLeaveTypeForm from "../../forms/Master/ManageLeaveTypeForm";
import PropTypes from "prop-types";
import LeaveTypeModel from "../../model/Leave/LeaveTypeModel";
import * as compapi from "../../../api/companyApi";
import {
  getLeaveType,
  addLeaveType,
  updateLeaveType,
} from "../../../api/leaveTypeApi";
import { getEmpyGrade } from "../../../api/empyGradeApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newLeaveType = LeaveTypeModel({});

export default function ManageLeaveTypePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [empyGrades, setEmpyGrades] = useState([]);
  const [leaveType, setLeaveType] = useState(newLeaveType);
  const [isLoading, setIsLoading] = useState(true);
  const { LTKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [getEmpyGrade()];
    promises.push(compapi.getCompany());
    let editKey = LTKey;
    if (editKey > 0) {
      promises.push(getLeaveType(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        values[2]
          ? (values[2]["Company"] = values[1])
          : (newLeaveType.Company.length = 0);
        setLeaveType(newLeaveType);
        values[1].map((x) => newLeaveType["Company"].push(x));
        setEmpyGrades(values[0] ? values[0] : []);
        if (values[2]) {
          if (values[2].UpdatedDate) {
            values[2].UpdatedOn = values[2].UpdatedDate;
          }
          if (values[2].CreatedDate) {
            values[2].CreatedOn = values[2].CreatedDate;
          }
        }
        setLeaveType(values[2] ? values[2] : newLeaveType);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [LTKey]);

  function saveLeaveType(values) {
    let leaveTypeSubmit = {
      ...leaveType,
      ...values,
      LTCode: values.LTCode.trim(),
      LTDesc: values.LTDesc.trim(),
    };

    let upsertLeaveType =
      leaveTypeSubmit.LTKey !== -1 ? updateLeaveType : addLeaveType;
    setIsLoading(true);
    upsertLeaveType(leaveTypeSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/leavetypes", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          leaveTypeSubmit.LTKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputLeaveType() {
    history("/home/leavetypes", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function getGradeSelectionData() {
    let selectionData;

    selectionData = [...empyGrades];
    //add all option
    selectionData.unshift({
      Key: -1,
      Code: "All",
      Desc: "All",
      CodeDesc: "All",
      Active: true,
    });
    return selectionData;
  }

  return (
    <ManageLeaveTypeForm
      isLoading={isLoading}
      leaveType={leaveType}
      gradeSelectionData={getGradeSelectionData()}
      onSubmitted={saveLeaveType}
      onCancelled={cancelInputLeaveType}
      onDataChanged={setLeaveType}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageLeaveTypePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
