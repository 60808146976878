import React from "react";
import { Modal, InputNumber, Row, Col, Collapse, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import { LimitType } from "../../model/Claim/ClaimEnum";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
  stepPrecision,
  precisionDecimal,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { Panel } = Collapse;

export const ClaimTypeLimitInput = ({
  isVisible,
  limitLevel,
  claimTypeLimits,
  onCancelled,
  onSaved,
  onLimitAmtChanged,
  userCompCurr,
  form,
  selectionData = [],
}) => {
  const { t } = useTranslation();

  const decimalProps = {
    style: { width: "90%" },
    step: stepPrecision(userCompCurr.CurrCode),
    min: 0,
    precision: precisionDecimal(userCompCurr.CurrCode),
    prefix: userCompCurr.CurrSymb ? userCompCurr.CurrSymb : "",
    formatter: inputLocaleFormatter(precisionDecimal(userCompCurr.CurrCode)),
    parser: inputLocaleParser(precisionDecimal(userCompCurr.CurrCode)),
  };

  const numberProps = {
    min: 0,
    precision: 0,
    formatter: inputLocaleFormatter(0),
    parser: inputLocaleParser(0),
  };

  const LimitTypeHeader = (header, total) => {
    return (
      <Row type="flex" justify="space-between">
        <Col>{header}</Col>
        <Col>
          {(userCompCurr && userCompCurr.CurrSymb
            ? userCompCurr.CurrSymb
            : "") +
            " " +
            total.toLocaleString(regionLocale(), {
              minimumFractionDigits: precisionDecimal(userCompCurr.CurrCode),
              maximumFractionDigits: 2,
            })}
        </Col>
      </Row>
    );
  };

  function calcTotalLimitByTrans({
    selfAmt = form.getFieldValue("SelfAmtByTrans"),
    spouseAmt = form.getFieldValue("SpouseAmtByTrans"),
    childAmt = form.getFieldValue("ChildAmtByTrans"),
    maxChildNum = form.getFieldValue("MaxChildNumByTrans"),
  }) {
    onLimitAmtChanged(
      LimitType.TRANS.Type,
      selfAmt ? selfAmt : 0,
      spouseAmt ? spouseAmt : 0,
      childAmt ? childAmt : 0,
      maxChildNum ? maxChildNum : 0
    );
  }

  function calcTotalLimitByMth({
    selfAmt = form.getFieldValue("SelfAmtByMth"),
    spouseAmt = form.getFieldValue("SpouseAmtByMth"),
    childAmt = form.getFieldValue("ChildAmtByMth"),
    maxChildNum = form.getFieldValue("MaxChildNumByMth"),
  }) {
    onLimitAmtChanged(
      LimitType.MTH.Type,
      selfAmt ? selfAmt : 0,
      spouseAmt ? spouseAmt : 0,
      childAmt ? childAmt : 0,
      maxChildNum ? maxChildNum : 0
    );
  }

  function calcTotalLimitByYr({
    selfAmt = form.getFieldValue("SelfAmtByYr"),
    spouseAmt = form.getFieldValue("SpouseAmtByYr"),
    childAmt = form.getFieldValue("ChildAmtByYr"),
    maxChildNum = form.getFieldValue("MaxChildNumByYr"),
  }) {
    onLimitAmtChanged(
      LimitType.YR.Type,
      selfAmt ? selfAmt : 0,
      spouseAmt ? spouseAmt : 0,
      childAmt ? childAmt : 0,
      maxChildNum ? maxChildNum : 0
    );
  }

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        onSaved(limitLevel, values);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={"Claim Limit for " + limitLevel.Desc}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label={t("general." + limitLevel.Desc)}
          name="Ref"
          rules={[
            {
              required: true,
              message: t("claimTypesForm." + limitLevel.Desc + " is required"),
            },
          ]}
          initialValue={
            claimTypeLimits[0] && claimTypeLimits[0].RefKey !== -1
              ? {
                  key: claimTypeLimits[0].RefKey,
                  label: claimTypeLimits[0].RefDesc,
                }
              : undefined
          }
        >
          <SelectionInput
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            ref={React.createRef()}
            data={selectionData}
            valueProp={"Key"}
            textProp={"CodeDesc"}
            disabledProp={"Active"}
            placeholder={t("claimTypesForm.Select " + limitLevel.Desc)}
            labelInValue={true}
          />
        </Form.Item>
        <Row style={{ marginRight: 15, textAlign: "right", display: "block" }}>
          <Col>
            <b>{t("general.total")}</b>
          </Col>
        </Row>
        <Collapse bordered={false} defaultActiveKey={["2"]}>
          <Panel
            header={LimitTypeHeader(
              t("claimTypesForm.limit_per_year"),
              claimTypeLimits[2] && claimTypeLimits[2].MaxTotalAmt
                ? claimTypeLimits[2].MaxTotalAmt
                : 0
            )}
            key="2"
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.personal_amount")}
                  name="SelfAmtByYr"
                  initialValue={
                    claimTypeLimits[2] && claimTypeLimits[2].SelfAmt
                      ? claimTypeLimits[2].SelfAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByYr({
                        selfAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.spouse_amount")}
                  name="SpouseAmtByYr"
                  initialValue={
                    claimTypeLimits[2] && claimTypeLimits[2].SpouseAmt
                      ? claimTypeLimits[2].SpouseAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByYr({
                        spouseAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.children_amount")}
                  name="ChildAmtByYr"
                  initialValue={
                    claimTypeLimits[2] && claimTypeLimits[2].ChildAmt
                      ? claimTypeLimits[2].ChildAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByYr({
                        childAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.max_num_of_children")}
                  name="MaxChildNumByYr"
                  initialValue={
                    claimTypeLimits[2] && claimTypeLimits[2].MaxChildNum
                      ? claimTypeLimits[2].MaxChildNum
                      : 0
                  }
                >
                  <InputNumber
                    {...numberProps}
                    onChange={(e) =>
                      calcTotalLimitByYr({
                        maxChildNum: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel
            header={LimitTypeHeader(
              t("claimTypesForm.limit_per_month"),
              claimTypeLimits[1] && claimTypeLimits[1].MaxTotalAmt
                ? claimTypeLimits[1].MaxTotalAmt
                : 0
            )}
            key="1"
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.personal_amount")}
                  name="SelfAmtByMth"
                  initialValue={
                    claimTypeLimits[1] && claimTypeLimits[1].SelfAmt
                      ? claimTypeLimits[1].SelfAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByMth({
                        selfAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.spouse_amount")}
                  name="SpouseAmtByMth"
                  initialValue={
                    claimTypeLimits[1] && claimTypeLimits[1].SpouseAmt
                      ? claimTypeLimits[1].SpouseAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByMth({
                        spouseAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.children_amount")}
                  name="ChildAmtByMth"
                  initialValue={
                    claimTypeLimits[1] && claimTypeLimits[1].ChildAmt
                      ? claimTypeLimits[1].ChildAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByMth({
                        childAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.max_num_of_children")}
                  name="MaxChildNumByMth"
                  initialValue={
                    claimTypeLimits[1] && claimTypeLimits[1].MaxChildNum
                      ? claimTypeLimits[1].MaxChildNum
                      : 0
                  }
                >
                  <InputNumber
                    {...numberProps}
                    onChange={(e) =>
                      calcTotalLimitByMth({
                        maxChildNum: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
          <Panel
            header={LimitTypeHeader(
              t("claimTypesForm.limit_per_transaction"),
              claimTypeLimits[0] && claimTypeLimits[0].MaxTotalAmt
                ? claimTypeLimits[0].MaxTotalAmt
                : 0
            )}
            key="0"
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.personal_amount")}
                  name="SelfAmtByTrans"
                  initialValue={
                    claimTypeLimits[0] && claimTypeLimits[0].SelfAmt
                      ? claimTypeLimits[0].SelfAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByTrans({
                        selfAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.spouse_amount")}
                  name="SpouseAmtByTrans"
                  initialValue={
                    claimTypeLimits[0] && claimTypeLimits[0].SpouseAmt
                      ? claimTypeLimits[0].SpouseAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByTrans({
                        spouseAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.children_amount")}
                  name="ChildAmtByTrans"
                  initialValue={
                    claimTypeLimits[0] && claimTypeLimits[0].ChildAmt
                      ? claimTypeLimits[0].ChildAmt
                      : 0
                  }
                >
                  <InputNumber
                    {...decimalProps}
                    onChange={(e) =>
                      calcTotalLimitByTrans({
                        childAmt: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={t("claimTypesForm.max_num_of_children")}
                  name="MaxChildNumByTrans"
                  initialValue={
                    claimTypeLimits[0] && claimTypeLimits[0].MaxChildNum
                      ? claimTypeLimits[0].MaxChildNum
                      : 0
                  }
                >
                  <InputNumber
                    {...numberProps}
                    onChange={(e) =>
                      calcTotalLimitByTrans({
                        maxChildNum: e,
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

ClaimTypeLimitInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  limitLevel: PropTypes.object.isRequired,
  claimTypeLimits: PropTypes.array.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  onLimitAmtChanged: PropTypes.func.isRequired,
  userCompCurr: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  selectionData: PropTypes.array,
};

export const ClaimTypeLimitForm = ClaimTypeLimitInput;
