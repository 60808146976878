import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LeaveApplicationDisplayAllForm from "../../forms/Leave/LeaveApplicationDisplayAllForm";
import { getAllLeaveApplication } from "../../../api/leaveApplicationApi";
import { getOU } from "../../../api/ouApi";
import { getDepartment } from "../../../api/deptApi";
import { getESSEmployee } from "../../../api/employeeApi";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { StatusByHexColor } from "../../../components/model/common/Status";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function LeaveApplicationDisplayAllPage() {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [userLeaveApplication, setUserLeaveApplication] = useState([]);
  const [filteredUserLeaveApplication, setFilteredUserLeaveApplication] =
    useState([]);
  const [currentMonth, setCurrentMonth] = useState(dayjs().month() + 1);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [allOus, setAllOus] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [ouKey, setOUKey] = useState(0);
  const [deptKey, setDeptKey] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [initialUsers, setInitialUsers] = useState([]);
  const [userKey, setUserKey] = useState(0);

  useEffect(getLeaves, []);

  function getLeaves() {
    let promises = [
      getLeaveApplicationDataSource(currentMonth, currentYear),
      getOU(),
      getDepartment(),
      getESSEmployee(),
    ];

    Promise.all(promises)
      .then((values) => {
        setAllOus(values[1] ? values[1].filter((x) => x.Active) : []);
        setAllDepartments(values[2] ? values[2].filter((x) => x.Active) : []);
        setAllUsers(values[3] ? values[3] : []);
        setInitialUsers(values[3] ? values[3] : []);

        setTimeout(() => {
          scrollToView();
        }, 300);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function scrollToView() {
    //scroll to current date to ease view
    let now = new Date();
    //date x cell width to get scroll position
    let date = now.getDate();
    var tableBody = document.querySelector("div.ant-table-body");
    if (tableBody != null) {
      tableBody.scrollLeft = (date - 1) * 35;
    }
  }

  function formatLocaleDay(day) {
    if (day === "Isn" || day === "Sen") {
      day = "Mon";
    } else if (day === "Sel") {
      day = "Tue";
    } else if (day === "Rab") {
      day = "Wed";
    } else if (day === "Kha" || day === "Kam") {
      day = "Thu";
    } else if (day === "Jum") {
      day = "Fri";
    } else if (day === "Sab") {
      day = "Sat";
    } else if (day === "Ahd" || day === "Min") {
      day = "Sun";
    }

    return day;
  }

  function getListData(value, lvalue, hvalue, ovalue) {
    let listData = [];

    if (hvalue.length > 0) {
      let x = 0;
      let formatDate = value.startOf("day");
      for (x = 0; x < hvalue.length; x++) {
        let formatDateHValue = dayjs(hvalue[x].HolidayDate).startOf("day");
        if (formatDate.isSame(formatDateHValue)) {
          listData.push({
            type: StatusByHexColor[95],
            content: hvalue[x].HolidayDesc,
            day: value.format("dddd"),
          });
          break;
        }
      }
    }

    if (ovalue.length > 0) {
      let x = 0;
      let formatDay = formatLocaleDay(value.format("ddd"));
      for (x = 0; x < ovalue.length; x++) {
        if (ovalue[x][formatDay]) {
          listData.push({
            type: StatusByHexColor[88],
            content: "Rest Day",
            day: value.format("dddd"),
          });
          break;
        }
      }
    }

    if (lvalue.length > 0) {
      let x = 0;
      let formatDate = value.startOf("day");
      for (x = 0; x < lvalue.length; x++) {
        let fromFormatDate = dayjs(lvalue[x].FromLeaveApplicationDate).startOf(
          "day"
        );
        let toFormatDate = dayjs(lvalue[x].ToLeaveApplicationDate).startOf(
          "day"
        );

        if (formatDate.isBetween(fromFormatDate, toFormatDate, "days", "[]")) {
          listData.push({
            type: StatusByHexColor[lvalue[x].Status],
            content: lvalue[x].LTDesc,
            day: value.format("dddd"),
          });
          break;
        }
      }
    }

    return listData || [];
  }

  function dateCellRender(
    leaveTransData,
    offDaysData,
    holidayProfilesData,
    month,
    year
  ) {
    const result = {};
    const map = new Map();

    for (let x = 1; x < 32; x++) {
      let tempToStringDate = x.toString().padStart(2, "0");
      let tempToStringMth = month.toString().padStart(2, "0");
      let tempToStringYr = year.toString().padStart(4, "0");
      let tempToStringFull =
        tempToStringYr + "-" + tempToStringMth + "-" + tempToStringDate;

      tempToStringDate = dayjs(tempToStringFull);

      const listData = getListData(
        tempToStringDate,
        leaveTransData,
        holidayProfilesData,
        offDaysData
      );

      if (listData.length > 0) {
        if (!map.has(x)) {
          map.set(x, true);
          result[x] = listData[0];
        }
      }
    }
    return result;
  }

  function getLeaveApplicationDataSource(month, year) {
    setIsLoading(true);

    let promises = [getAllLeaveApplication(month, year)];

    Promise.all(promises)
      .then((values) => {
        let y = 0;
        let tempData = [];

        if (values[0]) {
          for (y = 0; y < values[0].length; y++) {
            let LeaveTrans = values[0][y].LeaveTrans
              ? values[0][y].LeaveTrans
              : [];
            let OffDayTrans = values[0][y].OffDayTrans
              ? values[0][y].OffDayTrans
              : [];
            let HolidayTrans = values[0][y].HolidayTrans
              ? values[0][y].HolidayTrans
              : [];

            let userLeaveApplicationData = dateCellRender(
              LeaveTrans,
              OffDayTrans,
              HolidayTrans,
              month,
              year
            );

            userLeaveApplicationData.EmpyID = values[0][y].EmpyID;
            userLeaveApplicationData.EmployeeName = values[0][y].EmpyName;
            userLeaveApplicationData.UserKey = values[0][y].UserKey;
            userLeaveApplicationData.OUKey = values[0][y].OUKey;
            userLeaveApplicationData.DeptKey = values[0][y].DeptKey;

            tempData.push(userLeaveApplicationData);
          }
        }
        setUserLeaveApplication(tempData);
        setFilteredUserLeaveApplication(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (deptKey === 0 || x.DeptKey === deptKey) &&
              (userKey === 0 || x.UserKey === userKey)
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function filterRecord(ouKey, deptKey, userKey) {
    setOUKey(ouKey);
    setDeptKey(deptKey);
    setUserKey(userKey);
    setFilteredUserLeaveApplication(
      userLeaveApplication.filter(
        (x) =>
          (ouKey === 0 || x.OUKey === ouKey) &&
          (deptKey === 0 || x.DeptKey === deptKey) &&
          (userKey === 0 || x.UserKey === userKey)
      )
    );
  }

  return (
    <LeaveApplicationDisplayAllForm
      isLoading={isLoading}
      userLeaveApplication={filteredUserLeaveApplication}
      getLeaveApplicationDataSource={getLeaveApplicationDataSource}
      setCurrentMonth={setCurrentMonth}
      setCurrentYear={setCurrentYear}
      allOus={allOus}
      allDepartments={allDepartments}
      allUsers={allUsers}
      initialUsers={initialUsers}
      setAllUsers={setAllUsers}
      filterRecord={filterRecord}
      form={form}
    />
  );
}

LeaveApplicationDisplayAllPage.propTypes = {
  history: PropTypes.func.isRequired,
};
