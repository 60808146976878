import { RowState } from "../../common/RowState";

export default function TravelAuthorizationDetModel({
  travelTransDetKey = Math.floor(Math.random() * 999999),
  travelTransHdrKey = -1,
  claimTypeKey = null,
  claimTypeCodeClaimTypeDesc = "",
  uomKey = -1,
  uomCodeUOMDesc = "",
  remarks = "",
  quantity = 1,
  unitCost = 0,
  totalAmount = 0,
  funcTotalAmount = 0,
  rowState = RowState.ADDED,
}) {
  return Object.freeze({
    TravelTransDetKey: travelTransDetKey,
    TravelTransHdrKey: travelTransHdrKey,
    ClaimTypeKey: claimTypeKey,
    ClaimTypeCodeClaimTypeDesc: claimTypeCodeClaimTypeDesc,
    UOMKey: uomKey,
    UOMCodeUOMDesc: uomCodeUOMDesc,
    Remarks: remarks,
    Quantity: quantity,
    UnitCost: unitCost,
    TotalAmount: totalAmount,
    FuncTotalAmount: funcTotalAmount,
    RowState: rowState,
  });
}
