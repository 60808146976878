import React, { useState, useEffect } from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Slider,
  Checkbox,
  Radio,
  Switch,
  Tag,
  Divider,
  Form,
  Space,
} from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const ManageAppraisalTemplatePreviewForm = ({
  sectionHeader,
  sectionDetail,
  sectionAnswer = [],
  sectionTag = [],
  onDataChanged,
  appraisalSubmission,
  isSelfAppraiseExp = false,
  isReviewExp = false,
  isLv1Appraiser = false,
  isLv2Appraiser = false,
  isApproved = false,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { Paragraph, Text } = Typography;
  const { TextArea } = Input;

  const radioStyle = {
    height: "30px",
    lineHeight: "30px",
    fontSize: "12px",
  };
  const checkBoxStyle = {
    fontSize: "12px",
  };
  const marks = {
    1: {
      style: {
        color: "#ff0000",
      },
      label: <strong>1</strong>,
    },
    2: {
      style: {
        color: "#ff0000",
      },
      label: <strong>2</strong>,
    },
    3: {
      style: {
        color: "#ff0000",
      },
      label: <strong>3</strong>,
    },
    4: {
      style: {
        color: "#ff8c00",
      },
      label: <strong>4</strong>,
    },
    5: {
      style: {
        color: "#ff8c00",
      },
      label: <strong>5</strong>,
    },
    6: {
      style: {
        color: "#ff8c00",
      },
      label: <strong>6</strong>,
    },
    7: {
      style: {
        color: "#ff8c00",
      },
      label: <strong>7</strong>,
    },
    8: {
      style: {
        color: "#008000",
      },
      label: <strong>8</strong>,
    },
    9: {
      style: {
        color: "#008000",
      },
      label: <strong>9</strong>,
    },
    10: {
      style: {
        color: "#008000",
      },
      label: <strong>10</strong>,
    },
  };

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (appraisalSubmission !== undefined) {
      updateAppraisal();
    }
    // eslint-disable-next-line
  }, [currentStep]);

  function getFilterAppraisalTemplateAnswerData(answerSection) {
    let count = 0;
    let filterData = answerSection
      .filter((x) => x.RowState !== sectionDetail.DELETED)
      .map((x) => {
        count++;
        return { ...x, ATSubDetSeqNo: count };
      });
    return filterData.reverse();
  }

  function onChange() {
    setCurrentStep(currentStep + 1);
  }

  function updateAppraisal() {
    let role = isLv1Appraiser ? "aprlv1" : isLv2Appraiser ? "aprlv2" : "user";

    let temp = appraisalSubmission.Dets.map((item) =>
      item.ATDetType === 1 && item.UserRole === role
        ? {
            ...item,
            AppraisalSelection:
              form.getFieldValue(item.ATDetKey + "-radiogroup") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-radiogroup")
                : item.AppraisalSelection,
            Comment:
              form.getFieldValue(item.ATDetKey + "-ATDetComments") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-ATDetComments")
                : item.Comment,
          }
        : item.ATDetType === 2 && item.UserRole === role
        ? {
            ...item,
            AppraisalSelection:
              form.getFieldValue(item.ATDetKey + "-switch") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-switch")
                : item.AppraisalSelection,
            Comment:
              form.getFieldValue(item.ATDetKey + "-ATDetComments") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-ATDetComments")
                : item.Comment,
          }
        : item.ATDetType === 3 && item.UserRole === role
        ? {
            ...item,
            AppraisalSelection:
              form.getFieldValue(item.ATDetKey + "-slider") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-slider")
                : item.AppraisalSelection,
            Comment:
              form.getFieldValue(item.ATDetKey + "-ATDetComments") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-ATDetComments")
                : item.Comment,
          }
        : item.ATDetType === 4 && item.UserRole === role
        ? {
            ...item,
            AppraisalSelection:
              form.getFieldValue(item.ATDetKey + "-checkbox") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-checkbox")
                : item.AppraisalSelection,
            Comment:
              form.getFieldValue(item.ATDetKey + "-ATDetComments") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-ATDetComments")
                : item.Comment,
          }
        : item.UserRole === role
        ? {
            ...item,
            Comment:
              form.getFieldValue(item.ATDetKey + "-ATDetComments") !== undefined
                ? form.getFieldValue(item.ATDetKey + "-ATDetComments")
                : item.Comment,
          }
        : {
            ...item,
          }
    );

    onDataChanged({
      ...appraisalSubmission,
      Dets: temp,
    });
  }

  function getTransformValue(key, type) {
    let temp = sectionAnswer.find(
      (z) => z.ATDetKey === key && z.ATDetType === type
    );

    if (temp !== undefined) {
      return temp.AppraisalSelection;
    }
    return temp;
  }

  function calculatePercentage(item) {
    if (appraisalSubmission !== undefined) {
      let appraisalAns = appraisalSubmission.Dets.find(
        (x) =>
          x.ATDetKey === item.ATDetKey &&
          (isLv1Appraiser
            ? x.UserRole === "aprlv1"
            : isLv2Appraiser
            ? x.UserRole === "aprlv2"
            : "user")
      );
      let selection = 0;
      let total = 0;
      let weight = 0;
      let result = 0;
      if (item.ATDetType === 1) {
        selection = appraisalAns.AppraisalSelection;
        total = item.AppraisalSubDetail.length;
        weight = item.ATDetWeight;
        result = (selection / total) * weight;
        return result.toFixed(2) + " / ";
      } else if (item.ATDetType === 2) {
        if (appraisalAns.AppraisalSelection) result = item.ATDetWeight;
        return result.toFixed(2) + " / ";
      } else if (item.ATDetType === 3) {
        selection = appraisalAns.AppraisalSelection;
        total = 10;
        weight = item.ATDetWeight;
        result = (selection / total) * weight;
        return result.toFixed(2) + " / ";
      } else if (item.ATDetType === 4) {
        selection = appraisalAns.AppraisalSelection.length;
        total = item.AppraisalSubDetail.length;
        weight = item.ATDetWeight;
        result = (selection / total) * weight;
        return result.toFixed(2) + " / ";
      }
    }
    return "";
  }

  function getSelectionTag(key, role, color, value) {
    let temp = sectionTag.find(
      (z) => z.ATDetKey === key && z.UserRole === role
    );

    let roleString = "";
    if (role === "user") roleString = "Employee";
    else if (role === "aprlv1") roleString = "Reviewer";
    else if (role === "aprlv2") roleString = "Reviewer 2";

    if (temp !== undefined) {
      if (temp.ATDetType === 1) {
        if (temp.AppraisalSelection === value)
          return <Tag color={color}>{roleString}</Tag>;
      } else if (temp.ATDetType === 2) {
        if (temp.AppraisalSelection) {
          return (
            <Tag color={color}>
              {roleString} : {t("general.yes")}
            </Tag>
          );
        } else {
          return (
            <Tag color={color}>
              {roleString} : {t("general.no")}
            </Tag>
          );
        }
      } else if (temp.ATDetType === 3) {
        return (
          <Tag color={color}>
            {roleString} : {temp.AppraisalSelection}
          </Tag>
        );
      } else if (temp.ATDetType === 4) {
        if (temp.AppraisalSelection.includes(value))
          return <Tag color={color}>{roleString}</Tag>;
      } else {
        return <Tag color={color}>{roleString}</Tag>;
      }
    }
  }

  function getSelectionCommentTag(key, role, color) {
    let temp = sectionTag.find(
      (z) => z.ATDetKey === key && z.UserRole === role
    );

    let roleString = "";
    if (role === "user") roleString = "Employee";
    else if (role === "aprlv1") roleString = "Reviewer";
    else if (role === "aprlv2") roleString = "Reviewer 2";

    if (temp !== undefined) {
      if (temp.Comment !== "") {
        return (
          <Tag color={color} style={{ whiteSpace: "pre-wrap" }}>
            {roleString} {t("appraisalTemplateForm.comments")} : {temp.Comment}
          </Tag>
        );
      }
    }
  }

  return (
    <Form form={form} layout="vertical">
      <div
        style={{
          border: "1px solid rgb(235, 237, 240)",
          backgroundColor: "cornflowerblue",
          position: "relative",
          padding: "16px 24px",
        }}
      >
        <div style={{ display: "inline-block" }}>
          <span
            style={{
              display: "inline-block",
              paddingRight: 12,
              fontWeight: 700,
              font: 16,
              lineHeight: 1.4,
            }}
          >
            {sectionHeader.ATDetTitle}
          </span>
          <span
            style={{
              display: "inline-block",
              paddingRight: 12,
              color: "rgba(0,0,0,.45)",
              font: 14,
              lineHeight: 1.8,
            }}
          >
            {sectionHeader.ATDetSubTitle}
          </span>
          <span style={{ right: 0, top: 16, position: "absolute" }}>
            <div key={sectionHeader.ATDetKey}>
              [{sectionHeader.ATDetWeight}%]
            </div>
          </span>
        </div>
      </div>
      <br></br>
      {sectionDetail.map((x, index) => (
        <div key={x.ATDetKey}>
          <Row>
            <Col span={1}>{index + 1}</Col>
            <Col span={23}>
              <Typography style={{ margin: "0px" }}>
                <Paragraph style={{ margin: "0px" }}>
                  <Text strong>{x.ATDetTitle}</Text>
                  <Text strong style={{ float: "right" }}>
                    {calculatePercentage(x)} [{x.ATDetWeight}%]
                  </Text>
                </Paragraph>
                <Paragraph style={{ margin: "0px" }}>
                  {x.ATDetSubTitle}
                </Paragraph>
              </Typography>
            </Col>
          </Row>

          {/* Radio */}
          <Row
            style={{
              display: x.ATDetType === 1 ? "" : "none",
            }}
          >
            <Col span={1}></Col>
            <Col span={23}>
              <Form.Item
                name={x.ATDetKey + "-radiogroup"}
                initialValue={
                  sectionAnswer.length > 0
                    ? getTransformValue(x.ATDetKey, 1)
                    : 1
                }
              >
                <Radio.Group
                  name="radiogroup"
                  onChange={onChange}
                  disabled={
                    (isSelfAppraiseExp &&
                      (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                    isApproved
                  }
                >
                  <Space direction="vertical">
                    {getFilterAppraisalTemplateAnswerData(
                      x.AppraisalSubDetail
                    ).map((y) => (
                      <Radio
                        key={y.ATSubDetKey}
                        style={radioStyle}
                        value={y.ATSubDetSeqNo}
                      >
                        [{y.ATSubDetSeqNo}]&nbsp;
                        <span
                          style={{
                            width: "98%",
                            lineHeight: "normal",
                            whiteSpace: "normal",
                          }}
                        >
                          {y.ATSubDetDesc} &nbsp;
                          {getSelectionTag(
                            x.ATDetKey,
                            "user",
                            "blue",
                            y.ATSubDetSeqNo
                          )}
                          {getSelectionTag(
                            x.ATDetKey,
                            "aprlv1",
                            "geekblue",
                            y.ATSubDetSeqNo
                          )}
                          {getSelectionTag(
                            x.ATDetKey,
                            "aprlv2",
                            "purple",
                            y.ATSubDetSeqNo
                          )}
                        </span>
                      </Radio>
                    ))}
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Switch */}
          <Row
            style={{
              display: x.ATDetType === 2 ? "" : "none",
            }}
          >
            <Col span={1}></Col>
            <Col span={5}>
              <Form.Item
                name={x.ATDetKey + "-switch"}
                valuePropName="checked"
                initialValue={
                  sectionAnswer.length > 0
                    ? getTransformValue(x.ATDetKey, 2)
                    : false
                }
              >
                <Switch
                  checkedChildren={t("general.yes")}
                  unCheckedChildren={t("general.no")}
                  onChange={onChange}
                  disabled={
                    (isSelfAppraiseExp &&
                      (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                    isApproved
                  }
                />
              </Form.Item>
              <div style={{ width: 205, paddingBottom: 12 }}>
                {getSelectionTag(x.ATDetKey, "user", "blue")}
                {getSelectionTag(x.ATDetKey, "aprlv1", "geekblue")}
                {getSelectionTag(x.ATDetKey, "aprlv2", "purple")}
              </div>
            </Col>
          </Row>

          {/* Slider */}
          <Row
            style={{
              display: x.ATDetType === 3 ? "" : "none",
            }}
          >
            <Col span={1}></Col>
            <Col span={10}>
              <Form.Item
                name={x.ATDetKey + "-slider"}
                initialValue={
                  sectionAnswer.length > 0
                    ? getTransformValue(x.ATDetKey, 3)
                    : 1
                }
              >
                <Slider
                  min={1}
                  max={10}
                  marks={marks}
                  onAfterChange={onChange}
                  disabled={
                    (isSelfAppraiseExp &&
                      (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                    isApproved
                  }
                  tooltip={{
                    getPopupContainer: () =>
                      document.getElementById("scroll-container"),
                  }}
                />
              </Form.Item>
              <div style={{ paddingBottom: 12 }}>
                {getSelectionTag(x.ATDetKey, "user", "blue")}
                {getSelectionTag(x.ATDetKey, "aprlv1", "geekblue")}
                {getSelectionTag(x.ATDetKey, "aprlv2", "purple")}
              </div>
            </Col>
          </Row>

          {/* Checkbox */}
          <Row
            style={{
              display: x.ATDetType === 4 ? "" : "none",
            }}
          >
            <Col span={1}></Col>
            <Col span={23}>
              <Form.Item
                name={x.ATDetKey + "-checkbox"}
                initialValue={
                  sectionAnswer.length > 0
                    ? getTransformValue(x.ATDetKey, 4)
                    : []
                }
              >
                <Checkbox.Group
                  style={{ width: "100%", display: "contents" }}
                  onChange={onChange}
                  disabled={
                    (isSelfAppraiseExp &&
                      (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                    isApproved
                  }
                >
                  {getFilterAppraisalTemplateAnswerData(
                    x.AppraisalSubDetail
                  ).map((y) => (
                    <div key={y.ATSubDetKey}>
                      <Checkbox style={checkBoxStyle} value={y.ATSubDetKey}>
                        {y.ATSubDetDesc}
                      </Checkbox>
                      &nbsp;
                      {getSelectionTag(
                        x.ATDetKey,
                        "user",
                        "blue",
                        y.ATSubDetKey
                      )}
                      {getSelectionTag(
                        x.ATDetKey,
                        "aprlv1",
                        "geekblue",
                        y.ATSubDetKey
                      )}
                      {getSelectionTag(
                        x.ATDetKey,
                        "aprlv2",
                        "purple",
                        y.ATSubDetKey
                      )}
                      <br></br>
                      <br></br>
                    </div>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>

          {/* Text Area */}
          <Row
            style={{
              display: x.ATDetComment ? "" : "none",
            }}
          >
            <Col span={24} offset={0}>
              <Form.Item
                label={t("appraisalTemplateForm.comments")}
                name={x.ATDetKey + "-ATDetComments"}
                rules={[
                  {
                    max: 500,
                    message: t(
                      "appraisalTemplateForm.comments_max_length_validation"
                    ),
                  },
                ]}
                initialValue={
                  sectionAnswer.length > 0
                    ? sectionAnswer.find((z) => z.ATDetKey === x.ATDetKey)
                        .Comment
                    : x.ATDetComments
                }
              >
                <TextArea
                  onChange={onChange}
                  placeholder={t("appraisalTemplateForm.comments_placeholder")}
                  autoSize={{ minRows: 2, maxRows: 6 }}
                  disabled={
                    (isSelfAppraiseExp &&
                      (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                    isApproved
                  }
                />
              </Form.Item>
              {getSelectionCommentTag(x.ATDetKey, "user", "blue")}
              {getSelectionCommentTag(x.ATDetKey, "aprlv1", "geekblue")}
              {getSelectionCommentTag(x.ATDetKey, "aprlv2", "purple")}
            </Col>
          </Row>
          <Divider></Divider>
        </div>
      ))}

      <Row
        style={{
          display: sectionHeader.ATDetComment ? "" : "none",
        }}
      >
        <Col span={24} offset={0}>
          <Form.Item
            label={t("appraisalTemplateForm.section_comments")}
            name={sectionHeader.ATDetKey + "-ATDetComments"}
            rules={[
              {
                max: 500,
                message: t(
                  "appraisalTemplateForm.comments_max_length_validation"
                ),
              },
            ]}
            initialValue={
              sectionAnswer.length > 0
                ? sectionAnswer.find(
                    (z) => z.ATDetKey === sectionHeader.ATDetKey
                  ).Comment
                : sectionHeader.ATDetComments
            }
          >
            <TextArea
              onChange={onChange}
              placeholder={t("appraisalTemplateForm.comments_placeholder")}
              autoSize={{ minRows: 2, maxRows: 6 }}
              disabled={
                (isSelfAppraiseExp &&
                  (isReviewExp || !(isLv1Appraiser || isLv2Appraiser))) ||
                isApproved
              }
            />
          </Form.Item>
          {getSelectionCommentTag(sectionHeader.ATDetKey, "user", "blue")}
          {getSelectionCommentTag(sectionHeader.ATDetKey, "aprlv1", "geekblue")}
          {getSelectionCommentTag(sectionHeader.ATDetKey, "aprlv2", "purple")}
        </Col>
      </Row>
    </Form>
  );
};

ManageAppraisalTemplatePreviewForm.propTypes = {
  sectionHeader: PropTypes.object.isRequired,
  sectionDetail: PropTypes.array.isRequired,
  sectionAnswer: PropTypes.array,
  sectionTag: PropTypes.array,
  onDataChanged: PropTypes.func,
  appraisalSubmission: PropTypes.object,
  isSelfAppraiseExp: PropTypes.bool,
  isReviewExp: PropTypes.bool,
  isLv1Appraiser: PropTypes.bool,
  isLv2Appraiser: PropTypes.bool,
  isApproved: PropTypes.bool,
};

export default ManageAppraisalTemplatePreviewForm;
