import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "appraisalsubmissionapproval/";

export function getPendingApprovalAppraisalSubmissions() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalAppraisalSubmissions",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedAppraisalSubmissions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedAppraisalSubmissions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidAppraisalSubmissions() {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidAppraisalSubmissions",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledAppraisalSubmissions(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledAppraisalSubmissions",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveAppraisalSubmissions(appraisalSubmission) {
  return axios({
    method: "POST",
    url: base + "ApproveAppraisalSubmissions",
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then()
    .catch(handleError);
}

export function rejectAppraisalSubmissions(
  appraisalSubmission,
  rejectReason,
  isAllowAmend
) {
  return axios({
    method: "POST",
    url: base + "RejectAppraisalSubmissions",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: appraisalSubmission,
  })
    .then()
    .catch(handleError);
}

export function voidAppraisalSubmissions(appraisalSubmission, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidAppraisalSubmissions",
    headers: authHeader(),
    params: { reason: voidReason },
    data: appraisalSubmission,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledAppraisalSubmissions(appraisalSubmission) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledAppraisalSubmissions",
    headers: authHeader(),
    data: appraisalSubmission,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledAppraisalSubmissions(
  appraisalSubmission,
  rejectReason
) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledAppraisalSubmissions",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: appraisalSubmission,
  })
    .then()
    .catch(handleError);
}
