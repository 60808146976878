export default function ClaimTypeModel({
  claimTypeKey = -1,
  claimTypeCode = "",
  claimTypeDesc = "",
  defaultCost = 0,
  payKey = -1,
  claimTypeColor = "",
  claimTypeIcon = "",
  active = true,
  rcdType = 0,
  rcdTypeDesc = "User",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  isMedicalClaim = false,
  isBillable = false,
  isRequiredAuth = false,
  isClaimable = true,
  isDentalClaim = false,
  claimTypeLimits = [],
  rowVersion = null,
  checkedOUList = [],
}) {
  return Object.freeze({
    ClaimTypeKey: claimTypeKey,
    ClaimTypeCode: claimTypeCode,
    ClaimTypeDesc: claimTypeDesc,
    DefaultCost: defaultCost,
    PayKey: payKey,
    ClaimTypeColor: claimTypeColor,
    ClaimTypeIcon: claimTypeIcon,
    Active: active,
    RcdType: rcdType,
    RcdTypeDesc: rcdTypeDesc,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    IsMedicalClaim: isMedicalClaim,
    IsBillable: isBillable,
    IsRequiredAuth: isRequiredAuth,
    IsClaimable: isClaimable,
    IsDentalClaim: isDentalClaim,
    ClaimTypeLimits: claimTypeLimits,
    RowVersion: rowVersion,
    checkedOUList: checkedOUList,
  });
}
