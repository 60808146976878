import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "announcement/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export function getAllAnnouncements() {
  return axios({
    method: "GET",
    url: base + "GetAllAnnouncements",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllPublishedAnnouncements() {
  return axios({
    method: "GET",
    url: base + "GetAllPublishedAnnouncements",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAnnouncement(announcementKey) {
  return axios({
    method: "GET",
    url: base + "GetAnnouncement",
    headers: authHeader(),
    params: { key: announcementKey, environment: env },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addAnnouncement(announcement) {
  return axios({
    method: "POST",
    url: base + "AddAnnouncement",
    headers: authHeader(),
    data: announcement,
  })
    .then()
    .catch(handleError);
}

export function updateAnnouncement(announcement) {
  return axios({
    method: "PUT",
    url: base + "UpdateAnnouncement",
    headers: authHeader(),
    params: { key: announcement.AnnouncementKey },
    data: announcement,
  })
    .then()
    .catch(handleError);
}

export function deleteAnnouncement(announcementKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteAnnouncement",
    headers: authHeader(),
    params: { key: announcementKey },
  })
    .then()
    .catch(handleError);
}
