export function setListHeaderStyle(isFromAdmin) {
  const antListHeader = document.querySelector('.ant-list-header');
    if (antListHeader && isFromAdmin) {
      const gradientColor1 = '#e6f4ff'.replace(/["']/g, '');
      const gradientColor2 = '#e6f4ff'.replace(/["']/g, '');

      antListHeader.style.setProperty(
        '--gradientColor1', gradientColor1
      );
      antListHeader.style.setProperty(
        '--gradientColor2', gradientColor2
      );
    }
}