import React from "react";
import PropTypes from "prop-types";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { List, Button, Skeleton } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function EmpyList({
  dataSource,
  title,
  desc_name1,
  desc_value1,
  desc_name2,
  desc_value2,
  desc_name3,
  desc_value3,
  content,
  onAdd,
  onEdit,
  onDelete,
}) {
  const { t } = useTranslation();

  return (
    <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      dataSource={dataSource}
      footer={
        <div>
          <Button onClick={onAdd} type="primary">
            {t("general.new")}
          </Button>
        </div>
      }
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button
              key={item}
              type="link"
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                onEdit(item);
              }}
            />,
            <Button
              key={item}
              type="link"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                onDelete(item);
              }}
            />,
          ]}
        >
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta
              title={item[title]}
              description={
                <div>
                  <p style={{ margin: "0" }}>
                    {title === "EmpyChildName"
                      ? desc_name1 +
                        " : " +
                        (item[desc_value1].toUpperCase() === "HIGHEDU"
                          ? "High Education"
                          : item[desc_value1].toUpperCase() === "FORM6"
                          ? "Secondary (Form 6)"
                          : item[desc_value1].toUpperCase() === "SECEDU"
                          ? "Secondary"
                          : item[desc_value1].toUpperCase() === "PRIEDU"
                          ? "Primary"
                          : item[desc_value1].toUpperCase() === "PRESCHL"
                          ? "Preschool"
                          : item[desc_value1].toUpperCase() === "OTHERS"
                          ? "Others"
                          : item[desc_value1].toUpperCase() === "NA"
                          ? "Not Available"
                          : item[desc_value1])
                      : title === "Attachment"
                      ? desc_name1 + " : "
                      : desc_name1 + " : " + item[desc_value1]}
                    {title === "Attachment" ? (
                      <a href={item["SecuredURL"]}>{item[desc_value1]}</a>
                    ) : (
                      ""
                    )}
                  </p>
                  <p
                    style={{ width: "100%", margin: "0" }}
                    hidden={
                      title === "EmpyChildName"
                        ? item[desc_value1].toUpperCase() === "NA"
                          ? true
                          : false
                        : false
                    }
                  >
                    {desc_name2 + " : " + item[desc_value2]}
                  </p>
                  <p
                    style={{ width: "100%", margin: "0" }}
                    hidden={title === "EmpyChildName" ? false : true}
                  >
                    {desc_name3 + " : " + item[desc_value3]}
                  </p>
                </div>
              }
            />
            <div>
              {content === ""
                ? item[content]
                : dayjs(item[content], "YYYY/MM/DD").format("DD/MM/YYYY")}
            </div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
}

EmpyList.propTypes = {
  dataSource: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  desc_name1: PropTypes.string.isRequired,
  desc_value1: PropTypes.string.isRequired,
  desc_name2: PropTypes.string.isRequired,
  desc_value2: PropTypes.string.isRequired,
  desc_name3: PropTypes.string.isRequired,
  desc_value3: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
