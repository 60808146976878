import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "empygrade/";

export function getEmpyGrade() {
  return axios({
    method: "GET",
    url: base + "GetEmpyGrade",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getEmpyPosition() {
  return axios({
    method: "GET",
    url: base + "GetEmpyPosition",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}
