import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "restdayprofile/";

export function getAllRestDayProfiles() {
  return axios({
    method: "GET",
    url: base + "GetAllRestDayProfiles",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getRestDayProfile(userKey) {
  return axios({
    method: "GET",
    url: base + "GetRestDayProfile",
    headers: authHeader(),
    params: { key: userKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addRestDayProfile(restdayprofile) {
  return axios({
    method: "POST",
    url: base + "AddRestDayProfile",
    headers: authHeader(),
    data: restdayprofile,
  })
    .then()
    .catch(handleError);
}

export function updateRestDayProfile(restdayprofile) {
  return axios({
    method: "PUT",
    url: base + "UpdateRestDayProfile",
    headers: authHeader(),
    params: { key: restdayprofile.WDKey },
    data: restdayprofile,
  })
    .then()
    .catch(handleError);
}

export function deleteRestDayProfile(wdKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteRestDayProfile",
    headers: authHeader(),
    params: { key: wdKey },
  })
    .then()
    .catch(handleError);
}
