import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getEmpyInfo, getYearMonthParam } from "../../api/reportApi";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd";
import { SelectionInput } from "../common/SelectionInput";
import "../../styles/report.scss";
import { useTranslation } from "react-i18next";

export default function StaffEAForm() {
  const { t } = useTranslation();
  const REPORT_NAME = "Staff EA Form";

  const [year, setYear] = useState();
  const [empyInfo, setEmpyInfo] = useState(null);
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [dsYearOptions, setDsYearOptions] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [isReportGenerating, setReportGenerating] = useState(false);
  const [language, setLanguage] = useState();
  const [dsLanguageOptions, setDSLanguageOptions] = useState([]);

  const onYrChange = (value) => {
    setYear(value);
  };

  const onLanguageChange = (value) => {
    setLanguage(value);
  };

  const generateReport = (e) => {
    e.preventDefault();
    setReportGenerate(true);
    setReportGenerating(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );
    setReportParam({
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      CompKey: empyInfo.CompKey,
      OUKey: empyInfo.OUKey,
      Year: year,
      Staff: empyInfo.EmpyKey,
      Status: "",
      EAName: "",
      EAPost: "",
      EAAlamat: "",
      EAPhone: "",
      DefCompany: empyInfo.CompKey,
      EACawangan: "",
      CntOU: "",
      Date: false,
      IsESS: true,
      Language: language,
    });
  };

  const validate = (year) => {
    var valid = false;
    if (year > 0) {
      valid = true;
    }
    setReportParamValid(valid);
  };

  useEffect(() => {
    validate(year);
  }, [year]);

  useEffect(() => {
    let promises = [getYearMonthParam("E"), getEmpyInfo()];

    Promise.all(promises)
      .then((data) => {
        const languageResult = [];
        languageResult.push(
          {
            text: "Bahasa Malaysia",
            value: "BM",
          },
          {
            text: "English",
            value: "EN",
          }
        );
        if (data[0] && data[0].length > 0) {
          const result = [];
          const map = new Map();
          for (const item of data[0]) {
            if (!map.has(item.Year)) {
              map.set(item.Year, true);
              result.push({
                text: item.Year,
                value: item.Year,
              });
            }
          }
          setDsYearOptions(result);
          setYear(result[result.length - 1].value);
        }
        setEmpyInfo(data[1] ? data[1][0] : {});
        setDSLanguageOptions(languageResult);
        setLanguage("BM");
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>

        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.languange")}</label>
          <br />
          <SelectionInput
            value={language}
            ref={React.createRef()}
            data={dsLanguageOptions}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.languange_placeholder")}
            onChange={onLanguageChange}
            style={{ width: 200 }}
          />
          <label>{t("general.year")}</label>
          <br />
          <SelectionInput
            value={year}
            ref={React.createRef()}
            data={dsYearOptions}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.year_placeholder_2")}
            onChange={onYrChange}
            style={{ width: 200 }}
          />
          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
