import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { API_REPORT_ROOT } from "../../api/api-config";
import { v4 as uuidv4 } from "uuid";

export function ReportViewer({
  Collapsed,
  ReportGenerate,
  ReportName,
  ReportParam,
  CustomStyle,
  setReportGenerating,
  setReportGenerate,
}) {
  let reportView = {
    width: Collapsed ? "100%" : "calc(100% - 260px)",
    height: "calc(100vh - 205px)",
    overflow: "hidden",
    fontFamily: "Verdana, Arial",
  };

  if (CustomStyle) {
    reportView = {
      width: "100%",
      height: "calc(100vh - 200px)",
      overflow: "hidden",
      fontFamily: "Verdana, Arial",
    };
  }
  const TELERIK_REPORT_SCALEMODE_FIT_PAGE_WIDTH = "FIT_PAGE_WIDTH";
  const uuid = useState(uuidv4());

  useEffect(() => {
    window.jQuery("#reportViewer1").telerik_ReportViewer({
      serviceUrl: API_REPORT_ROOT + "reports",
      //templateUrl: "/assets/telerikReportViewerTemplate.html", // <- not using this template
      reportSource: {
        //Quarto set report in this format: reportName + clientID + clientKey + userKey + langKey + uuid
        report: "",
        parameters: {},
      },
      scaleMode: TELERIK_REPORT_SCALEMODE_FIT_PAGE_WIDTH,
      scale: 1.0,
      viewMode: "PRINT_PREVIEW",
      printMode: "SPECIFIC",
      sendEmail: { enabled: true },
      ready: function () {},
      renderingBegin: function () {
        //move this set parameter to rendering begin due to it will trigger twice if only set after page ready.
        if (setReportGenerating) {
          setReportGenerating(false);
          setReportGenerate(false);
        }
        //window.jQuery(".trv-error-pane").toggle();
      },
      renderingEnd: function () {},
      pageReady: function () {
        // setTimeout(function() {
        //   window.jQuery(".trv-error-pane").toggle();
        // }, 3000);
      },
      error: function () {
        if (setReportGenerating) {
          setReportGenerating(false);
        }
      },
    });
  }, [Collapsed, setReportGenerating, setReportGenerate]);

  useEffect(() => {
    if (ReportGenerate) {
      var viewer = window.jQuery("#reportViewer1").data("telerik_ReportViewer");
      viewer.reportSource({
        report: ReportName + "#" + uuid,
        parameters: ReportParam,
      });
    }
  }, [ReportGenerate, ReportName, ReportParam, uuid]);

  return <div id="reportViewer1" style={reportView} />;
}

ReportViewer.propTypes = {
  Collapsed: PropTypes.bool.isRequired,
  ReportName: PropTypes.string.isRequired,
  ReportParam: PropTypes.object.isRequired,
  ReportGenerate: PropTypes.bool.isRequired,
  CustomStyle: PropTypes.bool.isRequired,
  setReportGenerating: PropTypes.func,
  setReportGenerate: PropTypes.func,
};
