import React, { useState, useEffect } from "react";
import ManageRoomRequisitionForm from "../../forms/RoomReq/ManageRoomRequisitionForm";
import RoomRequisitionModel from "../../model/RoomReq/RoomRequisitionModel";
import {
  getRoomRequisitionByKey,
  addRoomRequisition,
  updateRoomRequisition,
  deleteRoomRequisition,
  withdrawRoomRequisition,
  requestCancelRoomRequisition,
  assignRoomAndExpenses,
  completeRoomRequisition,
} from "../../../api/roomRequisitionApi";
import { getAvailableRoomsForAssignment } from "../../../api/roomApi";
import { getUserCompCurrency } from "../../../api/currApi";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import {
  Status,
  isStatusOpen,
  isStatusSubmitted,
} from "../../model/common/Status";
import { isLoginUser, isEditor } from "../../common/ValidateLoginUser";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newRoomRequisition = RoomRequisitionModel({});

const ManageRoomRequisitionPage = ({ history, setIsDirty }) => {
  const { t } = useTranslation();
  const ROOM_REQ_FORM_MESSAGE = {
    ROOM_REQ_SAVED: t("roomRequisitionForm.room_req_saved"),
    ROOM_REQ_SUBMITTED: t("roomRequisitionForm.room_req_submitted"),
    ROOM_REQ_WITHDRAWN: t("roomRequisitionForm.room_req_withdrawn"),
    ROOM_REQ_DELETED: t("roomRequisitionForm.room_req_deleted"),
    ROOM_REQ_CANCEL_REQUEST: t("roomRequisitionForm.room_req_cancel_request"),
    ROOM_REQ_COMPLETE: t("roomRequisitionForm.room_req_complete"),
    UNKNOWN_STATUS: t("general.unknown_status"),
  };
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [editItem, setEditItem] = useState(newRoomRequisition);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveAndNew, setIsSaveAndNew] = useState(false);
  const [allRooms, setAllRooms] = useState([]);
  const [userCompCurr, setUserCompCurr] = useState({});
  const [isOwner, setIsOwner] = useState(true);
  const [isViewOnly, setIsViewOnly] = useState(false);
  const [isAssignor, setIsAssignor] = useState(false);
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  function onCancelled() {
    if (location.state) {
      if (location.state.fromApproval) {
        history("/home/meetingroomrequisitionapproval");
      } else if (location.state.fromVoid) {
        history("/home/meetingroomrequisitionvoid");
      } else if (location.state.fromCancel) {
        history("/home/meetingroomrequisitioncancellationapproval");
      } else if (location.state.fromAssignment) {
        history("/home/meetingroomassignment");
      } else if (location.state.fromAdmin) {
        history("/home/adminportal/facilities/meetingroomdisplay", {state: location.state});
      } else {
        history("/home/meetingroomrequisitiondisplay");
      }
    } else {
      history("/home/meetingroomrequisitiondisplay");
    }
  }

  function onSaved(roomRequisitionData) {
    setIsLoading(true);

    let dataSubmit = {
      ...editItem,
      ...roomRequisitionData,
      Remarks: roomRequisitionData.Remarks.trim(),
    };

    let upsertRoomRequisitionData =
      dataSubmit.RoomRequisitionKey !== -1
        ? updateRoomRequisition
        : addRoomRequisition;

    return upsertRoomRequisitionData(dataSubmit)
      .then((result) => {
        let msgSuccess = isStatusOpen(result.Status)
          ? ROOM_REQ_FORM_MESSAGE.ROOM_REQ_SAVED
          : isStatusSubmitted(result.Status)
          ? ROOM_REQ_FORM_MESSAGE.ROOM_REQ_SUBMITTED
          : ROOM_REQ_FORM_MESSAGE.UNKNOWN_STATUS;
        toast.success(msgSuccess);
        if (isSaveAndNew) {
          setIsDirty(false);
          history("/home/meetingroomrequisition/roomtrans/", {
            state: { force: true },
          });
          loadPageData(undefined);
        } else {
          setIsDirty(false);
          history(
            "/home/meetingroomrequisition/roomtrans/" +
              result.RoomRequisitionKey,
            { state: { force: true } }
          );
          loadPageData(result.RoomRequisitionKey);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onDelete(roomRequisitionData) {
    setIsLoading(true);
    return deleteRoomRequisition({ ...editItem, ...roomRequisitionData })
      .then(() => {
        toast.success(ROOM_REQ_FORM_MESSAGE.ROOM_REQ_DELETED);
        history("/home/meetingroomrequisition/roomtrans/", {
          state: { force: true },
        });
        loadPageData(undefined);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onWithdraw(roomRequisitionData) {
    setIsLoading(true);
    return withdrawRoomRequisition({ ...editItem, ...roomRequisitionData })
      .then(() => {
        toast.success(ROOM_REQ_FORM_MESSAGE.ROOM_REQ_WITHDRAWN);
        loadPageData(editItem.RoomRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onRequestCancel(roomRequisitionData, reason) {
    setIsLoading(true);
    return requestCancelRoomRequisition(
      {
        ...editItem,
        ...roomRequisitionData,
      },
      reason
    )
      .then(() => {
        toast.success(ROOM_REQ_FORM_MESSAGE.ROOM_REQ_CANCEL_REQUEST);
        loadPageData(editItem.RoomRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onAssignRoomAndExpenses(roomRequisitionData) {
    setIsLoading(true);
    return assignRoomAndExpenses({
      ...editItem,
      ...roomRequisitionData,
    })
      .then(() => {
        setIsDirty(false);
        toast.success(ROOM_REQ_FORM_MESSAGE.ROOM_REQ_SAVED);
        loadPageData(editItem.RoomRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function onCompleteRoomRequisition(roomRequisitionData) {
    setIsLoading(true);
    return completeRoomRequisition({
      ...editItem,
      ...roomRequisitionData,
    })
      .then(() => {
        setIsDirty(false);
        toast.success(ROOM_REQ_FORM_MESSAGE.ROOM_REQ_COMPLETE);
        loadPageData(editItem.RoomRequisitionKey);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.ExceptionMessage);
      });
  }

  function loadPageData(editKey) {
    setIsLoading(true);
    let promises = [];
    promises.push(
      getAvailableRoomsForAssignment(editKey ? editKey : -1),
      getUserCompCurrency()
    );

    if (editKey) promises.push(getRoomRequisitionByKey(editKey));

    Promise.all(promises)
      .then((values) => {
        setAllRooms(values[0] ? values[0] : []);
        setUserCompCurr(values[1] ? values[1] : {});
        let roomRequisitionToEdit = values[2];

        if (roomRequisitionToEdit) {
          roomRequisitionToEdit.StatusDesc =
            Status[roomRequisitionToEdit.Status];
          roomRequisitionToEdit.ReqDate = [
            dayjs(roomRequisitionToEdit.FromReqDate),
            dayjs(roomRequisitionToEdit.ToReqDate),
          ];
          roomRequisitionToEdit.TransDate = new Date(
            roomRequisitionToEdit.TransDate
          );

          setEditItem(roomRequisitionToEdit);
          setIsOwner(
            isLoginUser(roomRequisitionToEdit.UserKey) ||
              isEditor(roomRequisitionToEdit.CreatedBy)
          );
          setIsAssignor(roomRequisitionToEdit.IsAssignor);
        } else {
          setEditItem(newRoomRequisition);
          setIsOwner(true);
          setIsAssignor(false);
        }

        setIsLoading(false);
        setIsFormInitialized(!isFormInitialized);
        setIsViewOnly((location.state && location.state.fromAdmin)
                        ? location.state.fromAdmin : false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.ExceptionMessage);
      });
  }

  return (
    <ManageRoomRequisitionForm
      isLoading={isLoading}
      loadPageData={loadPageData}
      onSubmitted={onSaved}
      onWithdraw={onWithdraw}
      onCancelled={onCancelled}
      onDelete={onDelete}
      onRequestCancel={onRequestCancel}
      onAssignRoomAndExpenses={onAssignRoomAndExpenses}
      onCompleteRoomRequisition={onCompleteRoomRequisition}
      editItem={editItem}
      onIsSaveAndNewChange={setIsSaveAndNew}
      isSaveAndNewData={isSaveAndNew}
      allRooms={allRooms}
      userCompCurr={userCompCurr}
      isOwner={isOwner}
      isViewOnly={isViewOnly}
      isAssignor={isAssignor}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
};

ManageRoomRequisitionPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageRoomRequisitionPage;
