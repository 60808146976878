import { handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const base = API_ROOT + "leavemigration/";

export function importMigrationData(importDataUrl) {
  let env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

  return axios({
    method: "POST",
    url: base + "ImportMigrationData",
    headers: authHeader(),
    params: { importDataUrl: importDataUrl, environment: env },
  })
    .then()
    .catch(handleError);
}
