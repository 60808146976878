import React, { useState, useEffect } from "react";
import * as apapi from "../../../api/appraisalApi";
import { toast } from "react-toastify";
import { AppraisalForm } from "../../forms/Master/AppraisalForm";
import PropTypes from "prop-types";
import { showDeleteConfirm } from "../../common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

export default function AppraisalPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [appraisal, setAppraisal] = useState([]);
  const [rowSelected, setRowSelected] = useState({});
  const location = useLocation();
  let defaultPagination = location.state
    ? location.state.currentPage
      ? location.state.currentPage
      : 1
    : 1;
  const [currentPage, setCurrentPage] = useState(defaultPagination);

  useEffect(getAppraisal, []);

  function getAppraisal() {
    apapi
      .getAllAppraisals()
      .then((result) => {
        setIsLoading(false);
        setAppraisal(result);
      })
      .catch((error) => {
        setIsLoading(false);
        setAppraisal([]);
        showErrorMessage(t("general.fail_to_load_record") + error.message);
      });
  }

  function addNewItem() {
    history("/home/appraisals/appraisal", {
      state: { currentPage: currentPage },
    });
  }

  function editItem(appraisalKey) {
    history("/home/appraisals/appraisal/" + appraisalKey, {
      state: { currentPage: currentPage },
    });
  }

  function onDelete(appraisalKey) {
    showDeleteConfirm("Appraisal", appraisalKey, deleteAppraisal, t);
  }

  function deleteAppraisal(appraisalKey) {
    setIsLoading(true);
    apapi
      .deleteAppraisal(appraisalKey)
      .then(() => {
        const newData = appraisal.filter((item) => item.APKey !== appraisalKey);
        setAppraisal(newData);
        setIsLoading(false);
        toast.success(t("general.record_deleted_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_delete_record") + error.message);
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function onRowSelected(value) {
    if (value.UpdatedDate) {
      value.UpdatedOn = value.UpdatedDate;
    }
    if (value.CreatedDate) {
      value.CreatedOn = value.CreatedDate;
    }
    setRowSelected(value);
  }

  return (
    <AppraisalForm
      isLoading={isLoading}
      appraisal={appraisal}
      onAdd={addNewItem}
      onEdit={editItem}
      onDelete={onDelete}
      onRowSelected={onRowSelected}
      rowSelected={rowSelected}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
}

AppraisalPage.propTypes = {
  history: PropTypes.func.isRequired,
};
