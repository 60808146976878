import React from "react";
import { FileTextOutlined } from "@ant-design/icons";
import { Input, Collapse, Spin, Card, Avatar, List } from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Search } = Input;
const { Panel } = Collapse;
const { Meta } = Card;

export const ReportForm = ({ isLoading, reports, onEdit, filterReport }) => {
  const { t } = useTranslation();

  function onReportClick(reportRoute) {
    onEdit(reportRoute);
  }

  function onSearch(data) {
    filterReport(data);
  }

  const ListItem = (data) => (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 3,
        xl: 4,
        xxl: 6,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          onClick={() => {
            onReportClick(item.route);
          }}
        >
          <Card
            bodyStyle={{ display: "none" }}
            bordered={false}
            hoverable
            cover={
              <Meta
                avatar={
                  <Avatar
                    shape="square"
                    size={48}
                    icon={<FileTextOutlined />}
                    style={{ backgroundColor: "#87d068" }}
                  />
                }
                description={item.name}
              />
            }
          />
        </List.Item>
      )}
    />
  );

  const getRegionReports = () => {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth.Region === "I") {
      //Hide EA form if Indonesian Version
      return reports.filter((x) => x.menuParentId === 49 && x.id !== 52);
    }
    return reports.filter((x) => x.menuParentId === 49);
  };

  return (
    <Card
      title={<span className="form-title">{t("report.report")}</span>}
      extra={
        <Search
          placeholder={t("report.report_placeholder")}
          onSearch={(value) => onSearch(value)}
          style={{ width: 300 }}
        />
      }
    >
      <Spin spinning={isLoading}>
        <Collapse
          bordered={false}
          defaultActiveKey={[
            "49",
            "50",
            "51",
            "103",
            "107",
            "123",
            "135",
            "137",
            "153",
          ]}
        >
          <Panel
            header={t("general.employee")}
            key="49"
            style={{
              display: reports.some((x) => x.menuParentId === 49) ? "" : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(getRegionReports())}
          </Panel>
          <Panel
            header={t("general.claim")}
            key="50"
            style={{
              display: reports.some((x) => x.menuParentId === 50) ? "" : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 50))}
          </Panel>
          <Panel
            header={t("general.leave")}
            key="51"
            style={{
              display: reports.some((x) => x.menuParentId === 51) ? "" : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 51))}
          </Panel>
          <Panel
            header={t("general.facilities_management")}
            key="103"
            style={{
              display: reports.some((x) => x.menuParentId === 103)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 103))}
          </Panel>
          <Panel
            header={t("general.appraisal")}
            key="107"
            style={{
              display: reports.some((x) => x.menuParentId === 107)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 107))}
          </Panel>
          <Panel
            header={t("general.training")}
            key="123"
            style={{
              display: reports.some((x) => x.menuParentId === 123)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 123))}
          </Panel>
          <Panel
            header={t("general.recruitment")}
            key="135"
            style={{
              display: reports.some((x) => x.menuParentId === 135)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 135))}
          </Panel>
          <Panel
            header={t("general.travel")}
            key="137"
            style={{
              display: reports.some((x) => x.menuParentId === 137)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 137))}
          </Panel>
          <Panel
            header={t("general.clinic")}
            key="153"
            style={{
              display: reports.some((x) => x.menuParentId === 153)
                ? ""
                : "none",
              backgroundColor: "#ffffff",
            }}
          >
            {ListItem(reports.filter((x) => x.menuParentId === 153))}
          </Panel>
        </Collapse>
      </Spin>
    </Card>
  );
};

ReportForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  filterReport: PropTypes.func.isRequired,
};

export default ReportForm;
