import React from "react";
import {
  Modal,
  Select,
  Row,
  Col,
  Switch,
  Input,
  Button,
  Checkbox,
  Form,
} from "antd";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const { Option } = Select;

export const APRBySubjectInput = ({
  isVisible,
  empys,
  allEmpys,
  selectedAPRConfg,
  onCancelled,
  onSaved,
  form,
  isActiveUser,
  onIsApplyToAllChange,
  isApplyToAllData,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let tempValue = Object.assign({}, values);

        if (typeof values.aprConfgApprovedBy1Key === "number") {
          values.aprConfgApprovedBy1Key = [values.aprConfgApprovedBy1Key];
        }

        tempValue.aprConfgApprovedBy1Key = values.aprConfgApprovedBy1Key
          .sort((a, b) => a - b)
          .map((empy) => {
            let check = allEmpys.filter(
              (item) => item.EmpyID + " - " + item.EmpyName === empy
            );
            if (check.length > 0) {
              return check[0].UserKey;
            } else {
              return empy;
            }
          });

        tempValue.aprConfgApprovedBy1Key.sort((a, b) => a - b);

        tempValue.aprConfgApprovedBy2Key = values.aprConfgApprovedBy2Key
          .sort((a, b) => a - b)
          .map((empy) => {
            let check = allEmpys.filter(
              (item) => item.EmpyID + " - " + item.EmpyName === empy
            );
            if (check.length > 0) {
              return check[0].UserKey;
            } else {
              return empy;
            }
          });

        tempValue.aprConfgApprovedBy2Key.sort((a, b) => a - b);

        tempValue.aprConfgValidatedByKeys = values.aprConfgValidatedByKeys
          .sort((a, b) => a - b)
          .map((empy) => {
            let check = allEmpys.filter(
              (item) => item.EmpyID + " - " + item.EmpyName === empy
            );
            if (check.length > 0) {
              return check[0].UserKey;
            } else {
              return empy;
            }
          });

        tempValue.aprConfgValidatedByKeys.sort((a, b) => a - b);
        onSaved(tempValue);
      })
      .catch(() => {});
  }

  function generateEmpyOption() {
    let dataOptions = [];
    if (empys && empys.length) {
      dataOptions = empys.map((empy) => (
        <Option key={empy.UserKey} value={empy.UserKey}>
          {empy.EmpyID + " - " + empy.EmpyName}
        </Option>
      ));
    }

    return dataOptions;
  }

  function checkInActive(userKey, userName) {
    let tempArray = [];

    userKey.forEach((key, index) => {
      const tempData = empys.filter((item) => item.UserKey === key);

      if (tempData.length === 0) {
        key = userName[index];
      }
      tempArray.push(key);
    });
    return tempArray;
  }

  function onChangeIsApplyToAll(e) {
    onIsApplyToAllChange(e.target.checked);
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("aprConfigForm.subject_approval_level")}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancelled}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Checkbox
          key="all"
          checked={isApplyToAllData}
          onChange={onChangeIsApplyToAll}
          style={{
            float: "left",
            marginTop: "5px",
            marginBottom: "5px",
            display:
              selectedAPRConfg.length > 0 &&
              selectedAPRConfg[0].Subject === "ORG"
                ? ""
                : "none",
          }}
        >
          {t("aprConfigForm.apply_to_all_subjects")}
        </Checkbox>,
        <Button key="back" onClick={onCancelled}>
          {t("general.cancel")}
        </Button>,
        <Button key="submit" type="primary" onClick={saveForm}>
          {t("general.save")}
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={t("aprLvlConfigForm.subject")}
              name="Subject"
              initialValue={
                selectedAPRConfg.length > 0
                  ? selectedAPRConfg[0].SubjectDesc === ""
                    ? undefined
                    : selectedAPRConfg[0].SubjectDesc
                  : undefined
              }
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("aprLvlConfigForm.approval_level")}
              name="AppLvl"
              initialValue={
                selectedAPRConfg.length > 0
                  ? selectedAPRConfg[0].SubjectAppLvl === 0
                    ? t("aprLvlConfigForm.not_required")
                    : selectedAPRConfg[0].SubjectAppLvl
                  : ""
              }
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item
              label={t("aprLvlConfigForm.L1_approver")}
              name="aprConfgApprovedBy1Key"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      (selectedAPRConfg[0].SubjectAppLvl === 1 ||
                        selectedAPRConfg[0].SubjectAppLvl === 2) &&
                      value.length === 0 &&
                      isActiveUser === true
                    )
                      return Promise.reject(
                        new Error(
                          t("aprLvlConfigForm.L1_approver_required_validation")
                        )
                      );

                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={
                selectedAPRConfg.length > 0
                  ? checkInActive(
                      selectedAPRConfg[0].AprConfgApprover[0]
                        .aprConfgApprovedBy1Key,
                      selectedAPRConfg[0].L1ApprovedBy
                    )
                  : undefined
              }
            >
              <Select
                showSearch
                mode={
                  selectedAPRConfg.length > 0
                    ? selectedAPRConfg[0].Subject === "ORG"
                      ? ""
                      : "multiple"
                    : "multiple"
                }
                placeholder={t("aprLvlConfigForm.L1_approver_placeholder")}
                optionFilterProp="children"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                disabled={
                  selectedAPRConfg.length > 0
                    ? selectedAPRConfg[0].SubjectAppLvl < 1
                      ? true
                      : false
                    : false
                }
              >
                {generateEmpyOption()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t("aprLvlConfigForm.L2_approver")}
              name="aprConfgApprovedBy2Key"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      selectedAPRConfg[0].SubjectAppLvl === 2 &&
                      value.length === 0 &&
                      isActiveUser === true
                    )
                      return Promise.reject(
                        new Error(
                          t("aprLvlConfigForm.L2_approver_required_validation")
                        )
                      );

                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={
                selectedAPRConfg.length > 0
                  ? checkInActive(
                      selectedAPRConfg[0].AprConfgApprover[0]
                        .aprConfgApprovedBy2Key,
                      selectedAPRConfg[0].L2ApprovedBy
                    )
                  : undefined
              }
            >
              <Select
                showSearch
                mode="multiple"
                placeholder={t("aprLvlConfigForm.L2_approver_placeholder")}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                disabled={
                  selectedAPRConfg.length > 0
                    ? selectedAPRConfg[0].SubjectAppLvl < 2
                      ? true
                      : false
                    : false
                }
              >
                {generateEmpyOption()}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row
          style={{
            display:
              selectedAPRConfg.length > 0 && selectedAPRConfg[0].IsValidateTrans
                ? ""
                : "none",
          }}
        >
          <Col span={24}>
            <Form.Item
              label={
                selectedAPRConfg.length > 0 &&
                (selectedAPRConfg[0].Subject === "VEH" ||
                  selectedAPRConfg[0].Subject === "ROOM")
                  ? t("aprLvlConfigForm.assignor")
                  : t("aprLvlConfigForm.validator")
              }
              name="aprConfgValidatedByKeys"
              rules={[
                {
                  validator: (_, value) => {
                    if (
                      selectedAPRConfg.length > 0 &&
                      selectedAPRConfg[0].IsValidateTrans &&
                      value.length === 0 &&
                      isActiveUser === true
                    )
                      return Promise.reject(
                        new Error(
                          selectedAPRConfg.length > 0 &&
                          (selectedAPRConfg[0].Subject === "VEH" ||
                            selectedAPRConfg[0].Subject === "ROOM")
                            ? t("aprLvlConfigForm.assignor_required_validation")
                            : t(
                                "aprLvlConfigForm.validator_required_validation"
                              )
                        )
                      );
                    return Promise.resolve();
                  },
                },
              ]}
              initialValue={
                selectedAPRConfg.length > 0
                  ? checkInActive(
                      selectedAPRConfg[0].AprConfgValidator[0]
                        .aprConfgValidatedByKeys,
                      selectedAPRConfg[0].ValidatedBy
                    )
                  : undefined
              }
            >
              <Select
                showSearch
                mode="multiple"
                placeholder={
                  selectedAPRConfg.length > 0 &&
                  (selectedAPRConfg[0].Subject === "VEH" ||
                    selectedAPRConfg[0].Subject === "ROOM")
                    ? t("aprLvlConfigForm.assignor_placeholder")
                    : t("aprLvlConfigForm.validator_placeholder")
                }
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              >
                {generateEmpyOption()}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("aprLvlConfigForm.email_notification")}
              name="EmailNotification"
              valuePropName="checked"
              initialValue={
                selectedAPRConfg.length > 0
                  ? selectedAPRConfg[0].EmailNotification
                  : false
              }
            >
              <Switch
                checkedChildren={t("general.yes")}
                unCheckedChildren={t("general.no")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

APRBySubjectInput.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  empys: PropTypes.array.isRequired,
  allEmpys: PropTypes.array.isRequired,
  selectedAPRConfg: PropTypes.array.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onSaved: PropTypes.func.isRequired,
  onIsApplyToAllChange: PropTypes.func.isRequired,
  isApplyToAllData: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  isActiveUser: PropTypes.bool,
  setIsDirty: PropTypes.func.isRequired,
};

export const APRBySubjectForm = APRBySubjectInput;
