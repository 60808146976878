import React, { useState, useEffect } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Card,
  Spin,
  DatePicker,
  Modal,
  Form,
} from "antd";
import { CurrExRateTable } from "../Master/CurrExRateTable";
import { CurrExRateForm } from "../Master/CurrExRateForm";
import PropTypes from "prop-types";
import ExRateCurrModel from "../../model/Master/ExRateCurrModel";
import { RowState } from "../../model/common/RowState";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const { MonthPicker } = DatePicker;
const { info, confirm } = Modal;
const newExRateCurr = ExRateCurrModel({});

const ExRateInputs = ({
  isLoading,
  exRate,
  currencies,
  defaultExDate,
  exDate,
  onSubmitted,
  onCancelled,
  onDataChanged,
  getCurrExRate,
  getLastMonthExRateCurrencies,
  checkLastMonthDataExists,
  form,
  setIsDirty,
  isDirty = false,
}) => {
  const { t } = useTranslation();
  const [formCurrExRate] = Form.useForm();
  const [isFormCurrExRateInitialized, setIsFormCurrExRateInitialized] =
    useState(false);
  useEffect(
    () => formCurrExRate.resetFields(),
    [isFormCurrExRateInitialized, formCurrExRate]
  );

  const formats = { cellSpacing: 20 };
  const [inputSelectionData, setInputSelectionData] = useState([]);
  const [showCurrencyForm, setShowCurrencyForm] = useState(false);
  const [selectedExRateCurrency, setSelectedExRateCurrency] = useState({});

  function getExRateDets() {
    return exRate.ExRateDets.filter((x) => x.RowState !== RowState.DELETED);
  }

  //filter out selected data
  function getCurrencySelectionData(selectedCurrKey) {
    return currencies.filter(
      (x) =>
        !exRate.ExRateDets.find(
          (y) =>
            y.CurrKey === x.Key &&
            y.CurrKey !== selectedCurrKey &&
            y.RowState !== RowState.DELETED
        )
    );
  }

  function onExDateChanged(value) {
    if (value) {
      if (isDirty === true) {
        showSaveConfirm(value);
      } else {
        getCurrExRate(value);
      }
    }
  }

  function showSaveConfirm(newExDate) {
    confirm({
      title: t("exRateForm.save_changes"),
      content: t("exRateForm.save_changes_message"),
      okText: t("general.yes"),
      okType: "danger",
      cancelText: t("general.no"),
      onOk() {
        document.getElementById("submitExRate").click();
      },
      onCancel() {
        getCurrExRate(newExDate);
      },
    });
  }

  function checkLastMonthData() {
    let currentExDate = form.getFieldValue("ExDate");

    if (currentExDate) {
      checkLastMonthDataExists(currentExDate).then((isExist) => {
        if (isExist) {
          showOverwriteConfirm();
        } else {
          info({
            title: t("exRateForm.record_does_not_exist"),
            content: t("exRateForm.no_record_from_last_month"),
            onOk() {},
          });
        }
      });
    }
  }

  function showOverwriteConfirm() {
    if (exRate.ExRateDets && exRate.ExRateDets.length > 0) {
      confirm({
        title: t("exRateForm.overwrite_confirmation"),
        content: t("exRateForm.overwrite_ex_rate_confirmation_message"),
        okText: t("general.yes"),
        okType: "danger",
        cancelText: t("general.no"),
        onOk() {
          copyFromLastMonth();
        },
        onCancel() {},
      });
    } else {
      copyFromLastMonth();
    }
  }

  function copyFromLastMonth() {
    let currentExDate = form.getFieldValue("ExDate");

    if (currentExDate) {
      getLastMonthExRateCurrencies(currentExDate);
    }
  }

  function addNewExRateCurrency() {
    setSelectedExRateCurrency(newExRateCurr);
    setInputSelectionData(getCurrencySelectionData(-1));
    setShowCurrencyForm(true);
    setIsFormCurrExRateInitialized(!isFormCurrExRateInitialized);
  }

  function editExRateCurrency(currKey) {
    let exRateCurr = exRate.ExRateDets.filter(
      (item) => item.CurrKey === currKey && item.RowState !== RowState.DELETED
    );
    let selectedCurr =
      exRateCurr && exRateCurr.length > 0 ? exRateCurr[0] : newExRateCurr;

    setSelectedExRateCurrency(selectedCurr);
    setInputSelectionData(getCurrencySelectionData(selectedCurr.CurrKey));
    setShowCurrencyForm(true);
    setIsFormCurrExRateInitialized(!isFormCurrExRateInitialized);
  }

  function deleteExRateCurrency(currKey) {
    //change state to DELETED for removal in db
    let newData = exRate.ExRateDets.map((x) => {
      return x.CurrKey === currKey && x.RowState !== RowState.DELETED
        ? { ...x, RowState: RowState.DELETED }
        : x;
    }).filter(
      //exclude those new record which is added and deleted before save the form
      (y) => !(y.ExRateDetKey === -1 && y.RowState === RowState.DELETED)
    );

    onDataChanged({ ...exRate, ExRateDets: newData });
  }

  function saveExRateCurrency(values) {
    let exRateCurrSubmit = {
      ...selectedExRateCurrency,
      CurrKey:
        values.Currency && values.Currency.value !== undefined
          ? values.Currency.value
          : -1,
      CurrCodeDesc:
        values.Currency && values.Currency.label !== undefined
          ? values.Currency.label
          : "",
      ExRateFunc: values.ExRateFunc ? values.ExRateFunc : 0,
    };

    let prevCurrKey =
      selectedExRateCurrency.CurrKey !== -1
        ? selectedExRateCurrency.CurrKey
        : exRateCurrSubmit.CurrKey;

    //upon save consider as MODIFIED even no changes on data, new row remain as ADDED
    if (exRateCurrSubmit) {
      let index = exRate.ExRateDets.findIndex(
        (x) => x.CurrKey === prevCurrKey && x.RowState !== RowState.DELETED
      );

      if (index !== -1) {
        let temp = exRate.ExRateDets.map((x) => {
          return x.CurrKey === prevCurrKey && x.RowState !== RowState.DELETED
            ? exRateCurrSubmit.RowState === RowState.ADDED
              ? exRateCurrSubmit
              : { ...exRateCurrSubmit, RowState: RowState.MODIFIED }
            : x;
        });
        onDataChanged({ ...exRate, ExRateDets: temp });
      } else {
        //row state is ADDED by default
        onDataChanged({
          ...exRate,
          ExRateDets: [...exRate.ExRateDets, exRateCurrSubmit],
        });
      }

      setShowCurrencyForm(false);
    }
  }

  function cancelInputCurrency() {
    setShowCurrencyForm(false);
  }

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      if (exRate.ExRateDets && exRate.ExRateDets.length > 0) {
        onSubmitted(values);
      } else {
        form.setFieldsValue({
          ExDate: exDate,
        });
        toast.error(t("exRateForm.at_least_one_ex_rate_validation"));
      }
    });
  };

  const handleSubmitFailed = async () => {
    form.setFieldsValue({
      ExDate: exDate,
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onFinishFailed={handleSubmitFailed}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("exRateForm.currency_exchange_rate_setup")}
                </span>
              }
            >
              <Row gutter={formats.cellSpacing}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={<span>{t("general.year_month")}</span>}
                    name="ExDate"
                    rules={[
                      {
                        required: true,
                        message: t("general.year_month_required_validation"),
                      },
                    ]}
                    initialValue={
                      exRate.ExRateHdrKey !== -1 && exRate.ExDate
                        ? dayjs(new Date(exRate.ExDate))
                        : defaultExDate
                    }
                  >
                    <MonthPicker
                      allowClear={false}
                      placeholder={t("general.year_month")}
                      format="MM/YYYY"
                      onChange={onExDateChanged}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={formats.cellSpacing}>
                <Col xs={24} lg={24}>
                  <Form.Item
                    label={t("general.remarks")}
                    name="Remarks"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.remarks_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.remarks_max_length_validation"),
                      },
                    ]}
                    initialValue={exRate.Remarks}
                  >
                    <Input placeholder={t("general.remarks_placeholder")} />
                  </Form.Item>
                </Col>
              </Row>
              <CurrExRateTable
                data={getExRateDets()}
                onEdited={editExRateCurrency}
                onDeleted={deleteExRateCurrency}
              />
              <div style={{ marginTop: 16 }}>
                <Button type="primary" onClick={() => addNewExRateCurrency()}>
                  {t("general.add_new")}
                </Button>
                <Button
                  type="default"
                  style={{ marginLeft: 16 }}
                  onClick={checkLastMonthData}
                >
                  {t("exRateForm.copy_from_last_month")}
                </Button>
              </div>
              <CurrExRateForm
                isVisible={showCurrencyForm}
                exRateCurrency={selectedExRateCurrency}
                allCurrencies={currencies}
                onSaved={saveExRateCurrency}
                onCancelled={cancelInputCurrency}
                selectionData={inputSelectionData}
                setIsDirty={setIsDirty}
                form={formCurrExRate}
              />
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  id="submitExRate"
                >
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={exRate}
              showFooter={
                exRate.ExRateHdrKey !== -1 && exRate.ExRateHdrKey ? true : false
              }
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

ExRateInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  exRate: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  defaultExDate: PropTypes.instanceOf(dayjs).isRequired,
  exDate: PropTypes.instanceOf(dayjs).isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  getCurrExRate: PropTypes.func.isRequired,
  getLastMonthExRateCurrencies: PropTypes.func.isRequired,
  checkLastMonthDataExists: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  isDirty: PropTypes.bool,
};

export const ExRateForm = ExRateInputs;
