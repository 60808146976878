export default function ProfileChildModel({
  empyAprKey = -1,
  empyChildDetKey = -1,
  empyKey = -1,
  empyChildName = "",
  empyChildDOB = new Date(),
  eduLevel = "",
  schName = "",
  idNo = "",
  isSelect = false,
  rowState = 0,
}) {
  return Object.freeze({
    EmpyAprKey: empyAprKey,
    EmpyChildDetKey: empyChildDetKey,
    EmpyKey: empyKey,
    EmpyChildName: empyChildName,
    EmpyChildDOB: empyChildDOB,
    EduLevel: eduLevel,
    SchName: schName,
    IDNo: idNo,
    isSelect: isSelect,
    RowState: rowState,
  });
}
