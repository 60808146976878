import React, { useState } from "react";
import { EditButton, DeleteButton } from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const RoleTypesForm = ({
  isLoading,
  roleTypes,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");

  const RoleTypeActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("roleTypesForm.role_code"),
      dataIndex: "RoleTypeCode",
      key: "RoleTypeCode",
      width: 200,
      sorter: (a, b) => a.RoleTypeCode.localeCompare(b.RoleTypeCode),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "RoleTypeCode",
        searchText,
        handleSearch,
        handleReset,
        "Role Type Code",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "RoleTypeDesc",
      key: "RoleTypeDesc",
      sorter: (a, b) => a.RoleTypeDesc.localeCompare(b.RoleTypeDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "RoleTypeDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("general.action"),
      dataIndex: "RoleTypeKey",
      key: "RoleTypeActions",
      align: "center",
      width: 100,
      render: RoleTypeActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">{t("roleTypesForm.role_setup")}</span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          New
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={roleTypes}
          columns={columns}
          size="middle"
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
          rowKey={(record) => record.RoleTypeKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

RoleTypesForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roleTypes: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
