import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "NotifConfg/";

export function getNotifConfgUser() {
  return axios({
    method: "GET",
    url: base + "GetNotifConfgUser",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getNotifConfgByKey(userKey) {
  return axios({
    method: "GET",
    url: base + "GetNotifConfgByKey",
    headers: authHeader(),
    params: { key: userKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateNotifConfg(notifConfg) {
  return axios({
    method: "PUT",
    url: base + "UpdateNotifConfg",
    headers: authHeader(),
    data: notifConfg
  })
    .then()
    .catch(handleError);
}