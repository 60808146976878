import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Input, Upload, message, Form, Spin } from "antd";
import { useTranslation } from "react-i18next";

function EmpyAttachmentModal({
  handleSave,
  handleCancel,
  isVisible,
  attachment,
  fileList,
  setFileList,
  setIsDirty,
  form,
  isLoadingAttch,
}) {
  const { t } = useTranslation();
  const [isAddAttchFlag, setIsAddAttchFlag] = useState(false);

  useEffect(() => {
    if (attachment.AttchKey === -1) {
      setIsAddAttchFlag(true);
    } else {
      setIsAddAttchFlag(false);
    }
  }, [isVisible, attachment.AttchKey]);

  function uploadProp() {
    return {
      maxCount: 1,
      onRemove: () => {
        setFileList([]);
      },
      beforeUpload: (file) => {
        const maxFileSize = file.size / 1024 / 1024 < 4;
        if (!maxFileSize) {
          message.error(t("documentManager.maximum_file_size_4mb"));
        } else {
          //max one file can be uploaded
          setFileList([file]);
        }
        file.RowState = 1;
        return false;
      },
      showUploadList: {
        showPreviewIcon: false,
        showRemoveIcon: isAddAttchFlag,
      },
    };
  }

  function saveForm() {
    form
      .validateFields()
      .then((values) => {
        let attachmentSubmit = {
          ...attachment,
          ...values,
        };

        handleSave(attachmentSubmit);
      })
      .catch(() => {});
  }

  return (
    <Modal
      forceRender
      open={isVisible}
      title={<span className="form-subtitle">{t("general.attachment")}</span>}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={handleCancel}
      destroyOnClose={true}
      maskClosable={false}
      okButtonProps={{
        disabled: isLoadingAttch,
      }}
      cancelButtonProps={{
        disabled: isLoadingAttch,
      }}
      closable={false}
    >
      <Spin spinning={isLoadingAttch}>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={() => setIsDirty(true)}
        >
          <Form.Item
            label={t("employeeForm.document")}
            name={"Document"}
            rules={[
              {
                required: isAddAttchFlag,
                message: t("employeeForm.document_required_validation"),
              },
            ]}
          >
            <Upload
              {...uploadProp()}
              listType="picture"
              fileList={fileList}
              disabled={!isAddAttchFlag}
            >
              <Button disabled={!isAddAttchFlag}>
                <UploadOutlined /> {t("general.browse")}
              </Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label={t("general.remarks")}
            name={"Remarks"}
            rules={[
              {
                required: false,
              },
              {
                max: 250,
                message: t("general.remarks_max_length_validation"),
              },
            ]}
            initialValue={attachment.Remarks}
          >
            <Input type="text" placeholder={t("general.remarks_placeholder")} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

EmpyAttachmentModal.propTypes = {
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  attachment: PropTypes.object.isRequired,
  fileList: PropTypes.array.isRequired,
  setFileList: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
  isLoadingAttch: PropTypes.bool.isRequired,
};

export default EmpyAttachmentModal;
