import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ManageRoleAssignmentForm } from "../../forms/Admin/ManageRoleAssignmentForm";
import { toast } from "react-toastify";
import { getRoleTypeCode } from "../../../api/roleTypeApi";
import { getSelectedUser, UpdateESSUser } from "../../../api/roleAssignmentApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newUserRole = {
  UserKey: "0",
  UserName: "",
  Email: "",
  RoleTypeKey: "0",
};

export default function ManageRoleAssignmentPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [roleTypes, setRoleTypes] = useState([]);
  const [roleAssignment, setroleAssignment] = useState(newUserRole);
  const [isLoading, setIsLoading] = useState(true);
  const { UserKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [getRoleTypeCode()];
    let editKey = UserKey;
    if (editKey > 0) {
      promises.push(getSelectedUser(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setRoleTypes(values[0] ? values[0] : []);
        setroleAssignment(values[1] ? values[1] : newUserRole);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [UserKey]);

  function saveRoleAssignment(values) {
    setIsDirty(false);
    let roleassignmentSubmit = { ...roleAssignment, ...values };

    UpdateESSUser(roleassignmentSubmit)
      .then(() => {
        let filterOuKey = 0,
          filterDeptKey = 0;

        if (location.state) {
          if (location.state.filterOuKey) {
            filterOuKey = location.state.filterOuKey;
          }
          if (location.state.filterDeptKey) {
            filterDeptKey = location.state.filterDeptKey;
          }
        }

        history("/home/roleassignments", {
          state: {
            filterOuKey: filterOuKey,
            filterDeptKey: filterDeptKey,
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });

        let message = t("roleAssignment.role_assignment_saved_successfully");
        toast.success(message);
      })
      .catch((error) => {
        let message =
          roleassignmentSubmit.UserKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.user_does_not_exists");
        toast.error(message + error.message);
      });
  }

  function cancelInputRoleAssignment() {
    let filterOuKey = 0,
      filterDeptKey = 0;

    if (location.state) {
      if (location.state.filterOuKey) {
        filterOuKey = location.state.filterOuKey;
      }
      if (location.state.filterDeptKey) {
        filterDeptKey = location.state.filterDeptKey;
      }
    }

    history("/home/roleassignments", {
      state: {
        filterOuKey: filterOuKey,
        filterDeptKey: filterDeptKey,
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageRoleAssignmentForm
      isLoading={isLoading}
      roleAssignment={roleAssignment}
      roleTypes={roleTypes}
      onCancelled={cancelInputRoleAssignment}
      history={history}
      onSubmitted={saveRoleAssignment}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageRoleAssignmentPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
