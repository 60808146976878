import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "company/";

export function getCompany() {
  return axios({
    method: "GET",
    url: base + "GetCompany",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}
