import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "proj/";

export function getAllProjects() {
  return axios({
    method: "GET",
    url: base + "GetAllProjects",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getProject(projKey) {
  return axios({
    method: "GET",
    url: base + "GetProject",
    headers: authHeader(),
    params: { key: projKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addProject(project) {
  return axios({
    method: "POST",
    url: base + "AddProject",
    headers: authHeader(),
    data: project
  })
    .then()
    .catch(handleError);
}

export function updateProject(project) {
  return axios({
    method: "PUT",
    url: base + "UpdateProject",
    headers: authHeader(),
    params: { key: project.ProjKey },
    data: project
  })
    .then()
    .catch(handleError);
}

export function deleteProject(projKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteProject",
    headers: authHeader(),
    params: { key: projKey }
  })
    .then()
    .catch(handleError);
}

export function getProjectCode() {
  return axios({
    method: "GET",
    url: base + "getProjectCode",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}
