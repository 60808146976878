import React, { useState, useEffect } from "react";
import { SettingOutlined } from "@ant-design/icons";
import { Tabs, Tooltip, Select, Form } from "antd";
import "./SupportIcon.css";
import HeaderDropdown from "../HeaderDropdown/HeaderDropdown";
import { withRouter } from "../../customHooks/withRouter";
import { getESSUser } from "../../api/roleAssignmentApi";
import { login } from "../../api/userApi";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const { Option } = Select;

function SupportIcon() {
  const [isVisible, setIsVisible] = useState(false);
  const [empys, setEmpys] = useState([]);
  const [selectedUserKey, setSelectedUserKey] = useState(-1);
  const { t } = useTranslation();

  function showErrorMessage(message) {
    toast.error(message);
  }

  useEffect(() => {
    let promises = [getESSUser()];

    Promise.all(promises)
      .then((values) => {
        setEmpys(
          values[0] ? values[0].filter((x) => x.IsLMStaff === false) : []
        );
      })
      .catch((error) => {
        showErrorMessage(t("fail_to_load_record"), error);
      });
  }, [t]);

  const trigger = (
    <span
      style={{
        display: "inline-block",
        height: "100%",
        padding: "0 12px",
        cursor: "pointer",
        transition: "all .3s",
      }}
    >
      <Tooltip placement="bottomRight" title={t("supportIcon.support")}>
        <SettingOutlined
          className="icon"
          style={{ padding: 4, verticalAlign: "middle", fontSize: 16 }}
        />
      </Tooltip>
    </span>
  );

  function generateRoleTypesOption() {
    let dataOptions = [];
    if (empys && empys.length) {
      dataOptions = empys.map((empy) => (
        <Option key={empy.UserKey} value={empy.UserKey}>
          {empy.EmpyName}
        </Option>
      ));
    }

    return dataOptions;
  }

  function getItems() {
    let result = [];

    result.push({
      label: t("supportIcon.user"),
      key: 0,
      children: (
        <>
          <Form.Item
            label={t("supportIcon.select_supporting_user")}
            style={{ marginTop: -8, marginLeft: 20 }}
          >
            {
              <Select
                showSearch
                style={{ width: 360 }}
                placeholder={t("supportIcon.select_user")}
                optionFilterProp="children"
                onChange={setSelectedUserKeyFunc}
                data={empys}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {generateRoleTypesOption()}
              </Select>
            }
          </Form.Item>
          <div className="bottomBar">
            {<div onClick={onApply}>{t("general.apply")}</div>}
            {<div onClick={onReset}>{t("general.reset")}</div>}
          </div>
        </>
      ),
    });

    return result;
  }

  function getNotificationBox() {
    return (
      <Tabs
        items={getItems()}
        style={{
          boxShadow: "0 2px 8px rgba(0,0,0,.15)",
          borderRadius: "inherit",
        }}
      ></Tabs>
    );
  }

  function handleVisibleChange(isVisible) {
    setIsVisible(isVisible);
  }

  function onApply() {
    if (selectedUserKey === -1)
      showErrorMessage(t("supportIcon.error_message_empty_user"));
    else login(selectedUserKey);
  }

  function onReset() {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth && auth.LocalEditorKey) {
      login(Number(auth.LocalEditorKey));
    }
  }

  function setSelectedUserKeyFunc(value) {
    setSelectedUserKey(value);
  }

  const notificationBox = getNotificationBox();

  return (
    <HeaderDropdown
      placement="bottomRight"
      dropdownRender={() => notificationBox}
      overlayClassName="popover"
      trigger={["click"]}
      open={isVisible}
      onOpenChange={handleVisibleChange}
    >
      {trigger}
    </HeaderDropdown>
  );
}

export default connect()(withRouter(SupportIcon));
