import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseTrainingApplication = API_ROOT + "trainingapplication/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export async function getTrainingApplications(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseTrainingApplication + "GetAllTrainingApplicationsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getTrainingApplicationByKey(transKey) {
  return axios
    .get(baseTrainingApplication + "GetTrainingApplicationByKey", {
      headers: authHeader(),
      params: {
        key: transKey,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function applyTraining(trainingApplication) {
  return axios({
    method: "POST",
    url: baseTrainingApplication + "ApplyTraining",
    headers: authHeader(),
    data: trainingApplication,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function upsertTrainingFeedback(trainingApplication, isSubmit) {
  return axios({
    method: "POST",
    url:
      baseTrainingApplication +
      "UpsertTrainingFeedback?key=" +
      trainingApplication.TrainingApplicationKey,
    headers: authHeader(),
    params: { isSubmit: isSubmit },
    data: trainingApplication,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawTrainingApplication(trainingApplication) {
  return axios({
    method: "POST",
    url:
      baseTrainingApplication +
      "WithdrawTrainingApplication?key=" +
      trainingApplication.TrainingApplicationKey,
    headers: authHeader(),
    data: trainingApplication,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelTrainingApplication(
  trainingApplication,
  cancelReason
) {
  return axios({
    method: "POST",
    url:
      baseTrainingApplication +
      "RequestCancelTrainingApplication?key=" +
      trainingApplication.TrainingApplicationKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: trainingApplication,
  })
    .then(handleResponse)
    .catch(handleError);
}
