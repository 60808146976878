import { RowState } from "../common/RowState";
import { LimitLevel, LimitType } from "./ClaimEnum";

export default function ClaimTypeLimitModel({
  claimTypeLimitKey = -1,
  refKey = -1,
  refDesc = "",
  limitLevel = LimitLevel.COMPANY,
  limitType = LimitType.NONE,
  selfAmt = 0,
  spouseAmt = 0,
  childAmt = 0,
  maxChildNum = 0,
  maxTotalAmt = 0,
  rowState = RowState.ADDED
}) {
  return Object.freeze({
    ClaimTypeLimitKey: claimTypeLimitKey,
    RefKey: refKey,
    RefDesc: refDesc,
    LimitLevel: limitLevel.Level,
    LimitType: limitType.Type,
    LimitTypeDesc: limitType.Desc,
    SelfAmt: selfAmt,
    SpouseAmt: spouseAmt,
    ChildAmt: childAmt,
    MaxChildNum: maxChildNum,
    MaxTotalAmt: maxTotalAmt,
    RowState: rowState
  });
}
