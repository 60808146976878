import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import OrganizationChart from "../OrgChart/components/ChartContainer";
import { getOrgChart } from "../../../api/orgChartApi";
import $ from "jquery";
import { Input, Spin, Row, Col, Form, Select } from "antd";
import { useTranslation } from "react-i18next";

export default function OrgChartPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [orgChartData, setOrgChartData] = useState({});
  const [groupBy, setGroupBy] = useState(0);

  const { Search } = Input;
  const { Option } = Select;

  useEffect(() => {
    setIsLoading(true);
    let promises = [getOrgChart(groupBy)];

    Promise.all(promises)
      .then((values) => {
        setOrgChartData(values[0]);
        setTimeout(() => {
          setCenter();
        }, 300);
        setIsLoading(false);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [groupBy]);

  function loopChart($hierarchy) {
    var $siblings = $hierarchy.children("").children(".oc-hierarchy");
    if ($siblings.length) {
      $siblings
        .filter(":not(.hidden)")
        .first()
        .addClass("first-shown")
        .end()
        .last()
        .addClass("last-shown");
    }
    $siblings.each(function (index, sibling) {
      loopChart($(sibling));
    });
  }

  function filterNode(filterValue) {
    unfilterNode();
    var keyWord = filterValue;

    if (!keyWord.length) {
      //toast.error(t("orgChartPage.keyword_validation"));
      return;
    } else {
      var $chart = $(".orgchart");
      // disable the expand/collapse feature
      $chart.addClass("noncollapsable");
      // distinguish the matched nodes and the unmatched nodes according to the given key word
      $chart
        .find(".oc-node")
        .filter(function (index, node) {
          return $(node).text().toLowerCase().indexOf(keyWord) > -1;
        })
        .addClass("matched")
        .closest(".oc-hierarchy")
        .parents(".oc-hierarchy")
        .children(".oc-node")
        .addClass("retained");

      //Center the node (failed)
      //$chart.css("transform", "matrix(1, 0, 0, 1, " + 0 + ", " + 0 + ")");

      // hide the unmatched nodes
      $chart.find(".matched,.retained").each(function (index, node) {
        $(node)
          .removeClass("slide-up")
          .closest(".nodes")
          .removeClass("hidden")
          .siblings(".oc-hierarchy")
          .removeClass("isChildrenCollapsed");
        $(node)
          .closest(".oc-hierarchy")
          .siblings()
          .find(".oc-node:first:not(.matched,.retained)")
          .closest(".oc-hierarchy")
          .addClass("hidden");
      });

      //hide the redundant descendant nodes of the matched nodes
      // $chart.find(".matched").each(function (index, node) {
      //   if (!$(node).siblings(".nodes").find(".matched").length) {
      //     $(node)
      //       .siblings(".nodes")
      //       .addClass("hidden")
      //       .parent()
      //       .addClass("isChildrenCollapsed");
      //   }
      // });

      // loop chart and adjust lines
      loopChart($chart.find(".oc-hierarchy:first"));
    }
  }

  function unfilterNode() {
    $(".orgchart")
      .removeClass("noncollapsable")
      .find(".oc-node")
      .removeClass("matched retained")
      .end()
      .find(".hidden, .isChildrenCollapsed, .first-shown, .last-shown")
      .removeClass("hidden isChildrenCollapsed first-shown last-shown")
      .end()
      .find(".slide-up, .slide-left, .slide-right")
      .removeClass("slide-up slide-right slide-left")
      .end()
      .find(".oc-hierarchy .isSiblingsCollapsed")
      .removeClass("isSiblingsCollapsed");
  }

  function setCenter() {
    var $container = $("#orgChartid");
    $container.scrollLeft(($container[0].scrollWidth - $container.width()) / 2);
  }

  var nodeTemplate = function (data) {
    let name = data.nodeData.name;
    let title = data.nodeData.title;
    let photo = data.nodeData.photoUrl;

    return (
      <div className="org-node-container">
        <div
          className="org-person"
          style={{
            display: photo !== "" ? "" : "none",
          }}
        >
          <div>
            <img src={photo} className="headshot" alt="" draggable="false" />
          </div>
        </div>
        <div
          className="org-tooltip"
          style={{
            display: name !== "" && title !== "" ? "" : "none",
          }}
        >
          <span className="org-tooltiptext">
            <div className="org-name-tooltip">{name}</div>
            <div className="org-title-tooltip">{title}</div>
          </span>
          <div className="org-name">{name}</div>
          <div className="org-title">{title}</div>
        </div>

        <div
          className="org-tooltip"
          style={{
            display: name !== "" && title === "" ? "" : "none",
          }}
        >
          <span
            className="org-tooltiptext"
            style={{ borderRadius: "12px 12px 12px 12px" }}
          >
            <div className="org-name-tooltip">{name}</div>
          </span>
          <div className="org-name">{name}</div>
        </div>

        {/* {nodeData.children.length > 0 && (
          <div
            className="org-node-children"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              let childNodes = document.getElementById(nodeData.id)
                .parentElement.childNodes;
              if (childNodes[1].className.includes("hidden")) {
                childNodes[0].className = "oc-node";
                childNodes[1].className = "";
              } else {
                childNodes[0].className = "oc-node isChildrenCollapsed";
                childNodes[1].className = "hidden";
              }
            }}
          >
            {nodeData.children.length} Reportees
          </div>
        )} */}
      </div>
    );
  };

  const handleChange = (value) => {
    setGroupBy(value);
  };

  return (
    <Spin spinning={isLoading}>
      <Form layout="vertical">
        <div>
          <OrganizationChart
            datasource={orgChartData}
            pan={true}
            zoom={true}
            zoominLimit={1}
            zoomoutLimit={0.5}
            NodeTemplate={nodeTemplate}
          />
        </div>
        <Row
          gutter={20}
          style={{
            marginTop: 16,
          }}
        >
          <Col xs={24} lg={12}>
            <Form.Item label={t("orgChartPage.keyword")}>
              <Search
                allowClear
                type="text"
                id="keyword"
                placeholder={t("general.search")}
                enterButton={t("general.search")}
                onSearch={(value) => filterNode(value)}
              />
            </Form.Item>
          </Col>
          <Col
            xs={24}
            lg={12}
            //style={{ marginTop: "21px", paddingLeft: "0px" }}
          >
            <Form.Item
              label={<span>{t("orgChartPage.group_by")}</span>}
              name="GroupBy"
              initialValue={groupBy}
            >
              <Select
                getPopupContainer={() =>
                  document.getElementById("scroll-container")
                }
                onChange={handleChange}
                placeholder={t("orgChartPage.group_by")}
              >
                <Option value={0}>{t("general.default")}</Option>
                <Option value={1}>{t("general.company")}</Option>
                <Option value={2}>{t("general.operating_unit")}</Option>
                <Option value={3}>{t("general.department")}</Option>
              </Select>
            </Form.Item>
            {/* <Button size="default" type="text" onClick={() => unfilterNode()}>
              {t("general.clear")}
            </Button> */}
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
