import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Row, Col } from "antd";
import { ApprovalReasonModal } from "../../common/ApprovalReasonModal";
import { PropMaintList } from "./PropMaintList";
import "../../../App.css";
import {
  Status,
  StatusByHexColor,
} from "../../../components/model/common/Status";
import { RepairDateModal } from "./RepairDateModal";
import { useTranslation } from "react-i18next";

export default function PropMaintValidationForm({
  isLoading,
  data,
  selectedItems,
  onAdd,
  onView,
  onValidated,
  onItemSelected,
  onAttachmentClicked,
  setCurrentYear,
}) {
  const { t } = useTranslation();
  const [showReasonForm, setShowReasonForm] = useState(false);
  const [showRepairDateForm, setShowRepairDateForm] = useState(false);
  const [tempItem, setTempItem] = useState();

  function submitNoRepairReason(reason) {
    setShowReasonForm(false);
    if (tempItem) {
      //no repair single item
      onValidated([tempItem], false, reason);
    } else {
      //no repair multiple items
      onValidated(selectedItems, false, reason);
    }
  }

  function selectAllItems(isSelected) {
    if (onItemSelected) {
      if (isSelected) {
        onItemSelected(data.filter((x) => !x.IsViewOnly));
      } else {
        onItemSelected([]);
      }
    }
  }

  function noRepairPropMaint(selectedPropMaint) {
    setTempItem(selectedPropMaint);
    setShowReasonForm(true);
  }

  function noRepairPropMaints() {
    setTempItem(null);
    setShowReasonForm(true);
  }

  function submitRepairDate(startDate, endDate) {
    setShowRepairDateForm(false);
    if (tempItem) {
      //repair single item
      onValidated([tempItem], true, "", startDate, endDate);
    } else {
      //repair multiple items
      onValidated(selectedItems, true, "", startDate, endDate);
    }
  }

  function repairPropMaint(selectedPropMaint) {
    setTempItem(selectedPropMaint);
    setShowRepairDateForm(true);
  }

  function repairPropMaints() {
    setTempItem(null);
    setShowRepairDateForm(true);
  }

  function getListActions(item) {
    return [
      <div key>
        <span
          style={{
            float: "left",
            fontStyle: "italic",
            marginLeft: "50px",
            color: StatusByHexColor[item.Status],
          }}
        >
          {Status[item.Status]}
        </span>

        <Button
          type="default"
          size="default"
          style={{
            marginRight: 10,
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => noRepairPropMaint(item)}
        >
          {t("propMaintForm.no_repair_required")}
        </Button>
        <Button
          type="primary"
          size="default"
          style={{
            display: !item.IsViewOnly ? "" : "none",
          }}
          onClick={() => repairPropMaint(item)}
        >
          {t("propMaintForm.repair_required")}
        </Button>
      </div>,
    ];
  }

  const footerElement = () => (
    <Row>
      <Col span={6} style={{ paddingTop: "5px" }}>
        <Checkbox
          indeterminate={
            selectedItems.length &&
            selectedItems.length < data.filter((x) => !x.IsViewOnly).length
          }
          checked={
            selectedItems.length &&
            selectedItems.length === data.filter((x) => !x.IsViewOnly).length
          }
          onChange={(event) => selectAllItems(event.target.checked)}
        >
          {t("general.select_all")}
        </Checkbox>
      </Col>

      <Col span={18} style={{ textAlign: "right" }}>
        <Button
          type="default"
          style={{ marginRight: "5px", marginLeft: "10px" }}
          disabled={selectedItems.length === 0 || isLoading}
          onClick={noRepairPropMaints}
        >
          {t("propMaintForm.no_repair_required")}
        </Button>
        <Button
          type="primary"
          disabled={selectedItems.length === 0 || isLoading}
          onClick={repairPropMaints}
        >
          {t("propMaintForm.repair_required")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <div>
      <PropMaintList
        isLoading={isLoading}
        data={data}
        onAdd={onAdd}
        onItemViewed={onView}
        isSelectable={true}
        selectedItems={selectedItems}
        onItemSelected={onItemSelected}
        onListActionsRendered={getListActions}
        filterCheckedText={t("propMaintForm.show_unvalidated_properties_only")}
        footerElement={footerElement}
        onAttachmentClicked={onAttachmentClicked}
        setCurrentYear={setCurrentYear}
      />

      <ApprovalReasonModal
        isVisible={showReasonForm}
        reasonType={"No Repair Required"}
        onSaved={submitNoRepairReason}
        onCancelled={() => setShowReasonForm(false)}
      />

      <RepairDateModal
        isVisible={showRepairDateForm}
        onSaved={submitRepairDate}
        onCancelled={() => setShowRepairDateForm(false)}
      />
    </div>
  );
}

PropMaintValidationForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onValidated: PropTypes.func.isRequired,
  onItemSelected: PropTypes.func.isRequired,
  isSelectable: PropTypes.bool,
  onAttachmentClicked: PropTypes.func.isRequired,
  setCurrentYear: PropTypes.func.isRequired,
};
