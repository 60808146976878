import React from "react";
import dayjs from "dayjs";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

const EntryFormFooter = ({
  footerData,
  showFooter,
  isApproved,
  isPending,
  isVoid,
  t,
}) => (
  <>
    <div
      style={{
        border: "1px solid #fff",
        backgroundColor: "#fff",
        display: showFooter ? "" : "none",
        padding: "15px 25px",
        fontSize: "12px",
      }}
    >
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          hidden={
            footerData.CreatedBy === undefined ||
            footerData.CreatedBy === -1 ||
            footerData.CreatedBy === 0
          }
        >
          <span>
            <b>{t("general.created_by")}</b>{" "}
          </span>
          <span>{footerData.CreatedByCode}</span>
          <span>
            {" "}
            {dayjs(footerData.CreatedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          hidden={
            footerData.UpdatedBy === undefined ||
            footerData.UpdatedBy === -1 ||
            footerData.UpdatedBy === 0
          }
        >
          <span>
            <b>{t("general.updated_by")}</b>{" "}
          </span>
          <span>{footerData.UpdatedByCode}</span>
          <span>
            {" "}
            {dayjs(footerData.UpdatedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} hidden={!isApproved}>
          <span>
            <b>
              {footerData.L2ApprovedByCode === ""
                ? t("general.approved_by")
                : t("general.L1_approved_by")}
            </b>{" "}
          </span>
          <span>{footerData.L1ApprovedByCode}</span>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} hidden={!isApproved}>
          <span>
            <b>
              {footerData.L2ApprovedByCode === ""
                ? t("general.approved_on")
                : t("general.L1_approved_on")}
            </b>{" "}
          </span>
          <span>
            {" "}
            {dayjs(footerData.L1ApprovedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          hidden={!isApproved || footerData.L2ApprovedByCode === ""}
        >
          <span>
            <b>{t("general.L2_approved_by")}</b>{" "}
          </span>
          <span>{footerData.L2ApprovedByCode}</span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          hidden={!isApproved || footerData.L2ApprovedByCode === ""}
        >
          <span>
            <b>{t("general.L2_approved_on")}</b>{" "}
          </span>
          <span>
            {" "}
            {dayjs(footerData.L2ApprovedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} hidden={!isVoid}>
          <span>
            <b>{t("general.voided_by")}</b>{" "}
          </span>
          <span>{footerData.VoidedByCode}</span>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} hidden={!isVoid}>
          <span>
            <b>{t("general.voided_on")}</b>{" "}
          </span>
          <span>
            {" "}
            {dayjs(footerData.VoidedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} hidden={!isPending}>
          <span>
            <b>{t("general.pending_approval_from")}</b>{" "}
          </span>
          <span>{footerData.PenApproval}</span>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          hidden={
            footerData.AssignedBy === undefined ||
            footerData.AssignedBy === -1 ||
            footerData.AssignedBy === 0
          }
        >
          <span>
            <b>{t("general.assigned_by")}</b>{" "}
          </span>
          <span>{footerData.AssignedByCode}</span>
          <span>
            {" "}
            {dayjs(footerData.AssignedOn).format("DD/MM/YYYY hh:mm:ss A")}
          </span>
        </Col>
      </Row>
    </div>
  </>
);

EntryFormFooter.propTypes = {
  footerData: PropTypes.object.isRequired,
  showFooter: PropTypes.bool.isRequired,
  isApproved: PropTypes.bool.isRequired,
  isPending: PropTypes.bool,
  isVoid: PropTypes.bool,
  t: PropTypes.func,
};

export default EntryFormFooter;
