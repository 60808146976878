import React, { useState, useEffect } from "react";
import ReportsForm from "../../forms/Report/ReportForm";
import PropTypes from "prop-types";
import { GetReportRoute } from "../../../mock/reportRoute";
import { getMenuPermByRole } from "../../../api/menuApi";

export default function ReportPage({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);

  useEffect(getReports, []);

  function getReports() {
    let { UserRole, UserRoleKey } = JSON.parse(sessionStorage.getItem("auth"));
    let allReports = GetReportRoute(UserRole);

    let promises = [getMenuPermByRole(UserRoleKey)];

    Promise.all(promises).then((values) => {
      allReports.forEach((element) => {
        for (let x = 0; x < values[0].length; x++) {
          if (element.id === values[0][x]) {
            element.enabled = true;
          }
        }
      });
      allReports = allReports.filter((x) => x.enabled === true);

      setReports(allReports);
      setFilteredReports(allReports);
      setIsLoading(false);
    });
  }

  function openReport(reportRoute) {
    history(reportRoute);
  }

  function filterReport(reportName) {
    let filteredData = reports.filter(
      (x) => x.name.toLowerCase().indexOf(reportName.toLowerCase()) > -1
    );
    setFilteredReports(filteredData);
  }

  return (
    <ReportsForm
      isLoading={isLoading}
      reports={filteredReports}
      onEdit={openReport}
      filterReport={filterReport}
      filteredReport={filteredReports}
    />
  );
}

ReportPage.propTypes = {
  history: PropTypes.func.isRequired,
};
