import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "AppraisalTemplate/";

export function getAllAppraisalTemplates() {
  return axios({
    method: "GET",
    url: base + "GetAllAppraisalTemplates",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAllActiveAppraisalTemplates() {
  return axios({
    method: "GET",
    url: base + "GetAllActiveAppraisalTemplates",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAppraisalTemplate(appraisalTemplateKey) {
  return axios({
    method: "GET",
    url: base + "GetAppraisalTemplate",
    headers: authHeader(),
    params: { key: appraisalTemplateKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getAppraisalEmployeeInfo(userKey) {
  return axios({
    method: "GET",
    url: base + "GetAppraisalEmployeeInfo",
    headers: authHeader(),
    params: { key: userKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addAppraisalTemplate(appraisalTemplate) {
  return axios({
    method: "POST",
    url: base + "AddAppraisalTemplate",
    headers: authHeader(),
    data: appraisalTemplate,
  })
    .then()
    .catch(handleError);
}

export function updateAppraisalTemplate(appraisalTemplate) {
  return axios({
    method: "PUT",
    url: base + "UpdateAppraisalTemplate",
    headers: authHeader(),
    params: { key: appraisalTemplate.ATKey },
    data: appraisalTemplate,
  })
    .then()
    .catch(handleError);
}

export function deleteAppraisalTemplate(appraisalTemplateKey) {
  return axios({
    method: "DELETE",
    url: base + "DeleteAppraisalTemplate",
    headers: authHeader(),
    params: { key: appraisalTemplateKey },
  })
    .then()
    .catch(handleError);
}
