import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageAppraisalTemplateForm from "../../forms/Master/ManageAppraisalTemplateForm";
import PropTypes from "prop-types";
import AppraisalTemplateHeaderModel from "../../model/AppraisalTemplate/AppraisalTemplateHeaderModel";
import {
  getAppraisalTemplate,
  addAppraisalTemplate,
  updateAppraisalTemplate,
} from "../../../api/appraisalTemplateApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newAppraisalTemplate = AppraisalTemplateHeaderModel({});

export default function ManageAppraisalTemplatePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [appraisalTemplate, setAppraisalTemplate] =
    useState(newAppraisalTemplate);
  const [isLoading, setIsLoading] = useState(true);
  const { ATKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = ATKey;
    if (editKey > 0) {
      promises.push(getAppraisalTemplate(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
          if (values[0].CreatedDate) {
            values[0].CreatedOn = values[0].CreatedDate;
          }
        }
        setAppraisalTemplate(values[0] ? values[0] : newAppraisalTemplate);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, [ATKey]);

  function saveAppraisalTemplate(values) {
    let appraisalTemplateSubmit = {
      ...appraisalTemplate,
      ...values,
      ATCode: values.ATCode.trim(),
      ATDesc: values.ATDesc.trim(),
    };

    let upsertAppraisalTemplate =
      appraisalTemplateSubmit.ATKey !== -1
        ? updateAppraisalTemplate
        : addAppraisalTemplate;
    setIsLoading(true);
    upsertAppraisalTemplate(appraisalTemplateSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/appraisaltemplates", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        toast.success(t("general.record_saved_successfully"));
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          appraisalTemplateSubmit.ATKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputAppraisalTemplate() {
    history("/home/appraisaltemplates", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  return (
    <ManageAppraisalTemplateForm
      isLoading={isLoading}
      appraisalTemplate={appraisalTemplate}
      onSubmitted={saveAppraisalTemplate}
      onCancelled={cancelInputAppraisalTemplate}
      onDataChanged={setAppraisalTemplate}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAppraisalTemplatePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
