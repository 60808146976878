import React, { useEffect } from "react";
import { Input, Row, Col, Button, Switch, Card, Spin, Form } from "antd";
import { SelectionInput } from "../../common/SelectionInput";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

const VehicleInputs = ({
  isLoading,
  vehicle,
  ous,
  vehiclesInOU,
  selectedQVehicle,
  onSubmitted,
  onCancelled,
  getVehiclesInOU,
  getVehicleInfo,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();
  const formats = { cellSpacing: 20 };
  const disableField = vehicle.RcdType === RecordType.USER ? false : true;

  useEffect(
    () => form.resetFields(["VehicleType", "RegNo", "Model"]),
    [selectedQVehicle, form]
  );

  function getVehicleSelectionDataByOU(value) {
    form.setFieldsValue({ VehRef: undefined });
    getVehiclesInOU(value.value);
  }

  function getSelectedVehicleInfo(value) {
    getVehicleInfo(value.value);
  }

  const handleSubmit = async () => {
    form.validateFields().then((values) => {
      onSubmitted(values);
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        layout={"vertical"}
        onFinish={handleSubmit}
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {" "}
                  {t("vehicleForm.vehicle_setup")}
                </span>
              }
              extra={
                <Form.Item
                  style={{ textAlign: "right", margin: 0 }}
                  name="Active"
                  valuePropName="checked"
                  initialValue={vehicle.Active}
                >
                  <Switch
                    checkedChildren={t("general.active")}
                    unCheckedChildren={t("general.inactive")}
                  />
                </Form.Item>
              }
            >
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("vehicleForm.vehicle_code")}
                    name="VehicleCode"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t(
                          "vehicleForm.vehicle_code_required_validation"
                        ),
                      },
                      {
                        max: 100,
                        message: t(
                          "vehicleForm.vehicle_code_max_length_validation"
                        ),
                      },
                    ]}
                    initialValue={vehicle.VehicleCode}
                  >
                    <Input
                      placeholder={t("vehicleForm.vehicle_code_placeholder")}
                      disabled={disableField}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.description")}
                    name="VehicleDesc"
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: t("general.description_required_validation"),
                      },
                      {
                        max: 250,
                        message: t("general.description_max_length_validation"),
                      },
                    ]}
                    initialValue={vehicle.VehicleDesc}
                  >
                    <Input placeholder={t("general.description_placeholder")} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={formats.cellSpacing}>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("general.operating_unit")}
                    style={{ margin: 0 }}
                    name="OURef"
                    rules={[
                      {
                        required: true,
                        message: t(
                          "general.operating_unit_required_validation"
                        ),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            !ous.some((x) => x.Key === value.value)
                          ) {
                            return Promise.reject(
                              new Error(
                                t("general.operating_unit_invalid_validation")
                              )
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    initialValue={
                      vehicle.OURefKey > 0
                        ? {
                            key: vehicle.OURefKey,
                            value: vehicle.OURefKey,
                            label: vehicle.OURefCodeDesc,
                          }
                        : undefined
                    }
                  >
                    <SelectionInput
                      ref={React.createRef()}
                      labelInValue={true}
                      data={ous}
                      valueProp={"Key"}
                      textProp={"CodeDesc"}
                      disabledProp={"Active"}
                      placeholder={t("general.operating_unit_placeholder_full")}
                      fncOnSelect={getVehicleSelectionDataByOU}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("vehicleForm.vehicle")}
                    style={{ margin: 0 }}
                    name="VehRef"
                    rules={[
                      {
                        required: true,
                        message: t("vehicleForm.vehicle_required_validation"),
                      },
                      {
                        validator: (_, value) => {
                          if (
                            value &&
                            !vehiclesInOU.some((x) => x.Key === value.value)
                          ) {
                            return Promise.reject(
                              new Error(t("vehicleForm.invalid_vehicle"))
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    initialValue={
                      vehicle.VehRefKey > 0
                        ? {
                            key: vehicle.VehRefKey,
                            value: vehicle.VehRefKey,
                            label: vehicle.VehRefIDDesc,
                          }
                        : undefined
                    }
                  >
                    <SelectionInput
                      ref={React.createRef()}
                      labelInValue={true}
                      data={vehiclesInOU}
                      valueProp={"Key"}
                      textProp={"CodeDesc"}
                      disabledProp={"Active"}
                      placeholder={t("vehicleForm.vehicle_placeholder")}
                      fncOnSelect={getSelectedVehicleInfo}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row
                gutter={formats.cellSpacing}
                style={{
                  marginTop: 16,
                  display:
                    selectedQVehicle &&
                    Object.keys(selectedQVehicle).length !== 0
                      ? ""
                      : "none",
                }}
              >
                <Col xs={24} lg={12}>
                  <Form.Item
                    label={t("vehicleForm.vehicle_type")}
                    name="VehicleType"
                    initialValue={
                      selectedQVehicle ? selectedQVehicle.VehicleType : ""
                    }
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item
                    label={t("vehicleForm.registration_no")}
                    name="RegNo"
                    initialValue={
                      selectedQVehicle ? selectedQVehicle.RegNo : ""
                    }
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
                <Col xs={24} lg={6}>
                  <Form.Item
                    label={t("vehicleForm.model")}
                    name="Model"
                    initialValue={
                      selectedQVehicle ? selectedQVehicle.Model : ""
                    }
                  >
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Row className="save-section">
              <Col
                style={{
                  textAlign: "right",
                  padding: "10px",
                }}
              >
                <Button
                  size="large"
                  type="default"
                  onClick={onCancelled}
                  style={{ marginRight: "5px" }}
                >
                  {t("general.cancel")}
                </Button>
                <Button size="large" type="primary" htmlType="submit">
                  {t("general.save")}
                </Button>
              </Col>
            </Row>
            <EntryFormFooter
              footerData={vehicle}
              showFooter={vehicle.VehicleKey !== -1 ? true : false}
              isApproved={false}
              isVoid={false}
              isPending={false}
              t={t}
            ></EntryFormFooter>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

VehicleInputs.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  vehicle: PropTypes.object.isRequired,
  ous: PropTypes.array.isRequired,
  vehiclesInOU: PropTypes.array.isRequired,
  selectedQVehicle: PropTypes.object.isRequired,
  onSubmitted: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
  getVehiclesInOU: PropTypes.func.isRequired,
  getVehicleInfo: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export const ManageVehicleForm = VehicleInputs;
