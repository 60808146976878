import React from "react";
import { Modal, InputNumber, Row, Col, Typography, Form, Input } from "antd";
import PropTypes from "prop-types";
import {
  inputLocaleFormatter,
  inputLocaleParser,
  regionLocale,
} from "../../common/InputNumberFormatter";
import { useTranslation } from "react-i18next";

const { TextArea } = Input;

const ManageLeaveAdjustmentLeaveTypeForm = ({
  isVisible,
  leaveType,
  onCancel,
  onSave,
  onDataChanged,
  form,
  setIsDirty,
}) => {
  const { t } = useTranslation();

  const saveForm = () => {
    form
      .validateFields()
      .then((values) => {
        values.AdjustmentDay = values.AdjustmentDay ? values.AdjustmentDay : 0;
        values.LTKey = leaveType.LTKey;
        onSave(values);
      })
      .catch(() => {});
  };

  function onChangeAdjustmentDay({
    totalAvailableLeave = form.getFieldValue("TotalAvailableLeave"),
    totalLeave = form.getFieldValue("TotalLeave"),
    adjustmentDay = form.getFieldValue("AdjustmentDay"),
  }) {
    onDataChanged(
      leaveType.LTKey,
      totalAvailableLeave ? totalAvailableLeave : 0,
      totalLeave ? totalLeave : 0,
      adjustmentDay ? adjustmentDay : 0
    );

    form.setFieldsValue({
      NewTotalAvailableLeave: totalAvailableLeave + adjustmentDay,
      NewTotalLeave: totalLeave + adjustmentDay,
    });
  }

  // eslint-disable-next-line
  const ForRefTypography = React.forwardRef(({ text }, ref) => {
    if (!isNaN(text)) {
      text = text.toLocaleString(regionLocale(), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }

    return <Typography>{text}</Typography>;
  });

  return (
    <Modal
      forceRender
      open={isVisible}
      title={t("leaveAdjustmentForm.leave_type_adjustment")}
      okText={t("general.save")}
      onOk={saveForm}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={() => setIsDirty(true)}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("general.leave_type")}
              name="LTCodeLTDesc"
              initialValue={
                leaveType.LTCodeLTDesc === undefined
                  ? undefined
                  : leaveType.LTCodeLTDesc
              }
            >
              <ForRefTypography
                ref={React.createRef()}
                text={leaveType.LTCodeLTDesc}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("leaveAdjustmentForm.leave_balance")}
              name="TotalAvailableLeave"
              initialValue={
                leaveType.TotalAvailableLeave === undefined
                  ? undefined
                  : leaveType.TotalAvailableLeave
              }
            >
              <ForRefTypography
                ref={React.createRef()}
                text={leaveType.TotalAvailableLeave}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("leaveAdjustmentForm.total_leave")}
              name="TotalLeave"
              initialValue={
                leaveType.TotalLeave === undefined
                  ? undefined
                  : leaveType.TotalLeave
              }
            >
              <ForRefTypography
                ref={React.createRef()}
                text={leaveType.TotalLeave}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("leaveAdjustmentForm.adjustment_day")}
              name="AdjustmentDay"
              initialValue={leaveType.AdjustmentDay}
            >
              <InputNumber
                step="1"
                precision={1}
                formatter={inputLocaleFormatter(1)}
                parser={inputLocaleParser(1)}
                onChange={(e) =>
                  onChangeAdjustmentDay({
                    adjustmentDay: e,
                  })
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              label={t("leaveAdjustmentForm.new_leave_balance")}
              name="NewTotalAvailableLeave"
              initialValue={
                leaveType.NewTotalAvailableLeave === undefined
                  ? undefined
                  : leaveType.NewTotalAvailableLeave
              }
            >
              <ForRefTypography
                ref={React.createRef()}
                text={leaveType.NewTotalAvailableLeave}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t("leaveAdjustmentForm.new_total_leave")}
              name="NewTotalLeave"
              initialValue={
                leaveType.NewTotalLeave === undefined
                  ? undefined
                  : leaveType.NewTotalLeave
              }
            >
              <ForRefTypography
                ref={React.createRef()}
                text={leaveType.NewTotalLeave}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={t("general.remarks")}
              name="Remarks"
              rules={[
                // {
                //   required: true,
                //   whitespace: true,
                //   message: t("general.remarks_required_validation"),
                // },
                {
                  max: 500,
                  message: t("general.remarks_max_length_500_validation"),
                },
              ]}
              initialValue={leaveType.Remarks}
            >
              <TextArea
                placeholder={t("general.remarks_placeholder")}
                autoSize={{ minRows: 2, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ManageLeaveAdjustmentLeaveTypeForm.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  leaveType: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};

export default ManageLeaveAdjustmentLeaveTypeForm;
