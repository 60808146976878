import React, { useState, useEffect } from "react";
import { getAllMenus } from "../../../api/menuApi";
import { getAllRoleTypes } from "../../../api/roleTypeApi";
import { toast } from "react-toastify";
import { RoleTypesPermissionByFormTable } from "./RoleTypesPermissionByFormTable";
import { RoleTypesPermissionByRoleTable } from "./RoleTypesPermissionByRoleTable";
import PropTypes from "prop-types";
import { Row, Col, Card, Spin, Tabs, Form } from "antd";
import { useTranslation } from "react-i18next";

export default function RoleTypesPermissionPage({ history }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("0");
  const [menuWaterfall, setMenuWaterfall] = useState([]);
  const [roleWaterfall, setRoleWaterfall] = useState([]);

  useEffect(() => {
    let promises = [getAllMenus(), getAllRoleTypes()];

    function recurvsiveChild(item) {
      if (item.ChildMenu.length === 0) {
        return {
          key: item.MenuKey,
          name: item.MenuName,
          parentMenuKey: item.ParentMenuKey,
          menuUrl: item.MenuUrl,
        };
      } else {
        return {
          key: item.MenuKey,
          name: item.MenuName,
          parentMenuKey: item.ParentMenuKey,
          menuUrl: item.MenuUrl,
          children: item.ChildMenu.map((child) => {
            return recurvsiveChild(child);
          }),
        };
      }
    }

    Promise.all(promises)
      .then((values) => {
        let tempMenu = [];
        let tempRole = [];
        values[0].map((item) => {
          return tempMenu.push({
            key: item.MenuKey,
            name: item.MenuName,
            parentMenuKey: item.ParentMenuKey,
            menuUrl: item.MenuUrl,
            children:
              item.ChildMenu.length !== 0
                ? item.ChildMenu.map((child) => {
                    return recurvsiveChild(child);
                  })
                : null,
          });
        });

        values[1].map((item) => {
          return tempRole.push({
            key: item.RoleTypeKey,
            code: item.RoleTypeCode,
          });
        });
        setMenuWaterfall(tempMenu);
        setRoleWaterfall(tempRole);
        setIsLoading(false);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [t]);

  function editMenu(menuKey) {
    history("/home/roletypesperm/roletypespermbyform/" + menuKey);
  }

  function editRole(roleKey) {
    history("/home/roletypesperm/roletypespermbyrole/" + roleKey);
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  const items = [
    {
      key: "0",
      label: t("roleTypesPermissionForm.by_form"),
      children: (
        <RoleTypesPermissionByFormTable
          data={menuWaterfall.filter((x) => x.name !== "Dashboard")}
          onEdited={editMenu}
        />
      ),
    },
    {
      key: "1",
      label: t("roleTypesPermissionForm.by_role"),
      children: (
        <RoleTypesPermissionByRoleTable
          data={roleWaterfall}
          onEdited={editRole}
        />
      ),
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Form layout={"vertical"}>
        <Row>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            xl={{ span: 24, offset: 0 }}
            xxl={{ span: 16, offset: 4 }}
          >
            <Card
              title={
                <span className="form-title">
                  {t("roleTypesPermissionForm.role_type_permission")}
                </span>
              }
            >
              <Tabs
                defaultActiveKey="0"
                activeKey={activeTab}
                onChange={(value) => setActiveTab(value)}
                items={items}
              ></Tabs>
            </Card>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

RoleTypesPermissionPage.propTypes = {
  history: PropTypes.func.isRequired,
};
