import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import PublishControlForm from "../../forms/Admin/PublishControlForm";
import {
  getPublishControl,
  addPublishControl,
  updatePublishControl,
} from "../../../api/publishControlApi";
import PublishControlModel from "../../model/Master/PublishControlModel";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import {
  StopOutlined,
  CheckOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newPublishControl = PublishControlModel({});

export default function PublishControlPage() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [publishControlDataSource, setPublishControlDataSource] = useState([]);
  const [currentYear, setCurrentYear] = useState(dayjs().year());
  const [datePickerState, setDatePickerState] = useState({
    isOpen: false,
    time: null,
  });

  useEffect(getPublishControls, []);

  const StatusByHexColor = Object.freeze({
    O: "#e81d62", //Not Ready
    C: "#fc8d56", //Ready
    P: "#8ac249", //Published
    X: "#8ac249", //Published (Invalid to unpublish)
  });

  const StatusByDesc = Object.freeze({
    O: "Not Ready", //Not Ready
    C: "Ready", //Ready
    P: "Published", //Published
    X: "Published", //Published (Invalid to unpublish)
  });

  const StatusByIcon = Object.freeze({
    O: <StopOutlined />, //Not Ready
    C: <CheckOutlined />, //Ready
    P: <CheckCircleOutlined />, //Published
    X: <CheckCircleOutlined />, //Published (Invalid to unpublish)
  });

  function savePublishControl(values, type) {
    let publishControlData = {};
    let toastStatus = 0;

    if (type === "P1") {
      publishControlData = {
        ...newPublishControl,
        ...values.PCData1[0],
        Type: "P1",
        OUKey: values.OUKey,
        Yr: values.Year,
        Mth: values.Mth,
        IsPublish: values.StatusC1 === "C" ? true : false,
      };

      toastStatus = values.StatusC1 === "P" ? 1 : 0;
    } else {
      publishControlData = {
        ...newPublishControl,
        ...values.PCData[0],
        Type: values.Type,
        OUKey: values.OUKey,
        Yr: values.Year,
        Mth: values.Mth,
        IsPublish: values.Status === "C" ? true : false,
      };

      toastStatus = values.Status === "P" ? 1 : 0;
    }

    let upsertPublishControl =
      publishControlData.PublishControlKey !== -1
        ? updatePublishControl
        : addPublishControl;

    setIsLoading(true);
    upsertPublishControl(publishControlData)
      .then((result) => {
        if (result.data) {
          getPublishControls();

          if (toastStatus === 0) {
            toast.success(t("general.record_published_successfully"));
          } else {
            toast.success(t("general.record_reverted_successfully"));
          }
        }
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          publishControlData.PublishControlKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.message);
      });
  }

  function getPublishControls() {
    getPublishControlDataSource(currentYear);
  }

  function getPublishControlDataSource(year) {
    setIsLoading(true);

    let promises = [getPublishControl(year)];

    Promise.all(promises)
      .then((values) => {
        let y = 0;
        let tempData = [];

        if (values[0]) {
          for (y = 0; y < values[0].length; y++) {
            let userLeaveApplicationData = values[0][y] ? values[0][y] : [];

            userLeaveApplicationData.January = {
              Status: userLeaveApplicationData.January,
              StatusColor: StatusByHexColor[userLeaveApplicationData.January],
              StatusIcon: StatusByIcon[userLeaveApplicationData.January],
              StatusDesc: StatusByDesc[userLeaveApplicationData.January],
              StatusC1: userLeaveApplicationData.JanuaryC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.JanuaryC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.JanuaryC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.JanuaryC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 1,
              Month: "January",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 1 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 1 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.February = {
              Status: userLeaveApplicationData.February,
              StatusColor: StatusByHexColor[userLeaveApplicationData.February],
              StatusIcon: StatusByIcon[userLeaveApplicationData.February],
              StatusDesc: StatusByDesc[userLeaveApplicationData.February],
              StatusC1: userLeaveApplicationData.FebruaryC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.FebruaryC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.FebruaryC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.FebruaryC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 2,
              Month: "February",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 2 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 2 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.March = {
              Status: userLeaveApplicationData.March,
              StatusColor: StatusByHexColor[userLeaveApplicationData.March],
              StatusIcon: StatusByIcon[userLeaveApplicationData.March],
              StatusDesc: StatusByDesc[userLeaveApplicationData.March],
              StatusC1: userLeaveApplicationData.MarchC1,
              StatusColorC1: StatusByHexColor[userLeaveApplicationData.MarchC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.MarchC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.MarchC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 3,
              Month: "March",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 3 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 3 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.April = {
              Status: userLeaveApplicationData.April,
              StatusColor: StatusByHexColor[userLeaveApplicationData.April],
              StatusIcon: StatusByIcon[userLeaveApplicationData.April],
              StatusDesc: StatusByDesc[userLeaveApplicationData.April],
              StatusC1: userLeaveApplicationData.AprilC1,
              StatusColorC1: StatusByHexColor[userLeaveApplicationData.AprilC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.AprilC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.AprilC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 4,
              Month: "April",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 4 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 4 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.May = {
              Status: userLeaveApplicationData.May,
              StatusColor: StatusByHexColor[userLeaveApplicationData.May],
              StatusIcon: StatusByIcon[userLeaveApplicationData.May],
              StatusDesc: StatusByDesc[userLeaveApplicationData.May],
              StatusC1: userLeaveApplicationData.MayC1,
              StatusColorC1: StatusByHexColor[userLeaveApplicationData.MayC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.MayC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.MayC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 5,
              Month: "May",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 5 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 5 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.June = {
              Status: userLeaveApplicationData.June,
              StatusColor: StatusByHexColor[userLeaveApplicationData.June],
              StatusIcon: StatusByIcon[userLeaveApplicationData.June],
              StatusDesc: StatusByDesc[userLeaveApplicationData.June],
              StatusC1: userLeaveApplicationData.JuneC1,
              StatusColorC1: StatusByHexColor[userLeaveApplicationData.JuneC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.JuneC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.JuneC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 6,
              Month: "June",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 6 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 6 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.July = {
              Status: userLeaveApplicationData.July,
              StatusColor: StatusByHexColor[userLeaveApplicationData.July],
              StatusIcon: StatusByIcon[userLeaveApplicationData.July],
              StatusDesc: StatusByDesc[userLeaveApplicationData.July],
              StatusC1: userLeaveApplicationData.JulyC1,
              StatusColorC1: StatusByHexColor[userLeaveApplicationData.JulyC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.JulyC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.JulyC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 7,
              Month: "July",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 7 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 7 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.August = {
              Status: userLeaveApplicationData.August,
              StatusColor: StatusByHexColor[userLeaveApplicationData.August],
              StatusIcon: StatusByIcon[userLeaveApplicationData.August],
              StatusDesc: StatusByDesc[userLeaveApplicationData.August],
              StatusC1: userLeaveApplicationData.AugustC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.AugustC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.AugustC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.AugustC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 8,
              Month: "August",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 8 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 8 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.September = {
              Status: userLeaveApplicationData.September,
              StatusColor: StatusByHexColor[userLeaveApplicationData.September],
              StatusIcon: StatusByIcon[userLeaveApplicationData.September],
              StatusDesc: StatusByDesc[userLeaveApplicationData.September],
              StatusC1: userLeaveApplicationData.SeptemberC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.SeptemberC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.SeptemberC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.SeptemberC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 9,
              Month: "September",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 9 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 9 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.October = {
              Status: userLeaveApplicationData.October,
              StatusColor: StatusByHexColor[userLeaveApplicationData.October],
              StatusIcon: StatusByIcon[userLeaveApplicationData.October],
              StatusDesc: StatusByDesc[userLeaveApplicationData.October],
              StatusC1: userLeaveApplicationData.OctoberC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.OctoberC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.OctoberC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.OctoberC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 10,
              Month: "October",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 10 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 10 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.November = {
              Status: userLeaveApplicationData.November,
              StatusColor: StatusByHexColor[userLeaveApplicationData.November],
              StatusIcon: StatusByIcon[userLeaveApplicationData.November],
              StatusDesc: StatusByDesc[userLeaveApplicationData.November],
              StatusC1: userLeaveApplicationData.NovemberC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.NovemberC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.NovemberC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.NovemberC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 11,
              Month: "November",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 11 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 11 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.December = {
              Status: userLeaveApplicationData.December,
              StatusColor: StatusByHexColor[userLeaveApplicationData.December],
              StatusIcon: StatusByIcon[userLeaveApplicationData.December],
              StatusDesc: StatusByDesc[userLeaveApplicationData.December],
              StatusC1: userLeaveApplicationData.DecemberC1,
              StatusColorC1:
                StatusByHexColor[userLeaveApplicationData.DecemberC1],
              StatusIconC1: StatusByIcon[userLeaveApplicationData.DecemberC1],
              StatusDescC1: StatusByDesc[userLeaveApplicationData.DecemberC1],
              TypeC1: "P1",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 12,
              Month: "December",
              Type: "P",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 12 && x.Type === "P"
              ),
              PCData1: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 12 && x.Type === "P1"
              ),
            };
            userLeaveApplicationData.EA = {
              Status: userLeaveApplicationData.EA,
              StatusColor: StatusByHexColor[userLeaveApplicationData.EA],
              StatusIcon: StatusByIcon[userLeaveApplicationData.EA],
              StatusDesc: StatusByDesc[userLeaveApplicationData.EA],
              StatusC1: "NA",
              OUKey: userLeaveApplicationData.OUKey,
              OUCodeOUDesc: userLeaveApplicationData.OUCodeOUDesc,
              Year: year,
              Mth: 0,
              Month: "EA",
              Type: "E",
              PCData: userLeaveApplicationData.Details.filter(
                (x) => x.Mth === 0 && x.Type === "E"
              ),
            };

            tempData.push(userLeaveApplicationData);
          }
        }
        setPublishControlDataSource(tempData);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  return (
    <PublishControlForm
      isLoading={isLoading}
      publishControlDataSource={publishControlDataSource}
      getPublishControlDataSource={getPublishControlDataSource}
      setCurrentYear={setCurrentYear}
      datePickerState={datePickerState}
      setDatePickerState={setDatePickerState}
      onPublish={savePublishControl}
      form={form}
    />
  );
}

PublishControlPage.propTypes = {
  history: PropTypes.func.isRequired,
};
