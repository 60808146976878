import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { 
  getNotifConfgByKey, 
  updateNotifConfg 
} from "../../../api/notificationConfgApi";
import  ManageNotifConfgForm from "../../forms/AdminPortal/ManageNotifConfgForm";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newNotifConfg = {
  NotifConfgKey: "0",
  UserKey: "0",
  NotifUserConfg: [],
  UpdatedBy: -1,
  UpdatedByCode: "",
  UpdatedOn: new Date(),
};

export default function ManageNotifConfgPage({ history, setIsDirty}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [notifConfg, setNotifConfg] = useState(newNotifConfg);
  const [isLoading, setIsLoading] = useState(false);
  const { UserKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    setIsLoading(true);

    let editKey = UserKey;
    if (editKey > 0) {
      getNotifConfgByKey(editKey).then((results) => {
        if (results) {
          if (results.UpdatedDate) {
            results.UpdatedOn = results.UpdatedDate;
          }
        }
        setNotifConfg(results ? results : newNotifConfg);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
    }      
  }, [UserKey, t]);

  function saveNotifConfg(values) {
    if (values) {
      let notifConfgSubmit = {
        ...notifConfg,
        ...values
      };
      setIsDirty(false);
      setIsLoading(true);

      updateNotifConfg(notifConfgSubmit.NotifUserConfg)
      .then(() => {
        setIsLoading(false);

        let filterOuKey = 0,
          filterDeptKey = 0;

        if (location.state) {
          if (location.state.filterOuKey) {
            filterOuKey = location.state.filterOuKey;
          }
          if (location.state.filterDeptKey) {
            filterDeptKey = location.state.filterDeptKey;
          }
        }

        history("/home/adminportal/settings/notification", {
          state: {
            filterOuKey: filterOuKey,
            filterDeptKey: filterDeptKey,
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });

        let message =
          notifConfgSubmit.NotifConfgKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          notifConfgSubmit.NotifConfgKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");

        showErrorMessage(message, error);
      });
    }
  }

  function cancelEditNotifConfg() {
    let filterOuKey = 0,
      filterDeptKey = 0;

    if (location.state) {
      if (location.state.filterOuKey) {
        filterOuKey = location.state.filterOuKey;
      }
      if (location.state.filterDeptKey) {
        filterDeptKey = location.state.filterDeptKey;
      }
    }

    history("/home/adminportal/settings/notification", {
      state: {
        filterOuKey: filterOuKey,
        filterDeptKey: filterDeptKey,
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  return (
    <ManageNotifConfgForm 
      isLoading={isLoading}
      notifConfg={notifConfg}
      onSubmitted={saveNotifConfg}
      onCancelled={cancelEditNotifConfg}
      onDataChanged={setNotifConfg}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageNotifConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};