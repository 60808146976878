import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ManageAppraisalRatingForm from "../../forms/Master/ManageAppraisalRatingForm";
import PropTypes from "prop-types";
import AppraisalRatingModel from "../../model/AppraisalTemplate/AppraisalRatingModel";
import {
  getAppraisalRating,
  addAppraisalRating,
  updateAppraisalRating,
} from "../../../api/appraisalRatingApi";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newAppraisalRating = AppraisalRatingModel({});

export default function ManageAppraisalRatingPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [appraisalRating, setAppraisalRating] = useState(newAppraisalRating);
  const [isLoading, setIsLoading] = useState(true);
  const { ARKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [];
    let editKey = ARKey;
    if (editKey > 0) {
      promises.push(getAppraisalRating(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        if (values[0]) {
          if (values[0].UpdatedDate) {
            values[0].UpdatedOn = values[0].UpdatedDate;
          }
          if (values[0].CreatedDate) {
            values[0].CreatedOn = values[0].CreatedDate;
          }
        }

        setAppraisalRating(values[0] ? values[0] : newAppraisalRating);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [ARKey, t]);

  function saveAppraisalRating(values) {
    let appraisalRatingSubmit = {
      ...appraisalRating,
      Active: values.Active,
      ARCode: values.ARCode.trim(),
      ARDesc: values.ARDesc.trim(),
      ARDecimalPoints: values.ARDecimalPoints,
    };

    let upsertAppraisalRating =
      appraisalRatingSubmit.ARKey !== -1
        ? updateAppraisalRating
        : addAppraisalRating;

    setIsLoading(true);
    upsertAppraisalRating(appraisalRatingSubmit)
      .then(() => {
        setIsLoading(false);
        setIsDirty(false);
        history("/home/appraisalratings", {
          state: {
            force: true,
            currentPage: location.state
              ? location.state.currentPage
                ? location.state.currentPage
                : 1
              : 1,
          },
        });
        let message =
          appraisalRatingSubmit.ARKey !== -1
            ? t("general.record_updated_successfully")
            : t("general.record_added_successfully");
        toast.success(message);
      })
      .catch((error) => {
        setIsLoading(false);
        let message =
          appraisalRatingSubmit.ARKey !== -1
            ? t("general.fail_to_update_record")
            : t("general.fail_to_add_record");
        toast.error(message + error.response.data.Message);
      });
  }

  function cancelInputAppraisalRating() {
    history("/home/appraisalRatings", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  return (
    <ManageAppraisalRatingForm
      isLoading={isLoading}
      appraisalRating={appraisalRating}
      onAppraisalRatingChanged={setAppraisalRating}
      onSubmitted={saveAppraisalRating}
      onCancelled={cancelInputAppraisalRating}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAppraisalRatingPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
