import React, { useEffect } from "react";
import {
  Table,
  Spin,
  Card,
  Avatar,
  Divider,
  Row,
  Col,
  DatePicker,
  Tooltip,
  Form,
} from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { SelectionInput } from "../../common/SelectionInput";
import { useTranslation } from "react-i18next";

const { MonthPicker } = DatePicker;

const RoomAvailabilityDisplayForm = ({
  isLoading,
  roomsAvailability,
  getRoomsAvailabilityDataSource,
  setIsByYrMth,
  setCurrentDate,
  form,
  allOus,
  allRooms,
  isByYrMth,
  filterRecord,
  scrollToView,
}) => {
  const { t } = useTranslation();
  const viewOption = [
    { Value: 0, Text: t("general.by_month_year") },
    { Value: 1, Text: t("general.by_day") },
  ];

  useEffect(() => {
    scrollToView(isByYrMth);
  }, [isByYrMth, scrollToView]);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        getRoomsAvailabilityDataSource(isByYrMth, values.MonthYear.$d);
      })
      .catch(() => {});
  };

  function onDateChanged(value) {
    if (value) {
      setCurrentDate(value);

      form
        .validateFields()
        .then(() => {
          getRoomsAvailabilityDataSource(isByYrMth, value.$d);
        })
        .catch(() => {});
    }
  }

  const AvailabilityIndicators = (value) => (
    <Tooltip
      title={
        value
          ? isByYrMth
            ? value[0].day + ", " + value.length + t("general.bookings")
            : value
                .map((x, index) => <div key={index}>{x.content}</div>)
                .reduce((acc, x) => (acc === null ? [x] : [acc, x]), null)
          : ""
      }
    >
      <Avatar
        shape="round"
        size="small"
        style={{
          width: "17px",
          height: "17px",
          backgroundColor: value ? "#ff0000" : "#ffffff",
        }}
      />
    </Tooltip>
  );

  const columns = [
    {
      title: t("meetingRoomForm.meeting_room_code"),
      dataIndex: "RoomCode",
      key: "RoomCode",
      width: 120,
      fixed: "left",
      sorter: (a, b) => a.RoomCode.localeCompare(b.RoomCode),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.description"),
      dataIndex: "RoomDesc",
      key: "RoomDesc",
      width: 300,
      fixed: "left",
      sorter: (a, b) => a.RoomDesc.localeCompare(b.RoomDesc),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.date"),
      children: [
        {
          title: "1",
          dataIndex: "1",
          key: "1",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2",
          dataIndex: "2",
          key: "2",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "3",
          dataIndex: "3",
          key: "3",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "4",
          dataIndex: "4",
          key: "4",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "5",
          dataIndex: "5",
          key: "5",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "6",
          dataIndex: "6",
          key: "6",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "7",
          dataIndex: "7",
          key: "7",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "8",
          dataIndex: "8",
          key: "8",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "9",
          dataIndex: "9",
          key: "9",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "10",
          dataIndex: "10",
          key: "10",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "11",
          dataIndex: "11",
          key: "11",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "12",
          dataIndex: "12",
          key: "12",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "13",
          dataIndex: "13",
          key: "13",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "14",
          dataIndex: "14",
          key: "14",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "15",
          dataIndex: "15",
          key: "15",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "16",
          dataIndex: "16",
          key: "16",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "17",
          dataIndex: "17",
          key: "17",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "18",
          dataIndex: "18",
          key: "18",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "19",
          dataIndex: "19",
          key: "19",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "20",
          dataIndex: "20",
          key: "20",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "21",
          dataIndex: "21",
          key: "21",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "22",
          dataIndex: "22",
          key: "22",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "23",
          dataIndex: "23",
          key: "23",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "24",
          dataIndex: "24",
          key: "24",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "25",
          dataIndex: "25",
          key: "25",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "26",
          dataIndex: "26",
          key: "26",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "27",
          dataIndex: "27",
          key: "27",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "28",
          dataIndex: "28",
          key: "28",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "29",
          dataIndex: "29",
          key: "29",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "30",
          dataIndex: "30",
          key: "30",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "31",
          dataIndex: "31",
          key: "31",
          width: 35,
          align: "center",
          render: AvailabilityIndicators,
        },
      ],
    },
  ];

  const timeColumns = [
    {
      title: t("meetingRoomForm.meeting_room_code"),
      dataIndex: "RoomCode",
      key: "RoomCode",
      width: 120,
      fixed: "left",
      sorter: (a, b) => a.RoomCode.localeCompare(b.RoomCode),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.description"),
      dataIndex: "RoomDesc",
      key: "RoomDesc",
      width: 300,
      fixed: "left",
      sorter: (a, b) => a.RoomDesc.localeCompare(b.RoomDesc),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: t("general.time"),
      children: [
        {
          title: "0000",
          dataIndex: "0000",
          key: "0000",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0030",
          dataIndex: "0030",
          key: "0030",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0100",
          dataIndex: "0100",
          key: "0100",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0130",
          dataIndex: "0130",
          key: "0130",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0200",
          dataIndex: "0200",
          key: "0200",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0230",
          dataIndex: "0230",
          key: "0230",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0300",
          dataIndex: "0300",
          key: "0300",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0330",
          dataIndex: "0330",
          key: "0330",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0400",
          dataIndex: "0400",
          key: "0400",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0430",
          dataIndex: "0430",
          key: "0430",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0500",
          dataIndex: "0500",
          key: "0500",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0530",
          dataIndex: "0530",
          key: "0530",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0600",
          dataIndex: "0600",
          key: "0600",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0630",
          dataIndex: "0630",
          key: "0630",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0700",
          dataIndex: "0700",
          key: "0700",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0730",
          dataIndex: "0730",
          key: "0730",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0800",
          dataIndex: "0800",
          key: "0800",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0830",
          dataIndex: "0830",
          key: "0830",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0900",
          dataIndex: "0900",
          key: "0900",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "0930",
          dataIndex: "0930",
          key: "0930",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1000",
          dataIndex: "1000",
          key: "1000",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1030",
          dataIndex: "1030",
          key: "1030",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1100",
          dataIndex: "1100",
          key: "1100",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1130",
          dataIndex: "1130",
          key: "1130",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1200",
          dataIndex: "1200",
          key: "1200",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1230",
          dataIndex: "1230",
          key: "1230",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1300",
          dataIndex: "1300",
          key: "1300",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1330",
          dataIndex: "1330",
          key: "1330",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1400",
          dataIndex: "1400",
          key: "1400",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1430",
          dataIndex: "1430",
          key: "1430",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1500",
          dataIndex: "1500",
          key: "1500",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1530",
          dataIndex: "1530",
          key: "1530",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1600",
          dataIndex: "1600",
          key: "1600",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1630",
          dataIndex: "1630",
          key: "1630",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1700",
          dataIndex: "1700",
          key: "1700",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1730",
          dataIndex: "1730",
          key: "1730",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1800",
          dataIndex: "1800",
          key: "1800",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1830",
          dataIndex: "1830",
          key: "1830",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1900",
          dataIndex: "1900",
          key: "1900",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "1930",
          dataIndex: "1930",
          key: "1930",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2000",
          dataIndex: "2000",
          key: "2000",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2030",
          dataIndex: "2030",
          key: "2030",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2100",
          dataIndex: "2100",
          key: "2100",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2130",
          dataIndex: "2130",
          key: "2130",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2200",
          dataIndex: "2200",
          key: "2200",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2230",
          dataIndex: "2230",
          key: "2230",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2300",
          dataIndex: "2300",
          key: "2300",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
        {
          title: "2330",
          dataIndex: "2330",
          key: "2330",
          width: 48.09,
          align: "center",
          render: AvailabilityIndicators,
        },
      ],
    },
  ];

  const getResponsiveColumns = () => {
    if (form.getFieldValue("MonthYear") === undefined) return;
    if (isByYrMth) {
      let respMonthField = form.getFieldValue("MonthYear").month() + 1;
      let respYearField = form.getFieldValue("MonthYear").year();

      if (
        respMonthField === 4 ||
        respMonthField === 6 ||
        respMonthField === 9 ||
        respMonthField === 11
      ) {
        columns[2].children = columns[2].children.filter(
          (item) => item.key !== "31"
        );
        return columns;
      } else if (respMonthField === 2) {
        if (respYearField % 4 === 0) {
          columns[2].children = columns[2].children.filter(
            (item) => item.key !== "30" && item.key !== "31"
          );
          return columns;
        } else {
          columns[2].children = columns[2].children.filter(
            (item) =>
              item.key !== "29" && item.key !== "30" && item.key !== "31"
          );
          return columns;
        }
      } else {
        return columns;
      }
    } else {
      return timeColumns;
    }
  };

  function onChangeViewOption(value) {
    let byYrMth = value.value === 1 ? false : true;
    setIsByYrMth(byYrMth);
    let now = new Date();
    setCurrentDate(now);

    form.setFieldsValue({
      MonthYear: dayjs(now),
      Date: dayjs(now),
    });
    getRoomsAvailabilityDataSource(byYrMth, now);
  }

  function onChangeOU(value) {
    form.setFieldsValue({
      Room: { key: 0, value: 0, label: "All" },
    });

    filterRecord(value.value, 0);
  }

  function onChangeRoom(value) {
    let ou = form.getFieldValue("OU");
    filterRecord(ou.value, value.value);
  }

  return (
    <Card
      title={
        <span className="form-title">
          {t("roomRequisitionForm.display_meeting_room_availability")}
        </span>
      }
    >
      <Spin spinning={isLoading}>
        <Form form={form} layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.view_option")}
                name="ViewBy"
                initialValue={{ value: isByYrMth === false ? 1 : 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={viewOption}
                  valueProp={"Value"}
                  textProp={"Text"}
                  placeholder={t("general.view_option_placeholder")}
                  onChange={onChangeViewOption}
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ display: isByYrMth ? "" : "none" }}
            >
              <Form.Item
                label={t("general.month_year")}
                name="MonthYear"
                rules={[
                  {
                    required: true,
                    message: t("general.month_year_required_validation"),
                  },
                ]}
                initialValue={dayjs(dayjs(), "MM/YYYY")}
              >
                <MonthPicker
                  allowClear={false}
                  placeholder={t("general.month_year_placeholder")}
                  format="MM/YYYY"
                  onChange={onDateChanged}
                />
              </Form.Item>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={6}
              lg={6}
              xl={6}
              style={{ display: isByYrMth ? "none" : "" }}
            >
              <Form.Item
                label={t("general.date")}
                name="Date"
                rules={[
                  {
                    required: true,
                    message: t("general.date_required_validation"),
                  },
                ]}
                initialValue={dayjs(dayjs(), "DD/MM/YYYY")}
              >
                <DatePicker
                  allowClear={false}
                  placeholder={t("general.date_placeholder")}
                  format="DD/MM/YYYY"
                  onChange={onDateChanged}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("general.operating_unit")}
                name="OU"
                initialValue={{ value: 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allOus}
                  valueProp={"OUKey"}
                  textProp={"OUCodeOUDesc"}
                  placeholder={t("general.operating_unit_placeholder")}
                  onChange={onChangeOU}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Form.Item
                label={t("roomRequisitionForm.meeting_room")}
                name="Room"
                initialValue={{ value: 0 }}
              >
                <SelectionInput
                  ref={React.createRef()}
                  labelInValue={true}
                  data={allRooms}
                  valueProp={"RoomKey"}
                  textProp={"RoomCodeDesc"}
                  placeholder={t(
                    "roomRequisitionForm.meeting_room_placeholder"
                  )}
                  onChange={onChangeRoom}
                  isIncludeAllOption={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Table
          bordered
          dataSource={roomsAvailability}
          columns={getResponsiveColumns()}
          size="small"
          rowKey={(record) => record.RoomKey}
          pagination={true}
          scroll={{ x: "max-content", y: "calc(100vh - 100px)" }}
        />

        <Divider orientation="left" style={{ color: "#0070cc" }}>
          {t("general.legend")}
        </Divider>
        <Row>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #ccc",
                backgroundColor: "#ffffff",
              }}
            />{" "}
            {t("general.available")}
          </Col>
          <Col xs={12} lg={4}>
            <Avatar
              shape="round"
              size="small"
              style={{
                border: "3px solid #fff",
                backgroundColor: "#ff0000",
              }}
            />{" "}
            {t("general.in_used")}
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};

RoomAvailabilityDisplayForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  roomsAvailability: PropTypes.array.isRequired,
  getRoomsAvailabilityDataSource: PropTypes.func.isRequired,
  setIsByYrMth: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  allOus: PropTypes.array.isRequired,
  allRooms: PropTypes.array.isRequired,
  isByYrMth: PropTypes.bool.isRequired,
  filterRecord: PropTypes.func.isRequired,
  scrollToView: PropTypes.func.isRequired,
};

export default RoomAvailabilityDisplayForm;
