import React from "react";
import { Spin, Card, List } from "antd";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

export default function AnnouncementWidget({
  isLoading,
  announcement,
  history,
}) {
  const { t } = useTranslation();

  function onItemClick(data) {
    history("/home/announcementdisplay/" + data.AnnouncementKey);
  }

  return (
    <Card
      title={
        <span style={{ color: "#0070cc" }}>
          {t("announcementWidget.announcement")}
        </span>
      }
      className="dashboard-card"
    >
      <Spin
        style={{ display: isLoading ? "block" : "none", marginTop: "150px" }}
      />
      <div style={{ display: !isLoading ? "block" : "none" }}>
        <div
          style={{
            //marginTop: "10px",
            height: "370px",
            backgroundColor: "#f5f7fa",
            borderRadius: "10px",
            overflow: "auto",
          }}
        >
          <List
            itemLayout="horizontal"
            dataSource={announcement.announcement}
            renderItem={(item) => (
              <List.Item
                onClick={() => onItemClick && onItemClick(item)}
                style={{
                  paddingTop: 12,
                  paddingBottom: 12,
                  paddingRight: 24,
                  paddingLeft: 24,
                  overflow: "hidden",
                  cursor: "pointer",
                  transition: "all .3s",
                }}
              >
                <List.Item.Meta
                  style={{ padding: "0 10px 0 0" }}
                  title={
                    <div
                      style={{
                        marginBottom: 8,
                      }}
                    >
                      {item.AnnouncementTitle}
                    </div>
                  }
                  description={item.AnnouncementDesc}
                />
              </List.Item>
            )}
          />
        </div>
      </div>
    </Card>
  );
}

AnnouncementWidget.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  announcement: PropTypes.object.isRequired,
  history: PropTypes.func.isRequired,
};
