import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function roleAssignmentReducer(
  state = initialState.roleAssignment,
  action = {}
) {
  switch (action.type) {
    case types.LOAD_ESSUSER_SUCCESS:
      return action.roleassignments;
    default:
      return state;
  }
}
