import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "Clinic/";

export function getAllClinics() {
  return axios({
    method: "GET",
    url: base + "GetAllClinics",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClinic(clinicKey) {
  return axios({
    method: "GET",
    url: base + "GetClinic",
    headers: authHeader(),
    params: { key: clinicKey },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getClinicCode() {
  return axios({
    method: "GET",
    url: base + "GetClinicCode",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function addClinic(clinic) {
  return axios({
    method: "POST",
    url: base + "AddClinic",
    headers: authHeader(),
    data: clinic,
  })
    .then()
    .catch(handleError);
}

export function updateClinic(clinic) {
  return axios({
    method: "PUT",
    url: base + "UpdateClinic",
    headers: authHeader(),
    params: { key: clinic.ClinicKey },
    data: clinic,
  })
    .then()
    .catch(handleError);
}

export function deleteClinic(clinicKey) {
  return axios({
    method: "DELETE",
    url: base + "deleteClinic",
    headers: authHeader(),
    params: { key: clinicKey },
  })
    .then()
    .catch(handleError);
}
