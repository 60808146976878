import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Axios from "axios";
import { dataSourceService } from "../../../api/employeeApi";
import { changePassword } from "../../../api/forgotPasswordApi";
import EmployeeForm from "../../forms/Profile/EmployeeForm";
import dayjs from "dayjs";
import { ENVIRONMENT } from "./../../../api/api-config";
import { v4 as uuidv4 } from "uuid";
import { RowState } from "../../model/common/RowState";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const dateFormat = "YYYY/MM/DD";

export default function EmployeePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  const [allBanks, setAllBanks] = useState([]);
  const [allCountrys, setAllCountrys] = useState([]);

  const [flag, setFlag] = useState({
    isFirstLoad: true,
    isLoading: true,
    isLoadingAttch: false,
    isAllDSDone: false,
    PhyBankAcc: true,
    BNMCode: true,
    MaritalStatus: true,
    SpouseName: true,
    SpouseDOB: true,
    SpouseDOM: true,
    SpouseID: true,
    IsSpouseWork: true,
    IsDisableSpouse: true,
  });

  const [showSalary, setSalary] = useState(false);

  const [uuid] = useState(uuidv4());
  const [attachID, setAttachID] = useState("");
  const [cntImage, setCntImage] = useState(0);
  const [fileList, setFileList] = useState([]);
  const [attachmentDataSource, setAttachmentDataSource] = useState([]);
  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const [region, setRegion] = useState("M");

  const initialFlag = {
    PhyBankAcc: true,
    BNMCode: true,
    MaritalStatus: true,
    SpouseName: true,
    SpouseDOB: true,
    SpouseDOM: true,
    SpouseID: true,
    IsSpouseWork: true,
    IsDisableSpouse: true,
  };

  const [empyDataSource, setEmpyDataSource] = useState({
    BNMCode: "",
    EmergContactName: "",
    EmergContactTel: "",
    EmergContactRelate: "",
    DeptKey: "",
    DeptCodeDeptDesc: "",
    DOB: "",
    Email: "",
    EmpyID: "",
    EmpyKey: "",
    EmpyName: "",
    EPFNo: "",
    BPJSKetenagakerjaanRefNo: "",
    BPJSPensiunRefNo: "",
    BPJSKesehatanRefNo: "",
    NPWPI: "",
    EmpyPerMasPhotoUrl: "",
    EnableEmailPayslip: "",
    GradeKey: "",
    GradeDesc: "",
    HiredDate: "",
    IDNo: "",
    Fax: "",
    FirstDayWork: "",
    LastDayWork: "",
    LoginEmail: "",
    RejoinDate: "",
    MRate: "",
    MaritalStatus: "",
    Mobile: "",
    NationalityKey: "",
    NationalDesc: "",
    OUKey: "",
    OUCode: "",
    OUCodeOUDesc: "",
    PassportNo: "",
    PermStreet: "",
    PermPostCode: "",
    PermCity: "",
    PermState: "",
    PermCtryKey: "",
    MailStreet: "",
    MailPostCode: "",
    MailCity: "",
    MailState: "",
    MailCtryKey: "",
    PostKey: "",
    PostDesc: "",
    PhyBankAcc: "",
    RaceKey: "",
    RaceDesc: "",
    RowState: 0,
    Status: -1,
    SOCSONo: "",
    SpouseName: "",
    SpouseDOB: "1900/01/01",
    SpouseDOM: "1900/01/01",
    SpouseID: "",
    Tel: "",
    isSOCSOIC: false,
    IsSpouseWork: false,
    IsDisableSpouse: false,
    TaxNo: "",
    TaxStatus: "",
    empyChildDetails: [
      {
        EmpyChildDetKey: "",
        EmpyKey: "",
        EmpyChildName: "",
        EmpyChildDOB: "",
        EduLevel: "",
        SchName: "",
        IDNo: "",
        isSelect: false,
        RowState: 0,
      },
    ],
    empyEduDetails: [
      {
        EmpyEduDetKey: "",
        EmpyKey: "",
        Education: "",
        Remarks: "",
        Year: 1900,
        isSelect: false,
        RowState: 0,
      },
    ],
    attaches: [
      {
        AttchKey: 0,
        FileName: "",
        Remarks: "",
        UploadedUrl: null,
        RowState: 0,
        BlobUri: "",
        SecuredURL: "",
      },
    ],
  });

  const [childDataSource, setChildDataSource] = useState([]);
  const [deletedChildDataSource, setDeletedChildDataSource] = useState([]);

  const [eduDataSource, setEduDataSource] = useState([]);
  const [deletedEduDataSource, setDeletedEduDataSource] = useState([]);

  const [aprDataSource, setAprDataSource] = useState([
    {
      EmpyKey: -1,
      Category: "",
      Field: "",
      EmpyEduDetKey: -1,
      Yr: 1900,
      Education: "",
      Remarks: "",
      EmpyChildDetKey: -1,
      EmpyChildName: "",
      EmpyChildDOB: "1900/01/01",
      EduLevel: "",
      SchName: "",
      IDNo: "",
      BNMCode: "",
      PhyBankAcc: "",
      MaritalStatus: "",
      SpouseName: "",
      SpouseID: "",
      IsSpouseWork: false,
      IsDisableSpouse: false,
      AprStatus: "PD",
    },
  ]);

  const [initialEmpyDataSource, setInitialEmpyDataSource] = useState({});
  const [initialCombinedEmpyDataSource, setInitialCombinedEmpyDataSource] =
    useState({});

  const [cancelSource, setSource] = useState();

  const setShowSalaryFlag = () => {
    setSalary(!showSalary);
  };

  useEffect(() => {
    let auth = JSON.parse(sessionStorage.getItem("auth"));
    if (auth && auth.Region) {
      if (auth.Region === "M") {
        setRegion("M");
      } else {
        setRegion("I");
      }
    }
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setSource(source);

    let promises = [];
    promises.push(
      dataSourceService.getBank(source),
      dataSourceService.getCountry(source),
      dataSourceService.getEmployeeData(source),
      dataSourceService.getEmployeeChild(source),
      dataSourceService.getEmployeeEdu(source),
      dataSourceService.getPendingData(source)
    );

    Promise.all(promises)
      .then((values) => {
        let banks = values[0];
        let countrys = values[1];
        let employee = values[2];
        let employeeChild = values[3];
        let employeeEdu = values[4];
        let pendingData = values[5];

        setAllBanks(banks);
        setAllCountrys(countrys);

        if (employee) {
          employee[0].LoginEmail =
            employee[0].LoginEmail == null ? "" : employee[0].LoginEmail;

          employee[0].EmpyPerMasPhotoUrl =
            employee[0].EmpyPerMasPhotoUrl == null
              ? "https://quartoblobv2.blob.core.windows.net/shared/image/noimage.jpg"
              : employee[0].EmpyPerMasPhotoUrl;

          setInitialEmpyDataSource(employee[0]);
          setEmpyDataSource(employee[0]);
          setAttachmentDataSource(employee[0].attaches);
        }

        if (employeeChild) {
          setChildDataSource(employeeChild);
        }

        if (employeeEdu) {
          setEduDataSource(employeeEdu);
        }

        if (pendingData) {
          setAprDataSource(pendingData);
        }

        setFlag((prevState) => {
          return { ...prevState, isAllDSDone: true };
        });
      })
      .catch((error) => {
        setFlag((prevState) => {
          return { ...prevState, isLoading: false };
        });
        toast.error(error.response.data.error_description);
      });

    return () => {
      source.cancel();
    };
  }, []);

  const combineDataSource = useCallback(() => {
    var combinedChild = Object.assign([], childDataSource);
    const aprChild = Object.assign(
      [],
      aprDataSource.filter((res) => res.Category === "Children")
    );

    var combinedEducation = Object.assign([], eduDataSource);
    const aprEdu = Object.assign(
      [],
      aprDataSource.filter((res) => res.Category === "Education")
    );

    var combinedEmpy = Object.assign({}, empyDataSource);
    const aprEmpy = Object.assign(
      [],
      aprDataSource.filter((res) => res.Category === "General Details")
    );

    const flags = Object.assign({}, flag, {
      isFirstLoad: false,
      isLoading: false,
    });

    aprChild.forEach((element) => {
      if (element.RowState === 1) {
        combinedChild.push({
          // EmpyChildDetKey : Math.floor(Math.random() * 999999),
          EmpyAprKey: element.EmpyAprKey,
          // EmpyChildDetKey : element.EmpyChildDetKey,
          EmpyChildDetKey:
            element.EmpyChildDetKey === -1
              ? element.EmpyAprKey * element.EmpyChildDetKey
              : element.EmpyChildDetKey,
          EduLevel: element.EduLevel,
          EduName:
            element.EduLevel.toUpperCase() === "HIGHEDU"
              ? "High Education"
              : element.EduLevel.toUpperCase() === "FORM6"
              ? "Secondary (Form 6)"
              : element.EduLevel.toUpperCase() === "SECEDU"
              ? "Secondary"
              : element.EduLevel.toUpperCase() === "PRIEDU"
              ? "Primary"
              : element.EduLevel.toUpperCase() === "PRESCHL"
              ? "Preschool"
              : element.EduLevel.toUpperCase() === "OTHERS"
              ? "Others"
              : "Not Available",
          EmpyChildDOB: element.EmpyChildDOB,
          EmpyChildName: element.EmpyChildName,
          EmpyKey: element.EmpyKey,
          RowState: 0,
          SchName: element.SchName,
          IDNo: element.IDNo,
        });
      } else if (element.RowState === 2) {
        let index = combinedChild.findIndex(
          (x) => x.EmpyChildDetKey === element.EmpyChildDetKey
        );
        combinedChild[index] = element;
      } else if (element.RowState === 3) {
        //if pending delete , wouldnt show , remove from combined empy
        let index = combinedChild.findIndex(
          (x) => x.EmpyChildDetKey === element.EmpyChildDetKey
        );
        if (index !== -1) {
          combinedChild.splice(index, 1);
        }
      }
    });

    aprEdu.forEach((element) => {
      if (element.RowState === 1) {
        combinedEducation.push({
          EmpyAprKey: element.EmpyAprKey,
          EmpyEduDetKey:
            element.EmpyEduDetKey === -1
              ? element.EmpyAprKey * element.EmpyEduDetKey
              : element.EmpyEduDetKey,
          Education: element.Education,
          EmpyKey: element.EmpyKey,
          Remarks: element.Remarks,
          Year: element.Yr,
          RowState: 0,
        });
      } else if (element.RowState === 2) {
        let index = combinedEducation.findIndex(
          (x) => x.EmpyEduDetKey === element.EmpyEduDetKey
        );
        combinedEducation[index] = element;
      } else if (element.RowState === 3) {
        //if pending delete , wouldnt show , remove from combined edu
        let index = combinedEducation.findIndex(
          (x) => x.EmpyEduDetKey === element.EmpyEduDetKey
        );
        if (index !== -1) {
          combinedEducation.splice(index, 1);
        }
      }
    });

    aprEmpy.forEach((element) => {
      combinedEmpy[element.Field] = element[element.Field];
      flags[element.Field] = false;
    });

    if (flag.isFirstLoad) {
      setChildDataSource(combinedChild);
      setEduDataSource(combinedEducation);
      setEmpyDataSource(combinedEmpy);
      setInitialCombinedEmpyDataSource(combinedEmpy);
      setFlag(flags);
    }

    setIsFormInitialized(true);
  }, [aprDataSource, childDataSource, eduDataSource, empyDataSource, flag]);

  useEffect(() => {
    if (flag.isAllDSDone) {
      combineDataSource();
    }
  }, [flag.isAllDSDone, combineDataSource]);

  function handleChildModalSave(values) {
    const newData = [...childDataSource];
    const index = newData.findIndex(
      (item) => values.EmpyChildDetKey === item.EmpyChildDetKey
    );
    if (index > -1) {
      const item = newData[index];
      values.RowState =
        item.RowState === 0 ? 2 : item.RowState === 1 ? 1 : item.RowState;
      values.EmpyChildDOB = values.EmpyChildDOB.format(dateFormat);
      newData.splice(index, 1, {
        ...item,
        ...values,
      });

      setChildDataSource(newData);
    } else {
      values["EmpyChildDetKey"] = Math.floor(Math.random() * 999999);
      values["RowState"] = 1;
      values.EmpyChildDOB = values.EmpyChildDOB.format(dateFormat);
      newData.push(values);
      setChildDataSource(newData);
    }
  }

  function handleEduModalSave(values) {
    const newData = [...eduDataSource];
    const index = newData.findIndex(
      (item) => values.EmpyEduDetKey === item.EmpyEduDetKey
    );
    if (index > -1) {
      const item = newData[index];
      values.RowState =
        item.RowState === 0 ? 2 : item.RowState === 1 ? 1 : item.RowState;
      values.Year = values.Year.$y;
      newData.splice(index, 1, {
        ...item,
        ...values,
      });

      setEduDataSource(newData);
    } else {
      values["EmpyEduDetKey"] = Math.floor(Math.random() * 999999);
      values["RowState"] = 1;
      values.Year = values.Year.$y;
      newData.push(values);
      setEduDataSource(newData);
    }
  }

  function handleAttchModalSave(values) {
    setFlag({ ...flag, isLoadingAttch: true });
    const newAttchDataSource = [...attachmentDataSource];
    let index = -1;
    //edit existing attachment's remarks
    if (values.AttchKey !== 0) {
      index = newAttchDataSource.findIndex(
        (item) => values.AttchKey === item.AttchKey
      );
    } else {
      //edit temp attachment's remarks
      index = newAttchDataSource.findIndex(
        (item) => values.FileName === item.FileName
      );
    }

    if (index > -1) {
      const item = newAttchDataSource[index];
      item.RowState =
        item.RowState === 0 ? 2 : item.RowState === 1 ? 1 : item.RowState;
      item.Remarks = values["Remarks"];

      newAttchDataSource.splice(index, 1, item);
      setAttachmentDataSource(newAttchDataSource);
      setFileList([]);
    } else {
      //upload file to temp server folder
      uploadTempAttachment(values["Document"])
        .then((tempPath) => {
          let attachmentID = tempPath.split("/");
          setAttachID(attachmentID[4]);

          const newAttch = {
            AttchKey: 0,
            FileName: values["Document"].file.name,
            Remarks: values["Remarks"],
            RowState: RowState.ADDED,
            UploadedUrl: null,
          };

          setAttachmentDataSource([...attachmentDataSource, newAttch]);
          setFileList([]);
          setFlag({ ...flag, isLoadingAttch: false });
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  }

  function handleBNMCode(value) {
    let temp = Object.assign({}, empyDataSource, {
      BNMCode: value,
    });

    setEmpyDataSource(temp);
  }

  function handleSelectChange(value) {
    let temp =
      value === "S"
        ? Object.assign({}, empyDataSource, {
            MaritalStatus: value,
            SpouseName: "",
            SpouseDOB: "1900/01/01",
            SpouseDOM: "1900/01/01",
            SpouseID: "",
          })
        : Object.assign({}, empyDataSource, {
            MaritalStatus: value,
          });
    setEmpyDataSource(temp);

    if (value === "S") {
      form.setFieldsValue({
        SpouseName: "",
        SpouseDOB: dayjs(new Date("1900-01-01")),
        SpouseDOM: dayjs(new Date("1900-01-01")),
        SpouseID: "",
        IsSpouseWork: false,
        IsDisableSpouse: false,
      });
    }
  }

  function handleSpouseDate(value) {
    //below is to convert back to the type YYYY/MM/DD so that no error saving into DB
    let date =
      value === null
        ? "1900/01/01"
        : dayjs(value, "YYYY-MM-DD").format("YYYY/MM/DD");

    let temp = Object.assign({}, empyDataSource, {
      SpouseDOB: date,
    });
    setEmpyDataSource(temp);
  }

  function handleSpouseDOMDate(value) {
    //below is to convert back to the type YYYY/MM/DD so that no error saving into DB

    let date =
      value === null
        ? "1900/01/01"
        : dayjs(value, "YYYY-MM-DD").format("YYYY/MM/DD");

    let temp = Object.assign({}, empyDataSource, {
      SpouseDOM: date,
    });
    setEmpyDataSource(temp);
  }

  function handleImageChange(info) {
    setFlag((prevState) => {
      return { ...prevState, isLoading: true };
    });
    if (info.file.status === "uploading") {
      return;
    }

    if (info.file.status === "done") {
      let imageData = new FormData();
      imageData.append(
        "images[0]",
        info.file.originFileObj,
        info.file.originFileObj.name
      );
      //set back to empty image only can trigger change image
      var temp = Object.assign({}, empyDataSource, {
        EmpyPerMasPhotoUrl:
          "https://quartoblobv2.blob.core.windows.net/shared/image/noimage.jpg",
      });
      setEmpyDataSource(temp);

      dataSourceService
        .postAttachment(uuid + "_" + cntImage, imageData, ENVIRONMENT)
        .then((image) => {
          //dataSourceService.postAttachment(uuid, imageData, ENVIRONMENT).then(image => {
          //try
          var attachmentID = image.data.split("/");
          var temp = Object.assign({}, empyDataSource, {
            EmpyPerMasPhotoUrl: image.data,
          });

          setAttachID(attachmentID[4]);
          setEmpyDataSource(temp);
          setFlag((prevState) => {
            return { ...prevState, isLoading: false };
          });

          let tempCnt = cntImage;
          setCntImage(tempCnt + 1);
        });
    }
  }

  function handleChildDelete(ds) {
    setIsDirty(true);
    const dataSource = [...childDataSource];
    const dltDataSource = [...deletedChildDataSource];
    const dlt = dataSource.filter(
      (item) => item.EmpyChildDetKey === ds.EmpyChildDetKey
    )[0];
    if (dlt.RowState !== 1) {
      dltDataSource.push(dlt);
    }
    setChildDataSource(
      dataSource.filter((item) => item.EmpyChildDetKey !== ds.EmpyChildDetKey)
    );
    setDeletedChildDataSource(
      dltDataSource.filter((item) => item.EmpyChildDetKey !== "")
    );
  }

  function handleEduDelete(ds) {
    setIsDirty(true);
    const dataSource = [...eduDataSource];
    const dltDataSource = [...deletedEduDataSource];
    const dlt = dataSource.filter(
      (item) => item.EmpyEduDetKey === ds.EmpyEduDetKey
    )[0];
    if (dlt.RowState !== 1) {
      dltDataSource.push(dlt);
    }
    setEduDataSource(
      dataSource.filter((item) => item.EmpyEduDetKey !== ds.EmpyEduDetKey)
    );
    setDeletedEduDataSource(
      dltDataSource.filter((item) => item.EmpyEduDetKey !== "")
    );
  }

  function handleAttchDelete(ds) {
    setIsDirty(true);
    const newAttchDataSource = Object.assign([], attachmentDataSource);
    //Delete existing file
    if (ds.AttchKey !== 0) {
      const index = newAttchDataSource.findIndex(
        (x) => x.AttchKey === ds.AttchKey
      );
      if (index > -1) {
        ds.RowState = RowState.DELETED;
        newAttchDataSource.splice(index, 1);
        setDeletedAttachments([...deletedAttachments, ds]);
        setAttachmentDataSource(newAttchDataSource);
      }
    } else {
      //Delete temp file from server
      const index = newAttchDataSource.findIndex(
        (item) => item.AttchKey === 0 && item.FileName === ds.FileName
      );

      return dataSourceService
        .deleteEmpyAttachment(attachID, ds.FileName)
        .then(() => {
          //delete data from empy attaches list
          newAttchDataSource.splice(index, 1);
          setAttachmentDataSource(newAttchDataSource);
        })
        .catch((err) => {
          toast.error(err.response.data.Message);
        });
    }
  }

  function uploadTempAttachment(info) {
    const formData = new FormData();
    formData.append("files[0]", info.file, info.file.name);

    let tempAttachID = attachID & (attachID !== "") ? attachID : uuid;
    return dataSourceService
      .postEmpyAttachment(tempAttachID, info.file.name, formData)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }

  function handleChangePassword() {
    changePassword()
      .then((encrypt) => {
        if (encrypt) {
          history(encrypt.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function handleSubmit(values, formProps) {
    const pendingEmpy = {};

    if (values.PhyBankAcc !== initialEmpyDataSource.PhyBankAcc) {
      if (initialCombinedEmpyDataSource.PhyBankAcc !== values.PhyBankAcc) {
        pendingEmpy["PhyBankAcc"] = values.PhyBankAcc;
        initialEmpyDataSource.PhyBankAcc === ""
          ? initialCombinedEmpyDataSource.PhyBankAcc === ""
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "PhyBankAcc1")
              : (pendingEmpy.RcdTypeDesc += "PhyBankAcc1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "PhyBankAcc2")
            : (pendingEmpy.RcdTypeDesc += "PhyBankAcc2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "PhyBankAcc2")
          : (pendingEmpy.RcdTypeDesc += "PhyBankAcc2");
      }
      values.PhyBankAcc = initialEmpyDataSource.PhyBankAcc;
    } else {
      if (initialCombinedEmpyDataSource.PhyBankAcc !== values.PhyBankAcc) {
        pendingEmpy["PhyBankAcc"] = values.PhyBankAcc;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "PhyBankAcc3")
          : (pendingEmpy.RcdTypeDesc += "PhyBankAcc3");
      }
      values["PhyBankAcc"] = initialEmpyDataSource.PhyBankAcc;
    }

    let BNMCode = values.BNMCode !== undefined ? values.BNMCode.value : "";
    if (BNMCode !== initialEmpyDataSource.BNMCode) {
      if (initialCombinedEmpyDataSource.BNMCode !== BNMCode) {
        pendingEmpy["BNMCode"] = BNMCode;
        initialEmpyDataSource.BNMCode === ""
          ? initialCombinedEmpyDataSource.BNMCode === ""
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "BNMCode1")
              : (pendingEmpy.RcdTypeDesc += "BNMCode1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "BNMCode2")
            : (pendingEmpy.RcdTypeDesc += "BNMCode2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "BNMCode2")
          : (pendingEmpy.RcdTypeDesc += "BNMCode2");
      }
      values["BNMCode"] = initialEmpyDataSource.BNMCode;
    } else {
      if (initialCombinedEmpyDataSource.BNMCode !== BNMCode) {
        pendingEmpy["BNMCode"] = BNMCode;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "BNMCode3")
          : (pendingEmpy.RcdTypeDesc += "BNMCode3");
      }
      values["BNMCode"] = initialEmpyDataSource.BNMCode;
    }

    if (values.PermCtryKey !== undefined) {
      values["PermCtryKey"] = values.PermCtryKey.value;
    }

    if (values.MailCtryKey !== undefined) {
      values["MailCtryKey"] = values.MailCtryKey.value;
    }

    if (empyDataSource.MaritalStatus !== initialEmpyDataSource.MaritalStatus) {
      if (
        initialCombinedEmpyDataSource.MaritalStatus !==
        empyDataSource.MaritalStatus
      ) {
        pendingEmpy["MaritalStatus"] = empyDataSource.MaritalStatus;
        initialEmpyDataSource.MaritalStatus === ""
          ? initialCombinedEmpyDataSource.MaritalStatus === ""
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "MaritalStatus1")
              : (pendingEmpy.RcdTypeDesc += "MaritalStatus1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "MaritalStatus2")
            : (pendingEmpy.RcdTypeDesc += "MaritalStatus2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "MaritalStatus2")
          : (pendingEmpy.RcdTypeDesc += "MaritalStatus2");
      }
      values["MaritalStatus"] = initialEmpyDataSource.MaritalStatus;
    } else {
      if (
        initialCombinedEmpyDataSource.MaritalStatus !==
        empyDataSource.MaritalStatus
      ) {
        pendingEmpy["MaritalStatus"] = empyDataSource.MaritalStatus;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "MaritalStatus3")
          : (pendingEmpy.RcdTypeDesc += "MaritalStatus3");
      }
      values["MaritalStatus"] = initialEmpyDataSource.MaritalStatus;
    }

    if (
      (values.SpouseName === null || values.SpouseName === undefined
        ? ""
        : values.SpouseName) !==
      (initialEmpyDataSource.SpouseName === null ||
      initialEmpyDataSource.SpouseName === undefined
        ? ""
        : initialEmpyDataSource.SpouseName)
    ) {
      if (
        initialCombinedEmpyDataSource.SpouseName !==
        (values.SpouseName === null || values.SpouseName === undefined
          ? ""
          : values.SpouseName)
      ) {
        pendingEmpy["SpouseName"] =
          values.SpouseName === undefined
            ? empyDataSource.SpouseName
            : values.SpouseName;
        initialEmpyDataSource.SpouseName === ""
          ? initialCombinedEmpyDataSource.SpouseName === ""
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "SpouseName1")
              : (pendingEmpy.RcdTypeDesc += "SpouseName1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "SpouseName2")
            : (pendingEmpy.RcdTypeDesc += "SpouseName2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseName2")
          : (pendingEmpy.RcdTypeDesc += "SpouseName2");
      }
      values.SpouseName = initialEmpyDataSource.SpouseName;
    } else {
      if (
        (initialCombinedEmpyDataSource.SpouseName === null ||
        initialCombinedEmpyDataSource.SpouseName === undefined
          ? ""
          : initialCombinedEmpyDataSource.SpouseName) !==
        (values.SpouseName === null || values.SpouseName === undefined
          ? ""
          : values.SpouseName)
      ) {
        pendingEmpy["SpouseName"] =
          values.SpouseName === undefined
            ? empyDataSource.SpouseName
            : values.SpouseName;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseName3")
          : (pendingEmpy.RcdTypeDesc += "SpouseName3");
      }
      values["SpouseName"] = initialEmpyDataSource.SpouseName;
    }

    if (
      (values.SpouseID === null || values.SpouseID === undefined
        ? ""
        : values.SpouseID) !==
      (initialEmpyDataSource.SpouseID === null ||
      initialEmpyDataSource.SpouseID === undefined
        ? ""
        : initialEmpyDataSource.SpouseID)
    ) {
      if (
        initialCombinedEmpyDataSource.SpouseID !==
        (values.SpouseID === null || values.SpouseID === undefined
          ? ""
          : values.SpouseID)
      ) {
        pendingEmpy["SpouseID"] =
          values.SpouseID === undefined
            ? empyDataSource.SpouseID
            : values.SpouseID;
        initialEmpyDataSource.SpouseID === ""
          ? initialCombinedEmpyDataSource.SpouseID === ""
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "SpouseID1")
              : (pendingEmpy.RcdTypeDesc += "SpouseID1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "SpouseID2")
            : (pendingEmpy.RcdTypeDesc += "SpouseID2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseID2")
          : (pendingEmpy.RcdTypeDesc += "SpouseID2");
      }
      values.SpouseID = initialEmpyDataSource.SpouseID;
    } else {
      if (
        (initialCombinedEmpyDataSource.SpouseID === null ||
        initialCombinedEmpyDataSource.SpouseID === undefined
          ? ""
          : initialCombinedEmpyDataSource.SpouseID) !==
        (values.SpouseID === null || values.SpouseID === undefined
          ? ""
          : values.SpouseID)
      ) {
        pendingEmpy["SpouseID"] =
          values.SpouseID === undefined
            ? empyDataSource.SpouseID
            : values.SpouseID;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseID3")
          : (pendingEmpy.RcdTypeDesc += "SpouseID3");
      }
      values["SpouseID"] = initialEmpyDataSource.SpouseID;
    }

    if (
      dayjs(empyDataSource.SpouseDOB).format("YYYY/MM/DD") !==
      dayjs(initialEmpyDataSource.SpouseDOB).format("YYYY/MM/DD")
    ) {
      if (
        dayjs(initialCombinedEmpyDataSource.SpouseDOB).format("YYYY/MM/DD") !==
        dayjs(empyDataSource.SpouseDOB).format("YYYY/MM/DD")
      ) {
        pendingEmpy["SpouseDOB"] = empyDataSource.SpouseDOB;
        dayjs(initialEmpyDataSource.SpouseDOB).format("YYYY/MM/DD") ===
        "1900/01/01"
          ? dayjs(initialCombinedEmpyDataSource.SpouseDOB).format(
              "YYYY/MM/DD"
            ) === "1900/01/01"
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOB1")
              : (pendingEmpy.RcdTypeDesc += "SpouseDOB1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOB2")
            : (pendingEmpy.RcdTypeDesc += "SpouseDOB2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOB2")
          : (pendingEmpy.RcdTypeDesc += "SpouseDOB2");
      }
      values["SpouseDOB"] = initialEmpyDataSource.SpouseDOB;
    } else {
      if (
        dayjs(initialCombinedEmpyDataSource.SpouseDOB).format("YYYY/MM/DD") !==
        dayjs(empyDataSource.SpouseDOB).format("YYYY/MM/DD")
      ) {
        pendingEmpy["SpouseDOB"] = empyDataSource.SpouseDOB;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOB3")
          : (pendingEmpy.RcdTypeDesc += "SpouseDOB3");
      }
      values["SpouseDOB"] = initialEmpyDataSource.SpouseDOB;
    }

    if (
      dayjs(empyDataSource.SpouseDOM).format("YYYY/MM/DD") !==
      dayjs(initialEmpyDataSource.SpouseDOM).format("YYYY/MM/DD")
    ) {
      if (
        dayjs(initialCombinedEmpyDataSource.SpouseDOM).format("YYYY/MM/DD") !==
        dayjs(empyDataSource.SpouseDOM).format("YYYY/MM/DD")
      ) {
        pendingEmpy["SpouseDOM"] = empyDataSource.SpouseDOM;
        dayjs(initialEmpyDataSource.SpouseDOM).format("YYYY/MM/DD") ===
        "1900/01/01"
          ? dayjs(initialCombinedEmpyDataSource.SpouseDOM).format(
              "YYYY/MM/DD"
            ) === "1900/01/01"
            ? typeof pendingEmpy["RcdTypeDesc"] == "undefined"
              ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOM1")
              : (pendingEmpy.RcdTypeDesc += "SpouseDOM1")
            : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
            ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOM2")
            : (pendingEmpy.RcdTypeDesc += "SpouseDOM2")
          : typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOM2")
          : (pendingEmpy.RcdTypeDesc += "SpouseDOM2");
      }
      values["SpouseDOM"] = initialEmpyDataSource.SpouseDOM;
    } else {
      if (
        dayjs(initialCombinedEmpyDataSource.SpouseDOM).format("YYYY/MM/DD") !==
        dayjs(empyDataSource.SpouseDOM).format("YYYY/MM/DD")
      ) {
        pendingEmpy["SpouseDOM"] = empyDataSource.SpouseDOM;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "SpouseDOM3")
          : (pendingEmpy.RcdTypeDesc += "SpouseDOM3");
      }
      values["SpouseDOM"] = initialEmpyDataSource.SpouseDOM;
    }

    if (values.IsSpouseWork !== initialEmpyDataSource.IsSpouseWork) {
      pendingEmpy["IsSpouseWork"] = values.IsSpouseWork;
      typeof pendingEmpy["RcdTypeDesc"] == "undefined"
        ? (pendingEmpy["RcdTypeDesc"] = "IsSpouseWork2")
        : (pendingEmpy.RcdTypeDesc += "IsSpouseWork2");

      values["IsSpouseWork"] = initialEmpyDataSource.IsSpouseWork;
    } else {
      var filterApr = aprDataSource.filter(
        (x) => x.Field === "IsSpouseWork" && x.AprStatus === "PD"
      );
      if (filterApr.length > 0) {
        pendingEmpy["IsSpouseWork"] = values.IsSpouseWork;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "IsSpouseWork3")
          : (pendingEmpy.RcdTypeDesc += "IsSpouseWork3");
      }
      values["IsSpouseWork"] = initialEmpyDataSource.IsSpouseWork;
    }

    if (values.IsDisableSpouse !== initialEmpyDataSource.IsDisableSpouse) {
      pendingEmpy["IsDisableSpouse"] = values.IsDisableSpouse;
      typeof pendingEmpy["RcdTypeDesc"] == "undefined"
        ? (pendingEmpy["RcdTypeDesc"] = "IsDisableSpouse2")
        : (pendingEmpy.RcdTypeDesc += "IsDisableSpouse2");
      //because Switch IsDisableSpouse seems to have default value of false
      values["IsDisableSpouse"] = initialEmpyDataSource.IsDisableSpouse;
    } else {
      var filterAprDisableS = aprDataSource.filter(
        (x) => x.Field === "IsDisableSpouse" && x.AprStatus === "PD"
      );
      if (filterAprDisableS.length > 0) {
        pendingEmpy["IsDisableSpouse"] = values.IsDisableSpouse;
        typeof pendingEmpy["RcdTypeDesc"] == "undefined"
          ? (pendingEmpy["RcdTypeDesc"] = "IsDisableSpouse3")
          : (pendingEmpy.RcdTypeDesc += "IsDisableSpouse3");
      }

      values["IsDisableSpouse"] = initialEmpyDataSource.IsDisableSpouse;
    }

    //add those deleted child to post , they requires approval before deleted
    const childDS = Object.assign([], childDataSource);
    const dltchildDS = Object.assign([], deletedChildDataSource);
    dltchildDS.forEach((child) => {
      child.RowState = 3;
      if (child.EmpyChildDetKey !== "") {
        childDS.push(child);
      }
    });

    //add those deleted education to post , they requires approval before deleted
    const eduDS = Object.assign([], eduDataSource);
    const dlteduDS = Object.assign([], deletedEduDataSource);
    dlteduDS.forEach((edu) => {
      edu.RowState = 3;
      if (edu.EmpyEduDetKey !== "") {
        eduDS.push(edu);
      }
    });

    //clear all attaches and add attaches that need to upload
    empyDataSource.attaches.splice(0);
    const newAttachments = attachmentDataSource.filter(
      (x) =>
        (x.AttchKey === 0 && x.RowState === RowState.ADDED) ||
        (x.AttchKey !== 0 && x.RowState === RowState.MODIFIED)
    );

    if (newAttachments.length > 0) {
      empyDataSource.attaches = newAttachments;
    }
    if (deletedAttachments.length > 0) {
      deletedAttachments.forEach((deleteItem) => {
        empyDataSource.attaches.push(deleteItem);
      });
    }

    const dataToPost = Object.assign(
      {},
      empyDataSource,
      {
        empyChildDetails: childDS,
        RowState: 2,
        EnableEmailPayslip: empyDataSource.Email === "" ? false : true,
        empyEduDetails: eduDS,
      },
      values
    );

    formProps
      .validateFields()
      .then(() => {
        setFlag((prevState) => {
          return { ...prevState, isLoading: true };
        });
        //all these below is to get the latest keys in approval table
        dataSourceService
          .saveEmployee(
            dataToPost,
            attachID & (attachID !== "") ? attachID : uuid,
            ENVIRONMENT
          )
          .then((values) => {
            //check if pending approval data is ammended
            if (Object.keys(pendingEmpy).length !== 0) {
              //post to save require approval info
              dataSourceService
                .savePendingEmployee(
                  pendingEmpy,
                  values.data.IsNotificationSent
                )
                .then(() => {
                  setIsDirty(false);
                  getData();
                });
            } else {
              setIsDirty(false);
              getData();
            }
          });
        setEmpyDataSource(dataToPost);
        setDeletedAttachments([]);
      })
      .catch(() => {});
  }

  function getData() {
    let promisesSub = [];

    promisesSub.push(
      dataSourceService
        .getPendingData(cancelSource)
        .then(function (dataSrc) {
          if (typeof dataSrc != "undefined") {
            dataSrc.forEach((child, index) => {
              if (typeof dataSrc[index].EmpyChildDOB != "undefined") {
                var EmpyChildDOB = new Date(dataSrc[index].EmpyChildDOB);
                dataSrc[index].EmpyChildDOB =
                  EmpyChildDOB.getFullYear() +
                  "/" +
                  ("0" + (EmpyChildDOB.getMonth() + 1)).slice(-2) +
                  "/" +
                  ("0" + EmpyChildDOB.getDate()).slice(-2);
              }

              if (typeof dataSrc[index].SpouseDOB != "undefined") {
                var SpouseDOB = new Date(dataSrc[index].SpouseDOB);
                dataSrc[index].SpouseDOB =
                  SpouseDOB.getFullYear() +
                  "/" +
                  ("0" + (SpouseDOB.getMonth() + 1)).slice(-2) +
                  "/" +
                  ("0" + SpouseDOB.getDate()).slice(-2);
              }

              if (typeof dataSrc[index].SpouseDOM != "undefined") {
                var SpouseDOM = new Date(dataSrc[index].SpouseDOM);
                dataSrc[index].SpouseDOM =
                  SpouseDOM.getFullYear() +
                  "/" +
                  ("0" + (SpouseDOM.getMonth() + 1)).slice(-2) +
                  "/" +
                  ("0" + SpouseDOM.getDate()).slice(-2);
              }
            });

            setAprDataSource(dataSrc);
          }

          return dataSrc;
        })
        .catch((error) => {
          console.log(error);
        })
    );
    promisesSub.push(dataSourceService.getEmpyPhoto());
    promisesSub.push(dataSourceService.getEmployeeData(cancelSource));

    Promise.all(promisesSub).then((values) => {
      if (values[0] !== undefined) {
        var combinedChild = Object.assign([], childDataSource);
        const aprChild = Object.assign(
          [],
          values[0].filter((res) => res.Category === "Children")
        );

        var combinedEducation = Object.assign([], eduDataSource);
        const aprEdu = Object.assign(
          [],
          values[0].filter((res) => res.Category === "Education")
        );

        var combinedEmpy = Object.assign({}, empyDataSource, {
          EmpyPerMasPhotoUrl: values[1].EmpyPhotoUrl,
        });
        const aprEmpy = Object.assign(
          [],
          values[0].filter((res) => res.Category === "General Details")
        );

        var flags = Object.assign({}, flag, initialFlag);

        aprChild.forEach((r) => {
          let index = combinedChild.findIndex(
            (x) => x.EmpyChildDetKey === r.EmpyChildDetKey
          );
          if (index !== -1) {
            combinedChild[index] = r;
            combinedChild[index].RowState = 0;
            combinedChild[index].EmpyAprKey = r.EmpyAprKey;
          }
        });
        combinedChild.forEach((r) => {
          r.RowState = 0;
        });

        aprEdu.forEach((r) => {
          let index = combinedEducation.findIndex(
            (x) => x.EmpyEduDetKey === r.EmpyEduDetKey
          );
          if (index !== -1) {
            combinedEducation[index] = r;
            combinedEducation[index].RowState = 0;
            combinedEducation[index]["Year"] = r.Yr;
            combinedEducation[index].EmpyAprKey = r.EmpyAprKey;
          }
        });
        combinedEducation.forEach((r) => {
          r.RowState = 0;
        });

        aprEmpy.forEach((element) => {
          combinedEmpy[element.Field] = element[element.Field];
          flags[element.Field] = false;
        });

        setChildDataSource(combinedChild);
        setEduDataSource(combinedEducation);
        setEmpyDataSource(combinedEmpy);
        setInitialCombinedEmpyDataSource(combinedEmpy);
        setAttachmentDataSource(values[2][0].attaches);
        setFlag(flags);
        toast.success(t("general.record_saved_successfully"));
        setFlag((prevState) => {
          return {
            ...prevState,
            isLoading: false,
          };
        });
      }
    });
  }

  return (
    <div>
      <EmployeeForm
        flag={flag}
        fileList={fileList}
        setFileList={setFileList}
        attachmentDataSource={attachmentDataSource}
        allBanks={allBanks}
        allCountrys={allCountrys}
        empyDataSource={empyDataSource}
        childDataSource={childDataSource}
        eduDataSource={eduDataSource}
        handleChildModalSave={handleChildModalSave}
        handleEduModalSave={handleEduModalSave}
        handleBNMCode={handleBNMCode}
        handleSelectChange={handleSelectChange}
        handleSpouseDate={handleSpouseDate}
        handleSpouseDOMDate={handleSpouseDOMDate}
        handleImageChange={handleImageChange}
        handleChildDelete={handleChildDelete}
        handleEduDelete={handleEduDelete}
        handleAttchModalSave={handleAttchModalSave}
        handleAttchDelete={handleAttchDelete}
        handleChangePassword={handleChangePassword}
        handleSubmit={handleSubmit}
        setIsDirty={setIsDirty}
        showSalary={showSalary}
        setShowSalaryFlag={setShowSalaryFlag}
        region={region}
        form={form}
      />
    </div>
  );
}

EmployeePage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
