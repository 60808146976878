import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { List, Button, Skeleton } from "antd";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export default function HolidayProfileList({ ...props }) {
  const { t } = useTranslation();

  return (
    <List
      className="demo-loadmore-list"
      itemLayout="horizontal"
      dataSource={props.dataSource}
      footer={
        <div>
          <Button onClick={props.onAdd} type="primary">
            {t("general.add_new")}
          </Button>
        </div>
      }
      renderItem={(item) => (
        <List.Item
          actions={[
            <Button
              key={item}
              type="link"
              icon={<EditOutlined />}
              size="small"
              onClick={() => {
                props.onEdit(item);
              }}
            />,
            <Button
              key={item}
              type="link"
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                props.onDelete(item);
              }}
            />,
          ]}
        >
          <Skeleton avatar title={false} loading={false} active>
            <List.Item.Meta title={item[props.title]} />
            <div>
              {typeof item[props.content] == "object"
                ? item[props.content].format("DD/MM/YYYY")
                : dayjs(item[props.content], "YYYY/MM/DD").format("DD/MM/YYYY")}
            </div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
}
HolidayProfileList.propTypes = {
  dataSource: PropTypes.array.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
