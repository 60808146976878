import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const baseVehicleRequisition = API_ROOT + "vehiclerequisition/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export async function getVehicleRequisitions(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(baseVehicleRequisition + "GetAllVehicleRequisitionsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getVehicleRequisitionByKey(transKey) {
  return axios
    .get(baseVehicleRequisition + "GetVehicleRequisitionByKey", {
      headers: authHeader(),
      params: {
        key: transKey,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getAllVehiclesAvailability(isByYrMth, filterDate) {
  return axios
    .get(baseVehicleRequisition + "GetAllVehiclesAvailability", {
      headers: authHeader(),
      params: {
        isByYrMth: isByYrMth,
        filterDate: filterDate,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addVehicleRequisition(vehicleRequisition) {
  return axios({
    method: "POST",
    url: baseVehicleRequisition + "AddVehicleRequisition",
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updateVehicleRequisition(vehicleRequisition) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "UpdateVehicleRequisition?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deleteVehicleRequisition(vehicleRequisition) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "DeleteVehicleRequisition?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawVehicleRequisition(vehicleRequisition) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "WithdrawVehicleRequisition?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelVehicleRequisition(
  vehicleRequisition,
  cancelReason
) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "RequestCancelVehicleRequisition?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function assignVehicleAndMiles(vehicleRequisition) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "AssignVehicleAndMiles?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function completeVehicleRequisition(vehicleRequisition) {
  return axios({
    method: "POST",
    url:
      baseVehicleRequisition +
      "CompleteVehicleRequisition?key=" +
      vehicleRequisition.VehicleRequisitionKey,
    headers: authHeader(),
    data: vehicleRequisition,
  })
    .then(handleResponse)
    .catch(handleError);
}
