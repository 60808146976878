export default function ClinicModel({
  ClinicKey = -1,
  ClinicCode = "",
  ClinicDesc = "",
  ClinicName = "",
  ClinicAddress = "",
  ClinicTel = "",
  EnableAutoNo = true,
  Prefix = "",
  NumLength = 1,
  NextNum = 1,
  NextRunningNum = "",
  Active = true,
  RcdType = 0,
  RcdTypeDesc = "User",
  CreatedBy = -1,
  CreatedByCode = "",
  CreatedDate = new Date(),
  UpdatedBy = -1,
  UpdatedByCode = "",
  UpdatedDate = new Date(),
  RowVersion = null,
  checkedOUList = [],
  Company = [],
  ClinicType = 0,
}) {
  return Object.freeze({
    ClinicKey: ClinicKey,
    ClinicCode: ClinicCode,
    ClinicDesc: ClinicDesc,
    ClinicName: ClinicName,
    ClinicAddress: ClinicAddress,
    ClinicTel: ClinicTel,
    EnableAutoNo: EnableAutoNo,
    Prefix: Prefix,
    NumLength: NumLength,
    NextNum: NextNum,
    NextRunningNum: NextRunningNum,
    Active: Active,
    RcdType: RcdType,
    RcdTypeDesc: RcdTypeDesc,
    CreatedBy: CreatedBy,
    CreatedByCode: CreatedByCode,
    CreatedDate: CreatedDate,
    UpdatedBy: UpdatedBy,
    UpdatedByCode: UpdatedByCode,
    UpdatedDate: UpdatedDate,
    RowVersion: RowVersion,
    checkedOUList: checkedOUList,
    Company: Company,
    ClinicType: ClinicType,
  });
}
