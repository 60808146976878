let backendHost;
let reportHost;
let environtment;

const hostname = window && window.location && window.location.hostname;

if (hostname === "quartoweb-test.azurewebsites.net") {
  backendHost = "https://lmonehrmsapi-test.azurewebsites.net";
  reportHost = "https://lmonereportapi-test.azurewebsites.net";
  environtment = "test";
} else if (hostname === "dev.ess.cloud") {
  backendHost = "https://ilmonehrmsapi-dev.azurewebsites.net";
  reportHost = "https://ilmonereportapi-dev.azurewebsites.net";
  environtment = "dev";
} else if (
  hostname === "lessweb-qa.azurewebsites.net" ||
  hostname === "qa-ess.quarto.cloud" ||
  hostname === "iessweb-qa.azurewebsites.net"
) {
  backendHost = "https://ilmonehrmsapi-qa.azurewebsites.net";
  reportHost = "https://qalmonereportapi-qa.azurewebsites.net";
  environtment = "qa";
} else if (hostname === "llive-essweb-pre-live.azurewebsites.net") {
  backendHost = "https://llive-hrmsapi-pre-live.azurewebsites.net";
  reportHost = "https://llive-reportapi-pre-live.azurewebsites.net";
  environtment = "pre-live";
} else if (
  hostname === "hr.quarto.cloud" ||
  hostname === "llive-essweb.azurewebsites.net"
) {
  backendHost = "https://hrms.quarto.cloud";
  reportHost = "https://report.quarto.cloud";
  environtment = "live";
} else {
  backendHost = "http://localhost:52002";
  reportHost = "http://localhost:45002";
  environtment = "local";
}

export const API_REPORT_ROOT = `${reportHost}/api/`;
export const REPORT_ROOT = `${reportHost}/`;
export const API_ROOT = `${backendHost}/api/`;
export const ROOT = `${backendHost}/`;
export const ENVIRONMENT = environtment;
