import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "travelapproval/";

export function getPendingApprovalTravels() {
  return axios({
    method: "GET",
    url: base + "GetPendingApprovalTravels",
    headers: authHeader(),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndApprovedTravels(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndApprovedTravels",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getApprovedAndVoidTravels(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetApprovedAndVoidTravels",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getCancelledTravels(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetCancelledTravels",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getPendingAndValidatedTravels(currentYear) {
  return axios({
    method: "GET",
    url: base + "GetPendingAndValidatedTravels",
    headers: authHeader(),
    params: { year: currentYear },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function approveTravels(travelTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveTravels",
    headers: authHeader(),
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectTravels(travelTrans, rejectReason, isAllowAmend) {
  return axios({
    method: "POST",
    url: base + "RejectTravels",
    headers: authHeader(),
    params: { reason: rejectReason, isAllowAmend: isAllowAmend },
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function voidTravels(travelTrans, voidReason) {
  return axios({
    method: "POST",
    url: base + "VoidTravels",
    headers: authHeader(),
    params: { reason: voidReason },
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function approveCancelledTravels(travelTrans) {
  return axios({
    method: "POST",
    url: base + "ApproveCancelledTravels",
    headers: authHeader(),
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function rejectCancelledTravels(travelTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "RejectCancelledTravels",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function validateTravels(travelTrans) {
  return axios({
    method: "POST",
    url: base + "ValidateTravels",
    headers: authHeader(),
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}

export function invalidateTravels(travelTrans, rejectReason) {
  return axios({
    method: "POST",
    url: base + "InvalidateTravels",
    headers: authHeader(),
    params: { reason: rejectReason },
    data: travelTrans,
  })
    .then()
    .catch(handleError);
}
