import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT } from "./api-config";

const base = API_ROOT + "menu/";

export function getAllMenus() {
  return axios({
    method: "GET",
    url: base + "GetAllMenus",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getUserMenu() {
  return axios({
    method: "GET",
    url: base + "GetUserMenu",
    headers: authHeader()
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMenuPermByKey(menuKey) {
  return axios({
    method: "GET",
    url: base + "GetMenuPermByKey",
    headers: authHeader(),
    params: { menuKey: menuKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMenuPermByRole(roleTypeKey) {
  return axios({
    method: "GET",
    url: base + "GetMenuPermByRole",
    headers: authHeader(),
    params: { roleTypeKey: roleTypeKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getMenuCode(menuKey) {
  return axios({
    method: "GET",
    url: base + "GetMenuCode",
    headers: authHeader(),
    params: { MenuKey: menuKey }
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateRoleTypesPermissionByForm(data) {
  return axios({
    method: "PUT",
    url: base + "UpdateRoleTypesPermissionByForm",
    headers: authHeader(),
    data: data
  })
    .then()
    .catch(handleError);
}

export function updateRoleTypesPermissionByRole(data) {
  return axios({
    method: "PUT",
    url: base + "UpdateRoleTypesPermissionByRole",
    headers: authHeader(),
    data: data
  })
    .then()
    .catch(handleError);
}
