import React from "react";
import { Button, Modal, Input, DatePicker, Form } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

function HolidayProfileModal({ ...props }) {
  const { t } = useTranslation();

  function disabledDate(current) {
    //disable date that not belongs to written year
    return (
      current < dayjs(props.year, "YYYY") ||
      current >= dayjs(props.year + 1, "YYYY")
    );
  }
  return (
    <Modal
      forceRender
      title={props.title}
      destroyOnClose={true}
      open={props.show}
      onOk={() => {
        props.handleSave(props);
      }}
      onCancel={props.handleCancel}
      footer={[
        <Button key="back" onClick={props.handleCancel}>
          {t("general.cancel")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            props.handleSave(props, props.dataSource);
          }}
        >
          {t("general.save")}
        </Button>,
      ]}
    >
      <Form form={props.form} layout={"vertical"} onSubmit={props.handleSave}>
        <Form.Item
          label={t("holidayProfileForm.holiday_description")}
          name={"HolidayDesc"}
          rules={[
            {
              required: true,
              whitespace: true,
              message: t(
                "holidayProfileForm.holiday_description_required_validation"
              ),
            },
          ]}
          initialValue={
            typeof props.dataSource["HolidayDesc"] == "undefined"
              ? ""
              : props.dataSource["HolidayDesc"]
          }
        >
          <Input
            type="text"
            name={"HolidayDesc"}
            placeholder={t(
              "holidayProfileForm.holiday_description_placeholder"
            )}
          />
        </Form.Item>
        <Form.Item
          label={t("holidayProfileForm.holiday_date")}
          name={"HolidayDate"}
          rules={[
            {
              required: true,
              message: t("holidayProfileForm.holiday_date_required_validation"),
            },
          ]}
          initialValue={
            typeof props.dataSource["HolidayDate"] == "undefined"
              ? dayjs(dayjs().date(1).month(0).year(props.year), "YYYY/MM/DD")
              : dayjs(props.dataSource["HolidayDate"], "YYYY/MM/DD")
          }
        >
          <DatePicker
            format={"DD/MM/YYYY"}
            style={{ width: "100%" }}
            disabledDate={disabledDate}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

HolidayProfileModal.propTypes = {
  show: PropTypes.bool,
  handleSave: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  year: PropTypes.number,
  form: PropTypes.object,
};

export default HolidayProfileModal;
