import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  getEmpyInfo,
  getYearMonthParam,
  getPayrollCycleInfo,
} from "../../api/reportApi";
import { ReportViewer } from "./ReportViewer";
import Icon, { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { Button, Row, Col } from "antd";
import { SelectionInput } from "../common/SelectionInput";
import "../../styles/report.scss";
import { useTranslation } from "react-i18next";

export default function StaffPayslipForm() {
  const { t } = useTranslation();
  let REPORT_NAME = "Staff Payslip";
  let languageResult = [
    {
      text: "Bahasa Malaysia",
      value: "BM",
    },
    {
      text: "English",
      value: "EN",
    },
  ];
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  if (auth && auth.Region) {
    if (auth.Region === "I") {
      REPORT_NAME = "Staff Payslip IND";

      languageResult = [
        {
          text: "Bahasa Indonesia",
          value: "ID",
        },
        {
          text: "English",
          value: "EN",
        },
      ];
    }
  }

  const DEF_EMPY_INFO = {
    UserKey: -1,
    EmpyKey: -1,
    OUKey: -1,
    EmpyName: null,
    CompKey: -1,
    ClientKey: -1,
    ClientID: null,
    LangKey: 1, //cant find langkey anywhere in db
    DeptKey: -1,
    EnablePayrollCycle: 0,
  }; //To make sure when first open report wont throw error due to empyInfo is needed for some validation below.

  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [payrollCycle, setPayrollCycle] = useState();
  const [empyInfo, setEmpyInfo] = useState(DEF_EMPY_INFO);
  const [reportName, setReportName] = useState(REPORT_NAME);
  const [isReportParamValid, setReportParamValid] = useState(false);
  const [isReportGenerate, setReportGenerate] = useState(false);
  const [reportParam, setReportParam] = useState({});
  const [dsYearMonth, setDsYearMonth] = useState([]);
  const [dsYearOptions, setDsYearOptions] = useState([]);
  const [dsMonthOptions, setDsMonthOptions] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [isReportGenerating, setReportGenerating] = useState(false);
  const [dsPayrollCycleOptions, setDSPayrollCycleOptions] = useState([]);

  const [isEnablePayrollCycle, setEnablePayrollCycle] = useState(false); //To support payroll cycle for Quarto HR Staff Payslip
  const [language, setLanguage] = useState("EN");

  function getMonthLabel(value) {
    const mthLabel = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];
    return mthLabel[value - 1];
  }

  const onLanguageChange = (value) => {
    setLanguage(value);
  };

  const onMonthChange = (value) => {
    setMonth(value);
    setPayrollCycle(null);
  };

  const onPayrollCycleChange = (value) => {
    setPayrollCycle(value);
  };

  const onYrChange = (value) => {
    const filterData = dsYearMonth.filter((item) => item.Year === value);
    const mthResult = [];
    const mapMth = new Map();
    for (const filterItem of filterData) {
      if (!mapMth.has(filterItem.Month)) {
        mapMth.set(filterItem.Month, true);
        mthResult.push({
          text: getMonthLabel(filterItem.Month),
          value: filterItem.Month,
        });
      }
    }
    setYear(value);
    setMonth();
    setDsMonthOptions(mthResult);
    setPayrollCycle(null);
  };

  const generateReport = (e) => {
    e.preventDefault();
    setReportGenerate(true);
    setReportGenerating(true);
    setReportName(
      REPORT_NAME +
        "#" +
        empyInfo.ClientID +
        "#" +
        empyInfo.ClientKey +
        "#" +
        empyInfo.UserKey +
        "#" +
        empyInfo.LangKey
    );
    setReportParam({
      OUKey: empyInfo.OUKey,
      Year: year,
      Month: month,
      DeptKey: null,
      EmpyKey: empyInfo.EmpyKey,
      PayMtd: null,
      ClientKey: empyInfo.ClientKey,
      UserKey: -1,
      DefOUKey: empyInfo.OUKey,
      BreakEmpy: false,
      ShowSignature: false,
      HideBorder: false,
      IsESS: true,
      EnablePayrollCycle: isEnablePayrollCycle,
      Language: language,
      PayrollCycle:
        isEnablePayrollCycle && payrollCycle > 0 && payrollCycle < 3
          ? payrollCycle
          : "0",
    });
  };

  useEffect(() => {
    const validate = (year, month) => {
      var valid = false;
      if (
        year > 0 &&
        month > 0 &&
        month <= 12 &&
        ((isEnablePayrollCycle && payrollCycle > 0 && payrollCycle < 3) ||
          !isEnablePayrollCycle)
      ) {
        valid = true;
      }
      setReportParamValid(valid);
    };

    if (year > 0 && month > 0 && empyInfo.OUKey !== -1) {
      let promises = [getPayrollCycleInfo(year, month, empyInfo.OUKey)];

      Promise.all(promises)
        .then((data) => {
          if (data[0] && data[0].length > 0) {
            setEnablePayrollCycle(data[0][0].EnablePayrollCycle);
            if (data[0][0].Cycle1Publish && data[0][0].Cycle2Publish) {
              setDSPayrollCycleOptions([
                {
                  text: "Cycle 1",
                  value: 1,
                },
                {
                  text: "Cycle 2",
                  value: 2,
                },
              ]);
            } else if (!data[0][0].Cycle1Publish && data[0][0].Cycle2Publish) {
              setDSPayrollCycleOptions([
                {
                  text: "Cycle 2",
                  value: 2,
                },
              ]);
            } else if (data[0][0].Cycle1Publish) {
              setDSPayrollCycleOptions([
                {
                  text: "Cycle 1",
                  value: 1,
                },
              ]);
            }

            validate(year, month);
          }
        })
        .catch(() => {
          toast.error("something went wrong");
        });
    }
  }, [isEnablePayrollCycle, payrollCycle, empyInfo.OUKey, year, month]);

  useEffect(() => {
    let promises = [getYearMonthParam("P,P1"), getEmpyInfo()];

    Promise.all(promises)
      .then((data) => {
        if (data[0] && data[0].length > 0) {
          setDsYearMonth(data[0]);

          const yrResult = [],
            mthResult = [];
          const mapYr = new Map(),
            mapMth = new Map();
          for (const item of data[0]) {
            if (!mapYr.has(item.Year)) {
              mapYr.set(item.Year, true);
              yrResult.push({
                text: item.Year,
                value: item.Year,
              });
            }
          }

          const filterData = data[0].filter(
            (item) => item.Year === data[0][data[0].length - 1].Year
          );

          for (const filterItem of filterData) {
            if (!mapMth.has(filterItem.Month)) {
              mapMth.set(filterItem.Month, true);
              mthResult.push({
                text: getMonthLabel(filterItem.Month),
                value: filterItem.Month,
              });
            }
          }

          setDsYearOptions(yrResult);
          setYear(yrResult[yrResult.length - 1].value);
          setDsMonthOptions(mthResult);
          setMonth(mthResult[mthResult.length - 1].value);
        }
        setEmpyInfo(data[1] ? data[1][0] : {});
      })
      .catch(() => {
        toast.error("something went wrong");
      });
  }, []);

  return (
    <Row>
      <Col span={24}>
        <div className="report-container">
          <ReportViewer
            Collapsed={collapsed}
            ReportParam={reportParam}
            ReportName={reportName}
            ReportGenerate={isReportGenerate}
            CustomStyle={false}
            setReportGenerating={setReportGenerating}
            setReportGenerate={setReportGenerate}
          />
        </div>
        <div className="report-filter-indicator">
          <Button
            type="link"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <Icon
              style={{ fontSize: "24px" }}
              component={collapsed ? FilterFilled : FilterOutlined}
            />
          </Button>
        </div>
        <div
          className="report-param"
          style={{ display: collapsed ? "none" : "block" }}
        >
          <label>{t("general.languange")}</label>
          <br />
          <SelectionInput
            value={language}
            ref={React.createRef()}
            data={languageResult}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.languange_placeholder")}
            onChange={onLanguageChange}
            style={{ width: 200 }}
          />
          <label>{t("general.year")}</label>
          <br />
          <SelectionInput
            value={year}
            ref={React.createRef()}
            data={dsYearOptions}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.year_placeholder_2")}
            onChange={onYrChange}
            style={{ width: 200 }}
          />
          <label>{t("general.month")}</label>
          <br />
          <SelectionInput
            value={month}
            ref={React.createRef()}
            data={dsMonthOptions}
            valueProp={"value"}
            textProp={"text"}
            placeholder={t("general.month_placeholder")}
            onChange={onMonthChange}
            style={{ width: 200 }}
          />

          <div
            className="report-param-payroll-cycle"
            style={{ display: isEnablePayrollCycle ? "block" : "none" }}
          >
            <label>{t("report.payroll_cycle")}</label>
            <br />
            <SelectionInput
              value={payrollCycle}
              ref={React.createRef()}
              data={dsPayrollCycleOptions}
              valueProp={"value"}
              textProp={"text"}
              placeholder={t("report.payroll_cycle_placeholder")}
              onChange={onPayrollCycleChange}
              style={{ width: 200 }}
            />
          </div>

          <Button
            type="primary"
            onClick={generateReport}
            disabled={!isReportParamValid}
            loading={isReportGenerating}
          >
            {t("report.generate_report")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
