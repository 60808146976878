import axios from "axios";
import { handleResponse, handleError } from "./apiUtils";
import interceptorUtil from "./interceptorUtil";
import { ROOT, API_ROOT } from "./api-config";

const base = API_ROOT + "ESSUser/";

// eslint-disable-next-line no-unused-vars
export function login(credentials) {
  let auth = JSON.parse(sessionStorage.getItem("auth"));
  var data = "";

  let deviceTimeZoneOffset = -(
    Math.round((new Date().getTimezoneOffset() / 60.0) * 100) / 100
  );

  if (!Number.isInteger(credentials)) {
    if (credentials.client === undefined) {
      credentials.client = "";
    }

    data =
      "grant_type=password&username=" +
      credentials.email +
      "&password=" +
      credentials.password +
      "&isValidated=" +
      credentials.isValidated +
      "&client=" +
      credentials.client +
      "&userKey=" +
      "&timezoneoffset=" +
      deviceTimeZoneOffset;
  } else {
    data =
      "grant_type=password&username=" +
      auth.UserEmail +
      "&password=''&isValidated=''&client=" +
      auth.ClientID +
      "&userKey=" +
      credentials +
      "&timezoneoffset=" +
      deviceTimeZoneOffset;
  }

  // var registration = {
  //   userName: "choonsiang.ang@lintramax.com",
  //   password: "123456",
  //   confirmPassword: "123456"
  // };
  axios.interceptors.request.eject(interceptorUtil.getInterceptor());

  return (
    //authentication
    axios
      .post(ROOT + "token", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        sessionStorage.setItem("auth", JSON.stringify(res.data));
        if (Number.isInteger(credentials)) window.location.reload();
        return res.data;
        //handleResponse();
      })
      .catch(handleError)

    //register
    // axios
    //   .post("http://localhost:52002/api/account/register", registration)
    //   .then(res => {
    //     sessionStorage.setItem("user", JSON.stringify(res.data.access_token));
    //     return res.data.access_token;
    //   })
    //   .catch(handleError)
  );
}

export function getClient(email, userName) {
  return axios({
    method: "GET",
    url: base + "GetClient",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { email: email, userName: userName },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getESSLogo(type = "") {
  return axios({
    method: "GET",
    url: base + "GetESSLogo",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    params: { type: type },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("auth");
  sessionStorage.removeItem("localUserKey");
}
