import React, { forwardRef } from "react";
import { TreeSelect } from "antd";
import PropTypes from "prop-types";

//forwardRef is required because this component will be used as child component
export const TreeSelectionInput = forwardRef(
  (
    {
      allowClear = false,
      placeholder = "",
      fncOnChange = null,
      value,
      dsOption,
      isIncludeAllOption = false,
      disabledProp = "",
      ...props
    },
    ref
  ) => {
    return (
      <TreeSelect
        getPopupContainer={() => document.getElementById("scroll-container")}
        ref={ref}
        showSearch={true}
        allowClear={allowClear}
        placeholder={placeholder}
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        style={{
          width: 240,
          maxHeight: 100,
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
        onChange={fncOnChange}
        value={value}
        maxTagCount={maxTagCountFn(value, isIncludeAllOption)}
        maxTagTextLength={12}
        maxTagPlaceholder={(omittedValues) =>
          omittedValues.length ===
          dsOption.length + (isIncludeAllOption ? 1 : 0)
            ? `ALL SELECTED`
            : `+ ${omittedValues.length} ...`
        }
        treeData={mapSelectData(dsOption, isIncludeAllOption)}
        treeNodeFilterProp="title"
        disabled={disabledProp}
        {...props}
      ></TreeSelect>
    );
  }
);

export function getAllDsOption(dsOption, isIncludeAllOption = false) {
  let allIds = dsOption.map(({ value }) => value);
  if (isIncludeAllOption) allIds = allIds.toSpliced(0, 0, -1);
  return allIds;
}

function mapSelectData(dsOption, isIncludeAllOption) {
  let x = [];
  if (isIncludeAllOption && dsOption.length > 0) {
    x.push({
      key: -1,
      value: -1,
      title: "ALL SELECTED",
    });
  }
  let z = Array.from(dsOption, (y) => ({
    key: y.value,
    value: y.value,
    title: y.text,
  }));
  x.push(...z);
  return x;
}

function maxTagCountFn(value, isIncludeAllOption) {
  if (isIncludeAllOption) {
    if (value.includes(-1)) {
      return 0;
    }
  } else {
    if (value.includes(0)) {
      return 0;
    }
  }
  return "responsive";
}

TreeSelectionInput.displayName = "TreeSelectionInput";

TreeSelectionInput.propTypes = {
  allowClear: PropTypes.bool,
  placeholder: PropTypes.string,
  fncOnChange: PropTypes.func,
  value: PropTypes.array,
  dsOption: PropTypes.array.isRequired,
  isIncludeAllOption: PropTypes.bool,
  disabledProp: PropTypes.string,
};
