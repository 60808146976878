import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ManageAPRConfgForm } from "../../forms/Master/ManageAPRConfgForm";
import PropTypes from "prop-types";
import {
  getAPRConfgSubject,
  getAPRConfg,
  updateAPRConfg,
} from "../../../api/aprConfgApi";
import {
  getESSUser,
  getAllESSUser,
  getUserWithApprovelPerm,
} from "../../../api/roleAssignmentApi";
import * as UserType from "../../../redux/actions/actionTypes";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

let newAPRConfg = {
  APRConfgKey: "0",
  UserKey: "0",
  RoleTypeCode: UserType.USER_ROLE_USER,
  APRUserConfg: [],
  UpdatedBy: -1,
  UpdatedByCode: "",
  UpdatedOn: new Date(),
};

export default function ManageAPRConfgPage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [empys, setEmpys] = useState([]);
  const [allEmpys, setAllEmpys] = useState([]);
  const [approvers, setApprovers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [aprConfg, setAprConfg] = useState(newAPRConfg);
  const [isLoading, setIsLoading] = useState(true);
  const { UserKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    let promises = [
      getAPRConfgSubject(),
      getESSUser(),
      getUserWithApprovelPerm(),
      getAllESSUser(),
    ];
    let editKey = UserKey;
    if (editKey > 0) {
      promises.push(getAPRConfg(editKey));
    }

    Promise.all(promises)
      .then((values) => {
        setSubjects(values[0] ? values[0] : []);
        setEmpys(
          values[1] ? values[1].filter((x) => x.IsLMStaff === false) : []
        );
        setApprovers(
          values[2] ? values[2].filter((x) => x.IsLMStaff === false) : []
        );
        setAllEmpys(
          values[3] ? values[3].filter((x) => x.IsLMStaff === false) : []
        );

        if (values[4]) {
          if (values[4].UpdatedDate) {
            values[4].UpdatedOn = values[4].UpdatedDate;
          }
        }
        setAprConfg(values[4] ? values[4] : newAPRConfg);
        setIsLoading(false);
        setIsFormInitialized(true);
      })
      .catch((error) => {
        showErrorMessage(t("general.fail_to_load_record"), error);
      });
  }, [UserKey, t]);

  function saveAPRConfg(values) {
    let emptyL1ApproverCount = aprConfg.APRUserConfg.filter(
      (x) =>
        x.SubjectAppLvl === 1 &&
        (x.AprConfgApprover === undefined ||
          x.AprConfgApprover.length === 0 ||
          (x.AprConfgApprover.length > 0 &&
            x.AprConfgApprover[0].aprConfgApprovedBy1Key.length === 0))
    );

    let emptyL2ApproverCount = aprConfg.APRUserConfg.filter(
      (x) =>
        x.SubjectAppLvl === 2 &&
        (x.AprConfgApprover === undefined ||
          x.AprConfgApprover.length === 0 ||
          (x.AprConfgApprover.length > 0 &&
            x.AprConfgApprover[0].aprConfgApprovedBy2Key.length === 0))
    );

    let emptyValidatorCount = aprConfg.APRUserConfg.filter(
      (x) =>
        x.IsValidateTrans === true &&
        x.Subject !== "VEH" &&
        x.Subject !== "ROOM" &&
        (x.AprConfgValidator === undefined ||
          x.AprConfgValidator.length === 0 ||
          (x.AprConfgValidator.length > 0 &&
            x.AprConfgValidator[0].aprConfgValidatedByKeys.length === 0))
    );

    let emptyAssignorCount = aprConfg.APRUserConfg.filter(
      (x) =>
        x.IsValidateTrans === true &&
        (x.Subject === "VEH" || x.Subject === "ROOM") &&
        (x.AprConfgValidator === undefined ||
          x.AprConfgValidator.length === 0 ||
          (x.AprConfgValidator.length > 0 &&
            x.AprConfgValidator[0].aprConfgValidatedByKeys.length === 0))
    );

    let errorFields = [];
    if (emptyL1ApproverCount.length > 0 && aprConfg.IsActive === true) {
      let moduleDesc = [];
      emptyL1ApproverCount.forEach((det) => {
        moduleDesc.push(det.SubjectDesc);
      });
      moduleDesc.join(", ");
      errorFields.push(
        "[" + moduleDesc + "] " + t("aprConfigForm.L1_approved_by")
      );
    }

    if (emptyL2ApproverCount.length > 0 && aprConfg.IsActive === true) {
      let moduleDesc = [];
      emptyL2ApproverCount.forEach((det) => {
        moduleDesc.push(det.SubjectDesc);
      });
      moduleDesc.join(", ");
      errorFields.push(
        "[" + moduleDesc + "] " + t("aprConfigForm.L2_approved_by")
      );
    }

    if (emptyValidatorCount.length > 0 && aprConfg.IsActive === true) {
      let moduleDesc = [];
      emptyValidatorCount.forEach((det) => {
        moduleDesc.push(det.SubjectDesc);
      });
      moduleDesc.join(", ");
      errorFields.push(
        "[" + moduleDesc + "] " + t("aprConfigForm.validated_by")
      );
    }

    if (emptyAssignorCount.length > 0 && aprConfg.IsActive === true) {
      let moduleDesc = [];
      emptyAssignorCount.forEach((det) => {
        moduleDesc.push(det.SubjectDesc);
      });
      moduleDesc.join(", ");
      errorFields.push(
        "[" + moduleDesc + "] " + t("aprConfigForm.assigned_by")
      );
    }

    if (errorFields.length > 0) {
      showErrorMessage1(
        errorFields.join(", ") + t("aprConfigForm.is_not_set_properly")
      );
    } else {
      let aprConfgSubmit = {
        ...aprConfg,
        ...values,
      };
      setIsDirty(false);
      setIsLoading(true);
      updateAPRConfg(aprConfgSubmit.APRUserConfg)
        .then(() => {
          setIsLoading(false);

          let filterOuKey = 0,
            filterDeptKey = 0;

          if (location.state) {
            if (location.state.filterOuKey) {
              filterOuKey = location.state.filterOuKey;
            }
            if (location.state.filterDeptKey) {
              filterDeptKey = location.state.filterDeptKey;
            }
          }

          history("/home/aprconfgs", {
            state: {
              filterOuKey: filterOuKey,
              filterDeptKey: filterDeptKey,
              force: true,
              currentPage: location.state
                ? location.state.currentPage
                  ? location.state.currentPage
                  : 1
                : 1,
            },
          });

          let message =
            aprConfgSubmit.APRConfgKey !== -1
              ? t("general.record_updated_successfully")
              : t("general.record_added_successfully");
          toast.success(message);
        })
        .catch((error) => {
          setIsLoading(false);
          let message =
            aprConfgSubmit.APRConfgKey !== -1
              ? t("general.fail_to_update_record")
              : t("general.fail_to_add_record");

          showErrorMessage(message, error);
        });
    }
  }

  function cancelInputAPRConfg() {
    let filterOuKey = 0,
      filterDeptKey = 0;

    if (location.state) {
      if (location.state.filterOuKey) {
        filterOuKey = location.state.filterOuKey;
      }
      if (location.state.filterDeptKey) {
        filterDeptKey = location.state.filterDeptKey;
      }
    }

    history("/home/aprconfgs", {
      state: {
        filterOuKey: filterOuKey,
        filterDeptKey: filterDeptKey,
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function getAPRConfgInputSelectionData(aprConfgKey, subject) {
    let selectionData = [...aprConfg];

    return selectionData.filter(
      () =>
        !aprConfg.APRSubject.find(
          (y) => y.Subject === subject && y.APRConfgKey === aprConfgKey
        )
    );
  }

  function showErrorMessage(message, error) {
    let errorMsg = error.response.data.error_description
      ? error.response.data.error_description
      : error.response.data.ExceptionMessage;

    toast.error(message + (errorMsg ? errorMsg : ""));
  }

  function showErrorMessage1(message) {
    toast.error(message);
  }

  return (
    <ManageAPRConfgForm
      isLoading={isLoading}
      aprConfg={aprConfg}
      empys={empys}
      allEmpys={allEmpys}
      approvers={approvers}
      subjects={subjects}
      getAPRConfgInputSelectionData={getAPRConfgInputSelectionData}
      onSubmitted={saveAPRConfg}
      onCancelled={cancelInputAPRConfg}
      onDataChanged={setAprConfg}
      setIsDirty={setIsDirty}
      form={form}
    />
  );
}

ManageAPRConfgPage.propTypes = {
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
