import { handleResponse, handleError, authHeader } from "./apiUtils";
import axios from "axios";
import { API_ROOT, ENVIRONMENT } from "./api-config";

const basePropMaint = API_ROOT + "propmaint/";
const env = ENVIRONMENT === "qa" ? "qaa" : ENVIRONMENT;

export async function getPropMaint(source, currentYear, currentMonth = 0, isViewAllUser = false) {
  return axios
    .get(basePropMaint + "GetAllPropMaintsByUser", {
      headers: authHeader(),
      params: { year: currentYear, month: currentMonth, isViewAllUser: isViewAllUser },
      cancelToken: source.token,
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function getPropMaintByKey(key) {
  return axios
    .get(basePropMaint + "GetPropMaintByKey", {
      headers: authHeader(),
      params: {
        key: key,
        environment: env,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

export async function addPropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "AddPropMaint",
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function updatePropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "UpdatePropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function deletePropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "DeletePropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function withdrawPropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "WithdrawPropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function requestCancelPropMaint(propMaint, cancelReason) {
  return axios({
    method: "POST",
    url: basePropMaint + "RequestCancelPropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    params: { reason: cancelReason },
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function feedbackPropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "FeedbackPropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function completePropMaint(propMaint) {
  return axios({
    method: "POST",
    url: basePropMaint + "CompletePropMaint?key=" + propMaint.PropMaintKey,
    headers: authHeader(),
    data: propMaint,
  })
    .then(handleResponse)
    .catch(handleError);
}
