import React, { useState } from "react";
import {
  ActiveCheckbox,
  EditButton,
  DeleteButton,
} from "../../common/TableCols";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Table, Button, Spin, Card, Row, Col } from "antd";
import PropTypes from "prop-types";
import { RecordType } from "../../model/common/RecordType";
import FilterGridDropdown from "../../common/FilterGridDropdown";
import EntryFormFooter from "../../common/EntryFormFooter";
import { useTranslation } from "react-i18next";

export const AnnouncementsForm = ({
  isLoading,
  announcements,
  onAdd,
  onEdit,
  onDelete,
  onRowSelected,
  rowSelected,
  setCurrentPage,
  currentPage,
}) => {
  const { t } = useTranslation();

  const AnnouncementActiveCheckbox = (value) => (
    <ActiveCheckbox isChecked={value} />
  );

  const [searchText, setSearchText] = useState("");

  const AnnouncementActionButtons = (value, record) => (
    <Row>
      <Col span={12}>
        <EditButton fncEdit={() => onEdit(value)} />
      </Col>
      <Col
        span={12}
        style={{
          display: record.RcdType === RecordType.USER ? "" : "none",
        }}
      >
        <DeleteButton fncDelete={() => onDelete(value)} />
      </Col>
    </Row>
  );

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: t("general.title"),
      dataIndex: "AnnouncementTitle",
      key: "AnnouncementTitle",
      width: 200,
      sorter: (a, b) => a.AnnouncementTitle.localeCompare(b.AnnouncementTitle),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "AnnouncementTitle",
        searchText,
        handleSearch,
        handleReset,
        "Title",
        t
      ),
    },
    {
      title: t("general.description"),
      dataIndex: "AnnouncementDesc",
      key: "AnnouncementDesc",
      sorter: (a, b) => a.AnnouncementDesc.localeCompare(b.AnnouncementDesc),
      sortDirections: ["ascend", "descend"],
      ...FilterGridDropdown(
        "AnnouncementDesc",
        searchText,
        handleSearch,
        handleReset,
        "Description",
        t
      ),
    },
    {
      title: t("general.active"),
      dataIndex: "Active",
      key: "Active",
      align: "center",
      width: 100,
      render: AnnouncementActiveCheckbox,
    },
    {
      title: t("general.action"),
      dataIndex: "AnnouncementKey",
      key: "AnnouncementActions",
      align: "center",
      width: 100,
      render: AnnouncementActionButtons,
    },
  ];

  const tablePaginationChanged = (page) => {
    setCurrentPage(page);
  };

  return (
    <Card
      title={
        <span className="form-title">
          {t("announcementsForm.announcement_setup")}
        </span>
      }
      extra={
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          type="primary"
          style={{ border: "1px solid #fff" }}
          onClick={() => onAdd()}
        >
          {t("general.new")}
        </Button>
      }
    >
      <Spin spinning={isLoading}>
        <Table
          dataSource={announcements}
          columns={columns}
          size="middle"
          rowKey={(record) => record.AnnouncementKey}
          scroll={{ y: "calc(100vh - 270px)" }}
          onRow={(r) => ({ onClick: () => onRowSelected(r) })}
          pagination={{
            defaultCurrent: currentPage,
            onChange: tablePaginationChanged,
          }}
        />
      </Spin>
      <EntryFormFooter
        footerData={rowSelected}
        showFooter={rowSelected.UpdatedBy ? true : false}
        isApproved={false}
        isVoid={false}
        isPending={false}
        t={t}
      ></EntryFormFooter>
    </Card>
  );
};

AnnouncementsForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  announcements: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onRowSelected: PropTypes.func.isRequired,
  rowSelected: PropTypes.object.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};
