import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VehicleAvailabilityDisplayForm from "../../forms/VehicleReq/VehicleAvailabilityDisplayForm";
import { getAllVehiclesAvailability } from "../../../api/vehicleRequisitionApi";
import { getOU } from "../../../api/ouApi";
import { getAllVehicles } from "../../../api/vehicleApi";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

export default function VehicleAvailabilityDisplayPage() {
  const isBetween = require("dayjs/plugin/isBetween");
  dayjs.extend(isBetween);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [vehiclesAvailability, setVehiclesAvailability] = useState([]);
  const [filteredVehiclesAvailability, setFilteredVehiclesAvailability] =
    useState([]);
  const [isByYrMth, setIsByYrMth] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allOus, setAllOus] = useState([]);
  const [allVehicles, setAllVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [ouKey, setOUKey] = useState(0);
  const [vehicleKey, setVehicleKey] = useState(0);

  useEffect(getVehiclesAvailability, []);

  function getVehiclesAvailability() {
    let promises = [
      getVehiclesAvailabilityDataSource(isByYrMth, currentDate),
      getOU(),
      getAllVehicles(),
    ];

    Promise.all(promises)
      .then((values) => {
        setAllOus(values[1] ? values[1] : []);
        setAllVehicles(values[2] ? values[2] : []);
        setFilteredVehicles(values[2] ? values[2] : []);
        setTimeout(() => {
          scrollToView(isByYrMth);
        }, 300);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function scrollToView(isByYrMth) {
    //scroll to current date/time to ease view
    let now = new Date();
    var tableBody = document.querySelector("div.ant-table-body");
    if (tableBody != null) {
      if (isByYrMth) {
        //date x cell width to get scroll position
        let date = now.getDate();
        tableBody.scrollLeft = (date - 1) * 35;
      } else {
        //x 2 as column display by 30 mins interval, so 1 hour has 2 columns
        //if minutes less than 30, then minus 1 from result x 2 to get first 30 mins interval
        //+1 to include 0000 which shows at the beginning
        //total from above x cell width to get scroll position
        let hour = now.getHours() * 2 - (now.getMinutes() >= 30 ? 0 : 1) + 1;
        tableBody.scrollLeft = hour * 48.09;
      }
    }
  }

  function getListData(dateValue, isByYrMth, requisitionsData) {
    let listData = [];

    if (requisitionsData.length > 0) {
      if (isByYrMth) {
        let formatDate = dateValue.startOf("day");
        for (let x = 0; x < requisitionsData.length; x++) {
          let fromFormatDate = dayjs(requisitionsData[x].FromReqDate).startOf(
            "day"
          );
          let toFormatDate = dayjs(requisitionsData[x].ToReqDate);

          if (
            formatDate.isBetween(fromFormatDate, toFormatDate, undefined, "[)")
          ) {
            listData.push({
              content: "",
              day: dateValue.format("dddd"),
            });
          }
        }
      } else {
        for (let x = 0; x < requisitionsData.length; x++) {
          let fromDate = dayjs(requisitionsData[x].FromReqDate);
          let fromFormatDate = fromDate
            .clone()
            .subtract(fromDate.minute() % 30, "minutes");

          let toDate = dayjs(requisitionsData[x].ToReqDate);
          let toFormatDate =
            toDate.minute() > 0
              ? toDate.clone().add(30 - (toDate.minute() % 30), "minutes")
              : toDate;

          if (
            dateValue.isBetween(fromFormatDate, toFormatDate, undefined, "[)")
          ) {
            listData.push({
              content:
                requisitionsData[x].EmpyName +
                " (" +
                fromDate.format("HHmm") +
                " - " +
                toDate.format("HHmm") +
                ")",
              day: undefined,
            });
          }
        }
      }
    }

    return listData || [];
  }

  function dateCellRender(roomRequisitionData, isByYrMth, date) {
    const result = {};

    if (isByYrMth) {
      for (let x = 1; x < 32; x++) {
        let tempToStringDate = x.toString().padStart(2, "0");
        let tempToStringMth = (date.getMonth() + 1).toString().padStart(2, "0");
        let tempToStringYr = date.getFullYear().toString().padStart(4, "0");
        let tempToStringFull =
          tempToStringYr + "-" + tempToStringMth + "-" + tempToStringDate;

        tempToStringDate = dayjs(tempToStringFull);

        const listData = getListData(
          tempToStringDate,
          isByYrMth,
          roomRequisitionData
        );

        if (listData.length > 0) {
          result[x] = (result[x] ? result[x] : []).concat(listData);
        }
      }
    } else {
      for (let x = 0; x < 24; x++) {
        let tempDateTime = dayjs(date);

        //get for 0 minutes
        let time0 = (x * 100 + "").padStart(4, "0");
        tempDateTime.set({ hour: x, minute: 0, second: 0, millisecond: 0 });
        const listData = getListData(
          tempDateTime,
          isByYrMth,
          roomRequisitionData
        );
        if (listData.length > 0) {
          result[time0] = (result[time0] ? result[time0] : []).concat(listData);
        }

        //get for 30 minutes interval
        let time30 = (x * 100 + 30 + "").padStart(4, "0");
        tempDateTime.set({ hour: x, minute: 30, second: 0, millisecond: 0 });
        const listData2 = getListData(
          tempDateTime,
          isByYrMth,
          roomRequisitionData
        );
        if (listData2.length > 0) {
          result[time30] = (result[time30] ? result[time30] : []).concat(
            listData2
          );
        }
      }
    }

    return result;
  }

  function getVehiclesAvailabilityDataSource(isByYrMth, filterDate) {
    setIsLoading(true);

    let promises = [getAllVehiclesAvailability(isByYrMth, filterDate)];

    Promise.all(promises)
      .then((values) => {
        let y = 0;
        let tempData = [];

        if (values[0]) {
          for (y = 0; y < values[0].length; y++) {
            let vehicleRequisitions = values[0][y].VehicleRequisitions
              ? values[0][y].VehicleRequisitions
              : [];

            let vehicleAvailabilityData = dateCellRender(
              vehicleRequisitions,
              isByYrMth,
              filterDate
            );

            vehicleAvailabilityData.VehicleKey = values[0][y].VehicleKey;
            vehicleAvailabilityData.VehicleCode = values[0][y].VehicleCode;
            vehicleAvailabilityData.VehicleDesc = values[0][y].VehicleDesc;
            vehicleAvailabilityData.OUKey = values[0][y].OURefKey;

            tempData.push(vehicleAvailabilityData);
          }
        }
        setVehiclesAvailability(tempData);
        setFilteredVehiclesAvailability(
          tempData.filter(
            (x) =>
              (ouKey === 0 || x.OUKey === ouKey) &&
              (vehicleKey === 0 || x.VehicleKey === vehicleKey)
          )
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        showErrorMessage(
          t("general.fail_to_load_record") +
            error.response.data.error_description
        );
      });
  }

  function showErrorMessage(message) {
    toast.error(message);
  }

  function filterRecord(newOUKey, newVehicleKey) {
    setOUKey(newOUKey);
    setVehicleKey(newVehicleKey);

    let filteredVehicles = allVehicles.filter(
      (x) => newOUKey === 0 || x.OURefKey === newOUKey
    );
    setFilteredVehicles(filteredVehicles ? filteredVehicles : []);
    setFilteredVehiclesAvailability(
      vehiclesAvailability.filter(
        (x) =>
          (newOUKey === 0 || x.OUKey === newOUKey) &&
          (newVehicleKey === 0 || x.VehicleKey === newVehicleKey)
      )
    );
  }

  return (
    <VehicleAvailabilityDisplayForm
      isLoading={isLoading}
      vehiclesAvailability={filteredVehiclesAvailability}
      getVehiclesAvailabilityDataSource={getVehiclesAvailabilityDataSource}
      setIsByYrMth={setIsByYrMth}
      setCurrentDate={setCurrentDate}
      allOus={allOus}
      allVehicles={filteredVehicles}
      isByYrMth={isByYrMth}
      filterRecord={filterRecord}
      scrollToView={scrollToView}
      form={form}
    />
  );
}

VehicleAvailabilityDisplayPage.propTypes = {
  history: PropTypes.func.isRequired,
};
