import { RowState } from "../common/RowState";

export default function ExRateCurrModel({
  exRateDetKey = -1,
  currKey = -1,
  currCodeDesc = "",
  exRateFunc = 1,
  rowState = RowState.ADDED
}) {
  return Object.freeze({
    ExRateDetKey: exRateDetKey,
    CurrKey: currKey,
    CurrCodeDesc: currCodeDesc,
    ExRateFunc: exRateFunc,
    RowState: rowState
  });
}
