export default function PropMaintModel({
  propMaintKey = -1,
  transDate = new Date(),
  title = "",
  description = "",
  isRequireMaint = true,
  maintRemarks = "",
  status = 0,
  statusDesc = "",
  docNum = "Auto No.",
  reason = "",
  createdBy = -1,
  createdByCode = "",
  createdDate = new Date(),
  updatedBy = -1,
  updatedByCode = "",
  updatedDate = new Date(),
  rowVersion = null,
  attaches = []
}) {
  return Object.freeze({
    PropMaintKey: propMaintKey,
    TransDate: transDate,
    Title: title,
    Description: description,
    IsRequireMaint: isRequireMaint,
    MaintRemarks: maintRemarks,
    Status: status,
    StatusDesc: statusDesc,
    DocNum: docNum,
    Reason: reason,
    CreatedBy: createdBy,
    CreatedByCode: createdByCode,
    CreatedDate: createdDate,
    UpdatedBy: updatedBy,
    UpdatedByCode: updatedByCode,
    UpdatedDate: updatedDate,
    RowVersion: rowVersion,
    Attaches: attaches
  });
}
