import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DocumentManagerForm } from "../../forms/Master/DocumentManagerForm";
import dayjs from "dayjs";
import { attachFiles } from "../../../api/fileUploadApi";
import { message } from "antd";
import {
  getAllDocuments,
  addDocument,
  updateDocument,
  deleteDocument,
} from "../../../api/docManagerApi";
import * as UserType from "../../../redux/actions/actionTypes";
import { useTranslation } from "react-i18next";

export default function DocumentMangagerPage() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});

  const [newAddFiles, setNewAddedFiles] = useState([]);
  const [updateFiles, setUpdateFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  const [userRoleType, setUserRoleType] = useState(UserType.USER_ROLE_USER);

  useEffect(() => {
    //get user role
    let { UserRoleType } = JSON.parse(sessionStorage.getItem("auth"));
    setUserRoleType(UserRoleType);
    let promises = [];
    promises.push(getAllDocuments());
    Promise.all(promises)
      .then((values) => {
        if (values.length > 0) {
          let tempValues = Object.assign([], values[0]);
          tempValues.forEach((val) => {
            val.key = val.DisplayPath;
            val.size = val.Size;
            val.modified = +dayjs(val.UpdatedDate);
          });
          setFiles(values[0]);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error_description);
      });
  }, []);

  //Creating folder
  function handleCreateFolder(key) {
    //check if folder exist
    let tempRcd = files.filter((x) => x.key.substr(0, key.length) === key);
    if (tempRcd.length > 0) {
      message.error(t("documentManager.duplicate_uploaded_folder_name"));
    } else {
      let tempFiles = files.concat([{ key: key, DocManagerKey: -1 }]);
      let tempNewAddedFiles = [
        ...newAddFiles,
        { key: key, FileType: "Folder", DocManagerKey: -1 },
      ];
      setIsLoading(true);
      setNewAddedFiles(tempNewAddedFiles);
      setFiles(tempFiles);
      saveNewFolder(tempFiles, tempNewAddedFiles);
    }
  }

  function saveNewFolder(latestFile, toSaveFiles) {
    let tempFiles = Object.assign([], latestFile);
    let documentSubmit = Object.assign([], toSaveFiles);
    documentSubmit.forEach((doc) => {
      doc.DisplayPath = doc.key;
    });
    addDocument(documentSubmit)
      .then((result) => {
        setNewAddedFiles([]);
        setIsLoading(false);
        //assign back to files
        result.data.forEach((res) => {
          tempFiles.find((x) => x.key === res.DisplayPath).DocManagerKey =
            res.DocManagerKey;
          tempFiles.find((x) => x.key === res.DisplayPath).DisplayPath =
            res.DisplayPath;
        });
        setFiles(tempFiles);
        // toast.success("Record added successfully.");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t("general.fail_to_load_record"));
      });
  }

  function handleCreateFiles(filesParam, prefix) {
    let tempFile = Object.assign([], newAddFiles);
    let newAddedTempFile = []; //push into another array of data type File
    const newFiles = filesParam.map((file) => {
      let newKey = prefix;
      if (
        prefix !== "" &&
        prefix.substring(prefix.length - 1, prefix.length) !== "/"
      ) {
        newKey += "/";
      }
      newKey += file.name;
      newAddedTempFile.push(file);
      newAddedTempFile[newAddedTempFile.length - 1].key = newKey;
      newAddedTempFile[newAddedTempFile.length - 1].FileType = "File";
      newAddedTempFile[newAddedTempFile.length - 1].DocManagerKey = -1;
      return {
        key: newKey,
        size: file.size,
        modified: +dayjs(),
        DocManagerKey: -1,
      };
    });
    const uniqueNewFiles = [];
    newFiles.forEach((newFile) => {
      let exists = false;
      files.forEach((existingFile) => {
        if (existingFile.key === newFile.key) {
          exists = true;
        }
      });

      //check is file size less than 4mb
      const isLt4M = newFile.size / 1024 / 1024 <= 4;
      if (!isLt4M) {
        message.error(t("documentManager.maximum_file_size_4mb"));
      }
      if (!exists && isLt4M) {
        uniqueNewFiles.push(newFile);
      } else if (exists) {
        message.error(t("documentManager.duplicate_uploaded_file_name"));
      }
    });

    //own added file type array, loop and push if not exist in existing file list
    newAddedTempFile.forEach((newTempFile) => {
      if (!files.some((existingFile) => existingFile.key === newTempFile.key)) {
        const isLt4M = newTempFile.size / 1024 / 1024 <= 4;
        if (isLt4M) {
          tempFile.push(newTempFile);
        }
      }
    });
    setNewAddedFiles(tempFile);
    let tempExistingFiles = files.concat(uniqueNewFiles);
    setFiles(tempExistingFiles);
    if (tempFile.length > 0) {
      setIsLoading(true);
      onUploadAttachment(tempFile)
        .then((result) => {
          saveNewFile(result, tempExistingFiles, tempFile);
        })
        .catch(() => {
          toast.error("File Upload failed.");
        });
    }
  }

  function saveNewFile(documents, latestFile, toSaveFiles) {
    let tempFiles = Object.assign([], latestFile);
    let documentSubmit = Object.assign([], toSaveFiles);
    documentSubmit.forEach((doc) => {
      doc.AzurePath = documents.data.filter(
        (x) => x.DisplayPath === doc.key
      )[0].UploadedUrl;
      doc.DisplayPath = doc.key;
      doc.Size = doc.size;
    });
    addDocument(documentSubmit)
      .then((result) => {
        setNewAddedFiles([]);
        setIsLoading(false);
        //assign back to files
        result.data.forEach((res) => {
          tempFiles.find((x) => x.key === res.DisplayPath).DocManagerKey =
            res.DocManagerKey;
          tempFiles.find((x) => x.key === res.DisplayPath).AzurePath =
            res.AzurePath;
          tempFiles.find((x) => x.key === res.DisplayPath).DisplayPath =
            res.DisplayPath;
        });
        setFiles(tempFiles);
        // toast.success("Record added successfully.");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t("general.fail_to_add_record"));
      });
  }

  //applicable for move and rename
  function handleRenameFolder(oldKey, newKey) {
    const newFiles = [];
    let updateFile = Object.assign([], updateFiles);
    // let tempFile = Object.assign([], newAddFiles);
    //to ensure newly added folder doesnt added to updateFiles
    // let editExist = false;
    files.forEach((file) => {
      if (file.key.substr(0, oldKey.length) === oldKey) {
        newFiles.push({
          ...file,
          key: file.key.replace(oldKey, newKey),
          modified: +dayjs(),
        });

        updateFile.push({
          ...file,
          key: file.key.replace(oldKey, newKey),
          modified: +dayjs(),
          DisplayPath: file.key.replace(oldKey, newKey),
        });
        //remove the checkings because all files directly save to server
        //will not be file that newly created then edited

        // if (file.DocManagerKey !== -1) { // if not newly created Folder
        //     //not exist in the current updateFile list
        //     if (!updateFile.some(x => x.key.substr(0, oldKey.length) === oldKey)) {
        //         updateFile.push({
        //             ...file,
        //             key: file.key.replace(oldKey, newKey),
        //             modified: +dayjs(),
        //             DisplayPath: file.key.replace(oldKey, newKey),
        //         })
        //     }
        //     else {
        //         //remove from updateFile if the previously updated file was added to the list
        //         updateFile = updateFile.filter(x => x.key.substr(0, oldKey.length) !== oldKey);
        //     }

        // }
        // else {
        //     //those newly created directly edit, do not add to updateFile list
        //     tempFile.find(x => x.key.substr(0, oldKey.length) === oldKey).key = file.key.replace(oldKey, newKey);
        //     editExist = true;
        // }
      } else {
        newFiles.push(file);
      }
    });
    // if (editExist) {
    //     setNewAddedFiles(tempFile);
    // }

    setFiles(newFiles);
    setUpdateFiles(updateFile);
    if (updateFile.length > 0) {
      setIsLoading(true);
      saveUpdate(updateFile);
    }
  }

  function handleRenameFile(oldKey, newKey) {
    const newFiles = [];
    let updateFile = Object.assign([], updateFiles);
    // let tempFile = Object.assign([], newAddFiles);
    // let editExist = false;

    files.forEach((file) => {
      if (file.key === oldKey) {
        newFiles.push({
          ...file,
          key: newKey,
          modified: +dayjs(),
        });
        updateFile.push({
          ...file,
          key: newKey,
          modified: +dayjs(),
          DisplayPath: newKey,
        });
      } else {
        newFiles.push(file);
      }
    });

    setFiles(newFiles);
    setUpdateFiles(updateFile);
    if (updateFile.length > 0) {
      setIsLoading(true);
      saveUpdate(updateFile);
    }
  }

  function saveUpdate(toUpdateFiles) {
    let documentSubmit = Object.assign([], toUpdateFiles);
    updateDocument(documentSubmit)
      .then(() => {
        setUpdateFiles([]);
        setIsLoading(false);
        // toast.success("Record updated successfully.");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t("general.fail_to_update_record"));
      });
  }

  function handleDeleteFolder(folderKey) {
    const newFiles = [];
    let deleteFile = Object.assign([], deletedFiles);
    // let tempFile = Object.assign([], newAddFiles);
    // let editExist = false;
    files.forEach((file) => {
      //try own code
      folderKey.forEach((x) => {
        //assign back all files not equals to deleted files
        if (file.key.substr(0, x.length) !== x && !newFiles.includes(file)) {
          newFiles.push(file);
        }
        // deleted and wasnt newly added files
        else if (
          file.key.substr(0, x.length) === x &&
          !deleteFile.includes(file) &&
          file.DocManagerKey !== -1
        ) {
          deleteFile.push(file);
        }
        //remove checking of newly added files
        // deleted and newly added files
        // else if (file.key.substr(0, x.length) === x && file.DocManagerKey === -1) {
        //     //filter them away
        //     tempFile = tempFile.filter(k => file.key.substr(0, x.length) !== k.key);
        //     editExist = true;
        // }
      });
    });
    // if (editExist) {
    //     setNewAddedFiles(tempFile);
    // }
    setFiles(newFiles);
    setDeletedFiles(deleteFile);
    if (deleteFile.length > 0) {
      setIsLoading(true);
      saveDelete(deleteFile);
    }
  }

  function handleDeleteFile(fileKey) {
    let newFiles = [];
    let deleteFile = Object.assign([], deletedFiles);
    //try own code
    newFiles = Object.assign(
      [],
      files.filter((x) => !fileKey.includes(x.key))
    );
    files.forEach((file) => {
      if (
        fileKey.includes(file.key) &&
        !deleteFile.includes(file) &&
        file.DocManagerKey !== -1
      ) {
        deleteFile.push(file);
      }
    });
    setFiles(newFiles);
    setDeletedFiles(deleteFile);
    if (deleteFile.length > 0) {
      setIsLoading(true);
      saveDelete(deleteFile);
    }
  }

  function saveDelete(toDeleteFiles) {
    let documentSubmit = Object.assign([], toDeleteFiles);
    deleteDocument(documentSubmit)
      .then(() => {
        setDeletedFiles([]);
        setIsLoading(false);
        // toast.success("Record deleted successfully.");
      })
      .catch(() => {
        setIsLoading(false);
        toast.error(t("general.fail_to_delete_record"));
      });
  }

  function handleDownloadFile() {
    //open in new window
    window.open(selectedFile.AzurePath, "_blank");
  }

  function handleSelectedFile(file) {
    //assign file's info when selected, to be used during download
    setSelectedFile(file);
    //to directly download according to yong khong
    if (!isEdit) {
      window.open(file.AzurePath, "_blank");
    }
  }

  function onUploadAttachment(toSaveFiles) {
    //uploading attachment
    let attachmentID = "";
    const formData = new FormData();
    toSaveFiles.forEach((file) => {
      formData.append(file.key, file);
    });
    setIsLoading(true);
    return attachFiles(formData, "DocumentManager", attachmentID)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        toast.error(err.response.data.Message);
      });
  }

  function onEdit() {
    setIsEdit(!isEdit);
  }

  return (
    <DocumentManagerForm
      isLoading={isLoading}
      files={files}
      handleCreateFolder={handleCreateFolder}
      handleCreateFiles={handleCreateFiles}
      handleRenameFolder={handleRenameFolder}
      handleRenameFile={handleRenameFile}
      handleDeleteFolder={handleDeleteFolder}
      handleDeleteFile={handleDeleteFile}
      // handleDownloadFolder={handleDownloadFolder}
      handleDownloadFile={handleDownloadFile}
      handleSelectedFile={handleSelectedFile}
      isEdit={isEdit}
      onEdit={onEdit}
      isAdmin={userRoleType === UserType.USER_ROLE_ADMIN}
    />
  );
}
