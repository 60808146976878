import React, { useEffect, useState } from "react";
import { ManageHolidayProfileForm } from "../../forms/Master/ManageHolidayProfileForm";
import { dataSourceService } from "../../../api/holidayProfileApi";
import * as compapi from "../../../api/companyApi";
import PropTypes from "prop-types";
import Axios from "axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { useParams, useLocation } from "react-router-dom";
import { Form } from "antd";
import { useTranslation } from "react-i18next";

const newHolidayProfile = {
  ProfileKey: -1,
  ProfileCode: "",
  ProfileDescDesc: "",
  ProfileCodeDesc: "",
  HPStpOUKey: [],
  OUCodeOUDesc: " - ",
  OUKey: -1,
  RowState: 0,
  Yr: 1900,
  Active: true,
  RcdType: 0,
  RcdTypeDesc: "User",
  CreatedBy: -1,
  CreatedByCode: "",
  CreatedDate: new Date(),
  UpdatedBy: -1,
  UpdatedByCode: "",
  UpdatedDate: new Date(),
  checkedOUList: [],
  Company: [],
};

export default function ManageHolidayProfilePage({ history, setIsDirty }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isFormInitialized, setIsFormInitialized] = useState(false);
  const [holidayProfile, setHolidayProfile] = useState(newHolidayProfile);
  const [isLoading, setIsLoading] = useState(true);
  const [holidayProfileDet, setHolidayProfileDet] = useState([]);
  const [initialHolidayProfileDet, setInitialHolidayProfileDet] = useState([]);
  const [deletedHolidayDet, setDeleteHolidayDet] = useState([]);
  const [tempHoliday, setTempHoliday] = useState({});
  const [initialOU, setInitialOU] = useState({});
  const [allHolidayProfile, setAllHoliday] = useState([]);
  const [tempProfileKey, setTempProfilekey] = useState(-1);
  const [tempYr, setTempYr] = useState(1900);
  const [cancelSouce, setSource] = useState();
  const { ProfileKey } = useParams();
  const location = useLocation();

  useEffect(() => form.resetFields(), [isFormInitialized, form]);

  useEffect(() => {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    setSource(source);
    let promises = [];
    promises.push(compapi.getCompany());
    promises.push(dataSourceService.getHdrDataSource(source));
    let editKey = ProfileKey;
    if (editKey > 0) {
      promises.push(
        dataSourceService.getHolidayList(editKey, source).then((res) => {
          res.forEach((element) => {
            element.HolidayDate = dayjs(element.HolidayDate, "YYYY/MM/DD");
          });
          return res;
        })
      );
    }
    Promise.all(promises)
      .then((values) => {
        if (editKey > 0) {
          values[1]
            ? values[1].map((x) => (x["Company"] = values[0]))
            : (newHolidayProfile.Company.length = 0);
          values[0].map((x) => newHolidayProfile["Company"].push(x));
          setHolidayProfileDet(values[2]);
          setInitialHolidayProfileDet(values[2]);
          setAllHoliday(values[1]);
          const profile = values[1].filter(
            (res) => res.ProfileKey === parseInt(editKey, 10)
          )[0];
          filterUsedOU(profile.Yr, profile.Company, values[1], editKey);
          if (profile) {
            if (profile.UpdatedDate) {
              profile.UpdatedOn = profile.UpdatedDate;
            }
            if (profile.CreatedDate) {
              profile.CreatedOn = profile.CreatedDate;
            }
          }

          setHolidayProfile(profile);
          setTempYr(profile.Yr);
          setInitialOU(profile.HPStpOUKey);
          setTempProfilekey(parseInt(editKey, 10));
          setIsLoading(false);
          setIsFormInitialized(!isFormInitialized);
        } else {
          newHolidayProfile["Company"].length = 0;
          values[0].map((x) => newHolidayProfile["Company"].push(x));
          newHolidayProfile.HPStpOUKey = [];
          setHolidayProfile(newHolidayProfile);
          setTempYr(newHolidayProfile.Yr);
          setAllHoliday(values[1]);
          setIsLoading(false);
          setIsFormInitialized(!isFormInitialized);
        }
      })
      .catch(() => {
        setHolidayProfile(newHolidayProfile);
        setIsLoading(false);
      });
    return () => {
      source.cancel();
    };
    // eslint-disable-next-line
  }, [ProfileKey]);

  function submitForm(values) {
    setIsLoading(true);
    const tempOU = [];
    if (initialOU.length > 0) {
      initialOU.forEach((r) => {
        values.checkedOUList.includes(r)
          ? tempOU.push({ OUKey: r, RowState: 0 })
          : tempOU.push({ OUKey: r, RowState: 3 });
      });
      values.checkedOUList.forEach((r) => {
        if (!initialOU.includes(r)) {
          tempOU.push({ OUKey: r, RowState: 1 });
        }
      });
    } else {
      values.checkedOUList.forEach((r) => {
        tempOU.push({ OUKey: r, RowState: 1 });
      });
    }

    let appendOURowState = "";
    const HPStpOUKeyToPost = [];
    tempOU
      .sort((a, b) => a.OUKey - b.OUKey)
      .forEach((element) => {
        appendOURowState += element.OUKey + "," + element.RowState + ";";
        HPStpOUKeyToPost.push(element.OUKey);
      });

    tempProfileKey === -1
      ? setTempProfilekey(Math.floor(Math.random() * 999999))
      : setTempProfilekey(tempProfileKey);

    let holidayProfilePost = Object.assign([], holidayProfileDet);
    //to handle deleted holiday
    if (deletedHolidayDet.length > 0) {
      deletedHolidayDet.forEach((dlt) => {
        holidayProfilePost.push(dlt);
      });
    }

    //cast profilecode to upper case
    values.ProfileCode = values.ProfileCode.toUpperCase();
    var dataToPost = Object.assign({}, holidayProfile, values, {
      profDetails: holidayProfilePost,
      RowState: tempProfileKey > 0 ? 2 : 1,
      OUCodeOUDesc: appendOURowState,
      HPStpOUKey: HPStpOUKeyToPost,
    });

    dataSourceService.saveHolidayDet(dataToPost, cancelSouce).then(() => {
      setIsLoading(false);
      toast.success(t("general.record_saved_successfully"));
      setIsDirty(false);
      history("/home/holiday", {
        state: {
          force: true,
          currentPage: location.state
            ? location.state.currentPage
              ? location.state.currentPage
              : 1
            : 1,
        },
      });
    });
  }

  function cancelInputHolidayProfile() {
    history("/home/holiday", {
      state: {
        currentPage: location.state
          ? location.state.currentPage
            ? location.state.currentPage
            : 1
          : 1,
      },
    });
  }

  function filterUsedOU(value, company, holidayProfile, editKey) {
    const filteredHoliday = holidayProfile
      ? holidayProfile.filter(
        (element) =>
          element.Yr === value && element.ProfileKey.toString() !== editKey
      )
      : allHolidayProfile.filter((element) => element.Yr === value);

    const usedOU = [];
    //loop filtered holiday and loop their HPStpOUKey and push the oukey into usedOU
    filteredHoliday.forEach((hol) => {
      hol.HPStpOUKey.forEach((ou) => {
        if (initialOU.length > 0 && tempProfileKey !== -1) {
          // to make sure initialOU have value
          // to check does initial ou contain filtered ou, to prevent deletion
          if (!initialOU.includes(ou)) {
            usedOU.push(ou);
          }
          //if the year on form is different from the initial year, OU should be filtered
          else if (holidayProfile !== value) {
            usedOU.push(ou);
          }
        } else {
          usedOU.push(ou);
        }
      });
    });

    company.forEach((comp) => {
      comp.SelectionDetData.forEach((ou) => {
        if (usedOU.includes(ou.Key)) {
          ou["disabled"] = true;
        } else {
          ou["disabled"] = false;
        }
      });
    });
  }

  function onYearChange(value, company) {
    if (value.toString().length === 4) {
      if (value !== tempYr && value !== holidayProfile.Yr) {
        setHolidayProfileDet([]);
        setTempYr(value);
      }
      //bottom part to return the initial listing if year typed is same as initial year
      else if (value === holidayProfile.Yr) {
        setHolidayProfileDet(initialHolidayProfileDet);
      }
    }
    filterUsedOU(value, company, null, -1);
  }
  return (
    <ManageHolidayProfileForm
      isLoading={isLoading}
      holidayProfile={holidayProfile}
      history={history}
      holidayProfileDet={holidayProfileDet}
      setHolidayProfileDet={setHolidayProfileDet}
      deleteHolidayDet={setDeleteHolidayDet}
      deletedDet={deletedHolidayDet}
      tempHoliday={tempHoliday}
      setTempHoliday={setTempHoliday}
      onSubmit={submitForm}
      allHoliday={allHolidayProfile}
      profileKey={tempProfileKey}
      onYearChange={onYearChange}
      setIsDirty={setIsDirty}
      form={form}
      onCancelled={cancelInputHolidayProfile}
    />
  );
}

ManageHolidayProfilePage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.func.isRequired,
  setIsDirty: PropTypes.func.isRequired,
};
